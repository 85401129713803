import axios from "axios";
import dateNow from "../../utilities/dateNow";
import ochoDigitos from "../../utilities/ochoDigitos";
export default class TallerPaymentCardService {
  async save(data, jwt1) {
    // if (token) {
    //   jwt = JSON.parse(token);
    // }
    try {
      const response = await axios.post(
      "https://proxy.mycoop.io/https://cerberus.multicredit.com.gt/api/v1/exchequer/teller/payment/card",
     // "https://proxy.mycoop.io/https://cerberus.ist.com.gt/api/v1/exchequer/teller/payment/card", 
      data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt1}`,
          },
        }
      );

      return response;
    } catch (err) {
      console.log(err?.response);
    }

    // let config = {
    //   method: 'post',
    //   maxBodyLength: Infinity,
    //   url: 'https://cerberus.ist.com.gt/api/v1/exchequer/teller/payment/card',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJUVEtTTjBwLVR2WFotTTRUNzZ4S080RFJqM3lneDhKMTBOTm1pZXJIZDBJIn0.eyJleHAiOjE2ODQxOTQ4ODksImlhdCI6MTY4NDE5MzM4OSwianRpIjoiOGQ2MzdiNWMtMjFkOS00ZmEwLThhYjQtYzE1YjY1OTA0MTIxIiwiaXNzIjoiaHR0cHM6Ly9zc28uaXN0LmNvbS5ndC9hdXRoL3JlYWxtcy9FeGNoZXF1ZXIiLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiMWQ4YmNkYjctMTY1Zi00ZmMwLWIyOWQtMjY5YTgzZGU4NWJjIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiYXBpIiwic2Vzc2lvbl9zdGF0ZSI6IjViN2E5NDVlLWM0MzYtNDIyYy1iMjllLWRiZmM1ODA2MDA2MyIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJjZXJiZXJ1c19hcGkiLCJkZWZhdWx0LXJvbGVzLWV4Y2hlcXVlciJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImFjY291bnQiOnsicm9sZXMiOlsibWFuYWdlLWFjY291bnQiLCJtYW5hZ2UtYWNjb3VudC1saW5rcyIsInZpZXctcHJvZmlsZSJdfX0sInNjb3BlIjoicHJvZmlsZSBlbWFpbCIsInNpZCI6IjViN2E5NDVlLWM0MzYtNDIyYy1iMjllLWRiZmM1ODA2MDA2MyIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwiY29tcGFueSI6ImZvbmRvc2FtZXJpY2EiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJmb25kb3NhbWVyaWNhIiwiZW1haWwiOiJBQUEifQ.Y393FIqq206iRwYBFtlOtxlbg5D-a1m-rgNgapb21PM-OJtVjV6MdvUfwJBpzvhKCeaXZLu8qnD7SBkXdfedQZaACPilA05TU8ewtyxCtJU1QS3ezh-xMBrzPXLyi-ioEE_yPlnRYvjse2kz5Xvrx9fceIwrqjJTVFZPk6ZPzcTOOU43no3z385Ye-5jikH8Kqei5FV8MhC4wOWrqF8tyJ0JLa_30f5i7wuJAws117CRXMamu6WwgdooGSo-gJxHGo-MKNCeG8MfpQWopB8wm7akBvCcg5mN7xPZBM_VjCJ2MrKjRmQa_CVi_xkfH8WmmD68GVu5ZxjCyTu3hlU7xQ'
    //   },
    //   data : data
    // };
    // axios
    //   .request(config)
    //   .then((response) => {
    //    return response.data;
    //   })
    //   .catch((error) => {
    //    console.log(error);
    //   });
  }

  async saveRetiro(data, jwt1) {


    try {
      const response = await axios.post(
        "https://proxy.mycoop.io/https://cerberus.multicredit.com.gt/api/v1/exchequer/teller/debit/card",
      //  "https://proxy.mycoop.io/https://cerberus.ist.com.gt//api/v1/exchequer/teller/debit/card",
       
     data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt1}`,
          },
        }
      );

      return response;
    } catch (err) {
      console.log(err?.response);
    }

  }



  async saveSif(dataTransaccion, numeroDeAutorizacion, jwt) {

    function redondear(numero) {
      const numeroRedondeado = Math.round(numero * 100) / 100;
      return numeroRedondeado.toFixed(2);
    }
   // user.usr.usuUsuario,

    const user = JSON.parse(localStorage.getItem("user"));
    let data = {
      Transaccion: {
        ApEmpCodigo: 1,
        ApEmpNombre: "COOPERATIVA FONDOS DE AMÉRICA, R. L.",
        ApTrxCod:  dataTransaccion.numeroTransaccion,
        ApTrxDes:
          dataTransaccion.numeroTransaccion === 5334
            ? "DEPOSITO TARJETA DE DEBITO"
            : "RETIRO TARJETA DE DEBITO",
        ApModCod: "CJ",
        ApModNom: "CAJA",
        ApTrxUso: 1,
        ApTrxNaturaleza: 2,
        ApTrxSigla:
          dataTransaccion.numeroTransaccion === 5334 ? "DTDEB" : "RTDEB",
        ApTrxEstadisticas: "S",
        ApTrxTipCer: 1,
        ApTrxTC1Rut: 80,
        ApTrxTC1Des: "",
        ApTrxTC2Rut: 80,
        ApTrxTC2Des: "",
        ApTrxAutoSup: "1",
        ApTrxUltPar: 0,
        ApTrxConta: "S",
        ApTrxActiva: "A",
        ApTrxEsAjuste: "N",
        ApTrxRptCertifica: "",
        ApTrxEnlace: 0,
        Transaccion: {
          FechaValor: dateNow(),
          Total: dataTransaccion.moneda === "320" ? redondear(dataTransaccion.monto) : `${redondear(Number(dataTransaccion.monto)*8)}` ,
          Cuenta: "",
          LibNumero: 0,
          ApEmpCodigo: 1,
          ApSucCodigo: user.usr.appSucursal.sucId,
          ApUsuCod:  user.usr.usuUsuario,
          ApMonCod: 1,
          NumeroDocumento: dataTransaccion.numeroDococumento,
          ApCajRecCodigo: 0,
          ClCliCod: dataTransaccion.asociado,
          Cuotas: 0,
          CrUltimaTrx: 0,
        },
        Campos: [
          {
            ApCamCod: 2,
            ApCtxOrden: 1,
            ApCamClase: 4,
            ApCamEti: "No. DE DOCUMENTO",
            ApCamTipo: "N",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "",
            ValorTXT: "",
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: `${dataTransaccion.numeroDococumento}`,
          },
          {
            ApCamCod: 9,
            ApCtxOrden: 2,
            ApCamClase: 3,
            ApCamEti: "VALOR EFECTIVO",
            ApCamTipo: "D",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "",
            ValorTXT: "",
            ValorNumerico: `${ dataTransaccion.moneda === "320" ? redondear(dataTransaccion.monto) : `${redondear(Number(dataTransaccion.monto)*8)}` }`,
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 10,
            ApCtxOrden: 3,
            ApCamClase: 3,
            ApCamEti: "CHEQUES PROPIOS",
            ApCamTipo: "D",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "",
            ValorTXT: "",
            ValorNumerico:  `${(dataTransaccion.montoCheque)}`,
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 23,
            ApCtxOrden: 4,
            ApCamClase: 12,
            ApCamEti: "REFERENCIA",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "S",
            ValorTXT: dataTransaccion.referencia,
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 24,
            ApCtxOrden: 5,
            ApCamClase: 3,
            ApCamEti: "CODIGO SIFCO ASO",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "S",
            ValorTXT: `${ dataTransaccion.asociado}`,
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 30,
            ApCtxOrden: 6,
            ApCamClase: 12,
            ApCamEti: "NOMBRE",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "S",
            ValorTXT:  dataTransaccion.nombreAsociado,
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 228,
            ApCtxOrden: 7,
            ApCamClase: 3,
            ApCamEti: "NO. TARJETA DEBITO",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "N",
            ValorTXT:ochoDigitos(dataTransaccion.numeroTarjeta),
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 182,
            ApCtxOrden: 8,
            ApCamClase: 3,
            ApCamEti: "NO. AUTORIZACIÓN",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "N",
            ValorTXT: numeroDeAutorizacion,
            ValorNumerico: "0.00",
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 199,
            ApCtxOrden: 9,
            ApCamClase: 3,
            ApCamEti: "MONTO DÓLARES",
            ApCamTipo: "A",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "N",
            ValorTXT:  dataTransaccion.moneda === "320" ? "0.00" : `${redondear(dataTransaccion.monto)}`,
            ValorNumerico:  dataTransaccion.moneda === "320" ? "0.00" : `${redondear(dataTransaccion.monto)}`,
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
          {
            ApCamCod: 13,
            ApCtxOrden: 10,
            ApCamClase: 5,
            ApCamEti: "TOTAL TRANSACCION",
            ApCamTipo: "D",
            ApPResCod: 0,
            ApPResDes: "",
            ApCamModifica: "",
            ValorTXT: "",
            ValorNumerico:  dataTransaccion.moneda === "320" ? redondear(dataTransaccion.monto) : `${redondear(Number(dataTransaccion.monto)*8)}` ,
            ValorCuenta: "",
            ValorFecha: "0000-00-00",
            ValorDocumento: "0",
          },
        ],
        Rutinas: [
          {
            ApRutxTrx: 13,
            ApRutxDes: "ACTUALIZAR TOTALES DE CAJERO",
            ApRutSec: 1,
            ApRutxTip: "A",
          },
          {
            ApRutxTrx: 506,
            ApRutxDes: "RUTINA DEPOSITO TARJETA DEBITO",
            ApRutSec: 2,
            ApRutxTip: "I",
          },
        ],
      },
    };

    
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://proxy.mycoop.io/https://app.sifco.org/fondos/rest/WSTransaccionesCaja",
      headers: {
        Authorization:
          `OAuth ${jwt}`,
         "GENEXUS-AGENT": "SmartDevice Application",
        "Content-Type": "application/json",
    
      },
      data: data,
    };

    // const data = new FormData();
    // data.append("GENEXUS-AGENT", "SmartDevice Application");

    // const headers = {
    //   "authorization":
    //     "OAuth 9c0405ce-c668-4826-8889-050a65b41ebf!cbb6a438713857bdc938e6ceeb26831a07aa5c3c9e79e5269a5f8da92446575061e94a98734fbe",
    //   "content-type": "application/json",
    //   "genexus-agent": " SmartDevice Application",
    // };

    // const config = {
    //   method: "get",
    //   maxBodyLength: Infinity,
    //   url: "https://test.sifco.org/democoopgt/rest/WSApUsuariosSistemaLista",
    //   headers: {
    //     "authorization":
    //     "OAuth 9c0405ce-c668-4826-8889-050a65b41ebf!cbb6a438713857bdc938e6ceeb26831a07aa5c3c9e79e5269a5f8da92446575061e94a98734fbe",
    //   "content-type": "application/json",
    //   "genexus-agent": " SmartDevice Application"
    //   },
    // };

    try {
      const response = await axios(config);
      console.log(response);
      return response;
    } catch (err) {
      console.error(err);
    }

    // const apiUrl = 'https://proxy.mycoop.io/https://test.sifco.org/fondos/rest/TClClientes/26500';

    // const myHeaders = new Headers();
    // myHeaders.append("authorization", "OAuth 9c0405ce-c668-4826-8889-050a65b41ebf!c678441d9416ee5cb154c7daa0497b187f0e87e28c205bdacbe7b7b867f2cc3ca573bf79c7699b");
    // myHeaders.append("genexus-agent", "SmartDevice Application");
    // myHeaders.append("Cookie", "ASP.NET_SessionId=wiuljxte4jtbvgntnwxd0cas");

    // fetch(apiUrl, {
    //   method: 'GET',
    //   headers: myHeaders,
    //   redirect: 'follow',
    // })
    //   .then(response => response.text())
    //   .then(result => console.log(result))
    //   .catch(error => console.log('error', error));
  }
}
