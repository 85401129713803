import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppPreAprobacionService {
  async findByPage(pageNumber, descripcion) {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/apppreaprobacion/page/${descripcion || 0}?page=${pageNumber}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/apppreaprobacion/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findById(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/apppreaprobacion/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/apppreaprobacion/all`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async delete(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.delete(
        api.baseUrl() + `/apppreaprobacion/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
