import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
//import {printer} from 'printer';

//const printer = require("printer")
export const EncabezadoLibreta = ({ ahorro }) => {
  const [dialogImpresion, setDialogImpresion] = useState(false);


  // const imprimirTexto = (texto) => {
  //   const printerName = 'Tally Dascom 1330'; // Reemplaza 'nombre_impresora' con el nombre de tu impresora matricial
  //   printer.printDirect({
  //     data: texto,
  //     printer: printerName,
  //     type: 'RAW',
  //     success: function (jobID) {
  //       console.log('Impresión exitosa. ID del trabajo: ' + jobID);
  //     },
  //     error: function (err) {
  //       console.log('Error en la impresión: ' + err);
  //     },
  //   });
  // };
    const buttonImpresion = () =>{

            return(
                <>
        <Button className="mr-2 p-button-danger" label="Cancelar" icon="pi pi-times"/>

        <Button label="Imprimir" icon="pi pi-print"
        
       // onClick={()=>imprimirTexto("Impresion de Libreta")}
        
        />
                </>
            )

    }

  return (
    <>
      <div className="card">
        <h5>Impresion de Libreta</h5>
        <div className="p-formgrid p-grid p-fluid">
          <div className="p-col-7">
            <strong>No.Cuenta: </strong>
            {ahorro?.ahoId}
          </div>
          <div className="p-col-2" style={{ textAlign: "right" }}>
            <strong>Tipo de Ahorro: </strong>
          </div>
          <div className="p-col-3">
            {ahorro?.appAhoSubProducto?.subAhoNombre}
          </div>
          <div className="p-col-7">
            <strong>Nombre </strong>
            {ahorro?.ahoNombre}
          </div>
          <div className="p-col-2" style={{ textAlign: "right" }}>
            <strong>Moneda: </strong>
          </div>
          <div className="p-col-3">
            {ahorro?.appAhoSubProducto?.appMoneda?.monNombre}
          </div>
          <div className="p-col-7">
            <strong>Codigo: </strong>
            {ahorro?.asoId}
          </div>
          <div className="p-col-12">
            <strong>Direccion: </strong>
            {ahorro?.cliDireccion?.dirDireccion},{" "}
            {ahorro?.cliDireccion?.appMunicipio?.munNombre},{" "}
            {ahorro?.cliDireccion?.appDepartamento?.depNombre},{" "}
            {ahorro?.cliDireccion?.appPais?.paiNombre}
          </div>
        </div>
      </div>
      <div style={{ textAlign: "right" }}>
        <Button
          label="Impesion"
          style={{ width: "150px" }}
          icon="pi pi-print"
          onClick={() => setDialogImpresion(true)}
        />
      </div>
      <Dialog
        header="Impresion de encabezado de libreta"
        visible={dialogImpresion}
        onHide={() => setDialogImpresion(false)}
        style={{ width: "50vh" }}
        footer={buttonImpresion}
      >
        <div className="card ">
          <div className="p-d-flex p-ai-center p-jc-center">
            <i
              className="pi pi-exclamation-triangle p-mr-3"
              style={{ fontSize: "2rem" }}
            />
            <span>Confirme si desea reimprimir el encabezado.</span>
          </div>
        </div>
      </Dialog>
    </>
  );
};
