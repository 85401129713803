export default function dateTimeNowNormal() {
    const event = new Date();
    var mm = event.getMonth() + 1;
    var dd = event.getDate();
    var yy = event.getFullYear();
    var hh = event.getHours();
    var min = event.getMinutes();
    var ss = event.getMinutes();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    if(min< 10){
      min ="0" + min
    }
  
    if(ss <10){
      ss="0" +ss
    }
    var myDateString =
    dd + "/" + mm + "/" + yy + " " + hh + ":" + min + ":" + ss ;
  
    return myDateString;
  }
  