import React, { useState, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import AppDisponibilidadProvenienciaService from "../../../../service/AppServices/AppDisponibilidadProvenienciaService";

import { CamposLiquidez } from "../CamposLiquidez";

export const Proveniencias = ({
  gruDisponibilidadId,
  disFecha,
  sucId,
  conTransaccionLiquidez,
  gruDisponibilidad,
}) => {
  const [appDisponibilidadProveniencias, setAppDisponibilidadProvencias] =
    useState([]);

  useEffect(() => {
    // console.log("sillega", gruDisponibilidadId, disFecha, sucId);
    const appDisponibilidadProvenienciaService =
      new AppDisponibilidadProvenienciaService();
    appDisponibilidadProvenienciaService
      .findAllByGroup(gruDisponibilidadId)
      .then((response) => {
        setAppDisponibilidadProvencias(response);
        console.log(response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conTransaccionLiquidez]);

  const renderProvenciencias = () => (
    <Accordion>
      {appDisponibilidadProveniencias.map((item) => (
        <AccordionTab
          header={item.disNombre}
          key={item.disId}
          disabled={
            (sucId !== 1 && item.disId === 4) ||
            (sucId !== 1 && item.disId === 3)
          }
        >
          <CamposLiquidez
            gruDisponibilidadId={gruDisponibilidadId}
            gruDisponibilidad={gruDisponibilidad}
            disId={item.disId}
            proveniencia={item}
            disFecha={disFecha}
            sucId={sucId}
            key={item.gruDisponibilidadId}
            conTransaccionLiquidez={conTransaccionLiquidez}
          />
        </AccordionTab>
      ))}
    </Accordion>
  );

  return <div>{renderProvenciencias()}</div>;
};
