import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import classNames from "classnames";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";

//Import components
import { TabView, TabPanel } from "primereact/tabview";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { AutoComplete } from "primereact/autocomplete";
import { locale } from "primereact/api";
import { addLocale } from "primereact/api";
import { Link } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";

// Import Services
import AppSexoService from "../../service/AppServices/AppSexoService";
import AppTipoPersonaService from "../../service/AppServices/AppTipoPersonaService";
import AppEstadoCivilService from "../../service/AppServices/AppEstadoCivilService";
import AppTipoIdentificacionService from "../../service/AppServices/AppTipoIdentificacionService";
import AppActividadEconomicaService from "../../service/AppServices/AppActividadEconomicaService";
import AppProfesionOficioService from "../../service/AppServices/AppProfesionOficioService";
import AppCondicionMigratoriaService from "../../service/AppServices/AppCondicionMigratoriaService";
import AppMediosComunicacionService from "../../service/AppServices/AppMediosComunicacionService";
import AppSucursalService from "../../service/AppServices/AppSucursalService";

import CliAsociadoService from "../../service/CliServices/CliAsociadoService";
import CliAsociadoDireccionComponent from "../ClientesPages/CliAsociadoDireccionComponent";
import CliAsociadoContactoComponent from "../ClientesPages/CliAsociadoContactoComponent";
import CliAsociadoReferenciaPersonalComponent from "../ClientesPages/CliAsociadoReferenciaPersonalComponent";
import CliAsociadoPerfilEconomicoComponent from "../ClientesPages/CliAsociadoPerfilEconomicoComponent";
import CliAsociadoDatosDigitalesComponent from "../ClientesPages/CliAsociadoDatosDigitalesComponent";
import CliCampoAdicionalFieldset from "../ClientesPages/CliCampoAdicionalFieldset";
import CliAsociadoUploadImage from "../ClientesPages/CliAsociadoUploadImage";
import { CliAsociadoCuentasBan } from "./CliAsociadoCuentasBan";

export const CliAsociadoEditar = () => {
  const toast = useRef(null);

  let emptyAsociado = {
    actEcoId: 0,
    appActividadEconomica: {
      actEcoDescripcion: "",
      actEcoId: 0,
    },
    appCondicionMigratoria: {
      conMigDescripcion: "",
      conMigId: 0,
    },
    appEstadoCivil: {
      estCivDescripcion: "",
      estCivId: 0,
    },
    appMediosComunicacion: {
      medComDescripcion: "",
      medComId: 0,
      medComLogo: "",
    },
    appProfesionOficio: {
      ofiDescripcion: "",
      ofiId: 0,
    },
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
    },
    appTipoIdentificacion: {
      ideTipoDescripcion: "",
      ideTipoFormato: "",
      ideTipoId: 0,
    },
    appTipoPersona: {
      perTipoDescripcion: "",
      perTipoId: 0,
    },
    asoActuaNombrePropio: 0,
    asoAfectoImpuesto: 0,
    asoApellidoCasada: "",
    asoCPE: 0,
    asoCalidadActua: "",
    asoEmpleado: 0,
    asoEstado: "",
    asoFechaConstitucion: "",
    asoFechaInactivacion: "",
    asoFechaIngreso: "",
    asoFechaModificacion: "",
    asoFechaNacimiento: "",
    asoFechaVenceIdentificacion: "",
    asoFirma: "",
    asoFoto: "",
    asoId: 0,
    asoIdReferencia: "",
    asoIdRepresentante: "",
    asoListaNegra: 0,
    asoListaNegraReferencia: "",
    asoMensajeAdvertencia: "",
    asoNit: "",
    asoNombreJuridico: "",
    asoNumDocIdentificacion: "",
    asoOtraCondicionMigra: "",
    asoOtroNombre: "",
    asoPrimerApellido: "",
    asoPrimerNombre: "",
    asoPropositoRelacion: "",
    asoRegistroMercantil: "",
    asoRepresentanteLegal: "",
    asoSegundoApellido: "",
    asoSegundoNombre: "",
    asoUsaBancoCoop: "",
    conMigId: 0,
    estCivId: 0,
    ideTipoId: 0,
    medComId: 0,
    ofiId: 0,
    perTipoId: 0,
    sexId: 0,
    sucId: 0,
    usuUsuarioIngresa: "",
    usuUsuarioModifica: "",
    usuUsuarioPromotor: "",
  };

  let emptyAppSexo = {
    sexId: 0,
    sexDescripcion: "",
  };

  let emptyAppTipoPersona = {
    perTipoId: 0,
    perTipoDescripcion: "",
  };

  let emptyAppEstadoCivil = {
    estCivId: 0,
    estCivDescripcion: "",
  };

  let emptyAppTipoIdentificacion = {
    ideTipoId: 0,
    ideTipoDescripcion: "",
    ideTipoFormato: "",
  };

  let emptyAppProfesionOficio = {
    ofiDescripcion: "",
    ofiId: 0,
  };

  let emptyAppCondicionMigratoria = {
    conMigDescripcion: "",
    conMigId: 0,
  };

  let emptyAppMediosComunicacion = {
    medComDescripcion: "",
    medComId: 0,
    medComLogo: "",
  };

  let emptyAppSucursal = {
    appEmpresa: {
      empGerente: "",
      empId: 0,
      empNombre: "",
    },
    sucDireccion: "",
    sucEmpId: 0,
    sucGerente: "",
    sucId: 0,
    sucNombre: "",
  };

  const [asociadoImageDialog, setAsociadoImageDialog] = useState(false);

  const editAndViewImageDialog = (asociado) => {
    setAsociadoImageDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setAsociadoImageDialog(false);
  };

  const [submitted, setSubmitted] = useState(false);

  const [asociado, setAsociado] = useState(emptyAsociado);

  const [appSucursales, setAppSucursales] = useState(emptyAppSucursal);

  const [editAsociado, setEditAsociado] = useState(false);

  const [appSexos, setAppSexos] = useState(emptyAppSexo);

  const [appTipoPersonas, setAppTipoPersonas] = useState(emptyAppTipoPersona);

  const [appEstadosCiviles, setAppEstadosCiviles] =
    useState(emptyAppEstadoCivil);

  const [appActividadEconomicas, setAppActividadEconomicas] = useState(
    emptyAppTipoIdentificacion
  );

  const [appTipoIdentificaciones, setAppTipoIdentificaciones] = useState(
    emptyAppTipoIdentificacion
  );

  const [appProfesionOficios, setAppProfesionOficios] = useState(
    emptyAppProfesionOficio
  );

  const [appCondicionMigratorias, setAppCondicionMigratorias] = useState(
    emptyAppCondicionMigratoria
  );

  const [appMediosComunicaciones, setAppMediosComunicaciones] = useState(
    emptyAppMediosComunicacion
  );

  const [dropdownItemRelacionAsociado, setDropdownItemRelacionAsociado] =
    useState(null);
  const dropdownItemsRelacionAsociados = [
    { name: "ASOCIADO", code: 1 },
    { name: "CLIENTE REMESAS", code: 2 },
    { name: "EMPLEADO", code: 3 },
    { name: "DIRECTIVO", code: 4 },
  ];

  const [autoFilteredActividadEconomica, setAutoFilteredActividadEconomica] =
    useState([]);
  const [autoFilteredProfesionOficio, setAutoFilteredProfesionOficio] =
    useState([]);

  const searchActividadEconomica = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        setAutoFilteredActividadEconomica([...appActividadEconomicas]);
      } else {
        setAutoFilteredActividadEconomica(
          appActividadEconomicas.filter((actividadEconomica) => {
            return actividadEconomica.actEcoDescripcion
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          })
        );
      }
    }, 250);
  };

  const searchAppProfesionOficio = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        setAutoFilteredProfesionOficio([...appProfesionOficios]);
      } else {
        setAutoFilteredProfesionOficio(
          appProfesionOficios.filter((profesionOficio) => {
            return profesionOficio.ofiDescripcion
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          })
        );
      }
    }, 250);
  };

  useEffect(() => {
    const appSucursalService = new AppSucursalService();
    appSucursalService.findAll().then((data) => {
      setAppSucursales(data);
    });
  }, []);

  useEffect(() => {
    const appSexoService = new AppSexoService();
    appSexoService.findAll().then((data) => {
      setAppSexos(data);
    });
  }, []);

  useEffect(() => {
    const appTipoPersonaService = new AppTipoPersonaService();
    appTipoPersonaService.findAll().then((data) => {
      setAppTipoPersonas(data.data);
    });
  }, []);

  useEffect(() => {
    const appEstadoCivilService = new AppEstadoCivilService();
    appEstadoCivilService.findAll().then((data) => {
      setAppEstadosCiviles(data.data);
    });
  }, []);

  useEffect(() => {
    const appTipoIdentificacionService = new AppTipoIdentificacionService();
    appTipoIdentificacionService.findAll().then((data) => {
      setAppTipoIdentificaciones(data.data);
    });
  }, []);

  useEffect(() => {
    const appActividadEconomicaService = new AppActividadEconomicaService();
    appActividadEconomicaService.findAll().then((data) => {
      setAppActividadEconomicas(data);
    });
  }, []);

  useEffect(() => {
    const appProfesionOficioService = new AppProfesionOficioService();
    appProfesionOficioService.findAll().then((data) => {
      setAppProfesionOficios(data);
    });
  }, []);

  useEffect(() => {
    const appCondicionMigratoriaService = new AppCondicionMigratoriaService();
    appCondicionMigratoriaService.findAll().then((data) => {
      setAppCondicionMigratorias(data);
    });
  }, []);

  useEffect(() => {
    const appMediosComunicacionService = new AppMediosComunicacionService();
    appMediosComunicacionService.findAll().then((data) => {
      setAppMediosComunicaciones(data);
    });
  }, []);

  useEffect(() => {
    var aso = JSON.parse(sessionStorage.getItem("aso"));
    setAsociado(aso.asoId !== 0 ? aso : emptyAsociado);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (asociado?.asoId < "0" || asociado?.asoId < 0) {
      setEditAsociado(false);
    }
  }, [asociado?.asoId]);

  useEffect(() => {
    if (asociado?.asoId !== "0") {
      setEditAsociado(true);
    }
  }, [asociado?.asoId]);

  useEffect(() => {}, [asociado]);

  const token = localStorage.getItem("user");
  function cargarSuc() {
    var suc = JSON.parse(token).usr.appSucursal;
    if (asociado.appSucursal.sucId === 0) {
      let _asociado = { ...asociado };
      _asociado[`${"appSucursal"}`] = suc;
      setAsociado(_asociado);

      //Set simple data
      _asociado[`${"sucId"}`] = suc.sucId;
      setAsociado(_asociado);
    }
  }
  cargarSuc();

  const onInputChangeObjectEsEmpleado = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    setDropdownItemRelacionAsociado(val);

    let _asociado = { ...asociado };
    //Set simple data
    _asociado[`${name2}`] = val1;
    setAsociado(_asociado);
  };

  useEffect(() => {
    if (asociado.asoId === 0) {
    } else {
      var item = dropdownItemsRelacionAsociados.find(
        (item) => item.code === asociado.asoEmpleado
      );
      setDropdownItemRelacionAsociado(item);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [asociado.asoEmpleado]);

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Claro",
  });

  locale("es");

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _asociado = { ...asociado };
    _asociado[`${name}`] = val;
    setAsociado(_asociado);
  };

  const onInputChangesimpleData = (val, name) => {
    let _asociado = { ...asociado };
    _asociado[`${name}`] = val;
    setAsociado(_asociado);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _asociado = { ...asociado };
    _asociado[`${name}`] = val;
    setAsociado(_asociado);

    //Set simple data
    _asociado[`${name2}`] = val1;
    setAsociado(_asociado);
  };

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  let a = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];
  let s = join(new Date(), a, "-");

  const saveAsociado = (asociado) => {
    // event.preventDefault();
    //Si el tipo de persona es NATURAL
    if (asociado.perTipoId === 1) {
      setSubmitted(true);
      if (
        asociado.sucId &&
        asociado.perTipoId &&
        asociado.asoPrimerNombre.trim() !== "" &&
        asociado.asoPrimerApellido.trim() !== "" &&
        asociado.asoFechaNacimiento &&
        asociado.asoPropositoRelacion.trim() !== "" &&
        asociado.sexId &&
        asociado.estCivId &&
        asociado.ofiId &&
        asociado.ideTipoId &&
        asociado.asoNumDocIdentificacion.trim() !== "" &&
        asociado.asoFechaVenceIdentificacion &&
        asociado.asoNit.trim() !== "" &&
        asociado.actEcoId &&
        asociado.asoEmpleado &&
        asociado.medComId
      ) {
        const cliAsociadoService = new CliAsociadoService();
        var user = JSON.parse(token).usr.usuUsuario;

        let _asociado = { ...asociado };
        if (asociado.asoId === 0) {
          _asociado[`${"asoFechaIngreso"}`] = s;
          _asociado[`${"usuUsuarioIngresa"}`] = user;
          _asociado[`${"usuUsuarioPromotor"}`] = "cdsay";
          _asociado[`${"asoEstado"}`] = "A";
        } else {
          _asociado[`${"asoFechaIngreso"}`] = s;
          _asociado[`${"usuUsuarioIngresa"}`] = user;
          _asociado[`${"asoFechaModificacion"}`] = s;
          _asociado[`${"usuUsuarioModifica"}`] = user;
          _asociado[`${"usuUsuarioPromotor"}`] = user;
          _asociado[`${"asoEstado"}`] = "A";
        }
        cliAsociadoService.save(_asociado).then((newAsociado) => {
          sessionStorage.setItem("aso",JSON.stringify(newAsociado))
          setAsociado(newAsociado);
          setEditAsociado(true);
          toast.current.show({
            severity: "success",
            summary: "¡Exitoso!",
            detail:
              "Datos Guardados: " +
              (asociado?.asoNombreJuridico === null
                ? ""
                : asociado.asoNombreJuridico) +
              " " +
              asociado.asoPrimerNombre +
              " " +
              asociado.asoSegundoNombre +
              " " +
              (asociado.asoOtroNombre === null ? "" : asociado.asoOtroNombre) +
              " " +
              asociado.asoPrimerApellido +
              " " +
              asociado.asoSegundoApellido +
              " " +
              (asociado.asoApellidoCadasa == null
                ? ""
                : asociado.asoApellidoCadasa),
            life: 3000,
          });
        });
      }
    }
    //Si el tipo de persona es juridico
    else if (asociado.perTipoId === 2) {
      setSubmitted(true);
      if (
        asociado.perTipoId &&
        asociado.asoNombreJuridico.trim() !== "" &&
        asociado.asoRepresentanteLegal.trim() !== "" &&
        asociado.asoRegistroMercantil.trim() !== "" &&
        asociado.asoPropositoRelacion.trim() !== "" &&
        asociado.asoNit.trim() !== "" &&
        asociado.asoFechaConstitucion &&
        asociado.actEcoId &&
        asociado.asoEmpleado &&
        asociado.ofiId &&
        asociado.medComId
      ) {
        const cliAsociadoService = new CliAsociadoService();

        let _asociado = { ...asociado };
        if (asociado.asoId === 0) {
          _asociado[`${"asoFechaIngreso"}`] = s;
          _asociado[`${"usuUsuarioIngresa"}`] = user;
          _asociado[`${"usuUsuarioPromotor"}`] = user;
          _asociado[`${"asoEstado"}`] = "A";
        } else {
          _asociado[`${"asoFechaModificacion"}`] = s;
          _asociado[`${"usuUsuarioModifica"}`] = user;
        }
        cliAsociadoService.save(_asociado).then((newAsociado) => {
          sessionStorage.setItem("aso",JSON.stringify(newAsociado))
          setAsociado(newAsociado);
          setEditAsociado(true);
          toast.current.show({
            severity: "success",
            summary: "¡Exitoso!",
            detail: "Datos Guardados: " + asociado.asoNombreJuridico,
            life: 3000,
          });
        });
      }
    }
  };

  const [asociadoExiste, setAsociadoExiste] = useState(emptyAsociado);

  //Buscar asociado por numero de documento de identificacion
  const findByNumIdentificacion = (asoNumDocIdentificacion) => {
    const cliAsociadoService = new CliAsociadoService();

    cliAsociadoService
      .findAsociadoByNumDocIdentificacion(asoNumDocIdentificacion)
      .then((data) => {
        if (data) {
          setAsociadoExiste(data);
        } else {
          setAsociadoExiste(emptyAsociado);
        }
      });
  };

  //Me coorrige la fecha a la fecha correcta sin restar un dia
  let fechaNacimiento = new Date(asociado.asoFechaNacimiento.toString());
  fechaNacimiento.setMinutes(
    fechaNacimiento.getMinutes() + fechaNacimiento?.getTimezoneOffset()
  );

  let fechaConstitucion = new Date(asociado?.asoFechaConstitucion);
  fechaConstitucion.setMinutes(
    fechaConstitucion.getMinutes() + fechaConstitucion.getTimezoneOffset()
  );

  let fechaVenceIdentificacion = new Date(
    asociado?.asoFechaVenceIdentificacion.toString()
  );
  fechaVenceIdentificacion.setMinutes(
    fechaVenceIdentificacion.getMinutes() +
      fechaVenceIdentificacion.getTimezoneOffset()
  );

  function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Foto"
          icon="pi pi-cloud-upload"
          className="p-button-rounded p-button-warning"
          onClick={() => editAndViewImageDialog()}
        />
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    );
  };


  return (
    <div className="p-grid" disabled="disabled">
      <div className="p-col-12 p-md-12" disabled="disabled">
        <div className="p-col-12 p-md-12">
          {/* <form onSubmit={(e) => saveAsociado(e, asociado)}> */}
          <div className="card">
            <Toolbar
              left={
                <h5>
                  {asociado?.asoId} -{"  "}
                  {asociado?.asoNombreJuridico === null
                    ? asociado?.asoPrimerNombre.trim()
                    : asociado?.asoNombreJuridico}{" "}
                  {asociado?.asoSegundoNombre} {asociado?.asoOtroNombre}{" "}
                  {asociado?.asoPrimerApellido} {asociado?.asoSegundoApellido}{" "}
                  {asociado?.asoApellidoCasada}{" "}
                </h5>
              }
              right={rightToolbarTemplate}
            ></Toolbar>
            <Toast ref={toast} />

            <TabView>
              <TabPanel header="General">
                {/* Inicio Card */}
                <div className="p-col-12 p-md-12">
                  <div
                    className="card p-fluid"
                    style={{ border: "1px solid #BDBDBD" }}
                  >
                    <h5>Datos del Asociado</h5>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-2">
                        <label htmlFor="asoId">Código Asociado</label>
                        <InputText
                          id="asoId"
                          type="number"
                          value={asociado?.asoId || 0}
                          onChange={(e) => onInputChange(e, "asoId")}
                          // autoFocus={true}
                          // readOnly={true}
                        />
                      </div>
                      <div className="p-field p-col-3">
                        <label htmlFor="perTipoId">Tipo Persona</label>
                        <Dropdown
                          id="perTipoId"
                          name="perTipoId"
                          value={asociado?.appTipoPersona ||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appTipoPersona",
                              e.target.value.perTipoId,
                              "perTipoId"
                            );
                          }}
                          options={appTipoPersonas}
                          optionLabel="perTipoDescripcion"
                          placeholder="Selecciones un tipo de Persona"
                          disabled={
                            asociado.perTipoId === 0 ? editAsociado : true
                          }
                          className={classNames({
                            "p-invalid": submitted && !asociado.perTipoId,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.perTipoId && (
                          <small className="p-invalid">
                            *Tipo de persona es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col-3">
                        <label htmlFor="state">Sucursal</label>
                        <Dropdown
                          id="state"
                          value={asociado.appSucursal || ""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appSucursal",
                              e.target.value.sucId,
                              "sucId"
                            );
                          }}
                          options={appSucursales}
                          optionLabel="sucNombre"
                          placeholder="Selecciones una Sucursal..."
                          disabled={true}
                          className={classNames({
                            "p-invalid": submitted && !asociado.appSucursal,
                          })}
                        ></Dropdown>
                      </div>
                      <div className="p-field p-col-1">
                        <h6 style={{ marginTop: 0 }}>Estado Asociado</h6>
                        <InputSwitch
                          checked={asociado?.asoEstado === "I" ? false : true}
                          onChange={(e) => {
                            onInputChangesimpleData(
                              e.value === true ? "A" : "I",
                              "asoEstado"
                            );
                          }}
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col-1">
                        <Button
                          icon={
                            editAsociado === true
                              ? "pi pi-lock"
                              : "pi pi-lock-open"
                          }
                          onClick={() =>
                            editAsociado === false
                              ? setEditAsociado(true)
                              : setEditAsociado(false)
                          }
                          className="p-mr-2 p-mb-2"
                          tooltip="Editar datos"
                          type="button"
                        ></Button>
                      </div>
                      <div className="p-field p-col-2">
                        <img
                          src={asociado?.asoFoto}
                          width="110"
                          height="120"
                          alt="Foto asociado"
                          style={{
                            borderRadius: "10px",
                            border: "3px solid #575757",
                          }}
                        ></img>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div
                  className="p-col-12 p-md-12"
                  style={{
                    display: asociado?.perTipoId === 1 ? "block" : "none",
                  }}
                >
                  <div
                    className="card p-fluid"
                    style={{ border: "1px solid #BDBDBD" }}
                  >
                    <h5>Persona Individual</h5>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="sexId">Género</label>
                        <Dropdown
                          id="sexId"
                          name="sexId"
                          value={asociado?.appSexo || ""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appSexo",
                              e.target.value.sexId,
                              "sexId"
                            );
                          }}
                          options={appSexos}
                          optionLabel="sexDescripcion"
                          placeholder="Seleciones un Género"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.sexId,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.sexId && (
                          <small className="p-invalid">
                            Género es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="estCivId">Estado Cívil</label>
                        <Dropdown
                          id="estCivId"
                          name="estCivId"
                          value={asociado?.appEstadoCivil||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appEstadoCivil",
                              e.target.value.estCivId,
                              "estCivId"
                            );
                          }}
                          options={appEstadosCiviles}
                          optionLabel="estCivDescripcion"
                          placeholder="Selecciones un Estado Cívil"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.estCivId,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.estCivId && (
                          <small className="p-invalid">
                            Estado Cívil es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="asoFechaNacimiento">
                          Fecha Nacimiento
                        </label>
                        <Calendar
                          showIcon
                          showButtonBar
                          value={fechaNacimiento}
                          onChange={(e) => {
                            var newDate = toJSONLocal(e.value);
                            onInputChangesimpleData(
                              newDate,
                              "asoFechaNacimiento"
                            );
                          }}
                          disabled={editAsociado}
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1917:2500"
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoFechaNacimiento,
                          })}
                        ></Calendar>
                        {submitted && !asociado.asoFechaNacimiento && (
                          <small className="p-invalid">
                            Fecha de nacimiento es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="state">Tipo identificación</label>
                        <Dropdown
                          id="state"
                          value={asociado?.appTipoIdentificacion || ""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appTipoIdentificacion",
                              e.target.value.ideTipoId,
                              "ideTipoId"
                            );
                          }}
                          options={appTipoIdentificaciones}
                          optionLabel="ideTipoDescripcion"
                          placeholder="Selececcione tipo de Identificación..."
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.ideTipoId,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.ideTipoId && (
                          <small className="p-invalid">
                            Tipo de identificación es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="inputmask">
                          Número de Identificación
                        </label>
                        {asociado?.appTipoIdentificacion?.ideTipoId === 3 ? (
                          <InputText
                            value={asociado?.asoNumDocIdentificacion ||""}
                            onChange={(e) =>
                              onInputChange(e, "asoNumDocIdentificacion")
                            }
                            onBlur={(e) =>
                              // console.log('DPI ASOCIADO', e.target.value)
                              findByNumIdentificacion(e.target.value)
                            }
                            disabled={editAsociado}
                            className={classNames({
                              "p-invalid":
                                submitted && !asociado.asoNumDocIdentificacion,
                            })}
                          />
                        ) : (
                          <InputMask
                            id="inputmask"
                            mask={
                              asociado?.appTipoIdentificacion?.ideTipoFormato.trim() ||
                              "9999 99999 9999"
                            }
                            value={asociado?.asoNumDocIdentificacion ||""}
                            onChange={(e) =>
                              onInputChange(e, "asoNumDocIdentificacion")
                            }
                            onBlur={(e) =>
                              // console.log('DPI ASOCIADO', e.target.value)
                              findByNumIdentificacion(e.target.value)
                            }
                            disabled={editAsociado}
                            className={classNames({
                              "p-invalid":
                                submitted && !asociado.asoNumDocIdentificacion,
                            })}
                          ></InputMask>
                        )}

                        {submitted && !asociado.asoNumDocIdentificacion && (
                          <small className="p-invalid">
                            Número de identificación es requerido.
                          </small>
                        )}
                        {asociado.asoId === 0 &&
                        asociadoExiste.asoNumDocIdentificacion !== "" ? (
                          <>
                            <div className="flex align-items-center">
                              <Tooltip target=".tooltip-button" autoHide={true}>
                                <div className="flex align-items-center">
                                  <span>{asociadoExiste.asoId}</span>
                                  <br></br>
                                  <span>
                                    {asociadoExiste.asoPrimerNombre}
                                  </span>{" "}
                                  <span>{asociadoExiste.asoSegundoNombre}</span>{" "}
                                  <span>
                                    {asociadoExiste.asoPrimerApellido}
                                  </span>
                                </div>
                              </Tooltip>

                              <Link
                                className="tooltip-button ml-2"
                                to={{
                                  pathname: "/cliasociadoeditarExiste",
                                  state: {
                                    asociado: asociadoExiste,
                                  },
                                }}
                              >
                                <small className="p-invalid">
                                  Ya existe un asociado con la misma
                                  identificación click para ver.
                                </small>
                              </Link>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="inputmask">
                          Fecha Vencimiento Identificación
                        </label>
                        <Calendar
                          showIcon
                          showButtonBar
                          value={fechaVenceIdentificacion}
                          onChange={(e) => {
                            var newDate = toJSONLocal(e.value);
                            onInputChangesimpleData(
                              newDate,
                              "asoFechaVenceIdentificacion"
                            );
                          }}
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1917:2500"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted &&
                              !asociado.asoFechaVenceIdentificacion,
                          })}
                        ></Calendar>
                        {submitted && !asociado.asoFechaVenceIdentificacion && (
                          <small className="p-invalid">
                            Fecha vencimiento de identificación es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="name2">Primer Nombre</label>
                        <InputText
                          id="name2"
                          type="text"
                          value={asociado?.asoPrimerNombre ||""}
                          onChange={(e) => onInputChange(e, "asoPrimerNombre")}
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.asoPrimerNombre,
                          })}
                        />
                        {submitted && !asociado.asoPrimerNombre && (
                          <small className="p-invalid">
                            Primer Nombre es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="email2">Segundo Nombre</label>
                        <InputText
                          id="email2"
                          type="text"
                          value={asociado?.asoSegundoNombre ||""}
                          onChange={(e) => onInputChange(e, "asoSegundoNombre")}
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="email2">Otro Nombre</label>
                        <InputText
                          id="email2"
                          type="text"
                          value={asociado?.asoOtroNombre||""}
                          onChange={(e) => onInputChange(e, "asoOtroNombre")}
                          disabled={editAsociado}
                        />
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="name2">Primer Apellido</label>
                        <InputText
                          id="name2"
                          type="text"
                          value={asociado?.asoPrimerApellido||""}
                          onChange={(e) =>
                            onInputChange(e, "asoPrimerApellido")
                          }
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoPrimerApellido,
                          })}
                        />
                        {submitted && !asociado.asoprimerApellido && (
                          <small className="p-invalid">
                            Primer Apellido es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="email2">Segundo Apellido</label>
                        <InputText
                          id="email2"
                          type="text"
                          value={asociado?.asoSegundoApellido||""}
                          onChange={(e) =>
                            onInputChange(e, "asoSegundoApellido")
                          }
                          disabled={editAsociado}
                        />
                      </div>
                      <div
                        className="p-field p-col"
                        style={
                          asociado?.appSexo?.sexId === 2 &&
                          asociado?.appEstadoCivil?.estCivId === 2
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label htmlFor="email2">Apellido Casada</label>
                        <InputText
                          id="email2"
                          type="text"
                          value={asociado?.asoApellidoCasada||""}
                          onChange={(e) =>
                            onInputChange(e, "asoApellidoCasada")
                          }
                          disabled={editAsociado}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div
                  className="p-col-12 p-md-12"
                  style={
                    asociado?.perTipoId === 2
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <div
                    className="card p-fluid"
                    style={{ border: "1px solid #BDBDBD" }}
                  >
                    <h5>Persona Jurídica</h5>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="asoNombreJuridico">
                          Nombre Jurídico
                        </label>
                        <InputText
                          id="asoNombreJuridico"
                          name="asoNombreJuridico"
                          type="text"
                          value={asociado?.asoNombreJuridico||""}
                          onChange={(e) =>
                            onInputChange(e, "asoNombreJuridico")
                          }
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoNombreJuridico,
                          })}
                        />
                        {submitted && !asociado.asoNombreJuridico && (
                          <small className="p-invalid">
                            Nombre Jurídico es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="asoRepresentanteLegal">
                          Representante Legal
                        </label>
                        <InputText
                          id="asoRepresentanteLegal"
                          name="asoRepresentanteLegal"
                          type="text"
                          tooltip="Nombre de la persona quien representa a la Empresa"
                          value={asociado?.asoRepresentanteLegal||""}
                          onChange={(e) =>
                            onInputChange(e, "asoRepresentanteLegal")
                          }
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoRepresentanteLegal,
                          })}
                        />
                        {submitted && !asociado.asoRepresentanteLegal && (
                          <small className="p-invalid">
                            Nombre del Representante Legal es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="asoRegistroMercantil">
                          Registro Mercantil
                        </label>
                        <InputText
                          id="asoRegistroMercantil"
                          name="asoRegistroMercantil"
                          type="text"
                          value={asociado?.asoRegistroMercantil||""}
                          onChange={(e) =>
                            onInputChange(e, "asoRegistroMercantil")
                          }
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="asoFechaConstitucion">
                          Fecha Constitución
                        </label>
                        <Calendar
                          id="asoFechaConstitucion"
                          name="asoFechaConstitucion"
                          tooltip="Fecha en la que incio la empresa"
                          showIcon
                          showButtonBar
                          value={fechaConstitucion||""}
                          onChange={(e) => {
                            var newDate = toJSONLocal(e.value);
                            onInputChangesimpleData(
                              newDate,
                              "asoFechaConstitucion"
                            );
                          }}
                          dateFormat="dd/mm/yy"
                          monthNavigator
                          yearNavigator
                          yearRange="1917:2500"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoFechaConstitucion,
                          })}
                        ></Calendar>
                        {submitted && !asociado.asoFechaConstitucion && (
                          <small className="p-invalid">
                            Fecha Constitucion es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div className="p-col-12 p-md-12">
                  <div
                    className="card p-fluid"
                    style={{ border: "1px solid #BDBDBD" }}
                  >
                    <h5>Actua en Nombre Propio</h5>

                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-3">
                        <h6 style={{ marginTop: 0 }}>Actua en Nombre Propio</h6>
                        <InputSwitch
                          checked={
                            asociado?.asoActuaNombrePropio === 1 ? true : false
                          }
                          onChange={(e) => {
                            onInputChangesimpleData(
                              e.value === true ? 1 : 0,
                              "asoActuaNombrePropio"
                            );
                          }}
                          disabled={editAsociado}
                        />
                      </div>
                      <div
                        className="p-field p-col-2"
                        style={
                          asociado?.asoActuaNombrePropio === 1
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <label htmlFor="asoIdrepresentante">
                          Código Representante
                        </label>
                        <InputText
                          id="asoIdrepresentante"
                          type="text"
                          tooltip=""
                          value={asociado?.asoIdRepresentante||""}
                          onChange={(e) =>
                            onInputChange(e, "asoIdRepresentante")
                          }
                          disabled={editAsociado}
                        />
                      </div>
                      <div
                        className="p-field p-col"
                        style={
                          asociado?.asoActuaNombrePropio === 1
                            ? { display: "none" }
                            : { display: "block" }
                        }
                      >
                        <label htmlFor="asoCalidadActua">
                          Calidad en la que Actua el Representante
                        </label>
                        <InputText
                          id="asoCalidadActua"
                          type="text"
                          tooltip=""
                          value={asociado?.asoCalidadActua||""}
                          onChange={(e) => onInputChange(e, "asoCalidadActua")}
                          disabled={editAsociado}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div className="p-col-12 p-md-12">
                  <div
                    className="card p-fluid"
                    style={{ border: "1px solid #BDBDBD" }}
                  >
                    <h5>Condiciones Migratorias</h5>

                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="state">Condición Migratoria</label>
                        <Dropdown
                          id="state"
                          value={asociado?.appCondicionMigratoria||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appCondicionMigratoria",
                              e.target.value.conMigId,
                              "conMigId"
                            );
                          }}
                          options={appCondicionMigratorias}
                          optionLabel="conMigDescripcion"
                          placeholder="Seleccione..."
                          disabled={editAsociado}
                        ></Dropdown>
                      </div>
                      <div
                        className="p-field p-col"
                        style={
                          asociado?.appCondicionMigratoria?.conMigId === 8
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <label htmlFor="appPropositoRelacion">
                          Especifique Otra
                        </label>
                        <InputText
                          id="appPropositoRelacion"
                          type="text"
                          tooltip="Descripción de otra condición migratoria"
                          value={asociado?.asoOtraCondicionMigra||""}
                          onChange={(e) =>
                            onInputChange(e, "asoOtraCondicionMigra")
                          }
                          disabled={editAsociado}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div className="p-col-12 p-md-12">
                  <div
                    className="card p-fluid"
                    style={{ border: " 1px solid #BDBDBD" }}
                  >
                    <h5>Otros Datos</h5>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-2">
                        <label htmlFor="nit">NIT</label>
                        <InputText
                          id="nit"
                          type="text"
                          tooltip="Número de Identificación tributaria (NIT)"
                          value={asociado?.asoNit||""}
                          onChange={(e) => onInputChange(e, "asoNit")}
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.asoNit,
                          })}
                        />
                        {submitted && !asociado.asoNit && (
                          <small className="p-invalid">
                            Nit (Número de Identificación Tributaria) es
                            requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="aspPropositoRelacion">
                          Proposito Relación
                        </label>
                        <InputText
                          id="aspPropositoRelacion"
                          type="text"
                          tooltip=""
                          value={asociado?.asoPropositoRelacion||""}
                          onChange={(e) =>
                            onInputChange(e, "asoPropositoRelacion")
                          }
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid":
                              submitted && !asociado.asoPropositoRelacion,
                          })}
                        />
                        {submitted && !asociado.asoPropositoRelacion && (
                          <small className="p-invalid">
                            Propósito de la relación es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="state">Profesión u Oficio</label>
                        <AutoComplete
                          placeholder="Search"
                          id="dd"
                          dropdown
                          // multiple
                          value={asociado?.appProfesionOficio||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appProfesionOficio",
                              e.target.value.ofiId,
                              "ofiId"
                            );
                          }}
                          suggestions={autoFilteredProfesionOficio}
                          completeMethod={searchAppProfesionOficio}
                          field="ofiDescripcion"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.ofiId,
                          })}
                        />
                        {submitted && !asociado.ofiId && (
                          <small className="p-invalid">
                            Profesión u Oficio es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="state">Actividad Económica</label>
                        <AutoComplete
                          placeholder="Search"
                          id="dd"
                          dropdown
                          // multiple
                          value={asociado?.appActividadEconomica||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appActividadEconomica",
                              e.target.value.actEcoId,
                              "actEcoId"
                            );
                          }}
                          suggestions={autoFilteredActividadEconomica}
                          completeMethod={searchActividadEconomica}
                          field="actEcoDescripcion"
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.actEcoId,
                          })}
                        />
                        {submitted && !asociado.actEcoId && (
                          <small className="p-invalid">
                            Actividad Económica es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="state">Relación Asociado</label>
                        <Dropdown
                          id="state"
                          value={dropdownItemRelacionAsociado||""}
                          onChange={(e) => {
                            onInputChangeObjectEsEmpleado(
                              e,
                              "apprelacionPersona",
                              e.target.value.code,
                              "asoEmpleado"
                            );
                          }}
                          options={dropdownItemsRelacionAsociados}
                          optionLabel="name"
                          placeholder="Seleccione..."
                          disabled={editAsociado}
                          className={classNames({
                            "p-invalid": submitted && !asociado.asoEmpleado,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.asoEmpleado && (
                          <small className="p-invalid">
                            Relaciónm del asociado es requerido.
                          </small>
                        )}
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-3">
                        <h6 style={{ marginTop: 0 }}>Afecto a impuesto</h6>
                        <InputSwitch
                          checked={
                            asociado?.asoAfectoImpuesto === 1 ? true : false
                          }
                          onChange={(e) => {
                            onInputChangesimpleData(
                              e.value === true ? 1 : 0,
                              "asoAfectoImpuesto"
                            );
                          }}
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col-3">
                        <h6 style={{ marginTop: 0 }}>Usará Bancos Coop</h6>
                        <InputSwitch
                          checked={
                            asociado?.asoUsaBancoCoop === 1 ? true : false
                          }
                          onChange={(e) => {
                            onInputChangesimpleData(
                              e.value === true ? 1 : 0,
                              "asoUsaBancoCoop"
                            );
                          }}
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col-3">
                        <h6 style={{ marginTop: 0 }}>Asociado CPE</h6>
                        <InputSwitch
                          checked={asociado?.asoCPE === 1 ? true : false}
                          onChange={(e) => {
                            onInputChangesimpleData(
                              e.value === true ? 1 : 0,
                              "asoCPE"
                            );
                          }}
                          disabled={editAsociado}
                        />
                      </div>
                      <div className="p-field p-col-2">
                        <label htmlFor="state">
                          Como se Entero de la Coope
                        </label>
                        <Dropdown
                          id="state"
                          value={asociado?.appMediosComunicacion||""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appMediosComunicacion",
                              e.target.value.medComId,
                              "medComId"
                            );
                          }}
                          options={appMediosComunicaciones}
                          optionLabel="medComDescripcion"
                          placeholder="Seleccione..."
                          className={classNames({
                            "p-invalid": submitted && !asociado.medComId,
                          })}
                        ></Dropdown>
                        {submitted && !asociado.medComId && (
                          <small className="p-invalid">
                            Como se entero de la cooperativa es requerido.
                          </small>
                        )}
                      </div>
                      <div className="p-field p-col-1">
                        <label>
                          <img
                            src={asociado?.appMediosComunicacion?.medComLogo}
                            alt={
                              asociado?.appMediosComunicacion?.medComDescripcion
                            }
                            style={{ verticalAlign: "middle" }}
                            width={"80%"}
                            height={"80%"}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
                {/* Inicio Card */}
                <div className="p-col-12 p-md-12">
                  <div
                    className="card p-fluid"
                    style={{ border: " 1px solid #BDBDBD" }}
                  >
                    <h5>{""}</h5>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-6">
                        <Button
                          label="CANCELAR"
                          icon="pi pi-bookmark"
                          className="p-mr-2 p-mb-2 p-button-danger"
                          onClick={() => setEditAsociado(true)}
                          disabled={editAsociado}
                        ></Button>
                      </div>
                      <div className="p-field p-col-6">
                        <Button
                          label="GUARDAR"
                          icon="pi pi-bookmark"
                          className="p-mr-2 p-mb-2 p-button-secondary"
                          type="button"
                          onClick={() => saveAsociado(asociado)}
                          disabled={editAsociado}
                        ></Button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Fin Card */}
              </TabPanel>
              <TabPanel header="Direcciones">
                {/* add code HTMl */}
                <CliAsociadoDireccionComponent asociado={asociado} />
              </TabPanel>
              <TabPanel header="Contactos">
                {/* add code HTMl */}
                <CliAsociadoContactoComponent asociado={asociado} />
              </TabPanel>
              <TabPanel header="Cuentas Bancarias">
                {/* add code HTMl */}
                <CliAsociadoCuentasBan asociado={asociado} slect={"N"} />
              </TabPanel>
              <TabPanel header="Referencias Personales">
                {/* add code HTMl */}
                <CliAsociadoReferenciaPersonalComponent
                  asociado={asociado}
                ></CliAsociadoReferenciaPersonalComponent>
              </TabPanel>
              <TabPanel header="Perfil Económico">
                <CliAsociadoPerfilEconomicoComponent
                  asociado={asociado}
                ></CliAsociadoPerfilEconomicoComponent>
                {/* add code HTMl */}
              </TabPanel>
              <TabPanel header="Perfil Transaccional">
                {/* add code HTMl */}
              </TabPanel>
              <TabPanel header="Datos Digitales">
                {/* add code HTMl */}
                <CliAsociadoDatosDigitalesComponent
                  asociado={asociado}
                ></CliAsociadoDatosDigitalesComponent>
              </TabPanel>
              <TabPanel header="Campos Adicionales">
                {/* add code HTMl */}
                <CliCampoAdicionalFieldset
                  asociado={asociado}
                ></CliCampoAdicionalFieldset>
              </TabPanel>
            </TabView>
          </div>
          {/* </form> */}
          <Dialog
            visible={asociadoImageDialog}
            onHide={hideDialog}
            header={"Fotografia Asociado"}
            style={{ width: "51vw", backgroundColor: "#575757" }}
            className
          >
            {
              <CliAsociadoUploadImage
                asociado={asociado}
                setAsociado={setAsociado}
                asociadoImageDialog={asociadoImageDialog}
                setAsociadoImageDialog={setAsociadoImageDialog}
              />
            }
          </Dialog>
        </div>
      </div>
    </div>
  );
};
