import React, { useState, useEffect, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
//funciones
import dateNow from "../../../../utilities/dateNow";
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import onChajeObjN from "../../../../utilities/onChajeObjN";

//Services
import AppConfigService from "../../../../service/AppServices/AppConfigService";
import AppCuentaBancoService from "../../../../service/AppServices/AppCuentaBancoService";
import AhoCuentaService from "../../../../service/AhoCuentaService/AhoCuentaService";
import AhoMovimientoDiarioService from "../../../../service/AhoCuentaService/AhoMovimientoDiarioService";

//Componentes
import { CliAsociadoCuentasBan } from "../../../ClientesPages/CliAsociadoCuentasBan";
import classNames from "classnames";
export const BackOffie = ({ ahorro, setAhorro, toast }) => {
  const message = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const data = JSON.parse(sessionStorage.getItem("fechamodaho"));
  let movDiEmpty = {
    appTransaccion: null,
    movAhoBanco: "N",
    movAhoCajaId: null,
    movAhoCtaBanId: null,
    movAhoDocumento: 0,
    movAhoEstado: "S",
    movAhoFechaSistema: dateNow(),
    movAhoHora: "",
    movAhoId: ahorro?.ahoId,
    movAhoImpAcumulado: 0,
    movAhoImpMes: 0,
    movAhoIntAcumulado: 0,
    movAhoIntMes: 0,
    movAhoInterno: "S",
    movAhoMonto: 0,
    movAhoReferencia: "",
    movAhoUsuIngresa: user?.usr.usuUsuario,
    movDiarioId: 0,
    movFechaTransaccion: data?.modFechaHoy,
    movSucId: user?.usr.sucId,
    movTrxId: 0,
    ahoMovimientoDiarioBanco: null,
  };

  let movDiaBancEmpty = {
    movDIaBanCtaBanCli: null,
    movDiaBanComprobante: null,
    movDiaBanCtaBanId: 0,
    movDiaBanId: 0,
    movDiaBanNombreCheque: ahorro?.ahoNombre,
    movDiaBanTipMov: 0,
    cuenta: "",
  };
  const [movBan, setMovBan] = useState(movDiaBancEmpty);
  const [backOffice, setBackOffice] = useState(movDiEmpty);
  const [cuentaCli, setCuentaCli] = useState(false);
  const [ajuste, setAjuste] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [ctaBancarias, setCtaBancarias] = useState([]);
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    const appConfigService = new AppConfigService();
    appConfigService.findAll().then((res) => {
      try {
        var options = [
          {
            id: res[0]?.configAhoRet,
            nombre: "Retiro",
            abre: "R",
          },
          {
            id: res[0]?.configAhoDep,
            nombre: "Deposito",
            abre: "D",
          },
        ];
        setAjuste(options);
      } catch (error) {}
    });

    const appCuentaBancoService = new AppCuentaBancoService();
    appCuentaBancoService.findSucId(user?.usr.sucId).then((res) => {
      var options = [];
     
      if (res?.length > 0) {
        res.forEach((element) => {
          var elemento = {
            banco: `${element?.appBanco.banNombre}- ` + element?.ctaBanNumero,
            id: element?.ctaBanId,
          };
          options.push(elemento);
        });
        setCtaBancarias(options);
      } else {
        setCtaBancarias(options);
      }
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  function BackOffieForm() {
    return (
      <>
        <div className="p-grid">
          <div className="p-col-12 p-md-4">
            <label>Tipo de movimiento</label>
            <Dropdown
              value={backOffice.options}
              options={ajuste}
              placeholder="Seleccione..."
              optionLabel="nombre"
              onChange={(e) => 
               { onChangeObjDropDawn(
                  e,
                  "options",
                  e.target.value.id,
                  "movTrxId",
                  backOffice,
                  setBackOffice
                );
                setMovBan(movDiaBancEmpty)}
              }
              className={classNames({
                "p-invalid": submitted && !backOffice?.movTrxId,
              })}
            />
            {submitted && !backOffice?.movTrxId && (
              <small className="p-invalid">Seleccione un movimiento</small>
            )}
          </div>
          <div className="p-col-12 p-md-4">
            <label>Es movimiento bancario</label>
            <SelectButton
              value={backOffice.movAhoBanco}
              options={[
                { name: "No", value: "N" },
                { name: "Si", value: "S" },
              ]}
              onChange={(e) =>
                onChajeObjN(e, "movAhoBanco", backOffice, setBackOffice)
              }
              optionLabel="name"
              className={classNames({
                "p-invalid": submitted && !backOffice?.movAhoBanco,
              })}
            />
            {submitted && !backOffice?.movAhoBanco && (
              <small className="p-invalid">Seleccione si afecta bancos</small>
            )}
          </div>
          <div className="p-col-12 p-md-4">
            <label>Monto</label>
            <span className="p-input-icon-left">
              <i style={{ fontStyle: "normal" }}>
                <b>Q. </b>{" "}
              </i>
              <InputText
                type={"number"}
                value={backOffice?.movAhoMonto}
                onChange={(e) =>
                  onChajeObjN(e, "movAhoMonto", backOffice, setBackOffice)
                }
                className={classNames({
                  "p-invalid": submitted && !backOffice?.movAhoMonto,
                })}
              />
              {submitted && !backOffice?.movAhoMonto && (
                <small className="p-invalid">Ingrese un monto</small>
              )}
            </span>
          </div>

          {backOffice.movAhoBanco === "S" ? MovBancario() : ""}
          <div className="p-col-12">
            <label>Referencia</label>
            <InputTextarea
              placeholder="Por deposito ..."
              value={backOffice?.movAhoReferencia}
              rows={3}
              onChange={(e) =>
                onChajeObjN(e, "movAhoReferencia", backOffice, setBackOffice)
              }
              className={classNames({
                "p-invalid": submitted && !backOffice?.movAhoReferencia,
              })}
            />
            {submitted && !backOffice?.movAhoReferencia && (
              <small className="p-invalid">Ingrese una referencia</small>
            )}
            <Messages ref={message} />
          </div>

          <div className="p-col-12" style={{ textAlign: "right" }}>
            <Button
              icon="pi pi-check"
              className="p-button-sm"
              style={{ width: "150px" }}
              label="Aceptar"
              onClick={() => save()}
            />
          </div>
        </div>
      </>
    );
  }

  function MovBancario() {
    return (
      <>
        <div className="p-col-12 p-md-4">
          <label>Movimiento bancario</label>
          <Dropdown
            value={movBan?.movDiaBanTipMov}
            placeholder="Seleccione..."
            options={
              backOffice?.options?.abre === "R"
                ? [
                    {
                      value: 1,
                      label: "Cheque",
                    },
                    {
                      value: 2,
                      label: "Nota de debito",
                    },
                  ]
                : [
                    {
                      value: 3,
                      label: "Deposito en el banco",
                    },
                    {
                      value: 4,
                      label: "Nota de credito",
                    },
                  ]
            }
            onChange={(e) =>
              setMovBan({
                ...movBan,
                banco: null,
                movDiaBanTipMov: e.value,
                movDIaBanCtaBanCli: null,
                movDiaBanComprobante: null,
                movDiaBanCtaBanId: 0,
                movDiaBanId: 0,
                movDiaBanNombreCheque: ahorro?.ahoNombre,
              })
            }
            className={classNames({
              "p-invalid":
                submitted &&
                !movBan?.movDiaBanTipMov &&
                backOffice.movAhoBanco === "S",
            })}
          />{" "}
          {submitted &&
            !movBan?.movDiaBanTipMov &&
            backOffice.movAhoBanco === "S" && (
              <small className="p-invalid">Seleccione un movimiento</small>
            )}
        </div>
        <div className="p-col-12 p-md-4">
          <label>Cuenta bancaria</label>
          <Dropdown
            value={movBan?.banco}
            options={ctaBancarias}
            placeholder="Selecione..."
            optionLabel="banco"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "banco",
                e.target.value.id,
                "movDiaBanCtaBanId",
                movBan,
                setMovBan
              )
            }
            className={classNames({
              "p-invalid":
                submitted &&
                !movBan?.movDiaBanCtaBanId &&
                backOffice.movAhoBanco === "S",
            })}
          />{" "}
          {submitted &&
            !movBan?.movDiaBanCtaBanId &&
            backOffice.movAhoBanco === "S" && (
              <small className="p-invalid">Seleccione un banco</small>
            )}
        </div>
        <div className="p-col-12 p-md-4">{campoBanco()}</div>
      </>
    );
  }
  // function onChangeMovDia(camp, camp2, val1, val2) {
  //   const _backOffice = { ...backOffice };
  //   _backOffice.ahoMovimientoDiarioBanco[`${camp}`] = val1;
  //   _backOffice.ahoMovimientoDiarioBanco[`${camp2}`] = val2;
  //   console.log(_backOffice);
  //   setBackOffice(_backOffice);

  // }

  function campoBanco() {
    switch (movBan?.movDiaBanTipMov) {
      case 1:
        return (
          <>
            <label> Nombre </label>
            <InputText
              value={movBan?.movDiaBanNombreCheque}
              onChange={(e) =>
                onChajeObjN(e, "movDiaBanNombreCheque", movBan, setMovBan)
              }
              className={classNames({
                "p-invalid":
                  submitted &&
                  !movBan?.movDiaBanNombreCheque &&
                  backOffice.movAhoBanco === "S" &&
                  movBan?.movDiaBanTipMov === 1,
              })}
            />
            {submitted &&
              !movBan?.movDiaBanNombreCheque &&
              backOffice.movAhoBanco === "S" &&
              movBan?.movDiaBanTipMov === 1 && (
                <small className="p-invalid">Ingrese un nombre</small>
              )}
          </>
        );
      case 2:
        return (
          <>
            <label>Cuenta Bancaria</label>
            <div className="p-inputgroup">
              <InputText
                disabled={true}
                value={movBan?.cuenta}
                className={classNames({
                  "p-invalid":
                    submitted &&
                    !movBan?.cuenta &&
                    backOffice.movAhoBanco === "S" &&
                    movBan?.movDiaBanTipMov === 2,
                })}
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setCuentaCli(true)}
              />
            </div>
            {submitted &&
              !movBan?.cuenta &&
              backOffice.movAhoBanco === "S" &&
              movBan?.movDiaBanTipMov === 2 && (
                <small className="p-invalid">Seleccione una cuentas</small>
              )}
          </>
        );
      case 3:
        return (
          <>
            <label>No.Boletea</label>
            <InputText
              type={"number"}
              value={movBan.movDiaBanComprobante}
              onChange={(e) =>
                onChajeObjN(e, "movDiaBanComprobante", movBan, setMovBan)
              }
              className={classNames({
                "p-invalid":
                  submitted &&
                  !movBan?.movDiaBanComprobante &&
                  backOffice.movAhoBanco === "S" &&
                  movBan?.movDiaBanTipMov === 3,
              })}
            />
            {submitted &&
              !movBan?.movDiaBanComprobante &&
              backOffice.movAhoBanco === "S" &&
              movBan?.movDiaBanTipMov === 3 && (
                <small className="p-invalid">Ingrese el numero de boleta</small>
              )}
          </>
        );
      case 4:
        return (
          <>
            <label>No.Nota</label>
            <InputText
              type={"number"}
              value={movBan.movDiaBanComprobante}
              onChange={(e) =>
                onChajeObjN(e, "movDiaBanComprobante", movBan, setMovBan)
              }
              className={classNames({
                "p-invalid":
                  submitted &&
                  !movBan?.movDiaBanComprobante &&
                  backOffice.movAhoBanco === "S" &&
                  movBan?.movDiaBanTipMov === 4,
              })}
            />
            {submitted &&
              !movBan?.movDiaBanComprobante &&
              backOffice.movAhoBanco === "S" &&
              movBan?.movDiaBanTipMov === 4 && (
                <small className="p-invalid">Ingrese el numero de nota</small>
              )}
          </>
        );
      default:
        return (
          <>
            <label>Boleta</label>
            <InputText
              type={"number"}
              disabled={true}
              className={classNames({
                "p-invalid":
                  submitted &&
                  !movBan?.movDiaBanTipMov &&
                  backOffice.movAhoBanco === "S",
              })}
              placeholder="ingrese..."
            />
            {submitted &&
              !movBan?.movDiaBanTipMov &&
              backOffice.movAhoBanco === "S" && (
                <small className="p-invalid">Ingrese un numero de boleta</small>
              )}
          </>
        );
    }
  }

  function buttons() {
    return (
      <>
        <Button
          className="p-button p-button-danger "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => setCuentaCli(false)}
        />
      </>
    );
  }

  function save() {
    if (
      backOffice.movAhoBanco === "S" &&
      backOffice.movAhoBanco &&
      backOffice.movAhoReferencia !== "" &&
      backOffice.movTrxId &&
      backOffice?.movAhoMonto &&
      movBan?.movDiaBanTipMov &&
      movBan?.movDiaBanCtaBanId !== 0 &&
      backOffice?.movAhoMonto &&
      movBan?.movDiaBanTipMov &&
      movBan?.movDiaBanCtaBanId !== ""
    ) {
      const _backOffice = { ...backOffice };
      const _movBan = { ...movBan };
      if (
        movBan?.movDiaBanTipMov === 1 &&
        movBan?.movDiaBanNombreCheque !== ""
      ) {
        _backOffice["ahoMovimientoDiarioBanco"] = _movBan;
        setConfirm(true);
        setBackOffice(_backOffice);
      } else if (
        movBan?.movDiaBanTipMov === 2 &&
        movBan?.movDIaBanCtaBanCli !== 0 &&
        movBan?.movDIaBanCtaBanCli !== ""
      ) {
        _movBan["movDiaBanNombreCheque"] = null;
        _backOffice["ahoMovimientoDiarioBanco"] = _movBan;
        setConfirm(true);
        setBackOffice(_backOffice);
      } else if (
        (movBan?.movDiaBanTipMov === 3 &&
          movBan.movDiaBanComprobante !== 0 &&
          movBan.movDiaBanComprobante !== "") ||
        (movBan?.movDiaBanTipMov === 4 &&
          movBan.movDiaBanComprobante !== 0 &&
          movBan.movDiaBanComprobante !== "")
      ) {
        _movBan["movDiaBanNombreCheque"] = null;
        _backOffice["ahoMovimientoDiarioBanco"] = _movBan;
        setConfirm(true);
        setBackOffice(_backOffice);
      } else {
        mensaje();
      }
    } else if (
      backOffice.movAhoBanco === "N" &&
      backOffice.movAhoBanco &&
      backOffice.movAhoReferencia !== "" &&
      backOffice.movTrxId &&
      backOffice?.movAhoMonto !== 0 &&
      backOffice?.movAhoMonto !== ""
    ) {
      setBackOffice(backOffice);
      setConfirm(true);
    } else {
      mensaje();
    }
  }

  function onSave() {
    const ahoMovimientoService = new AhoMovimientoDiarioService();
    ahoMovimientoService.saveBackoffice(backOffice).then((res) => {
      if (res?.code === 201) {
        toast.current.show({
          severity: "success",
          summary: `${res?.message}`,
          detail: `${res?.description}`,
          life: 4000,
        });
        const ahoService = new AhoCuentaService();
        ahoService.findById(ahorro.ahoId).then((res) => {
          setAhorro(res);
          sessionStorage.setItem("Ahorro", JSON.stringify(res));
        });

        setMovBan(movDiaBancEmpty);
        setBackOffice(movDiEmpty);
      } else {
        toast.current.show({
          severity: "error",
          summary: `${res?.message}`,
          detail: `${res?.description}`,
          life: 4000,
        });
      }
    });
  }

  function mensaje() {
    setSubmitted(true);
    message.current.show({
      severity: "warn",
      summary: "error",
      content: "Verifique que los campos necesarios no esten vacios",
      life: 4000,
    });
    setInterval(() => setSubmitted(false), 4000);
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => setConfirm(false)}
      />
      <Button
        icon="pi pi-check"
        label="Si"
        onClick={() => {setConfirm(false);onSave()}}
      />
    </>
  );

  return (
    <>
      <h5>BackOffice</h5>
      <div className="card"> {BackOffieForm()}</div>

      <Dialog
        visible={cuentaCli}
        onHide={() => setCuentaCli(false)}
        header="Cuentas del Cliente"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "75vw" }}
        footer={buttons}
      >
        <CliAsociadoCuentasBan
          ahorro={ahorro}
          movBan={movBan}
          setMovBan={setMovBan}
          setCuentaCli={setCuentaCli}
          slect={"S"}
        />
      </Dialog>

      <Dialog
        header="Mensaje del sistema"
        visible={confirm}
        onHide={() => setConfirm(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea realizar la transaccion.</span>
        </div>
      </Dialog>
    </>
  );
};
