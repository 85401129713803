import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Column } from "primereact/column";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//servicios
import AppPreSubProductoService from "../../../../service/AppServices/AppPreSubProductoService";
import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
//Componentes
import { Pagination } from "../../../../components/Pagination"; 
import { NuevoSubProducto } from "./NuevoSubProducto";

//funciones
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import onChajeObjN from "../../../../utilities/onChajeObjN";
import formatMiles from "../../../../utilities/formatMiles";

export const SubProducto = () => {
  const message = useRef();
  const toast = useRef();
  let filtroEmpty = {
    nombre: "",
    option: {
      proId: 0,
      proNombre: "TODOS",
    },
    proId: 0,
  };

  let subProductoEmpty = {
    appFrecuencia: {
      freDias: 0,
      freId: 0,
      freMeses: 0,
      freNombre: "",
    },
    appMoneda: {
      monAlfanumerico: "",
      monId: 0,
      monNombre: "",
      monSimbolo: "",
    },
    appProducto: {
      appTipoProducto: {
        proTipoId: 0,
        proTipoNombre: "",
      },
      proId: 0,
      proNombre: "",
      proTipoId: 0,
    },
    appTasa: {
      appTasaRangoList: [
        {
          tasId: 0,
          tasRangoFechaVigencia: "",
          tasRangoId: 0,
          tasRangoMontoFinal: 0,
          tasRangoMontoInicial: 0,
          tasRangoPorcentaje: 0,
        },
      ],
      appTipoTasa: {
        tasTipoId: 0,
        tasTipoNombre: "",
      },
      tasFechaIngreso: "",
      tasId: 0,
      tasNombre: "",
      tasTipoId: 0,
      tasUnica: "",
    },
    preSubAplicaDeduccion: 1,
    appTasaMora: null,
    preSubCobraMora: true,
    preSubDescripcion: "",
    preSubDiasGracias: 0,
    preSubDiasSuspencion: 0,
    preSubFormaMora: 0,
    preSubFormaTasa: 0,
    preSubFreId: 0,
    preSubId: 0,
    preSubMonId: 0,
    preSubMontoMax: 0,
    preSubMoraId: null,
    preSubPlazoMax: 0,
    preSubProId: 0,
    preSubProvision: 0,
    preSubTasaId: 0,
    preSubTasaMax: 0,
    preSubTasaMin: 0,
    preSubTipoCalculo: 0,
    preSubTipoMora: 0,
    preSubValorMulta: 0,
  };

  const [subProductos, setSubProductos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [productos, setProductos] = useState([]);
  const [nuevoSubProductos, setNuevoSubProductos] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [subProducto, setSubProducto] = useState(subProductoEmpty);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  useEffect(() => {
    const appProductoService = new AppTipoProductoService();
    appProductoService.findAllCre().then((response) => {
      response?.data?.push({
        proId: 0,
        proNombre: "TODOS",
      });
      setProductos(response?.data);
    });

    const preSubProductos = new AppPreSubProductoService();
    preSubProductos
      .findByPage(pageNumber, filtro.proId, filtro.nombre)
      .then((response) => {
        setSubProductos(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filtro.proId, estado]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const preSubProductos = new AppPreSubProductoService();
      preSubProductos
        .findByPage(pageNumber, filtro.proId, filtro.nombre)
        .then((response) => {
          setSubProductos(response?.content);
          setTotalPages(response?.totalPages);
          setPageNumber(response?.number);
          setFirstPage(response?.first);
          setLastPage(response?.last);
          if (filtro.nombre !== "") {
            setPageNumber(0);
          }
        });
    }
  };

  function left() {
    return (
      <div className="p-grid p-fluid" onKeyDown={(e) => fil(e)}>
        <div
          className="p-col "
          //   onKeyDown={(e) => fil(e)}
        >
          <span className="p-float-label p-mt-2">
            <InputText
              value={filtro.nombre}
              className="p-inputtext-sm"
              type="search"
              onChange={(e) => onChajeObjN(e, "nombre", filtro, setFiltro)}
            />
            <label>Nombre</label>
          </span>
        </div>
        <div
          className="p-col"
          // onKeyDown={(e) => fil(e)}
        >
          <span className="p-float-label p-mt-2">
            <Dropdown
              value={filtro.option}
              className="p-inputtext-sm"
              type="search"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "option",
                  e.target.value.proId,
                  "proId",
                  filtro,
                  setFiltro
                )
              }
              options={productos}
              optionLabel="proNombre"
            />
            <label>Producto</label>
          </span>
        </div>
      </div>
    );
  }

  function right() {
    return (
      <Button
        className="p-button-success p-mr-2 p-button-sm"
        icon="pi pi-plus"
        label="Nuevo"
        onClick={() => 
        {  setNuevoSubProductos(true);setSubProducto(subProductoEmpty)}
        }
      />
    );
  }

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  const acciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-eye"
          tooltip="Editar"
          onClick={() => {setSubProducto(rowData); setNuevoSubProductos(true)}}
        />
 
      </>
    );
  };

  function tasaMora(rowData) {
    return <>{rowData?.appTasaMora?.tasNombre || "NO APLICA"}</>;
  }

  function monto(rowData) {
    return <>{formatMiles(rowData?.preSubMontoMax)}</>;
  }

  function renderTable() {
    return (
      <>
        <DataTable
          value={subProductos}
          emptyMessage="No se encontraron sub productos para los prestamos"
          footer={renderPaginacion}
          scrollable={true}
        >
          <Column field="appProducto.proNombre" header="Producto" />
          <Column field="preSubDescripcion" header="Sub producto" />
          <Column field="appMoneda.monNombre" header="Tipo de moneda" />
          <Column field="appTasa.tasNombre" header="Tipo de Tasa" />
          <Column
            field="appTasaMora.tasNombre"
            header="Tipo de Tasa Mora"
            body={tasaMora}
          />
          <Column field="preSubMontoMax" header="Monto maximo" body={monto} />

          <Column field="preSubTasaMax" header="Tasa Maxima" />
          <Column body={acciones} header="Acciones" />
        </DataTable>
      </>
    );
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-mr-2 "
          onClick={() => setNuevoSubProductos(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save() {
    if (
      subProducto.preSubMonId &&
      subProducto.preSubProId &&
      subProducto.appFrecuencia &&
      subProducto.appTasa &&
      subProducto.preSubFormaTasa &&
      subProducto.preSubProvision &&
      subProducto.preSubTipoCalculo &&
      subProducto.preSubDescripcion &&
      subProducto.preSubMontoMax &&
      subProducto.preSubPlazoMax &&
      subProducto.preSubTasaMax &&
      subProducto.preSubAplicaDeduccion &&
      subProducto.preSubTasaMin !== 0 &&
      subProducto.preSubDescripcion &&
      subProducto.preSubMontoMax &&
      subProducto.preSubPlazoMax &&
      subProducto.preSubTasaMax &&
      subProducto.preSubTasaMin &&
      subProducto.preSubMonId &&
      subProducto.preSubProId &&
      subProducto.appFrecuencia &&
      subProducto.appTasa &&
      subProducto.preSubFormaTasa &&
      subProducto.preSubProvision &&
      subProducto.preSubAplicaDeduccion &&
      subProducto.preSubTipoCalculo !== ""
    ) {
      const preSubProductos = new AppPreSubProductoService();

      preSubProductos.save(subProducto).then(() => {
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: "Sub producto creado ",
          life: 4000,
        });
        setEstado(!estado);
        setNuevoSubProductos(false);
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }

  return (
    <div className="card">
      <Toolbar left={left} right={right} className="p-mb-4" />
      {renderTable()}

      <Dialog
        visible={nuevoSubProductos}
        header={subProducto.preSubId === 0 ?"Nuevo Sub Producto" : "Editar Sub Producto"}
        onHide={() => setNuevoSubProductos(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
     
      >
        <NuevoSubProducto
          productosutilities={productos}
          subProducto={subProducto}
          setSubProducto={setSubProducto}
          submitted={submitted}
          toast={toast}
          footer={footer()}
        />
        <Messages ref={message} />
      </Dialog>
      <Toast ref={toast} />

  
    </div>
  );
};
