import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppTasaService {
  async findAllAho() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/apptasa/all/2`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }
  async findAllCre() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/apptasa/all/1`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  //Roles no asignados al usuario
  async findByID(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/apptasa/${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/apptasa/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }
  async findByPage(pageNumber, name, idTipoTasa) {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/apptasa/page/${name || "0"}/${idTipoTasa || "0"}?page=${pageNumber}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
