import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//Componentes
import { NuevoTasaRango } from "./NuevoTasaRango";
//Servicios
import AppAhoTasaPlazoRangoService from "../../../../service/AppServices/AppAhoTasaPlazoRangoService";

export const TasaRango = ({ newProducto, setNuevoAhoProducto, toast }) => {
  const toastRango = useRef();

  let tasaRangoEmpty = {
    ahoPlaFinal: 0,
    ahoPlaId: 0,
    ahoPlaInicial: 0,
    ahoSubAhoId: 0,
    ahoTasId: 0,
  };

  const [tasaRango, setTasaRango] = useState(tasaRangoEmpty);
  const [tasaRangos, setTasaRangos] = useState([]);
  const [nuevoTasaRangos, setNuevoTasaRangos] = useState(false);
  const [estado, setEstado] = useState(false);
  const [eliminar, setEliminar] = useState(false);

  useEffect(() => {
    const appahoplazorangoService = new AppAhoTasaPlazoRangoService();
    appahoplazorangoService
      .findAllIdSubProducto(newProducto?.subAhoId)
      .then((response) => {
        setTasaRangos(response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  function nuevo() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => 
            {setNuevoTasaRangos(true); setTasaRango(tasaRangoEmpty)}
          }
        />
      </>
    );
  }

  //delete

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoTasaRangos(true); setTasaRango(rowData)}}
        />
        <Button
          className="p-button-danger p-button-rounded"
          icon="pi pi-trash"
          onClick={() => {setEliminar(true); setTasaRango(rowData)}}
        />
      </>
    );
  }

  function rederTabale() {
    return (
      <>
        <DataTable
          value={tasaRangos}
          emptyMessage={`No se encontro ningun rango de tasa `}
        >
          <Column field="ahoPlaInicial" header="Plazo Inicial" />
          <Column field="ahoPlaFinal" header="Plazo Final" />
          <Column field="appTasa.tasNombre" header="Tasa A Utilizar" />

          <Column
            header="Acciones"
            body={acciones}
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </>
    );
  }
  function borrar() {
   
    const appahoplazorangoService = new AppAhoTasaPlazoRangoService();
    appahoplazorangoService.delete(tasaRango?.ahoPlaId).then((response) => {
      setEstado(!estado);
      setEliminar(false);
      toastRango.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Tasa eliminada",
        life: 4000,
      });
    });
  }

  const elimarProducto = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={() => setEliminar(false)}
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        className="p-button-text"
        onClick={borrar}
      />
    </>
  );
  return (
    <>
      <div className="card">
        <Toolbar className="p-mb-4" left={nuevo} />
        {rederTabale()}
      </div>

      <Dialog
        visible={nuevoTasaRangos}
        onHide={() => {
          setNuevoTasaRangos(false);
          toastRango.current.show({
            severity: "warn",
            summary: "Alerta",
            detail: "Tasa no creada/actualizada",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header={"Nuevo Rango de Tasa"}
      >
        <NuevoTasaRango
          setNuevoTasaRangos={setNuevoTasaRangos}
          toastRango={toastRango}
          tasaRango={tasaRango}
          newProducto={newProducto}
          estado={estado}
          setEstado={setEstado}
        />
      </Dialog>

      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "120px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => {
            setNuevoAhoProducto(false);
            toast.current.show({
              severity: "warn",
              summary: "Error",
              detail: "Pestaña cerrada",
              life: 4000,
            })
          }}
        />
      </div>

      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Confirmar Eliminacion"
        modal
        footer={elimarProducto}
        onHide={() => setEliminar(false)}
      >
        <div className="confirmation-content">
          <i
            className="pi pi-exclamation-triangle mr-3"
            style={{ fontSize: "2rem" }}
          />

          <span style={{ marginLeft: "2rem" }}>
            No se eliminara si la tasa se esta utilizando
          </span>
        </div>
      </Dialog>

      <Toast ref={toastRango} />
    </>
  );
};
