import React, { useState, useEffect, useRef } from "react";
import { SelectButton } from "primereact/selectbutton";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";
import { Messages } from "primereact/messages";
import classNames from "classnames";
import selectText from "../../../../../utilities/selecText";

//service
import CliAsociadoService from "../../../../../service/CliServices/CliAsociadoService";
import AppParentescoService from "../../../../../service/AppServices/AppParentescoService";
import AhoBeneficiarioService from "../../../../../service/AhoCuentaService/AhoBeneficiarioService";
export const NuevoBeneficiario = ({
  ahorro,
  setEstado,
  beneficiarioHook,
  setNuevoBenificiario,
  totalPor,
  editar,
  toast,
  estado
}) => {
  const message = useRef();
  const [beneficiario, setBeneficiariio] = useState(beneficiarioHook);
  const [appParentesco, setAppParentesco] = useState([]);
  const [contacto, setContacto] = useState([]);
  const [direcciones, setDirecciones] = useState([]);
  const [value1, setValue1] = useState("Si");
  const [submitted, setSubmitted] = useState(false);
  const options = ["Si", "No"];

  useEffect(() => {
    const appParentesco = new AppParentescoService();
    appParentesco.findAll().then((response) => {
      setAppParentesco(response);
    });
  }, []);

  const onChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _beneficiario = { ...beneficiario };
    _beneficiario[`${name}`] = val;
    setBeneficiariio(_beneficiario);
  };

  function buttons() {
    return (
      <div  style={{textAlign:"right"}}>

          <Button
            label="Cancelar"
            icon="pi pi-times"
            className="p-mr-2 p-mb-2 p-button-danger"
            onClick={() => 
              {setNuevoBenificiario(false)
              toast.current.show({
                severity: "error",
                summary: "Accion cancelada",
                detail: "Benificiario no agregregado /editado",
                life: 4000,
              })
            }}
          />
    
          <Button
            label="Guardar"
            icon="pi pi-check"
            className="p-mr-2 p-mb-2 p-button-success"
            onClick={() => save()}
          />

      </div>
    );
  }
  function gfg() {
    setSubmitted(false);
  }

  function onSave() {
    const ahoBeneficiarioService = new AhoBeneficiarioService();
    let _beneficiario = { ...beneficiario };
    if (beneficiario.ahoId === "") {
      _beneficiario[`ahoId`] = ahorro?.ahoId;
    }
    if (
      _beneficiario.benDireccion !== "" &&
      _beneficiario.benNumeroIdentificacion !== "" &&
      _beneficiario.benPorcentaje !== 0 &&
      _beneficiario.benTelefono !== "" &&
      _beneficiario.benNombre !== ""
    ) {
      ahoBeneficiarioService.save(_beneficiario).then(() => {
        setEstado(!estado);
        setNuevoBenificiario(false);
        if (beneficiario?.ahoId === "") {
          toast.current.show({
            severity: "success",
            summary: "Accion realizada con exito",
            detail: "Benificiario agregado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Accion realizada con exito",
            detail: "Benificiario actualizado",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      setTimeout(gfg, 4000);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }
  function save() {
    if (totalPor + Number(beneficiario.benPorcentaje) <= 100) {
      onSave();
    } else {
      message.current.show({
        severity: "warn",
        summary: "alerta",
        content: "La suma de los porcentajes no debe ser mayor a 100",
        life: 4000,
      });
    }
  }

  function perBeneficiarioId(evt) {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (
      charCode === 9 &&
      beneficiario.asoId !== 0 &&
      beneficiario.asoId !== "" &&
      beneficiario.asoId !== null
    ) {
      const cliAsociadoService = new CliAsociadoService();
      cliAsociadoService
        .findByIdBeneficiario(beneficiario.asoId)
        .then((res) => {
          let _beneficiario = { ...beneficiario };
          _beneficiario[`benNombre`] = res?.name;
          _beneficiario[`benNumeroIdentificacion`] = res?.identification;
          _beneficiario[`asoId`] = res?.id;
          setBeneficiariio(_beneficiario);
          setDirecciones(res?.address);
          setContacto(res?.phones);
        });
    }
  }
  function onInputChangeObjet(e, name, value, key) {
    var val = (e.target && e.target.value) || "";
    let _beneficiario = { ...beneficiario };
    _beneficiario[`${name}`] = val;
    _beneficiario[`${key}`] = value;
    setBeneficiariio(_beneficiario);
  }

  function verPor() {
    if (totalPor + Number(beneficiario.benPorcentaje) > 100) {
      message.current.show({
        severity: "warn",
        summary: "alerta",
        content: "La suma de los porcentajes no debe ser mayor a 100",
        life: 4000,
      });
    }
  }
  return (
    <div>
      <div className="card">
        <div className="p-grid">
          <div className="p-fluid p-col" style={{ textAlign: "left" }}>
            <h5>Es Asociado? </h5>
          </div>
          <div className="p-fluid p-col-4" style={{ textAlign: "center" }}>
            <SelectButton
              value={value1}
              options={options}
              onChange={(e) => setValue1(e.value)}
              disabled={editar}
            />
          </div>
        </div>
        <div className="p-grid">
          <div
            className="p-fluid  p-col-12 p-lg-3 p-md-2"
            onKeyDown={(e) => perBeneficiarioId(e)}
          >
            <label>Codigo Cliente</label>
            <InputText
              id="asoId"
              type={"number"}
              disabled={value1 === "No" ? true : false || editar}
              value={beneficiario.asoId}
              onClick={(e) => selectText(e)}
              onChange={(e) => onChange(e, "asoId")}
            />
          </div>
          <div className="p-fluid  p-col-12 p-lg-5 p-md-6">
            <label>Nombre</label>
            <InputText
              id="nombre"
              type={"text"}
              required
              disabled={value1 === "Si" ? true : false}
              value={beneficiario.benNombre}
              onClick={(e) => selectText(e)}
              onChange={(e) => onChange(e, "benNombre")}
              className={classNames({
                "p-invalid": submitted && !beneficiario.benNombre,
              })}
            />
            {submitted && !beneficiario.benNombre && (
              <small className="p-invalid">Nombre requerido</small>
            )}
          </div>
          <div className="p-fluid  p-col-12 p-lg-4 p-md-4">
            <label>Número de Identificación</label>
            <InputMask
              id="dpi"
              mask="9999 99999 9999"
              required
              disabled={value1 === "Si" ? true : false}
              value={beneficiario.benNumeroIdentificacion}
              onClick={(e) => selectText(e)}
              onChange={(e) => onChange(e, "benNumeroIdentificacion")}
              className={classNames({
                "p-invalid": submitted && !beneficiario.benNumeroIdentificacion,
              })}
            />
            {submitted && !beneficiario.benNumeroIdentificacion && (
              <small className="p-invalid">
                Numero de identificacion requerido.
              </small>
            )}
          </div>
        </div>
        <div className="p-grid">
          <div
            className="p-fluid p-col-12"
            style={{ display: value1 === "Si" ? "none" : "" }}
          >
            <label>Direccion</label>
            <InputTextarea
              id="dirbem"
              rows={3}
              disabled={value1 === "Si" ? true : false}
              value={beneficiario.benDireccion}
              onClick={(e) => selectText(e)}
              onChange={(e) => onChange(e, "benDireccion")}
              className={classNames({
                "p-invalid": submitted && !beneficiario.benDireccion,
              })}
            />
            {submitted && !beneficiario.benDireccion && (
              <small className="p-invalid">Direccion requerida.</small>
            )}
          </div>

          <div
            className="p-fluid p-col-12"
            style={{ display: value1 === "Si" ? "" : "none" }}
          >
            <label>Direccion</label>
            <Dropdown
              id="dirbem"
              options={direcciones}
              value={beneficiario.benDireccion}
              onChange={(e) => onChange(e, "benDireccion")}
              editable
              className={classNames({
                "p-invalid": submitted && !beneficiario.benDireccion,
              })}
            />
            {submitted && !beneficiario.benDireccion && (
              <small className="p-invalid">Direccion requerida.</small>
            )}
          </div>
        </div>
        <div className="p-grid">
          <div
            className="p-fluid p-col-12 p-lg-3 pmd-3"
            style={{ display: value1 === "Si" ? "none" : "" }}
          >
            <label>Télefono/Correo</label>
            <InputText
              id="telefonoben"
              value={beneficiario.benTelefono}
              disabled={value1 === "Si" ? true : false}
              onChange={(e) => onChange(e, "benTelefono")}
              onClick={(e) => selectText(e)}
              className={classNames({
                "p-invalid": submitted && !beneficiario.benTelefono,
              })}
            />
            {submitted && !beneficiario.benDireccion && (
              <small className="p-invalid">Télefono/Correo requerido.</small>
            )}
          </div>
          <div
            className="p-fluid p-col-12 p-lg-3 pmd-3"
            style={{ display: value1 === "Si" ? "" : "none" }}
          >
            <label>Télefono/Correo</label>
            <Dropdown
              id="telefonoben"
              options={contacto}
              value={beneficiario.benTelefono}
              onChange={(e) => onChange(e, "benTelefono")}
              editable
              className={classNames({
                "p-invalid": submitted && !beneficiario.benTelefono,
              })}
            />
            {submitted && !beneficiario.benDireccion && (
              <small className="p-invalid">Télefono/Correo requerido.</small>
            )}
          </div>
          <div className="p-fluid p-col-12 p-lg-5 pmd-5">
            <label>Tipo de Parantesco</label>
            <Dropdown
              value={beneficiario.appParentesco}
              options={appParentesco}
              optionLabel="parNombre"
              onChange={(e) =>
                onInputChangeObjet(e, "appParentesco", e.value.parId, "parId")
              }
              className={classNames({
                "p-invalid": submitted && !beneficiario.parId,
              })}
            />
            {submitted && !beneficiario.parId && (
              <small className="p-invalid">
                Se necesita un parentesco con la persona.
              </small>
            )}
          </div>
          <div className="p-fluid p-col-12 p-lg-4 pmd-4">
            <label>Porcentaje que recibe</label>
            <span className="p-input-icon-right">
              <i> %</i>
              <InputText
                id="porben"
                value={beneficiario.benPorcentaje}
                onChange={(e) => onChange(e, "benPorcentaje")}
                onClick={(e) => selectText(e)}
                className={classNames({
                  "p-invalid": submitted && !beneficiario.benPorcentaje,
                })}
                onBlur={verPor}
              />
              {submitted && !beneficiario.parId && (
                <small className="p-invalid">
                  Se necesita el porcentaje que recibe la persona
                </small>
              )}
            </span>
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div className="">
   
        {buttons()}
      </div>
    </div>
  );
};
