import React, { useState, useEffect, useRef } from 'react'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toolbar } from 'primereact/toolbar'
import { Button } from 'primereact/button'
import { Toast } from 'primereact/toast'
import { Calendar } from 'primereact/calendar'

import AppSucursalService from '../../../service/AppServices/AppSucursalService'
import ConControlDisponibilidadService from '../../../service/ConContabilidad/ConControlDisponibilidadService'

export const LiquidezConsolidado = () => {
  const toast = useRef(null)

  const [headers, setHeaders] = useState([])
  const [conControlDisponibilidad, setConControlDisponibilidad] = useState([])
  const [conControlDisponibilidad2, setConControlDisponibilidad2] = useState([])
  const [conControlDisponibilidad3, setConControlDisponibilidad3] = useState([])
  const [conControlDisponibilidad4, setConControlDisponibilidad4] = useState([])
  const [conControlDisponibilidad5, setConControlDisponibilidad5] = useState([])
  const [disFecha, setDisFecha] = useState(
    new Date().toISOString().substring(0, 10),
  )

  console.log(new Date().toISOString().substring(0, 10))
  console.log(new Date().toISOString())
  const [selectedRow, setSelectedRow] = useState(null)

  //use efect para cargar los datos de la tabla de cada grupo
  useEffect(() => {
    const appSucursalService = new AppSucursalService()

    appSucursalService.findAllHeadres().then((response) => {
      setHeaders(response)
    })

    const conControlDisponibilidadService = new ConControlDisponibilidadService()
    conControlDisponibilidadService
      .availabilityDateGroup1(disFecha, 1)
      .then((response) => {
        setConControlDisponibilidad(response)
      })

    conControlDisponibilidadService
      .availabilityDate(disFecha, 2)
      .then((response) => {
        setConControlDisponibilidad2(response)
      })

    conControlDisponibilidadService
      .availabilityDate(disFecha, 3)
      .then((response) => {
        console.log(response)
        setConControlDisponibilidad3(response)
      })

    conControlDisponibilidadService
      .availabilityDate(disFecha, 4)
      .then((response) => {
        setConControlDisponibilidad4(response)
      })

    conControlDisponibilidadService
      .availabilityDate(disFecha, 5)
      .then((response) => {
        setConControlDisponibilidad5(response)
      })
  }, [disFecha])

  //use efect para validar si el usuario verifico cada uno de los detalles

  const dynamicColumns = headers.map((suc) => {
    return (
      <Column
        key={suc.field}
        field={suc.field}
        header={suc.header}
        style={{
          // lastChild: { backgroundred: 'red' },
          // border: '1px solid black',
          // borderCollapse: 'collapse',
          fontSize: '10px',
          // cellPadding: '0px',
        }}
        frozen={suc.field === 'provenance.name' ? true : false}
        alignFrozen={suc.field === 'provenance.name' ? 'left' : ''}
        bodyStyle={{ lastChild: { fontSize: '14px' } }}
      />
    )
  })

  const reportConsolidatedDate = async (disFecha) => {
    toast.current.show({
      severity: 'info',
      summary: '¡Información!',
      detail: 'Generando reporte de liquidez por favor espere...',
      life: 4000,
    })
    const conControlDisponibilidadService = new ConControlDisponibilidadService()

    var reporte = await conControlDisponibilidadService.reportConsolidatedByDate(
      disFecha,
    )

    var file = new Blob([reporte], { type: 'application/pdf' })
    var fileURL = URL.createObjectURL(file)
    window.open(fileURL)
  }

  const toolbarLeftTemplate = () => {
    return (
      <>
        <label style={{ marginRight: '.5em' }}>Fecha liquidez</label>
        <Calendar
          id="basic"
          maxDate={new Date()}
          value={new Date()}
          onChange={(e) => setDisFecha(e.value.toISOString().split('T')[0])}
          dateFormat="dd/mm/yy"
          showIcon
        />
      </>
    )
  }

  const toolbarRightTemplate = (
    <>
      <Button
        icon="pi pi-print"
        label="Impimir"
        className="p-button-warning"
        onClick={() => reportConsolidatedDate(disFecha)}
      />
    </>
  )

  return (
    <div>
      <div className="p-grid table-demo">
        <div className="p-col-12">
          <div className="card">
            <Toast ref={toast} />
            {/* <h5>Toolbar</h5> */}
            <Toolbar
              left={toolbarLeftTemplate}
              right={toolbarRightTemplate}
            ></Toolbar>
          </div>
        </div>
        <div
          className="p-col-12"
          id="ControlConzolidado"
          name="ControlConzolidado"
        >
          <div className="card" id="printTable">
            <h5>CONTROL DE DISPONIBILIDAD CONSOLIDADO</h5>

            <DataTable
              value={conControlDisponibilidad}
              rowGroupMode="rowspan"
              groupRowsBy="provenance.name"
              sortMode="single"
              sortField="provenance.name"
              sortOrder={1}
              responsiveLayout="scroll"
              rowHover
              showGridlines
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedRow(e.value)
              }}
              selection={selectedRow}
              metaKeySelection={false}
            >
              {dynamicColumns}
            </DataTable>

            <DataTable
              value={conControlDisponibilidad2}
              style={{ marginTop: '3em' }}
              rowGroupMode="rowspan"
              groupRowsBy="provenance.name"
              sortMode="single"
              sortField="provenance.name"
              sortOrder={1}
              responsiveLayout="scroll"
              rowHover
              showGridlines
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedRow(e.value)
              }}
              selection={selectedRow}
              metaKeySelection={false}
            >
              {dynamicColumns}
            </DataTable>

            <DataTable
              value={conControlDisponibilidad3}
              style={{ marginTop: '3em' }}
              rowGroupMode="rowspan"
              groupRowsBy="provenance.name"
              sortMode="single"
              sortField="provenance.name"
              sortOrder={1}
              responsiveLayout="scroll"
              rowHover
              showGridlines
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedRow(e.value)
              }}
              selection={selectedRow}
              metaKeySelection={false}
            >
              {dynamicColumns}
            </DataTable>

            <DataTable
              value={conControlDisponibilidad4}
              style={{ marginTop: '3em' }}
              rowGroupMode="rowspan"
              groupRowsBy="provenance.name"
              sortMode="single"
              sortField="provenance.name"
              sortOrder={1}
              responsiveLayout="scroll"
              rowHover
              showGridlines
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedRow(e.value)
              }}
              selection={selectedRow}
              metaKeySelection={false}
            >
              {dynamicColumns}
            </DataTable>

            <DataTable
              value={conControlDisponibilidad5}
              style={{ marginTop: '3em' }}
              rowGroupMode="rowspan"
              groupRowsBy="provenance.name"
              sortMode="single"
              sortField="provenance.name"
              sortOrder={1}
              responsiveLayout="scroll"
              rowHover
              showGridlines
              selectionMode="single"
              onSelectionChange={(e) => {
                setSelectedRow(e.value)
              }}
              selection={selectedRow}
              metaKeySelection={false}
            >
              {dynamicColumns}
            </DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}
