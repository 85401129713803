import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";

//servicios
import AppPreDestinoService from "../../../../service/AppServices/AppPreDestinoService";

import { Pagination } from "../../../../components/Pagination";
import { NuevoDestino } from "./NuevoDestino";

export const Destino = () => {
  const toast = useRef();
  const message = useRef();
  let filtroEmpty = {
    descripcion: "",
  };
  let destinoEmpty =  {
    preDesDescripcion: "",
    preDesId: 0,
    preDesReferencia: ""
  }
  const [destino, setDestino] = useState(destinoEmpty);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevoDestino, setNuevoDestino] = useState(false);
  const [destinos, setDestinos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [estado, setEstado] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);

  useEffect(() => {
    const destinoService = new AppPreDestinoService();
    destinoService.findByPage(pageNumber, filtro.descripcion).then((result) => {
      setDestinos(result?.content);
      setTotalPages(result?.totalPages);
      setPageNumber(result?.number);
      setFirstPage(result?.first);
      setLastPage(result?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado, pageNumber]);
  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9 || charCode === 13) {
      const destinoService = new AppPreDestinoService();
      destinoService
        .findByPage(pageNumber, filtro.descripcion)
        .then((result) => {
          setDestinos(result?.content);
          setTotalPages(result?.totalPages);
          setPageNumber(result?.number);
          setFirstPage(result?.first);
          setLastPage(result?.last);
          if (filtro.descripcion !== "") {
            setPageNumber(0);
          }
        });
    }
  };
  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            type={"search"}
            className="p-inputtext-sm"
            value={filtro.descripcion}
            onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
          />
          <label>Destino</label>
        </span>
      </div>
    );
  }
  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() =>{ setNuevoDestino(true); setDestino(destinoEmpty)}}
        />
      </>
    );
  }
  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }
  function renderTable() {
    return (
      <>
        <DataTable
          emptyMessage="Destinos no encontrados"
          value={destinos}
          footer={renderPaginacion()}
        >
          <Column header="Id" field="preDesId" />
          <Column header="Destino" field="preDesDescripcion" />
          <Column header="Referencia" field="preDesReferencia" />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }
  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning  mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setDestino(rowData) ;setNuevoDestino(true)}}
        />
        <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setDestino(rowData); setEliminar(true)}}
        />
      </>
    );
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-button-text p-mr-2 "
          onClick={() => setNuevoDestino(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
           onClick={() => save()}
        />
      </>
    );
  }

  function save(){
    if(destino.preDesDescripcion && destino.preDesReferencia !== ""){
        const destinoService = new AppPreDestinoService();
        destinoService.save(destino).then(()=>{
          setEstado(!estado);
          setNuevoDestino(false)
          toast.current.show({
              severity: "success",
              summary: "Tarea realizada con exito",
              detail: `Destino creada con exito`,
              life: 4000,
            });
  
        })
    }else{
        setSubmitted(true);
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que los campos necesarios no esten vacios",
          life: 4000,
        });
        setInterval(() => setSubmitted(false), 4000);
    }

  }
  const deleteDestino = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={borrar} />
    </>
  );

  function borrar() {
    const destinoService = new AppPreDestinoService();
    destinoService.delete(destino.preDesId).then(()=>{
      setEstado(!estado);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Destino eliminado con exito",
        life: 4000,
      });
    });
  }

  return (
    <div className="card">
      <Toolbar className="p-mb-4" right={right} left={left} />
      {renderTable()}
      <Dialog
        visible={nuevoDestino}
        onHide={() => setNuevoDestino(false)}
        header="Nuevo destino de fondos"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevoDestino destino={destino} setDestino={setDestino}  submitted={submitted}/>
        <Messages ref={message} />
        
      </Dialog>
      <Toast ref={toast} />
      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={deleteDestino}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar este destino .</span>
        </div>
      </Dialog>
    </div>
  );
};
