import { InputText } from "primereact/inputtext";
import { useRef, useState } from "react";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";

//funciones
import selectText from "../../../utilities/selecText";
//servicio

import PlazoService from "../../../service/TcrService/PlazoService";
export const NuevoPlazoMediosElectronicos = ({
  plazoHooks,
  setNuevoPlazo,
  estado,
  setEstado,
  toast
}) => {
  const message = useRef();
  const [plazo, setPLazo] = useState(plazoHooks);
  const [submitted, setSubmitted] = useState(false);

  function save() {
    if (plazo?.plaMeses !== 0 && plazo?.plaMeses !== "0") {
      setSubmitted(false);
      const plazoServicio = new PlazoService();
      plazoServicio.save(plazo).then(() => {
        setEstado(!estado);
        setNuevoPlazo(false);

        if (plazo?.plaId === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Plazo creado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Plazo actualizado",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }
  return (
    <>
      <div className="card">
        <div className="p-grid">
          <div className="p-fluid p-col">
            <label>Plazo en meses</label>
            <InputText
              onClick={(e) => selectText(e)}
              id="plazp"
              value={plazo?.plaMeses}
              type="number"
              onChange={(e) => setPLazo({ ...plazo, plaMeses: e.target.value })}
              className={submitted && !plazo.plaMeses ? "p-invalid" : ""}
            />
            {submitted && !plazo?.plaMeses && (
              <small className="p-invalid">Plazo requerido</small>
            )}
          </div>
        </div>
      </div>
      <Messages ref={message} />

      <div style={{ textAlign: "right" }}>
        <Button
          style={{ width: "120px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => {
            setNuevoPlazo(false);
            setSubmitted(false);
          }}
        />
        <Button
          style={{ width: "120px" }}
          icon="pi pi-check"
          label="Guardar"
          onClick={() => save()}
        />
      </div>
    </>
  );
};
