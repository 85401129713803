import React, { useState, useEffect } from "react";
import classNames from "classnames";

import AppTopBar from "./AppTopbar";
import AppFooter from "./AppFooter";
import AppConfig from "./AppConfig";
import AppMenu from "./AppMenu";
import AppSearch from "./AppSearch";
import AppRightMenu from "./AppRightMenu";
import { Dashboard } from "./components/Dashboard";
import { useHistory } from "react-router-dom";
// import { FormLayoutDemo } from "./components/FormLayoutDemo";
// import { InputDemo } from "./components/InputDemo";
// import { FloatLabelDemo } from "./components/FloatLabelDemo";
// import { ButtonDemo } from "./components/ButtonDemo";
import { TableDemo } from "./components/TableDemo";
// import { ListDemo } from "./components/ListDemo";
// import { TreeDemo } from "./components/TreeDemo";
// import { PanelDemo } from "./components/PanelDemo";
// import { OverlayDemo } from "./components/OverlayDemo";
// import { MediaDemo } from "./components/MediaDemo";
// import { MenuDemo } from "./components/MenuDemo";
// import { MessagesDemo } from "./components/MessagesDemo";
// import { FileDemo } from "./components/FileDemo";
// import { ChartDemo } from "./components/ChartDemo";
// import { MiscDemo } from "./components/MiscDemo";
// import { Documentation } from "./components/Documentation";
// import { IconsDemo } from "./utilities/IconsDemo";
// import { Widgets } from "./utilities/Widgets";
// import { GridDemo } from "./utilities/GridDemo";
// import { SpacingDemo } from "./utilities/SpacingDemo";
// import { ElevationDemo } from "./utilities/ElevationDemo";
// import { TextDemo } from "./utilities/TextDemo";
// import { TypographyDemo } from "./utilities/TypographyDemo";
// import { DisplayDemo } from "./utilities/DisplayDemo";
// import { FlexBoxDemo } from "./utilities/FlexBoxDemo";
// import { CrudDemo } from "./pages/CrudDemo";
// import { CalendarDemo } from "./pages/CalendarDemo";
// import { Invoice } from "./pages/Invoice";
// import { Help } from "./pages/Help";
// import { EmptyPage } from "./pages/EmptyPage";
import { Access } from "./pages/Access";

//===========================ASOCIADO===================================
import { CliAsociado } from "./pages/ClientesPages/CliAsociado";
import { CliAsociadoEditar } from "./pages/ClientesPages/CliAsociadoEditar";
import { CliAsociadoUploadImage } from "./pages/ClientesPages/CliAsociadoUploadImage";

//===========================TARJETA CREDITO===================================
import { ListarTarjetas } from "./pages/TarjetaCreditoPages/ListarTarjetas";
import { PlazoMediosElectronicos } from "./pages/AppPages/AppMediosElectronicos/PlazoMediosElectronicos";
//===========================Documento de concentimiento ===================================
import { ListaDocumento } from "./pages/ConsentimientoPages/ListaDocumento";
//===========================CONTABILIDAD===================================
import { Liquidez } from "./pages/Contabilidad/Liquidez";
import { LiquidezConsolidado } from "./pages/Contabilidad/LiquidezConsolidado";

//===========================AHORRO===================================
import { ListarCuentasAhorro } from "./pages/AhorroPages/ListarCuentasAhorro";
import { NuevaCuentaAhorro } from "./pages/AhorroPages/NuevaCuentaAhorro";

//===========================CREDITOS===================================
import { ListarPrestamo } from "./pages/PrestamoPages/ListarPrestamo";
import { MenuNuevoPrestamo } from "./pages/PrestamoPages/NuevoPrestamo/MenuNuevoPrestamo";
// import { AppRegion } from "./pages/AppRegion";
import { AppSexo } from "./pages/AppPages/AppSexo";
import { AppActividadEconomica } from "./pages/AppPages/AppActividadEconomica";

//===========================DIRECCIONES===================================
import { AppPais } from "./pages/AppPais";
import { ListarRegiones } from "./pages/AppPages/RegionPages/ListarRegiones";
import { ListarDepartamentos } from "./pages/AppPages/DepartamentoPages/ListarDepartamentos";
import { ListarMunicipio } from "./pages/AppPages/AppMunicipio/ListarMunicipio";
//===========================USUARIOS===================================
import { ListarUsuario } from "./pages/AppPages/UsuarioPages/ListarUsuarios";
import { PerfilUsuario } from "./pages/AppPages/UsuarioPages/PerfilUsuario";

//===========================CIERRE===================================
import { CierreDiario } from "./pages/AppPages/CierrePages/CierreDiario";

//===========================REOLES Y PERMISOS===================================
import { ListAuthority } from "./pages/AppPages/AuthorityPages/ListAuthority";
import { ListRole } from "./pages/AppPages/RolePages/ListRole";

//===========================AHORROS===================================
import { ListarTipoTasa } from "./pages/AppPages/AppGeneral/TipoTasaPages/ListarTipoTasas";
import { AhoProducto } from "./pages/AppPages/AppAhorro/ProductoAhorPages/AhoProducto";
import { Producto } from "./pages/AppPages/AppGeneral/Producto";
import { TasaInteres } from "./pages/AppPages/AppGeneral/TasaAhoInteresPage/TasaInteres";
//===========================Seguros===================================
import { ReportsSecurity } from "./pages/ReportsSecurity";

//===========================BANCO================================
import { AppBanco } from "./pages/AppPages/AppBanco/AppBanco";
import PrimeReact from "primereact/api";

import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./App.scss";
// import { InvalidStateDemo } from "./components/InvalidStateDemo";
// import { TimelineDemo } from "./pages/TimelineDemo";
import PrivateRoute from "./routers/PrivateRoute";
import { ListarProfesionOficio } from "./pages/AppPages/ProfesionOficioPages/ListarProfesionOficio";

//===========================CREDITOS===================================
import { AppTipoCompromiso } from "./pages/AppPages/AppPrestao/TipoCompromiso/AppTipoCompromiso";
import { SubProducto } from "./pages/AppPages/AppPrestao/SubProducto/SubProducto";
import { Garantia } from "./pages/AppPages/AppPrestao/Garantia/Garantia";
import { Aprobacion } from "./pages/AppPages/AppPrestao/Aprobacion/Aprobacion";
import { OrigenDeFondos } from "./pages/AppPages/AppPrestao/OrigenDeFondos/OrigenDeFondos";
import { Destino } from "./pages/AppPages/AppPrestao/Destino/Destino";
import { Deduccion } from "./pages/AppPages/AppPrestao/Deduccion/Deduccion";
import { Estado } from "./pages/AppPages/AppPrestao/Estado/Estado";
//===========================GENERAL================================
import { Saldo } from "./pages/AppPages/AppGeneral/Saldo/Saldo";

//===========================AsableaPages================================
import { AsableaHome } from "./pages/AsambleaPages/AsableaHome";
import { Registro } from "./pages/AsambleaPages/Registro/Registro";
import { Evento } from "./pages/AsambleaPages/EventoPages/Evento";
import { Transaccion } from "./pages/CajaPages/Transaccion";
import { AppListarConLibreta } from "./pages/AppPages/AppConfigLibreta/AppListarConLibreta";
const App = () => {
  const history = useHistory();
  const [menuActive, setMenuActive] = useState(false);
  const [menuMode, setMenuMode] = useState("static");
  const [colorScheme, setColorScheme] = useState("light");
  const [menuTheme, setMenuTheme] = useState("layout-sidebar-darkgray");
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
    useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [searchActive, setSearchActive] = useState(false);
  const [topbarUserMenuActive, setTopbarUserMenuActive] = useState(false);
  const [topbarNotificationMenuActive, setTopbarNotificationMenuActive] =
    useState(false);
  const [rightMenuActive, setRightMenuActive] = useState(false);
  const [configActive, setConfigActive] = useState(false);
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(false);
  const [logoColor, setLogoColor] = useState("white");
  const [componentTheme, setComponentTheme] = useState("blue");
  const [logoUrl, setLogoUrl] = useState("assets/layout/images/logo-dark.svg");

  let menuClick = false;
  let searchClick = false;
  let userMenuClick = false;
  let notificationMenuClick = false;
  let rightMenuClick = false;
  let configClick = false;

  /* creamos un array de menu donde incluye etiqueta, el icono a usar, los items,
  que son labe, icon y el path*/
  const menu = [
    {
      label: "HOME",
      icon: "pi pi-fw pi-home",
      items: [{ label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" }],
    },
    { separator: true },
    {
      label: menuMode === "slim" ? "ASO" : "ASOCIADO",
      icon: "pi pi-fw pi-user",
      items: [
        {
          label: "Gestión Asociados",
          icon: "pi pi-fw pi-user-edit",
          to: "/cliasociado",
        },
        {
          label: "Tarjeta de Crédito",
          icon: "pi pi-fw pi-credit-card",
          to: "/tarjetacredito",
        },
        {
          label: "Clausula de Consemiento",
          icon: "pi pi-fw pi-file",
          to: "/clausuladeconsentimiento",
        },
        /*
        {
          label: "Seguro",
          icon: "pi pi-fw pi-id-card",
          to: "/appseguros",
        },*/
      ],
    },
    { separator: true },
    {
      label: "AHORRO",
      icon: "pi pi-fw pi-id-card",
      items: [
        {
          label: "Gestión de Cuentas",
          icon: "pi pi-wallet",
          to: "/ahocuentas",
        },
      ],
    },
    { separator: true },
    {
      label: "PRESTAMO",
      icon: "pi pi-fw pi-id-card",
      items: [
        {
          label: "Gestión de Creditos",
          icon: "pi pi-copy",
          to: "/preprestamo",
        },
      ],
    },
    { separator: true },
    {
      label: "CAJA",
      icon: "pi pi-shopping-car",
      items: [
        {
          label: "Transacciones",
          icon: "pi pi-shopping-cart",
          to: "/transaccion",
        },
      ],
    },
    { separator: true },
    {
      label: "ASAMBLEA",
      icon: "pi pi-cog",
      items: [
        {
          label: "Evento",
          icon: "pi pi-fw pi-id-card",
          items: [
            {
              label: "Nuevo evento",
              icon: "pi pi-book",
              to: "/evento",
            },
            {
              label: "Registrar",
              icon: "pi pi-user-plus",
              to: "/registro",
            },

            {
              label: "Estadisticas",
              icon: "pi pi-chart-bar",
              to: "/asamblea",
            },
          ],
        },
      ],
    },

    // { separator: true },
    // {
    //   label: menuMode === "slim" ? "CONTA" : "CONTABILIDAD",
    //   items: [
    //     {
    //       label: "Liquidez",
    //       icon: "pi pi-dollar pi-shopping-cart",
    //       to: "/liquidez",
    //     },
    //     {
    //       label: "Liquidez Consolidado",
    //       icon: "pi pi-fw pi-info-circle",
    //       to: "/liquidezconsolidado",
    //     },
    //   ],
    // },

    { separator: true },
    {
      label: menuMode === "slim" ? "CONFIG" : "CONFIGRUACION",
      icon: "pi pi-cog",
      items: [
        {
          label: "Datos del sistema",
          icon: "pi pi-cog",
          items: [
            {
              label: "Direccion",
              icon: "pi pi-fw pi-globe",
              items: [
                {
                  label: "Pais",
                  icon: "pi pi-fw pi-directions",
                  to: "/apppais",
                },
                {
                  label: "Region",
                  icon: "pi pi-fw pi-directions",
                  to: "/appregion",
                },
                {
                  label: "Departamento",
                  icon: "pi pi-fw pi-directions",
                  to: "/appdepartamento",
                },

                {
                  label: "Municipio",
                  icon: "pi pi-fw pi-directions",
                  to: "/appmunicipio",
                },
              ],
            },
            {
              label: "Configuraciones",
              icon: "pi pi-fw pi-cog",
              items: [
                {
                  label: "Géneros",
                  icon: "pi pi-fw pi-slack",
                  to: "/appsexo",
                },
                // {
                //   label: "ESTADOS CIVILES",
                //   icon: "pi pi-fw pi-align-left",
                //   to: "/appestadocivil",
                // },
                // {
                //   label: "IDENTIFICACIONES",
                //   icon: "pi pi-fw pi-align-left",
                //   to: "/apptipoidentificacion",
                // },
                {
                  label: "Actividades Económicas",
                  icon: "pi pi-fw pi-briefcase",
                  to: "/appactividadeconomica",
                },
                {
                  label: "Profesiones y Oficios",
                  icon: "pi pi-fw pi-briefcase",
                  to: "/appprofesionoficio",
                },
                {
                  label: "Producto",
                  icon: "pi pi-file",
                  to: "/appproducto",
                },
                {
                  label: "Tipo Tasa",
                  icon: "pi pi-sort-numeric-up-alt",
                  to: "/apptipotasa",
                },
                {
                  label: "Tasa de Interes",
                  icon: "pi pi-percentage",
                  to: "/apptasa",
                },
                {
                  label: "Banco",
                  icon: "pi pi-chart-bar",
                  to: "/appbanco",
                },
                {
                  label: "Saldo",
                  icon: "pi pi-book",
                  to: "/saldo",
                },
                // { label: "Submenu 1.1.2", icon: "pi pi-fw pi-align-left" },
                // { label: "Submenu 1.1.3", icon: "pi pi-fw pi-align-left" },
              ],
            },
            {
              label: "Seguridad",
              icon: "pi pi-lock",
              items: [
                {
                  label: "Usuarios",
                  icon: "pi pi-users",
                  to: "/appusuario",
                },
                {
                  label: "Permisos",
                  icon: "pi pi-key",
                  items: [
                    {
                      label: "Roles",
                      icon: "pi pi-fw pi-key",
                      to: "/approle",
                    },
                    {
                      label: "Autorizaciones",
                      icon: "pi pi-fw pi-key",
                      to: "/appauthority",
                    },
                  ],
                },
              ],
            },
            {
              label: "Ahorro",
              icon: "pi pi-money-bill",
              items: [
                {
                  label: "Producto Ahorro",
                  icon: "pi pi-file",
                  to: "/appahoproducto",
                },
              ],
            },
            {
              label: "Credito",
              icon: "pi pi-tablet",
              items: [
                {
                  label: "Tipo compromiso",
                  icon: "pi pi-search-plus",
                  to: "/appprecompromiso",
                },

                {
                  label: "Sub Producto",
                  icon: "pi pi-file",
                  to: "/apppresubcompromiso",
                },
                {
                  label: "Garantía",
                  icon: "pi pi-briefcase",
                  to: "/apppregarantia",
                },
                {
                  label: "Aprobacion",
                  icon: "pi pi-check-circle",
                  to: "/apppreaprobacion",
                },
                {
                  label: "Origen de fondo",
                  icon: "pi pi-money-bill",
                  to: "/apppreorigendefondos",
                },
                {
                  label: "Destino",
                  icon: "pi pi-shopping-cart",
                  to: "/apppredestino",
                },
                {
                  label: "Deduccion",
                  icon: "pi pi-chart-line",
                  to: "/appprededuccion",
                },
                {
                  label: "Estado",
                  icon: "pi pi-chevron-circle-down",
                  to: "/apppreestado",
                },
              ],
            },
            {
              label: "Medios electronicos",
              icon: "pi pi-credit-card",
              items: [
                {
                  label: "Plazo medios electronicos",
                  icon: "pi pi-calendar",
                  to: "/plazomedioselectronicos",
                },
              ],
            },
            {
              label: "Libreta",
              icon: "pi pi-book",
              items: [
                {
                  label: "Configuraicon Libreta",
                  icon: "pi pi-book",
                  to: "/configuracionlibreta",
                },
              ],
            },
            {
              label: "Cierre",
              icon: "pi pi-calendar-plus",
              items: [
                {
                  label: "Cierre de diario",
                  icon: "pi pi-calendar",
                  to: "/appcierrediario",
                },
              ],
            },
          ],
        },
      ],
    },
  ];

  // Finaliza los menus

  /**********************
  Comienza los routers
  ***********************/
  const routers = [
    {
      path: "/",
      component: Dashboard,
      exact: true,
      meta: { breadcrumb: [{ parent: "Dashboard", label: "Dashboard" }] },
    },
    {
      path: "/access",
      component: Access,
      exact: true,
      meta: { breadcrumb: [{ parent: "Access", label: "Access" }] },
    },
    {
      path: "/cliasociado",
      component: CliAsociado,
      meta: {
        breadcrumb: [{ parent: "Asociado", label: "Gestión Asociados" }],
      },
    },
    {
      path: "/cliasociadoeditar",
      component: CliAsociadoEditar,
      meta: {
        breadcrumb: [{ parent: "Asociado", label: "Gestión Asociados" }],
      },
    },
    {
      path: "/cliasociadoeditarExiste",
      component: CliAsociadoEditar,
      meta: {
        breadcrumb: [{ parent: "Asociado", label: "Gestión Asociados" }],
      },
    },
    {
      path: "/cliasociadofoto",
      component: CliAsociadoUploadImage,
      meta: {
        breadcrumb: [{ parent: "Asociado", label: "Gestión Asociados" }],
      },
    },
    // ===================== MODULO TARJETA DE CREDITO =====================
    {
      path: "/tarjetacredito",
      component: ListarTarjetas,
      meta: {
        breadcrumb: [
          { parent: "Tarjeta de Crédito", label: "Tarjeta de Crédito" },
        ],
      },
    },

    // ===================== MODULO CLAUSALA DE CONSENTIMIENTO =====================
    {
      path: "/clausuladeconsentimiento",
      component: ListaDocumento,
      meta: {
        breadcrumb: [
          {
            parent: "Clausula de Consentimiento",
            label: "Clausula de Consentimiento",
          },
        ],
      },
    },

    // ===================== MODULO CONTABILIDAD =====================
    {
      path: "/liquidez",
      component: Liquidez,
      meta: { breadcrumb: [{ parent: "Liquidez", label: "Liquidez" }] },
    },
    {
      path: "/liquidezconsolidado",
      component: LiquidezConsolidado,
      meta: {
        breadcrumb: [
          { parent: "Liquidez Consolidado", label: "Liquidez Consolidado" },
        ],
      },
    },
    // ===================== MODULO AHORRO =====================
    {
      path: "/ahocuentas",
      component: ListarCuentasAhorro,
      meta: {
        breadcrumb: [
          { parent: "Gestion de Cuentas", label: "Gestion de Cuentas" },
        ],
      },
    },
    {
      path: "/ahonuevo",
      component: NuevaCuentaAhorro,
      meta: {
        breadcrumb: [
          { parent: "Gestion de Cuenta", label: "Gestion de Cuenta" },
        ],
      },
    },
    // ===================== MODULO AHORRO =====================
    {
      path: "/preprestamo",
      component: ListarPrestamo,
      meta: {
        breadcrumb: [
          { parent: "Gestion de Prestamos", label: "Gestion de Prestamos" },
        ],
      },
    },
    {
      path: "/nuevoprestamo",
      component: MenuNuevoPrestamo,
      meta: {
        breadcrumb: [
          { parent: "Gestion de Prestamo", label: "Gestion de Prestamo" },
        ],
      },
    },
    // ===================== MODULO CAJA =====================

    {
      path: "/transaccion",
      component: Transaccion,
      meta: {
        breadcrumb: [
          {
            parent: "Transacciones",
            label: "Transacciones",
          },
        ],
      },
    },
    // ===================== APLICACION ASABLEA =====================
    {
      path: "/asamblea",
      component: AsableaHome,
      meta: {
        breadcrumb: [
          { parent: "Asamblea Estadistica", label: "Asamblea Estadistica" },
        ],
      },
    },
    {
      path: "/registro",
      component: Registro,
      meta: {
        breadcrumb: [
          { parent: "Registro participantes", label: "Registro participantes" },
        ],
      },
    },
    {
      path: "/evento",
      component: Evento,
      meta: {
        breadcrumb: [{ parent: "Registro Evento", label: "Registro Evento" }],
      },
    },
    // ===================== MODULO APLICACION =====================
    {
      path: "/appregion",
      component: ListarRegiones,
      meta: {
        breadcrumb: [{ parent: "Región", label: "Regiones" }],
      },
    },
    {
      path: "/appdepartamento",
      component: ListarDepartamentos,
      meta: {
        breadcrumb: [{ parent: "Departamento", label: "Departamentos" }],
      },
    },
    {
      path: "/appmunicipio",
      component: ListarMunicipio,
      meta: {
        breadcrumb: [{ parent: "Municipio", label: "Municipio" }],
      },
    },
    {
      path: "/appsexo",
      component: AppSexo,
      meta: {
        breadcrumb: [{ parent: "Género", label: "Género" }],
      },
    },
    {
      path: "/appactividadeconomica",
      component: AppActividadEconomica,
      meta: {
        breadcrumb: [
          { parent: "Actividad Económica", label: "Actividad Económica" },
        ],
      },
    },
    {
      path: "/appprofesionoficio",
      component: ListarProfesionOficio,
      meta: {
        breadcrumb: [
          { parent: "Profesión u Oficio", label: "Profesión u Oficio" },
        ],
      },
    },
    {
      path: "/apppais",
      component: AppPais,
      meta: {
        breadcrumb: [{ parent: "AppPais", label: "Gestión Países" }],
      },
    },
    {
      path: "/appusuario",
      component: ListarUsuario,
      meta: {
        breadcrumb: [{ parent: "AppUsuario", label: "Gestion de Usuarios" }],
      },
    },
    {
      path: "/appproducto",
      component: Producto,
      meta: {
        breadcrumb: [{ parent: "Producto", label: "Producto" }],
      },
    },

    // ===================== MODULO APLICACION =====================

    {
      path: "/appperfilusuario",
      component: PerfilUsuario,
      meta: {
        breadcrumb: [{ parent: "PerfilUsuario", label: "Perfil de Usuario" }],
      },
    },
    {
      path: "/approle",
      component: ListRole,
      meta: {
        breadcrumb: [{ parent: "ListRole", label: "Roles del Sistema" }],
      },
    },
    {
      path: "/appauthority",
      component: ListAuthority,
      meta: {
        breadcrumb: [
          { parent: "ListaAuthority", label: "Autorizaciones del Sistema" },
        ],
      },
    },
    {
      path: "/appcierrediario",
      component: CierreDiario,
      meta: {
        breadcrumb: [{ parent: "CierreDiario", label: "Cierre Diario" }],
      },
    },
    {
      path: "/table",
      component: TableDemo,
      meta: { breadcrumb: [{ parent: "UI Kit", label: "Table" }] },
    },
    // ===================== APLICACION AHORRO =====================
    {
      path: "/apptipotasa",
      component: ListarTipoTasa,
      meta: {
        breadcrumb: [{ parent: "Tipo Tasa", label: "Tipo Tasa" }],
      },
    },
    {
      path: "/apptasa",
      component: TasaInteres,
      meta: {
        breadcrumb: [{ parent: "Tasa de Interes", label: "Tasa de Interes" }],
      },
    },
    {
      path: "/appbanco",
      component: AppBanco,
      meta: {
        breadcrumb: [{ parent: "Banco", label: "Bancos disponibles" }],
      },
    },
    {
      path: "/saldo",
      component: Saldo,
      meta: {
        breadcrumb: [{ parent: "Saldo", label: "Saldo" }],
      },
    },
    {
      path: "/appahoproducto",
      component: AhoProducto,
      meta: {
        breadcrumb: [
          { parent: "Productos de ahorro", label: "Productos de ahorro" },
        ],
      },
    },

    {
      path: "/appseguros",
      component: ReportsSecurity,
      meta: {
        breadcrumb: [{ parent: "UI Kit", label: "Seguro" }],
      },
    },

    // ===================== APLICACION CREDITOS =====================
    {
      path: "/appprecompromiso",
      component: AppTipoCompromiso,
      meta: {
        breadcrumb: [{ parent: "Tipo compromiso", label: "Tipo compromiso" }],
      },
    },
    {
      path: "/apppresubcompromiso",
      component: SubProducto,
      meta: {
        breadcrumb: [{ parent: "Sub producto", label: "Sub producto" }],
      },
    },
    {
      path: "/apppregarantia",
      component: Garantia,
      meta: {
        breadcrumb: [{ parent: "Garantía", label: "Garantía" }],
      },
    },
    {
      path: "/apppreorigendefondos",
      component: OrigenDeFondos,
      meta: {
        breadcrumb: [{ parent: "Origen de Fondos", label: "Origen de Fondos" }],
      },
    },
    {
      path: "/apppredestino",
      component: Destino,
      meta: {
        breadcrumb: [{ parent: "Destino", label: "Destino" }],
      },
    },
    {
      path: "/appprededuccion",
      component: Deduccion,
      meta: {
        breadcrumb: [{ parent: "Deduccion", label: "Deduccion" }],
      },
    },
    {
      path: "/apppreaprobacion",
      component: Aprobacion,
      meta: {
        breadcrumb: [{ parent: "Aprobacion", label: "Aprobacion" }],
      },
    },
    {
      path: "/apppreestado",
      component: Estado,
      meta: {
        breadcrumb: [{ parent: "Estado", label: "Estado" }],
      },
    },

    // ===================== APLICACION  MEDIOS ELECTRONICOS=====================
    {
      path: "/plazomedioselectronicos",
      component: PlazoMediosElectronicos,
      meta: {
        breadcrumb: [
          {
            parent: "Plazo medios electronicos",
            label: "Plazo medios electronicos",
          },
        ],
      },
    },

    // ===================== APLICACION  CONFIGURACION DE LIBRETA=====================
    {
      path: "/configuracionlibreta",
      component: AppListarConLibreta,
      meta: {
        breadcrumb: [
          {
            parent: "Configuracion de libreta",
            label: "Configuracion de libreta",
          },
        ],
      },
    },
  ];

  // Se utiliza para verificar si la App se abrio en un telefono entonces activa el scroll
  // correspondiente
  useEffect(() => {
    if (staticMenuMobileActive) {
      blockBodyScroll();
    } else {
      unblockBodyScroll();
    }
  }, [staticMenuMobileActive]);

  //al cargar la App, utiliza el tema light por defecto.
  useEffect(() => {
    changeStyleSheetUrl("layout-css", "layout-" + colorScheme + ".css", 1);
    changeStyleSheetUrl("theme-css", "theme-" + colorScheme + ".css", 1);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Establece el estilo del input
  const onInputStyleChange = (inputStyle) => {
    setInputStyle(inputStyle);
  };

  // Cambia el tema del menu
  const changeMenuTheme = (name, logoColor, componentTheme) => {
    onMenuThemeChange(name);
    changeStyleSheetUrl("theme-css", componentTheme, 2);
    setComponentTheme(componentTheme);

    const appLogoLink = document.getElementById("app-logo");
    const appLogoUrl = `assets/layout/images/logo-${
      logoColor === "dark" ? "dark" : "white"
    }.svg`;

    if (appLogoLink) {
      appLogoLink.src = appLogoUrl;
    }
    setLogoColor(logoColor);
  };

  // cambia los componentes del tema
  const changeComponentTheme = (theme) => {
    setComponentTheme(theme);
    changeStyleSheetUrl("theme-css", theme, 3);
  };

  // cambia el color del esquema
  const changeColorScheme = (e) => {
    setColorScheme(e.value);

    const scheme = e.value;
    changeStyleSheetUrl("layout-css", "layout-" + scheme + ".css", 1);
    changeStyleSheetUrl("theme-css", "theme-" + scheme + ".css", 1);
    changeLogo(scheme);
  };

  // cambia el estilo de Css a traves del URL
  const changeStyleSheetUrl = (id, value, from) => {
    const element = document.getElementById(id);
    const urlTokens = element.getAttribute("href").split("/");

    if (from === 1) {
      // which function invoked this function
      urlTokens[urlTokens.length - 1] = value;
    } else if (from === 2) {
      // which function invoked this function
      if (value !== null) {
        urlTokens[urlTokens.length - 2] = value;
      }
    } else if (from === 3) {
      // which function invoked this function
      urlTokens[urlTokens.length - 2] = value;
    }

    const newURL = urlTokens.join("/");

    replaceLink(element, newURL);
  };

  // Cambia el diseño del logotipo
  const changeLogo = (scheme) => {
    const appLogoLink = document.getElementById("app-logo");
    const mobileLogoLink = document.getElementById("logo-mobile");
    const invoiceLogoLink = document.getElementById("invoice-logo");
    const footerLogoLink = document.getElementById("footer-logo");
    setLogoUrl(
      `assets/layout/images/logo-${scheme === "light" ? "dark" : "white"}.svg`
    );

    if (appLogoLink) {
      appLogoLink.src = `assets/layout/images/logo-${
        scheme === "light" ? logoColor : "white"
      }.svg`;
    }

    if (mobileLogoLink) {
      mobileLogoLink.src = logoUrl;
    }

    if (invoiceLogoLink) {
      invoiceLogoLink.src = logoUrl;
    }

    if (footerLogoLink) {
      footerLogoLink.src = logoUrl;
    }
  };

  // funcion para remplazar el link, evaluar si es Internet Explorer
  const replaceLink = (linkElement, href) => {
    if (isIE()) {
      linkElement.setAttribute("href", href);
    } else {
      const id = linkElement.getAttribute("id");
      const cloneLinkElement = linkElement.cloneNode(true);

      cloneLinkElement.setAttribute("href", href);
      cloneLinkElement.setAttribute("id", id + "-clone");

      linkElement.parentNode.insertBefore(
        cloneLinkElement,
        linkElement.nextSibling
      );

      cloneLinkElement.addEventListener("load", () => {
        linkElement.remove();
        cloneLinkElement.setAttribute("id", id);
      });
    }
  };

  // Evalua si el navegador es internet explorer.
  const isIE = () => {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  };

  const onRippleChange = (e) => {
    PrimeReact.ripple = e.value;
    setRipple(e.value);
  };

  const onDocumentClick = () => {
    if (!searchClick && searchActive) {
      onSearchHide();
    }

    if (!userMenuClick) {
      setTopbarUserMenuActive(false);
    }

    if (!notificationMenuClick) {
      setTopbarNotificationMenuActive(false);
    }

    if (!rightMenuClick) {
      setRightMenuActive(false);
    }

    if (!menuClick) {
      if (isSlim()) {
        setMenuActive(false);
      }

      if (overlayMenuActive || staticMenuMobileActive) {
        hideOverlayMenu();
      }

      unblockBodyScroll();
    }

    if (configActive && !configClick) {
      setConfigActive(false);
    }

    searchClick = false;
    configClick = false;
    userMenuClick = false;
    rightMenuClick = false;
    notificationMenuClick = false;
    menuClick = false;
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuButtonClick = (event) => {
    menuClick = true;
    setTopbarUserMenuActive(false);
    setTopbarNotificationMenuActive(false);
    setRightMenuActive(false);

    if (isOverlay()) {
      setOverlayMenuActive((prevOverlayMenuActive) => !prevOverlayMenuActive);
    }

    if (isDesktop()) {
      setStaticMenuDesktopInactive(
        (prevStaticMenuDesktopInactive) => !prevStaticMenuDesktopInactive
      );
    } else {
      setStaticMenuMobileActive(
        (prevStaticMenuMobileActive) => !prevStaticMenuMobileActive
      );
    }

    event.preventDefault();
  };

  const onMenuitemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();

      if (isSlim()) {
        setMenuActive(false);
      }
    }
  };

  const onRootMenuitemClick = () => {
    setMenuActive((prevMenuActive) => !prevMenuActive);
  };

  const onMenuThemeChange = (name) => {
    setMenuTheme("layout-sidebar-" + name);
  };

  const onMenuModeChange = (e) => {
    setMenuMode(e.value);
    if (e.value === "static") {
      setStaticMenuDesktopInactive(false);
    }
  };

  const onTopbarUserMenuButtonClick = (event) => {
    userMenuClick = true;
    setTopbarUserMenuActive(
      (prevTopbarUserMenuActive) => !prevTopbarUserMenuActive
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const onTopbarNotificationMenuButtonClick = (event) => {
    notificationMenuClick = true;
    setTopbarNotificationMenuActive(
      (prevTopbarNotificationMenuActive) => !prevTopbarNotificationMenuActive
    );

    hideOverlayMenu();

    event.preventDefault();
  };

  const toggleSearch = () => {
    setSearchActive((prevSearchActive) => !prevSearchActive);
    searchClick = true;
  };

  const onSearchClick = () => {
    searchClick = true;
  };

  const onSearchHide = () => {
    setSearchActive(false);
    searchClick = false;
  };

  const onRightMenuClick = () => {
    rightMenuClick = true;
  };

  const onRightMenuButtonClick = (event) => {
    rightMenuClick = true;
    setRightMenuActive((prevRightMenuActive) => !prevRightMenuActive);
    hideOverlayMenu();
    event.preventDefault();
  };

  const onConfigClick = () => {
    configClick = true;
  };

  const onConfigButtonClick = () => {
    setConfigActive((prevConfigActive) => !prevConfigActive);
    configClick = true;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
    unblockBodyScroll();
  };

  const blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add("blocked-scroll");
    } else {
      document.body.className += " blocked-scroll";
    }
  };

  const unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove("blocked-scroll");
    } else {
      document.body.className = document.body.className.replace(
        new RegExp(
          "(^|\\b)" + "blocked-scroll".split(" ").join("|") + "(\\b|$)",
          "gi"
        ),
        " "
      );
    }
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 991;
  };

  const containerClassName = classNames(
    "layout-wrapper",
    {
      "layout-overlay": menuMode === "overlay",
      "layout-static": menuMode === "static",
      "layout-slim": menuMode === "slim",
      "layout-sidebar-dim": colorScheme === "dim",
      "layout-sidebar-dark": colorScheme === "dark",
      "layout-overlay-active": overlayMenuActive,
      "layout-mobile-active": staticMenuMobileActive,
      "layout-static-inactive":
        staticMenuDesktopInactive && menuMode === "static",
      "p-input-filled": inputStyle === "filled",
      "p-ripple-disabled": !ripple,
    },
    colorScheme === "light" ? menuTheme : ""
  );

  var inactividadTimeout;
  const reiniciarTemporizadorInactividad = () => {
    clearTimeout(inactividadTimeout);
    inactividadTimeout = setTimeout(() => {
      localStorage.removeItem("token");
      //  console.log("borrar")
      history.push("/login");
    }, 900000);
  };

  document.addEventListener("mousemove", reiniciarTemporizadorInactividad);
  document.addEventListener("keydown", reiniciarTemporizadorInactividad);
  return (
    <div
      className={containerClassName}
      data-theme={colorScheme}
      onClick={onDocumentClick}
    >
      {reiniciarTemporizadorInactividad()}
      <div className="layout-content-wrapper">
        <AppTopBar
          routers={routers}
          topbarNotificationMenuActive={topbarNotificationMenuActive}
          topbarUserMenuActive={topbarUserMenuActive}
          onMenuButtonClick={onMenuButtonClick}
          onSearchClick={toggleSearch}
          onTopbarNotification={onTopbarNotificationMenuButtonClick}
          onTopbarUserMenu={onTopbarUserMenuButtonClick}
          onRightMenuClick={onRightMenuButtonClick}
          onRightMenuButtonClick={onRightMenuButtonClick}
        ></AppTopBar>

        <div className="layout-content">
          {routers.map((router, index) => {
            if (router.exact) {
              return (
                <PrivateRoute
                  key={`router${index}`}
                  path={router.path}
                  exact
                  component={router.component}
                />
              );
            }

            return (
              <PrivateRoute
                key={`router${index}`}
                path={router.path}
                component={router.component}
              />
            );
          })}
        </div>

        <AppFooter />
      </div>

      <AppMenu
        model={menu}
        menuMode={menuMode}
        active={menuActive}
        mobileMenuActive={staticMenuMobileActive}
        onMenuClick={onMenuClick}
        onMenuitemClick={onMenuitemClick}
        onRootMenuitemClick={onRootMenuitemClick}
      ></AppMenu>

      <AppRightMenu
        rightMenuActive={rightMenuActive}
        onRightMenuClick={onRightMenuClick}
      ></AppRightMenu>

      <AppConfig
        configActive={configActive}
        menuMode={menuMode}
        onMenuModeChange={onMenuModeChange}
        colorScheme={colorScheme}
        changeColorScheme={changeColorScheme}
        menuTheme={menuTheme}
        changeMenuTheme={changeMenuTheme}
        componentTheme={componentTheme}
        changeComponentTheme={changeComponentTheme}
        onConfigClick={onConfigClick}
        onConfigButtonClick={onConfigButtonClick}
        rippleActive={ripple}
        onRippleChange={onRippleChange}
        inputStyle={inputStyle}
        onInputStyleChange={onInputStyleChange}
      ></AppConfig>

      <AppSearch
        searchActive={searchActive}
        onSearchClick={onSearchClick}
        onSearchHide={onSearchHide}
      />

      <div className="layout-mask modal-in"></div>
    </div>
  );
};

export default App;
