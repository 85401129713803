import React, { useEffect, useState } from "react";

import { Chart } from "primereact/chart";

//servicios
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";

export const GraGenero = ({ eventId }) => {
  const [labels, setLabels] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [inscritos, setInscritos] = useState([]);
  const [color, setColors] = useState([]);

  useEffect(() => {
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService.getGenederByEventId(eventId).then((res) => {
      if (res?.length > 0) {
        var labelsVar = [];
        var participantesVar = [];
        var inscritosVar = [];
        var colorsVar = [];
        res?.forEach((element) => {
          labelsVar.push(element.gender);
          inscritosVar.push(element.totalEnroll);
          participantesVar.push(element.totalParticipants)
          if (element.code === 1) {
            colorsVar.push("rgba(15,151,199,1)");
          } else if (element.code === 2) {
            colorsVar.push("rgba(185,68,214,1)");
          } else {
            colorsVar.push(
              "#" + Math.floor(Math.random() * 16777215).toString(16)
            );
          }
        });
        setColors(colorsVar);
        setLabels(labelsVar);
        setInscritos(inscritosVar);
        setParticipantes(participantesVar)
      }
    });
  }, [eventId]);
  const data = {
    labels: labels,
    datasets: [
      {
        data: inscritos,
        backgroundColor: color,
      },
      {
        data: participantes,
        backgroundColor: color
      },
    ],
  };
  return (
    <div className="revenue-chart-container">
      <Chart type="pie" id="revenue-chart" data={data}></Chart>
    </div>
  );
};


