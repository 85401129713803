import React from "react";
import "../../utils/App.css";
import Cropper from "react-easy-crop";
import { Button } from "primereact/button";
import CliAsociadoService from "../../service/CliServices/CliAsociadoService";

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} imageSrc - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */

// import { generateDownload } from "../../utils/cropImage";

export const CliAsociadoUploadImage = (props) => {
  const inputRef = React.useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();

  const [image, setImage] = React.useState(null);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
      });
    }
  };

  const onDownload = () => {
    generateDownload(image, croppedArea);
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  async function getCroppedImg(imageSrc, pixelCrop, rotation = 0) {
    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const maxSize = Math.max(image.width, image.height);
    const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

    // set each dimensions to double largest dimension to allow for a safe area for the
    // image to rotate in without being clipped by canvas context
    canvas.width = safeArea;
    canvas.height = safeArea;

    // translate canvas context to a central location on image to allow rotating around the center.
    ctx.translate(safeArea / 2, safeArea / 2);
    ctx.rotate(getRadianAngle(rotation));
    ctx.translate(-safeArea / 2, -safeArea / 2);

    // draw rotated image and store data.
    ctx.drawImage(
      image,
      safeArea / 2 - image.width * 0.5,
      safeArea / 2 - image.height * 0.5
    );

    const data = ctx.getImageData(0, 0, safeArea, safeArea);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image with correct offsets for x,y crop values.
    ctx.putImageData(
      data,
      0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x,
      0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y
    );

    // As Base64 string
    // return canvas.toDataURL("image/jpeg");
    return canvas;
  }
  var base64data;
  const generateDownload = async (imageSrc, crop) => {
    if (!crop || !imageSrc) {
      return;
    }

    const canvas = await getCroppedImg(imageSrc, crop);

    canvas.toBlob(
      (blob) => {
        console.log("PROPS--->", props);
        // const previewUrl = window.URL.createObjectURL(blob);
        // console.log("BASE 64---->", blob);
        // const anchor = document.createElement("a");
        // anchor.download = "image.jpeg";
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();
        // window.URL.revokeObjectURL(previewUrl);
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = function () {
          base64data = reader.result;

          const cliAsociadoService = new CliAsociadoService();

          let _asociado = { ...props.asociado };
          _asociado[`${"asoFoto"}`] = base64data;
          cliAsociadoService.save(_asociado).then((data) => {
            props.setAsociado(data);
            props.setAsociadoImageDialog(false);
          });
        };
      },
      "image/jpeg",
      0.66
    );
  };

  return (
    <div>
    <div
        className="container-cropper card"
        style={{ height: "48vh", width: "48vw", backgroundColor: "#575757" }}
      >
          {image ? (
            <>
              <div className="cropper">
                <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={7 / 8}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
            </>
          ) : null}
        </div>
    
      <div className="container-buttons p-mt-5">
        <input
          type="file"
          accept="image/*"
          ref={inputRef}
          onChange={onSelectFile}
          style={{ display: "none" }}
        />
        <Button
          label="Seleccionar"
          icon="pi pi-image"
          iconPos="right"
          variant="contained"
          className="p-button-rounded p-button-secondary"
          onClick={triggerFileSelectPopup}
          style={{ marginRight: "10px" }}
        />
        <Button
          label="Subir"
          icon="pi pi-cloud-upload"
          iconPos="right"
          variant="contained"
          className="p-button-rounded p-button-success"
          onClick={onDownload}
        />
      </div>
    </div>
  );
};

export default CliAsociadoUploadImage;
