import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class PrePrestamoService {
  async findByPage(pageNumber, preId,preNombre,preCliId,preReferencia) {
    console.log( preId,preNombre,preCliId,preReferencia)
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/preprestamo/page/${preId || 0}/${preNombre || 0}/${preCliId || 0}/${preReferencia || 0}?page=${pageNumber||0}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/preprestamo/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findById(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/preprestamo/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/preprestamo/all`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async delete(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.delete(
        api.baseUrl() + `/preprestamo/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }


  async findTasa() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/preprestamo/tipotasa`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
