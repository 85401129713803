import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import React, { useEffect, useState, useRef } from "react";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import classNames from "classnames";
//servicios
import AppSucursalService from "../../../service/AppServices/AppSucursalService";
import EventoService from "../../../service/AsambleaServices/EventoService";
//funciones
import selectText from "../../../utilities/selecText";
import onChajeObjN from "../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../utilities/onChangeObjDropDawn";
import { AsambleaReport } from "./EventoReport/AsambleaReport";
export const NuevoEvento = ({
  setNuevoRegistro,
  eventoHook,
  toast,
  estado,
  setEstado,
}) => {
  const message = useRef();

  const [evento, setEvento] = useState(eventoHook);
  const [sucursales, setSucursales] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    const sucursalService = new AppSucursalService();
    sucursalService.findAll().then((res) => {
      setSucursales(res);
    });
  }, []);

  function save() {
    if (
      evento.eveNombre &&
      evento?.eveDescripcion &&
      evento?.eveFecha !== "" &&
      evento.eveSucId !== 0 &&
      evento.eveSucId !== ""
    ) {
      const eventoService = new EventoService();
      eventoService.save(evento).then(() => {
        setEstado(!estado);
        setNuevoRegistro(false);
        if (evento.eveId === 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Evento creado correctamente",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Evento actualizado correctamente",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => {
        setSubmitted(false);
      }, 4000);
    }
  }

  const renderForm = (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col">
          <label>Nombre del evento</label>
          <InputText
            id="nomEve"
            onClick={(e) => selectText(e)}
            value={evento?.eveNombre}
            onChange={(e) => onChajeObjN(e, "eveNombre", evento, setEvento)}
            placeholder="Nombre"
            className={classNames({
              "p-invalid": submitted && !evento?.eveNombre,
            })}
            disabled={evento?.eveId !== 0}
          />
          {submitted && !evento?.eveDescripcion && (
            <small className="p-invalid">
              Ingrese un el nombre para el evento
            </small>
          )}
        </div>
      </div>
      <div className="p-grid p-fluid">
        <div className="p-col">
          <label>Descripcion del evento</label>
          <InputTextarea
            id="desEve"
            onClick={(e) => selectText(e)}
            value={evento?.eveDescripcion}
            rows={3}
            onChange={(e) =>
              onChajeObjN(e, "eveDescripcion", evento, setEvento)
            }
            placeholder="Descripcion"
            className={classNames({
              "p-invalid": submitted && !evento?.eveDescripcion,
            })}
            disabled={evento?.eveId !== 0}
          />
          {submitted && !evento?.eveDescripcion && (
            <small className="p-invalid">Ingrese una descripcion</small>
          )}
        </div>
      </div>
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Fecha del evento</label>
          <InputText
            type={"date"}
            value={evento?.eveFecha}
            onChange={(e) => onChajeObjN(e, "eveFecha", evento, setEvento)}
            className={classNames({
              "p-invalid": submitted && !evento?.eveFecha,
            })}
          />
          {submitted && !evento?.eveSeveFechaucId && (
            <small className="p-invalid">Seleccione una fecha</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Sucursal anfitrión</label>
          <Dropdown
            value={evento?.appSucursal}
            placeholder="Seleccione..."
            optionLabel="sucNombre"
            options={sucursales}
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appSucursal",
                e.target.value.sucId,
                "eveSucId",
                evento,
                setEvento
              )
            }
            className={classNames({
              "p-invalid": submitted && !evento?.eveSucId,
            })}
          />
          {submitted && !evento?.eveSucId && (
            <small className="p-invalid">Seleccione una sucrusal</small>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {renderForm}
      <Messages ref={message} />
      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "100px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => setNuevoRegistro(false)}
        />
        <Button
          style={{ width: "100px" }}
          icon="pi pi-check "
          className="p-button-info p-mr-2 "
          label="Guardar"
          onClick={() => save()}
        />
        <AsambleaReport evento={evento}/>

      </div>
    </>
  );
};



