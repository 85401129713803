import React, {  useState } from "react";
import { Button } from "primereact/button";

import { InputText } from "primereact/inputtext";

//funciones
import onChajeObjN from "../../../../../utilities/onChajeObjN";
import selectText from "../../../../../utilities/selecText";

//Servicios
import AppTasaUnicaService from "../../../../../service/AppServices/AppTasaUnicaService";

export const NuevaTasaUnica = ({
  setNuevaTasaUnica,
  toasUnico,
  tasaUnicaHook,
  nuevoTasaInteres,
  prueba,setPrueba
}) => {
  const [tasaUnica, setTasaUnica] = useState(tasaUnicaHook);

  function save() {
    if (
      tasaUnica.tasUniFechaVigencia &&
      tasaUnica.tasUniValor !== "" &&
      tasaUnica.tasUniValor !== 0
    ) {
      const appTasaUnicaService =   new AppTasaUnicaService ();

      const _tasaUnica = {...tasaUnica}
      _tasaUnica['tasUniTasId'] = nuevoTasaInteres?.tasId
      appTasaUnicaService.save(_tasaUnica).then(()=>{
        setPrueba(!prueba)
        setNuevaTasaUnica(false)
      })
    }
  }

  function buttons() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-mr-2 p-mb-2 p-button-danger "
          onClick={() => setNuevaTasaUnica(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-check"
          className="p-mr-2 p-mb-2"
          onClick={() => save()}
          label="save"
        />
      </>
    );
  }

  function renderForm() {
    return (
      <>
        <div className="p-grid p-fluid">
          <div className="p-col">
            <label>Fecha de vigencia</label>
            <InputText
              value={tasaUnica?.tasUniFechaVigencia}
              type={"date"}
              onChange={(e) =>
                onChajeObjN(e, "tasUniFechaVigencia", tasaUnica, setTasaUnica)
              }
            />
          </div>

          <div className="p-col">
            <label>Tasa a utilizar</label>
            <InputText
              id="tasaUtilizar"
              value={tasaUnica?.tasUniValor}
              type={"number"}
              onChange={(e) =>
                onChajeObjN(e, "tasUniValor", tasaUnica, setTasaUnica)
              }
              onClick={(e) => selectText(e)}
            />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="card">{renderForm()}</div>
      <div style={{ textAlign: "right" }}>{buttons()}</div>
    </>
  );
};
