import { useEffect, useState, useRef } from "react";
import AppAhoLibretaService from "../../../service/AppServices/AppAhoLibretaService";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { AppNuevaConLibreta } from "./AppNuevaConLibreta";
import { Messages } from "primereact/messages";
export const AppListarConLibreta = () => {
  const message = useRef();

  let libretaEmty = {
    ahoLibDescripcion: "",
    ahoLibDetColumnaP1: 0,
    ahoLibDetColumnaP2: 0,
    ahoLibEncColumna: 0,
    ahoLibEncLinea: 0,
    ahoLibLineasP1: 0,
    ahoLibLineasP2: 0,
    ahoLibMargenP1: 0,
    ahoLibMargenP2: 0,
    ahoLibNoPagina: 0,
    appAhoLibId: 0,
  };
  const [libreta, setLibreta] = useState(libretaEmty);
  const [libretas, setLibretas] = useState([]);
  const [nuevaLibreta, setNuevaLibreta] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [estado,setEstado] = useState(false)


  useEffect(() => {
   
    const ahoLibretaService = new AppAhoLibretaService();
    ahoLibretaService.findAll().then((res)=>{
        setLibretas(res);   
    })

  }, [estado]);

  function table() {
    return (
      <div className="p-mt-5 card">
        <DataTable value={libretas} emptyMessage="No se encontro ningun Banco">
          <Column header="Id" field="appAhoLibId" />
          <Column header="Descripcion" field="ahoLibDescripcion" />
          {/* <Column
        header="No. Columnas pag. 1"
        field="ahoLibDetColumnaP1"
        />  
         <Column
        header="No. Columnas pag. 2"
        field="ahoLibDetColumnaP2"
        />   
         <Column
        header="No. Lineas pag. 1"
        field="ahoLibLineasP1"
        />  
         <Column
        header="No. Lineas pag. 2"
        field="ahoLibLineasP2"
        />   */}
          <Column header="Margen superior pag. 1" field="ahoLibMargenP1" />
          <Column header="Margen superior pag. 2" field="ahoLibMargenP2" />
          <Column header="No. paginas" field="ahoLibNoPagina" />
          <Column header="Columnas encabezado" field="ahoLibEncColumna" />
          <Column header="Lineas encabezado" field="ahoLibEncLinea" />
          <Column header="Lineas encabezado" field="ahoLibEncLinea" />
          <Column header="Acciones"  body={acciones}/>
        </DataTable>
      </div>
    );
   
  }


  function acciones(rowData) {
        
    return (
      <>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setLibreta(rowData); setNuevaLibreta(true)}}
        />
      </>
    );

}
  function right() {
    return (
      <Button
        className="p-button-success"
        icon="pi pi-plus"
        label="Nuevo"
        onClick={() => setNuevaLibreta(true)}
      />
    );
  }
  const cancelarNuevaConfiguracion = () => {
    setNuevaLibreta(false);
    setSubmitted(false);
    setLibreta(libretaEmty);
  };

  function save() {
    if (
      libreta?.ahoLibDescripcion !== "" &&
      libreta?.ahoLibNoPagina &&
      libreta.ahoLibEncColumna &&
      libreta.ahoLibEncLinea &&
      libreta.ahoLibMargenP1 &&
      libreta.ahoLibMargenP2 &&
      libreta.ahoLibDetColumnaP1 &&
      libreta.ahoLibDetColumnaP2 &&
      libreta.ahoLibLineasP1 &&
      libreta.ahoLibLineasP2 !== 0 &&
      libreta?.ahoLibNoPagina &&
      libreta.ahoLibEncColumna &&
      libreta.ahoLibEncLinea &&
      libreta.ahoLibMargenP1 &&
      libreta.ahoLibMargenP2 &&
      libreta.ahoLibDetColumnaP1 &&
      libreta.ahoLibDetColumnaP2 &&
      libreta.ahoLibLineasP1 &&
      libreta.ahoLibLineasP2 !== "0"
    ) {
        const ahoLibretaService = new AppAhoLibretaService();

        ahoLibretaService.save(libreta).then(()=>{
            cancelarNuevaConfiguracion()
            setEstado(!estado)
        })
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }
  return (
    <>
      <Toolbar right={right} />
      {table()}
      <Dialog
        visible={nuevaLibreta}
        onHide={() => cancelarNuevaConfiguracion()}
        header={
          libreta.appAhoLibId !== 0
            ? "Editar configuracion de libreta"
            : "Nueva configuracion de libreta"
        }
        breakpoints={{ "960px": "80vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        footer={
          <>
            <Button
              icon="pi pi-times"
              className="p-button-danger p-mr-2 "
              label="Cancelar"
              onClick={() => cancelarNuevaConfiguracion()}
            />
            <Button
              icon="pi pi-save"
              className="p-button-success"
              label="Guardar"
              onClick={() => save()}
            />
          </>
        }
      >
        <Messages ref={message} />
        <AppNuevaConLibreta
          libreta={libreta}
          setLibreta={setLibreta}
          submitted={submitted}
          setSubmitted={setSubmitted}
        />
      </Dialog>
    </>
  );
};
