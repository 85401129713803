import React, { useState, useEffect, useRef } from 'react'
//import { useLocation } from 'react-router-dom'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import AppAuthorityService from '../../../../service/AppServices/AppAuthorityService'

export const ListAuthority = () => {
 // const history = useHistory()
  //let location = useLocation()
  //const previusObjectURL = location.state?.from

  let emptyAuthority = {
    authId: 0,
    authName: '',
  }

  const [appAuthorities, setAppAuthorities] = useState(null)
  const [appAuthorityDialog, setAppAuthorityDialog] = useState(false)
  const [deleteAuthorityDialog, setDeleteAuthorityDialog] = useState(false)
  const [appAuthority, setAppAuthority] = useState(emptyAuthority)
  const [selectedAuthorities, setSelectedAuthorities] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const toast = useRef(null)
  const dt = useRef(null)

  //Creamos el state para la busqueda
  const [authorityFind, setAuthorityFind] = useState('')

  //States para la paginacion
  const [sizeForPage, setSizeForPage] = useState(2)
  const [totalElements, setTotalElements] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [firstPage, setFirstPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    const appAuthorityService = new AppAuthorityService()

    appAuthorityService
      .findByName(authorityFind)
      .then((response) => {
        setAppAuthorities(response.data.content)
        setSizeForPage(response.data.size)
        setTotalElements(response.data.totalElements)
        setTotalPages(response.data.totalPages)
        setPageNumber(response.data.number)
        setFirstPage(response.data.first)
        setLastPage(response.data.last)
      })
      .catch((err) => {
        console.log('Error', err.message)
      })
  }, [authorityFind])

  const openNew = () => {
    setAppAuthority(emptyAuthority)
    setSubmitted(false)
    setAppAuthorityDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setAppAuthorityDialog(false)
  }

  const hideDeleteAuthorityDialog = () => {
    setDeleteAuthorityDialog(false)
  }

  //Guardamos un registro
  const saveAuthority = () => {
    setSubmitted(true)

    const appAuthorityService = new AppAuthorityService()
    if (appAuthority.authId !== null) {
      appAuthorityService.save(appAuthority).then((data) => {
        appAuthorityService.findByName(authorityFind).then((data) => {
          console.log(data)
          setAppAuthorities(data.data.content)
          setSizeForPage(data.data.size)
          setTotalElements(data.data.totalElements)
          setTotalPages(data.data.totalPages)
          setPageNumber(data.data.number)
          setFirstPage(data.data.first)
          setLastPage(data.data.last)
        })
        toast.current.show({
          severity: 'success',
          summary: '¡Exitoso!',
          detail: 'Actividad Authority Actualizado',
          life: 3000,
        })
      })
    } else {
      appAuthorityService.save(appAuthority).then((data) => {
        appAuthorityService.findByName(authorityFind).then((data) => {
          console.log(data)
          setAppAuthorities(data.data.content)
          setSizeForPage(data.data.size)
          setTotalElements(data.data.totalElements)
          setTotalPages(data.data.totalPages)
          setPageNumber(data.data.number)
          setFirstPage(data.data.first)
          setLastPage(data.data.last)
        })

        toast.current.show({
          severity: 'success',
          summary: '¡Exitoso!',
          detail: 'Authority Creado',
          life: 5000,
        })
      })
    }

    //Llamamos el metodo findByName para que la busqueda actual no cambie al guardar los datos
    findByName()
    //Cuerramos el dialogo de nueva appAuthority Economica
    setAppAuthorityDialog(false)
    setAppAuthority(emptyAuthority)
    //}
  }

  //Editamos un registro
  const editAuthority = (appAuthority) => {
    setAppAuthority({ ...appAuthority })
    setAppAuthorityDialog(true)
  }

  //Mensaje de confirmación de un registro
  const confirmDeleteAuthority = (product) => {
    setAppAuthority(product)
    setDeleteAuthorityDialog(true)
  }

  //Borramos un Género
  const deleteAthority = () => {
    setSubmitted(true)
    //Cramos una instancia de nuestro servicio
    const appAuthorityService = new AppAuthorityService()
    //Enviamos los datos al servicio correspondiente
    appAuthorityService.delete(appAuthority.authId).then((data) => {
      appAuthorityService.findByName(authorityFind).then((data) => {
        console.log(data)
        setAppAuthorities(data.data.content)
        setSizeForPage(data.data.size)
        setTotalElements(data.data.totalElements)
        setTotalPages(data.data.totalPages)
        setPageNumber(data.data.number)
        setFirstPage(data.data.first)
        setLastPage(data.data.last)
      })
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      console.log(data)
      toast.current.show({
        severity: 'success',
        summary: '¡Éxitoso!',
        detail: 'Authority Eliminado',
        life: 3000,
      })
    })
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteAuthorityDialog(false)
    //Metemos al state el all de los appAuthoritys del sistemas despues de aver eliminado
    setAppAuthority(emptyAuthority)
  }

  const findByName = () => {
    //Cramos una instancia de nuestro servicio
    const appAuthorityService = new AppAuthorityService()
    //Enviamos los datos al servicio correspondiente
    appAuthorityService.findByName(authorityFind).then((response) => {
      console.log(response)
      setAppAuthorities(response.data.content)
    })
  }

  const findByNameAndPage = (pageNumber) => {
    //Cramos una instancia de nuestro servicio
    const appAuthorityService = new AppAuthorityService()
    //Enviamos los datos al servicio correspondiente
    appAuthorityService
      .findByName(authorityFind, pageNumber)
      .then((response) => {
        console.log(response)
        setAppAuthorities(response.data.content)
        setPageNumber(response.data.number)
        setFirstPage(response.data.first)
        setLastPage(response.data.last)
      })
  }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _appAuthority = { ...appAuthority }
    _appAuthority[`${name}`] = val
    setAppAuthority(_appAuthority)
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    )
  }

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.authId}
      </>
    )
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.authName}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editAuthority(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteAuthority(rowData)}
        />
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestión de Authorities</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setAuthorityFind(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveAuthority} />
    </>
  )
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteAuthorityDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteAthority} />
    </>
  )

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={appAuthorities}
            selection={selectedAuthorities}
            onSelectionChange={(e) => setSelectedAuthorities(e.value)}
            dataKey="paiId"
            rows={sizeForPage}
            className="datatable-responsive"
            totalRecords={totalElements}
            emptyMessage="No se encontraron autorizaciones en el sistema."
            header={header}
          >
            <Column
              field="authId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="authName"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>

          <div Style="text-align: center">
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={firstPage}
              onClick={() => findByNameAndPage(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={firstPage}
              onClick={() => findByNameAndPage(pageNumber - 1)}
            />

            <InputText
              type="text"
              placeholder={
                'Página ' +
                (pageNumber + 1) +
                ' de ' +
                totalPages +
                ' : : elementos ' +
                totalElements
              }
              disabled
              Style="background: transparent; color: #ffff !important; margin-right: 4px; width:18%; text-align:center"
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-right"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={lastPage}
              onClick={() => findByNameAndPage(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-right"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={lastPage}
              onClick={() => findByNameAndPage(totalPages - 1)}
            />
          </div>

          <Dialog
            visible={appAuthorityDialog}
            style={{ width: '450px' }}
            header="Datos del Authority"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="actEcoDescripcion">Nombre</label>
              <InputText
                id="actEcoDescripcion"
                value={appAuthority.authName}
                onChange={(e) => onInputChange(e, 'authName')}
                required
                autoFocus
                className={classNames({
                  'p-invalid': submitted && !appAuthority.authName,
                })}
              />
              {submitted && !appAuthority.authName && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteAuthorityDialog}
            style={{ width: '450px' }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteAuthorityDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: '2rem' }}
              />
              {appAuthority && (
                <span>
                  ¿Estás seguro de que quieres eliminar{' '}
                  <b>{appAuthority.authName}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
