import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

//component
import { CliAsociadoSeleccionar } from "../../../ClientesPages/CliAsociadoSeleccionar";

//Servicios
import AppTipoRelacionCuentaService from "../../../../service/AppServices/AppTipoRelacionCuentaService";
import CliAsociadoService from "../../../../service/CliServices/CliAsociadoService";
//funciones
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import selectText from "../../../../utilities/selecText";
export const NuevoMancomunado = ({
  mancomunado,
  setMancomunado,
  toast,
  submitted,
}) => {
  const [buscar, setBuscar] = useState(false);
  const [relaciones, setRelaciones] = useState([]);

  useEffect(() => {
    const relacionService = new AppTipoRelacionCuentaService();
    relacionService.findAll().then((res) => {
      setRelaciones(res);
    });
  }, []);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9) {
      if (
        mancomunado.asoId !== 0 &&
        mancomunado.asoId !== undefined &&
        mancomunado.asoId !== null &&
        mancomunado.asoId !== ""
      ) {
        const cliAsociadoService = new CliAsociadoService();
        cliAsociadoService.findByIdClau(mancomunado.asoId).then((res) => {
          setMancomunado({ ...mancomunado, asoNombre: res?.name });
        });
      }
    }
  };

  function renderForm() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-4">
          <label>Codigo del cliente</label>
          <div className="p-inputgroup" onKeyDown={(e) => fil(e)}>
            <InputText
              placeholder="Codigo"
              value={mancomunado?.asoId}
              onChangeCapture={(e) => {
                setMancomunado({ ...mancomunado, asoId: e.target.value });
              }}
              id="asoId"
              onClick={(e) => selectText(e)}
              className={classNames({
                "p-invalid": submitted && !mancomunado?.asoId,
              })}
            />
            <Button
              icon="pi pi-search"
              className="p-button-info"
              onClick={() => setBuscar(true)}
            />
          </div>
          {submitted && !mancomunado.asoId && (
            <small className="p-invalid">Codigo requerido</small>
          )}
        </div>
        <div className="p-col-8">
          <label>Nombre</label>
          <InputText
            disabled={true}
            placeholder="Nombre..."
            value={mancomunado?.asoNombre}
            className={classNames({
              "p-invalid": submitted && !mancomunado?.asoNombre,
            })}
          />
          {submitted && !mancomunado.asoNombre && (
            <small className="p-invalid">
              Nombre de la persona requerioda.
            </small>
          )}
        </div>
        <div className="p-col-12">
          <label>Tipo de relacion</label>
          <Dropdown
            value={mancomunado?.appTipoRelacionCuenta}
            placeholder="Seleccionar..."
            options={relaciones}
            optionLabel="relNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appTipoRelacionCuenta",
                e.value.relId,
                "relId",
                mancomunado,
                setMancomunado
              )
            }
            className={classNames({
              "p-invalid": submitted && !mancomunado?.relId,
            })}
          />
          {submitted && !mancomunado.relId && (
            <small className="p-invalid">Seleccione una opcion</small>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      {renderForm()}
      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={mancomunado}
          setPersona={setMancomunado}
          parametro1={"asoId"}
          parametro2={"asoNombre"}
          cantPar={2}
        />
      </Dialog>
    </div>
  );
};
