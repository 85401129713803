import React, { useEffect, useState } from "react";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Link } from "react-router-dom";
//Servicios
import PrePrestamoService from "../../service/PrePrestamoService/PrePrestamoService";
import useAuth from "../../auth/useAuth";

//funciones
import onChajeObjN from "../../utilities/onChajeObjN";
import dateNow from "../../utilities/dateNow";

//compnentes
import { Pagination } from "../../components/Pagination";

export const ListarPrestamo = () => {
  const auth = useAuth();
  let prestamoEmpty = {
    appFrecuenciaCapital: {
      freDias: 0,
      freId: 0,
      freMeses: 0,
      freNombre: "",
    },
    appFrecuenciaInteres: {
      freDias: 0,
      freId: 0,
      freMeses: 0,
      freNombre: "",
    },
    appFrecuenciaPlazo: {
      freDias: 0,
      freId: 0,
      freMeses: 0,
      freNombre: "",
    },
    appPreAprobacion: {
      preAproDescripcion: "",
      preAproId: 0,
      preAproLimite: true,
      preAproMax: 0,
      preAproMin: 0,
    },
    appPreDestino: {
      preDesDescripcion: "",
      preDesId: 0,
      preDesReferencia: "",
    },
    appPreGarantia: {
      preGarDescripcion: "",
      preGarId: 0,
      preGarTipo: 0,
    },
    appPreOrigenFondo: {
      preOriDescripcion: "",
      preOriId: 0,
    },
    appPreSubproducto: {
      appFrecuencia: {
        freDias: 0,
        freId: 0,
        freMeses: 0,
        freNombre: "",
      },
      appMoneda: {
        monAlfanumerico: "",
        monId: 0,
        monNombre: "",
        monSimbolo: "",
      },
      appProducto: {
        appTipoProducto: {
          proTipoId: 0,
          proTipoNombre: "",
        },
        proId: 0,
        proNombre: "",
        proTipoId: 0,
      },
      appTasa: {
        appTasaRangoList: [
          {
            tasId: 0,
            tasRangoFechaVigencia: "",
            tasRangoId: 0,
            tasRangoMontoFinal: 0,
            tasRangoMontoInicial: 0,
            tasRangoPorcentaje: 0,
          },
        ],
        appTipoTasa: {
          tasTipoId: 0,
          tasTipoNombre: "",
        },
        tasFechaIngreso: "",
        tasId: 0,
        tasNombre: "",
        tasTipoId: 0,
        tasUnica: "",
      },
      appTasaMora: {
        appTasaRangoList: [
          {
            tasId: 0,
            tasRangoFechaVigencia: "",
            tasRangoId: 0,
            tasRangoMontoFinal: 0,
            tasRangoMontoInicial: 0,
            tasRangoPorcentaje: 0,
          },
        ],
        appTipoTasa: {
          tasTipoId: 0,
          tasTipoNombre: "",
        },
        tasFechaIngreso: "",
        tasId: 0,
        tasNombre: "",
        tasTipoId: 0,
        tasUnica: "",
      },
      preSubCobraMora: true,
      preSubDescripcion: "",
      preSubDiasGracias: 0,
      preSubDiasSuspencion: 0,
      preSubFormaMora: 0,
      preSubFormaTasa: 0,
      preSubFreId: 0,
      preSubId: 0,
      preSubMonId: 0,
      preSubMontoMax: 0,
      preSubMoraId: 0,
      preSubPlazoMax: 0,
      preSubProId: 0,
      preSubProvision: 0,
      preSubTasaId: 0,
      preSubTasaMax: 0,
      preSubTasaMin: 0,
      preSubTipoCalculo: 0,
      preSubTipoMora: 0,
      preSubValorMulta: 0,
    },

    appProducto: {
      appTipoProducto: {
        proTipoId: 0,
        proTipoNombre: "",
      },
      proId: 0,
      proNombre: "",
      proTipoId: 0,
    },
    appPreTipoCompromiso: {
      preTipComAccion: "",
      preTipComId: 0,
      preTipoComDescripcion: "",
    },
    preAproId: 0,
    preAsoId: 0,
    preDesId: 0,
    preDescripcion: "",
    preDirId: null,
    preEstado: 1,
    preFechaAprobacion: "",
    preFechaContrato: "",
    preFechaModificacion:null,
    preFechaPrimerCap: null,
    preFechaPrimerInt: null,
    preFechaProximaCap: null,
    preFechaProximaInt: null,
    preFechaSuspencion: null,
    preFechaUltCap: null,
    preFechaUltInt:null,
    preFechaVencimiento: "",
    preFechaConcesion: null,
    preFechaIngreso: dateNow(),
    preFreId: 0,
    preFrePagoCapitalId: 0,
    preFrePagoInteresId: 0,
    preGarId: 0,
    preId: "",
    preInteresAcumulado: 0,
    preInteresAnticipado: 0,
    preInteresMes: 0,
    preInteresVencido: 0,
    preMontoOriginal: 0,
    preMontoSolicitado: 0,
    preMontoTotal: 0,
    preMorosidad: 0,
    preNombre: "",
    preOriId: 0,
    prePlazo: 0,
    prePrimerDesembolso: dateNow(),
    preProId: 0,
    preReferencia: "",
    preSaldoCapital: 0,
    preSaldoCapitalAtrasado: 0,
    preSubProId: 0,
    preSucId: auth.user?.usr.sucId,
    preSuspencionIntereses: true,
    preTasaBase: 0,
    preTasaFijaVariable: 0,
    preTasaMora: 0,
    preTasaMoraSpread: 0,
    preTasaSpread: 0,
    preTipoCompromisoId: 0,
    preUsuIngresa: auth.user?.usr.usuUsuario,
    preUsuModifica: null,
    preUsuPromotor: auth.user?.usr.usuUsuario,
  };

  let filtroEmpty = {
    prestamo: "",
    codigo: "",
    nombre: "",
    referencia: "",
  };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [prestamos, setPrestamos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  //const [estado, setEstado] = useState(false);
  useEffect(() => {
    const prestamoService = new PrePrestamoService();
    prestamoService
      .findByPage(
        pageNumber,
        filtro.prestamo,
        filtro.nombre,
        filtro.codigo,
        filtro.referencia
      )
      .then((result) => {
        setPrestamos(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9 || charCode === 13) {
      const prestamoService = new PrePrestamoService();
      prestamoService
        .findByPage(
          0,
          filtro.prestamo,
          filtro.nombre,
          filtro.codigo,
          filtro.referencia
        )
        .then((result) => {
          setPrestamos(result?.content);
          setTotalPages(result?.totalPages);
          setPageNumber(result?.number);
          setFirstPage(result?.first);
          setLastPage(result?.last);
        });
    }
  };

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function renderTable() {
    return (
      <>
        <DataTable
          emptyMessage="No se encontro ningun credito"
          value={prestamos}
          footer={renderPaginacion}
        >
          <Column field="preId" header="No. Prestamo" />
          <Column field="preNombre" header="Nombre Asociado" />
          <Column field="preMontoTotal" header="Monto total" />
          <Column field="preSaldoCapital" header="Saldo Capital" />
          <Column field="preFechaAprobacion" header="Fecha aprovacion" />
          <Column
            field="appPreSubproducto.preSubDescripcion"
            header="Sub producto"
          />
          <Column
            field="appPreSubproducto.appProducto.proNombre"
            header="Producto"
          />
          <Column
            field="appFrecuenciaCapital.freNombre"
            header="Frec pago capital"
          />
          <Column
            field="appFrecuenciaInteres.freNombre"
            header="Frec pago Interes"
          />
          <Column field="" header="Acciones" />
        </DataTable>
      </>
    );
  }
  function right() {
    return (
      <>
        <Link
          to={{
            pathname: "/nuevoprestamo",
          }}
        >
          <Button
            label="Nuevo"
            icon="pi pi-plus"
            className="p-button-success p-mr-2 p-button-sm"
            onClick={() =>
              sessionStorage.setItem("prestamo", JSON.stringify(prestamoEmpty))
            }
          />
        </Link>
      </>
    );
  }

  function left() {
    return (
      <div className="p-grid p-formgrid p-fluid" onKeyDown={(e) => fil(e)}>
        <div className="p-col-3">
          <span className="p-float-label p-mt-2">
            <InputText
              type={"search"}
              className="p-inputtext-sm"
              value={filtro.prestamo}
              onChange={(e) => onChajeObjN(e, "prestamo", filtro, setFiltro)}
            />

            <label>No. Prestamo</label>
          </span>
        </div>
        <div className="p-col-2">
          <span className="p-float-label p-mt-2">
            <InputText
              type={"number"}
              className="p-inputtext-sm"
              value={filtro.codigo}
              onChange={(e) => onChajeObjN(e, "codigo", filtro, setFiltro)}
            />

            <label>Codigo</label>
          </span>
        </div>
        <div className="p-col-4">
          <span className="p-float-label p-mt-2">
            <InputText
              type={"search"}
              className="p-inputtext-sm"
              value={filtro.nombre}
              onChange={(e) => onChajeObjN(e, "nombre", filtro, setFiltro)}
            />

            <label>Nombre</label>
          </span>
        </div>
        <div className="p-col-3">
          <span className="p-float-label p-mt-2">
            <InputText
              type={"search"}
              className="p-inputtext-sm"
              value={filtro.referencia}
              onChange={(e) => onChajeObjN(e, "referencia", filtro, setFiltro)}
            />

            <label>Referencia</label>
          </span>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <Toolbar className="p-mb-4" right={right} left={left} />
      {renderTable()}
    </div>
  );
};
