import { Button } from "primereact/button";
import "./Reporte.css";
import useAuth from "./../../../auth/useAuth";

import React from "react";

import { Dialog } from "primereact/dialog";
import jsPDF from "jspdf";
export const ReporteDocumento = ({
  toast,
  setImprimir,
  imprimir,
  setEstado,
  estado,
}) => {
  const auth = useAuth();
  var obj = JSON.parse(sessionStorage.getItem("reporte"));

  const hideDialog = (dato) => {
    setImprimir(false);

    if (dato === true) {
      toast.current.show({
        severity: "error",
        summary: "Cancelada",
        detail: "Clausula no impresa",
        life: 4000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: "Confirmada",
        detail: `Clausula de ${obj?.conNombre.toLowerCase()} impresa`,
        life: 4000,
      });
    }
  };

  const dayNow = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    var dayNow =
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;

    return <>{dayNow}</>;
  };
  const pdfGenerate = () => {

    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hour = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    if (hour < 10) {
      hour = "0" + hour;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    var dayNow =
      day +
      "/" +
      month +
      "/" +
      year +
      " " +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;

    var dayNowG =
      day +
      "/" +
      month +
      "/" +
      year +
      "_" +
      hour +
      ":" +
      minutes +
      ":" +
      seconds;
    var doc = new jsPDF("p", "pt", "a4");
   
    doc.setFont("arial ", "bold");

    doc.setFontSize("11.5");
    doc.text(
      "CLAUSULA DE CONSENTIMIENTO Y EXONERACIÓN",
      doc.internal.pageSize.getWidth() / 2,
      125,
      "center"
    );
    doc.text(
      "DE RESPONSABILIDAD",
      doc.internal.pageSize.getWidth() / 2,
      145,
      "center"
    );

    doc.setFont("arial", "italic");
    doc.text("Adherirla a la Solicitud de crédito", 60, 190, {
      align: "justify",
      lineHeightFactor: 1.5,
      maxWidth: doc.internal.pageSize.getWidth() / 1.25,
      marginLeft: 100,
      marginTop: 500,
      marginRight: 100,
    });

    doc.text(`Yo:       ${obj?.conNombre.toUpperCase()}`, 60, 220, {
      align: "justify",
      lineHeightFactor: 1.5,
      maxWidth: doc.internal.pageSize.getWidth() / 1.25,
      marginLeft: 100,
      marginTop: 500,
      marginRight: 100,
    });

    var text1 =
      "“Autorizo a consultar mi información en las entidades que prestan servicios de información, centrales de riesgo y buros de crédito; y autorizo expresamente a COOPERATIVA INTEGRAL DE AHORRO Y CRÉDITO “FONDOS DE AMÉRICA” RESPONSABILIDAD LIMITADA, para que pueda proporcionar todos mis datos personales y datos personales sensibles a Trans Union Guatemala, S.A., y a las entidades que prestan servicios de información, centrales de riesgo y buros de crédito; autorizo expresamente también a COOPERATIVA INTEGRAL DE AHORRO Y CRÉDITO “FONDOS DE AMÉRICA” RESPONSABILIDAD LIMITADA, a Trans Union Guatemala, S. A. y a las entidades que prestan servicios de información, centrales de riesgo y burós de crédito  a recopilar, difundir, distribuir y comercializar mi información personal y de comportamiento crediticio, con la finalidad de verificación y análisis de otorgamiento crediticio.  Esta autorización se otorga bajo reserva del derecho de actualizar y rectificar la información, si se comprueba que los datos son erróneos, incompletos o inexactos.  En virtud de lo autorizado en este documento y cláusula específica, resulta inaplicable lo estipulado en el Código Penal y la Ley de Acceso a la Información Pública, por lo que exonero de cualquier responsabilidad A LA COOPERATIVA INTEGRAL DE AHORRO Y CRÉDITO “FONDOS DE AMÉRICA” RESPONSABILIDAD LIMITADA, a Trans Union Guatemala, S.A. y a las entidades que prestan servicios de información, centrales de riesgo y buros de crédito, por la difusión, distribución y comercialización de mi información.”";
    doc.text(text1, 60, 250, {
      align: "justify",
      lineHeightFactor: 1.5,
      maxWidth: doc.internal.pageSize.getWidth() / 1.25,
      marginLeft: 100,
      marginTop: 500,
      marginRight: 100,
    });

    doc.setFont("arial", "normal");
    doc.text(
      `Lugar y fecha:       ${auth.user?.usr.appSucursal.sucDireccion}. ${dayNow}`,
      60,
      575,
      {
        align: "justify",
        lineHeightFactor: 1.5,
        maxWidth: doc.internal.pageSize.getWidth() / 1.25,
        marginLeft: 100,
        marginTop: 500,
        marginRight: 100,
      }
    );
 

    doc.setFontSize("11.5");
    doc.text(
      "F ________________________________",
      doc.internal.pageSize.getWidth() / 2,
      625,
      "center"
    );
    doc.setFontSize("11");
    doc.text(
      `${obj?.conNombre.toUpperCase()}`,
      doc.internal.pageSize.getWidth() / 2,
      640,
      "center"
    );
    doc.text(
      `${obj?.conIdentificacion}`,
      doc.internal.pageSize.getWidth() / 2,
      655,
      "center"
    );
    var nombre = obj?.conNombre;
    let text2 = nombre.split(" ").join("_");

    doc.save(`clausula-${text2.toUpperCase()}-${dayNowG}.pdf`);
  };

  const footerDialog = () => {
    return (
      <div>
        <Button
          className="p-button-danger p-button "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => {
            hideDialog(true);
          }}
        />

        <Button
          className=" p-button "
          icon="pi pi-print"
          label="Imprimir"
          onClick={() => {
            pdfGenerate();
            hideDialog(false);
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={imprimir}
        header="Solicitud"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
        footer={footerDialog}
        className="p-fluid"
        onHide={() => {
          hideDialog(true);
        }}
        maximizable={true}
      >
        <div className="card " style={{ marginTop: "0.5cm" }}>
          <div className="hoja">
            <div className="titulo">
              <h5>CLAUSULA DE CONSENTIMIENTO</h5>
            </div>
            <p>Adherirla a la Solicitud de crédito</p>
            <p>
              Yo cliente:{"     "}
              {obj?.conNombre.toUpperCase()}{" "}
            </p>
            <p>
              “CLÁUSULA DE CONSENTIMIENTO Y EXONERACIÓN DE RESPONSABILIDAD.
              Autorizo a consultar mi información en las entidades que prestan
              servicios de información, centrales de riesgo y buros de crédito;
              y autorizo expresamente a COOPERATIVA INTEGRAL DE AHORRO Y CRÉDITO
              “FONDOS DE AMÉRICA” RESPONSABILIDAD LIMITADA, para que pueda
              proporcionar todos mis datos personales y datos personales
              sensibles a Trans Union Guatemala, S.A., y a las entidades que
              prestan servicios de información, centrales de riesgo y buros de
              crédito; autorizo expresamente también a COOPERATIVA INTEGRAL DE
              AHORRO Y CRÉDITO “FONDOS DE AMÉRICA” RESPONSABILIDAD LIMITADA, a
              Trans Union Guatemala, S. A. y a las entidades que prestan
              servicios de información, centrales de riesgo y burós de crédito a
              recopilar, difundir, distribuir y comercializar mi información
              personal y de comportamiento crediticio, con la finalidad de
              verificación y análisis de otorgamiento crediticio. Esta
              autorización se otorga bajo reserva del derecho de actualizar y
              rectificar la información, si se comprueba que los datos son
              erróneos, incompletos o inexactos. En virtud de lo autorizado en
              este documento y cláusula específica, resulta inaplicable lo
              estipulado en el Código Penal y la Ley de Acceso a la Información
              Pública, por lo que exonero de cualquier responsabilidad A LA
              COOPERATIVA INTEGRAL DE AHORRO Y CRÉDITO “FONDOS DE AMÉRICA”
              RESPONSABILIDAD LIMITADA, a Trans Union Guatemala, S.A. y a las
              entidades que prestan servicios de información, centrales de
              riesgo y buros de crédito, por la difusión, distribución y
              comercialización de mi información.”
            </p>
            <p>
              Lugar y fecha {auth.user?.usr.appSucursal.sucDireccion} {dayNow()}
            </p>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
