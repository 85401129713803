import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
//components
import { NuevoBeneficiario } from "./NuevoBeneficiario";
//SERVICIOS
import AhoBeneficiarioService from "../../../../service/AhoCuentaService/AhoBeneficiarioService";

export const BeneficiarioComponent = ({ ahorro, toast }) => {
  let beneficiarioEmpty = {
    ahoId: "",
    appParentesco: {
      parId: 0,
      parNombre: "",
    },
    asoId: 0,
    benDireccion: "",
    benId: 0,
    benNombre: "",
    benNumeroIdentificacion: "",
    benPorcentaje: 0,
    benTelefono: "",
    parId: 0,
  };
  const [beneficiario, setBeneficiariio] = useState(beneficiarioEmpty);
  const [beneficiarios, setBeneficiarios] = useState([]);
  const [nuevoBenificiario, setNuevoBenificiario] = useState(false);
  const [estado, setEstado] = useState(false);
  const [totalPor, setTotalPor] = useState(0);
  const [editar, setEditar] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    var ahoService = new AhoBeneficiarioService();
    ahoService.findById(ahorro?.ahoId).then((response) => {
      setBeneficiarios(response);
      var total = 0;
      for (var i = 0; i < response?.length; i++) {
        total += Number(response[i]?.benPorcentaje);
      }
      setTotalPor(total);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  let footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          footer="Total de porsentaje:"
          colSpan={4}
          footerStyle={{ textAlign: "right" }}
        />
        <Column footer={`${totalPor} %`} />
      </Row>
    </ColumnGroup>
  );

  const porsentaje = (rowData) => {
    return rowData.benPorcentaje + " %";
  };

  const acciones = (rowData) => {
    return (
      <>
        <Button
          // label="Nuevo"
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => 
        {    setNuevoBenificiario(true)
            setEditar(true)
            setBeneficiariio(rowData)
            setTotalPor(totalPor - rowData.benPorcentaje)
          }}
        />
        <Button
          // label="Nuevo"
          className="p-button-danger p-button-rounded p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setVisible(true);setBeneficiariio(rowData)}}
        />
      </>
    );
  };

  function tableBenificiario() {
    return (
      <div>
        <DataTable
          value={beneficiarios}
          style={{ backgroundColor: "#f5f5f5" }}
          responsive={true}
          lazy={true}
          footerColumnGroup={footerGroup}
        >
          <Column field="benNombre" header="Nombre" />
          <Column field="benNumeroIdentificacion" header="Identificacion" />
          <Column field="benDireccion" header="Direccion" />
          <Column
            field="benTelefono"
            header="Télefono "
            style={{ textAlign: "center" }}
          />
          <Column
            field="appParentesco.parNombre"
            header="Tipo de relacion"
            style={{ textAlign: "center" }}
          />
          <Column
            field="benPorcentaje"
            body={porsentaje}
            header="Porcentaje"
            style={{ textAlign: "center" }}
          />
          <Column
            header="Acciones"
            body={acciones}
            style={{ textAlign: "center" }}
          />
        </DataTable>
      </div>
    );
  }

  function eliminarBeneficiario() {
    var ahoService = new AhoBeneficiarioService();
    ahoService.delete(beneficiario?.benId).then(() => {
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Accion realizada con exito",
        detail: `Beneficiario eliminado`,
        life: 4000,
      });

      setEstado(!estado);
    });
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => 
       {   setVisible(false);
          toast.current.show({
            severity: "error",
            summary: "Accion cancelada",
            detail: `Beneficiario no eliminado`,
            life: 4000,
          })
        }}
      />
      <Button icon="pi pi-check" label="Si" onClick={eliminarBeneficiario} />
    </>
  );
  return (
    <div>
      <div className="p-grid  p-mt-2">
        <div className="p-col">
          <h4>Beneficiarios</h4>
        </div>
        <div
          className="p-col"
          style={{ textAlign: "right", alignItems: "right" }}
        >
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-warning"
            onClick={() => {
              setNuevoBenificiario(true);
              setEditar(false);
              setBeneficiariio(beneficiarioEmpty);
            }}
            disabled={totalPor >= 100}
          />
        </div>
      </div>

      <div className="">{tableBenificiario()}</div>
      <Dialog
        header="Nuevo Benificiario"
        visible={nuevoBenificiario}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
        onHide={() => 
        { setNuevoBenificiario(false);
          toast.current.show({
            severity: "error",
            summary: "Accion cancelada",
            detail: "Benificiario no agregregado /editado",
            life: 4000,
          })
        }}
      >
        <NuevoBeneficiario
          ahorro={ahorro}
          setEstado={setEstado}
          estado={estado}
          beneficiarioHook={beneficiario}
          setNuevoBenificiario={setNuevoBenificiario}
          totalPor={totalPor}
          editar={editar}
          toast={toast}
        />
      </Dialog>

      <Dialog
        header="Mensaje del sistema"
        visible={visible}
        onHide={() => 
      {    setVisible(false);
          toast.current.show({
            severity: "error",
            summary: "Accion cancelada",
            detail: `Beneficiario no eliminado`,
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>
            Confirme la eliminacion del Beneficiario:{" "}
            <b>{beneficiario?.benNombre}</b>
          </span>
        </div>
      </Dialog>
    </div>
  );
};
