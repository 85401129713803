import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

//Nombre correcto AppProducto

export default class AppTipoProductoService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appproducto/tipo/1`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response;
    } catch (err) {
      addressingError(err);
    }
  }
  async findAllCre() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appproducto/tipo/2`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response;
    } catch (err) {
      addressingError(err);
    }
  }

  //Roles no asignados al usuario
  async findByID(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appproducto/${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/appproducto/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByPage(pageNumber, name, idTipoProducto) {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/appproducto/page/${name || "0"}/${
          idTipoProducto || "0"
        }?page=${pageNumber}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      //body: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
