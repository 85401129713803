import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Message } from "primereact/message";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import classNames from "classnames";
import useAuth from "../auth/useAuth";
import Api from "../Api";

export const Login = () => {
  const history = useHistory();
  let location = useLocation();
  const auth = useAuth();
  const previusObjectURL = location.state?.from;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let emptyUsr = {
    username: null,
    password: null,
  };

  const [usr, setUsr] = useState(emptyUsr);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  const [server, setServer] = useState(false);

  const credenciales = btoa(
    "reactapp" +
      ":" +
      "$2a$12$1eu3r8KvaU0fatGqkGHA4.Iy745ScC6RnAFugNzwg6JyVJ9LxWAhu"
  );

  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Basic " + credenciales);

  var formdata = new FormData();
  formdata.append("username", usr.username);
  formdata.append("password", usr.password);
  formdata.append("grant_type", "password");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  const iniciarSesion = async () => {
    const api = new Api();
    if (usr.password && usr.username != null) {

      try {  setServer(false);
        setSubmitted(false);

        let res = await fetch(api.baseUrl() + "/oauth/token", requestOptions);
        let json = await res.json();

        if (res.status === 200) {
          setEstado(true);
          auth.login(json.access_token);
          //obtenemos datos del usuario
          let config = {
            method: "GET",
            headers: {
              Authorization: `Bearer ${json.access_token}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };

          let res = await fetch(
            api.baseUrl() + "/appusuario/" + usr.username,
            config
          );
          if (res.status === 200) {
            setEstado(true);
            let usuario = await res.json();
            auth.login(json.access_token, usuario);
            history.push("/");
            window.location.reload();
          } else if (res.status === 403) {
            history.push(previusObjectURL || "/access");
          }
        } else if (res.status === 401) {
          setEstado(true);
        } else if (res.status === 403) {
          history.push(previusObjectURL || "/");
        } else {
          setSubmitted(true);
     

        }
      } catch (err) {
    
        setServer(true);
      }
    } else {
      setSubmitted(true);

    }
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _usr = { ...usr };
    _usr[`${name}`] = val;
    setUsr(_usr);
  };

  const handleLogin = () => {
    iniciarSesion();
    setEstado(false);
  };

  const login = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 13) {
      handleLogin();
    }
  };

  return (
    <div className="login-body">
      <div className="login-wrapper">
        {/*
        <div className="login-image">
          <div className="login-image-content">
            
            <h1>Accede a tu</h1>
            <h1>MyCoop</h1>
            <h1>Aquí</h1>
            <h3>
              Accede para agregar nuevos asociados <br />
              abrir cuentas de ahorro corriente, a plazo fijo
              <br />y desembolsosde manera fácil y divertida.
            </h3>
          </div>
          <div className="image-footer">
            <p>Síguenos en nuestras redes sociales @mycoop.</p>
            <div className="icons">
              <i className="pi pi-github"></i>
              <i className="pi pi-twitter"></i>
            </div>
            
          </div>
        </div>
        */}

        {/* <div
        className="p-mt-4"
        style={{
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <img
          src="assets/layout/images/logo-dark.svg"
          class="logo"
          alt="diamond-layout"
        />
      </div> */}

        <div className="login-panel">
          <div className="limiter">
            <div className="container-login100">
              <div
                className="card"
                style={{
                  width: "390px",
                  background: "#fff",
                  // border-radius: 10px;
                  overflow: "hidden",
                  padding: "50px 55px 33px 55px",
                }}
              >
                <form
                  className="login100-form validate-form"
                  onKeyDown={(e) => login(e)}
                >
                  <span className="login100-form-title p-mt-1 p-mb-4">
                    <img
                      src="assets/layout/images/logo-dark.svg"
                      alt="diamond-layout"
                    />
                  </span>
                  <span className="login100-form-title">Iniciar Sesión</span>
                  <span className="login100-form-title">
                    <i className="zmdi zmdi-font"></i>
                  </span>

                  <div
                    className="p-fluid p-grid p-formgrid p-mb-4 p-mt-4"
                    data-validate="Valid email is: a@b.c"
                  >
                    <InputText
                      placeholder="Usuario"
                      value={usr.username || ""}
                      onChange={(e) => onInputChange(e, "username")}
                      required
                      className={classNames({
                        "p-invalid": submitted && !usr.username,
                      })}
                    />
                    {submitted && !usr.username && !server && (
                      <Message
                        severity="error"
                        text="Se requiere nombre de usuario"
                      />
                    )}
                  </div>

                  <div
                    className="p-fluid p-grid p-formgrid p-mb-4 p-mt-4"
                    data-validate="Enter password"
                  >
                    <Password
                      style={{ width: "100%" }}
                      placeholder="Contraseña"
                      value={usr.password || ""}
                      onChange={(e) => onInputChange(e, "password")}
                      required
                      className={classNames({
                        "p-invalid": submitted && !usr.password,
                      })}
                      toggleMask={true}
                    />
                    {submitted && !usr.password && !server && (
                      <Message
                        severity="error"
                        text="Se requiere contraseña de usuario"
                      />
                    )}
                  </div>

                  <div className="p-fluid p-grid p-formgrid p-mb-6">
                    <Button
                      style={{ display: "block" }}
                      onClick={handleLogin}
                      label="ACCEDER"
                      type="button"
                      className={classNames({
                        "p-invalid": submitted && !estado,
                      })}
                    ></Button>
                    <br />
                    {submitted &&
                      !estado &&
                   
                      usr.username != null &&
                      usr.password != null && (
                        <Message
                          severity="error"
                          text="* Por favor revisa tu usuario y contraseña. Los campos son sensibles a mayúsculas y minúsculas."
                        />
                      )}
                    { server && (
                      <Message
                        severity="error"
                        text="* Ocurrió un error al conectarse con el servidor. Contacte al Administrador."
                      />
                    )}
                  </div>

                  {/* <div className="text-center p-t-115 p-mt-4">
                <span className="txt1">Don’t have an account?</span>

                <a className="txt2" href="#">
                  Sign Up
                </a>
              </div> */}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
