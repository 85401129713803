import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { InputMask } from "primereact/inputmask";
import { ReporteDocumento } from "../ReporteDocumento";
import { Toast } from "primereact/toast";
import { NuevoDocumento } from "../NuevoDocumento";
import { Calendar } from "primereact/calendar";
//Service
import ClausulaService from "./../../../service/ClausulaConsentimiento/ClausulaService";

export const ListaDocumento = () => {
  const toast = useRef();
  let filtroEmpty = {
    nombre: "",
    identificacion: "",
    fecha: "",
  };

  // let reporteEmpty = {
  //   conFechaIngreso: "",
  //   conId: 0,
  //   conIdentificacion: "",
  //   conNombre: "",
  //   conSucId: 0,
  //   conUsuUsuarioIngresa: "",
  // };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [imprimir, setImprimir] = useState(false);
  const [clausulas, setClausulas] = useState([]);
  const [newclausula, setNewClausula] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [estado, setEstado] = useState(false);

  const [date3, setDate3] = useState(null);
useEffect(()=>{setDate3(null)},[])
  useEffect(() => {
    peticion();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber,estado, filtro.fecha]);



  function peticion() {

    const clausulaService = new ClausulaService();
    clausulaService
      .findByPage(
        pageNumber,
        filtro.nombre,
        filtro.identificacion,
        filtro.fecha
      )
      .then((response) => {
        setClausulas(response.content);
        setTotalPages(response.totalPages);
        setFirstPage(response.first);
        setLastPage(response.last);
      });
  }

  const newClausula = () => {
    setNewClausula(true);
  };

  const onClick = (rowData) => {
    setImprimir(true);
    sessionStorage.setItem("reporte", JSON.stringify(rowData));
  };

  const onChangeFiltro = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;
    setFiltro(_filtro);
  };

  const onChangeFiltroDate = (value) => {
    let _filtro = { ...filtro };
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate();
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = yy + "-" + mm + "-" + dd;
    _filtro[`fecha`] = myDateString;

    setFiltro(_filtro);
  };

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      console.log("fil")
      const clausulaService = new ClausulaService();
      clausulaService
        .findByPage(
          pageNumber,
          filtro.nombre.toUpperCase(),
          filtro.identificacion.toUpperCase(),
          filtro.fecha
        )
        .then((response) => {
          setClausulas(response.content);

          setTotalPages(response.totalPages);

          setFirstPage(response.first);
          setLastPage(response.last);
          setPageNumber(0)
        });
    }
  };

  const formatDate = (value) => {
    const event = new Date(value);

    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "/" + mm + "/" + yy;

    return myDateString;
  };

  const dateTable = (rowData) => {
    return formatDate(rowData.conFechaIngreso);
  };

  const rightContents = (
    <>
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="p-button-success p-mr-2"
        onClick={() => newClausula()}
      />
    </>
  );
  const acciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-info mr-2 p-mr-2"
          icon="pi pi-print"
          onClick={() => onClick(rowData)}
          tooltip={"Imprimir "}
        />
      </>
    );
  };

  const renderPaginacion = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-double-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(0)}
        />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        />

        <InputText
          type="text"
          className="p-inputtext p-component"
          placeholder={pageNumber + 1 + " de " + totalPages}
          style={{
            width: "100px",
            height: "30px",
            borderColor: "#5499C7",
            textAlign: "center",

            fontSize: "15px",
            background: "transparent",
          }}
          readOnly
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-right"
          style={{
            background: "transparent; color: #5499C7",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(pageNumber + 1)}
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-double-right"
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(totalPages - 1)}
        />
      </div>
    );
  };

  const leftContents = (
    <>
      <div
        className="p-fluid p-col-7"
        onKeyDown={(e) => fil(e)}
        style={{ marginTop: "1px" }}
      >
        <span className="p-float-label">
          <InputText
            value={filtro.nombre}
            onChange={(e) => onChangeFiltro(e, "nombre")}
          />
          <label>Nombre</label>
        </span>
      </div>
      <div className="p-fluid   p-col-4" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputMask
            mask="9999 99999 9999"
            value={filtro.identificacion}
            onChange={(e) => onChangeFiltro(e, "identificacion")}
          />
          <label>Identificación</label>
        </span>
      </div>
      <div className="p-fluid p-col-4">
        <span className="p-float-label">
          {/* <Calendar
            inputId="calendar"
            value={filtro.fecha}
            onChange={(e) => onChangeFiltroDate(e, "fecha")}
            type="date"
          /> */}
          <Calendar
            id="icon"
            type="date"
            value={date3}
            onChange={(e) => {
              onChangeFiltroDate(e.value);
            }}
            dateFormat="dd-mm-yy"
            showIcon
            readOnly
          />

          <label htmlFor="calendar">Calendar</label>
        </span>
      </div>
    </>
  );

  const renderTablaClausula = () => {
    return (
      <DataTable value={clausulas} footer={renderPaginacion()}>
        <Column field="conId" header="Id" />
        <Column field="conNombre" header="Nombre" />
        <Column field="conIdentificacion" header="Identificación" />
        <Column
          field="conFechaIngreso"
          body={dateTable}
          header="Fecha Ingreso"
        />
        <Column
          header="Acciones"
          body={acciones}
          style={{ textAlign: "center" }}
        />
      </DataTable>
    );
  };
  return (
    <>
      <div className="card">
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <div className="card">{renderTablaClausula()}</div>

      <ReporteDocumento
        imprimir={imprimir}
        setImprimir={setImprimir}
        toast={toast}
        setEstado={setEstado}
        estado={estado}
      />
      <NuevoDocumento
        newclausula={newclausula}
        setNewClausula={setNewClausula}
        toast={toast}
        setImprimir={setImprimir}
        setEstado={setEstado}
        estado={estado}
      />
      <Toast ref={toast} />
    </>
  );
};
