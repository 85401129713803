import React, { useState, useEffect } from "react";
import useAuth from "../../../../auth/useAuth";
import dateLastMonth from "../../../../utilities/dateLastMonth";
import dateNow from "../../../../utilities/dateNow";
import formatDate from "../../../../utilities/formatDateTable";
import AhoBeneficiarioService from "../../../../service/AhoCuentaService/AhoBeneficiarioService";
import CliAsociadoService from "../../../../service/CliServices/CliAsociadoService";
export const PrintFirma = ({ impresionFirma,ahorro, firmas}) => {

useEffect(()=>{
    impresionFirma()
},[impresionFirma])


  const auth = useAuth();

  let filtro = {
    fechaIni: dateLastMonth(),
    fechaFin: dateNow(),
  };

  let estilosTabla = {
    izquierda: {
      textAlign: "left",
      padding: "0.1px",
    },
    izquierdaBold: {
      textAlign: "left",
      padding: "0.1px",
      fontWeight: "bold",
    },
  };

  const [beneficiarios, setBeneficiarios] = useState([]);
  const [aso,setAso] = useState({
    identificacion: "0000"
  })
  useEffect(() => {
    var ahoService = new AhoBeneficiarioService();
    ahoService.findById(ahorro?.ahoId).then((response) => {
      setBeneficiarios(response);
    });

    const appAsociadoService = new CliAsociadoService();

    appAsociadoService.findAdd(0,ahorro?.asoId).then((res)=>{
       setAso(res?.data?.content[0])
    })
  }, [ahorro?.ahoId,ahorro?.asoId]);

  return (
    <div id="printable-content">
      <div>
        <div
          style={{
            textAlign: "center",
            borderBottom: "1px solid black",
            padding: "10px",
          }}
        >
          <h5 className="p-m-1">
            {auth.user?.usr?.appSucursal?.appEmpresa?.empNombre}
          </h5>
          <h5 className="p-m-1">{auth.user?.usr?.appSucursal?.sucNombre}</h5>
          <h5 className="p-m-1">HOJA DE INGRESO DE FIRMAS POR CUENTA</h5>
          <h5 className="p-m-1" style={{ textAlign: "right" }}>
            {formatDate(filtro?.fechaFin)}
          </h5>
        </div>
        <div
          style={{
            borderBottom: "1px solid black",
            padding: "10px",
            textAlign: "left",
          }}
        >
          <table style={{ width: "100%" }}>
            <tbody>
              <tr>
                <td style={estilosTabla.izquierdaBold}>FIRMAS DE LA CUENTA</td>
                <td style={estilosTabla.izquierdaBold}>APERTURA DE CTA.</td>
                <td style={estilosTabla.izquierda}>
                  {formatDate(ahorro?.ahoFechaApertura)}
                </td>
              </tr>
              <tr>
                <td style={estilosTabla.izquierdaBold}>{ahorro.ahoId}</td>
                <td style={estilosTabla.izquierda}>
                  {ahorro?.appAhoSubProducto?.subAhoNombre}
                </td>
                <td style={estilosTabla.izquierda}>
                  {ahorro?.appAhoSubProducto?.appMoneda?.monNombre}
                </td>
              </tr>
              <tr>
                <td style={estilosTabla.izquierda}>{ahorro?.asoId}</td>
                <td style={estilosTabla.izquierda}>{ahorro.ahoNombre}</td>
                <td style={estilosTabla.izquierda}></td>
              </tr>
              <tr>
                <td style={estilosTabla.izquierdaBold}>
                  USUARIO INGRESO/MODIFICO
                </td>
                <td style={estilosTabla.izquierdaBold}>
                  {ahorro?.usuModifica === ""
                    ? ahorro?.usuIngresa
                    : ahorro?.usuModifica}
                </td>
                <td style={estilosTabla.izquierda}></td>
              </tr>
            </tbody>
          </table>
          <div className="p-mt-3">
            <strong> DIRECCIÓN Y TELÉFONOS REGISTRADOS: </strong>
            <br />
            {ahorro?.cliDireccion?.dirDireccion},{" "}
            {ahorro?.cliDireccion?.appMunicipio?.munNombre},{" "}
            {ahorro?.cliDireccion?.appDepartamento?.depNombre},{" "}
            {ahorro?.cliDireccion?.appPais?.paiNombre}
            <br />
            <br />
            <strong> BENEFICIARIOS </strong>
            {beneficiarios.map((item, index) => (
              <div key={item.benId}>
                {index + 1}
                {".  "} {item.benNombre}, {item.benPorcentaje}%,{" "}
                {item.appParentesco.parNombre} , {item.benDireccion}
              </div>
            ))}
            <table style={{ width: "100%" }} className="p-mt-3">
              <tbody>
                <tr>
                  <td style={estilosTabla.izquierdaBold}>
                    FIRMAS REGISTRADAS: {ahorro?.ahoNumeroFirmaRegistra}
                  </td>
                  <td style={estilosTabla.izquierdaBold}>
                    FIRMAS REQUERIDAS: {ahorro?.ahoNumeroFirmaRequiere}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        style={{
          padding: "10px",
        }}
      >
        <strong> INSTRUCCIONES: </strong>Firme y escanee el cuadro y
        coloque el nombre del archivo igual al indicado con terminacion JPG
        <br />
        <div className="p-grid p-mt-4 p-ml-5 ">
          <div className="p-col-5">
            <strong>NO. FIRMA: 1 </strong> <br />
            {ahorro?.ahoNombre}
            <div
              style={{
                width: "300px",
                height: "150px",
                border: "1px solid black",
                alignItems: 'flex-end',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
{aso?.identificacion}

            </div>
          </div>

          {firmas.map((item, index) => (
              <div key={index+1} className="p-col-5">
              <strong>NO. FIRMA: {index+2}</strong> <br />
              {item?.asoNombre}
              {console.log(item)}
              <div
                style={{
                  width: "300px",
                  height: "150px",
                  border: "1px solid black",
                  alignItems: 'flex-end',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                {item?.identificacion}
              </div>
              </div>
            ))}


        </div>
      </div>
    </div>
  );
};
