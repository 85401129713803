import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AhoCuentaService {
  async findByPage(pageNumber, data) {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/ahocuenta/page/${data.ahoId || 0}/${data.asoId || 0}/${
          data.ahoNom.toUpperCase() || 0
        }/${
          data.ahoEst + "" === "5" ? 0 : data.ahoEst || 0
        }?page=${pageNumber}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    // try {
    //   let config = {
    //     method: "POST",
    //     headers: {
    //       Authorization: `Bearer ${jwt?.jwt}`,
    //       Accept: "application/json",
    //       "Content-Type": "application/json",
    //     },
    //     body: JSON.stringify(data),
    //   };

    //   let res = await fetch(api.baseUrl() + "/ahocuenta/save", config);
    //   let json = await res.json();
    //   return json;
    // } catch (err) {
    //   addressingError(err);
    // }



    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/ahocuenta/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }

  }

  async findById(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/ahocuenta/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
