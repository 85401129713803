import formatMiles from "../../../../../utilities/formatMiles";
import numberToLetters from "../../../../../utilities/numberToLetters";
import formatDate from "../../../../../utilities/formatDateTable";
import { useEffect, useState } from "react";
import AhoBeneficiarioService from "../../../../../service/AhoCuentaService/AhoBeneficiarioService";
import useAuth from "../../../../../auth/useAuth";
// import dateTimeNow from "../../../../../hooks/dateTimeNow";
// import dateNow from "../../../../../hooks/dateNow";
import dateTimeNowNormal from "../../../../../utilities/dateTimeNowNormal";
export const ImpresionCerificado = ({ setImprimir, ahorro, handlePrint }) => {
  let estilosTabla = {
    izquierda: {
      textAlign: "left",
      padding: "0.1px",
    },
    izquierdaBold: {
      textAlign: "left",
      padding: "0.1px",
      fontWeight: "bold",
    },
    derecha: {
      textAlign: "right",
      padding: "0.1px",
    },
    derechaBold: {
      textAlign: "right",
      padding: "0.1px",
      fontWeight: "bold",
    },
    col1: {
      textAlign: "left",
      padding: "0.1px",
      fontWeight: "bold",
      width: "120px",
    },
    col3: {
      textAlign: "left",
      padding: "0.1px",
      fontWeight: "bold",
      width: "190px",
    },

    col2: {
      textAlign: "left",
      padding: "0.1px",
      width: "270px",
    },
    col3N: {
        textAlign: "left",
        padding: "0.1px",
    
        width: "190px",
      },
      col4:{
        textAlign: "left",
        padding: "0.1px",
    
        width: "100px",
      }
  };
  const auth = useAuth();

  const [beneficiarios,setBeneficiarios] = useState([])
  useEffect(()=>{

    const serviceBeneficiarios = new AhoBeneficiarioService()
    serviceBeneficiarios.findById(ahorro?.ahoId || "0").then((res) => {
        console.log(res)
        setBeneficiarios(res);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div

      style={{ padding: "80px", alignContent: "center", fontFamily:"arial",fontSize:"13px"}}
      id="printable-content"
    >
      <table style={{ width: "95%" }}>
        <tbody>
          <tr>
            <td style={estilosTabla.col1}></td>
            <td style={estilosTabla.col2}></td>
            <td style={estilosTabla.col3}>Certificado</td>
            <td style={estilosTabla.izquierda}>
            ***{ahorro?.ahoNumeroCertificado || "Sin certificado"}***
            </td>
          </tr>
          <tr>
            <td style={estilosTabla.col1}>Código Asociado: </td>
            <td style={estilosTabla.izquierda}>{ahorro?.asoId}</td>
            <td style={estilosTabla.izquierdaBold}>Cuenta No.:</td>
            <td style={estilosTabla.izquierda}>
              {ahorro?.ahoId || "Cuenta no encontrada"}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "95%" }}>
        <tbody>
          <tr>
            <td style={estilosTabla.col1}>Recibimos de: </td>
            <td style={estilosTabla.izquierda}>
              {ahorro?.ahoNombre.toUpperCase() || "Asociado no encontrado"}
            </td>
          </tr>

          <tr>
            <td style={estilosTabla.col1}>Dirección exacta: </td>
            <td style={estilosTabla.izquierda}>
              {ahorro?.cliDireccion?.dirDireccion.toUpperCase() ||
                "Sin Dirección exacta"}
              ,{" "}
              {ahorro?.cliDireccion?.appMunicipio?.munNombre.toUpperCase() ||
                "Sin Municicpio"}{" "}
              {ahorro?.cliDireccion?.appMunicipio?.appDepartamento?.depNombre.toUpperCase() ||
                "Sin Departamento"}{" "}
              {ahorro?.cliDireccion?.appPais?.paiNombreEn.toUpperCase() ||
                "Sin Pais"}
            </td>
          </tr>

          <tr>
            <td style={estilosTabla.col1}>La cantidad de:</td>
            <td style={estilosTabla.izquierda}>
              ***{numberToLetters(ahorro?.ahoMontoInicial)} ***
            </td>
          </tr>
          <tr>
            <td style={estilosTabla.izquierdaBold}>GTQ</td>
            <td style={estilosTabla.izquierda}>
              {formatMiles(ahorro?.ahoMontoInicial)}
            </td>
          </tr>
        </tbody>
      </table>
      <table style={{ width: "95%" }}>
        <tbody>
          <tr>
            <td style={estilosTabla.col1}>A plazo de: </td>
            <td style={estilosTabla.col2}>{ahorro.ahoPlazoDeposito} Días</td>
            <td style={estilosTabla.col3}>Vence:</td>
            <td style={estilosTabla.col4}>
              {formatDate(ahorro?.ahoFechaVencimiento)}
            </td>
          </tr>
          <tr>
            <td style={estilosTabla.col1}>Tasa de interés pactada:</td>
            <td style={estilosTabla.col2}>
              {Number(ahorro?.ahoTasaBase) + Number(ahorro?.ahoTasaNegociado)}%
              Anual
            </td>
            <td style={estilosTabla.col3}>Los cuales serán pagados:</td>
            <td style={estilosTabla.col4}>
            {ahorro?.appFrecuencia?.freNombre || "Mensual"}
            </td>
          </tr>
         
        </tbody>
      </table>
      Al momento de la liquidación se harán las retenciones de ley, impuestos sobre la renta.
      <table style={{ width: "95%" }}>
        <tbody>
          <tr>
            <td style={estilosTabla.col1}>Tipo de Ahorro: </td>
            <td style={estilosTabla.izquierda}>{ahorro?.appAhoSubProducto.subAhoNombre}</td>
          </tr>
          </tbody>
        </table>
     
      <table style={{ width: "95%" }}>
        <tbody>
                {beneficiarios?.map((beneficiario,index)=>(

                       <tr key={index}>
                       <td style={estilosTabla.col1}>{index === 0 ? "Beneficiarios": ""}</td>
                       <td style={estilosTabla.col2}>
                      {beneficiario?.benNombre}
                       </td>
                       <td style={estilosTabla.col3N}>  {beneficiario?.appParentesco?.parNombre}</td>
                       <td style={estilosTabla.col4}>
                       {beneficiario?.benPorcentaje} %
                       </td>
                     </tr>
                ))}
         
        </tbody>
        
      </table>
      <table style={{ width: "95%" }}>
        <tbody>
        <tr>
            <td style={estilosTabla.col1}>Lugar y fecha: </td>
            <td style={estilosTabla.izquierda}>{auth.user?.usr.appSucursal.sucDireccion} ,{dateTimeNowNormal()}</td>
          </tr>
        </tbody>
        
      </table>

    </div>
  );
};
