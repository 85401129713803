import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class CjaTransaccionService {

    async findAll(){
        if (token) {
            jwt = JSON.parse(token);
          }
          var config = {
            method: "get",
            url:
              api.baseUrl() +
              `/appcjatransaccion/all`,
            headers: {
              Authorization: `Bearer ${jwt?.jwt}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          };

          try {
            const response = await axios(config);
            return response.data;
          } catch (err) {
            addressingError(err);
          }

    }


}