import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
//FUNCIONES
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
import { InputTextarea } from "primereact/inputtextarea";
export const NuevoDestino = ({destino, setDestino, submitted }) => {
  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col-12">
        <label>Descripcion</label>
        <InputText
        id="des"
            value={destino.preDesDescripcion}
            onChange={(e) =>
              onChajeObjN(e, "preDesDescripcion", destino, setDestino)
            }
            placeholder="Descripcion"
            className={classNames({
              "p-invalid": submitted && !destino.preDesDescripcion,
            })}
            onClick={(e)=>selectText(e)}
          />
          {submitted && !destino.preDesDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}

        </div>
        <div className="p-col-12">
        <label>Referencia</label>
        <InputTextarea
        id="ref"
          value={destino.preDesReferencia}
          onChange={(e) =>
            onChajeObjN(e, "preDesReferencia", destino, setDestino)
          }
          placeholder="Referencia"
          className={classNames({
            "p-invalid": submitted && !destino.preDesReferencia,
          })}
          rows={4}
          onClick={(e)=>selectText(e)}
          
        />
         {submitted && !destino.preDesReferencia && (
            <small className="p-invalid">Referencia requerida</small>
          )}
            </div>
      </div>
    </div>
  );
};
