import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

//Import components
import { Dropdown } from "primereact/dropdown";

// Import Services
import CliDireccionService from "../../service/CliServices/CliDireccionService";
import AppPaisService from "../../service/AppServices/AppPaisService";
import AppDepartamentoService from "../../service/AppServices/AppDepartamentoService";
import AppMunicipioService from "../../service/AppServices/AppMunicipioService";
import AppTipoDireccionService from "../../service/AppServices/AppTipoDireccionService";
import classNames from "classnames";

export const CliAsociadoDireccionComponent = (props) => {
  let emptyAsociado = {
    actEcoId: 0,
    appActividadEconomica: {
      actEcoDescripcion: "",
      actEcoId: 0,
    },
    appCondicionMigratoria: {
      conMigDescripcion: "",
      conMigId: 0,
    },
    appEstadoCivil: {
      estCivDescripcion: "",
      estCivId: 0,
    },
    appMediosComunicacion: {
      medComDescripcion: "",
      medComId: 0,
      medComLogo: "",
    },
    appProfesionOficio: {
      ofiDescripcion: "",
      ofiId: 0,
    },
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
    },
    appTipoIdentificacion: {
      ideTipoDescripcion: "",
      ideTipoFormato: "",
      ideTipoId: 0,
    },
    appTipoPersona: {
      perTipoDescripcion: "",
      perTipoId: 0,
    },
    asoActuaNombrePropio: 0,
    asoAfectoImpuesto: 0,
    asoApellidoCasada: "",
    asoCPE: 0,
    asoCalidadActua: "",
    asoEmpleado: 0,
    asoEstado: "",
    asoFechaConstitucion: "",
    asoFechaInactivacion: "",
    asoFechaIngreso: "",
    asoFechaModificacion: "",
    asoFechaNacimiento: "",
    asoFechaVenceIdentificacion: "",
    asoFirma: "",
    asoFoto: "",
    asoId: 0,
    asoIdReferencia: "",
    asoIdRepresentante: "",
    asoListaNegra: 0,
    asoListaNegraReferencia: "",
    asoMensajeAdvertencia: "",
    asoNit: "",
    asoNombreJuridico: "",
    asoNumDocIdentificacion: "",
    asoOtraCondicionMigra: "",
    asoOtroNombre: "",
    asoPrimerApellido: "",
    asoPrimerNombre: "",
    asoPropositoRelacion: "",
    asoRegistroMercantil: "",
    asoRepresentanteLegal: "",
    asoSegundoApellido: "",
    asoSegundoNombre: "",
    asoUsaBancoCoop: "",
    conMigId: 0,
    estCivId: 0,
    ideTipoId: 0,
    medComId: 0,
    ofiId: 0,
    perTipoId: 0,
    sexId: 0,
    sucId: 0,
    usuUsuarioIngresa: "",
    usuUsuarioModifica: "",
    usuUsuarioPromotor: "",
  };

  let emptyCliDireccion = {
    asoId: 0,
    depId: 0,
    dirDireccion: "",
    dirId: 0,
    dirTipoId: 0,
    munId: 0,
    paiId: 0,
    appDepartamento: {
      depCodigo: 0,
      depId: 0,
      depNombre: "",
      paiId: 0,
      regId: 0,
    },
    appMunicipio: {
      depCodigo: 0,
      depId: 0,
      munCodigo: 0,
      munId: 0,
      munNombre: "",
    },
    appPais: {
      paiCodAlpha2: "",
      paiCodAlpha3: "",
      paiId: 0,
      paiNombre: "",
      paiNombreEn: "",
      paiNumerico: "",
    },
    appTipoDireccion: {
      dirTipoId: 0,
      dirTipoNombre: "",
    },
  };

  // let emptyAppPais = {
  //   paiCodAlpha2: '',
  //   paiCodAlpha3: '',
  //   paiId: 0,
  //   paiNombre: '',
  //   paiNombreEn: '',
  //   paiNumerico: '',
  // }

  // let emptyAppDepartamento = {
  //   depCodigo: 0,
  //   depId: 0,
  //   depNombre: '',
  //   paiId: 0,
  //   regId: 0,
  // }
  // let emptyAppMunicipio = {
  //   depCodigo: 0,
  //   depId: 0,
  //   munCodigo: 0,
  //   munId: 0,
  //   munNombre: '',
  // }

  // let emptyAppTipoDireccion = {
  //   dirTipoId: 0,
  //   dirTipoNombre: '',
  // }

  const [asociado, setAsociado] = useState(emptyAsociado);
  const [cliDireccion, setCliDireccion] = useState(emptyCliDireccion);
  const [cliDirecciones, setCliDirecciones] = useState([]);
  const [appPaises, setAppPaises] = useState([]);
  const [appMunicipios, setAppMunicipios] = useState([]);
  const [appDepartamentos, setAppDepartamentos] = useState([]);

  const [appTipoDirecciones, setAppTipoDirecciones] = useState([]);

  const [cliDireccionDialog, setCliDireccionDialog] = useState(false);
  const [deleteCliDireccionDialog, setDeleteCliDireccionDialog] =
    useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    setAsociado(props.asociado);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cliDireccionesService = new CliDireccionService();
    cliDireccionesService.findAllByAsoId(props.asociado.asoId).then((data) => {
      setCliDirecciones(data);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appPaisService = new AppPaisService();
    appPaisService.findAll().then((data) => {
      setAppPaises(data);
    });
    const appDepartamentoService = new AppDepartamentoService();
    appDepartamentoService
      .findByCountryId(cliDireccion?.appPais?.paiId || 1)
      .then((data) => setAppDepartamentos(data));
    //const appMunicipioService = new AppMunicipioService();
    // appMunicipioService
    //   .findByDepartamentId(cliDireccion?.appDepartamento?.depId || 1)
    //   .then((data) => setAppMunicipios(data))
    const appTipoDireccionService = new AppTipoDireccionService();
    appTipoDireccionService
      .findAll()
      .then((data) => setAppTipoDirecciones(data));
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appDepartamentoService = new AppDepartamentoService();
    appDepartamentoService
      .findByCountryId(cliDireccion?.appPais?.paiId)
      .then((data) => {
        setAppDepartamentos(data);
      });
    const appMunicipioService = new AppMunicipioService();
    appMunicipioService
      .findByDepartamentId(cliDireccion?.appDepartamento?.depId)
      .then((data) => {
        setAppMunicipios(data);
      });
  }, [cliDireccion.appPais, cliDireccion.appDepartamento]);

  const codeBodyTemplate = (rowData) => {
    return <>{rowData.dirId}</>;
  };

  // const paisBodyTemplate = (rowData) => {
  //   return <>{rowData.dirDireccion}</>
  // }
  // const departamentoBodyTemplate = (rowData) => {
  //   return <>{rowData.actEcoDescripcion}</>
  // }
  // const municipioBodyTemplate = (rowData) => {
  //   return <>{rowData.actEcoDescripcion}</>
  // }

  const tipoDireccionBodyTemplate = (rowData) => {
    return <>{rowData.appTipoDireccion.dirTipoNombre}</>;
  };

  const direccionBodyTemplate = (rowData) => {
    return <>{rowData.dirDireccion}</>;
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _cliDireccion = { ...cliDireccion };
    _cliDireccion[`${name}`] = val.toUpperCase();
    setCliDireccion(_cliDireccion);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _cliDireccion = { ...cliDireccion };
    _cliDireccion[`${name}`] = val;
    setCliDireccion(_cliDireccion);

    //Set simple data
    _cliDireccion[`${name2}`] = val1;
    setCliDireccion(_cliDireccion);
  };

  const saveCliDireccion = () => {
    setSubmitted(true);
    if (
      cliDireccion.dirTipoId &&
      cliDireccion.paiId &&
      cliDireccion.depId &&
      cliDireccion.munId !== 0 &&
      cliDireccion.dirDireccion !== ""
    ) {
      setSubmitted(false);
      const cliDireccionesService = new CliDireccionService();
      let _cliDireccion = { ...cliDireccion };
      _cliDireccion[`${"asoId"}`] = asociado.asoId;

      cliDireccionesService.save(_cliDireccion).then((data) => {
        cliDireccionesService
          .findAllByAsoId(props.asociado.asoId)
          .then((data) => {
            setCliDirecciones(data);
          });
        toast.current.show({
          severity: "success",
          summary: "¡Exitoso!",
          detail:
            cliDireccion.dirId !== null
              ? "Dirección Actualizado"
              : "Dirección Creado",
          life: 5000,
        });
      });

      cliDireccionesService
        .findAllByAsoId(props.asociado.asoId)
        .then((data) => {
          setCliDirecciones(data);
        });
      setCliDireccionDialog(false);
    }
  };

  //Borramos la direccion
  const deleteCliDireccion = () => {
    setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const cliDireccionesService = new CliDireccionService();
    //Enviamos los datos al servicio correspondiente
    cliDireccionesService.delete(cliDireccion).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      toast.current.show({
        severity: "success",
        summary: "¡Éxitoso!",
        detail: "Dirección eliminado",
        life: 3000,
      });

      cliDireccionesService
        .findAllByAsoId(props.asociado.asoId)
        .then((data) => {
          setCliDirecciones(data);
          //         setCliDirecciones(data)
        });
    });

    // cliDireccionesService.findAllByAsoId(props.asociado.asoId).then((data) => {
    //   setCliDirecciones(data)
    // })
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteCliDireccionDialog(false);
    //Metemos al state el all de los sexos del sistemas despues de aver eliminado
    setCliDireccion(emptyCliDireccion);
  };

  const editCliDireccion = (cliDireccion) => {
    setCliDireccion({ ...cliDireccion });
    setCliDireccionDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCliDireccionDialog(false);
  };

  const hideDeleteCliDireccionDialog = () => {
    setDeleteCliDireccionDialog(false);
  };

  //Mensaje de confirmación de eliminación un registro
  const confirmDeleteCliDireccion = (cliDireccion) => {
    setCliDireccion(cliDireccion);
    setDeleteCliDireccionDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editCliDireccion(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteCliDireccion(rowData)}
        />
      </div>
    );
  };

  const deleteCliDireccionDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCliDireccionDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteCliDireccion} />
    </>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-danger"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveCliDireccion} />
    </>
  );

  return (
    <div className="p-grid" disabled="disabled">
      <Toast ref={toast} />
      <div className="p-col-12 p-md-12" disabled="disabled">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          tooltip="Nueva Dirección"
          onClick={() => editCliDireccion(emptyCliDireccion)}
        />
        <div className="p-col-12 p-md-12">
          <DataTable
            value={cliDirecciones}
            dataKey="paiId"
            rowHover
            className="datatable-responsive"
            emptyMessage="No se encontraron direcciones."
          >
            <Column
              field="dirId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="appTipoDirecion"
              header="Tipo Dirección"
              sortable
              body={tipoDireccionBodyTemplate}
            ></Column>
            <Column
              field="dirDescripcion"
              header="Dirección"
              sortable
              body={direccionBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={cliDireccionDialog}
            style={{ width: "50vw" }}
            header="Datos de la Dirección"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                  <label htmlFor="state">Tipo Dirección</label>
                  <Dropdown
                    id="state"
                    key={cliDireccion.asoId}
                    value={cliDireccion.appTipoDireccion}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appTipoDireccion",
                        e.target.value.dirTipoId,
                        "dirTipoId"
                      );
                    }}
                    options={appTipoDirecciones}
                    optionLabel="dirTipoNombre"
                    placeholder="Seleccione..."
                    required
                    className={classNames({
                      "p-invalid": submitted && !cliDireccion?.dirTipoId,
                    })}
                  ></Dropdown>
                     {submitted && !cliDireccion?.dirTipoId && (
                <small className="p-invalid">Tipo direccion requerido.</small>
              )}
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                  <label htmlFor="state">País</label>
                  <Dropdown
                    key={cliDireccion.asoId}
                    id="state"
                    value={cliDireccion.appPais}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appPais",
                        e.target.value.paiId,
                        "paiId"
                      );
                    }}
                    options={appPaises}
                    optionLabel="paiNombre"
                    placeholder="Seleccione..."
                    required
                    className={classNames({
                      "p-invalid": submitted && !cliDireccion?.paiId,
                    })}
                  ></Dropdown>
                      {submitted && !cliDireccion?.dirTipoId && (
                <small className="p-invalid">Pais requerido.</small>
              )}
                </div>
                <div className="p-field p-col">
                  <label htmlFor="state">Departamento</label>
                  <Dropdown
                    key={cliDireccion.asoId}
                    id="state"
                    value={cliDireccion.appDepartamento}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appDepartamento",
                        e.target.value.depId,
                        "depId"
                      );
                    }}
                    options={appDepartamentos}
                    optionLabel="depNombre"
                    placeholder="Seleccione..."
                    required
                    className={classNames({
                      "p-invalid": submitted && !cliDireccion?.depId,
                    })}
                  ></Dropdown>
                          {submitted && !cliDireccion?.depId && (
                <small className="p-invalid">Departamento requerido.</small>
              )}
                </div>
                <div className="p-field p-col">
                  <label htmlFor="state">Municicpio</label>
                  <Dropdown
                    key={cliDireccion.asoId}
                    id="state"
                    value={cliDireccion.appMunicipio}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appMunicipio",
                        e.target.value.munId,
                        "munId"
                      );
                    }}
                    options={appMunicipios}
                    optionLabel="munNombre"
                    placeholder="Seleccione..."
                    required
                    className={classNames({
                      "p-invalid": submitted && !cliDireccion?.munId,
                    })}
                  ></Dropdown>
                                 {submitted && !cliDireccion?.munId && (
                <small className="p-invalid">Municicpio requerido.</small>
              )}
                </div>
                <div className="p-field p-col-12">
                  <label htmlFor="address">Address</label>
                  <InputTextarea
                    maxLength={151}
                    rows={5}
                    cols={30}
                    value={cliDireccion?.dirDireccion}
                    onChange={(e) => onInputChange(e, "dirDireccion")}
                    className={{
                      "p-invalid": (cliDireccion?.dirDireccion.length >= 151) ||
                 (     submitted && !cliDireccion?.dirDireccion)
                    }}
                  />

                  {cliDireccion?.dirDireccion.length >= 151 && (
                    <small className="p-invalid">
                      No se permiten mas de 150 caracteres{" "}
                    </small>
                  )}
                              {submitted && !cliDireccion?.dirDireccion && (
                <small className="p-invalid">Direccion requerida</small>
              )}
                </div>
              </div>
            </>
          </Dialog>
          <Dialog
            visible={deleteCliDireccionDialog}
            style={{ width: "450px" }}
            modal
            footer={deleteCliDireccionDialogFooter}
            onHide={hideDeleteCliDireccionDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {cliDireccion && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{cliDireccion.dirDireccion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CliAsociadoDireccionComponent;
