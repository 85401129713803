import React from "react";

//import jsPDF from "jspdf";
//import autoTable from "jspdf-autotable";
import { Button } from "primereact/button";

//import { usePDF } from '@react-pdf/renderer';
export const ReportsSecurity = () => {
  // It can parse html:
  // <table id="my-table"><!-- ... --></table>

  // const pdfGenerate = () => {
  //   var doc = new jsPDF("p", "pt", "a4");

  //   var rows1 = [];

  //   var itemNew = [
  //     { id: "TIPO DE SEGURO:", name: "PROTECCION MEDICA" },
  //     { id: "NUMERO DE PROTECION", name: "2781 " },
  //     { id: "CODIGO DE ASOCIADO:", name: " 22584" },
  //     {
  //       id: "NOMBRE DE LA CUENTA",
  //       name: "COMPRA DE SEGURO MEDICO y poasodfasdopfopaksdkj",
  //     },
  //     { id: "NUMEROS DE CUENTA", name: "COMPRA DE SEGURO MEDICO" },
  //     {
  //       id: "DIRECCION:",
  //       name: "SECTOR 2, CIENAGA GRANDE / A LA PAR DE LA IGLESIA ESMIRNA, MUNICIPIO DE SANTA LUCIA UTATLAN, DEP. SOLOLA",
  //     },
  //     { id: "FECHA:", name: "13/08/2022" },
  //     {
  //       id: "NOMBRE BENEFICIARIA:",
  //       name: "ALBERTA CARMELA CHAVEZ DE LEÓN DE COTUC",
  //     },
  //     { id: "NUMERO DE IDENTIFICACION", name: "2374 24282 0704" },
  //   ];

  //   itemNew.forEach((element) => {
  //     var temp1 = [element.id, element.name];
  //     rows1.push(temp1);
  //   });
  //   doc.setFont("arial", "bold");

  //   doc.setFontSize("20");
  //   doc.text(
  //     "BENEFICIO INDIVIDUAL",
  //     doc.internal.pageSize.getWidth() / 2,
  //     100,
  //     "center"
  //   );
  //   doc.setFontSize("16");
  //   doc.text(
  //     "FORMULARIO DE RECLAMO DE SEGURO.",
  //     doc.internal.pageSize.getWidth() / 2,
  //     120,
  //     "center"
  //   );
  //   doc.setFontSize("12");
  //   doc.setFont("arial", "normal");
  //   var text1 =
  //     "Por este medio SOLICITO el beneficio del seguro médico que extiende la Cooperativa FONDOS DE AMERICA, R.L. a sus ahorrantes, usuario de crédito y por beneficios adicionales, el costo de los medicamentos y consultas médicas, que haciende a un valor  total de Q.520.00, conformado por varias recetas médicas a: favor de ALBERTA CARMELA CHAVEZ DE LEÓN DE COTUC.";
  //   doc.text(text1, 60, 425, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   doc.text("F. ________________________________", 60, 510, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   doc.text("ALBERTA CARMELA CHAVEZ DE LEÓN DE COTUC", 60, 526, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   doc.text("Beneficiario @, PM-002781", 60, 541, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });

  //   doc.autoTable({
  //     theme: "grid",
  //     body: rows1,
  //     styles: { fontWeight: "bold" },
  //     startY: 150,
  //     columnStyles: {
  //       0: { cellWidth: 200 },
  //       1: { cellWidth: 250 },
  //     },

  //     margin: { horizontal: 70 },

  //     bodyStyles: {
  //       minCellHeight: 5,
  //       fontSize: 10,
  //       lineColor: "black",
  //       fontStyle: "bold",
  //       textColor: "black",
  //       Padding: {
  //         top: 10,
  //         bottom: 40,
  //         left: 2,
  //         right: 2,
  //         minCellWidth: "auto",
  //       },
  //     },
  //   });

  //   doc.text(
  //     "_______________________________________________________________________________",
  //     60,
  //     575,
  //     {
  //       align: "justify",
  //       lineHeightFactor: 1.5,
  //       maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //       marginLeft: 100,
  //       marginTop: 500,
  //       marginRight: 100,
  //     }
  //   );
  //   doc.setFont("arial", "bold");
  //   doc.text("RESOLUCION:", 60, 590, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });

  //   doc.setFont("arial", "normal");
  //   doc.text(
  //     "_______________________________________________________________________________",
  //     60,
  //     610,
  //     {
  //       align: "justify",
  //       lineHeightFactor: 1.5,
  //       maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //       marginLeft: 100,
  //       marginTop: 500,
  //       marginRight: 100,
  //     }
  //   );

  //   doc.text(
  //     "_______________________________________________________________________________",
  //     60,
  //     635,
  //     {
  //       align: "justify",
  //       lineHeightFactor: 1.5,
  //       maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //       marginLeft: 100,
  //       marginTop: 500,
  //       marginRight: 100,
  //     }
  //   );

  //   doc.text(
  //     "_______________________________________________________________________________",
  //     60,
  //     660,
  //     {
  //       align: "justify",
  //       lineHeightFactor: 1.5,
  //       maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //       marginLeft: 100,
  //       marginTop: 500,
  //       marginRight: 100,
  //     }
  //   );
  //   doc.setFont("arial", "bold");

  //   doc.text("AUROTIZADO:", 60, 680, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   doc.text("GERENTE DE AGENCIA:", 60, 700, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   doc.text("(sello):", 450, 700, {
  //     align: "justify",
  //     lineHeightFactor: 1.5,
  //     maxWidth: doc.internal.pageSize.getWidth() / 1.25,
  //     marginLeft: 100,
  //     marginTop: 500,
  //     marginRight: 100,
  //   });
  //   // doc.text('Por este medio SOLICITO el beneficio del seguro médico que extiende la Cooperativa FONDOS DE AMERICA, R.L. a sus ahorrantes, usuario de crédito y por beneficios adicionales, el costo de los medicamentos y consultas médicas, que haciende a un valor total de Q.520.00, conformado por varias recetas médicas a: favor de ALBERTA  CARMELA CHAVEZ DE LEÓN DE COTUC',2, 80, 'center');
  //   doc.save("seguro.pdf");
  // };

  return (
    <div>
      <h1>Reporte</h1>
      <>
        <Button
          className="p-button-danger p-button-rounded"
          icon="pi pi-print"

        />
      </>
    
      
    </div>
  );
};
