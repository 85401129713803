import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AhoMovimientoService {
  async findDate( ahoId, fecIn,fefIN) {
    if (token) {
      jwt = JSON.parse(token);
    }
    var config = {
      method: "get",
      url:
        api.baseUrl() +
        `/ahomovimiento/aho/${ahoId || 0}/${fecIn|| "2022-10-1"}/${
            fefIN || "2022-11-30"
        }`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
     // body: JSON.stringify(data),
    };
    try {
      const response = await axios(config);
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/ahocuenta/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }
}
