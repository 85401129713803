import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from "primereact/inputtextarea";
import { Divider } from "primereact/divider";
import classNames from "classnames";
import { Message } from "primereact/message";
import { CliAsociadoSeleccionar } from "../../ClientesPages/CliAsociadoSeleccionar";
import { Dropdown } from "primereact/dropdown";
import AppFrecuencia from "../../../service/AppServices/AppFrecuencia";
import AppPreSubProductoService from "../../../service/AppServices/AppPreSubProductoService";
import AppTipoProductoService from "../../../service/AppServices/AppTipoProductoService";
import AppPreGarantiaService from "../../../service/AppServices/AppPreGarantiaService";
import AppPreAprobacionService from "../../../service/AppServices/AppPreAprobacionService";
import AppPreOrigenFondoService from "../../../service/AppServices/AppPreOrigenFondoService";
import AppPreDestinoService from "../../../service/AppServices/AppPreDestinoService";
import AppPreTipoCompromisoAsignadoService from "../../../service/AppServices/AppPreTipoCompromisoAsignadoService";
import PrePrestamoService from "../../../service/PrePrestamoService/PrePrestamoService";
import onChajeObjN from "../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../utilities/onChangeObjDropDawn";
import selectText from "../../../utilities/selecText";
export const General = ({ prestamoHook, setPrestamoHook, toast }) => {
  const [buscar, setBuscar] = useState(false);
  const [prestamo, setPrestamo] = useState(prestamoHook);
  const [frecuencias, setFrecuencias] = useState([]);
  const [productos, setProductos] = useState([]);
  const [subProductos, setSubProductos] = useState([]);
  const [garantias, setGarantias] = useState([]);
  const [aprobacion, setAprobacion] = useState([]);
  const [origenes, setOrigenes] = useState([]);
  const [destinos, setDestinos] = useState([]);
  const [tasas, setTasas] = useState([]);
  const [comprimisos, setCompromisos] = useState([]);
  useEffect(() => {
    const frecuenciaService = new AppFrecuencia();
    const appProductoService = new AppTipoProductoService();
    const appPreGarantiaService = new AppPreGarantiaService();
    const appAprobacionService = new AppPreAprobacionService();
    const appPreOriginSerivice = new AppPreOrigenFondoService();
    const appDestinoService = new AppPreDestinoService();

    const prePrestamo = new PrePrestamoService();
    frecuenciaService.findAll().then((res) => {
      setFrecuencias(res?.data);
    });
    appProductoService.findAllCre().then((response) => {
      setProductos(response?.data);
    });
    appPreGarantiaService.findAll().then((res) => {
      setGarantias(res);
    });
    appAprobacionService.findAll().then((res) => {
      setAprobacion(res);
    });
    appPreOriginSerivice.findAll().then((res) => {
      setOrigenes(res);
    });
    appDestinoService.findAll().then((res) => {
      setDestinos(res);
    });

    prePrestamo.findTasa().then((res) => {
      var rest = [];
      if (res?.length > 0) {
        res.forEach((element) => {
          var restElement = {
            label: element.name,
            value: element.id,
          };
          rest.push(restElement);
        });
      }
      setTasas(rest);
    });
  }, []);

  function save() {
    // if(prestamo.preAsoId !== 0 && ){

    // }
    console.log(prestamo);
    const preService = new PrePrestamoService();
    preService.save(prestamo).then((res) => {
      console.log(res);
    });
  }

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelsar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscar(false)}
        />
      </>
    );
  }

  function findByPro(id) {
    const preSubProductos = new AppPreSubProductoService();
    preSubProductos.findByProId(id).then((res) => {
      setSubProductos(res);
    });
  }
  useEffect(() => {
    const appCompromisoService = new AppPreTipoCompromisoAsignadoService();
    appCompromisoService.findBySubProducto(prestamo.preSubProId).then((res) => {
      var compromisosNew = [];
      if (res?.length > 0) {
        res.forEach((element) => {
          var restElement = element.appPreTipoCompromiso;
          compromisosNew.push(restElement);
        });
      }
      console.log(compromisosNew);
      setCompromisos(compromisosNew);
    });
  }, [prestamo.preSubProId]);

  useEffect(() => {
    var date = new Date();
    sumarDias(date, prestamo.appFrecuenciaPlazo.freDias * prestamo.prePlazo);
   // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestamo.prePlazo, prestamo.appFrecuenciaPlazo]);

  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    var year = fecha.getFullYear();
    var month = fecha.getMonth() + 1;
    var day = fecha.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var dayNow = year + "-" + month + "-" + day;
    let _prestamo = { ...prestamo };
    _prestamo["preFechaVencimiento"] = dayNow;
    setPrestamo(_prestamo);
  }
  //  console.log(prestamo.appPreSubproducto);

  useEffect(() => {
    let _prestamo = { ...prestamo };
    _prestamo["preMontoTotal"] = prestamo.preMontoSolicitado;
    setPrestamo(_prestamo);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prestamo.preMontoSolicitado]);

  // useEffect(() => {
  //   let _prestamo = { ...prestamo };
  //   _prestamo["preMontoTotal"] = prestamo.preMontoSolicitado;
  //   _prestamo["preMontoTotal"] = prestamo.preMontoSolicitado;
  // }, [
  //   prestamo.preTasaSpread,
  //   prestamo.preTasaMoraSpread,
  //   prestamo.preTasaBase,
  //   prestamo.preTasaMora
  // ]);
  const renderTable = (
    <div>
      <div className="card">
        <div className="p-grid p-fluid ">
          <div className="p-col-12 p-lg-3 p-md-3">
            <label> Codigo del cliente</label>
            <div className="p-inputgroup">
              <InputText
                id="preAsoId"
                value={prestamo.preAsoId}
                type={"number"}
                placeholder="Codigo"
                onChange={(e) =>
                  onChajeObjN(e, "preAsoId", prestamo, setPrestamo)
                }
                onClick={(e) => selectText(e)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setBuscar(true)}
                //   disabled={
                //     editar || newAhorro?.ahoId !== "" ? true : false
                //   }
              />
            </div>
          </div>
          <div className="p-col  p-lg-6 p-md-6">
            <label> Nombre del cliente</label>
            <InputText
              disabled={true}
              placeholder="Nombre"
              value={prestamo.preNombre}
              style={{
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
              }}
            />
          </div>
          <div className="p-col-12 p-lg-3 p-md-3">
            <label> Fecha ingreso</label>
            <InputText
              disabled={true}
              placeholder="Nombre"
              value={prestamo.preFechaIngreso}
              type="date"
              style={{
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
              }}
            />
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Prestamo de referencia</label>
            <InputText
              id="preRef"
              value={prestamo.preReferencia}
              placeholder="Referencia..."
              onChange={(e) =>
                onChajeObjN(e, "preReferencia", prestamo, setPrestamo)
              }
              onClick={(e) => selectText(e)}
            />
          </div>
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Producto</label>
            <Dropdown
              value={prestamo.appProducto}
              placeholder="Seleccione..."
              options={productos}
              optionLabel="proNombre"
              onChange={(e) => {
                onChangeObjDropDawn(
                  e,
                  "appProducto",
                  e.target.value.proId,
                  "preProId",
                  prestamo,
                  setPrestamo
                );
                findByPro(e.target.value.proId)
              }}
            />
          </div>
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Sub porudcto</label>
            <Dropdown
              value={prestamo.appPreSubproducto}
              options={subProductos}
              optionLabel="preSubDescripcion"
              placeholder="Seleccione..."
              onChange={(e) =>
                setPrestamo({
                  ...prestamo,
                  prePlazo: e.target.value?.preSubPlazoMax,
                  appPreSubproducto: e.target.value,
                  preSubProId: e.target.value.preSubId,
                  appFrecuenciaPlazo: e.target.value.appFrecuencia,
                  preFreId: e.target.value.appFrecuencia.freId,
                })
              }
            />
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Garantia</label>
            <Dropdown
              value={prestamo.appPreGarantia}
              placeholder="Seleccione..."
              options={garantias}
              optionLabel="preGarDescripcion"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appPreGarantia",
                  e.target.value.preGarId,
                  "preGarId",
                  prestamo,
                  setPrestamo
                )
              }
            />
          </div>
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Aprobacion</label>
            <Dropdown
              value={prestamo.appPreAprobacion}
              placeholder="Seleccione..."
              options={aprobacion}
              optionLabel="preAproDescripcion"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appPreAprobacion",
                  e.target.value.preAproId,
                  "preAproId",
                  prestamo,
                  setPrestamo
                )
              }
            />
          </div>

          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Origen de fondos</label>
            <Dropdown
              value={prestamo.appPreOrigenFondo}
              placeholder="Seleccione..."
              options={origenes}
              optionLabel="preOriDescripcion"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appPreOrigenFondo",
                  e.target.value.preOriId,
                  "preOriId",
                  prestamo,
                  setPrestamo
                )
              }
            />
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Fecha de aprobacion</label>
            <InputText
              value={prestamo.preFechaAprobacion}
              placeholder="Fecha de aprobacion"
              type={"date"}
              onChange={(e) =>
                onChajeObjN(e, "preFechaAprobacion", prestamo, setPrestamo)
              }
            />
          </div>
          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Fecha de cocesion</label>
            <InputText
              value={prestamo.preFechaConcesion}
              placeholder="Fecha de cocesion"
              type={"date"}
              onChange={(e) =>
                onChajeObjN(e, "preFechaConcesion", prestamo, setPrestamo)
              }
            />
          </div>

          <div className="p-col-12 p-lg-4 p-md-4">
            <label>Fecha de contrato</label>
            <InputText
              value={prestamo.preFechaContrato}
              placeholder="Fecha de contrato"
              type={"date"}
              onChange={(e) =>
                onChajeObjN(e, "preFechaContrato", prestamo, setPrestamo)
              }
            />
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            <label>Descripcion/Comentario</label>
            <InputTextarea
              rows={3}
              value={prestamo.preDescripcion}
              onChange={(e) =>
                onChajeObjN(e, "preDescripcion", prestamo, setPrestamo)
              }
            />
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            <label>Destino</label>
            <Dropdown
              value={prestamo.appPreDestino}
              placeholder="Seleccione..."
              options={destinos}
              optionLabel="preDesDescripcion"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appPreDestino",
                  e.target.value.preDesId,
                  "preDesId",
                  prestamo,
                  setPrestamo
                )
              }
            />
          </div>
          <div className="p-col">
            <label>Promotor</label>
            <InputText
              value={prestamo.preUsuPromotor}
              placeholder="Promotor..."
              onChange={(e) =>
                setPrestamo({ ...prestamo, preUsuPromotor: e.target.value })
              }
            />
          </div>
        </div>
      </div>
      <Divider layout="horizontal" align="left">
        Tasa y plazo
      </Divider>
      <div className="p-grid p-fluid p-card p-mb-3 ">
        <div className="p-col">
          <label>Monto Original</label>
          <InputText
            id="montoOriginal"
            value={prestamo.preMontoOriginal}
            onChange={(e) =>
              onChajeObjN(e, "preMontoOriginal", prestamo, setPrestamo)
            }
            type="number"
            onClick={(e) => selectText(e)}
            placeholder={"Monto Original"}
          />
        </div>
        <div className="p-col">
          <label>Monto Solicitado</label>
          <InputText
            id="montoSoli"
            value={prestamo.preMontoSolicitado}
            onChange={(e) =>
              onChajeObjN(e, "preMontoSolicitado", prestamo, setPrestamo)
            }
            type="number"
            onClick={(e) => selectText(e)}
            placeholder={"Monto Silicitado"}
            className={classNames({
              "p-invalid":
                prestamo.preMontoTotal >
                prestamo.appPreSubproducto.preSubMontoMax,
            })}
          />
          {prestamo.preMontoTotal >
            prestamo.appPreSubproducto.preSubMontoMax && (
            <small className="p-invalid">
              Monto total excede lo permitido{" "}
            </small>
          )}
        </div>
        <div className="p-col">
          <label>Monto Total</label>
          <InputText
            disabled={true}
            id="monTotal"
            value={prestamo.preMontoTotal}
            onChange={(e) =>
              onChajeObjN(e, "preMontoTotal", prestamo, setPrestamo)
            }
            onClick={(e) => selectText(e)}
            type="number"
            placeholder={"Monto Total"}
            className={classNames({
              "p-invalid":
                prestamo.preMontoTotal >
                prestamo.appPreSubproducto.preSubMontoMax,
            })}
          />
          {prestamo.preMontoTotal >
            prestamo.appPreSubproducto.preSubMontoMax && (
            <small className="p-invalid">
              Monto total excede lo permitido{" "}
            </small>
          )}
        </div>

        <div className="p-col">
          <label>Tipo de tasa</label>
          <Dropdown
            value={prestamo.preTasaFijaVariable}
            options={tasas}
            placeholder="Seleccione..."
            onChange={(e) =>
              setPrestamo({ ...prestamo, preTasaFijaVariable: e.target.value })
            }
          />
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-1 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Interes Base</span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-6">
                <div className="overview-subtext">
                  <label>
                    <b>Corriente </b>
                  </label>
                </div>
                <div className="overview-subtext p-mt-4">
                  <label>
                    <b>Mora </b>
                  </label>
                </div>
              </div>
              <div className="p-col-6">
                <div className="overview-subtext p-fluid">
                  <InputText
                    value={prestamo.preTasaBase}
                    disabled={true}
                    className={classNames(
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) >
                          prestamo?.appPreSubproducto?.preSubTasaMax,
                      },
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) <
                          prestamo?.appPreSubproducto?.preSubTasaMin,
                      }
                    )}
                  />
                </div>
                <div className="overview-subtext p-fluid">
                  <InputText value={prestamo.preTasaMora} disabled={true} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-3 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Interes Spread</span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-6">
                <div className="overview-subtext">
                  <label>
                    <b>Corriente </b>
                  </label>
                </div>
                <div className="overview-subtext p-mt-4">
                  <label>
                    <b>Mora </b>
                  </label>
                </div>
              </div>
              <div className="p-col-6">
                <div className="overview-subtext p-fluid">
                  <InputText
                    id="tasaSpread"
                    value={prestamo.preTasaSpread}
                    placeholder="Tasa Spread"
                    onChange={(e) =>
                      onChajeObjN(e, "preTasaSpread", prestamo, setPrestamo)
                    }
                    onClick={(e) => selectText(e)}
                    className={classNames(
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) >
                          prestamo?.appPreSubproducto?.preSubTasaMax,
                      },
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) <
                          prestamo?.appPreSubproducto?.preSubTasaMin,
                      }
                    )}
                  />
                </div>
                <div className="overview-subtext p-fluid">
                  <InputText
                    id="preTasaMoraSpread"
                    value={prestamo.preTasaMoraSpread}
                    placeholder="Mora Spread"
                    onChange={(e) =>
                      onChajeObjN(e, "preTasaMoraSpread", prestamo, setPrestamo)
                    }
                    onClick={(e) => selectText(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-4 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Interes Total </span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-6">
                <div className="overview-subtext">
                  <label>
                    <b>Corriente </b>
                  </label>
                </div>
                <div className="overview-subtext  p-mt-4">
                  <label>
                    <b>Mora </b>
                  </label>
                </div>
              </div>
              <div className="p-col-6">
                <div className="overview-subtext p-fluid">
                  <InputText
                    disabled={true}
                    value={
                      Number(prestamo.preTasaBase) +
                      Number(prestamo.preTasaSpread)
                    }
                    className={classNames(
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) >
                          prestamo?.appPreSubproducto?.preSubTasaMax,
                      },
                      {
                        "p-invalid":
                          Number(prestamo.preTasaBase) +
                            Number(prestamo.preTasaSpread) <
                          prestamo?.appPreSubproducto?.preSubTasaMin,
                      }
                    )}
                  />

                  {/* {Number(prestamo.preTasaBase) +
                    Number(prestamo.preTasaSpread) >
                    prestamo?.appPreSubproducto?.preSubTasaMax && (
                    <small className="p-invalid">
                      La tasa excede lo permitido, Tasa maxima permititda{" "}
                      {prestamo?.appPreSubproducto?.preSubTasaMax}
                    </small>
                  )} */}
                </div>
                <div className="overview-subtext p-fluid">
                  <InputText
                    disabled={true}
                    value={
                      Number(prestamo.preTasaMora) +
                      Number(prestamo.preTasaMoraSpread)
                    }
                    className={classNames({
                      "p-invalid":
                        prestamo.prePlazo *
                          prestamo.appFrecuenciaPlazo.freDias >
                        prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
                          prestamo?.appPreSubproducto?.preSubPlazoMax,
                    })}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="p-grid p-fluid p-mt-1">
        <div
          className="p-col-12"
          style={{
            display:
              (Number(prestamo.preTasaBase) + Number(prestamo.preTasaSpread) > prestamo?.appPreSubproducto?.preSubTasaMax) ===
              true
                ? ""
                : (Number(prestamo.preTasaBase) +
                    Number(prestamo.preTasaSpread) <
                    prestamo?.appPreSubproducto?.preSubTasaMin) ===
                  true
                ? ""
                : "none",
          }}
        >
          <Message
            severity="error"
            text="La tasa se encuentra en el rango permitido"
          />
        </div>
      </div>

      <div className="p-grid p-fluid p-card p-mt-1">
        <div className="p-col">
          <label>plazo</label>
          <InputText
            id="plazo"
            value={prestamo.prePlazo}
            type={"number"}
            placeholder="Plazo"
            onChange={(e) => onChajeObjN(e, "prePlazo", prestamo, setPrestamo)}
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid":
                prestamo.prePlazo * prestamo.appFrecuenciaPlazo.freDias >
                prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
                  prestamo?.appPreSubproducto?.preSubPlazoMax,
            })}
          />
          {prestamo.prePlazo * prestamo.appFrecuenciaPlazo.freDias >
            prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
              prestamo?.appPreSubproducto?.preSubPlazoMax && (
            <small className="p-invalid">Plazo fuera de ranto </small>
          )}
        </div>
        <div className="p-col">
          <label>Frecuencia</label>
          <Dropdown
            value={prestamo.appFrecuenciaPlazo}
            options={frecuencias}
            placeholder="Seleccione..."
            optionLabel="freNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appFrecuenciaPlazo",
                e.target.value.freId,
                "preFreId",
                prestamo,
                setPrestamo
              )
            }
            className={classNames({
              "p-invalid":
                prestamo.prePlazo * prestamo.appFrecuenciaPlazo.freDias >
                prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
                  prestamo?.appPreSubproducto?.preSubPlazoMax,
            })}
          />
          {prestamo.prePlazo * prestamo.appFrecuenciaPlazo.freDias >
            prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
              prestamo?.appPreSubproducto?.preSubPlazoMax && (
            <small className="p-invalid">Plazo fuera de ranto </small>
          )}
        </div>
        <div className="p-col">
          <label>Fecha de vencimiento</label>
          <InputText
            value={prestamo.preFechaVencimiento}
            placeholder="Fecha de vencimiento"
            type={"date"}
            disabled={true}
          />
        </div>
        <div
          className="p-col-12"
          style={{
            display:
              (prestamo.prePlazo * prestamo.appFrecuenciaPlazo.freDias >
                prestamo?.appPreSubproducto?.appFrecuencia?.freDias *
                  prestamo?.appPreSubproducto?.preSubPlazoMax) ===
              true
                ? ""
                : "none",
          }}
        >
          <Message
            severity="error"
            text="Plazo no permitido para el sub producto de credito sleccionado"
          />
        </div>
      </div>

      <Divider layout="horizontal" align="left">
        Cuotas
      </Divider>
      <div className="p-grid p-fluid p-card">
        <div className="p-col">
          <label>Compromiso</label>
          <Dropdown
            value={prestamo.appPreTipoCompromiso}
            options={comprimisos}
            optionLabel="preTipoComDescripcion"
            placeholder="Seleccione..."
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appPreTipoCompromiso",
                e.target.value.preTipComId,
                "preTipoCompromisoId",
                prestamo,
                setPrestamo
              )
            }
          />
        </div>
        {/* <div className="p-col">
          <label>Monto por cuota</label>
          <InputText
            id="Monto por cuota"
            value={prestamo.prePlazo}
            type={"number"}
            placeholder="Monto por cuota"
            onChange={(e) => onChajeObjN(e, "prePlazo", prestamo, setPrestamo)}
            onClick={(e) => selectText(e)}
          />
        </div> */}
        <div className="p-col"></div>
      </div>
      <div className="p-grid p-mt-2 ">
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-1 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Frecuencia de pago</span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-4">
                <div className="overview-subtext">
                  <label>
                    <b>Capital </b>
                  </label>
                </div>
                <div className="overview-subtext p-mt-4">
                  <label>
                    <b>Interes </b>
                  </label>
                </div>
              </div>
              <div className="p-col-8">
                <div className="overview-subtext p-fluid">
                  <Dropdown
                    value={prestamo.appFrecuenciaCapital}
                    options={frecuencias}
                    placeholder="Seleccione..."
                    optionLabel="freNombre"
                    onChange={(e) =>
                      onChangeObjDropDawn(
                        e,
                        "appFrecuenciaCapital",
                        e.target.value.freId,
                        "preFrePagoCapitalId",
                        prestamo,
                        setPrestamo
                      )
                    }
                  />
                </div>
                <div className="overview-subtext p-fluid">
                  <Dropdown
                    value={prestamo.appFrecuenciaInteres}
                    options={frecuencias}
                    placeholder="Seleccione..."
                    optionLabel="freNombre"
                    onChange={(e) =>
                      onChangeObjDropDawn(
                        e,
                        "appFrecuenciaInteres",
                        e.target.value.freId,
                        "preFrePagoInteresId",
                        prestamo,
                        setPrestamo
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-3 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Primer pago</span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-4">
                <div className="overview-subtext">
                  <label>
                    <b>Capital </b>
                  </label>
                </div>
                <div className="overview-subtext p-mt-4">
                  <label>
                    <b>Interes </b>
                  </label>
                </div>
              </div>
              <div className="p-col-8">
                <div className="overview-subtext p-fluid">
                  <InputText
                    value={prestamo.preFechaPrimerCap}
                    type={"date"}
                    onChange={(e) =>
                      onChajeObjN(e, "preFechaPrimerCap", prestamo, setPrestamo)
                    }
                  />
                </div>
                <div className="overview-subtext p-fluid">
                  <InputText
                    value={prestamo.preFechaPrimerInt}
                    type={"date"}
                    onChange={(e) =>
                      onChajeObjN(e, "preFechaPrimerInt", prestamo, setPrestamo)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-lg-4 p-md-4">
          <div className="card no-gutter widget-overview-box widget-overview-box-4 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Primer Desembolso </span>

            <div
              className="p-grid overview-detail p-mr-5 p-ml-5"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-4">
                <div className="overview-subtext">
                  <label>
                    <b>Cantidad </b>
                  </label>
                </div>
                <div className="overview-subtext  p-mt-4">
                  <label>
                    <b>Fecha </b>
                  </label>
                </div>
              </div>
              <div className="p-col-8">
                <div className="overview-subtext p-fluid">
                  <InputText disabled={true} />
                </div>
                <div className="overview-subtext p-fluid">
                  <InputText
                    value={prestamo.prePrimerDesembolso}
                    type={"date"}
                    onChange={(e) =>
                      onChajeObjN(
                        e,
                        "prePrimerDesembolso",
                        prestamo,
                        setPrestamo
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card p-col-12">
          <div className="p-grid">
            <div className="p-fluid p-col-12 p-md-6 p-lg-6">
              <Button
                label="CANCELAR"
                icon="pi pi-bookmark"
                className="p-mr-2 p-mb-2 p-button-danger"
                onClick={() => setPrestamo(prestamoHook)}
                // disabled={editar}
              />
            </div>
            <div className="p-fluid p-col-12 p-md-6 p-lg-6">
              <Button
                label="GUARDAR"
                icon="pi pi-bookmark"
                className="p-mr-2 p-mb-2 p-button-success"
                onClick={() => save()}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  return (
    <>
      {renderTable}
      {/* <div>
        <Button label="Guardar" icon="pi pi-check" onClick={() => save()} />
        <Button label="Cancelar" icon="pi pi-times" onClick={() => onHide()} /> 
      </div> */}
      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={prestamo}
          setPersona={setPrestamo}
          parametro1={"preAsoId"}
          parametro2={"preNombre"}
          cantPar={2}
        />
      </Dialog>
    </>
  );
};
