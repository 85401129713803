import React, { useState, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
//Import components
import { TabView, TabPanel } from "primereact/tabview";

//componentes
import { GeneralComponentMain } from "./GeneralComponentMain";
import { BeneficiarioComponent } from "./BeneficiarioComponent";
import { SaldoComponente } from "./SaldoComponent/SaldoComponente";
import { Administrar } from "./Administrar/Administrar";
import { Mancomunado } from "./Mancomunado";
//funciones
import useAuthExpired from "../../../utilities/useAuthExpired";
import { Firma } from "./Firmas/Firma";
export const NuevaCuentaAhorro = () => {
  const toast = useRef();
  useAuthExpired();
  var obj = JSON.parse(sessionStorage.getItem("Ahorro"));

  const [ahorro, setAhorro] = useState(obj);

  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <div className="card">
          <Toolbar
            left={
              <h6>
                {ahorro?.asoId !== 0
                  ? ahorro?.asoId +
                    " - " +
                    ahorro?.ahoNombre +
                    " - " +
                    ahorro?.ahoId
                  : "Nueva Cuenta de Ahorro"}
              </h6>
            }
          ></Toolbar>
          <TabView >
            <TabPanel header="General">
              <GeneralComponentMain
                ahorro={ahorro}
                setAhorro={setAhorro}
                toast={toast}
              />
            </TabPanel>
            <TabPanel header="Mancomunado" disabled ={ahorro?.asoId === 0}>
              <Mancomunado ahorro={ahorro} toast={toast} />
            </TabPanel>
            <TabPanel header="Benificiarios"  disabled ={ahorro?.asoId === 0}>
              <BeneficiarioComponent ahorro={ahorro} toast={toast} />
            </TabPanel>

            <TabPanel header="Firmas"  disabled ={ahorro?.asoId === 0}>
              <Firma 
              ahorro={ahorro} toast={toast}
              />

            </TabPanel>
            <TabPanel abPanel header="Saldos"  disabled ={ahorro?.asoId === 0}>
              <SaldoComponente ahorro={ahorro} />
            </TabPanel>
            <TabPanel header="Administrar"  disabled ={ahorro?.asoId === 0}>
              <Administrar
                ahorro={ahorro}
                setAhorro={setAhorro}
                toast={toast}
              />
            </TabPanel>
          </TabView>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
