import React, { useState, useEffect, useCallback } from "react";
import { Divider } from "primereact/divider";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Button } from "primereact/button";
//funciones
import formatDate from "../../../../utilities/formatDateTable";
import formatMiles from "../../../../utilities/formatMiles";
import dateNow from "../../../../utilities/dateNow";
import dateLastMonth from "../../../../utilities/dateLastMonth";
import onChajeObjN from "../../../../utilities/onChajeObjN";

//servicios
import AhoMovimientoService from "../../../../service/AhoCuentaService/AhoMovimientoService";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import printJS from "print-js";
import { PrintContent } from "./PrintContent";

export const SaldoComponente = ({ ahorro }) => {
  const handlePrint = useCallback(() => {
    printJS({
      printable: "printable-content",
      type: "html",
      targetStyles: ["*"],
      font_size: "12px",
    });
  }, []);

  let filtroEmpty = {
    fechaIni: dateLastMonth(),
    fechaFin: dateNow(),
  };

  const [transacciones, setTransacciones] = useState([]);
  const [filtro, setFiltro] = useState(filtroEmpty);
  //const [selection, setSelection] = useState();
  //const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [totalDebito, setTotalDebito] = useState(0);
  const [totalCredito, setTotalCredito] = useState(0);
  const [tranDialo, setTranDialog] = useState(false);
  const [saldoTotal, setSaldoTotal] = useState(0);

  const [imprimir, setImprimir] = useState(false);
  useEffect(() => {
    const ahoMovimientoService = new AhoMovimientoService();
    ahoMovimientoService
      .findDate(ahorro.ahoId, filtro.fechaIni, filtro.fechaFin)
      .then((response) => {
        setTransacciones(response);


        var creditoTotal = 0;
        var debitoTotal = 0;
        response?.forEach((element) => {
          creditoTotal += Number(element?.credito);
          debitoTotal += Number(element?.debito);
          setSaldoTotal(Number(element?.saldo));
        });
        setTotalCredito(creditoTotal);
        setTotalDebito(debitoTotal);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function formatDateTable(rowData) {
    if (rowData.fecha === "") {
      return "";
    } else {
      return <> {formatDate(rowData.fecha)}</>;
    }
  }

  function left() {
    return (
      <>
        <div className="p-grid p-fluid p-formgrid">
          <div className="p-col-4">
            <label>Fecha Inicial</label>
            <InputText
              type={"date"}
              value={filtro.fechaIni}
              onChange={(e) => onChajeObjN(e, "fechaIni", filtro, setFiltro)}
            />
          </div>

          <div className="p-col-4">
            <label>Fecha Final</label>
            <InputText
              type={"date"}
              value={filtro.fechaFin}
              onChange={(e) => onChajeObjN(e, "fechaFin", filtro, setFiltro)}
            />
          </div>
        </div>
      </>
    );
  }

  function buscar() {
    const ahoMovimientoService = new AhoMovimientoService();
    ahoMovimientoService
      .findDate(ahorro.ahoId, filtro.fechaIni, filtro.fechaFin)
      .then((response) => {
        setTransacciones(response);
        var creditoTotal = 0;
        var debitoTotal = 0;
        // console.log("dentro")
        //console.log(response)
        response?.forEach((element) => {
          creditoTotal += Number(element?.credito);
          debitoTotal += Number(element?.debito);
          setSaldoTotal(Number(element?.saldo));
        });
        setTotalCredito(creditoTotal);
        setTotalDebito(debitoTotal);
      });
  }

  function right() {
    return (
      <>
        <Button
          className="p-mr-3 p-button"
          icon="pi   pi-print"
          iconPos="right"
          label="Imprimir"
          onClick={() => setImprimir(true)}
        />
        <Button
          className="p-mr-3 p-button-warning"
          icon="pi  pi-search"
          iconPos="right"
          label="Buscar"
          onClick={buscar}
        />
      </>
    );
  }
  let footerGroup = (
    <ColumnGroup
    scrollable={true}
    >
      <Row>
        <Column
        // footer="Totales:"
        //colSpan={2}
        //footerStyle={{ textAlign: "right" }}
        />
        <Column
        // footer="Totales:"
        // colSpan={2}
        // footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer="Totales:"
          // colSpan={2}
          //  footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={`${formatMiles(totalDebito)}`}
          footerStyle={{ textAlign: "right", color: "#CE3F2C" }}
        />
        <Column
          footer={`${formatMiles(totalCredito)}`}
          footerStyle={{ textAlign: "right" }}
        />
        <Column
          footer={`${formatMiles(saldoTotal)}`}
          footerStyle={{ textAlign: "right" }}
        />
      </Row>
    </ColumnGroup>
  );

  function debitoColum(rowData) {
    if (rowData.fecha === "") {
      return "";
    } else {
      return (
        <div style={{textAlign: "right", color: "#CE3F2C" }}>
          {formatMiles(rowData.debito)}
        </div>
      );
    }
  }

  function creditoColum(rowData) {
    return (
      <div style={{ textAlign: "right" }}>{formatMiles(rowData.credito)}</div>
    );
  }

  function saldo(rowData) {
    return (
      <div style={{ textAlign: "right" }}><label>{formatMiles(rowData.saldo)}</label></div>
    );
  }

  function descripcion(rowData) {
    return (
      <div>
        {rowData.transaccion}/{rowData.referencia || "SIN REFERENCIA"}
      </div>
    );
  }

  function tableTransaccion() {
    return (
      <>
        <DataTable
          emptyMessage="No se encontro ninguna transaccion"
          value={transacciones}
          selectionMode="single"
          // onSelectionChange={(e) => {
          //   setSelection(e.value); setTranDialog(true)
          // }}
          dataKey="movimiento"
          // selection={setSelectedProduct1}
          footerColumnGroup={footerGroup}
          paginator
          rows={7}
          size="small"
          //className="datatable-responsive"
          responsive={true}
         
        
          //scrollable={true}
         
         
        >
          <Column header="Fecha" field="fecha" body={formatDateTable} />
          <Column header="Hora" field="hora" />
          <Column header="Descripción" field="transaccion" body={descripcion} />
          <Column header="Débido" field="debito" body={debitoColum} />
          <Column header="Crédito" field="credito" body={creditoColum} />
          <Column
            header="Saldo"
            field="saldo"
            body={saldo}
            //style={{ textAlign: "right", alingItems: "right",float:"right" }}
            //bodyStyle={{ textAlign: "right", alingItems: "right" ,float:"right"}}
          />
        </DataTable>
      </>
    );
  }

  function informacionCard4() {
    return (
      <div className=" p-grid p-mt-4">
        <div className="p-col-12 p-md-12 p-lg-12">
          <div className="p-grid">
            <div className="p-col-6">
              <div
                className="card no-gutter widget-overview-box widget-overview-box-1 "
                style={{ height: "96%" }}
              >
                <span className="overview-icon">
                  <i className="pi pi-info"></i>
                </span>
                <span className="overview-title">Informacion</span>

                <div
                  className="p-grid overview-detail p-mr-5 p-ml-5"
                  style={{ textAlign: "left" }}
                >
                  <div className="p-col-6">
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Producto: </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Sub producto: </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Apertura: </b>
                      </label>
                    </div>

                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Tasa Base : </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Tasa Negociada: </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Ultimo Movimiento: </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Estado de la cuenta: </b>
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        <b>Libreta/ Certificado: </b>
                      </label>
                    </div>
                  </div>
                  <div className="p-col-6">
                    <div className="overview-subtext">
                      {" "}
                      <label style={{ fontSize: "12px" }}>
                        {ahorro?.appAhoSubProducto?.appProducto?.proNombre}
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {ahorro?.appAhoSubProducto?.subAhoNombre}
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {formatDate(ahorro?.ahoFechaApertura)}
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {ahorro?.ahoTasaBase}%
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {ahorro?.ahoTasaNegociado}%
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {formatDate(ahorro?.ahoFechaUltimoPago)}
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {ahorro.ahoEstado === 1
                          ? "VIGENTE"
                          : ahorro.ahoEstado === 2
                          ? "BLOQUEADO"
                          : ahorro.ahoEstado === 3
                          ? "CANDELADO"
                          : ahorro.ahoEstado === 4
                          ? "VENCIDO"
                          : "NO ENCONTRADO"}
                      </label>
                    </div>
                    <div className="overview-subtext">
                      <label style={{ fontSize: "12px" }}>
                        {ahorro?.ahoNumeroLibreta ||
                          ahorro?.ahoNumeroCertificado}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-6">
              <div className="p-grid">
                <div className="p-col-12">
                  <div className="card no-gutter widget-overview-box widget-overview-box-3  ">
                    <Divider layout="horizontal" align="left">
                      <div>
                        <span className="overview-icon p-mr-2 ">
                          <i className="pi pi-dollar "></i>
                        </span>
                        <span className="overview-title">Saldos</span>
                      </div>
                    </Divider>

                    <div className="p-formgrid p-grid p-fluid">
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Total:</b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoSaldoTotal)}
                        </label>
                      </div>
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Anterior: </b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoSaldoAnterior)}
                        </label>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid p-fluid">
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Libreta:</b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoSaldoLibreta)}
                        </label>
                      </div>
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Reserva: </b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoSaldoReserva)}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="p-col-12">
                  <div className="card no-gutter widget-overview-box widget-overview-box-4  ">
                    <Divider layout="horizontal" align="left">
                      <span className="overview-icon">
                        <i className="pi pi-percentage"></i>
                      </span>
                      <samp className="overview-title">Interes</samp>
                    </Divider>
                    <div className="p-formgrid p-grid p-fluid">
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Mes:</b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoInteresMes)}
                        </label>
                      </div>
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>Acumulado: </b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoInteresAcumulado)}
                        </label>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid p-fluid">
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>In.mes:</b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoImpuestoMes)}
                        </label>
                      </div>
                      <div className="p-col-2">
                        <label style={{ fontSize: "12px" }}>
                          <b>In.acumulado:</b>
                        </label>
                      </div>
                      <div className="p-col-4" style={{ textAlign: "right" }}>
                        <label style={{ fontSize: "12px", textAlign: "right" }}>
                          {formatMiles(ahorro?.ahoImpuestoAcumulado)}
                        </label>
                      </div>
                    </div>

                    {/* <div className="p-formgrid p-grid p-fluid">
                  <div className="p-col">
                    <label style={{ fontSize: "11px" }}>
                      <b>Mes:</b>
                    </label>
                  </div>
                  <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(ahorro?.ahoInteresMes)}
                    </label>
                  </div>
                  <div className="p-col-1">
                    <label style={{ fontSize: "11px" }}>
                      <b>Acumulado: </b>
                    </label>
                  </div>
                  <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(ahorro?.ahoInteresAcumulado)}
                    </label>
                  </div> */}
                    {/* <div className="p-col">
                    <label style={{ fontSize: "11px" }}>
                      <b>Total: </b>
                    </label>
                  </div> */}
                    {/* <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(
                        Number(ahorro?.ahoInteresMes) +
                          Number(ahorro?.ahoInteresAcumulado)
                      )}
                    </label>
                  </div> */}
                    {/* </div>

                <div className="p-formgrid p-grid p-fluid ">
                  <div className="p-col">
                    <label style={{ fontSize: "11px" }}>
                      <b>In.mes:</b>
                    </label>
                  </div>
                  <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(ahorro?.ahoImpuestoMes)}
                    </label>
                  </div>
                  <div className="p-col-1">
                    <label style={{ fontSize: "11px" }}>
                      <b>In.acumulado:</b>
                    </label>
                  </div>
                  <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(ahorro?.ahoImpuestoAcumulado)}
                    </label>
                  </div> */}
                    {/* <div className="p-col">
                    <label style={{ fontSize: "11px" }}>
                      <b>In.total:</b>
                    </label>
                  </div> */}
                    {/* <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(
                        Number(ahorro?.ahoImpuestoMes) +
                          Number(ahorro?.ahoImpuestoAcumulado)
                      )}
                    </label>
                  </div> */}
                    {/* </div> */}

                    {/* <Divider layout="horizontal" align="left" />
                <div className="p-formgrid p-grid p-fluid">
                  <div className="p-col">
                    <label style={{ fontSize: "11px" }}>
                      <b>Total a pagar:</b>
                    </label>
                  </div>
                  <div className="p-col" style={{ textAlign: "right" }}>
                    <label style={{ fontSize: "11px", textAlign: "right" }}>
                      {formatMiles(
                        Number(ahorro?.ahoInteresMes) +
                          Number(ahorro?.ahoInteresAcumulado) -
                          (Number(ahorro?.ahoImpuestoMes) +
                            Number(ahorro?.ahoImpuestoAcumulado))
                      )}
                    </label>
                  </div>
                </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12">
          <Divider layout="horizontal" align="left">
            <div>
              <span className="overview-icon p-mr-2 ">
                <i className="pi pi-money-bill"></i>
              </span>
              <samp className="overview-title">transacciones</samp>
            </div>
          </Divider>
          <div className="p-grid p-mt-2">
            <div className="p-col-12">
              <Toolbar left={left} right={right} />
            </div>
            <div className="p-col-12"> {tableTransaccion()}</div>
          </div>
        </div>
      </div>
    );
  }

  const footerDialog = () => {
    return (
      <div>
        <Button
          className="p-button-danger p-button "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => {
            setImprimir(false);
          }}
        />

        <Button
          className=" p-button "
          icon="pi pi-print"
          label="Imprimir"
          onClick={() => {
            handlePrint();
            setImprimir(false);
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div>{informacionCard4()}</div>

      <Dialog
        visible={tranDialo}
        onHide={() => setTranDialog(false)}
        header={"Detalles de la transaccion"}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >
        <div className="card"></div>
      </Dialog>

      <div>
        <Dialog
          visible={imprimir}
          onHide={() => setImprimir(false)}
          header={"Estado de cuenta"}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "900px" }}
          maximizable
          footer={footerDialog}
        >
          <PrintContent
            filtro={filtro}
            ahorro={ahorro}
            transacciones={transacciones}
            formatDateTable={formatDateTable}
            handlePrint={handlePrint}
            totalDebito={totalDebito}
            totalCredito={totalCredito}
            saldoTotal={saldoTotal}
          />
        </Dialog>
      </div>
    </>
  );
};
