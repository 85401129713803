import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";

import AppCamposAdicionalesService from "../../service/AppServices/AppCamposAdicionalesService";
import CliCampoAdicionalService from "../../service/CliServices/CliCampoAdicionalService";

const CliAsociadoCamposDefinidosComponenet = (props) => {
  let [appCamposAdicionales, setAppCamposAdicionales] = useState([]);
  const [cliCampoAdicional, setCliCampoAdicional] = useState();

  let cliCampoAdicionalNew = {
    asoId: 0,
    camCliFecha: "",
    camCliId: 0,
    camCliNumero: 0,
    camCliTexto: "",
    camId: 0,
  };

  useEffect(() => {
    const appCamposAdicionalesService = new AppCamposAdicionalesService();
    appCamposAdicionalesService.findAll().then((data) => {
      setAppCamposAdicionales(data);
    });
  }, []);

  useEffect(() => {
    const cliCampoAdicionalService = new CliCampoAdicionalService();
    cliCampoAdicionalService
      .findAllByCliAsoId(props.asociado.asoId)
      .then((data) => {

        setCliCampoAdicional(data || [{ code: "", name: "" }]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function toJSONLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }

  const save = (e, camCliId, camTipoId, camId) => {
    // let existeCampo;
    const cliCampoAdicionalService = new CliCampoAdicionalService();

    if (camCliId > 0) {
      // existeCampo = cliCampoAdicional?.find(
      //   (cliCampos) => cliCampos?.camCliId == camCliId
      // );
      cliCampoAdicional.map(function (dato) {
        if (dato?.camCliId === camCliId) {
          if (camTipoId === 1 || camTipoId === 3) {
            dato.camCliTexto = e;
          } else if (camTipoId === 2 || camTipoId === 4) {
            dato.camCliNumero = e;
          }
    
          cliCampoAdicionalService.save(dato).then(() => {
            cliCampoAdicionalService
              .findAllByCliAsoId(props?.asociado?.asoId)
              .then((data) => setCliCampoAdicional(data));
          });
        }
        return null;
      });
    } else {
      if (props?.asociado?.asoId) {
        if (camTipoId === 1 || camTipoId === 3) {
          cliCampoAdicionalNew = {
            asoId: props?.asociado?.asoId,
            camCliFecha: new Date(),
            camCliId: 0,
            camCliNumero: 0,
            camCliTexto: e,
            camId: camId,
          };
          cliCampoAdicionalService.save(cliCampoAdicionalNew).then((data) => {
            cliCampoAdicionalService
              .findAllByCliAsoId(props?.asociado?.asoId)
              .then((data) => setCliCampoAdicional(data));
          });
        } else if (camTipoId === 2 || camTipoId === 4) {
          cliCampoAdicionalNew = {
            asoId: props?.asociado?.asoId,
            camCliFecha: new Date(),
            camCliId: 0,
            camCliNumero: e,
            camCliTexto: "",
            camId: camId,
          };
          cliCampoAdicionalService.save(cliCampoAdicionalNew).then((data) => {
            cliCampoAdicionalService
              .findAllByCliAsoId(props?.asociado?.asoId)
              .then((data) => setCliCampoAdicional(data));
          });
        }
      }
    }
  };

  const listItems = [...appCamposAdicionales].map((person,index) => {
    var cliCampo;
    var seleccion;
    var campoClienteSeleccion;
    if (
      (person.camTipoId === 1 && cliCampoAdicional.length > 0) ||
      (person.camTipoId === 2 && cliCampoAdicional.length > 0) ||
      (person.camTipoId === 3 && cliCampoAdicional.length > 0)
    ) {
      cliCampo = cliCampoAdicional?.find(
        (cliCampos) => cliCampos?.camId === person?.camId
      );
    } else if (person.camTipoId === 4 && cliCampoAdicional.length > 0) {
      campoClienteSeleccion = cliCampoAdicional?.find(
        (cliCampos) => cliCampos?.camId === person?.camId
      );

      //Obtenemos el objeto del tipo de campo para despues buscar sus opciones seleccionables
      cliCampo = appCamposAdicionales.find(
        (cliCampos) => cliCampos?.camId === person?.camId
      );
    }

    if (person.camTipoId === 1 && props.seccion === person.secId) {
      return (
 
          <div className="p-field p-col-4" key={1}>
         
            <label htmlFor="asoId">{person?.camEtiqueta} </label>
            <InputText
              key={1}
              id={cliCampo?.camCliId}
              type="text"
              value={cliCampo?.camCliTexto || ""}
              onChange={(e) =>
                save(
                  e.target.value,
                  cliCampo?.camCliId,
                  person?.camTipoId,
                  person?.camId
                )
              }
              className="p-inputtext"
            />
     
        </div>
      );
    } else if (person?.camTipoId === 2 && props.seccion === person.secId) {
      return (
       
          <div className="p-field p-col-4" key={ 2}>
      
            <label htmlFor="asoId">{person?.camEtiqueta}</label>
            <InputText
              key={ 2}
              id={cliCampo?.camCliId}
              type="number"
              value={cliCampo?.camCliNumero}
              onChange={(e) =>
                save(
                  e.target.value,
                  cliCampo?.camCliId,
                  person?.camTipoId,
                  person?.camId
                )
              }
              // autoFocus={true}
              // readOnly={true}
              className="p-inputtext"
            />
          </div>
    
      );
    } else if (person?.camTipoId === 3 && props.seccion === person.secId) {
      let fecha = new Date(cliCampo?.camCliTexto);
      fecha.setMinutes(fecha.getMinutes() + fecha?.getTimezoneOffset());

      return (
  
          <div className="p-field p-col-4" key={ 3}>
      
            <label htmlFor="asoId">{person?.camEtiqueta}</label>
            <Calendar
              key={ 3}
              id={cliCampo?.camCliId}
              showIcon
              showButtonBar
              value={fecha}
              onChange={(e) => {
                var newDate = toJSONLocal(e.value);
                save(
                  newDate,
                  cliCampo?.camCliId,
                  person?.camTipoId,
                  person?.camId
                );
              }}
              //   disabled={editAsociado}
              dateFormat="dd/mm/yy"
              monthNavigator
              yearNavigator
              yearRange="1917:2500"
              //   required="true"
            ></Calendar>
          </div>
     
      );
    } else if (person?.camTipoId === 4 && props.seccion === person.secId) {
      //Obtebemos el valor de campo si ya fue ingresado
      seleccion = cliCampo?.appCampoAdicionalDetalles.find(
        (camposAd) =>
          camposAd?.camDetValor === campoClienteSeleccion?.camCliNumero
      );

      return (
     
          <div className="p-field p-col-4" key={index}>
          
            <label htmlFor="asoId">{person?.camEtiqueta}</label>
            <Dropdown
              id={campoClienteSeleccion?.camCliId}
              
              value={seleccion}
              onChange={(e) =>
                save(
                  e?.value?.camDetValor,
                  campoClienteSeleccion?.camCliId,
                  person?.camTipoId,
                  person?.camId
                )
              }
              options={person?.appCampoAdicionalDetalles}
              optionLabel="camDetDescripcion"
              placeholder="Seleccione..."
            ></Dropdown>
          </div>
  
      );
    } else {
      return null;
    }
  });

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-md-12">
          <div className="p-formgrid p-grid">{listItems}</div>
        </div>
      </div>
    </>
  );
};

export default CliAsociadoCamposDefinidosComponenet;
