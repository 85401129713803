import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { GraAgencias } from "./Graficas/GraAgencias";
import { GraGenero } from "./Graficas/GraGenero";
//Servicios
import EventoService from "../../service/AsambleaServices/EventoService";
import { GraGeneraciones } from "./Graficas/GraGeneraciones";
import { GraTotal } from "./Graficas/GraTotal";
import { Toolbar } from "primereact/toolbar";

//REPORTE
import { AsambleaReport } from "./EventoPages/EventoReport/AsambleaReport";
import { Button } from "primereact/button";
export const AsableaHome = () => {
  const [eventos, setEventos] = useState([]);
  const [eventId, setEventId] = useState(1);
  const [evento, setEvento] = useState({
    eveId: 1,
  });
  const [reload,setReload] = useState(true)
  useEffect(() => {
    const eventoService = new EventoService();
    eventoService.findAll().then((res) => {
      if (res?.length > 0) {
        var events = [];
        res?.forEach((element) => {
          var evento = {
            value: element?.eveId,
            label: element?.eveNombre,
          };
          events.push(evento);
        });
        setEventos(events);
      }
    });
  }, [reload]);

  function objet(eveId) {
    var returnObjet = {
      eveId: 1,
      eveNombre: eventos?.label || "ASAMBLEA ORDINARIA ANUAL 2023",
    };
    eventos.forEach((element) => {
      if (element?.value === eveId) {
        returnObjet = {
          eveId: element?.value,
          eveNombre: element?.label,
        };
      }
    });

    return returnObjet;
  }

  useEffect(() => {
    
    var varEvent = objet(eventId);
    setEvento(varEvent);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId]);

  const right = () => {
    return (
      <>
        <Dropdown
          className="p-mr-2"
          placeholder="Seleccione un evento"
          value={eventId}
          options={eventos}
          onChange={(e) => setEventId(e.value)}
        />
        <AsambleaReport evento={evento} />

        <Button icon="pi pi-replay" className="p-ml-2 " aria-label="Filter" 
        onClick={()=>setReload(!reload)}
        />
      </>
    );
  };

  return (
    <>
      <Toolbar className="card p-mb-2" left={"Eventos"} right={right} />

      <div className="p-grid ">
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <div className="card revenue">
            <h4>Genero</h4>
            <GraGenero eventId={eventId} />
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <div className="card revenue">
            <h4>Sucursal</h4>
            <GraAgencias eventId={eventId} />
          </div>
        </div>
      </div>
      <div className="p-grid">
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <div className="card revenue">
            <h4> Generación</h4>
            <GraGeneraciones eventId={eventId} />
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <div className="card revenue">
            <h4>Total</h4>
            <GraTotal eventId={eventId} />
          </div>
        </div>
      </div>
    </>
  );
};
