import React from 'react'
export const Access = () => {
  return (
    // <div className="screen">
    //   <div className="scene">
    //     <div className="overlay"></div>
    //     <div className="overlay"></div>
    //     <div className="overlay"></div>
    //     <div className="overlay"></div>
    //     <span className="bg-403">403</span>
    //     <div className="text">
    //       <span className="hero-text"></span>
    //       <span className="msg">
    //         no puedo <span>dejarte</span> entrar.
    //       </span>
    //       <span className="support">
    //         <span>¿Inesperado?</span>
    //         <a href="#">Regresar a la pagina de inicio</a>
    //       </span>
    //     </div>
    //     <div className="lock"></div>
    //   </div>
    // </div>
    <div className="screen">
      <div class="message">Usted no está autorizado.</div>
      <div class="message2">
        Intentó acceder a la que no tenía autorización previa.
        <span className="support">
          {/* <span>¿Inesperado?</span> */}
          <a href="/#">Regresar a la pagina de inicio</a>
        </span>
      </div>
      <div class="container">
        <div class="neon">403</div>
        <div class="door-frame">
          <div class="door">
            <div class="rectangle"></div>
            <div class="handle"></div>
            <div class="window">
              <div class="eye"></div>
              <div class="eye eye2"></div>
              <div class="leaf"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
