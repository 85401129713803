import React, { useEffect  } from "react";
import useAuth from "../../../../auth/useAuth";
import formatDate from "../../../../utilities/formatDateTable";
import { v4 as uuidv4 } from "uuid";
import "./TablaDatos.css";
import formatMiles from "../../../../utilities/formatMiles";

export const PrintContent = ({
  filtro,
  ahorro,
  transacciones,
  formatDateTable,
  handlePrint,
  totalDebito,
  totalCredito,
  saldoTotal
}) => {
  const auth = useAuth();

  let estilosTabla = {
    derecha: {
      textAlign: "right",
      padding: "1px",
      paddingRight: "10px",
    },
    derechaResultado: {
      textAlign: "right",
      padding: "1px",
      paddingRight: "10px",
      borderTop: "1px solid black",
    },
    izquierda: {
      textAlign: "left",
      padding: "1px",
    },
    derechaDebito: {
        textAlign: "right",
        padding: "1px",
        paddingRight: "10px",
        color:"red"
        
      },
      derechaDebitoResultado: {
        textAlign: "right",
        padding: "1px",
        paddingRight: "10px",
        color:"red",
        borderTop: "1px solid black",
        
      },
  };


  useEffect(() => {
    handlePrint();
    
    
  }, [handlePrint]);

  const generateId = () => {
    return uuidv4();
  };

  function formatDateTable1(rowData) {
    if (rowData.fecha === "") {
      return "";
    } else {
      return <> {formatDate(rowData.fecha)}</>;
    }

  }


  return (
    <div id="printable-content">
      <div>
        <div style={{ textAlign: "center" }}>
          <h5 className="p-m-1">
            {auth.user?.usr?.appSucursal?.appEmpresa?.empNombre}
          </h5>
          <h5 className="p-m-1">{auth.user?.usr?.appSucursal?.sucNombre}</h5>
          <h5 className="p-m-1">ESTADO DE CUENTA</h5>
          <h5 className="p-m-1">
            Del: {formatDate(filtro?.fechaIni)} Al:{" "}
            {formatDate(filtro?.fechaFin)}
          </h5>
        </div>
        <div>
          <h5 className="p-m-1">
            Numero de cuenta: {ahorro.ahoId} -{" "}
            {ahorro?.appAhoSubProducto?.subAhoNombre}
          </h5>
          <h5 className="p-m-1">
            Nombre: {ahorro.ahoNombre} - {ahorro?.asoId}
          </h5>
          <h5 className="p-m-1">
            Direccion: {ahorro?.cliDireccion?.dirDireccion}{" "}
          </h5>
        </div>
      </div>
      <table style={{ width: "100%" }}>
        <thead >
          <tr style={{ borderBottom: "1px solid black" }}>
            <th style={estilosTabla.izquierda}>Fecha</th>
            <th style={estilosTabla.izquierda}>Hora de movimiento</th>
            <th
              style={{
                textAlign: "center",
                padding: "1px",
              }}
            >
              Descripcion/referencia
            </th>
            <th style={estilosTabla.derecha}>Debito</th>
            <th style={estilosTabla.derecha}>Credito</th>
            <th style={estilosTabla.derecha}>Saldo</th>
          </tr>
        </thead>
        <tbody>
          {transacciones.map((item) => (
            <tr key={generateId()}>
              <td style={estilosTabla.izquierda}>{formatDateTable1(item)}</td>
              <td style={estilosTabla.izquierda}>{item.hora}</td>
              <td style={estilosTabla.izquierda}>{item.transaccion}/ { item.referencia}</td>
              <td style={estilosTabla.derechaDebito}>{formatMiles(item.debito)}</td>
              <td style={estilosTabla.derecha}>{formatMiles(item.credito)}</td>
              <td style={estilosTabla.derecha}>{formatMiles(item.saldo)} </td>
            </tr>
          ))}
          <tr style={{ borderTop: "1px solid black" }}>
            <td />
            <td />
            <td />
            <td style={estilosTabla.derechaDebitoResultado}>{formatMiles(totalDebito)}</td>
            <td style={estilosTabla.derechaResultado}>{formatMiles(totalCredito)}</td>
            <td style={estilosTabla.derechaResultado}>{formatMiles(saldoTotal)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
