import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { ProgressSpinner } from "primereact/progressspinner";

//Import services
import AppModuloService from "../../../../service/AppServices/AppModuloService";
import formatDate from "../../../../utilities/formatDateTable";

let menssageCierreDiario = {
  code: 0,
  description: "",
  message: "",
};

export const CierreDiario = () => {
  const [appModulos, setAppModulos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [menssage, setMenssage] = useState(menssageCierreDiario);

  useEffect(() => {
    const appModuloService = new AppModuloService();
    appModuloService.findAll().then((response) => {
      setAppModulos(response);
    });
  }, []);

  const renderModulos = () => {
    return appModulos.map((appModulo, i) => (
      <div key={i}>
        <div className="p-grid p-mb-0" key={i}>
          <div className="p-col-12 p-md-4 p-mb-0">
            <p>{appModulo.modNombre}</p>
          </div>
          <div className="p-col-12 p-md-4 p-mb-0">
            <p>{formatDate(appModulo.modFechaHoy)}</p>
          </div>
          <div className="p-col-12 p-md-4 p-mb-0">
            <p>{formatDate(appModulo.modFechaSiguiente)}</p>
          </div>
        </div>
        <Divider className="p-mt-0" />
      </div>
    ));
  };

  const procesarCierreDiario = () => {
    setLoading(true);
    const appModuloService = new AppModuloService();
    appModuloService
      .procesarCierreDiario()
      .then((response) => {
        window.location.reload();
        appModuloService.findAll().then((response) => {
          setAppModulos(response);
        });

        setMenssage(response);
        setLoading(false);
      })
      .catch((error) => {
        setMenssage(error);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <div className="card p-grid p-col-12">
        {/* Start Seccion Foto t Contraseña */}
        <div
          className="p-col-12 p-md-5 p-fluid"
          style={{ textAlign: "center" }}
        >
          {/* <div className="card p-fluid"> */}
          <h3 style={{ fontWeight: "bold" }}>Cierre Diario</h3>

          <Divider align="center">
            <div className="inline-flex align-items-center">
              <b>Hoy</b>
            </div>
          </Divider>

          <p>
            <b>Fecha Actual: </b>
            <span className="p-float-right">
              {new Date().toISOString().substring(0, 10)}
            </span>
            <br />
            <b>Fecha Cierre: </b>
            <span className="p-float-right">
              {appModulos.length > 0 && appModulos[0].modFechaHoy}
            </span>
            <br />
            <b>Fecha Siguiente: </b>
            <span className="p-float-right">
              {appModulos.length > 0 && appModulos[0].modFechaSiguiente}
            </span>
          </p>

          <Button
            icon="pi pi-list"
            style={{
              display: "block",
              background: "#0791e6",
              fontSize: "14px",
            }}
            label="Procesar Cierre"
            className="p-button-sm p-button-rounded"
            onClick={() => procesarCierreDiario()}
          />
          <br />
          <p>
            <b>Mensaje: </b>
            <span className="p-float-right">{menssage.message}</span>
            <br />
            <b>Descripcion: </b>
            <span className="p-float-right">{menssage.description}</span>
          </p>
          <ProgressSpinner
            style={{ display: loading === true ? "" : "none" }}
          />
        </div>
        {/* End Seccion Foto t Contraseña */}

        {/* Start Seccion Tus Datos */}
        <div
          className="p-col-12 p-md-7 p-fluid"
          style={{ borderLeft: "1px solid #F2F2F2" }}
        >
          <h3 style={{ fontWeight: "bold" }}>Fecha Módulos</h3>

          <div className="p-grid p-mb-0">
            <div className="p-col-12 p-md-4 p-mb-0">
              <p style={{ fontWeight: "bold" }}>Módulo</p>
            </div>
            <div className="p-col-12 p-md-4 p-mb-0">
              <p style={{ fontWeight: "bold" }}>Fecha Actual</p>
            </div>
            <div className="p-col-12 p-md-4 p-mb-0">
              <p style={{ fontWeight: "bold" }}>Fecha Siguiente</p>
            </div>
          </div>
          <Divider className="p-mt-0" />
          {renderModulos()}
        </div>
        {/* End Seccion Tus Datos */}
      </div>
    </div>
  );
};
