import React, { useRef, useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import classNames from "classnames";
import { Messages } from "primereact/messages";
//Servicios

import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
export const NuevoProducto = ({
  setNuevo,
  tipoProducto,
  newProductoHook,
  setRecargar,
  recargar,
  toast,
}) => {
  const message = useRef();
  const [newProducto, setNewProducto] = useState(newProductoHook);
  const [tipoProductoSelect, setTipoProductoSelect] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  let objet = () => {
    return tipoProducto.reduce(
      (objet, producto) => {
        if (producto.proTipoId !== 0) {
          objet.producto.push(producto);
        }
        return objet;
      },

      { producto: [] }
    );
  };
  function gfg() {
    setSubmitted(false);
  }

  useEffect(() => {
    var producto = objet();
    setTipoProductoSelect(producto?.producto);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newProducto = { ...newProducto };
    _newProducto[`${name}`] = val.toUpperCase();
    setNewProducto(_newProducto);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _newProducto = { ...newProducto };
    _newProducto[`${name}`] = val;
    setNewProducto(_newProducto);
    _newProducto[`${name2}`] = val1;
    setNewProducto(_newProducto);
  };

  function onSave() {
    if (
      newProducto.proNombre !== "" &&
      newProducto.proTipoId !== 0 &&
      newProducto.proId !== 0 &&
      newProducto.proId !== ""
    ) {
      const appProductoService = new AppTipoProductoService();
      appProductoService.save(newProducto).then(() => {
        var recargar1 = recargar === true ? false : true;
        setRecargar(recargar1);
        setNuevo(false);
        setSubmitted(false);
        if (newProductoHook?.proId !== 0) {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Producto actualizado",
            life: 4000,
          });
        } else {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Producto creado",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      setTimeout(gfg, 4000);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
    //setNuevo(false);
  }
  return (
    <div>
      <div className="card ">
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Codigo</label>
            <InputText
              value={newProducto?.proId}
              onChange={(e) => onInputChange(e, "proId")}
              className={classNames({
                "p-invalid": submitted && !newProducto.proId,
              })}
              disabled={newProductoHook?.proId !== 0}
            />
            {submitted && !newProducto.proId && (
              <small className="p-invalid">Codigo del producto requerido</small>
            )}
          </div>

          <div className="p-col">
            <label>Nombre del producto</label>
            <InputText
              value={newProducto?.proNombre}
              onChange={(e) => onInputChange(e, "proNombre")}
              className={classNames({
                "p-invalid": submitted && !newProducto.proNombre,
              })}
            />
            {submitted && !newProducto.proNombre && (
              <small className="p-invalid">Nombre del producto requerido</small>
            )}
          </div>
        </div>
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Tipo de producto</label>
            <Dropdown
              value={newProducto.appTipoProducto}
              options={tipoProductoSelect}
              optionLabel="proTipoNombre"
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appTipoProducto",
                  e.target.value.proTipoId,
                  "proTipoId"
                );
              }}
              placeholder="Seleccione al producto que pertenece"
              className={classNames({
                "p-invalid": submitted && !newProducto.proTipoId,
              })}
            />
            {submitted && !newProducto.proTipoId && (
              <small className="p-invalid"></small>
            )}
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div style={{ alignItems: "flex-end", textAlign: "right" }}>
        <Button
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => 
            {setNuevo(false);
            toast.current.show({
              severity: "error",
              summary: "Error",
              detail: "Cambios descartados",
              life: 4000,
            })
          }}
        />
        <Button icon="pi pi-save" label="Guardar " onClick={() => onSave()} />
      </div>
    </div>
  );
};
