import { Route, Redirect, useLocation } from 'react-router-dom'
import useAuth from '../auth/useAuth'
import useAuthExpired from '../utilities/useAuthExpired'

export default function PrivateRoute({ component: Component, ...rest }) {
  const auth = useAuth()
  const location = useLocation()
  const isAuth = useAuthExpired()

  return (
    <Route {...rest}>
      {auth.isLogger() && isAuth ? (
        <Component />
      ) : (
        <Redirect
          to={{ pathname: '/login', state: { from: location.pathname } }}
        />
      )}
    </Route>
  )
}
