import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import classNames from "classnames";
//Services
import AppTasaService from "../../../../service/AppServices/AppTasaService";
import AppAhoTasaPlazoRangoService from "../../../../service/AppServices/AppAhoTasaPlazoRangoService";

export const NuevoTasaRango = ({
  setNuevoTasaRangos,
  toastRango,
  tasaRango,
  newProducto,
  estado,
  setEstado,
}) => {
  const message = useRef();
  const [newTasaRango, setNewTasaRango] = useState(tasaRango);
  const [appTasas, setAppTasas] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const appTasaService = new AppTasaService();
    appTasaService.findAllAho().then((response) => {
      setAppTasas(response);
    });
  }, []);

  const onChangeNew = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newTasaRango = { ...newTasaRango };
    _newTasaRango[`${name}`] = val;
    setNewTasaRango(_newTasaRango);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    const val = (e.target && e.target.value) || "";
    let _newTasaRango = { ...newTasaRango };
    _newTasaRango[`${name}`] = val;
    setNewTasaRango(_newTasaRango);
    _newTasaRango[`${name2}`] = val1;
    setNewTasaRango(_newTasaRango);
  };

  function save() {
    if (
      newTasaRango?.ahoPlaInicial &&
      newTasaRango?.ahoPlaFinal &&
      newTasaRango?.ahoTasId !== 0 &&
      newTasaRango?.ahoPlaInicial &&
      newTasaRango?.ahoPlaFinal &&
      newTasaRango?.ahoTasId !== ""
    ) {
      let _newTasaRango = { ...newTasaRango };
      if (newTasaRango?.ahoSubAhoId === 0) {
        _newTasaRango["ahoSubAhoId"] = newProducto?.subAhoId;
      }

      const appAhoTasaPlazoRangoService = new AppAhoTasaPlazoRangoService();
      appAhoTasaPlazoRangoService.save(_newTasaRango).then(() => {
        setEstado(!estado);
        setNuevoTasaRangos(false);
        if (newTasaRango?.ahoPlaId === 0) {
          toastRango.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Tasa agregada",
            life: 4000,
          });
        } else {
          toastRango.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Tasa actualizada",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }

  return (
    <>
      <div className="card">
        <div className="p-grid ">
          <div className="p-col p-fluid ">
            <label>Plazo Inicial</label>
            <InputText
              value={newTasaRango?.ahoPlaInicial}
              type={"number"}
              placeholder="Inicio de Plazo ... 7"
              onChange={(e) => onChangeNew(e, "ahoPlaInicial")}
              className={classNames({
                "p-invalid": submitted && !newTasaRango?.ahoPlaInicial,
              })}
            />
            {submitted && !newTasaRango?.ahoPlaInicial && (
              <small className="p-invalid">Plazo Inicial Requerido</small>
            )}
          </div>

          <div className="p-col p-fluid ">
            <label>Plazo Final</label>
            <InputText
              value={newTasaRango?.ahoPlaFinal}
              type={"number"}
              placeholder="Fin de Plazo ... 30"
              onChange={(e) => onChangeNew(e, "ahoPlaFinal")}
              className={classNames({
                "p-invalid": submitted && !newTasaRango?.ahoPlaFinal,
              })}
            />
            {submitted && !newTasaRango?.ahoPlaFinal && (
              <small className="p-invalid">Plazo Final Requerido</small>
            )}
          </div>
        </div>
        <div className="p-grid">
          <div className="p-col p-fluid ">
            <label>Frecuencia de Tasa </label>
            <Dropdown
              value={newTasaRango?.appTasa}
              options={appTasas}
              optionLabel="tasNombre"
              placeholder="Tipo de Tasa..."
              className={classNames({
                "p-invalid": submitted && !newTasaRango?.ahoTasId,
              })}
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appTasa",
                  e.target.value.tasId,
                  "ahoTasId"
                );
              }}
            />
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "100px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => 
          {  setNuevoTasaRangos(false);
            toastRango.current.show({
              severity: "warn",
              summary: "Alerta",
              detail: "Tasa no creada/actualizada",
              life: 4000,
            })}
          }
        />
        <Button
          style={{ width: "100px" }}
          icon="pi pi-check"
          label="Guardar"
          onClick={() => save()}
        />
      </div>
    </>
  );
};
