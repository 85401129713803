import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
//Servicios
import CliCuentaBancoService from "../../service/CliServices/CliCuentaBancoService";

//Componentes
import { CliAsociadoNuevaCuentaBan } from "./CliAsociadoNuevaCuentaBan";
export const CliAsociadoCuentasBan = ({
  ahorro,
  movBan,
  setMovBan,
  setCuentaCli,
  slect,
  asociado,
}) => {
  const toast = useRef(null);

  function nombre() {
    let nombre =
      asociado?.asoNombreJuridico +
      " " +
      asociado?.asoPrimerNombre +
      " " +
      asociado?.asoSegundoNombre +
      " " +
      asociado?.asoOtroNombre +
      " " +
      asociado?.asoPrimerApellido +
      " " +
      asociado?.asoSegundoApellido +
      " " +
      asociado?.asoApellidoCadasa;

    let nombre1 = nombre.split("null").join("");
    let nombre2 = nombre1.split("undefined").join("");
    let nombreF = nombre2.split("  ").join(" ");
    return nombreF;
  }

  let cinetaLet = {
    appBanco: {
      banId: 0,
      banNombre: "",
    },
    ctaBanAsoId: ahorro?.asoId || asociado?.asoId,
    ctaBanBanId: 0,
    ctaBanDetalle: "",
    ctaBanEstado: 0,
    ctaBanId: 0,
    ctaBanNombreCuenta: ahorro?.ahoNombre || nombre(),

    ctaBanNumeroCuenta: "",
    ctaBanTipCtaBanId: 0,
  };
  const [cuentaBan, setCuentaBan] = useState(cinetaLet);
  const [cuentasCli, setCuentasCli] = useState([]);
  const [nuevaCuenta, setNuevaCuenta] = useState(false);
  const [estado, setEstado] = useState(false);

  useEffect(() => {
    const cliCuentaBancoSerive = new CliCuentaBancoService();
    cliCuentaBancoSerive
      .findCliId(ahorro?.asoId || asociado?.asoId)
      .then((res) => {
        setCuentasCli(res);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  function acciones(rowData) {
    if (slect === "S") {
      return (
        <>
          <Button
            className="p-button-success p-button-rounded p-mr-2"
            icon="pi pi-plus"
            onClick={() => seleccionar(rowData)}
          />
        </>
      );
    }
  }

  function seleccionar(rowData) {
    const _movBan = { ...movBan };
    _movBan["cuenta"] =
      rowData?.ctaBanNumeroCuenta + "/" + rowData?.appBanco.banNombre;
    _movBan["movDIaBanCtaBanCli"] = rowData?.ctaBanId;
    setMovBan(_movBan);
    setCuentaCli(false);
  }

  function renderTalbe() {
    return (
      <>
        <DataTable
          value={cuentasCli}
          emptyMessage="No se encontro ninguna cuenta del Asociado"
        >
          <Column field="appBanco.banNombre" header="Banco" />
          <Column field="ctaBanNombreCuenta" header="Nombre de la cuenta" />
          <Column field="ctaBanNumeroCuenta" header="Numero de la cuenta" />
          <Column field="ctaBanDetalle" header="Detalle de la cuenta" />
          <Column
            field="appTipoCuentaBancaria.tipCtaBanDescripcion"
            header="Tipo de cuenta"
          />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }

  function onSave() {
    const cliCuentaBancoSerive = new CliCuentaBancoService();
    cliCuentaBancoSerive.save(cuentaBan).then(() => {
      setNuevaCuenta(false);
      setEstado(!estado);
      toast.current.show({
        severity: "success",
        summary: "Tare realizada con exito",
        detail: "Cuenta agregada",
        life: 3000,
      });
    });
  }

  function botones() {
    return (
      <>
        <Button
          className="p-button p-button-danger "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => setNuevaCuenta(false)}
        />
        <Button icon="pi pi-check" label="Guardar" onClick={() => onSave()} />
      </>
    );
  }

  function right() {
    return (
      <Button
        onClick={() => {setNuevaCuenta(true); setCuentaBan(cinetaLet)}}
        className="p-button-success"
        label="Nuevo"
        icon="pi pi-plus"
      />
    );
  }

  return (
    <>
      {right()}
      <div className="card p-mt-3">{renderTalbe()}</div>
      <Dialog
        header="Nueva cuenta bancaria"
        visible={nuevaCuenta}
        onHide={() => setNuevaCuenta(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "45vw" }}
        footer={() => botones()}
      >
        <CliAsociadoNuevaCuentaBan
          cuentaBan={cuentaBan}
          setCuentaBan={setCuentaBan}
          setNuevaCuenta={setNuevaCuenta}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
