import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import AppActividadEconomicaService from '../../service/AppServices/AppActividadEconomicaService'
import { Pagination } from '../../components/Pagination'

export const AppActividadEconomica = () => {
  let emptyActividad = {
    actEcoId: 0,
    actEcoDescripcion: '',
  }

  const [actividades, setActividades] = useState(null)
  const [actividadDialog, setActividadDialog] = useState(false)
  const [deleteActividadDialog, setDeleteActividadDialog] = useState(false)
  const [actividad, setActividad] = useState(emptyActividad)
  const [selectedActividades, setSelectedActividades] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const toast = useRef(null)
  const dt = useRef(null)

  //Creamos el state para la busqueda
  const [actividadFind, setActividadFind] = useState('')

  //States para la paginacion
  //const [sizeForPage, setSizeForPage] = useState(2)
  //const [totalElements, setTotalElements] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [firstPage, setFirstPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    const appActividadEconomicaService = new AppActividadEconomicaService()
    appActividadEconomicaService.findByName(actividadFind,pageNumber).then((data) => {
      setActividades(data.data.content)
      setTotalPages(data.data?.totalPages);

      if(actividadFind !== ""){
        setPageNumber(0);
      }else{
        setPageNumber(data.data?.number);
      }

      setFirstPage(data.data?.first);
      setLastPage(data.data?.last);
    })
  }, [ actividadFind,pageNumber])

  // useEffect(() => {
  //   const appActividadEconomicaService = new AppActividadEconomicaService();
  //   appActividadEconomicaService.findByName(actividadFind).then((response) => {
  //     console.log(response);
  //     setActividades(response.data.content);
  //   });
  // }, [actividadFind]);

  const openNew = () => {
    setActividad(emptyActividad)
    setSubmitted(false)
    setActividadDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setActividadDialog(false)
  }

  const hideDeleteSexoDialog = () => {
    setDeleteActividadDialog(false)
  }

  //Guardamos un registro
  const saveActividadEconomica = () => {
    setSubmitted(true)

    const appActividadEconomicaService = new AppActividadEconomicaService()
    if (actividad.paiId !== null) {
      appActividadEconomicaService.save(actividad).then((data) => data)
      toast.current.show({
        severity: 'success',
        summary: '¡Exitoso!',
        detail: 'Actividad Económica Actualizado',
        life: 3000,
      })
    } else {
      appActividadEconomicaService.save(actividad).then((data) => data)
      toast.current.show({
        severity: 'success',
        summary: '¡Exitoso!',
        detail: 'Actividad Económica Creado',
        life: 5000,
      })
    }

    //Llamamos el metodo findByName para que la busqueda actual no cambie al guardar los datos
    findByName()
    //Cuerramos el dialogo de nueva actividad Economica
    setActividadDialog(false)
    setActividad(emptyActividad)
    //}
  }

  //Editamos un registro
  const editActividad = (actividad) => {
    setActividad({ ...actividad })
    setActividadDialog(true)
  }

  //Mensaje de confirmación de un registro
  const confirmDeleteActividad = (product) => {
    setActividad(product)
    setDeleteActividadDialog(true)
  }

  //Borramos un Género
  const deleteActividad = () => {
    setSubmitted(true)
    //Cramos una instancia de nuestro servicio
    const appActividadEconomicaService = new AppActividadEconomicaService()
    //Enviamos los datos al servicio correspondiente
    appActividadEconomicaService.delete(actividad).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      console.log(data)
      toast.current.show({
        severity: 'success',
        summary: '¡Éxitoso!',
        detail: 'Actividad Económica eliminado',
        life: 3000,
      })
    })
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteActividadDialog(false)
    //Metemos al state el all de los actividads del sistemas despues de aver eliminado
    setActividad(emptyActividad)
  }

  const findByName = () => {
    //Cramos una instancia de nuestro servicio
    const appActividadEconomicaService = new AppActividadEconomicaService()
    //Enviamos los datos al servicio correspondiente
    appActividadEconomicaService.findByName(actividadFind).then((response) => {
     
      setActividades(response.data.content)
    })
  }

  // const findByNameAndPage = (pageNumber) => {
  //   //Cramos una instancia de nuestro servicio
  //   const appActividadEconomicaService = new AppActividadEconomicaService()
  //   //Enviamos los datos al servicio correspondiente
  //   appActividadEconomicaService
  //     .findByName(actividadFind, pageNumber)
  //     .then((response) => {
   
  //       setActividades(response.data.content)
  //       setPageNumber(response.data.number)
  //       setFirstPage(response.data.first)
  //       setLastPage(response.data.last)
  //     })
  // }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _actividad = { ...actividad }
    _actividad[`${name}`] = val
    setActividad(_actividad)
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    )
  }

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.actEcoId}
      </>
    )
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.actEcoDescripcion}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editActividad(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteActividad(rowData)}
        />
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestionar Actividades Económicas</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setActividadFind(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={saveActividadEconomica}
      />
    </>
  )
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSexoDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteActividad} />
    </>
  )



  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }


  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={actividades}
            selection={selectedActividades}
            onSelectionChange={(e) => setSelectedActividades(e.value)}
            dataKey="paiId"
            footer={renderPaginacion}
            //paginator
            //rows={sizeForPage}
            // rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
           // totalRecords={totalElements}
            // paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            // currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} actividades económicas records"
            emptyMessage="No se encontraron actividades económicas."
            header={header}
          >
            <Column
              field="actEcoId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="actEcoDescripcion"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>
{/* 
          <div style={{textAlign: "center"}}>
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(pageNumber - 1)}
            />

            <InputText
              type="text"
              placeholder={
                'Página ' +
                (pageNumber + 1) +
                ' de ' +
                totalPages +
                ' : : elementos ' +
                totalElements
              }
              disabled
              Style="background: transparent; color: #ffff !important; margin-right: 4px; width:18%; text-align:center"
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(totalPages - 1)}
            />
          </div> */}

          <Dialog
            visible={actividadDialog}
            style={{ width: '450px' }}
            header="Datos de la Actividad Económica"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="actEcoDescripcion">Nombre</label>
              <InputText
                id="actEcoDescripcion"
                value={actividad.actEcoDescripcion}
                onChange={(e) => onInputChange(e, 'actEcoDescripcion')}
                required
                autoFocus
                className={classNames({
                  'p-invalid': submitted && !actividad.actEcoDescripcion,
                })}
              />
              {submitted && !actividad.actEcoDescripcion && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteActividadDialog}
            style={{ width: '450px' }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteSexoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: '2rem' }}
              />
              {actividad && (
                <span>
                  ¿Estás seguro de que quieres eliminar{' '}
                  <b>{actividad.actEcoDescripcion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
