import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
//Component
import { NuevoMancomunado } from "./NuevoMancomunado";

//servicios
import AhoMancomunadoService from "../../../../service/AhoCuentaService/AhoMancomunadoService";
export const Mancomunado = ({ ahorro, toast }) => {
  const message = useRef();
  let mancomunadoLet = {
    ahoManAhoId: ahorro?.ahoId,
    appTipoRelacionCuenta: {
      relId: 0,
      relNombre: "",
    },
    asoId: 0,
    asoNombre: "",
    manId: 0,
    relId: 0,
  };

  const [mancomunados, setMancomunados] = useState([]);
  const [nuevoMancomunado, setNuevoMancomunado] = useState(false);
  const [mancomunado, setMancomunado] = useState(mancomunadoLet);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const ahoManService = new AhoMancomunadoService();
    ahoManService.findByAhoId(ahorro?.ahoId).then((res) => {
      setMancomunados(res);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  const botones = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-mr-2 p-button-rounded p-button-warning"
          onClick={() => {setNuevoMancomunado(true); setMancomunado(rowData)}}
          disabled={rowData?.asoId === ahorro?.asoId}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => {setMancomunado(rowData); setVisible(true)}}
          disabled={rowData?.asoId === ahorro?.asoId}
        />
      </>
    );
  };

  function renderTable() {
    return (
      <DataTable emptyMessage="Clientes no encontrados" value={mancomunados}>
        <Column field="asoId" header="Código asociado" />
        <Column field="asoNombre" header="Nombre de la persona" />
        <Column
          field="appTipoRelacionCuenta.relNombre"
          header="Tipo de relacion"
        />
        <Column header="Acciones" body={botones} />
      </DataTable>
    );
  }

  function footer() {
    return (
      <>
        <Messages ref={message} />
        <Button
          icon="pi pi-times"
          className="p-button-text p-button-danger"
          label="Cancelar"
          onClick={() => setNuevoMancomunado(false)}
        />
        <Button
          icon="pi pi-check"
          label="Guardar"
          className="p-button-success"
          onClick={() => save()}
          disabled={ahorro?.asoId === mancomunado?.asoId }
        />
      </>
    );
  }

  function save() {
    if (
      mancomunado.asoId &&
      mancomunado.relId !== 0 &&
      mancomunado.asoId &&
      mancomunado.relId !== ""
    ) {
      const ahoManService = new AhoMancomunadoService();
      ahoManService.save(mancomunado).then(() => {
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Persona agregada",
          life: 4000,
        });
        setEstado(!estado);
        setNuevoMancomunado(false);
      });
    } else {
      setSubmitted(true);
      setInterval(() => setSubmitted(false), 4000);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }

  function delate() {
    const ahoManService = new AhoMancomunadoService();
    ahoManService.delete(mancomunado?.manId).then(() => {
      setEstado(!estado);
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Persona eliminada",
        life: 4000,
      });
    });
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => setVisible(false)}
      />
      <Button icon="pi pi-check" label="Si" onClick={delate} />
    </>
  );

  return (
    <>
      <div className="p-grid p-mt-2">
        <div className="p-col">
          <h4>Clientes mancomunados</h4>
        </div>
        <div
          className="p-col"
          style={{ textAlign: "right", alignItems: "right" }}
        >
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-warning"
            onClick={() => 
              {setNuevoMancomunado(true); setMancomunado(mancomunadoLet)}
            }
          />
        </div>
      </div>
      {renderTable()}

      <Dialog
        header="Nuevo mancomunado"
        visible={nuevoMancomunado}
        onHide={() => setNuevoMancomunado(false)}
        footer={footer}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >
        <NuevoMancomunado
          mancomunado={mancomunado}
          setMancomunado={setMancomunado}
          toast={toast}
          submitted={submitted}
        />
      </Dialog>

      <Dialog
        header="Mensaje del sistema"
        visible={visible}
        onHide={() => setVisible(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar este asociado.</span>
        </div>
      </Dialog>
    </>
  );
};
