import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
//import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Link } from "react-router-dom";
//Service
import AhoCuentaService from "../../../../../service/AhoCuentaService/AhoCuentaService";
import { Pagination } from "../../../../../components/Pagination";

export const SeleccionarCuentaAhorro = ({
  codigo,
  newAhorro,
  setNewAhorro,
  setNewCta,
}) => {

  let ahoCuentaFilter = {
    ahoEst: 1,
    ahoId: "",
    ahoNom: "",
    asoId: codigo,
  };

  const [ahoCuentaList, setAhoCuentaList] = useState([]);
  const [ahoCuentaListFilter] =
    useState(ahoCuentaFilter);

  //States para la paginacion
 // const [sizeForPage, setSizeForPage] = useState(2);
  //const [ setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [cuenta, setCuenta] = useState();

  //useEffect
  useEffect(() => {
    const ahoCuentaService = new AhoCuentaService();
    ahoCuentaService.findByPage(pageNumber, ahoCuentaListFilter).then((res) => {
      setAhoCuentaList(res?.content);
  
      setTotalPages(res?.totalPages);
      setPageNumber(res?.number);
      setFirstPage(res?.first);
      setLastPage(res?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, ahoCuentaListFilter.ahoEst]);

  // const fil = (evt) => {
  //   var e = evt;
  //   var charCode = e.which || e.keyCode;
  //   if (charCode == 9) {
  //     const ahoCuentaService = new AhoCuentaService();
  //     ahoCuentaService
  //       .findByPage(pageNumber, ahoCuentaListFilter)
  //       .then((res) => {
  //         setAhoCuentaList(res.content);
  //         setTotalElements(res.totalElements);
  //         setTotalPages(res.totalPages);
  //         setPageNumber(res.number);
  //         setFirstPage(res.first);
  //         setLastPage(res.last);
  //         if (
  //           ahoCuentaListFilter.ahoId !== "" ||
  //           ahoCuentaListFilter.ahoNom !== "" ||
  //           ahoCuentaListFilter.asoId !== "" ||
  //           ahoCuentaListFilter.ahoEst !== 0
  //         ) {
  //           setPageNumber(0);
  //         }
  //       });
  //   }
  // };

  //render filter cuentas ahorro

  // const renderPaginacion = () => {
  //   return (
  //     <div style={{ textAlign: "center" }}>
  //       <br />
  //       <Button
  //         type="button"
  //         label=""
  //         icon="pi pi-angle-double-left"
  //         className="p-button-outlined p-button-secondary "
  //         style={{
  //           background: "transparent; color: #6E707A",
  //           marginRight: "4px",
  //           border: "none",
  //         }}
  //         disabled={totalPages === 0 || firstPage}
  //         onClick={() => setPageNumber(0)}
  //       />
  //       <Button
  //         type="button"
  //         label=""
  //         icon="pi pi-angle-left"
  //         className="p-button-outlined p-button-secondary "
  //         style={{
  //           background: "transparent; color: #6E707A",
  //           marginRight: "4px",
  //           border: "none",
  //         }}
  //         disabled={totalPages == "0" || firstPage}
  //         onClick={() => setPageNumber(pageNumber - 1)}
  //       />

  //       <InputText
  //         type="text"
  //         className="p-inputtext p-component"
  //         placeholder={
  //           totalPages > 0
  //             ? pageNumber + 1 + " de " + totalPages
  //             : pageNumber + " de " + totalPages
  //         }
  //         style={{
  //           width: "100px",
  //           height: "30px",
  //           borderColor: "#5499C7",
  //           textAlign: "center",

  //           fontSize: "15px",
  //           background: "transparent",
  //         }}
  //         readOnly
  //       />

  //       <Button
  //         type="button"
  //         label=""
  //         className="p-button-outlined p-button-secondary "
  //         icon="pi pi-angle-right"
  //         style={{
  //           background: "transparent; color: #5499C7",
  //           marginRight: "4px",
  //           border: "none",
  //         }}
  //         disabled={totalPages == "0" || lastPage}
  //         onClick={() => setPageNumber(pageNumber + 1)}
  //       />

  //       <Button
  //         type="button"
  //         label=""
  //         className="p-button-outlined p-button-secondary "
  //         icon="pi pi-angle-double-right"
  //         style={{
  //           background: "transparent; color: #6E707A",
  //           marginRight: "4px",
  //           border: "none",
  //         }}
  //         disabled={totalPages == "0" || lastPage}
  //         onClick={() => setPageNumber(totalPages - 1)}
  //       />
  //     </div>
  //   );
  // };

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  const estadoCuenta = (rowData) => {
    return (
      <div>
        <span>
          {rowData.ahoEstado === 1
            ? "VIGENTE"
            : rowData.ahoEstado === 2
            ? "CANCELADO"
            : rowData.ahoEstado === 3
            ? "BLOQUEADO"
            : rowData.ahoEstado === 4
            ? "VENCIDO"
            : "NO ENCONTRADO"}
        </span>
      </div>
    );
  };

  const certificadoLibreta = (rowData) => {
    return (
      <div>
        <span>
          {rowData.ahoNumeroLibreta !== 0
            ? rowData.ahoNumeroLibreta
            : rowData.ahoNumeroCertificado}
        </span>
      </div>
    );
  };

  const accionesAhorro = (rowData) => {
    return (
      <div>
        <Link
          to={{
            pathname: "/ahonuevo",
          }}
        >
          <Button
            //   label="Imprimir"
            className="p-button-default p-button-rounded"
            icon="pi pi-plus"
            tooltip="Seleccionar"
            onClick={() =>
              //sessionStorage.setItem("Ahorro", JSON.stringify(rowData))
              {
                setConfirm(true);
                setCuenta(rowData.ahoId);
              }
            }
          />
        </Link>
      </div>
    );
  };
  const formatDate = (value) => {
    const event = new Date(value);

    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "/" + mm + "/" + yy;

    return myDateString;
  };

  const date = (rowData) => {
    return formatDate(rowData.ahoFechaApertura);
  };

  const renderListCuentasAhorro = () => {
    return (
      <>
        <DataTable
          value={ahoCuentaList}
          style={{ backgroundColor: "#f5f5f5" }}
          responsive={true}
          lazy={true}
          footer={renderPaginacion()}
        >
          <Column field="ahoId" header="No. Cuenta" />
          <Column field="ahoNombre" header="Nombre" />
          <Column
            field="appAhoSubProducto.subAhoNombre"
            header="Sub Producto"
          />
          <Column
            body={certificadoLibreta}
            header="Certificado / Libreta"
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
          <Column
            field="ahoFechaApertura"
            body={date}
            header="Fecha de Apertura"
          />
          <Column
            field="ahoEstado"
            body={estadoCuenta}
            header="Estado"
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
          <Column
            header="Acciones"
            body={accionesAhorro}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
        </DataTable>
      </>
    );
  };

  const confirmFun = () => {
    setConfirm(false);

    let _newAhorro = { ...newAhorro };
    _newAhorro[`ahoIdAcreditar`] = cuenta;
    setNewAhorro(_newAhorro);
    console.log(_newAhorro);

    setNewCta(false);
  };

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="Cancelar"
        onClick={() => {
          setConfirm(false);
          setCuenta("");
        }}
      />
      <Button icon="pi pi-check" label="Confirmar" onClick={confirmFun} />
    </>
  );
  return (
    <div>
      <div className="card">{renderListCuentasAhorro()}</div>

      <Dialog
        header={<h5>Confirmar</h5>}
        visible={confirm}
        onHide={() => setConfirm(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea desa agregar esta cuenta.</span>
        </div>
      </Dialog>
    </div>
  );
};
