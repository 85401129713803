import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import AppProfesionOficioService from '../../../../service/AppServices/AppProfesionOficioService'
import { Pagination } from '../../../../components/Pagination'

export const ListarProfesionOficio = () => {
  let emptyAppProfesionOficio = {
    ofiId: 0,
    ofiDescripcion: '',
  }

  const [appProfesionOficios, setAppProfesionOficios] = useState(null)
  const [profesionOficioDialog, setProfesionOficioDialog] = useState(false)
  const [
    deleteProfesionOficioDialog,
    setDeleteProfesionOficioDialog,
  ] = useState(false)
  const [appProfesionOficio, setAppProfesionOficio] = useState(
    emptyAppProfesionOficio,
  )
  const [selectedAppProfesionOficio, setSelectedAppProfesionOficio] = useState(
    null,
  )
  const [submitted, setSubmitted] = useState(false)
  const toast = useRef(null)
  const dt = useRef(null)

  //Creamos el state para la busqueda
  const [profesionOficioFind, setProfesionOficioFind] = useState('')

  //States para la paginacion
  // const [sizeForPage, setSizeForPage] = useState(2)
  // const [totalElements, setTotalElements] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [firstPage, setFirstPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)

  //Instanciamos el servicio
  const appProfesionOficioService = new AppProfesionOficioService()

  useEffect(() => {
    appProfesionOficioService.findByName(profesionOficioFind,pageNumber).then((data) => {

      setAppProfesionOficios(data.data.content)
      setTotalPages(data.data.totalPages);
      setFirstPage(data.data.first);
      setLastPage(data.data.last);
      if (profesionOficioFind !== "") {
        setPageNumber(0);
      }else{
        setPageNumber(data.data?.number)
      }

    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profesionOficioFind,pageNumber])

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  const openNew = () => {
    setAppProfesionOficio(emptyAppProfesionOficio)
    setSubmitted(false)
    setProfesionOficioDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setProfesionOficioDialog(false)
  }

  const hideDeleteSexoDialog = () => {
    setDeleteProfesionOficioDialog(false)
  }

  //Guardamos un registro
  const saveProfesionOficio = () => {
    setSubmitted(true)

    if (appProfesionOficio.ofiId !== null) {
      appProfesionOficioService.save(appProfesionOficio).then((data) => data)
      toast.current.show({
        severity: 'success',
        summary: '¡Exitoso!',
        detail: 'Profesion u Oficio Actualizado',
        life: 3000,
      })
    } else {
      appProfesionOficioService.save(appProfesionOficio).then((data) => data)
      toast.current.show({
        severity: 'success',
        summary: '¡Exitoso!',
        detail: 'Profesion u Oficio Creado',
        life: 5000,
      })
    }

    //Llamamos el metodo findByName para que la busqueda actual no cambie al guardar los datos
    findByName()
    //Cuerramos el dialogo de nueva appProfesionOficio Economica
    setProfesionOficioDialog(false)
    setAppProfesionOficio(emptyAppProfesionOficio)
    //}
  }

  //Editamos un registro
  const editProfesionOficio = (appProfesionOficio) => {
    setAppProfesionOficio({ ...appProfesionOficio })
    setProfesionOficioDialog(true)
  }

  //Mensaje de confirmación de un registro
  const confirmDeleteProfesionOficio = (product) => {
    setAppProfesionOficio(product)
    setDeleteProfesionOficioDialog(true)
  }

  //Borramos un Género
  const deleteProfesionOficio = () => {
    setSubmitted(true)

    //Enviamos los datos al servicio correspondiente
    appProfesionOficioService.delete(appProfesionOficio.ofiId).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion

      toast.current.show({
        severity: 'success',
        summary: '¡Éxitoso!',
        detail: 'Profesion u Oficio eliminado',
        life: 3000,
      })
    })
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteProfesionOficioDialog(false)
    //Metemos al state el all de los actividads del sistemas despues de aver eliminado
    setAppProfesionOficio(emptyAppProfesionOficio)
  }

  const findByName = () => {
    //Enviamos los datos al servicio correspondiente
    appProfesionOficioService
      .findByName(profesionOficioFind)
      .then((response) => {
   
        setAppProfesionOficios(response.data.content)
      })
  }

  // const findByNameAndPage = (pageNumber) => {
  //   //Cramos una instancia de nuestro servicio
  //   //const appProfesionOficioService = new appProfesionOficioService()
  //   //Enviamos los datos al servicio correspondiente
  //   appProfesionOficioService
  //     .findByName(profesionOficioFind, pageNumber)
  //     .then((response) => {
  //       console.log(response)
  //       setAppProfesionOficios(response.data.content)
  //       setPageNumber(response.data.number)
  //       setFirstPage(response.data.first)
  //       setLastPage(response.data.last)
  //     })
  // }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _actividad = { ...appProfesionOficio }
    _actividad[`${name}`] = val
    setAppProfesionOficio(_actividad)
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    )
  }

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.ofiId}
      </>
    )
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.ofiDescripcion}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editProfesionOficio(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteProfesionOficio(rowData)}
        />
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestionar Profesion y Oficios</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setProfesionOficioFind(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={saveProfesionOficio}
      />
    </>
  )
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSexoDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteProfesionOficio} />
    </>
  )

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={appProfesionOficios}
            selection={selectedAppProfesionOficio}
            onSelectionChange={(e) => setSelectedAppProfesionOficio(e.value)}
            dataKey="paiId"
          //  rows={sizeForPage}
            className="datatable-responsive"
//totalRecords={totalElements}
            emptyMessage="No se encontraron Profesion y Oficios."
            header={header}
            footer={renderPaginacion}
          >
            <Column
              field="ofiId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="ofiDescripcion"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>
{/* 
          <div Style="text-align: center">
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(pageNumber - 1)}
            />

            <InputText
              type="text"
              placeholder={
                'Página ' +
                (pageNumber + 1) +
                ' de ' +
                totalPages +
                ' : : elementos ' +
                totalElements
              }
              disabled
              Style="background: transparent; color: #ffff !important; margin-right: 4px; width:18%; text-align:center"
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(totalPages - 1)}
            />
          </div> */}

          <Dialog
            visible={profesionOficioDialog}
            style={{ width: '450px' }}
            header="Datos de la appProfesionOficio Económica"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="ofiDescripcion">Nombre</label>
              <InputText
                id="ofiDescripcion"
                value={appProfesionOficio.ofiDescripcion}
                onChange={(e) => onInputChange(e, 'ofiDescripcion')}
                required
                autoFocus
                className={classNames({
                  'p-invalid': submitted && !appProfesionOficio.ofiDescripcion,
                })}
              />
              {submitted && !appProfesionOficio.ofiDescripcion && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteProfesionOficioDialog}
            style={{ width: '450px' }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteSexoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: '2rem' }}
              />
              {appProfesionOficio && (
                <span>
                  ¿Estás seguro de que quieres eliminar{' '}
                  <b>{appProfesionOficio.ofiDescripcion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  )
}
