import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";

//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
import AppPreEstadoService from "../../../../service/AppServices/AppPreEstadoService";
export const NuevoEstado = ({ preEstado, setPreEstado ,summited}) => {

    const [estados,setEstados] = useState([])


    useEffect(()=>{
        const estadoService = new AppPreEstadoService()
        estadoService.findStatuses().then((res)=>{
            setEstados(res)
        })
    },[])

  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Descripcion</label>
          <InputText
            id="descripcion"
            value={preEstado.preEstDescripcion}
            onChange={(e) =>
              onChajeObjN(e, "preEstDescripcion", preEstado, setPreEstado)
            }
            placeholder="Descripcion"
            onClick={(e) => selectText(e)}
            className={{    "p-invalid": summited && !preEstado.preEstDescripcion,}}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Cambio de estado</label>
          <Dropdown
            options={[
              { label: "AUTOMATICO", value: 1 },
              { label: "MANUAL", value: 2 },
            ]}
            value={preEstado.preEstCambioEstado}
            onChange={(e) =>
              setPreEstado({ ...preEstado, preEstCambioEstado: e.target.value })
            }
            placeholder="Seleccione..."
            className={{    "p-invalid": summited && !preEstado.preEstCambioEstado,}}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Cantidad de cuotas</label>
          <InputText
            id="cantcuotas"
            type={"number"}
            value={preEstado.preEstCuota}
            onChange={(e) =>
              onChajeObjN(e, "preEstCuota", preEstado, setPreEstado)
            }
            placeholder="Cantidad de cuotas"
            onClick={(e) => selectText(e)}
            className={{    "p-invalid": summited && preEstado.preEstCuota === undefined,}}
          />
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <label>Estados</label>
          <Dropdown
            options={estados}
            value={preEstado.preEstIdentificador}
            onChange={(e) =>
              setPreEstado({ ...preEstado, preEstIdentificador: e.target.value })
            }
            placeholder="Seleccione..."
            optionLabel="name"
            className={{    "p-invalid": summited && !preEstado.preEstIdentificador,}}
          />
        </div>
      </div>
    </div>
  );
};
