import React, { useState, useEffect, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
//componentes
import { Pagination } from "../../../../components/Pagination"; 

//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";

//Servicios
import AppPreGarantiaService from "../../../../service/AppServices/AppPreGarantiaService";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { NuevaGarantia } from "./NuevaGarantia";

export const Garantia = () => {
  
  const toast = useRef();
  const message = useRef();
  let filtroEmpty = {
    descripcion: "",
  };

  let garantiaEmpty = {
    preGarDescripcion: "",
    preGarId: 0,
    preGarTipo: 0,
  };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [garantia, setGarantia] = useState(garantiaEmpty);
  const [garnatias, setGarantias] = useState([]);
  const [nuevaGarantia, setNuevaGarantia] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  useEffect(() => {
    const appPreGarantiaService = new AppPreGarantiaService();

    appPreGarantiaService
      .findByPage(pageNumber, filtro.descripcion)
      .then((result) => {
        setGarantias(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado,pageNumber]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const appPreGarantiaService = new AppPreGarantiaService();

      appPreGarantiaService
        .findByPage(pageNumber, filtro.descripcion)
        .then((result) => {
          setGarantias(result?.content);
          setTotalPages(result?.totalPages);
          setPageNumber(result?.number);
          setFirstPage(result?.first);
          setLastPage(result?.last);
          if (filtro.descripcion !== "") {
            setPageNumber(0);
          }
        });
    }
  };

  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {setNuevaGarantia(true); setGarantia(garantiaEmpty)}}
        />
      </>
    );
  }
  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            type={"search"}
            className="p-inputtext-sm"
            value={filtro.descripcion}
            onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
          />
          <label>Garantia</label>
        </span>
      </div>
    );
  }

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning  mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setGarantia(rowData); setNuevaGarantia(true)}}
        />
        <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setGarantia(rowData); setEliminar(true)}}
        />
      </>
    );
  }
  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-button-text p-mr-2 "
          onClick={() => setNuevaGarantia(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save() {
    if (
      garantia.preGarDescripcion &&
      garantia.preGarTipo !== "" &&
      garantia.preGarDescripcion &&
      garantia.preGarTipo !== 0
    ) {
      const appPreGarantiaService = new AppPreGarantiaService();
      appPreGarantiaService.save(garantia).then(() => {
        setEstado(!estado);
        setNuevaGarantia(false);
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: `Garantia creada con exito`,
          life: 4000,
        });
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }
  function tipoGar(rowData) {
    switch (rowData.preGarTipo) {
      case 1:
        return <>FIDUCIARIO</>;
      case 2:
        return <>DERECHO DE POSESION</>;
      case 3:
        return <>HIPOTECARIO </>;
      case 4:
        return <>BACK TO BACK</>;
        default:
          return null;
    }
  }

  function borrar() {
    const appPreGarantiaService = new AppPreGarantiaService();
    appPreGarantiaService.delete(garantia.preGarId).then((res) => {
      setEstado(!estado);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Garantia eliminada con exito",
        life: 4000,
      });
    });
  }

  const elimarProducto = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={borrar} />
    </>
  );

  function renderTable() {
    return (
      <>
        <DataTable
          emptyMessage="No se encontraron garantias"
          value={garnatias}
          footer={renderPaginacion()}
        >
          <Column header="No." field="preGarId" />
          <Column header="Tipo de garantia" body={tipoGar} />
          <Column header="Descripcion" field="preGarDescripcion" />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }
  return (
    <>
      <div className="card">
        <Toolbar className="p-mb-4" left={left} right={right} />
        {renderTable()}
      </div>
      <Dialog
        visible={nuevaGarantia}
        onHide={() => setNuevaGarantia(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
        header="Nueva Garantia"
      >
        <NuevaGarantia
          garantia={garantia}
          setGarantia={setGarantia}
          submitted={submitted}
        />
        <Messages ref={message} />
      </Dialog>

      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={elimarProducto}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar esta garantia .</span>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
