import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";

// funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";

//componentes
import { NuevoCompromiso } from "./NuevoCompromiso";
import { Pagination } from "../../../../components/Pagination";

//Servicios
import AppPreTipoCompromisoService from "../../../../service/AppServices/AppPreTipoCompromisoService";
export const AppTipoCompromiso = () => {
  const toast = useRef();
  const message = useRef();
  let filtroEmpty = {
    nombre: "",
  };

  let tipoCompromisoEmpty = {
    preTipComAccion: "",
    preTipComId: 0,
    preTipoComDescripcion: "",
  };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevoCompromiso, setNuevoCompromiso] = useState(false);
  const [tipoCompromiso, setTipoCompromiso] = useState(tipoCompromisoEmpty);
  const [tipoConpromisos, setTipoCompromisos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [estado,setEstado] = useState(false);
  useEffect(() => {
    const preTipoCompormiso = new AppPreTipoCompromisoService();
    preTipoCompormiso.findByPage(pageNumber, filtro.nombre).then((result) => {
      setTipoCompromisos(result?.content);
      setTotalPages(result?.totalPages);
      setPageNumber(result?.number);
      setFirstPage(result?.first);
      setLastPage(result?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber,estado]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const preTipoCompormiso = new AppPreTipoCompromisoService();
      preTipoCompormiso.findByPage(pageNumber, filtro.nombre).then((result) => {
        setTipoCompromisos(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
        if (filtro.nombre !== "") {
          setPageNumber(0);
        }
      });
    }
  };

  function save(){
    if(tipoCompromiso.preTipComAccion && tipoCompromiso.preTipoComDescripcion !== "" ){
      const preTipoCompormiso = new AppPreTipoCompromisoService();
      preTipoCompormiso.save(tipoCompromiso).then(()=>{
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: `Nuevo compromiso creada`,
          life: 4000,
        });
        setEstado(!estado)
        setNuevoCompromiso(false)
      })

    }else{
      setSubmitted(true)
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(()=>setSubmitted(false),4000)
    }
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-mr-2 "
          onClick={() => setNuevoCompromiso(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {setNuevoCompromiso(true);setTipoCompromiso(tipoCompromisoEmpty)}}
        />
      </>
    );
  }

  function left() {
    return (
      <div  onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            className="p-inputtext-sm"
            value={filtro.nombre}
            onChange={(e) => onChajeObjN(e, "nombre", filtro, setFiltro)}
          />
          <label>Compromiso</label>
        </span>
      </div>
    );
  }

  const acciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setTipoCompromiso(rowData);setNuevoCompromiso(true)}}
        />
        {/* <Button
          className="p-button-rounded p-button-danger mr-2 p-mr-2"
          icon="pi pi-trash"
          //  onClick={() => onClick(rowData)}
        /> */}
      </>
    );
  };
  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function renderTable() {
    return (
      <>
        <DataTable
          emptyMessage="Sin compromisos"
          value={tipoConpromisos}
          footer={renderPaginacion()}
        >
          <Column header="No." field="preTipComId" />
          <Column header="Descripcion" field="preTipoComDescripcion" />
          <Column header="Accion" field="preTipComAccion" />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }

  return (
    <>
      <div className="card">
        <Toolbar left={left()} right={right()} className="p-mb-4" />
        {renderTable()}

      </div>

      <Dialog
        visible={nuevoCompromiso}
        header="Nuevo Compromiso"
        onHide={() => setNuevoCompromiso(false)}
        footer={footer}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >
        <NuevoCompromiso tipoCompromiso={tipoCompromiso} setTipoCompromiso={setTipoCompromiso} submitted={submitted}/>
        <Messages ref={message} />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
