import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import { addLocale } from "primereact/api";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import classNames from "classnames";

//Service
import CliAsociadoService from "../../../service/CliServices/CliAsociadoService";
import CliDireccionService from "../../../service/CliServices/CliDireccionService";
import { CliAsociadoSeleccionar } from "../../ClientesPages/CliAsociadoSeleccionar";
import { InputTextarea } from "primereact/inputtextarea";

//funciones
import selectText from "../../../utilities/selecText";
import PlazoService from "../../../service/TcrService/PlazoService";
export const NuevaTarjeta = ({
  submitted,
  tcrTarjeta,
  setTcrTarjeta,
  appSexos,
  toast,
}) => {
  const tipoContratoOptions = [
    { label: "Apertura de crédito", value: 1 },
    { label: "Ampliación", value: 2 },
    { label: "Extra Financiamiento", value: 3 },
  ];

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Limpiar",
  });

  const [cliDirecciones, setCliDirecciones] = useState([]);
  const [buscar, setBuscar] = useState(false);
  const [plazos, setPlazos] = useState([]);

  useEffect(() => {
    const plazoService = new PlazoService();
    plazoService.getAll().then((res) => {
      var plazosEnviar = [];
      res?.forEach((element) => {
        var plazo = {
          value: element?.plaMeses,
          label: element?.plaMeses + " MESES",
        };
        plazosEnviar.push(plazo);
      });
      setPlazos(plazosEnviar);
    });
  }, []);

  const cliAsociadoService = new CliAsociadoService();
  const cliDireccionService = new CliDireccionService();

  useEffect(() => {
    const { cliAsociado } = tcrTarjeta;
    if (tcrTarjeta?.asoId !== 0) {
      if (
        cliAsociado?.asoNombreJuridico === null ||
        cliAsociado?.asoPrimerNombre !== ""
      ) {
        const partesNombre = [
          cliAsociado?.asoPrimerNombre,
          cliAsociado?.asoSegundoNombre,
          cliAsociado?.asoOtroNombre,
          cliAsociado?.asoPrimerApellido,
          cliAsociado?.asoSegundoApellido,
          cliAsociado?.asoApellidoCasada,
        ].filter(Boolean);

        const nombre = partesNombre.join(" ");
        setTcrTarjeta({ ...tcrTarjeta, nombre: nombre || "" });
      } else {
        setTcrTarjeta({
          ...tcrTarjeta,
          nombre: cliAsociado?.asoNombreJuridico,
        });
      }
      cliDireccionService.findAllByAsoId(tcrTarjeta?.asoId).then((response) => {
        setCliDirecciones(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tcrTarjeta?.asoId !== 0) {
      cliDireccionService
        .findAllByAsoId(tcrTarjeta?.asoId || 0)
        .then((response) => {
          setCliDirecciones(response);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tcrTarjeta?.nombre]);

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscar(false)}
        />
      </>
    );
  }

  const findCli = () => {
    if (tcrTarjeta?.asoId !== 0) {
      dataCli();
    }
  };

  const dataCli = () => {
    cliAsociadoService.findAdd(0, tcrTarjeta?.asoId).then((res) => {
      if (!res || res?.response?.status === 404) {
        toast.current.show({
          severity: "warn",
          summary: "error",
          content: "Asociado no encontrado",
          life: 4000,
        });
        setTcrTarjeta({ ...tcrTarjeta, asoId: 0, nombre: "" });
      } else {
        const nombre = res?.data?.content[0]?.nombre;
        setTcrTarjeta({ ...tcrTarjeta, nombre: nombre });
      }
    });
  };

  return (
    <div>
      <div className="card">
        <div className="p-grid">
          <div className="p-fluid p-col-3">
            <label htmlFor="asoId">Código Cliente</label>
            <div className="p-inputgroup">
              <InputText
                id="asoId"
                type={"number"}
                value={tcrTarjeta?.asoId || ""}
                onChangeCapture={(e) => {
                  setTcrTarjeta({ ...tcrTarjeta, asoId: e.target.value });
                }}
                onClick={(e) => selectText(e)}
                onBlur={(e) => findCli(e)}
                className={classNames({
                  "p-invalid": submitted && !tcrTarjeta?.asoId,
                })}
                placeholder="Código"
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setBuscar(true)}
              />
            </div>
            {submitted && !tcrTarjeta?.asoId && (
              <small className="p-invalid">Asociado requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-5">
            <label htmlFor="tcrTarjetaNombre">Nombre</label>
            <InputText
              id="tcrTarjetaNombre"
              value={tcrTarjeta?.nombre || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  tcrTarjetaNombre: e.target.value,
                });
              }}
              disabled={true}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.asoId,
              })}
              placeholder="Nombre del Asociado"
            />
            {submitted && !tcrTarjeta?.asoId && (
              <small className="p-invalid">Asociado requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-4">
            <label htmlFor="tipoContrato"> Tipo de Contrato </label>
            <Dropdown
              placeholder="Seleccione una opcion"
              id="tipoContrato"
              value={tcrTarjeta?.tipoContrato || ""}
              options={tipoContratoOptions}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  tipoContrato: e.value,
                });
              }}
            />
          </div>
          <div className="p-fluid p-col-6">
            <label htmlFor="tcrTarjetaNumero">Número de Tarjeta</label>
            <InputMask
              placeholder="9999 9999 9999 9999"
              id="tcrTarjetaNumero"
              mask="9999 9999 9999 9999"
              value={tcrTarjeta?.numeroTarjeta || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  numeroTarjeta: e.target.value,
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.numeroTarjeta,
              })}
            />
            {submitted && !tcrTarjeta?.numeroTarjeta && (
              <small className="p-invalid">Número de tarjeta requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-6">
            <label htmlFor="fechaAceptaContrato">
              Fecha de Aceptación de Contrato
            </label>
            <InputText
              id="fechaAceptaContrato"
              type={"date"}
              minLength={10}
              value={tcrTarjeta?.fechaAceptaContrato || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  fechaAceptaContrato: e.target.value,
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.fechaAceptaContrato,
              })}
            />{" "}
            {submitted && !tcrTarjeta?.fechaAceptaContrato && (
              <small className="p-invalid">Fecha requerida</small>
            )}
          </div>

          <div className="p-fluid p-col-6">
            <label htmlFor="limiteMonedaNacional">
              Monto en Moneda Nacional
            </label>
            <InputNumber
              id="limite"
              name="lim"
              onClick={(e) => selectText(e)}
              value={tcrTarjeta?.limiteMonedaNacional || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  limiteMonedaNacional: e.value,
                  limiteMonedaExtrajera: (e.value / 8).toFixed(2),
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.limiteMonedaNacional,
              })}
            />
            {submitted && !tcrTarjeta?.limiteMonedaNacional && (
              <small className="p-invalid">Monto requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-6">
            <label htmlFor="limiteMonedaExtrajera">
              Monto en Moneda Extranjera
            </label>
            <InputNumber
              id="limiteMonedaExtrajera"
              value={tcrTarjeta?.limiteMonedaExtrajera || 0}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  limiteMonedaExtrajera: e.value,
                });
              }}
              disabled={true}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.limiteMonedaNacional,
              })}
            />
            {submitted && !tcrTarjeta?.limiteMonedaNacional && (
              <small className="p-invalid">Monto requerido</small>
            )}
          </div>

          <div
            className="p-fluid p-col-6"
            style={{ display: tcrTarjeta?.tipoContrato !== 1 ? "" : "none" }}
          >
            <label htmlFor="fechaPrimeraApertura">
              {" "}
              Fecha de Primera Apertura{" "}
            </label>
            <InputText
              id="fechaPrimeraApertura"
              type={"date"}
              value={tcrTarjeta?.fechaPrimeraApertura || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  fechaPrimeraApertura: e.target.value,
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.fechaPrimeraApertura,
              })}
            />
            {submitted && !tcrTarjeta?.fechaPrimeraApertura && (
              <small className="p-invalid">Fecha requerida</small>
            )}
          </div>

          <div className="p-fluid p-col-6">
            <label htmlFor="fechaVenceTarjeta">
              Fecha de Vencimiento Tarjeta
            </label>
            <InputText
              id="fechaVenceTarjeta"
              type={"month"}
              value={tcrTarjeta?.fechaVenceTarjeta.substring(0, 7) || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  fechaVenceTarjeta: e.target.value + "-01",
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.fechaVenceTarjeta,
              })}
            />
            {submitted && !tcrTarjeta?.fechaVenceTarjeta && (
              <small className="p-invalid">Fecha requerida</small>
            )}
          </div>
          <div
            className="p-fluid p-col-6"
            style={{ display: tcrTarjeta?.tipoContrato === 3 ? "" : "none" }}
          >
            <label htmlFor="dirId">Plazo de extra financiamiento</label>
            <Dropdown
              placeholder="Seleccione una opcion"
              id="dirId"
              value={tcrTarjeta?.plazo || ""}
              options={plazos}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  plazo: e.value,
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.plazo,
              })}
            />
            {submitted && !tcrTarjeta?.plazo && (
              <small className="p-invalid">Plazo requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-6" style={{ textAlign: "left" }}>
            <label htmlFor="firma"> ¿Sabe Firmar? </label>{" "}
            <Checkbox
              className="p-mt-4 "
              id="firma"
              checked={tcrTarjeta?.firma === 0 ? false : true}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  firma: e.checked === true ? 1 : 0,
                });
              }}
            />
          </div>

          <div className="p-fluid p-col-12">
            <label htmlFor="garantia"> Garantía </label>
            <InputTextarea
              id="garantia"
              onClick={(e) => selectText(e)}
              value={tcrTarjeta?.garantia || ""}
              onChange={(e) => {
                setTcrTarjeta({ ...tcrTarjeta, garantia: e.target.value });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.garantia,
              })}
              placeholder="Describa la garantía del Asociado"
            />
            {submitted && !tcrTarjeta?.garantia && (
              <small className="p-invalid">Garantía requerida</small>
            )}
          </div>

          <div className="p-fluid p-col-12">
            <label htmlFor="dirId"> Dirección </label>
            <Dropdown
              placeholder="Seleccione una dirección"
              id="dirId"
              value={tcrTarjeta?.dirId || ""}
              options={cliDirecciones}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  dirId: e.value,
                });
              }}
              optionValue="dirId"
              optionLabel="dirDireccion"
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.dirId,
              })}
            />
            {submitted && !tcrTarjeta?.dirId && (
              <small className="p-invalid">Direccion requerida</small>
            )}
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{ display: tcrTarjeta?.firma === 1 ? "none" : "" }}
      >
        <div className="card-title">
          <h3>Datos de Testigo</h3>
        </div>
        <div className="p-grid">
          <div className="p-fluid p-col-12">
            <label htmlFor="nombreTestigo"> Nombre Testigo </label>
            <InputText
              placeholder="Nombre del testigo"
              id="nombreTestigo"
              value={tcrTarjeta?.nombreTestigo || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  nombreTestigo: e.target.value.toUpperCase(),
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.nombreTestigo,
              })}
            />
            {submitted && !tcrTarjeta?.nombreTestigo && (
              <small className="p-invalid">Nombre del testigo requerido</small>
            )}
          </div>
          <div className="p-fluid p-col-6">
            <label htmlFor="numIdentificacionTestigo"> Identificación </label>
            <InputMask
              placeholder="9999 99999 9999"
              id="numIdentificacionTestigo"
              value={tcrTarjeta?.numIdentificacionTestigo || ""}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  numIdentificacionTestigo: e.target.value,
                });
              }}
              mask="9999 99999 9999"
              maskChar={null}
              onBlur={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  numIdentificacionTestigo: e.target.value,
                });
              }}
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.numIdentificacionTestigo,
              })}
            />
            {submitted && !tcrTarjeta?.numIdentificacionTestigo && (
              <small className="p-invalid">
                Número de identificación del testigo requerido
              </small>
            )}
          </div>
          <div className="p-fluid p-col-6">
            <label htmlFor="sexIdTestigo"> Sexo Testigo </label>
            <Dropdown
              id="sexIdTestigo"
              value={tcrTarjeta?.sexIdTestigo || ""}
              options={appSexos}
              onChange={(e) => {
                setTcrTarjeta({
                  ...tcrTarjeta,
                  sexIdTestigo: e.value,
                });
              }}
              optionLabel="sexDescripcion"
              optionValue="sexId"
              className={classNames({
                "p-invalid": submitted && !tcrTarjeta?.sexIdTestigo,
              })}
              placeholder="Seleccione un genero"
            />
            {submitted && !tcrTarjeta?.sexIdTestigo && (
              <small className="p-invalid">Sexo requerido</small>
            )}
          </div>
        </div>
      </div>
      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={tcrTarjeta}
          setPersona={setTcrTarjeta}
          parametro1={"asoId"}
          parametro2={"nombre"}
          cantPar={2}
        />
      </Dialog>
    </div>
  );
};