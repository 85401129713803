import React, { useState, useRef, useEffect, useCallback } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import AhoFirmanteService from "../../../../service/AhoCuentaService/AhoFirmanteService";
import { Dialog } from "primereact/dialog";
import { NuevaFirma } from "./NuevaFirma";
import { Messages } from "primereact/messages";
import printJS from "print-js";
import { PrintFirma } from "./PrintFirma";
export const Firma = ({ ahorro, toast }) => {
  const message = useRef();

  const impresionFirma = useCallback (() => {
    printJS({
      printable: "printable-content",
      type: "html",
      targetStyles: ["*"],
      font_size: "12px",
      
    });
  },[]);

  let firmaLet = {
    ahoId: ahorro?.ahoId,
    appTipoRelacionCuenta: {
      relId: 0,
      relNombre: "",
    },
    asoId: 0,
    asoNombre: "",
    firArchivoFirma: "",
    firId: 0,
    relId: 0,
  };

  const [firmas, setFirmas] = useState([]);
  const [nuevaFirma, setNuevaFirma] = useState(false);
  const [firma, setFirma] = useState(firmaLet);
  const [visible, setVisible] = useState(false);
  const [estado, setEstado] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [imprimir,setImprimir] = useState(false)
  useEffect(() => {
    const ahoFirmante = new AhoFirmanteService();
    ahoFirmante.findByAhoId(ahorro?.ahoId).then((res) => {
      setFirmas(res);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  const botones = (rowData) => {
    return (
      <>
        <Button
          icon="pi pi-pencil"
          className="p-mr-2 p-button-rounded p-button-warning"
          onClick={() => {setNuevaFirma(true);setFirma(rowData)}}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-danger"
          onClick={() => {setFirma(rowData); setVisible(true)}}
        />
      </>
    );
  };

  function renderTable() {
    return (
      <DataTable emptyMessage="Clientes no encontrados" value={firmas}>
        <Column field="asoId" header="Código asociado" />
        <Column field="asoNombre" header="Nombre de la persona" />
        <Column
          field="appTipoRelacionCuenta.relNombre"
          header="Tipo de relacion"
        />
        <Column header="Acciones" body={botones} />
      </DataTable>
    );
  }

  function footer() {
    return (
      <>
        <Messages ref={message} />
        <Button
          icon="pi pi-times"
          className="p-button-text p-button-danger"
          label="Cancelar"
          onClick={() => setNuevaFirma(false)}
        />
        <Button
          icon="pi pi-check"
          label="Guardar"
          className="p-button-success"
           onClick={() => save()}
           disabled={ahorro?.asoId === firma?.asoId }
        />
      </>
    );
  }


  function  save(){
    if (
        firma.asoId &&
        firma.relId !== 0 &&
        firma.asoId &&
        firma.relId !== ""
      ) {
        const ahoFirmante = new AhoFirmanteService();
        ahoFirmante.save(firma).then(() => {
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Persona agregada",
            life: 4000,
          });
          setEstado(!estado);
          setNuevaFirma(false);
        });
      } else {
        setSubmitted(true);
        setInterval(() => setSubmitted(false), 4000);
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que los campos necesarios no esten vacios",
          life: 4000,
        });
      }

  }

  function delate() {
    const ahoFirmante = new AhoFirmanteService();
    ahoFirmante.delete(firma?.firId).then(() => {
      setEstado(!estado);
      setVisible(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Persona eliminada",
        life: 4000,
      });
    });
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => setVisible(false)}
      />
      <Button icon="pi pi-check" label="Si" onClick={delate} />
    </>
  );


  const footerDialog = () => {
    return (
      <div>
        <Button
          className="p-button-danger p-button "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => {
            setImprimir(false);
          }}
        />

        <Button
          className=" p-button "
          icon="pi pi-print"
          label="Imprimir"
          onClick={() => {
            impresionFirma();
            setImprimir(false);
          }}
        />
      </div>
    );
  };


  return (
    <>
      <div className="p-grid p-mt-2">
        <div className="p-col">
          <h4>Firmas</h4>
        </div>
        <div
          className="p-col"
          style={{ textAlign: "right", alignItems: "right" }}
        >
            <Button
            icon="pi pi-print"
            className="p-button-rounded p-button-warning p-mr-3"
            onClick={() => (setImprimir(true))}
          />
          <Button
            icon="pi pi-plus"
            className="p-button-rounded p-button-success p-mr-3"
            onClick={() =>{ setNuevaFirma(true);setFirma(firmaLet)}}
          />
        </div>
      </div>
      {renderTable()}

      <Dialog
        header="Nuevo firmante"
        visible={nuevaFirma}
        onHide={() => setNuevaFirma(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevaFirma firma={firma} setFirma={setFirma} toast={toast} submitted={submitted} />
      </Dialog>

      <Dialog
        header="Mensaje del sistema"
        visible={visible}
        onHide={() => setVisible(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar este asociado.</span>
        </div>
      </Dialog>

      <Dialog
         visible={imprimir}
         onHide={() => setImprimir(false)}
         header={"Ingreso de firmas"}
         breakpoints={{ "960px": "75vw", "640px": "100vw" }}
         style={{ width: "900px" }}
         footer={footerDialog}
        >
      <PrintFirma ahorro={ahorro} firmas={firmas} impresionFirma={impresionFirma}/>
          </Dialog>

    </>
  );
};
