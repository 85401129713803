import React, { useState, useEffect, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { NuevoRegistro } from "./NuevoRegistro";

//componentes
import { Pagination } from "../../../components/Pagination";
import useAuth from "../../../auth/useAuth";

//servicios
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";
import AppSucursalService from "../../../service/AppServices/AppSucursalService";

//funciones
import formatDate from "../../../utilities/formatDateTable";
import { InputMask } from "primereact/inputmask";
import { Dropdown } from "primereact/dropdown";
export const Registro = () => {
  const auth = useAuth();
  const toast = useRef();
  let filtroEmpty = {
    codigo: null,
    primerNombre: "",
    segundoNombre: "",
    tercerNombre: "",
    primerApellido: "",
    segundoApellido: "",
    otroApellido: "",
    numeroDeIdentifiacion: "",
    estado: "",
    sucursal: "",
    evento: "",
  };

  let registroEmpty = {
    evtEvento: {
      appSucursal: {
        appEmpresa: {
          empGerente: "",
          empId: 0,
          empNombre: "",
        },
        sucCodigoAbreviado: "",
        sucDireccion: "",
        sucEmpId: 0,
        sucGerente: "",
        sucId: 0,
        sucNombre: "",
        sucNombreAbreviado: "",
      },
      eveDescripcion: "",
      eveFecha: "",
      eveId: 0,
      eveNombre: "",
      eveSucId: 0,
    },
    parAsoId: 0,
    parEstado: false,
    parEveId: 0,
    parFechaInscripcion: null,
    parHoraInscripcion: null,
    parId: 0,
    parSucId: auth.user?.usr.appSucursal?.sucId,
    nom: "",
  };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [registro, setRegistro] = useState(registroEmpty);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(true);
  const [estado, setEstado] = useState(false);
  const [participantes, setParticipantes] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [eventos, setEventos] = useState([]);

  useEffect(() => {
    const sucursalesService = new AppSucursalService();
    sucursalesService.findAll().then((res) => {
      const sucursalesApp = [];
      res.forEach((element) => {
        var sucursal = {
          value: 0,
          label: "",
        };
        sucursal.value = element.sucId;
        sucursal.label = element.sucNombre;
        sucursalesApp.push(sucursal);
      });
      setSucursales(sucursalesApp);
    });
  }, []);
  useEffect(() => {
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService
      .findByPage(
        pageNumber,
        filtro.estado,
        filtro.codigo,
        filtro.sucursal,
        filtro.evento,
        filtro.primerNombre,
        filtro.segundoNombre,
        filtro.tercerNombre,
        filtro.primerApellido,
        filtro.segundoApellido,
        filtro.otroApellido,
        filtro.numeroDeIdentifiacion
      )
      .then((res) => {
        setParticipantes(res?.content || []);
        setTotalPages(res?.totalPages || 0);
        setPageNumber(res?.number || 0);
        setFirstPage(res?.first || 0);
        setLastPage(res?.last || 0);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado, pageNumber, filtro.estado, filtro.sucursal, filtro.evento]);

  useEffect(() => {
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService.findAll().then((res) => {
      const eventosApp = [];
      res?.forEach((element) => {
        let evento = { value: 0, label: "" };
        evento.value = element.eveId;
        evento.label = element.eveNombre;
        eventosApp.push(evento);
      });
      setEventos(eventosApp);
    });
  }, []);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9) {
      if (
        filtro.estado !== "" ||
        filtro.codigo != null ||
        filtro.sucursal !== "" ||
        filtro.evento !== "" ||
        filtro.primerNombre !== "" ||
        filtro.segundoNombre !== "" ||
        filtro.tercerNombre !== "" ||
        filtro.primerApellido !== "" ||
        filtro.segundoApellido !== "" ||
        filtro.otroApellido !== "" ||
        filtro.numeroDeIdentifiacion !== ""
      ) {
        const eventoParticipanteService = new EventoParticipanteService();
        eventoParticipanteService
          .findByPage(
            pageNumber,
            filtro.estado,
            filtro.codigo,
            filtro.sucursal,
            filtro.evento,
            filtro.primerNombre,
            filtro.segundoNombre,
            filtro.tercerNombre,
            filtro.primerApellido,
            filtro.segundoApellido,
            filtro.otroApellido,
            filtro.numeroDeIdentifiacion
          )
          .then((res) => {
            setParticipantes(res?.content || []);
            setTotalPages(res?.totalPages || 0);
            setFirstPage(res?.first || 0);
            setLastPage(res?.last || 0);
            setPageNumber(0);
          });
      }
    }
  };
  const estiloFiltro = {  backgroundColor: "#f5f5f5" };
  const renderFormfFilters = (
    <div className="p-fluid p-grid p-formgrid ">
            <div className=" p-col-9 ">
                <h5>Filtros</h5>
        </div>
          <div className=" p-col-3 p-mb-2">
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-button-sm"
          onClick={() => {setNuevoRegistro(true);setRegistro(registroEmpty)}}
        />
      </div>
      <div className="p-col-3" onKeyDown={(e) => fil(e)}>
        <samp className="p-float-label">
          <InputText
            className="p-inputtext-sm"
            type={"number"}
            style={estiloFiltro}
            value={filtro.codigo||""}
            onChange={(e) => setFiltro({ ...filtro, codigo: e.target.value })}
          />
          <label>Codigo</label>
        </samp>
      </div>
      <div className="p-col-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            className="p-inputtext-sm"
            value={filtro.primerNombre||""}
            style={estiloFiltro}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                primerNombre: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Primer Nombre</label>
        </span>
      </div>
      <div className=" p-col-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            className="p-inputtext-sm"
            style={estiloFiltro}
            value={filtro.segundoNombre ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                segundoNombre: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Segundo Nombre</label>
        </span>
      </div>
      <div className=" p-col-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            style={estiloFiltro}
            value={filtro.tercerNombre  ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                tercerNombre: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Otro Nombre</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            style={estiloFiltro}
            value={filtro.primerApellido  ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                primerApellido: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Primer Apellido</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            style={estiloFiltro}
            className="p-inputtext-sm"
            value={filtro.segundoApellido  ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                segundoApellido: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Segundo apellido</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputText
            style={estiloFiltro}
            className="p-inputtext-sm"
            value={filtro.otroApellido  ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                otroApellido: e.target.value.toUpperCase(),
              })
            }
            type={"text"}
          />
          <label>Apellido Casada</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <InputMask
            style={estiloFiltro}
            mask="9999 99999 9999"
            className="p-inputtext-sm"
            value={filtro.numeroDeIdentifiacion ||""}
            onChange={(e) =>
              setFiltro({
                ...filtro,
                numeroDeIdentifiacion: e.target.value,
              })
            }
            type={"text"}
          />
          <label>Numero de identificacion</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <Dropdown
            style={estiloFiltro}
            className="p-inputtext-sm"
            value={filtro.estado ||""}
            onChange={(e) => setFiltro({ ...filtro, estado: e.target.value })}
            options={[
              { label: "INSCRITOS", value: "f" },
              { label: "PARTICIPANTES", value: "t" },
              { label: "TODOS", value: "a" },
            ]}
            type={"text"}
          />
          <label>Estado</label>
        </span>
      </div>
      <div className=" p-col-3 p-mt-3 " onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <Dropdown
            style={estiloFiltro}
            className="p-inputtext-sm"
            value={filtro.sucursal ||""}
            onChange={(e) => setFiltro({ ...filtro, sucursal: e.target.value })}
            type={"text"}
            options={sucursales}
          />
          <label>Sucursal de ingreso</label>
        </span>
      </div>
      <div className=" p-col p-mt-3" onKeyDown={(e) => fil(e)}>
        <span className="p-float-label">
          <Dropdown
            style={estiloFiltro}
            className="p-inputtext-sm"
            value={filtro.evento ||""}
            onChange={(e) => setFiltro({ ...filtro, evento: e.target.value })}
            options={eventos}
          />
          <label>Por evento</label>
        </span>
      </div>
    
    </div>
  );
  //   const right = () => {
  //     return (
  //       <Button
  //         label="Nuevo"
  //         icon="pi pi-plus"
  //         className="p-button-success "
  //         onClick={() => (setNuevoRegistro(true), setRegistro(registroEmpty))}
  //       />
  //     );
  //   };

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  const nombreCompleto = (rowData) => {

    var primerNombre = rowData?.primerNombre +"";
    var segundoNombre = rowData?.segundoNombre +"";
    var tercerNombre = rowData?.otroNombre +"";
    var primerApellido = rowData?.primerApellido + "";
    var segundoApellido  = rowData?.segundoApellido +"";
    var apellidoCasada = rowData?.apellidoCasada +"";

    var nombre =
    primerNombre.replace("null" || "undefined","") +
      " " +
      segundoNombre.replace("null" || "undefined","") +
      " " +
      tercerNombre.replace("null" || "undefined","") +
      " " +
      primerApellido.replace("null" || "undefined","") +
      " " +
      segundoApellido.replace("null" || "undefined","") +
      " " +
      apellidoCasada.replace("null" || "undefined","");
  

    var nombre2 = nombre.replace("   ", " ");
    var nombre3 = nombre2.replace("  ", " ");

    return <>{nombre3}</>;
  };

  function formatDateTable(rowData) {
    return <>{formatDate(rowData.parFechaInscripcion)}</>;
  }

  function estadoParticipante(rowData) {
    return <>{rowData.parEstado=== false ? "INSCRITO" : "PARTICIPANTE"}</>;
  }

  const accionesTarjeta = (rowData) => {
    return (
      <div>
        <Button
          //   label="Editar"
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoRegistro(true); setRegistro(rowData)}}
        />
      </div>
    );
  };

  const renderTable = () => {
    return (
      <DataTable
        value={participantes}
        emptyMessage="No se encontro ningun asociado registrado para ningun evento"
        footer={renderPaginacion()}
      >
        <Column header="Codigo Asociado" field="parAsoId" />
        <Column header="Nombre" body={nombreCompleto} />
        <Column
          header="Numero de identificacion"
          field="numDocIdentificacion"
        />
        <Column header="Genero" field="sexDescripcion" />
        <Column header="Fecha de inscripcion" body={formatDateTable} />
        <Column header="Estado" body={estadoParticipante} />
        <Column header="Acciones" body={accionesTarjeta} />
      </DataTable>
    );
  };

  return (
    <>
      <div className="card p-mb-3 ">{renderFormfFilters}</div>
      <div className="card">{renderTable()}</div>
      <Dialog
        visible={nuevoRegistro}
        onHide={() => setNuevoRegistro(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header={registro?.parAsoId === 0 ? "Nueva inscripcion" :"Editar participante"}
      >
        <NuevoRegistro
          setNuevoRegistro={setNuevoRegistro}
          toast={toast}
          registro={registro}
          setRegistro={setRegistro}
          estado={estado}
          setEstado={setEstado}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
