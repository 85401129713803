import React, { useEffect, useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
//servicios
import AppMunicipioService from "../../../service/AppServices/AppMunicipioService";
import { Dialog } from "primereact/dialog";
import { NuevoMunicipio } from "./NuevoMunicipio";

export const ListarMunicipio = () => {
    const toast = useRef();
  let municipioEmpty = {
    appDepartamento: {
      appPais: {
        paiCodAlpha2: "",
        paiCodAlpha3: "",
        paiId: 0,
        paiNombre: "",
        paiNombreEn: "",
        paiNumerico: "",
      },
      appRegion: {
        appPais: {
          paiCodAlpha2: "",
          paiCodAlpha3: "",
          paiId: 0,
          paiNombre: "",
          paiNombreEn: "",
          paiNumerico: "",
        },
        paiId: 0,
        regCodigo: 0,
        regId: 0,
        regNombre: "",
      },
      depCodigo: 0,
      depId: 0,
      depNombre: "",
      paiId: 0,
      regId: 0,
    },
    depCodigo: 0,
    depId: 0,
    munCodigo: 0,
    munId: 0,
    munNombre: "",
  };

  const [municipios, setMunicipios] = useState([]);
  const [nuevoMunicipio, setNuevoMunicipio] = useState(false);
  const [municipio, setMunicipio] = useState(municipioEmpty);
  const [estado, setEstado] = useState(false);
  useEffect(() => {
    const appMunicipioService = new AppMunicipioService();
    appMunicipioService.findAll().then((res) => {
      setMunicipios(res);
    });
  }, [estado]);

  const funcionRenderTable = () => {
    return (
      <DataTable value={municipios}>
        <Column header="Id" field="munId" />
        <Column header="Municipio" field="munNombre" />
        <Column header="Codigo" field="munCodigo" />
        <Column header="Departamento" field="appDepartamento.depNombre" />
        <Column header="Acciones" body={accionMunicipio} />
      </DataTable>
    );
  };
  const accionMunicipio = (rowData) => {
    return (
      <div>
        <Button
          //   label="Editar"
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoMunicipio(true); setMunicipio(rowData)}}
        />
      </div>
    );
  };

  const ButtonNew = (
    <Button
      onClick={() => {setNuevoMunicipio(true); setMunicipio(municipioEmpty)}}
      className="p-button-success p-button-sm"
      label="nuevo"
      icon="pi pi-plus"
    />
  );

  return (
    <div className="card">
      <Toolbar className="p-mb-3" right={ButtonNew} />
      {funcionRenderTable()}

      <Dialog
        visible={nuevoMunicipio}
        onHide={() => setNuevoMunicipio(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header={municipio?.munId === 0 ? "Nuevo municipio" : "Editar municipio"}
      >
        <NuevoMunicipio
          municipio={municipio}
          setMunicipio={setMunicipio}
          setNuevoMunicipio={setNuevoMunicipio}
          estado={estado}
          setEstado={setEstado}
          toast={toast}
        />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
