import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import CliAsociadoService from "../../service/CliServices/CliAsociadoService";
import AppTipoIdentificacionService from "../../service/AppServices/AppTipoIdentificacionService";

import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { InputSwitch } from "primereact/inputswitch";

import { Link } from "react-router-dom";

export const CliAsociado = () => {
  let emptyAsociado = {
    actEcoId: 0,
    appActividadEconomica: {
      actEcoDescripcion: "",
      actEcoId: 0,
    },
    appCondicionMigratoria: {
      conMigDescripcion: "",
      conMigId: 0,
    },
    appEstadoCivil: {
      estCivDescripcion: "",
      estCivId: 0,
    },
    appMediosComunicacion: {
      medComDescripcion: "",
      medComId: 0,
      medComLogo: "",
    },
    appProfesionOficio: {
      ofiDescripcion: "",
      ofiId: 0,
    },
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
    },
    appTipoIdentificacion: {
      ideTipoDescripcion: "",
      ideTipoFormato: "",
      ideTipoId: 0,
    },
    appTipoPersona: {
      perTipoDescripcion: "",
      perTipoId: 0,
    },
    asoActuaNombrePropio: 0,
    asoAfectoImpuesto: 0,
    asoApellidoCasada: "",
    asoCPE: 0,
    asoCalidadActua: "",
    asoEmpleado: 0,
    asoEstado: "",
    asoFechaConstitucion: "",
    asoFechaInactivacion: "",
    asoFechaIngreso: "",
    asoFechaModificacion: "",
    asoFechaNacimiento: "",
    asoFechaVenceIdentificacion: "",
    asoFirma: "",
    asoFoto: "",
    asoId: 0,
    asoIdReferencia: "",
    asoIdRepresentante: "",
    asoListaNegra: 0,
    asoListaNegraReferencia: "",
    asoMensajeAdvertencia: "",
    asoNit: "",
    asoNombreJuridico: "",
    asoNumDocIdentificacion: "",
    asoOtraCondicionMigra: "",
    asoOtroNombre: "",
    asoPrimerApellido: "",
    asoPrimerNombre: "",
    asoPropositoRelacion: "",
    asoRegistroMercantil: "",
    asoRepresentanteLegal: "",
    asoSegundoApellido: "",
    asoSegundoNombre: "",
    asoUsaBancoCoop: "",
    conMigId: 0,
    estCivId: 0,
    ideTipoId: 0,
    medComId: 0,
    ofiId: 0,
    perTipoId: 0,
    sexId: 0,
    sucId: 0,
    usuUsuarioIngresa: "",
    usuUsuarioModifica: "",
    usuUsuarioPromotor: "",
  };

  let emptyTipoIdentificacion = {
    ideTipoId: 0,
    ideTipoDescripcion: "",
    ideTipoFormato: "",
  };

  useEffect(() => {
    const appTipoIdentificacionService = new AppTipoIdentificacionService();
    appTipoIdentificacionService.findAll().then((data) => {
      setTipoIdentificaciones(data.data);
    });
  }, []);

  const [codigoAsociado, setCodigoAsociado] = useState("");
  const [numeroIdentificacion, setNumeroIdentificacion] = useState();
  const [primerNombre, setPrimerNombre] = useState("");
  const [segundoNombre, setSegundoNombre] = useState("");
  const [otroNombre, setOtroNombre] = useState("");
  const [primerApellido, setPrimerApellido] = useState("");
  const [segundoApellido, setSegundoApellido] = useState("");
  const [nombreJuridico, setNombreJuridico] = useState("");
  const [estadoAsociado, setEstadoAsociado] = useState(true);
  const [estadoAsociadoNombre, setEstadoAsociadoNombre] = useState("");

  useEffect(() => {
    if (estadoAsociado === true) {
      setEstadoAsociadoNombre("Solo Activos");
    } else {
      setEstadoAsociadoNombre("Todos");
    }
  }, [estadoAsociado]);

  const [asociados, setAsociados] = useState([]);
  const [tipoIdentificaciones, setTipoIdentificaciones] = useState(null);
  const [tipoIdentificacion, setTipoIdentificacion] = useState(
    emptyTipoIdentificacion
  );
  const [asociadoDialog, setAsociadoDialog] = useState(false);
  const [deleteAsociadoDialog, setDeleteAsociadoDialog] = useState(false);
  const [asociado, setAsociado] = useState(emptyAsociado);
  const [selectedActividades, setSelectedActividades] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);

  //States para la paginacion
  const [sizeForPage, setSizeForPage] = useState(2);
  const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    const appAsociadoService = new CliAsociadoService();
    appAsociadoService
      .findByPage(
        pageNumber,
        codigoAsociado,
        primerNombre,
        segundoNombre,
        otroNombre,
        primerApellido,
        segundoApellido,
        numeroIdentificacion,
        estadoAsociado,
        nombreJuridico,
        tipoIdentificacion.ideTipoId
      )
      .then((data) => {
    
        setAsociados(data.data.content);
        setSizeForPage(data.data.size);
        setTotalElements(data.data.totalElements);
        setTotalPages(data.data.totalPages);
        setFirstPage(data.data.first);
        setLastPage(data.data.last);
      })
      .catch((error) => {
      
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estadoAsociado, pageNumber, tipoIdentificacion]);

  const fil = (evt) => {
    const appAsociadoService = new CliAsociadoService();
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9) {
      appAsociadoService
        .findByPage(
          pageNumber,
          codigoAsociado,
          primerNombre.toUpperCase(),
          segundoNombre.toUpperCase(),
          otroNombre.toUpperCase(),
          primerApellido.toUpperCase(),
          segundoApellido.toUpperCase(),
          numeroIdentificacion,
          estadoAsociado,
          nombreJuridico.toUpperCase(),
          tipoIdentificacion.ideTipoId
        )
        .then((data) => {
   

          setAsociados(data.data.content);
          setSizeForPage(data.data.size);
          setTotalElements(data.data.totalElements);
          setTotalPages(data.data.totalPages);
          setFirstPage(data.data.first);
          setLastPage(data.data.last);
          if (
            codigoAsociado !== "" ||
            primerNombre !== "" ||
            segundoNombre !== "" ||
            otroNombre !== "" ||
            primerApellido !== "" ||
            segundoApellido !== "" ||
            numeroIdentificacion !== "" ||
            nombreJuridico !== ""
          ) {
            setPageNumber(0);
          }
        });
    }
  };

  const hideDialog = () => {
    setSubmitted(false);
    setAsociadoDialog(false);
  };

  const hideDeleteSexoDialog = () => {
    setDeleteAsociadoDialog(false);
  };

  //Guardamos un registro
  const saveSexo = () => {
    setSubmitted(true);

    const appAsociadoService = new CliAsociadoService();
    if (asociado.paiId !== null) {
      appAsociadoService.save(asociado).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "Actividad Económica Actualizado",
        life: 3000,
      });
    } else {
      appAsociadoService.save(asociado).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "Actividad Económica Creado",
        life: 5000,
      });
    }

    //Llamamos el metodo findByPage para que la busqueda actual no cambie al guardar los datos
    findByPage();
    //Cuerramos el dialogo de nueva asociado Economica
    setAsociadoDialog(false);
    setAsociado(emptyAsociado);
    //}
  };

  //Mensaje de confirmación de un registro
  // const confirmDeleteActividad = (product) => {
  //   setAsociado(product);
  //   setDeleteAsociadoDialog(true);
  // };

  //Borramos un Género
  const deleteActividad = () => {
    setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const appAsociadoService = new CliAsociadoService();
    //Enviamos los datos al servicio correspondiente
    appAsociadoService.delete(asociado).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      toast.current.show({
        severity: "success",
        summary: "¡Éxitoso!",
        detail: "Actividad Económica eliminado",
        life: 3000,
      });
    });
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteAsociadoDialog(false);
    //Metemos al state el all de los asociados del sistemas despues de aver eliminado
    setAsociado(emptyAsociado);
  };

  const findByPage = () => {
    //Cramos una instancia de nuestro servicio
    const appAsociadoService = new CliAsociadoService();
    //Enviamos los datos al servicio correspondiente
    appAsociadoService.findByPage().then((response) => {
      setAsociados(response.data.content);
    });
  };

  // const findByPageAndPage = (pageNumber) => {
  //   //Cramos una instancia de nuestro servicio
  //   const appAsociadoService = new CliAsociadoService();
  //   //Enviamos los datos al servicio correspondiente
  //   appAsociadoService.findByPage(pageNumber).then((response) => {
  //     setAsociados(response.data.content);
  //     setPageNumber(response.data.number);
  //     setFirstPage(response.data.first);
  //     setLastPage(response.data.last);
  //   });
  // };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _asociado = { ...asociado };
    _asociado[`${name}`] = val;
    setAsociado(_asociado);
  };

  
  const renderFormfFilters = () => {
    return (
      <>
        <div onKeyDown={(e) => fil(e)}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {" "}
            <div
              style={{
                flex: 1,
              }}
            >
              <h4>Filtros</h4>
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                alignItems: "right",
                justifyContent: "right",
              }}
            >
              <Link
                to={{
                  pathname: "/cliasociadoeditar",

                }}
              >
                <Button
                  label="Nuevo"
                  icon="pi pi-plus"
                  className="p-button-success p-mr-4 p-mb-3"

                  onClick={()=> sessionStorage.setItem("aso",JSON.stringify({asoId:0}))}
                  //Link="/invalidstate"
                ></Button>
              </Link>
            </div>
          </div>

          <div className="p-fluid p-grid">
            <div className="p-field p-col">
              <span className="p-float-label">
                <InputText
                  id="codigoAsociado"
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  type="number"
                  value={codigoAsociado}
                  onChange={(e) => setCodigoAsociado(e.target.value)}
                />
                <label htmlFor="inputtext">Código</label>
              </span>
            </div>
            <div className="p-field p-col">
              <span className="p-float-label">
                <Dropdown
                  value={tipoIdentificacion}
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  onChange={(e) => setTipoIdentificacion(e.value)}
                  options={tipoIdentificaciones}
                  optionLabel="ideTipoDescripcion"
                  placeholder="Seleccione..."
                />
                <label htmlFor="tipoIdentificacion">Tipo Identificación</label>
              </span>
            </div>
            <div className="p-field p-col">
              <span className="p-float-label">
                <InputMask
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  mask={tipoIdentificacion?.ideTipoFormato || ""}
                  value={numeroIdentificacion}
                  onChange={(e) => setNumeroIdentificacion(e.value)}
                ></InputMask>
                <label htmlFor="numeroIdentificacion">
                  Número Identificación
                </label>
              </span>
            </div>
            <div className="p-field p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="otroNombre"
                  type="text"
                  value={otroNombre}
                  onChange={(e) => setOtroNombre(e.target.value)}
                />
                <label htmlFor="otroNombre">Otro Nombre</label>
              </span>
            </div>
          </div>
          <div className="p-fluid p-grid">
            <div className="p-field p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="primerNombre"
                  type="text"
                  value={primerNombre}
                  onChange={(e) => setPrimerNombre(e.target.value)}
                />
                <label htmlFor="primerNombre">Primer Nombre</label>
              </span>
            </div>
            <div className="p-field p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="segundoNombre"
                  type="text"
                  value={segundoNombre}
                  onChange={(e) => setSegundoNombre(e.target.value)}
                />
                <label htmlFor="segundoNombre">Segundo Nombre</label>
              </span>
            </div>

            <div className="p-field p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="primerApellido"
                  type="text"
                  value={primerApellido}
                  onChange={(e) => setPrimerApellido(e.target.value)}
                />
                <label htmlFor="primerApellido">Primer Apellido</label>
              </span>
            </div>
            <div className="p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="segundoApellido"
                  type="text"
                  value={segundoApellido}
                  onChange={(e) => setSegundoApellido(e.target.value)}
                />
                <label htmlFor="segundoApellido">Segundo Apellido</label>
              </span>
            </div>
          </div>
          <div className="p-fluid p-grid">
            <div className="p-col">
              <span className="p-float-label">
                <InputText
                  style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                  id="nombreJuridico"
                  type="text"
                  value={nombreJuridico}
                  onChange={(e) => setNombreJuridico(e.target.value)}
                />
                <label htmlFor="nombreJuridico">Nombre Juridico</label>
              </span>
            </div>
            <div className="p-col-3">
              <InputSwitch
                style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                className="p-mr-3"
                checked={estadoAsociado}
                onChange={(e) => setEstadoAsociado(e.target.value)}
              />
              <label htmlFor="estadoAsociado">{estadoAsociadoNombre}</label>
            </div>
          </div>
        </div>
      </>
    );
  };

  // const rightToolbarTemplate = () => {
  //   return (
  //     <React.Fragment>
  //       {/* //Agregar contenido al lado derecho */}
  //     </React.Fragment>
  //   );
  // };

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.asoId}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.asoNombreJuridico === null
          ? ""
          : rowData.asoNombreJuridico}{" "}
        {rowData.asoPrimerNombre} {rowData.asoSegundoNombre}{" "}
        {rowData.asoOtroNombre} {rowData.asoPrimerApellido}{" "}
        {rowData.asoSegundoApellido} {rowData.asoApellidoCadasa}
      </>
    );
  };

  const DPIBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">DPI</span>
        {rowData.asoNumDocIdentificacion}
      </>
    );
  };

  const asoEstadoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Estado</span>
        {rowData.asoEstado === "A" ? "ACTIVO" : "INACTVIO"}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    
    return (
      <div className="actions">
        <Link
          to={{
            pathname: "/cliasociadoeditar",
            state: {
              asociado: rowData,
            },
          }}
        >
          <Button
            icon="pi pi-eye"
            className="p-button-rounded p-button-success p-mr-2"
        //    tooltip={"Ver Cliente: " + rowData.asoId}
        onClick={()=> sessionStorage.setItem("aso",JSON.stringify(rowData))}
          />
        </Link>

        {/* <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteActividad(rowData)}
        /> */}
      </div>
    );
  };

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveSexo} />
    </>
  );
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSexoDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteActividad} />
    </>
  );

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">{renderFormfFilters()}</div>
        <div className="card">
          <Toast ref={toast} />
          {/* <Toolbar
            className="p-mb-4"
            left={renderFormfFilters}
            right={rightToolbarTemplate}
          ></Toolbar> */}

          <DataTable
            ref={dt}
            rowHover
            value={asociados}
            selection={selectedActividades}
            onSelectionChange={(e) => setSelectedActividades(e.value)}
            dataKey="asoId"
            rows={sizeForPage}
            className="datatable-responsive"
            totalRecords={totalElements}
            emptyMessage="No se encontraron asociados."
          >
            <Column
              field="asoId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="asoPrimerNombre"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column
              field="asoNumDocIdentificacion"
              header="Identificación"
              sortable
              body={DPIBodyTemplate}
            ></Column>
            <Column
              field="asoEstadi"
              header="Estado"
              sortable
              body={asoEstadoBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>

          {/* <div style={{ textAlign: 'center' }}>
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={firstPage}
              onClick={() => findByPageAndPage(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={firstPage}
              onClick={() => findByPageAndPage(pageNumber - 1)}
            />

            <InputText
              type="text"
              placeholder={
                'Página ' +
                (pageNumber + 1) +
                ' de ' +
                totalPages +
                ' : : elementos ' +
                totalElements
              }
              disabled
              style={{
                background: 'transparent',
                color: '#ffff !important',
                border: 'none',
                marginRight: '4px',
                width: '18%',
                textAlign: 'center',
              }}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-right"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={lastPage}
              onClick={() => findByPageAndPage(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-right"
              style={{
                background: 'transparent; color: #6E707A',
                marginRight: '4px',
                border: 'none',
              }}
              disabled={lastPage}
              onClick={() => findByPageAndPage(totalPages - 1)}
            />
          </div> */}

          <div style={{ textAlign: "center" }}>
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              className="p-button-outlined p-button-secondary "
              style={{
                background: "transparent; color: #6E707A",
                marginRight: "4px",
                border: "none",
              }}
              disabled={firstPage}
              onClick={() => setPageNumber(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              className="p-button-outlined p-button-secondary "
              style={{
                background: "transparent; color: #6E707A",
                marginRight: "4px",
                border: "none",
              }}
              disabled={firstPage}
              onClick={() => setPageNumber(pageNumber - 1)}
            />

            <InputText
              type="text"
              className="p-inputtext p-component"
              placeholder={pageNumber + 1 + " de " + totalPages}
              style={{
                width: "100px",
                height: "30px",
                borderColor: "#5499C7",
                textAlign: "center",

                fontSize: "15px",
                background: "transparent",
              }}
              readOnly
            />

            <Button
              type="button"
              label=""
              className="p-button-outlined p-button-secondary "
              icon="pi pi-angle-right"
              style={{
                background: "transparent; color: #5499C7",
                marginRight: "4px",
                border: "none",
              }}
              disabled={lastPage}
              onClick={() => setPageNumber(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              className="p-button-outlined p-button-secondary "
              icon="pi pi-angle-double-right"
              style={{
                background: "transparent; color: #6E707A",
                marginRight: "4px",
                border: "none",
              }}
              disabled={lastPage}
              onClick={() => setPageNumber(totalPages - 1)}
            />
          </div>

          <Dialog
            visible={asociadoDialog}
            style={{ width: "450px" }}
            header="Datos de la Actividad Económica"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="actEcoDescripcion">Nombre</label>
              <InputText
                id="actEcoDescripcion"
                value={asociado.asoPrimerApellido}
                onChange={(e) => onInputChange(e, "asoPrimerApellido")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !asociado.asoPrimerApellido,
                })}
              />
              {submitted && !asociado.actEcoDescripcion && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteAsociadoDialog}
            style={{ width: "450px" }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteSexoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {asociado && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{asociado.actEcoDescripcion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
