import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from "primereact/radiobutton";
//servicios
import AppPreDeduccionService from "../../../../service/AppServices/AppPreDeduccionService";
import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
import AppTipoSubProductoService from "../../../../service/AppServices/AppTipoSubProductoService";
//funciones
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
export const NuevaAsignacionDeduccion = ({
  deduccion,
  setDeduccion,
  submitted,
}) => {
  let productoEmpty = {
    proId: 0,
    proTipoId: 0,
    proTipoNombre: "",
  };
  const [idProducto, setIdProducto] = useState(productoEmpty);
  const [deducciones, setDeducciones] = useState([]);
  const [productoAho, setProductoAho] = useState([]);
  const [subProductos, setSubProductos] = useState([]);

  useEffect(() => {
    const deduccionesService = new AppPreDeduccionService();
    const productoSerivice = new AppTipoProductoService();
    deduccionesService.findAll().then((res) => {
      setDeducciones(res);
    });

    productoSerivice.findAll().then((res) => {
      setProductoAho(res?.data);
    });

    if (deduccion?.preDedAsiSubAhoId !== null) {
     
        const subProductoService = new AppTipoSubProductoService();
        subProductoService
          .findById(deduccion?.appAhoSubProducto.proId)
          .then((res) => {
            setSubProductos(res);
          });
  
          productoSerivice
          .findByID(deduccion?.appAhoSubProducto.proId)
          .then((response) => {
            setIdProducto(response);
          });
      }
  
 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onInputChangeRegObject = (e) => {
    setIdProducto(e.value);
    const subProductoService = new AppTipoSubProductoService();
    subProductoService.findById(e.value.proId).then((res) => {
      setSubProductos(res);
    });
  };
  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col-12">
          <label>Tipo de deduccion</label>
          <Dropdown
            value={deduccion.appPreDeduccion}
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appPreDeduccion",
                e.target.value.preDedId,
                "preDedAsiDedId",
                deduccion,
                setDeduccion
              )
            }
            options={deducciones}
            optionLabel="preDedDescripcion"
            placeholder="Seleccione...."
            className={{
              "p-invalid": submitted && !deduccion.preDedAsiDedId,
            }}
          />
           { submitted && !deduccion.preDedAsiDedId && (
              <small className="p-invalid">Tipo de deduccion requerido </small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <label>Acreditar a cuenta</label>
          <div className="p-grid p-fluid">
            <div className="p-col">
              <div className="p-field-radiobutton">
                <RadioButton
                  value={true}
                  onChange={(e) =>
                    setDeduccion({
                      ...deduccion,
                      preDedAsiAcreditar: e.value,
                    })
                  }
                  checked={deduccion.preDedAsiAcreditar === true}
                  className={{
                    "p-invalid": submitted && deduccion.preDedAsiAcreditar === undefined,
                  }}
                />
                <label> Si</label>
              </div>
            </div>
            <div className="p-col">
              <div className="p-field-radiobutton">
                <RadioButton
                  value={false}
                  onChange={(e) =>
                    setDeduccion({
                      ...deduccion,
                      preDedAsiAcreditar: e.value,
                    })
                  }
                  checked={deduccion.preDedAsiAcreditar === false}
                  className={{
                    "p-invalid": submitted && deduccion.preDedAsiAcreditar === undefined,
                  }}
                />
                <label>No</label>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <label>Forma de afectar</label>
          <Dropdown
            value={deduccion.preDedAsiSumaResta}
            options={[
              { label: "SUMAR", value: 1 },
              {
                label: "RESTAR",
                value: 2,
              },
            ]}
            placeholder="Seleccione..."
            onChange={(e) =>
              setDeduccion({
                ...deduccion,
                preDedAsiSumaResta: e.value,
              })
            }
            className={{
              "p-invalid": submitted && !deduccion.preDedAsiSumaResta,
            }}
          />
            { submitted && !deduccion.preDedAsiSumaResta && (
              <small className="p-invalid">Forma de pago requerido </small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 ">
          <label>Tipo de deduccion</label>
          <Dropdown
            value={deduccion.preDedAsiTipo}
            options={[
              { label: "LIBRE", value: 1 },
              { label: "PORCENTAJE", value: 2 },
            ]}
            onChange={(e) =>
              setDeduccion({
                ...deduccion,
                preDedAsiTipo: e.value,
              })
            }
            placeholder="Selecione..."
            className={{
              "p-invalid": submitted && !deduccion.preDedAsiTipo,
            }}
          />
            { submitted && !deduccion.preDedAsiTipo && (
              <small className="p-invalid">Tipo de deduccion requerido </small>
            )}
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 ">
          <label>Porcentaje a aplicar</label>
          <InputText
            value={deduccion.preDedAsiValor}
            id="por"
            onClick={(e) => selectText(e)}
            type={"number"}
            placeholder="Porcentaje a aplicar"
            onChange={(e) =>
              onChajeObjN(e, "preDedAsiValor", deduccion, setDeduccion)
            }
            disabled={deduccion.preDedAsiTipo !== 2}
            className={{
              "p-invalid":
                submitted &&
                !deduccion.preDedAsiValor &&
                deduccion.preDedAsiTipo === 2,
            }}
          />
           { submitted &&
                !deduccion.preDedAsiValor &&
                deduccion.preDedAsiTipo === 2 && (
              <small className="p-invalid">Porsentaje requerido </small>
            )}
        </div>
        <div
          className="p-col-12"
          style={{
            display: deduccion.preDedAsiAcreditar === false ? "none" : "",
          }}
        >
          <div className="p-grid">
            <div className="p-col-12 p-md-6 p-lg-6 ">
              <label>Producto</label>
              <Dropdown
                value={idProducto}
                options={productoAho}
                optionLabel="proNombre"
                placeholder="Seleccione un producto"
                onChange={(e) =>
                  onInputChangeRegObject(e, "idProducto", e.value.id, "proId")
                }
                className={{
                  "p-invalid":
                    submitted &&
                    !deduccion.idProducto &&
                    deduccion.preDedAsiAcreditar === true,
                }}
              />
               {     submitted &&
                    !deduccion.idProducto &&
                    deduccion.preDedAsiAcreditar === true && (
              <small className="p-invalid">Producto requerido </small>
            )}
            </div>
            <div className="p-col-12 p-md-6 p-lg-6 ">
              <label>Sub Producto</label>
              <Dropdown
                value={deduccion?.appAhoSubProducto}
                options={subProductos}
                onChange={(e) =>
                  onChangeObjDropDawn(
                    e,
                    "appAhoSubProducto",
                    e.target.value.subAhoId,
                    "preDedAsiSubAhoId",
                    deduccion,
                    setDeduccion
                  )
                }
                optionLabel="subAhoNombre"
                placeholder="Seleccionar"
                className={{
                  "p-invalid":
                    submitted &&
                    !deduccion.preDedAsiSubAhoId &&
                    deduccion.preDedAsiAcreditar === true,
                }}
              />
                {     submitted &&
                    !deduccion.preDedAsiSubAhoId &&
                    deduccion.preDedAsiAcreditar === true && (
              <small className="p-invalid">SubProducto requerido </small>
            )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
