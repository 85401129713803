import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class PlazoService {
  
  async getAll() {


    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/tcrplazo/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }

    
  }

  async save (data){
        if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/tcrplazo/save`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt?.jwt}`,
          },
        }
      );

      return response;
    } catch (err) {
      console.log(err?.response);
    }

  }


}
