import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
//component
import { CliAsociadoSeleccionar } from "../../../ClientesPages/CliAsociadoSeleccionar";

//funciones
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import selectText from "../../../../utilities/selecText";

//Servicios
import AppTipoRelacionCuentaService from "../../../../service/AppServices/AppTipoRelacionCuentaService";
import CliAsociadoService from "../../../../service/CliServices/CliAsociadoService";
import { CargarFirmaAso } from "./CargarFirmaAso";

export const NuevaFirma = ({ firma, setFirma, toast, submitted }) => {
  const [buscar, setBuscar] = useState(false);
  const [relaciones, setRelaciones] = useState([]);
  const [cargarFirma, setCargarFirma] = useState(false);

  useEffect(() => {
    const relacionService = new AppTipoRelacionCuentaService();
    relacionService.findAll().then((res) => {
      setRelaciones(res);
    });
  }, []);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9) {
      if (
        firma.asoId !== 0 &&
        firma.asoId !== undefined &&
        firma.asoId !== null &&
        firma.asoId !== ""
      ) {
        const cliAsociadoService = new CliAsociadoService();
        cliAsociadoService.findByIdClau(firma.asoId).then((res) => {
          setFirma({ ...firma, asoNombre: res?.name });
        });
      }
    }
  };

  function renderForm() {
    return (
      <div className="p-grid p-fluid">
        <div className="p-col-4">
          <label>Codigo del cliente</label>
          <div className="p-inputgroup" onKeyDown={(e) => fil(e)}>
            <InputText
              placeholder="Codigo"
              value={firma?.asoId}
              onChangeCapture={(e) => {
                setFirma({ ...firma, asoId: e.target.value });
              }}
              id="asoId"
              onClick={(e) => selectText(e)}
              className={classNames({
                "p-invalid": submitted && !firma?.asoId,
              })}
            />
            <Button
              icon="pi pi-search"
              className="p-button-info"
              onClick={() => setBuscar(true)}
            />
          </div>
          {submitted && !firma.asoId && (
            <small className="p-invalid">Codigo requerido</small>
          )}
        </div>
        <div className="p-col-8">
          <label>Nombre</label>
          <InputText
            disabled={true}
            placeholder="Nombre..."
            value={firma?.asoNombre}
            className={classNames({
              "p-invalid": submitted && !firma?.asoNombre,
            })}
          />
          {submitted && !firma.asoNombre && (
            <small className="p-invalid">
              Nombre de la persona requerioda.
            </small>
          )}
        </div>
        <div className="p-col-10">
          <label>Tipo de relacion</label>
          <Dropdown
            value={firma?.appTipoRelacionCuenta}
            placeholder="Seleccionar..."
            options={relaciones}
            optionLabel="relNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appTipoRelacionCuenta",
                e.value.relId,
                "relId",
                firma,
                setFirma
              )
            }
            className={classNames({
              "p-invalid": submitted && !firma?.relId,
            })}
          />
          {submitted && !firma.relId && (
            <small className="p-invalid">Seleccione una opcion</small>
          )}
        </div>

        <div className="p-col-1">
          <label>Firma</label>
          <Button
            icon="pi pi-cloud-upload"
            className="p-button-rounded p-button-warning"
            onClick={() => setCargarFirma(true)}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card">{renderForm()}</div>
      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={firma}
          setPersona={setFirma}
          parametro1={"asoId"}
          parametro2={"asoNombre"}
          cantPar={2}
        />
      </Dialog>

      <Dialog
        visible={cargarFirma}
        header="Cargar Firma"
        style={{ width: "52vw" }}
        onHide={() => {
          setCargarFirma(false);
        }}

      >
        <CargarFirmaAso setCargarFirma={setCargarFirma} />
      </Dialog>
    </>
  );
};
