export default function dateLastMonth(){
    const event = new Date();
    event.setMonth(event.getMonth() - 1);

    var mm = event.getMonth();
    var dd = event.getDate() ;
    var yyyy = event.getFullYear();

    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = yyyy + "-" + (mm + 1) + "-" +dd ;
    return myDateString;
}