import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { SelectButton } from "primereact/selectbutton";
import classNames from "classnames";
import { TabView, TabPanel } from "primereact/tabview";
//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import selectText from "../../../../utilities/selecText";
//Servicios
import AppTasaService from "../../../../service/AppServices/AppTasaService";
import AppMonedaService from "../../../../service/AppServices/AppMonedaService";
import AppFrecuencia from "../../../../service/AppServices/AppFrecuencia";
import { SubProductoDeduccion } from "./SubProductoDeduccion";
import { AsiganarCompromiso } from "./AsiganarCompromiso";
export const NuevoSubProducto = ({
  productosHooks,
  subProducto,
  setSubProducto,
  submitted,
  toast,
  footer,
}) => {
  const [productos, setProductos] = useState([]);
  const [appMonedas, setAppMonedas] = useState([]);
  const [appTasa, setAppTasa] = useState([]);
  const [appFrecuencia, setAppFrecuencia] = useState([]);
  let objet = () => {
    return productosHooks.reduce(
      (objet, producto) => {
        if (producto.proId !== 0) {
          objet.producto.push(producto);
        }
        return objet;
      },

      { producto: [] }
    );
  };

  useEffect(() => {
    var producto = objet();
    setProductos(producto?.producto);
    const appFrecuencia = new AppFrecuencia();
    const appTasaService = new AppTasaService();
    const appMonedaService = new AppMonedaService();
    appTasaService.findAllCre().then((response) => {
      setAppTasa(response);
    });

    appMonedaService.findAll().then((response) => {
      setAppMonedas(response);
    });
    appFrecuencia.findAll().then((response) => {
      setAppFrecuencia(response?.data);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function cobraMora(e) {
    let _subProducto = { ...subProducto };
    _subProducto["preSubCobraMora"] = e;

    if (!e) {
      _subProducto["appTasaMora"] = null;
      _subProducto["preSubMoraId"] = null;
      _subProducto["preSubFormaMora"] = 0;
    }
    setSubProducto(_subProducto);
  }

  function renderForm() {
    return (
      <div className="p-grid p-formgrid p-fluid">
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Tipo Moneda</label>
          <Dropdown
            value={subProducto.appMoneda}
            placeholder="Seleccione..."
            options={appMonedas}
            optionLabel="monNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appMoneda",
                e.target.value.monId,
                "preSubMonId",
                subProducto,
                setSubProducto
              )
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubMonId,
            })}
          />
            {submitted && !subProducto.preSubMonId && (
            <small className="p-invalid">Tipo de moneda requerido</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Producto</label>
          <Dropdown
            value={subProducto.appProducto}
            placeholder="Seleccione..."
            options={productos}
            optionLabel="proNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appProducto",
                e.target.value.proId,
                "preSubProId",
                subProducto,
                setSubProducto
              )
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubProId,
            })}
          />
           {submitted && !subProducto.preSubProId && (
            <small className="p-invalid">Producto requerido</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Sub Producto</label>
          <InputText
            id="descripcion"
            value={subProducto.preSubDescripcion}
            placeholder="Sub producto"
            onChange={(e) =>
              onChajeObjN(e, "preSubDescripcion", subProducto, setSubProducto)
            }
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubDescripcion,
            })}
          />
              {submitted && !subProducto.preSubDescripcion && (
            <small className="p-invalid">Sub producto requerido</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Monto Maximo</label>
          <InputText
            id="montmax"
            value={subProducto.preSubMontoMax}
            onChange={(e) =>
              onChajeObjN(e, "preSubMontoMax", subProducto, setSubProducto)
            }
            onClick={(e) => selectText(e)}
            placeholder="Monto maximo"
            type={"number"}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubMontoMax,
            })}
          />
           {submitted && !subProducto.preSubMontoMax && (
            <small className="p-invalid">Monto maximo requerido</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Frecuencia</label>
          <Dropdown
            value={subProducto.appFrecuencia}
            placeholder="Seleccione..."
            options={appFrecuencia}
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appFrecuencia",
                e.target.value.freId,
                "preSubFreId",
                subProducto,
                setSubProducto
              )
            }
            optionLabel="freNombre"
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubFreId,
            })}
          />
             {submitted && !subProducto.preSubFreId && (
            <small className="p-invalid">Frecuencia requerida</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Frecuencia maxima</label>
          <InputText
            id="fremax"
            value={subProducto.preSubPlazoMax}
            onChange={(e) =>
              onChajeObjN(e, "preSubPlazoMax", subProducto, setSubProducto)
            }
            onClick={(e) => selectText(e)}
            placeholder="Frecuencia Maxima"
            type={"number"}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubPlazoMax,
            })}
          />
             {submitted && !subProducto.preSubPlazoMax && (
            <small className="p-invalid">Plazo maximo requerido</small>
          )}
        </div>

        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Dias de gracia</label>
          <InputText
            id="gracia"
            value={subProducto.preSubDiasGracias}
            onChange={(e) =>
              onChajeObjN(e, "preSubDiasGracias", subProducto, setSubProducto)
            }
            onClick={(e) => selectText(e)}
            placeholder="Dias de gracia"
            type={"number"}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubDiasGracias,
            })}
          />
           {submitted && !subProducto.preSubDiasGracias && (
            <small className="p-invalid">Dias de gracia requerido</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Dias de Suspencion</label>
          <InputText
            id="suspencion"
            value={subProducto.preSubDiasSuspencion}
            onChange={(e) =>
              onChajeObjN(
                e,
                "preSubDiasSuspencion",
                subProducto,
                setSubProducto
              )
            }
            placeholder="Dias de suspencion"
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubDiasSuspencion,
            })}
          
          />
            {submitted && !subProducto.preSubDiasSuspencion && (
              <small className="p-invalid">Dias de suspencion requerido</small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Tipo de tasa</label>
          <Dropdown
            value={subProducto.appTasa}
            placeholder="Seleccione..."
            options={appTasa}
            optionLabel="tasNombre"
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appTasa",
                e.target.value.tasId,
                "preSubTasaId",
                subProducto,
                setSubProducto
              )
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubTasaId,
            })}
          />
           {submitted && !subProducto.preSubTasaId && (
              <small className="p-invalid">Tipo de tasa requerido</small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Forma de tasa</label>
          <Dropdown
            value={subProducto.preSubFormaTasa}
            placeholder="Seleccione..."
            options={[
              { label: "FIJA", value: 1 },
              { label: "VARIABLE", value: 2 },
              { label: "LIBRE", value: 3 },
            ]}
            onChange={(e) =>
              setSubProducto({
                ...subProducto,
                preSubFormaTasa: e.target.value,
              })
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubFormaTasa,
            })}
          />
          {submitted && !subProducto.preSubFormaTasa && (
              <small className="p-invalid">Forma de tasa requerido</small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Tasa Maxima</label>
          <InputText
            id="tasmax"
            value={subProducto.preSubTasaMax}
            onChange={(e) =>
              onChajeObjN(e, "preSubTasaMax", subProducto, setSubProducto)
            }
            placeholder="Tasa maxima"
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubTasaMax,
            })}
          />
              {submitted && !subProducto.preSubTasaMax && (
              <small className="p-invalid">Tasa maxima requerida</small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Tasa Minima</label>
          <InputText
            id="tasmin"
            value={subProducto.preSubTasaMin}
            onChange={(e) =>
              onChajeObjN(e, "preSubTasaMin", subProducto, setSubProducto)
            }
            placeholder="Tasa minima"
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubTasaMin,
            })}
          />
            {submitted && !subProducto.preSubTasaMin && (
              <small className="p-invalid">Tasa minima requerida</small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Provicion</label>
          <Dropdown
            value={subProducto.preSubProvision}
            placeholder="Seleccione..."
            options={[
              { label: "DIARIO", value: 1 },
              { label: "MENSUAL", value: 2 },
              { label: "NINGUNA", value: 3 },
            ]}
            onChange={(e) =>
              setSubProducto({
                ...subProducto,
                preSubProvision: e.target.value,
              })
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubProvision,
            })}
          />
              {submitted && !subProducto.preSubProvision && (
              <small className="p-invalid">Provicion requerido </small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Tipo de calculo</label>
          <Dropdown
            value={subProducto.preSubTipoCalculo}
            placeholder="Seleccione..."
            options={[
              { label: "CALENDARIO (365)", value: 1 },
              { label: "COMERCIAL (365)", value: 2 },
              { label: "336", value: 3 },
            ]}
            onChange={(e) =>
              setSubProducto({
                ...subProducto,
                preSubTipoCalculo: e.target.value,
              })
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubTipoCalculo,
            })}
          />
             {submitted && !subProducto.preSubTipoCalculo && (
              <small className="p-invalid">Tipo de calculo requerido </small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Valor de Multa</label>
          <InputText
            id="vamulta"
            value={subProducto.preSubValorMulta}
            onChange={(e) =>
              onChajeObjN(e, "preSubValorMulta", subProducto, setSubProducto)
            }
            placeholder="Valor de multa"
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubValorMulta,
            })}
          />
            {submitted && !subProducto.preSubValorMulta && (
              <small className="p-invalid">Valor de multa requerido </small>
            )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
          <label>Cobra mora</label>
          <SelectButton
            value={subProducto.preSubCobraMora}
            options={[
              { label: "SI", value: true },
              {
                label: "NO",
                value: false,
              },
            ]}
            onChange={(e) => cobraMora(e.target.value)}
          />
           {submitted && !subProducto.preSubCobraMora && (
              <small className="p-invalid">Cobro de mora  requerido </small>
            )}
        </div>
        <div
          className="p-col-12"
          style={{
            display: ` ${subProducto.preSubCobraMora === false ? "none" : ""}`,
          }}
        >
          <div className="p-grid p-formgrid p-fluid">
            <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
              <label>Tasa de Mora</label>
              <Dropdown
                value={subProducto?.appTasaMora}
                placeholder="Seleccione..."
                options={appTasa}
                optionLabel="tasNombre"
                onChange={(e) =>
                  onChangeObjDropDawn(
                    e,
                    "appTasaMora",
                    e.target.value.tasId,
                    "preSubMoraId",
                    subProducto,
                    setSubProducto
                  )
                }
              />
              
            </div>
            <div className="p-col-12 p-md-6 p-lg-6 p-mt-2">
              <label>Forma de tasa mora</label>
              <Dropdown
                value={subProducto.preSubFormaMora}
                placeholder="Seleccione..."
                options={[
                  { label: "INDEPENDIENTE", value: 1 },
                  { label: "SPREAD", value: 2 },
                  { label: "FIJO", value: 3 },
                ]}
                onChange={(e) =>
                  setSubProducto({
                    ...subProducto,
                    preSubFormaMora: e.target.value,
                  })
                }
              />
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-12 p-lg-12 p-mt-2">
          <label>
            Forma de aplicar la distribucion de gastos administrativos
          </label>
          <Dropdown
            value={subProducto.preSubAplicaDeduccion}
            placeholder="Seleccione..."
            options={[
              { label: "FIJA", value: 1 },
              { label: "DISTRIBUIDA", value: 2 },
            ]}
            onChange={(e) =>
              setSubProducto({
                ...subProducto,
                preSubAplicaDeduccion: e.target.value,
              })
            }
            className={classNames({
              "p-invalid": submitted && !subProducto.preSubAplicaDeduccion,
            })}
          />
               {submitted && !subProducto.preSubAplicaDeduccion && (
              <small className="p-invalid">Forma de distribucion requerido </small>
            )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="card">
        <TabView>
          <TabPanel header="Sub Producto">
            {renderForm()}
            <div className="p-mt-4" style={{ textAlign: "right" }}>
              {footer}
            </div>
          </TabPanel>
          <TabPanel header="Asignacion de Compromisos">
            <AsiganarCompromiso subProducto={subProducto} toast={toast} />
          </TabPanel>
          <TabPanel header="Asignacion de decucciones">
            <SubProductoDeduccion  subProducto={subProducto}/>
          </TabPanel>
        </TabView>
      </div>
    </>
  );
};
