import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

//Servicios
import AppTipoIdentificacionService from "../../service/AppServices/AppTipoIdentificacionService";
import CliAsociadoService from "../../service/CliServices/CliAsociadoService";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

//funciones
import selectText from "../../utilities/selecText";
import onChajeObjN from "../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../utilities/onChangeObjDropDawn";

//componentes
import { Pagination } from "../../components/Pagination";
import { InputMask } from "primereact/inputmask";
import { Button } from "primereact/button";

export const CliAsociadoSeleccionar = ({
  setBuscar,
  toast,
  persona,
  setPersona,
  parametro1,
  parametro2,
  parametro3,
  cantPar,
}) => {
  let filtroEmpty = {
    numeroIdentificacion: "",
    codigo: "",
    primerNombre: "",
    segundoNombre: "",
    otroNombre: "",
    primerApellido: "",
    segundoApellido: "",
    nombreJuridico: "",
    estado: "0",
    identificacion: "",
    idIden: 0,
    appTipoIdentificacion: {
      ideTipoDescripcion: "",
      ideTipoFormato: "",
      ideTipoId: 0,
    },
  };
  const [tipoIdentificaciones, setTipoIdentificaciones] = useState(null);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [asociados, setAsociados] = useState([]);
  const [numeroIdentificacion, setNumeroIdentificacion] = useState();
  //States para la paginacion
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  useEffect(() => {
    const appTipoIdentificacionService = new AppTipoIdentificacionService();
    appTipoIdentificacionService.findAll().then((data) => {
      setTipoIdentificaciones(data?.data);
    });
  }, []);

  useEffect(() => {
    peticon();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filtro.estadoAsociado, filtro.idIden]);

  function peticon() {
    const appAsociadoService = new CliAsociadoService();
    appAsociadoService
      .findAdd(
        pageNumber,
        filtro.codigo,
        filtro.primerNombre,
        filtro.segundoNombre,
        filtro.otroNombre,
        filtro.primerApellido,
        filtro.segundoApellido,
        numeroIdentificacion,
        filtro.estadoAsociado,
        filtro.nombreJuridico,
        filtro.appTipoIdentificacion.ideTipoId
      )
      .then((data) => {
        setAsociados(data?.data?.content);
        setFirstPage(data?.data?.first);
        setLastPage(data?.data?.last);
        setTotalPages(data?.data?.totalPages);
      })
      .catch((error) => {
    
      });
  }

  const fil = (evt) => {
    const appAsociadoService = new CliAsociadoService();
    var e = evt;
    var charCode = e.which || e.keyCode;
  
    if (charCode === 9 || charCode === 13) {
      appAsociadoService
        .findAdd(
          pageNumber,
          filtro.codigo,
          filtro.primerNombre,
          filtro.segundoNombre,
          filtro.otroNombre,
          filtro.primerApellido,
          filtro.segundoApellido,
          numeroIdentificacion,
          filtro.estadoAsociado,
          filtro.nombreJuridico,
          filtro.appTipoIdentificacion.ideTipoId
        )
        .then((data) => {
        
          setAsociados(data.data.content);
          setFirstPage(data.data.first);
          setLastPage(data.data.last);
          setTotalPages(data.data.totalPages);
          setPageNumber(0);
        });
    }
  };

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }
  function filtroRender() {
    const estiloFiltro = { fontWeight: "bold", backgroundColor: "#f5f5f5" };

    return (
      <div
        className="p-fluid p-formgrid p-grid p-mb-4"
        onKeyDown={(e) => fil(e)}
      >
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="codigo"
              onClick={(e) => selectText(e)}
              value={filtro.codigo}
              style={estiloFiltro}
              type="number"
              className="p-inputtext-sm "
              onChange={(e) => onChajeObjN(e, "codigo", filtro, setFiltro)}
            />
            <label>Codigo </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <Dropdown
              id="tipoIdentificacion"
              value={filtro.appTipoIdentificacion}
              options={tipoIdentificaciones}
              style={estiloFiltro}
              className="p-inputtext-sm "
              optionLabel="ideTipoDescripcion"
              placeholder="Seleccione..."
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appTipoIdentificacion",
                  e.target.value.ideTipoId,
                  "idIden",
                  filtro,
                  setFiltro
                )
              }
            />
            <label>Tipo de Identificación </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputMask
              id="identificacion"
              value={numeroIdentificacion}
              mask={
                filtro?.appTipoIdentificacion.ideTipoFormato ||
                "9999 99999 9999"
              }
              style={estiloFiltro}
              className="p-inputtext-sm "
              onChange={(e) => setNumeroIdentificacion(e.value)}
            />
            <label>Numero de Identificación </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="otroNombre"
              onClick={(e) => selectText(e)}
              value={filtro.otroNombre}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) => onChajeObjN(e, "otroNombre", filtro, setFiltro)}
            />
            <label> Otro Nombre</label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="perimerNombre"
              onClick={(e) => selectText(e)}
              value={filtro.primerNombre}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "primerNombre", filtro, setFiltro)
              }
            />
            <label>Primer Nombre</label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="segundoNombre"
              onClick={(e) => selectText(e)}
              value={filtro.segundoNombre}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "segundoNombre", filtro, setFiltro)
              }
            />
            <label>Segundo Nombre </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="primerApellido"
              onClick={(e) => selectText(e)}
              value={filtro.primerApellido}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "primerApellido", filtro, setFiltro)
              }
            />
            <label>Primer Apellido </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3 p-mb-4">
          <span className="p-float-label">
            <InputText
              id="segundoApellido"
              onClick={(e) => selectText(e)}
              value={filtro.segundoApellido}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "segundoApellido", filtro, setFiltro)
              }
            />
            <label>Segundo Apellido </label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-9 p-lg-9">
          <span className="p-float-label">
            <InputText
              id="nombreJuridico"
              onClick={(e) => selectText(e)}
              value={filtro.nombreJuridico}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "nombreJuridico", filtro, setFiltro)
              }
            />
            <label>Nombre Juridico</label>
          </span>
        </div>
        <div className="p-field p-col-12 p-md-3 p-lg-3">
          <span className="p-float-label">
            <Dropdown
              value={filtro.estadoAsociado}
              options={[
                { value: "0", label: "Todos" },
                { value: "A", label: "Activos" },
                { value: "I", label: "Inactivos" },
              ]}
              style={estiloFiltro}
              type="text"
              className="p-inputtext-sm "
              onChange={(e) =>
                onChajeObjN(e, "estadoAsociado", filtro, setFiltro)
              }
            />
            <label>Estado </label>
          </span>
        </div>
      </div>
    );
  }
  const codeBodyTemplate = (rowData) => {
    return <>{rowData.estado === "A" ? "ACTIVO" : "INACTVIO"}</>;
  };

  function enviar(rowData) {
    let _persona = { ...persona };
    switch (cantPar) {
      case 2:
        _persona[`${parametro1}`] = rowData.codigo;
        _persona[`${parametro2}`] = rowData.nombre;
        setPersona(_persona);
        break;
      case 3:
        _persona[`${parametro1}`] = rowData.codigo;
        _persona[`${parametro2}`] = rowData.nombre;
        _persona[`${parametro3}`] = rowData.identificacion;
        setPersona(_persona);
        break;
        default:
          _persona[`${parametro1}`] = 0;
          _persona[`${parametro2}`] = "";
          setPersona(_persona);
          break;

    }
  }

  function acciones(rowData) {
    return (
      <div>
        <Button
          icon="pi pi-user-plus"
          className="p-button-rounded p-button-success"
          onClick={() => 
          {  setBuscar(false);
            toast.current.show({
              severity: "success",
              summary: "Tarea Realizada con exito",
              detail: "Persona agregada",
              life: 4000,
            });
            enviar(rowData)
          }}
        />
      </div>
    );
  }
  function renderTable() {
    return (
      <>
        <DataTable
          value={asociados}
          className="datatable-responsive"
          emptyMessage="No se encontraron asociados."
          footer={renderPaginacion()}
        >
          <Column header="Codigo" field="codigo" />
          <Column header="Nombre" field="nombre" />
          <Column header="Identificacion" field="identificacion" />
          <Column header="Estado" body={codeBodyTemplate} />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }

  return (
    <div className="card">
      {filtroRender()}
      {renderTable()}
    </div>
  );
};
