//import axios from "axios";

class Api {
  baseUrl = () => {
    //return 'http://localhost:8090/sgc/api'
    //return "http://10.10.103.22:8090/sgc/api";
    return "https://api.mycoop.io/sgc/api";
    //return "https://test.mycoop.io/sgc/api";
  };

  async getToken(usr) {
    try {
      let config = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(usr),
      };

      let res = await fetch(this.baseUrl() + "/auth/authenticate", config);
      let json = await res.json();

      return json;
    } catch (err) {
      console.log("Error al obtener token: " + err);
    }
  }
  /*sgc: {
    getJwToken() {
      return axios
        .post(baseUrl + "auth/authenticate", data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          console.log(response.data);
        });
    },
  },*/
}

export default Api;
