import { InputText } from "primereact/inputtext";
import onChajeObjN from "../../../utilities/onChajeObjN";
import { InputTextarea } from "primereact/inputtextarea";
import selectText from "../../../utilities/selecText";

export const AppNuevaConLibreta = ({
  libreta,
  setLibreta,
  submitted,
  setSubmitted,
}) => {
  return (
    <>
      <div className="card">
        <div className="p-grid p-fluid">
          <div className="p-col">
            Descripcion
            <InputTextarea
              id="des"
              autoResize
              placeholder="Ingrese una descripcion"
              value={libreta?.ahoLibDescripcion}
              onChange={(e) =>
                onChajeObjN(e, "ahoLibDescripcion", libreta, setLibreta)
              }
              rows={3}
              className={
                submitted && !libreta?.ahoLibDescripcion ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibDescripcion && (
              <small className="p-invalid">Descripcion requerida.</small>
            )}
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            Cantidad de paginas
            <InputText
              id="ahoLibNoPagina"
              value={libreta?.ahoLibNoPagina}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibNoPagina", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibNoPagina ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibNoPagina && (
              <small className="p-invalid">Numero de paquina requerido.</small>
            )}
          </div>
          <div className="p-col">
            Encabezado columnas
            <InputText
              id="ahoLibEncColumna"
              value={libreta?.ahoLibEncColumna}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibEncColumna", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibEncColumna ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibEncColumna && (
              <small className="p-invalid">Columnas reuqeridas.</small>
            )}
          </div>
          <div className="p-col">
            Encabezado filas
            <InputText
              id="ahoLibEncLinea"
              value={libreta?.ahoLibEncLinea}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibEncLinea", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibEncLinea ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibEncLinea && (
              <small className="p-invalid">Lineas requeridas.</small>
            )}
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            Margen superior pagina 1
            <InputText
              id="ahoLibMargenP1"
              value={libreta?.ahoLibMargenP1}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibMargenP1", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibMargenP1 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibMargenP1 && (
              <small className="p-invalid">Margen requerido.</small>
            )}
          </div>
          <div className="p-col">
            Margen superior pagina 2
            <InputText
              id="ahoLibMargenP2"
              value={libreta?.ahoLibMargenP2}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibMargenP2", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibMargenP2 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibMargenP2 && (
              <small className="p-invalid">Margen requerido</small>
            )}
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            Columnas pagina 1
            <InputText
              id="ahoLibDetColumnaP1"
              value={libreta?.ahoLibDetColumnaP1}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibDetColumnaP1", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibDetColumnaP1 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibDetColumnaP1 && (
              <small className="p-invalid">Columna requerido</small>
            )}
          </div>
          <div className="p-col">
            Columnas pagina 2
            <InputText
              id="ahoLibDetColumnaP2"
              value={libreta?.ahoLibDetColumnaP2}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibDetColumnaP2", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibDetColumnaP2 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibDetColumnaP2 && (
              <small className="p-invalid">Columna requerdia.</small>
            )}
          </div>
        </div>
        <div className="p-grid p-fluid">
          <div className="p-col">
            Filas pagina 1
            <InputText
              id="ahoLibLineasP1"
              value={libreta?.ahoLibLineasP1}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibLineasP1", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibLineasP1 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibLineasP1 && (
              <small className="p-invalid">Lineas requeridas.</small>
            )}
          </div>
          <div className="p-col">
            Filas pagina 2
            <InputText
              id="ahoLibLineasP2"
              value={libreta?.ahoLibLineasP2}
              type="number"
              onChange={(e) =>
                onChajeObjN(e, "ahoLibLineasP2", libreta, setLibreta)
              }
              className={
                submitted && !libreta?.ahoLibLineasP2 ? "p-invalid" : ""
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !libreta?.ahoLibLineasP2 && (
              <small className="p-invalid">Filas requeridas.</small>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
