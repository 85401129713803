import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//servicios
import AppTasaService from "../../../../service/AppServices/AppTasaService";
import AppTipoTasaService from "../../../../service/AppServices/AppTipoTasaService";

// componentes
import { Pagination } from "../../../../components/Pagination";
import { NuevaTasaInteres } from "./NuevaTasaInteres";
import { TasaRango } from "./TasaRangoPages/TasaRango";
import { TasaUnica } from "./TasaUnica/TasaUnica";
//funciones
import formatDate from "../../../../utilities/formatDateTable";
import { Dropdown } from "primereact/dropdown";
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
export const TasaInteres = () => {
  let filtroEmpty = {
    tasNombre: "",
    tasTipoId: 0,
 
  };

  let tasaInteresEmpty = {
    appTipoTasa: {
      tasTipoId: 0,
      tasTipoNombre: ""
    },
    tasFechaIngreso: "",
    tasId: 0,
    tasNombre: "",
    tasTipoId: 0,
    tasUnica: "",
  };

  const toast = useRef();
  const [nuevoTasaInteres, setNuevoTasaInteres] = useState(tasaInteresEmpty);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [tasasInteres, setTasasInteres] = useState([]);
  const [ setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevaTasaInteres, setNuevaTasaInteres] = useState(false);
  const [estado, setEstado] = useState(false);
  const [nuevoRango, setNuevoRango] = useState(false);
  const [nuevoUnico, setNuevoUnico] = useState(false);
  const [tasas, setTasas] = useState([]);

  useEffect(() => {
    const appTipoTasaService = new AppTipoTasaService();
    appTipoTasaService.findAll().then((response) => {
      response?.push({
        tasTipoId: 0,
        tasTipoNombre: "TODOS",
      });
      setTasas(response);
    });
  }, []);


  useEffect(() => {
    const appTasaService = new AppTasaService();
    appTasaService.findByPage(pageNumber, filtro.tasNombre, filtro.tasTipoId).then((res) => {
 
      setTasasInteres(res?.content);
      setTotalElements(res?.totalElements);
      setTotalPages(res?.totalPages);
      setPageNumber(res?.number);
      setFirstPage(res?.first);
      setLastPage(res?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado,filtro.tasTipoId]);

  const acciones = (rowData) => {
    return (
      <div>
        {rowData.tasUnica === "R" ? (
          <Button
            className="p-button-warning p-button-rounded p-mr-2"
            icon="pi pi-sliders-h"
            tooltip="Tasa rango"
            onClick={() => {setNuevoRango(true); setNuevoTasaInteres(rowData)}}
          />
        ) : (
          <Button
            className="p-button-warning p-button-rounded p-mr-2"
            icon="pi pi-ellipsis-v"
            tooltip="Tasa unica"
            onClick={() => {setNuevoUnico(true); setNuevoTasaInteres(rowData)}}
          />
        )}

        <Button
          className="p-button-default p-button-rounded"
          icon="pi pi-eye"
          tooltip="ver"
          onClick={() => {
            setNuevoTasaInteres(rowData);setNuevaTasaInteres(true)
          }}
        />
      </div>
    );
  };

  function formatDateBody(rowData) {
    return formatDate(rowData?.tasFechaIngreso);
  }
  function tasaAplicacion(rowData) {
    return <>{rowData.tasUnica === "U" ? "UNICA" : "RANGO"}</>;
  }

  function renderTabla() {
    return (
      <>
        <DataTable
          value={tasasInteres}
          emptyMessage={"No se enontro ningun tipo de tasa para ahorro"}
          footer={renderPaginacion()}
        >
          <Column
            field="tasFechaVigencia"
            body={formatDateBody}
            header="Fecha Creada"
          />
          <Column field="tasId" header="Codigo" />
          <Column field="appTipoTasa.tasTipoNombre" header="Tipo de Tasa" />
          <Column field="tasNombre" header="Descripcion" />
          <Column field="tasUnica" header="Tasa por " body={tasaAplicacion} />

          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }
  function right(){
    return (
      <>
      <div className="p-fluid p-grid p-formgrid">
      <div className="p-col" style={{textAlign:"right"}}>
          <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-button-sm"
        style={{width: "100px"}}
          onClick={() => {
            setNuevaTasaInteres(true); setNuevoTasaInteres(tasaInteresEmpty)
          }}
        /></div>

      </div>
      </>
    )
  }

  function left() {
    return (
      <>
        <div className="p-fluid p-grid p-formgrid p-mt-2 " onKeyDown={(e) => fil(e)}>
          <div className="p-col">
          <span className="p-float-label"> 

           
              
              <InputText
              className="p-inputtext-sm"
                value={filtro.tasNombre}
                type="search"
                onChange={(e) => {
                  setFiltro({
                    ...filtro,
                    tasNombre: e.target.value.toUpperCase(),
                  });
                }}
       
              />
         
            <label >Buscar...</label>
            </span>
          </div>
          <div className="p-col">
          <span className="p-float-label">
            <Dropdown
            id="dropdown"
            value={filtro?.appTipoTasa}
            options={tasas}
            optionLabel="tasTipoNombre"
            style={{height: "30px"}}
            onChange={(e) =>
              onChangeObjDropDawn(
                e,
                "appTipoTasa",
                e.target.value.tasTipoId,
                "tasTipoId",
                filtro,
                setFiltro
              )
            }
            />
            <label htmlFor="dropdown">Tipo de tasa</label>
            </span>
          </div>
      
    
        </div>
      </>
    );
  }

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const appTasaService = new AppTasaService();
      appTasaService.findByPage(pageNumber, filtro.tasNombre, 2).then((res) => {
        setTasasInteres(res?.content);
        setTotalElements(res?.totalElements);
        setTotalPages(res?.totalPages);
        setPageNumber(res?.number);
        setFirstPage(res?.first);
        setLastPage(res?.last);
      });
      if (filtro.tasNombre !== "") {
        setPageNumber(0);
      }
    }
  };


  return (
    <>

      <div className="card"> 
   

       <Toolbar className="p-mb-4" left={left} right={ right}></Toolbar>

    
      {renderTabla()}</div>

      <Dialog
        visible={nuevaTasaInteres}
        header={
          nuevoTasaInteres?.tasId !== 0
            ? "Edita Tasa de Interes "
            : "Nueva Tasa de Interes "
        }
        onHide={() => 
        {  setNuevaTasaInteres(false);
          toast.current.show({
            severity: "warn",
            summary: "Error",
            detail: "Pestaña cerrada",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
      >
        <NuevaTasaInteres
          setNuevaTasaInteres={setNuevaTasaInteres}
          nuevoTasaInteresHook={nuevoTasaInteres}
          setNuevoRango={setNuevoRango}
          setEstado={setEstado}
          estado={estado}
          toast={toast}
          setNuevoUnico={setNuevoUnico}
          tasasHook={tasas}
        />
      </Dialog>

      <Dialog
        visible={nuevoRango}
        header="Rango cuenta"
        onHide={() => 
         { setNuevoRango(false);
          toast.current.show({
            severity: "warn",
            summary: "Error",
            detail: "Pestaña cerrada",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
      >
        <TasaRango
          setNuevoRango={setNuevoRango}
          nuevoTasaInteres={nuevoTasaInteres}
          toast={toast}
        />
      </Dialog>
      <Dialog
        visible={nuevoUnico}
        header="Tasa unica"
        onHide={() => setNuevoUnico(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
      >
        <TasaUnica
          setNuevoUnico={setNuevoUnico}
          nuevoTasaInteres={nuevoTasaInteres}
        />
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};
