import React, { useEffect, useState } from "react";
import jsPDF from "jspdf";
import { Button } from "primereact/button";
import EventoParticipanteService from "../../../../service/AsambleaServices/EventoParticipanteService";
export const AsambleaReport = ({ evento }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (evento?.eveId !== 0) {
      const eventoParticipante = new EventoParticipanteService();
      eventoParticipante.getByEvenId(evento?.eveId).then((res) => {
        setData(res);
      });
    }
  }, [evento]);

  const pdfReport = () => {
    var doc = new jsPDF("p", "pt", "a4");
    var rows1 = [];
    var i = 1;
    data?.forEach((element) => {
      var temp1 = [
        i,
        element.code,
        element.name,
        element.dpi,
        element.gender,
        element.branch,
      ];
      i++;
      rows1.push(temp1);
    });

    doc.autoTable({
      theme: "plain",
      body: rows1,
      head: [["ID","CODIGO", "NOMBRE", "DPI", "SEXO", "SUCURSAL"]],
      //     styles: { fontWeight: "" },
      // startY: 90,
      columnStyles: {
        0: { cellWidth: 25 },
        1: { cellWidth: 46 },
        2: { cellWidth: 250 },
        3: { cellWidth: 80 },
        4: { cellWidth: 65 },
        5: { cellWidth: 70 },
      },

      margin: { horizontal: 60, top: 110, bottom: 85 },

      bodyStyles: {
        minCellHeight: 1,
        cellPadding: 1,
        fontSize: 9,
 //      fontStyle: "bold",
        textColor: "black",
        Padding: {
          top: 2,
          bottom: 2,
          right: 3,
        },
      },
      headStyles: {
        textAlign:"left",
        fontSize: 9,
      },
    });
    var nombreEve = evento?.eveNombre?.split(" ").join("_");
    doc.save(nombreEve + ".pdf");
  };

  return (
    <>
      <Button
        style={{ width: "100px" }}
        icon="pi pi-print"
        className="p-button-secondary"
        label="Reporte"
        onClick={() => pdfReport()}
        disabled={evento?.eveId === 0 || data?.length === 0}
      />
    </>
  );
};
