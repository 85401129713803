import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useRef, useState } from "react";
import { Messages } from "primereact/messages";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
//servicios
import AppTasaService from "../../../../service/AppServices/AppTasaService";

//funciones
import dateNow from "../../../../utilities/dateNow";
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
export const NuevaTasaInteres = ({
  setNuevaTasaInteres,
  nuevoTasaInteresHook,
  setNuevoRango,
  setEstado,
  estado,
  toast,
  setNuevoUnico,
  tasasHook,
}) => {
  const message = useRef();
  const [nuevoTasaInteres, setNuevoTasaInteres] =
    useState(nuevoTasaInteresHook);
  const [submitted, setSubmitted] = useState(false);
  const [tasas, setTasas] = useState([]);

  let objet = () => {
    return tasasHook.reduce(
      (objet, producto) => {
        if (producto.tasTipoId !== 0) {
          objet.tasasObj.push(producto);
        }
        return objet;
      },

      { tasasObj: [] }
    );
  };

  useEffect(() => {
    var producto = objet();
    setTasas(producto?.tasasObj);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeNuevo = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _nuevoTasaInteres = { ...nuevoTasaInteres };
    _nuevoTasaInteres[`${name}`] = val.toUpperCase();

    setNuevoTasaInteres(_nuevoTasaInteres);
  };

  function renderForm() {
    return (
      <>
        <div className="p-grid">
          <div className="p-fluid p-col">
            <label>Nombre</label>
            <InputText
              value={nuevoTasaInteres?.tasNombre}
              onChange={(e) => onChangeNuevo(e, "tasNombre")}
              className={classNames({
                "p-invalid": submitted && !nuevoTasaInteres?.nuevoTasaInteres,
              })}
              disabled={nuevoTasaInteres?.tasId !== 0}
              placeholder="Nombre de la tasa que se utilizara"
            />
            {submitted && !nuevoTasaInteres.nuevoTasaInteres && (
              <small className="p-invalid">Nombre de la tasa requerida</small>
            )}
          </div>
        </div>
        <div className="p-grid">
          <div className="p-fluid p-col">
            <label>Tasa Unica/Rango</label>
            <Dropdown
              value={nuevoTasaInteres?.tasUnica}
              options={[
                { value: "U", label: "UNICA" },
                { value: "R", label: "RANGO" },
              ]}
              onChange={(e) => onChangeNuevo(e, "tasUnica")}
              className={classNames({
                "p-invalid": submitted && !nuevoTasaInteres?.tasUnica,
              })}
              disabled={nuevoTasaInteres?.tasId !== 0}
              placeholder="Selecione una opcion..."
            />

            {submitted && !nuevoTasaInteres.tasUnica && (
              <small className="p-invalid">Seleccione una opcion...</small>
            )}
          </div>

          <div className="p-fluid p-col">
            <label>Tipo de tasa</label>
            <Dropdown
              value={nuevoTasaInteres?.appTipoTasa}
              options={tasas}
              optionLabel="tasTipoNombre"
              className={classNames({
                "p-invalid": submitted && !nuevoTasaInteres?.tasTipoId,
              })}
              disabled={nuevoTasaInteres?.tasId !== 0}
              placeholder="Selecione una opcion..."
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appTipoTasa",
                  e.target.value.tasTipoId,
                  "tasTipoId",
                  nuevoTasaInteres,
                  setNuevoTasaInteres
                )
              }
            />
            {submitted && !nuevoTasaInteres.tasTipoId && (
              <small className="p-invalid">Seleccione una opcion...</small>
            )}
          </div>
        </div>
      </>
    );
  }

  function onSave() {
    if (nuevoTasaInteres?.tasNombre && nuevoTasaInteres?.tasUnica !== "") {
      const appTasa = new AppTasaService();
      let _nuevoTasaInteres = { ...nuevoTasaInteres };
      _nuevoTasaInteres[`tasFechaIngreso`] = dateNow();
      appTasa.save(_nuevoTasaInteres).then(() => {
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Tasa creada",
          life: 4000,
        });
        setNuevaTasaInteres(false);

        nuevoTasaInteres.tasUnica === "R"
          ? setNuevoRango(true)
          : setNuevoUnico(true);

        setEstado(!estado);
      });
    } else {
      setSubmitted(true);
      setTimeout(gfg, 4000);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }

  function gfg() {
    setSubmitted(false);
  }
  return (
    <>
      <div className="card">{renderForm()}</div>
      <Messages ref={message} />
      <div style={{ textAlign: "right" }}>
        <Button
          className="p-button-danger p-mr-2"
          label="Cancelar"
          onClick={() => 
          {  setNuevaTasaInteres(false);
            toast.current.show({
              severity: "warn",
              summary: "Error",
              detail: "Pestaña cerrada",
              life: 4000,
            })
          }}
        />

        {nuevoTasaInteres?.tasId === 0 ? (
          <Button label="Guardar" onClick={() => onSave()} />
        ) : nuevoTasaInteres.tasUnica === "R" ? (
          <Button
            label="Rango de Tasa"
            onClick={() => {setNuevoRango(true); setNuevaTasaInteres(false)}}
          />
        ) : (
          <Button
            label="Tasas unicas"
            onClick={() => {setNuevoUnico(true); setNuevaTasaInteres(false)}}
          />
        )}
      </div>
    </>
  );
};
