import React, { useEffect } from 'react'
import { Route, useLocation, withRouter, Redirect } from 'react-router-dom'
import App from './App'
import { Error } from './pages/Error'
import { NotFound } from './pages/NotFound'
import { Access } from './pages/Access'
import { Login } from './pages/Login'
import useAuthExpired from './utilities/useAuthExpired'

const AppWrapper = () => {
  let location = useLocation()
  const isAuth = useAuthExpired()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  switch (location.pathname) {
    case '/login':
      if (localStorage.getItem('token') !== null && isAuth) {
        return <Redirect to="/" />
      }
      return <Route exact path="/login" component={Login} />
    case '/error':
      return <Route exact path="/error" component={Error} />
    case '/notfound':
      return <Route exact path="/notfound" component={NotFound} />
    case '/access':
      return <Route exact path="/access" component={Access} />
    default:
      return <App />
  }
}

export default withRouter(AppWrapper)
