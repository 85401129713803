import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import { Dialog } from "primereact/dialog";
import { CliAsociadoSeleccionar } from "../ClientesPages/CliAsociadoSeleccionar";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
import TallerPaymentCardService from "../../service/CajaService/TallerPaymentCardService";
import CliAsociadoService from "../../service/CliServices/CliAsociadoService";
import selectText from "../../utilities/selecText";
import TransaccionTokenService from "../../service/CajaService/TransaccionTokenService";
import dateTimeNow from "../../utilities/dateTimeNow";
import generateGUID from "../../utilities/generateGUID";
import { InputMask } from "primereact/inputmask";
import classNames from "classnames";
import { ProgressSpinner } from "primereact/progressspinner";
import CjaTransaccionService from "../../service/CajaService/CjaTransaccionService";
export const Transaccion = () => {
  const toast = useRef();
  const message = useRef();
  let transaccionEmpty = {
    numeroTransaccion: 0,
    moneda: "320",
    monto: 0,
    asociado: 0,
    nombreAsociado: "",
    numeroDococumento: 0,
    montoCheque: 0,
    referencia: "",
    numeroTarjeta: "",
    montoDolares: 0,
    fechaExpira: 0,
  };

  const [transaccion, setTransaccion] = useState(transaccionEmpty);
  const [buscarAso, setBuscarAso] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [multi, setMulti] = useState("1");
  const [sif, setSif] = useState("1");
  const [transacciones, setTransacciones] = useState([]);

  useEffect(() => {
    const cjaTransaccionService = new CjaTransaccionService();
    cjaTransaccionService.findAll().then((res) => {
      var nTransacciones = [];

      res?.forEach((element) => {
        var nTransaccion = {
          value: element?.codigo,
          label: element?.descripcion,
        };

        nTransacciones.push(nTransaccion);
      });

      setTransacciones(nTransacciones);
    });
  }, []);

  //   useEffect(()=>{
  //     const user =JSON.parse( localStorage.getItem("user"))
  //     console.log(user)
  //   },[])

  //     useEffect(()=>{
  //       const service = new TransaccionTokenService();
  //       service.consulta().then((resSif)=>{

  //          if(resSif?.status == 200){
  //   console.log(resSif)

  //          }
  //       })

  //       //    const tallerPaymentCardService = new TallerPaymentCardService();
  //       //    tallerPaymentCardService.saveSif("ASDF","ASDFA","ASDF").then((res)=>{
  //       //        console.log(res,"1")
  //       //    })

  //     },[])

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelsar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscarAso(false)}
        />
      </>
    );
  }

  // const fil = (evt) => {
  //   var e = evt;
  //    charCode = e.which || e.keyCode;
  //   if (charCode == 9 || charCode == 13) {
  //     dataCli();
  //   }
  // };

  function asoNombre() {
    
      if (transaccion?.asociado !== 0 ) {
        dataCli();
      }
  }

 
  const dataCli = () => {

    const cliAsociadoService = new CliAsociadoService();
    cliAsociadoService.findByIdClau(transaccion?.asociado).then((res) => {
      if (res === undefined || res === false) {
        toast.current.show({
          severity: "warn",
          summary: "error",
          content: "Asociado no encontrado",
          life: 4000,
        });
        let _transaccion = { ...transaccion };
        _transaccion[`nombreAsociado`] = "";
        _transaccion[`asociado`] = 0;

        setTransaccion(_transaccion);
      } else {
        var nombre = res?.name || "";
        let _transaccion = { ...transaccion };
        _transaccion[`nombreAsociado`] = nombre;

        setTransaccion(_transaccion);
      }
    });
  }

  function partirCadenaNumeros(cadenaNumeros) {
    const longitud = cadenaNumeros.length;
    const mitad = Math.floor(longitud / 2);

    const primeraParte = cadenaNumeros.slice(0, mitad);
    const segundaParte = cadenaNumeros.slice(mitad);

    const nuevaCadena = segundaParte + primeraParte;
    return nuevaCadena;
  }

  function save() {
    var cardPago;
    if (
      transaccion.asociado !== 0 &&
      transaccion.monto !== 0 &&
      transaccion.numeroDococumento !== 0 &&
      transaccion.referencia !== "" &&
      transaccion.numeroTarjeta !== "" &&
      transaccion.numeroTransaccion !== 0
    ) {
      setDialog(true);
      const service = new TransaccionTokenService();
      service.consulta().then((res) => {
     
        if (res?.status === 200) {
          const tallerPaymentCardService = new TallerPaymentCardService();
          if (transaccion.numeroTransaccion === 5334) {
             cardPago = {
              identifier: generateGUID(),
              account: {
                number: transaccion.numeroTarjeta.replace(/\s/g, ""),
                expiration: transaccion.fechaExpira,
              },
              amount: Number(transaccion.monto),
              currency: `${transaccion.moneda}`,
              timestamp: dateTimeNow(),
            };

            tallerPaymentCardService
              .save(cardPago, res?.data?.access_token)
              .then((res) => {
               
                if (res?.status === 200 && res?.data?.authorization !== "") {
                  setMulti("2");
                  toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: "Transaccion realizada con exito",
                    life: 3000,
                  });
                  tokenSifco(res?.data?.authorization);
                } else {
                  setMulti("3");
                  setSif("3");
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail:
                      res?.data?.response_description ||
                      "OCURRIO UN ERROR PORFAVOR DE COMUNICARSE CON EL DEPARTAMENTO DE SOPORTE",
                    life: 3000,
                  });
                }
              });
          } else {
             cardPago = {
              identifier: generateGUID(),
              account: {
                number: transaccion.numeroTarjeta.replace(/\s/g, ""),
                expiration: partirCadenaNumeros(transaccion.fechaExpira),
              },
              amount: Number(transaccion.monto),
              currency: `${transaccion.moneda}`,
              timestamp: dateTimeNow(),
              transaction_code: "028001",
            };

            tallerPaymentCardService
              .saveRetiro(cardPago, res?.data?.access_token)
              .then((res) => {
             
                if (res?.status === 200 && res?.data?.authorization !== "") {
                  setMulti("2");
                  toast.current.show({
                    severity: "success",
                    summary: "Success",
                    detail: res?.data?.response_description||"Transaccion realizada con exito",
                    life: 3000,
                  });
                  tokenSifco(res?.data?.authorization);
                } else {
                  setMulti("3");
                  setSif("3");
                  toast.current.show({
                    severity: "error",
                    summary: "Error",
                    detail:
                      res?.data?.response_description ||
                      "OCURRIO UN ERROR PORFAVOR DE COMUNICARSE CON EL DEPARTAMENTO DE SOPORTE",
                    life: 3000,
                  });
                }
              });
          }
        } else {
          setMulti("3");
          setSif("3");
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              "OCURRIO UN ERROR PORFAVOR DE COMUNICARSE CON EL DEPARTAMENTO DE SOPORTE",
            life: 3000,
          });
        }

        setSubmitted(false);
      });
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "VERIFIQUE QUE LOS CAMPOS ESTEN COMPLETOS",
        life: 3000,
      });
      setSubmitted(true);
    }
  }

  function tokenSifco(authorization) {
    const service = new TransaccionTokenService();
    service.tokenSifco().then((resSif) => {
  
      if (resSif?.status === 200) {
        pallSifco(authorization, resSif?.data?.access_token);
      } else {
        setSif("3");
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            resSif?.data?.response_description ||
            "OCURRIO UN ERROR PORFAVOR DE COMUNICARSE CON EL DEPARTAMENTO DE SOPORTE",
          life: 3000,
        });
      }
    });
  }

  function pallSifco(authorization, access_token) {
    // setTransaccion(transaccionEmpty);
    const tallerPaymentCardService = new TallerPaymentCardService();
    tallerPaymentCardService
      .saveSif(transaccion, authorization, access_token)
      .then((res) => {
        if (res?.status === 200 && res?.data?.Resultado !== false) {
          setSif("2");
          toast.current.show({
            severity: "success",
            summary: "Exito",
            detail: "TRANSACCIO REALIZADA CON EXITO EN SIFCO",
            life: 3000,
          });
        } else {
          setSif("3");
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail:
              res?.data.Mensaje ||
              "OCURRIO UN ERROR EN SIFCO FAVOR DE REALIZAR LA TRANSACCION MANUAL",
            life: 3000,
          });
        }
      });
  }

  function restablecerEstado() {
    setDialog(false);
    setTransaccion(transaccionEmpty);
    setMulti("1");
    setSif("1");
  }
  return (
    <>
      <div className="p-fluid card">
        <div className="p-grid p-mt-3 p-mr-3 p-ml-3">
          <Divider>
            <div>
              <span className="overview-icon p-mr-2 ">
                <i className="pi pi-dollar "></i>
              </span>
              <span className="overview-title">Transacción</span>
            </div>
          </Divider>
          <div className="p-col">
            {" "}
            <h5>Transacción</h5>{" "}
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <div className="p-inputgroup">
              <Dropdown
                placeholder="Seleccione una Opción"
                style={{ textAlign: "right" }}
                //   options={[
                //     { value: 5334, label: "DEPOSITO TARJETA DE DEBITO" },
                //     {
                //       value: 5335,
                //       label: "RETIRO TARJETA DE DEBITO",
                //     },
                //   ]
                // }

                options={transacciones}
                className={classNames(
                  {
                    "p-invalid": submitted && !transaccion?.numeroTransaccion,
                  },
                  "p-inputtext-sm"
                )}
                value={transaccion.numeroTransaccion}
                onChange={(e) =>
                  setTransaccion({
                    ...transaccion,
                    numeroTransaccion: e.target.value,
                  })
                }
              />

              {/* <Button
                icon="pi pi-search"
                className="p-button-info"
                disabled={true}
              /> */}
            </div>
            {submitted && !transaccion?.numeroTransaccion && (
              <small className="p-invalid">Escoja una Transacción.</small>
            )}
            <Messages ref={message} />
          </div>
          {/* <div className="p-col">
            <InputText
              className={"p-inputtext-sm"}
              value={transaccion.nombreTransaccion}
              disabled={true}
              style={{
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
                textAlign: "right",
              }}
            />
          </div> */}
          <div className="p-col-2"></div>
        </div>
        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            {" "}
            <h5>Moneda</h5>{" "}
          </div>{" "}
          <div className="p-col-5">
            <Dropdown
              style={{ textAlign: "right" }}
              className="p-inputtext-sm"
              options={[
                { label: "QUETZALES GTQ", value: "320" },
                { label: "DOLARES US", value: "840" },
              ]}
              value={transaccion.moneda}
              onChange={(e) =>
                setTransaccion({ ...transaccion, moneda: e.target.value })
              }
            />
          </div>
          <div className="p-col-2"></div>
        </div>
        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Asociado</h5>{" "}
          </div>

          <div className="p-col-2 " style={{ textAlign: "right" }}>
            <div
              className="p-inputgroup"
             // onKeyDown={(e) => fil(e)}
            >
              <InputText
                id="codigo"
                style={{ textAlign: "right" }}
                className={classNames(
                  {
                    "p-invalid": submitted && !transaccion?.asociado,
                  },
                  "p-inputtext-sm"
                )}
                value={transaccion.asociado}
                onChange={(e) =>
                  setTransaccion({ ...transaccion, asociado: e.target.value })
                }
                onBlur={() => asoNombre()}
                onClick={(e) => selectText(e)}
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setBuscarAso(true)}
              />
            </div>
            {submitted && !transaccion?.asociado && (
              <small className="p-invalid">Codigo Requerido.</small>
            )}
          </div>
          <div className="p-col-3" style={{ textAlign: "right" }}>
            <InputText
              style={{
                fontWeight: "bold",
                backgroundColor: "#f5f5f5",
                textAlign: "right",
              }}
              className={classNames(
                {
                  "p-invalid": submitted && !transaccion?.nombreAsociado,
                },
                "p-inputtext-sm"
              )}
              disabled={true}
              value={transaccion.nombreAsociado}
            />
            {submitted && !transaccion?.nombreAsociado && (
              <small className="p-invalid">Nombre Requerido.</small>
            )}
          </div>

          <div className="p-col-2"></div>
        </div>
      </div>
      <div className="p-fluid card">
        <div className="p-grid p-mt-3 p-mr-3 p-ml-3">
          <Divider>
            <div>
              <span className="overview-icon p-mr-2 ">
                <i className="pi pi-dollar "></i>
              </span>
              <span className="overview-title">Datos de Transacción</span>
            </div>
          </Divider>
          <div className="p-col">
            <h5>Número de Documento</h5>
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <InputText
              id="noDoc"
              value={transaccion.numeroDococumento}
              style={{ textAlign: "right" }}
              className={classNames(
                {
                  "p-invalid": submitted && !transaccion?.numeroDococumento,
                },
                "p-inputtext-sm"
              )}
              type="number"
              onChange={(e) =>
                setTransaccion({
                  ...transaccion,
                  numeroDococumento: e.target.value,
                })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !transaccion?.numeroDococumento && (
              <small className="p-invalid">
                Numero de Documento Requerido.
              </small>
            )}
          </div>
          <div className="p-col-2"></div>
        </div>

        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Monto en Efectivo</h5>
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <InputText
              id="monto"
              value={transaccion.monto}
              style={{ textAlign: "right" }}
              className={classNames(
                {
                  "p-invalid":
                    submitted &&
                    transaccion?.montoCheque === 0 &&
                    !transaccion?.monto,
                },
                "p-inputtext-sm"
              )}
              type="number"
              onChange={(e) =>
                setTransaccion({ ...transaccion, monto: e.target.value })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted &&
              transaccion?.montoCheque === 0 &&
              !transaccion?.monto && (
                <small className="p-invalid">Monto Requerido</small>
              )}
          </div>
          <div className="p-col-2"></div>
        </div>
        {/* <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Monto en cheque</h5>{" "}
          </div>

          <div className="p-col" style={{ textAlign: "right" }}>
            <InputText
              id="montoCheque"
              value={transaccion.montoCheque}
              className={classNames(
                {
                  "p-invalid":
                    submitted &&
                    transaccion?.monto == 0 &&
                    !transaccion?.montoCheque,
                },
                "p-inputtext-sm"
              )}
              style={{ textAlign: "right" }}
              type="number"
              onChange={(e) =>
                setTransaccion({ ...transaccion, montoCheque: e.target.value })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted &&
              transaccion?.monto == 0 &&
              !transaccion?.montoCheque && (
                <small className="p-invalid">
                  Numero de cheque Requerido, si no existe un monto
                </small>
              )}
          </div>
          <div className="p-col-3"></div>
        </div> */}
        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Referencia</h5>{" "}
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <InputText
              id="referencia"
              style={{ textAlign: "right" }}
              value={transaccion.referencia}
              className={classNames(
                {
                  "p-invalid": submitted && !transaccion?.referencia,
                },
                "p-inputtext-sm"
              )}
              onChange={(e) =>
                setTransaccion({
                  ...transaccion,
                  referencia: e.target.value.toUpperCase(),
                })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !transaccion?.referencia && (
              <small className="p-invalid">Referencia Requerida</small>
            )}
          </div>
          <div className="p-col-2"></div>
        </div>
        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Número de Tarjeta</h5>{" "}
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <InputMask
              mask="9999 9999 9999 9999"
              placeholder="9999 9999 9999 9999"
              id="numeroTarjeta"
              style={{ textAlign: "right" }}
              value={transaccion.numeroTarjeta}
              className={classNames(
                {
                  "p-invalid": submitted && !transaccion?.numeroTarjeta,
                },
                "p-inputtext-sm"
              )}
              onChange={(e) =>
                setTransaccion({
                  ...transaccion,
                  numeroTarjeta: e.target.value,
                })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !transaccion?.numeroTarjeta && (
              <small className="p-invalid">Numero de Tarjeta Requerido</small>
            )}
          </div>
          <div className="p-col-2"></div>
        </div>
        <div className="p-grid  p-mr-3 p-ml-3">
          <div className="p-col">
            <h5>Fecha de Expiración</h5>{" "}
          </div>

          <div className="p-col-5" style={{ textAlign: "right" }}>
            <InputMask
              mask="9999"
              id="fechaExpira"
              placeholder="0526"
              style={{ textAlign: "right" }}
              value={transaccion.fechaExpira}
              className={classNames(
                {
                  "p-invalid": submitted && !transaccion?.fechaExpira,
                },
                "p-inputtext-sm"
              )}
              onChange={(e) =>
                setTransaccion({
                  ...transaccion,
                  fechaExpira: e.target.value,
                })
              }
              onClick={(e) => selectText(e)}
            />
            {submitted && !transaccion?.numeroTarjeta && (
              <small className="p-invalid">Fecha de Expiración requerida</small>
            )}
          </div>
          <div className="p-col-2"></div>
        </div>
      </div>
      <div className="p-grid" style={{ paddingRight: "20px" }}>
        <div className="p-col-10">
          <div style={{ alignItems: "right", textAlign: "right" }}>
            <Button
              style={{ width: "200px" }}
              className="p-button-danger p-mr-2 "
              icon="pi pi-times"
              label="Cancelar"
              onClick={() => {
                setTransaccion(transaccionEmpty);
                setSubmitted(false);
              }}
            />
            <Button
              style={{ width: "200px" }}
              icon="pi pi-check "
              className="p-button-info p-mr-2 "
              label="Confirmar"
              onClick={() => save()}
            />
          </div>
        </div>
        <div className="p-col-2"></div>
      </div>

      <Dialog
        visible={buscarAso}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscarAso(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscarAso}
          toast={toast}
          persona={transaccion}
          setPersona={setTransaccion}
          parametro1={"asociado"}
          parametro2={"nombreAsociado"}
          cantPar={2}
        />
      </Dialog>

      <Dialog
        visible={dialog}
        header="Estado de transaccion"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "25vw" }}
        onHide={() => {
          restablecerEstado();
        }}
        footer={
          <>
            <Button
              label="Aceptar"
              icon="pi pi-check"
              onClick={() => {
                restablecerEstado();
              }}
            />
          </>
        }
      >
        <div className="card ">
          <div className="p-d-flex p-ai-center ">
            <i
              className={`   ${
                multi === "2" ? "pi pi-check" : multi === "3" ? "pi pi-times" : ""
              }  p-mr-5`}
              style={{ fontSize: "2rem" }}
            >
              <ProgressSpinner
                style={{
                  width: "30px",
                  height: "30px",
                  display: `${multi === 1 ? "" : "none"}`,
                }}
                strokeWidth="8"
              />
            </i>
            <h6>Transacción en Multimain</h6>
          </div>
          <div className="p-d-flex p-ai-center">
            <i
              className={`   ${
                sif === "2" ? "pi pi-check" : sif === "3" ? "pi pi-times" : ""
              }  p-mr-5`}
              style={{ fontSize: "2rem" }}
            >
              <ProgressSpinner
                style={{
                  width: "30px",
                  height: "30px",
                  display: `${sif === 1 ? "" : "none"}`,
                }}
                strokeWidth="8"
              />
            </i>{" "}
            <h6>Transaccion en Sifco</h6>
          </div>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </>
  );
};
