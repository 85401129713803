import React from "react";
import { SelectButton } from "primereact/selectbutton";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
export const NuevaAprobacion = ({ aprobacion, setAprobacion, submitted }) => {
  return (
    <div className="card">
      <div className="p-grid p-fluid  ">
        <div className="p-col-6">
          <label>Aprobacion</label>
          <InputText
            id="descripcion"
            value={aprobacion?.preAproDescripcion}
            onChange={(e) =>
              onChajeObjN(e, "preAproDescripcion", aprobacion, setAprobacion)
            }
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !aprobacion.preAproDescripcion,
            })}
          />
          {submitted && !aprobacion.preAproDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}
        </div>
        <div className="p-col-6">
          <label>Tiene limite</label>
          <SelectButton
            value={aprobacion?.preAproLimite}
            options={[
              { label: "SI", value: true },
              { label: "NO", value: false },
            ]}
            onChange={(e) =>
              setAprobacion({
                ...aprobacion,
                preAproLimite: e.value,
              })
            }
          />
        </div>
      </div>
      <div
        className="p-grid p-fluid"
        style={{
          display: `${aprobacion?.preAproLimite === false ? "none" : ""}`,
        }}
      >
        <div className="p-col-6">
          <label>Monto Maximo</label>
          <InputText
            id="monMax"
            value={aprobacion?.preAproMax}
            onChange={(e) =>
              onChajeObjN(e, "preAproMax", aprobacion, setAprobacion)
            }
            type={"number"}
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid":
                submitted &&
                aprobacion?.preAproLimite &&
                !aprobacion.preAproMax,
            })}
          />
          {submitted && aprobacion?.preAproLimite && !aprobacion.preAproMax && (
            <small className="p-invalid">Monto maximo requerido</small>
          )}
        </div>
        <div className="p-col-6">
          <label> Monto minimo</label>
          <InputText
            id="monMin"
            value={aprobacion?.preAproMin}
            onChange={(e) =>
              onChajeObjN(e, "preAproMin", aprobacion, setAprobacion)
            }
            type={"number"}
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid":
                submitted &&
                aprobacion?.preAproLimite &&
                !aprobacion.preAproMin,
            })}
          />
          {submitted && aprobacion?.preAproLimite && !aprobacion.preAproMin && (
            <small className="p-invalid">Monto maximo requerido</small>
          )}
        </div>
      </div>
    </div>
  );
};
