import React, { useState, useRef } from "react";
import { Button } from "primereact/button";
import useAuth from "./../../../auth/useAuth";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { InputMask } from "primereact/inputmask";
//Service
import CliAsociadoService from "../../../service/CliServices/CliAsociadoService";
import ClausulaService from "./../../../service/ClausulaConsentimiento/ClausulaService";
//componentes 
import { CliAsociadoSeleccionar } from "../../ClientesPages/CliAsociadoSeleccionar";
import selectText from "../../../utilities/selecText";
export const NuevoDocumento = ({
  toast,
  newclausula,
  setNewClausula,
  setImprimir,
  setEstado,
  estado,
}) => {
  const auth = useAuth();
  const message = useRef();
  let personaEmpty = {
    cliCod: 0,
    conFechaIngreso: "",
    conId: 0,
    conIdentificacion: "",
    conNombre: "",
    conSucId: 0,
    conUsuUsuarioIngresa: "",
  };
  var charCode = 0
  const [cliPersona, setCliPersona] = useState(personaEmpty);
  const [visible, setVisible] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [buscar, setBuscar] = useState(false);
  const reject = () => {
    setSubmitted(false);
    setNewClausula(false);
    setVisible(false);
    setCliPersona(personaEmpty);
    toast.current.show({
      severity: "error",
      summary: "Cancelada",
      detail: "Clausula no impresa",
      life: 4000,
    });
  };

  const confirm = () => {
    sessionStorage.setItem("reporte", JSON.stringify(cliPersona));
    setImprimir(true);
    setNewClausula(false);
    setVisible(false);
    setCliPersona(personaEmpty);
  };

  const hideDialog = (dato) => {
    setSubmitted(false);
    setNewClausula(false);
    setCliPersona(personaEmpty);
    var dato1 = estado === true ? false : true;
    setEstado(dato1);
    if (dato === true) {
      toast.current.show({
        severity: "error",
        summary: "Cancelada",
        detail: "Clausula no ingresada",
        life: 4000,
      });
    } else {
      toast.current.show({
        severity: "success",
        summary: "Confirmada",
        detail: `Clausula de creada`,
        life: 4000,
      });
    }
  };

  const onChangeNew = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _cliPersona = { ...cliPersona };
    _cliPersona[`${name}`] = val;
    setCliPersona(_cliPersona);
  };

  function gfg() {
    setSubmitted(false);
  }
  const save = () => {
    setSubmitted(true);
    setTimeout(gfg, 4000);
    if (cliPersona.conNombre !== "" && cliPersona.conIdentificacion !== "") {
      const clausulaService = new ClausulaService();
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      if (month < 10) {
        month = "0" + month;
      }

      var dayNow = year + "-" + month + "-" + day;

      let _cliPersona = { ...cliPersona };
      _cliPersona[`conFechaIngreso`] = dayNow;
      _cliPersona[`conSucId`] = auth.user?.usr.sucId;
      _cliPersona[`conNombre`] = cliPersona.conNombre.toUpperCase();
      _cliPersona[`conUsuUsuarioIngresa`] = auth.user?.usr.usuUsuario;
     
      clausulaService.save(_cliPersona).then(() => {
        var dato = estado === true ? false : true;
        setEstado(dato);
        setVisible(true);
        setSubmitted(false);
        toast.current.show({
          severity: "success",
          summary: "Exitoso",
          detail: "Persona agregada",
          life: 4000,
        });
      });
    } else {
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  };

  const fil = (evt) => {
    var e = evt;
     charCode = e.which || e.keyCode;

    if (charCode === 9) {
      if (
        cliPersona.cliCod !== 0 &&
        cliPersona.cliCod !== undefined &&
        cliPersona.cliCod !== null &&
        cliPersona.cliCod !== ""
      ) {
        dataCli()
      }
    }
  }; 

   const findCli = () => {
    if (cliPersona?.cliCod !== 0 && charCode !== 9) {
      dataCli();
    }
  };
  const dataCli = () => {
    const cliAsociadoService = new CliAsociadoService();
    cliAsociadoService.findByIdClau(cliPersona?.cliCod).then((res) => {
      if (res === undefined || res === false) {
        toast.current.show({
          severity: "warn",
          summary: "error",
          content: "Asociado no encontrado",
          life: 4000,
        });
        let _cliPersona = { ...cliPersona };
        _cliPersona[`conNombre`] = "";
        _cliPersona[`cliCod`] = 0;
        _cliPersona[`conIdentificacion`] = ""

      
        setCliPersona(_cliPersona);
      } else {
        var nombre = res.name;
        let _cliPersona = { ...cliPersona };
        _cliPersona[`conNombre`] = nombre;
        _cliPersona[`conIdentificacion`] = res?.id;
   
        setCliPersona(_cliPersona);
      }
    });
  };



  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={reject}
      />
      <Button icon="pi pi-check" label="Si" onClick={confirm} />
    </>
  );

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelsar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscar(false)}
        />
      </>
    );
  }
  const footerDialog = () => {
    return (
      <div>
        <Button
          className="p-button-danger p-button "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => {
            hideDialog(true);
          }}
        />

        <Button
          className=" p-button "
          icon="pi pi-check"
          label="Guardar"
          onClick={() => {
            save();
          }}
        />
      </div>
    );
  };

  return (
    <div>
      <Dialog
        visible={newclausula}
        header="Solicitud"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "65vw" }}
        footer={footerDialog}
        className="p-fluid"
        onHide={() => {
          hideDialog(true);
        }}
      >
        <div className="card">
          <div className="p-grid">
            <div className="p-fluid p-col-4" onKeyDown={(e) => fil(e)}>
              <label htmlFor="asoId">Código Cliente</label>
              <div className="p-inputgroup">
                <InputText
                onClick={(e) =>selectText(e)}
                  id="asoId"
                  type={"number"}
                  value={cliPersona.cliCod}
                  onChange={(e) => onChangeNew(e, "cliCod")} onBlur={(e) => findCli(e)}
                />
                <Button
                  icon="pi pi-search"
                  className="p-button-info"
                  onClick={() => setBuscar(true)}
                />
              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-fluid p-col-7">
              <label htmlFor="asoId">Nombre</label>
              <InputText
                id="asoId"
                type={"text"}
                value={cliPersona.conNombre}
                onChange={(e) => onChangeNew(e, "conNombre")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !cliPersona.conNombre,
                })}
              />
              {submitted && !cliPersona.conNombre && (
                <small className="p-invalid">
                  Nombre de la persona requeriodo.
                </small>
              )}
            </div>
            <div className="p-fluid p-col-5">
              <label htmlFor="asoId">Número de Identificación</label>
              <InputMask
                id="asoId"
                mask="9999 99999 9999"
                value={cliPersona.conIdentificacion}
                onChange={(e) => onChangeNew(e, "conIdentificacion")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !cliPersona.conIdentificacion,
                })}
              />
              {submitted && !cliPersona.conIdentificacion && (
                <small className="p-invalid">
                  Numero de identificacion requerido.
                </small>
              )}
            </div>
          </div>
          <Messages ref={message} />
        </div>
      </Dialog>

      <Dialog
        header="Mensaje del sistema"
        visible={visible}
        onHide={() => reject()}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea imprimir la clausula.</span>
        </div>
      </Dialog>

      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={cliPersona}
          setPersona={setCliPersona}
          parametro1={"cliCod"}
          parametro2={"conNombre"}
          parametro3={"conIdentificacion"}
          cantPar={3}
        />
      </Dialog>

    </div>
  );
};
