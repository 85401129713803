import React from "react";
import { InputText } from "primereact/inputtext";

//funciones
import selectText from "../../../../utilities/selecText";
import onChajeObjN from "../../../../utilities/onChajeObjN";
export const NuevaDeduccion = ({ deduccion, setDeduccion,submitted }) => {
  const renderForm = (
    <div className="p-grid p-fluid">
      <div className="p-col">
        <label>Descripcion</label>
        <InputText
          id="descripcion"
          placeholder="Descripcion"
          value={deduccion.preDedDescripcion}
          onChange={(e) =>
            onChajeObjN(e, "preDedDescripcion", deduccion, setDeduccion)
          }
          onClick={(e)=>selectText(e)}
              className={{
              "p-invalid": submitted && !deduccion.preDedDescripcion,
            }}
        />  {submitted && !deduccion.preDedDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}
      </div>
    </div>
  );

  return <div className="card">{renderForm}</div>;
};
