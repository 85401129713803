import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppDepartamentoService {
  async findAll(dirId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appdepartamento/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByCountryId(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/appdepartamento/country/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }


  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/appdepartamento/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  // async save(data) {
  //   if (token) {
  //     jwt = JSON.parse(token);
  //   }
  //   try {
  //     let config = {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${jwt?.jwt}`,
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     };

  //     let res = await fetch(api.baseUrl() + "/appdepartamento/save", config);
  //     let json = await res.json();
  //     return json;
  //   } catch (err) {
  //     addressingError(err);
  //   }
  // }

  async delete(depId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      let config = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let res = await fetch(
        api.baseUrl() + `/appdepartamento/delete/${depId}`,
        config
      );
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }
}
