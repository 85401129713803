import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class ConControlDisponibilidadService {
  async dateandbranchgroup(date, branch, group) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/concontroldisponibilidad/dateandbranch/${date}/${branch}/${group}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async dateandbranchprovenance(date, branch, provenance) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/concontroldisponibilidad/datebranchprovenance/${date}/${branch}/${provenance}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async reportsucursalanddate(date, branch) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/concontroldisponibilidad/report/${date}/${branch}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
          responseType: "arraybuffer",
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async reportConsolidatedByDate(date) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/concontroldisponibilidad/report/consolidated/${date}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
          responseType: "arraybuffer",
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async idbranchdate(detDisId, branch, date) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/concontroldisponibilidad/idbranchdate/${detDisId}/${branch}/${date}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async availabilityDate(date, group) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/concontroldisponibilidad/availability/${date}/group/${group}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async availabilityDateGroup1(date, group) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/concontroldisponibilidad/all/availability/${date}/group/${group}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async saludo() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/concontroldisponibilidad/saludo`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
          responseType: "arraybuffer",
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async trxByDateAndBranch(date, branch) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/concontroldisponibilidad/trx/${date}/${branch}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      return err;
    }
  }

  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(
        api.baseUrl() + "/concontroldisponibilidad/save",
        config
      );
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async getLiquidez() {
    return axios
      .get("assets/demo/data/conControlJsonData.json")
      .then((res) => res.data.data);
  }
}
