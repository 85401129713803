import React, { useEffect, useState ,useRef } from "react";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Dialog } from "primereact/dialog";
//Servicos
import AppTasaUnicaService from "../../../../../service/AppServices/AppTasaUnicaService";

//Funciones
import formatDate from "../../../../../utilities/formatDateTable";

//paginas
import { NuevaTasaUnica } from "./NuevaTasaUnica";
export const TasaUnica = ({ setNuevoUnico, nuevoTasaInteres }) => {
  let tasaUnicaEmpty = {
    tasUniFechaVigencia: "",
    tasUniId: 0,
    tasUniTasId: 0,
    tasUniValor: 0,
  };
  const toasUnico = useRef();

  const [tasasUnicas, setTasasUnicas] = useState([]);
  const [nuevaTasaUnica, setNuevaTasaUnica] = useState(false);
  const [tasaUnica, setTasaUnica] = useState(tasaUnicaEmpty);
  const [prueba,setPrueba] = useState(false);



  useEffect(() => {
    const appTasaUnicaService = new AppTasaUnicaService();
    appTasaUnicaService
      .findAllGroupId(nuevoTasaInteres?.tasId)
      .then((response) => {

        setTasasUnicas(response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prueba]);



  function formDateTalbe(rowData){
    return <>
    {formatDate(rowData?.tasUniFechaVigencia)}
    </>

  }
  function porcentajeTable(rowData){
    return <>c
    {
        rowData?.tasUniValor
    }%
    </>
  }


  function nuevo() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => 
            {setNuevaTasaUnica(true); setTasaUnica(tasaUnicaEmpty)}
          }
        />
      </>
    );
  }

  function acciones(rowData) {
    return (
      <>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button"
          onClick={() => {setNuevaTasaUnica(true); setTasaUnica(rowData)}}
        />
      </>
    );
  }

  function renderTalbe() {
    return (
      <>
        <DataTable
          value={tasasUnicas}
          emptyMessage="No se encontro ninguna tasa Unica"
        >
          <Column header="Fecha de vigencia" field="tasUniFechaVigencia" body={formDateTalbe}/>
          <Column header="Tasa a utilizar" field="tasUniValor"  body={porcentajeTable}/>
          <Column header="Acciones" body={acciones}/>
        </DataTable>
      </>
    );
  }

  function buttons() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-mr-2 p-mb-2 p-button-danger "
          onClick={() => setNuevoUnico(false)}
          label="Cerrar"
        />
      </>
    );
  }
  return (
    <>
      <div>{nuevo()}</div>
      <div className="card p-mt-3">{renderTalbe()}</div>

      <div style={{ textAlign: "right" }}>{buttons()}</div>
      <Dialog
        visible={nuevaTasaUnica}
        header={tasaUnica?.tasUniId === 0 ?  "Nueva tasa unica " :"Editar tasa unica"}
        onHide={() => (
            setNuevaTasaUnica(false)
         
        )}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >

        <NuevaTasaUnica setNuevaTasaUnica={setNuevaTasaUnica} toasUnico={toasUnico} tasaUnicaHook={tasaUnica} nuevoTasaInteres={nuevoTasaInteres}
        prueba={prueba} setPrueba={setPrueba}
        />
      
      </Dialog>
      <Toast ref={toasUnico} />

    </>

    
  );
};
