import React, { useState, useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { TabView, TabPanel } from "primereact/tabview";
import { General } from "./General";
import { Toast } from "primereact/toast";
export const MenuNuevoPrestamo = () => {
  const toast = useRef();
  var obj = JSON.parse(sessionStorage.getItem("prestamo"));

  const [prestamo, setPrestamo] = useState(obj);
  return (
    <div className="p-grid">
      <div className="p-col-12 p-md-12">
        <div className="card">
          <Toolbar
            left={
              <h6>
                {prestamo?.preAsoId !== 0
                  ? prestamo?.preAsoId +
                    " - " +
                    prestamo?.preNombre +
                    " - " +
                    prestamo?.ahoId
                  : "Nueva prestamo"}
              </h6>
            }
          ></Toolbar>
          <TabView>
            <TabPanel header="General">
              <General
                prestamoHook={prestamo}
                setPrestamoHook={setPrestamo}
                toast={toast}
              />
            </TabPanel>
            <TabPanel header="Codeudores"></TabPanel>
            <TabPanel header="Desembolsos"></TabPanel>
            <TabPanel header="Estado de cuenta"></TabPanel>
            <TabPanel header="Transacciones"></TabPanel>
          </TabView>
        </div>
      </div>
      <Toast ref={toast} />
    </div>
  );
};
