import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Dropdown } from "primereact/dropdown";
import { Toast } from "primereact/toast";

//Servicios
import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
import AppProductoService from "../../../../service/AppServices/AppProductoService";

//Componentes
import { NuevoProducto } from "./NuevoProducto";
import { Dialog } from "primereact/dialog";
export const Producto = () => {
  const toast = useRef();
  let filtroEmpty = {
    nombre: "",
    proTipoId: 0,
    appTipoProducto: {
      proTipoId: 0,
      proTipoNombre: "",
    },
  };
  let producto = {
    appTipoProducto: {
      proTipoId: 0,
      proTipoNombre: "",
    },
    proId: 0,
    proNombre: "",
    proTipoId: 0,
  };
  const [newProducto, setNewProducto] = useState(producto);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [productos, setProductos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevo, setNuevo] = useState(false);
  const [tipoProducto, setTipoProducto] = useState([]);
  const [recargar, setRecargar] = useState(false);

  useEffect(() => {
    const appTipoProductoService = new AppProductoService();
    appTipoProductoService.findAll().then((response) => {
      response?.push({
        proTipoId: 0,
        proTipoNombre: "TODOS",
      });
      setTipoProducto(response);
    });
  }, []);

  useEffect(() => {
    const appProductoService = new AppTipoProductoService();
    appProductoService
      .findByPage(pageNumber, filtro.nombre, filtro.proTipoId)
      .then((response) => {
        //  console.log(response);
        setProductos(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, filtro.proTipoId, recargar]);

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _filtro = { ...filtro };
    _filtro[`${name}`] = val;
    setFiltro(_filtro);
    _filtro[`${name2}`] = val1;
    setFiltro(_filtro);
  };

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9) {
      const appProductoService = new AppTipoProductoService();
      appProductoService
        .findByPage(pageNumber, filtro.nombre.toUpperCase(), filtro.proTipoId)
        .then((response) => {
          //  console.log(response);
          setProductos(response?.content);
          setTotalPages(response?.totalPages);
          setPageNumber(response?.number);
          setFirstPage(response?.first);
          setLastPage(response?.last);
          if (filtro.proTipoId !== "" || filtro.proTipoId !== 0) {
            setPageNumber(0);
          }
        });
    }
  };

  const renderPaginacion = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-double-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(0)}
        />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={firstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        />

        <InputText
          type="text"
          className="p-inputtext p-component"
          placeholder={pageNumber + 1 + " de " + totalPages}
          style={{
            width: "100px",
            height: "30px",
            borderColor: "#5499C7",
            textAlign: "center",

            fontSize: "15px",
            background: "transparent",
          }}
          readOnly
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-right"
          style={{
            background: "transparent; color: #5499C7",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(pageNumber + 1)}
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-double-right"
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={lastPage}
          onClick={() => setPageNumber(totalPages - 1)}
        />
      </div>
    );
  };

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevo(true); setNewProducto(rowData)}}
        />
      </>
    );
  }

  function renderTable() {
    return (
      <DataTable value={productos} footer={renderPaginacion()}>
        <Column header="Id" field="proId" />
        <Column header="Descripcion" field="proNombre" />
        <Column
          header="Tipo de producto"
          field="appTipoProducto.proTipoNombre"
        />
        <Column
          header="Acciones"
          body={acciones}
          style={{ textAlign: "center" }}
        />
      </DataTable>
    );
  }

  const left = (
    <div className="p-fluid p-grid">
      <div className="p-col" onKeyDown={(e) => fil(e)}>
        <label>Descripcion</label>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            value={filtro.nombre}
            onChange={(e) => {
              setFiltro({
                ...filtro,
                nombre: e.target.value,
              });
            }}
            placeholder="Buscar..."
          />
        </span>
      </div>
      <div className="p-col">
        <label>Tipo de producto</label>
        <Dropdown
          style={{ width: "200px" }}
          value={filtro.appTipoProducto}
          options={tipoProducto}
          optionLabel="proTipoNombre"
          onChange={(e) => {
            onInputChangeObject(
              e,
              "appTipoProducto",
              e.target.value.proTipoId,
              "proTipoId"
            );
          }}
          placeholder="TODOS"
        />
      </div>
    </div>
  );

  const right = (
    <div>
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="p-button-success p-mr-2 p-mb-2"
        onClick={() => {setNuevo(true); setNewProducto(producto)}}
      />
    </div>
  );

  return (
    <div>
      <div className="card">
        <Toolbar className="p-mb-4" left={left} right={right}></Toolbar>
        {renderTable()}
      </div>

      <Dialog
        header={"Nuevo producto"}
        visible={nuevo}
        onHide={() => {
          setNuevo(false);
          toast.current.show({
            severity: "error",
            summary: "Error",
            detail: "Cambios descartados",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
      >
        <NuevoProducto
          setNuevo={setNuevo}
          tipoProducto={tipoProducto}
          newProductoHook={newProducto}
          setRecargar={setRecargar}
          recargar={recargar}
          toast={toast}
        />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
