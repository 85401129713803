import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { confirmDialog } from 'primereact/confirmdialog'; // To use confirmDialog method
import AppRegionService from "../../../../service/AppServices/AppRegionService";

export const ListarRegiones = () => {
    const toast = useRef();

    const appRegionEmpty = {
        regId: 0,
        regNombre: "",
        paiId: 0,
        regCodigo: 0,
    }


    const [appRegion, setAppRegion] = useState(appRegionEmpty);
    const [appRegiones, setAppRegiones] = useState([]);

    //State para el dialogo de nueva region
    const [displayDialog, setDisplayDialog] = useState(false);

    //State para el dialogo de confirmacion de eliminar
    //const [displayDialogEliminar, setDisplayDialogEliminar] = useState(false);

    //Metodo mostrar el dialogo de nueva region
    const showDialogToAdd = () => {
        // setAppRegion(appRegionEmpty);
        setDisplayDialog(true);
    }

    //Metodo ocultar el dialogo de nueva region
    const hideDialogToAdd = () => {
        setDisplayDialog(false);
    }

    //Metodo mostrar el dialogo de confirmacion de eliminar
    // const showDialogEliminar = () => {
    //     setDisplayDialogEliminar(true);
    // }

    // //Metodo ocultar el dialogo de confirmacion de eliminar
    // const hideDialogEliminar = () => {
    //     setDisplayDialogEliminar(false);
    // }

    //Metodo para editar una region
    const onEdit = (rowData) => {
        setAppRegion(rowData);
        setDisplayDialog(true);
    }

    //Metodo para guardar una region
    const onSave = () => {
        if (appRegion.regId === 0) {
            const appRegionService = new AppRegionService();
            appRegionService.save(appRegion)
                .then(response => {
                    setAppRegiones([...appRegiones, response]);
                    setDisplayDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Se guardo correctamente' });

                }
                )
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'No se pudo guardar' });
                    console.log(error);
                }
                )
        } else {
            const appRegionService = new AppRegionService();
            appRegionService.save(appRegion)
                .then(response => {
                    setAppRegiones([...appRegiones]);
                    setDisplayDialog(false);
                    toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Se guardo correctamente' });
                }
                )
                .catch(error => {
                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'No se pudo guardar' });
                    console.log(error);
                }
                )
        }
    }

    const accept = (rowData) => {
        const appRegionService = new AppRegionService();
        appRegionService.delete(rowData.regId)
            .then(response => {
                appRegionService.findAll().then(response => {
                    setAppRegiones(response);
                    // toast.current.show({ severity: 'success', summary: 'Correcto', detail: 'Se elimino correctamente' });
                }
                )
                // setAppRegiones(appRegiones.filter(appRegion => appRegion.regId !== appRegion.regId));
              //  setDisplayDialogEliminar(false);
                toast.current.show({ severity: 'success', summary: 'Correcto', detail: `Se elimino correctamente ` + rowData.regNombre });
            }
            )
    }

    const reject = () => {
        toast.current.show({ severity: 'info', summary: 'Cancelado', detail: 'No se elimino nada' });
      //  setDisplayDialogEliminar(false);
    }
    const onDeleteRegion = (rowData) => {
        confirmDialog({
            header: 'Confirmación',
            message: `¿Está seguro de eliminar la region: ${rowData.regNombre}?`,
            accept: () => accept(rowData),
            reject: reject
        });
    };



    useEffect(() => {
        const appRegionService = new AppRegionService();
        appRegionService.findAll().then((data) => {
            setAppRegiones(data);
        }
        )
    }, []);

    const accionesRegionTemplate = (rowData) => (
        <div>
            <Button
                tooltip="Editar"
                icon="pi pi-pencil"
                className="p-button-success p-button-rounded"
                onClick={() => onEdit(rowData)}
            ></Button>
            {" "}
            <Button
                tooltip="Eliminar"
                icon="pi pi-trash"
                className="p-button-warning p-button-rounded"
                onClick={() => onDeleteRegion(rowData)}
            ></Button>
        </div>
    )

    const renderListaRegiones = () => {
        return <DataTable
            header="Lista de Regiones"
            value={appRegiones}
        >
            <Column field="regId" header="Id" />
            <Column field="regNombre" header="Nombre" />
            <Column body={accionesRegionTemplate} header="Acciones" />

        </DataTable>
    }

    const renderToolbarRight = () => (
        <Button label="Nuevo" icon="pi pi-plus" className="p-button-success" onClick={showDialogToAdd} />
    )

    const footerDialogNuevaRegion = () => (
        <div>
            <Button label="Aceptar" icon="pi pi-check" className="p-button-success" onClick={onSave} />
            <Button label="Cancelar" icon="pi pi-times" className="p-button-danger" onClick={hideDialogToAdd} />
        </div>
    )

    return (
        <div className="p-grid crud-demo">
            <Toast
                ref={toast}
            ></Toast>
            <div className="p-col-12">
                <div className="card">
                    <Toolbar
                        className="p-mb-4"
                        left={renderToolbarRight} />
                    {renderListaRegiones()}
                </div>
            </div>
            <Dialog
                className="p-fluid"
                visible={displayDialog}
                header="Nueva Región"
                onHide={hideDialogToAdd}
                footer={footerDialogNuevaRegion()}>
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>Nombre</label>
                        <InputText
                            id="regNombre"
                            type="text"
                            value={appRegion?.regNombre}
                            onChange={(e) => {
                                setAppRegion({ ...appRegion, regNombre: e.target.value })
                            }
                            }

                        />
                    </div>
                    <div className="p-col-12">
                        <label>Codigo Region</label>
                        <InputText
                            id="regCodigo"
                            type="text"
                            value={appRegion?.regCodigo}
                            onChange={(e) => {
                                setAppRegion({ ...appRegion, regCodigo: e.target.value })
                            }
                            }
                        />
                    </div>
                    <div className="p-col-12">
                        <label>Codigo País</label>
                        <InputText
                            id="paiId"
                            type="text"
                            value={appRegion?.paiId}
                            onChange={(e) => {
                                setAppRegion({ ...appRegion, paiId: e.target.value })
                            }
                            }
                        />
                    </div>


                </div>
            </Dialog>
        </div>
    )
}