import axios from "axios";
import Api from "../Api";
import addressingError from "./../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppUsuarioService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const res = await axios.get(api.baseUrl() + "/appusuario/all", {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return res.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/appusuario/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByPage(pageNumber, usuUsuario, usuNombre, usuApellido) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/appusuario/pagina/${usuUsuario || 0}/${usuNombre || 0}/${
            usuApellido || 0
          }/?page=${pageNumber || 0}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      // console.log(response)
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async getUserById(user) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      let config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let res = await fetch(api.baseUrl() + "/appusuario/" + user, config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  // async validPasswordOld(usuUsuario, passwordOld) {
  //   if (token) {
  //     jwt = JSON.parse(token)
  //   }
  //   try {
  //     const response = await axios.get(
  //       api.baseUrl() +
  //         `/appusuario/pagina/${usuUsuario || 0}/${usuNombre || 0}/${
  //           usuApellido || 0
  //         }/?page=${pageNumber || 0}`,
  //       {
  //         headers: { Authorization: `Bearer ${jwt.jwt}` },
  //       },
  //     )
  //     // console.log(response)
  //     return response.data
  //   } catch (err) {
  //     if (err.response.status === 403) {
  //       return (window.location = '#/access')
  //     } else {
  //       return err
  //     }
  //   }
  // }
}
