import React, { useEffect, useState, useRef } from "react";
import { Column } from "jspdf-autotable";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
//servicios
import AppTasaRangoService from "../../../../../service/AppServices/AppTasaRangoService";

//componetes
import { NuevaTasaRango } from "./NuevaTasaRango";

//funciones
import formatDate from "../../../../../utilities/formatDateTable";
import formatMiles from "../../../../../utilities/formatMiles";

export const TasaRango = ({ setNuevoRango, nuevoTasaInteres, toast }) => {
  let tasaRangoEmpty = {
    tasId: 0,
    tasRangoFechaVigencia: "",
    tasRangoId: 0,
    tasRangoMontoFinal: 0,
    tasRangoMontoInicial: 0,
    tasRangoPorcentaje: 0,
  };
  const toastRango = useRef();
  const [rangosTasas, setRangosTasas] = useState([]);
  const [tasaRango, setTasaRango] = useState(tasaRangoEmpty);
  const [nuevaTasaRango, setNuevaTasaRango] = useState(false);
  const [rango, setRango] = useState(false);

  useEffect(() => {
    const appTasaRangoService = new AppTasaRangoService();
    appTasaRangoService
      .findAllGroupId(nuevoTasaInteres?.tasId)
      .then((response) => {
        setRangosTasas(response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rango]);

  function rednerButton() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-mr-2 p-mb-2 p-button-danger"
          onClick={() => {
            setNuevoRango(false);
            toast.current.show({
              severity: "warn",
              summary: "Alerta",
              detail: "Pestaña cerrada",
              life: 4000,
            })
          }}
          label="Cerrar"
        />
      </>
    );
  }
  function acciones(rowData) {
    return (
      <>
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button"
          onClick={() => {setNuevaTasaRango(true); setTasaRango(rowData)}}
        />
      </>
    );
  }

  function tableFormatDate(rowData) {
    return formatDate(rowData?.tasRangoFechaVigencia);
  }

  function tableFormateMiles(rowData) {
    return formatMiles(rowData?.tasRangoMontoInicial);
  }
  function tableFormateMilesMontFin(rowData) {
    return formatMiles(rowData?.tasRangoMontoFinal);
  }
  function tableFormPorsentaje(rowData) {
    return <>{rowData?.tasRangoPorcentaje}%</>;
  }

  function renderTable() {
    return (
      <>
        <DataTable
          value={rangosTasas}
          emptyMessage="No se encontro ning rango de tasa"
        >
          <Column
            field="tasRangoFechaVigencia"
            header="Fecha de vigencia"
            body={tableFormatDate}
          />
          <Column
            field="tasRangoMontoInicial"
            header="Monto Inicial"
            body={tableFormateMiles}
          />
          <Column
            field="tasRangoMontoFinal"
            header="Monto final"
            body={tableFormateMilesMontFin}
          />
          <Column
            field="tasRangoPorcentaje"
            header="Tasa a utilizar"
            body={tableFormPorsentaje}
          />
          <Column body={acciones} header="Aciones" />
        </DataTable>
      </>
    );
  }

  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setNuevaTasaRango(true); setTasaRango(tasaRangoEmpty)
          }}
        />
      </>
    );
  }
  return (
    <>
      <div>{right()} </div>
      <div className="card p-mt-3">{renderTable()}</div>
      <div style={{ textAlign: "right" }}>{rednerButton()}</div>

      <Dialog
        visible={nuevaTasaRango}
        header="Nuevo rango "
        onHide={() => {
          setNuevaTasaRango(false);
          toastRango.current.show({
            severity: "warn",
            summary: "Alerta",
            detail: "Pestaña de rangos cerrada",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >
        <NuevaTasaRango
          setNuevaTasaRango={setNuevaTasaRango}
          HooktasaRango={tasaRango}
          nuevoTasaInteres={nuevoTasaInteres}
          rango={rango}
          setRango={setRango}
          toastRango={toastRango}
        />
      </Dialog>
      <Toast ref={toastRango} />
    </>
  );
};
