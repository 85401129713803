import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppPaisService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    return axios
      .get(api.baseUrl() + "/apppais/all", {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      })
      .then((res) => res.data)
      .catch((err) => {
        addressingError(err);
      });
  }

  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/apppais/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

}
