import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AppSexoService from "../../service/AppServices/AppSexoService";

export const AppSexo = () => {
  let emptySexo = {
    sexId: 0,
    sexDescripcion: "",
  };

  const [sexos, setSexos] = useState(null);
  const [sexoDialog, setSexoDialog] = useState(false);
  const [deleteSexoDialog, setDeleteSexoDialog] = useState(false);
  const [sexo, setSexo] = useState(emptySexo);
  const [selectedSexos, setSelectedSexos] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);

  
  const [sexoFind, setSexoFind] = useState("");

  useEffect(() => {
    const appSexoService = new AppSexoService();
    appSexoService.findAll().then((data) => setSexos(data));
  }, []);

  useEffect(() => {
    const appSexoService = new AppSexoService();
    appSexoService.findByName(sexoFind).then((response) => {
      setSexos(response);
    });
  }, [sexoFind]);

  const openNew = () => {
    setSexo(emptySexo);
    setSubmitted(false);
    setSexoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setSexoDialog(false);
  };

  const hideDeleteSexoDialog = () => {
    setDeleteSexoDialog(false);
  };

  //Guardamos un registro
  const saveSexo = () => {
    setSubmitted(true);

    const appSexoService = new AppSexoService();
    if (sexo.paiId !== null) {
      appSexoService.save(sexo).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "Sexo Actualizado",
        life: 3000,
      });
    } else {
      appSexoService.save(sexo).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "Sexo Creado",
        life: 5000,
      });
    }

    //setPaises(_paises);
    //appSexoService.findAll().then((data) => setSexos(data));
    findByName();
    setSexoDialog(false);
    setSexo(emptySexo);
    //}
  };

  //Editamos un registro
  const editSexo = (sexo) => {
    setSexo({ ...sexo });
    setSexoDialog(true);
  };

  //Mensaje de confirmación de un registro
  const confirmDeleteSexo = (product) => {
    setSexo(product);
    setDeleteSexoDialog(true);
  };

  //Borramos un Género
  const deleteSexo = () => {
    setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const appSexoService = new AppSexoService();
    //Enviamos los datos al servicio correspondiente
    appSexoService.delete(sexo).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      console.log(data);
      toast.current.show({
        severity: "success",
        summary: "¡Éxitoso!",
        detail: "Sexo eliminado",
        life: 3000,
      });
    });
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteSexoDialog(false);
    //Metemos al state el all de los sexos del sistemas despues de aver eliminado
    setSexo(emptySexo);
  };

  const findByName = () => {
    //Cramos una instancia de nuestro servicio
    const appSexoService = new AppSexoService();
    //Enviamos los datos al servicio correspondiente
    appSexoService.findByName(sexoFind).then((response) => {
      setSexos(response);
    });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _sexo = { ...sexo };
    _sexo[`${name}`] = val;
    setSexo(_sexo);
  };

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    );
  };

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.sexId}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.sexDescripcion}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editSexo(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteSexo(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestionar Géneros</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          // onInput={(e) => setGlobalFilter(e.target.value)}
          // onInput={(e) => findByName(e.target.value)}
          onInput={(e) => setSexoFind(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveSexo} />
    </>
  );
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteSexoDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteSexo} />
    </>
  );

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={sexos}
            selection={selectedSexos}
            onSelectionChange={(e) => setSelectedSexos(e.value)}
            dataKey="paiId"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} géneros"
            //globalFilter={globalFilter}
            emptyMessage="No se encontraron géneros."
            header={header}
          >
            <Column
              field="sexId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="sexDescripcion"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={sexoDialog}
            style={{ width: "450px" }}
            header="Datos del Género"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="sexDescripcion">Nombre</label>
              <InputText
                id="sexDescripcion"
                value={sexo.sexDescripcion}
                onChange={(e) => onInputChange(e, "sexDescripcion")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !sexo.sexDescripcion,
                })}
              />
              {submitted && !sexo.sexDescripcion && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteSexoDialog}
            style={{ width: "450px" }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteSexoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {sexo && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{sexo.sexDescripcion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
