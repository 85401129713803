import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Timeline } from "primereact/timeline";
import { Dialog } from "primereact/dialog";

import { FileUpload } from "primereact/fileupload";


//import services
import AppTipoExpedienteService from "../../service/AppServices/AppTipoExpedienteService";
import CliExpedienteDigitalService from "../../service/CliServices/CliExpedienteDigitalService";

const CliAsociadoDatosDigitalesComponent = (props) => {
  // const toast = useRef(null);

  // let emptyCliExpedienteDigital = [
  //   {
  //     asoId: 0,
  //     expCliId: 0,
  //     expTipoId: 0,
  //     expUrl: "",
  //   },
  // ];
  // let emptyAppTipoExpediente = {
  //   expTipoDescripcion: "",
  //   expTipoId: 0,
  // };

  //const [base64Url, setBase64Url] = useState();

  const [cliExpedienteDigital, setCliExpedienteDigital] = useState();
  let [cliExpedientesDigitales, setCliExpedientesDigitales] = useState([]);
  // const [appTipoExpediente, setAppTipoExpediente] = useState(
  //   emptyAppTipoExpediente
  // );
  const [appTipoExpedientes, setAppTipoExpedientes] = useState(null);

  const [cliExpedienteDigitalDialog, setCliExpedienteDigitalDialog] =
    useState(false);

  useEffect(() => {
    const cliExpedienteDigitalService = new CliExpedienteDigitalService();
    cliExpedienteDigitalService
      .findAllByCliAsoId(props.asociado.asoId)
      .then((data) => {
        setCliExpedientesDigitales(data);
      });
    onInputChangesimpleData(props?.asociado?.asoId, "asoId");
    // alert("Hola");
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliExpedientesDigitales]);

  useEffect(() => {
    const appTipoExpedienteService = new AppTipoExpedienteService();
    appTipoExpedienteService.findAll().then((data) => {
      setAppTipoExpedientes(data);
    });
  }, []);

  const verGrande = (cliExpediente) => {
    let pdfWindow = window.open("about:blank", "");
    pdfWindow.document.write(
      "<iframe width = '100%' height = '100%' src = '" +
      cliExpediente?.expUrl +
      "'></iframe>"
    );
  };

  const customizedContent = (item) => {
    // window.open("about:blank", "<a href='#'>Hola</a>", "width=200,height=200");
    var cliExpediente = cliExpedientesDigitales.find(
      (cliExpediente) => cliExpediente?.expTipoId === item?.expTipoId
    );
    return (
      <Card

        title={item.expTipoDescripcion}
        subTitle={item.expTipoDescripcion}
        className="card p-shadow-20"
      >
        {cliExpediente?.expUrl && (
          <i
            src={cliExpediente?.expUrl}
            onError={(e) =>
            (e.target.src =
              "https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png")
            }
            alt={item.expTipoDescripcion}
            width={350}
            height={350}
            className="p-shadow-2"
          />
        )}
        <p>
          <strong>NOTA:</strong> Los documentos a cargar deben de ser legibles y
          tener un tamaño maximo de 10MB.
        </p>
        <Button
          tooltip="Agrear archivo"
          icon="pi pi-cloud-upload"
          className="p-button-rounded p-button-warning"
          onClick={() => editCliExpedienteDigital(item)}
        ></Button>{" "}
        <Button
          tooltip="Ver Archivo"
          icon="pi pi-eye"
          className="p-button-rounded"
          onClick={() => verGrande(cliExpediente)}
        ></Button>
      </Card>
    );
  };

  const hideDialog = () => {
    //  setSubmitted(false);
    setCliExpedienteDigitalDialog(false);
    // setCliExpedientesDigitales([]);
    // setCliExpedienteDigital(emptyCliExpedienteDigital);
  };

  const editCliExpedienteDigital = (item) => {
    setCliExpedienteDigitalDialog(true);
    //setAppTipoExpediente(item);

    var cliExpediente = cliExpedientesDigitales.find(
      (cliExpediente) => cliExpediente?.expTipoId === item?.expTipoId
    );

    if (cliExpediente?.asoId !== 0 && cliExpediente?.asoId !== null) {
      setCliExpedienteDigital(cliExpediente);
    } else {
      onInputChangesimpleData(item.expTipoId, "expTipoId");
    }
  };

  //callback que retornara el archivo convertido en base64
  const fileToBase64 = (file, cb) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(null, reader.result);
    };
    reader.onerror = function (error) {
      cb(error, null);
    };
  };

  const saveCliExpedienteDigital = (base64, cliExpedienteDigital) => {
    let _cliExpedienteDigital = { ...cliExpedienteDigital };
    _cliExpedienteDigital[`expUrl`] = base64;
    const cliExpedienteDigitalService = new CliExpedienteDigitalService();
    cliExpedienteDigitalService
      .save(_cliExpedienteDigital)
      .then((data) => data);

    cliExpedienteDigitalService
      .findAllByCliAsoId(props.asociado.asoId)
      .then((data) => {
        setCliExpedientesDigitales(data);
      });

    setCliExpedienteDigitalDialog(false);
  };

  //funcion que llamara y enviara los datos del archivos para ser convertidos a base64
  const onUploadFileChange = (target) => {
    if (target?.files < 1) {
      return;
    }
    fileToBase64(target?.files[0], (err, result) => {
      if (result) {
        //setBase64Url(result);
        saveCliExpedienteDigital(result, cliExpedienteDigital);
      }
    });

    // console.log("cliExpedienteDigital1234", cliExpedienteDigital);
  };

  const onInputChangesimpleData = (val, name) => {
    let _cliExpedienteDigital = { ...cliExpedienteDigital };
    _cliExpedienteDigital[`${name}`] = val;

    setCliExpedienteDigital(_cliExpedienteDigital);
  };
  return (
    <>
      <div className="p-grid timeline-demo">
        <div className="p-col-12">
          {/* <div className="card"> */}
          <h4>Datos digitales</h4>

          {/* <h5>Custom Timeline</h5> */}
          <Timeline
            value={appTipoExpedientes}
            align="alternate"
            className="customized-timeline"
            //   marker={customizedMarker}
            content={customizedContent}
          />
          {/* </div> */}
        </div>
      </div>
      <Dialog
        visible={cliExpedienteDigitalDialog}
        onHide={hideDialog}
        modal
        style={{ width: "60vw" }}
      >
        <FileUpload
          name="expediente"
          accept="/*"
          maxFileSize={10000000}
          customUpload={true}
          uploadHandler={onUploadFileChange}
          mode="advanced"
          multiple={false}
          chooseLabel="Seleccionar archivo"
          uploadLabel="Subir archivo"
          cancelLabel="Cancelar"
          invalidFileSizeMessageDetail="Tamaño de archivo no válido,
el tamaño máximo de carga es de 10 MB."
        />
      </Dialog>
    </>
  );
};

export default CliAsociadoDatosDigitalesComponent;
