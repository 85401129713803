import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AhoMovimientoDiarioService {
  //   async save(data,idAju) {
  //     console.log(data,idAju)
  //     try {
  //       let config = {
  //         method: "POST",
  //         headers: {
  //           Authorization: `Bearer ${jwt?.jwt}`,
  //           Accept: "application/json",
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       };

  //       let res = await fetch(api.baseUrl() + `/ahomovimientodiario/ajuste/${idAju}`, config);
  //       let json = await res.json();
  //       return json;
  //     } catch (err) {
  //       addressingError(err);
  //     }
  //   }

  async save(data, idAju, natu) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/ahomovimientodiario/ajuste/${idAju}/${natu}`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async saveBackoffice(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/ahomovimientodiario/backoffice`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async bloqueo(ctaNum, id) {
    console.log(ctaNum, id);
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/ahomovimientodiario/bloqueo/${ctaNum}/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async desBloqueo(ctaNum) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/ahomovimientodiario/desbloqueo/${ctaNum}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }
  async saldosCancelacion(ahoId, penalizacion) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/ahomovimientodiario/saldoscancelacion/${ahoId}/${penalizacion}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  // async cancelacion(
  //   ahoId,
  //   penalizacion,
  //   usuId,
  //   formaPago,
  //   certificado,
  //   plazo,
  //   monto
  // ) {
  //   if (token) {
  //     jwt = JSON.parse(token);
  //   }
  //   console.log( ahoId,
  //     penalizacion,
  //     usuId,
  //     formaPago)
  //   try {
  //     const response = await axios.post(
  //       api.baseUrl() +
  //         `/ahomovimientodiario/cancel/${ahoId}/${penalizacion}/${usuId}/${formaPago}/${certificado}/${plazo}/${monto}`,
  //       {
  //         headers: { Authorization: `Bearer ${jwt.jwt}` },
  //       }
  //     );
  //     return response?.data;
  //   } catch (err) {
  //     addressingError(err);
  //   }
  // }

  async cancelacion(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    //console.log(data)
    try {
      const response = await axios.post(
        api.baseUrl() + `/ahomovimientodiario/cancel`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    }catch (err) {
      console.log(err);
      // addressingError(err);
    }
  }
}
