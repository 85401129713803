import React from "react";
import classNames from "classnames";
import { InputText } from "primereact/inputtext";
//FUNCIONES
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
export const NuevoOrigen = ({ origen, setOrigen, submitted }) => {
  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col">
        <label>Descripcion</label>
        <InputText
        id="des"
            value={origen.preOriDescripcion}
            onChange={(e) =>
              onChajeObjN(e, "preOriDescripcion", origen, setOrigen)
            }
            placeholder="Descripcion"
            className={classNames({
              "p-invalid": submitted && !origen.preOriDescripcion,
            })}
            onClick={(e) =>selectText(e)}
          />
          {submitted && !origen.preOriDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}

        </div>
      </div>
    </div>
  );
};
