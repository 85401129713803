import React, { useState, useEffect } from 'react'
import { PickList } from 'primereact/picklist'
//import ProductService from '../../../../../src/service/ProductService'
import './../../../../utils/PickListDemo.css'

//import Services
// import AppAuthorityService from '../../../../../src/service/AppServices/AppAuthorityService'
// import AppAssignAuthorityService from '../../../../service/AppServices/AppAssignAuthorityService'
import AppAssignRoleService from '../../../../service/AppServices/AppAssignRoleService'

import AppRoleService from '../../../../service/AppServices/AppRoleService'

export const ViewRoleUser = ({ appUsuario }) => {
  let emptyAssignRole = {
    assignRoleId: 0,
    rolId: 0,
    usuUsuario: '',
  }
  const [appAssignRole, setAppAssignRole] = useState(emptyAssignRole)
  const [appAssignRoleAll, setAppAssignRoleAll] = useState([])
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  //const appAuthorityService = new AppAuthorityService()
  // const appAssignRoleService = new AppAssignAuthorityService()
  const appAssignRoleService = new AppAssignRoleService()
  const appRoleService = new AppRoleService()

  useEffect(() => {
    appRoleService
      .findByUsernameNotAssign(appUsuario.usuUsuario)
      .then((data) => {
       // console.log(data)
        setSource(data)
      })
      .catch((err) => {})

    appAssignRoleService
      .findByUsernameAssign(appUsuario.usuUsuario)
      .then((data) => {
        setTarget(data)
      })
      .catch((err) => {})
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (event) => {
    setSource(event.source)
    setTarget(event.target)
  }

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <span className="product-category">
            {item?.rolName || item?.appRole?.rolName}
          </span>
        </div>
      </div>
    )
  }

  const refreshAuthorities = () => {
    setAppAssignRole(emptyAssignRole)
    appRoleService
      .findByUsernameNotAssign(appUsuario.usuUsuario)
      .then((data) => {
        setSource(data)
      })
    appAssignRoleService
      .findByUsernameAssign(appUsuario.usuUsuario)
      .then((data) => {
        setTarget(data)
      })
  }

  const addNewAuthority = (data) => {
    let _appAssignRole = { ...appAssignRole }
    _appAssignRole.rolId = data.rolId
    _appAssignRole.usuUsuario = appUsuario.usuUsuario

    appAssignRoleService.save(_appAssignRole).then((data) => {
      refreshAuthorities()
    })
  } // end addNewAuthority
  const removeAuthority = (data) => {
    appAssignRoleService.delete(data).then((data) => {
      refreshAuthorities()
    })
  } // end removeAuthority

  const addNewAuthorityAll = (data) => {
    data.forEach((item) => {
      let _appAssignRoleAll = { ...emptyAssignRole }
      _appAssignRoleAll.rolId = item.rolId
      _appAssignRoleAll.usuUsuario = appUsuario.usuUsuario
      appAssignRoleAll.push(_appAssignRoleAll)
    })
    appAssignRoleService.saveAll(appAssignRoleAll).then((data) => {
      setAppAssignRoleAll([])
      refreshAuthorities()
    })
  }

  const removeAuthorityAll = (data) => {
    appAssignRoleService.deleteAll(data).then((data) => {
      refreshAuthorities()
    })
  }

  return (
    <div className="picklist-demo">
      <PickList
        source={source}
        target={target}
        itemTemplate={itemTemplate}
        sourceHeader="No Asignados"
        targetHeader="Asignados"
        sourceStyle={{ height: '700px' }}
        targetStyle={{ height: '700px' }}
        showSourceControls={false}
        showTargetControls={false}
        onMoveToTarget={(e) => addNewAuthority(e.value[0])}
        onMoveToSource={(e) => removeAuthority(e.value[0])}
        // onMoveToTarget={(e) => console.log(e.value[0])}
        // onMoveToSource={(e) => console.log(e.value[0])}
        onMoveAllToTarget={(e) => addNewAuthorityAll(e.value)}
        onMoveAllToSource={(e) => removeAuthorityAll(e.value)}
        onChange={onChange}
      ></PickList>
    </div>
  )
}
