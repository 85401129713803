import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import AppDisponibilidadDetalleService from "../../../../service/AppServices/AppDisponibilidadDetalleService";
import ConControlDisponibilidadService from "../../../../service/ConContabilidad/ConControlDisponibilidadService";

export const CamposLiquidez = ({
  disId,
  disFecha,
  sucId,
  conTransaccionLiquidez,
  gruDisponibilidad,
  proveniencia,
}) => {
  const toast = useRef(null);
  const conControlDisponibilidadService = new ConControlDisponibilidadService();

  const conControlDisponibilidad = {
    appDisponibilidadDetalle: {
      appDisponibilidadProveniencia: {
        disId: 0,
        disNombre: "",
        gruDisponibilidadId: 0,
      },
      detDisAceptaTrx: 0,
      detDisId: 0,
      detDisNombre: "",
      disId: 0,
    },
    detDisId: 0,
    disFecha: "",
    disId: 0,
    disMonto: 0,
    disStatus: 0,
    disTasaInversion: 0,
    disVencimientoInversion: "",
    sucId: 0,
  };

  const appDisponibilidadDetalleEmpty = {
    detDisAceptaTrx: true,
    detDisId: 0,
    detDisNombre: "",
    disId: 0,
  };

  const conControlDisponibilidadEmpty = {
    detDisId: 0,
    disFecha: "",
    disId: 0,
    disMonto: 0,
    disStatus: 0,
    disTasaInversion: 0,
    disVencimientoInversion: "",
    sucId: 0,
  };

  const [conControlDisponibilidades, setConControlDisponibilidades] = useState([
    conControlDisponibilidad,
  ]);

  //State para guardar los datos de la disponibilidad
  const [appDisponibilidadDetalle, setAppDisponibilidadDetalle] = useState(
    appDisponibilidadDetalleEmpty
  );
  const [appDisponibilidadDetalles, setAppDisponibilidadDetalles] = useState(
    []
  );
  const [appDisponibilidadDetalleState, setAppDisponibilidadDetalleState] =
    useState(appDisponibilidadDetalleEmpty);

  //State Dialog
 // const [position, setPosition] = useState(0);
  const [dialogNuevoDetalle, setDialogNuevoDetalle] = useState(false);

  //State Nuevo Detalle
  const [nuevoDetalle, setNuevoDetalle] = useState(false);
  const [detDisAceptaTrx, setDetDisAceptaTrx] = useState(false);

  //State para validar los campos
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    conControlDisponibilidadService
      .dateandbranchprovenance(disFecha, sucId, disId)
      .then((response) => {
        setConControlDisponibilidades(response);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disFecha]);

  const changeDatos = (mon, item, nombreCampo) => {
    conControlDisponibilidades.forEach(function (dato) {
      if (dato?.disId === item.disId) {
        dato[`${nombreCampo}`] = String(mon).replace(/,/g, "");

        if ("disStatus" === nombreCampo) {
          onSave(dato);
        }
      }
    });
  };

  function onSave(dato) {
    conControlDisponibilidadService.save(dato).then(() => {
      toast.current.show({
        severity: "success",
        summary: "Éxitoso!",
        detail: "Datos guardados correctamente",
        life: 4000,
      });
      conControlDisponibilidadService
        .dateandbranchprovenance(disFecha, sucId, disId)
        .then((response) => {
          setConControlDisponibilidades(response);
        });
    });
  }

  // useEffect(() => {
  //   const appDisponibilidadDetalleService =
  //     new AppDisponibilidadDetalleService();
  //   appDisponibilidadDetalleService
  //     .findAllByProvenance(disId)
  //     .then((response) => {
  //       setAppDisponibilidadDetalles(response);
  //       console.log(response);
  //     });
  // }, [disId, nuevoDetalle === false]);

  const listarCamposGrupo = () =>
    conControlDisponibilidades.map((item) => (
      <div className="p-grid p-ml-2 ">
        <div className="p-col-11">
          <div className="p-formgrid p-grid" key={item.detDisId}>
            <Toast ref={toast} />
            <div className="p-field p-col-3" style={{ marginTop: "30px" }}>
              <label htmlFor="in">
                {item.appDisponibilidadDetalle.detDisNombre}
              </label>
            </div>
            <div className="p-field p-col">
              <label htmlFor="asoId">Fecha Control</label>
              <InputText
                id="conDisFecha"
                key={item.detDisId}
                type="date"
                value={item.disFecha}
                disabled={true}
              />
            </div>
            <div
              className="p-field p-col"
              style={{
                display: disId !== 4 ? `${disId !== 3 ? "none" : ""}` : "",
              }}
            >
              <label htmlFor="asoId">Fecha Vence Inversión</label>
              <InputText
                id="conDisFechaVencimientoInversion"
                key={item.detDisId}
                name="disVencimientoInversion"
                type="date"
                value={item.disVencimientoInversion}
                onChange={(e) =>
                  changeDatos(e.target.value, item, "disVencimientoInversion")
                }
                disabled={
                  item.disStatus === 2 || item.disStatus === 1 ? true : false
                }
              />
            </div>
            <div
              className="p-field p-col"
              style={{
                display: disId !== 4 ? `${disId !== 3 ? "none" : ""}` : "",
              }}
            >
              <label htmlFor="asoId">Tasa Inv.</label>
              <InputText
                id="conDisTasaInversion"
                key={item.detDisId}
                type="number"
                step="0.01"
                value={item.disTasaInversion}
                onChange={(e) =>
                  changeDatos(e.target.value, item, "disTasaInversion")
                }
                disabled={
                  item.disStatus === 2 || item.disStatus === 1 ? true : false
                }
              />
            </div>
            <div className="p-field p-col">
              <label htmlFor="asoId">Monto Q.</label>
              <InputNumber
                align="right"
                id="conDisMonto"
                name="disMonto"
                key={item.detDisId}
                step="0.01"
                value={item?.disMonto}
                mode="decimal"
                minFractionDigits={2}
                locale="gt-GT"
                currency="GTQ"
                onBlur={(e) => {
                  changeDatos(e.target.value, item, "disMonto");
                }}
                disabled={
                  item.disStatus === 2 ||
                  item.disStatus === 1 ||
                  item.appDisponibilidadDetalle.detDisAceptaTrx === 1
                    ? true
                    : false
                }
              />
            </div>
            <div className="p-field p-col-1">
              <div className="p-field-checkbox" style={{ marginTop: "30px" }}>
                <Checkbox
                  detDisId
                  key={item.detDisId}
                  name="disStatus"
                  checked={item.disStatus === 0 ? false : true}
                  onChange={(e) => {
                    changeDatos(
                      e.target.checked === true ? 1 : 0,
                      item,
                      "disStatus"
                    );
                  }}
                  disabled={
                    item.disStatus === 2 || item.disStatus === 1 ? true : false
                  }
                />
                <label htmlFor="checkOption1">Verificado</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  //Methods Dialog
  const dialogFuncMap = {
    dialogNuevoDetalle: setDialogNuevoDetalle,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);
    const appDisponibilidadDetalleService =
      new AppDisponibilidadDetalleService();
    appDisponibilidadDetalleService
      .findAllByProvenance(disId)
      .then((response) => {
        setAppDisponibilidadDetalles(response);
        console.log(response);
      });

    // if (position) {
    //   console.log(position);
    //   setPosition(position);
    // }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const footerTemplate = () => (
    <>
      <Button
        onClick={() => onHide("dialogNuevoDetalle")}
        icon="pi pi-times"
        label="Cancelar"
        className="mr-2 p-button-danger"
      ></Button>
      <Button
        onClick={() => onSaveNewDetalle()}
        icon="pi pi-check"
        label="Confirmar"
        className="mr-2"
      ></Button>
    </>
  );

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _appDisponibilidadDetalleState = { ...appDisponibilidadDetalleState };
    _appDisponibilidadDetalleState[`${name}`] = val;
    setAppDisponibilidadDetalleState(_appDisponibilidadDetalleState);
  };

  const onSaveNewDetalle = () => {
    setSubmitted(true);

    if (nuevoDetalle === "true") {
      if (appDisponibilidadDetalleState.detDisNombre !== "") {
        let _appDisponibilidadDetalleState = {
          ...appDisponibilidadDetalleState,
        };
        _appDisponibilidadDetalleState[`disId`] = proveniencia.disId;
        _appDisponibilidadDetalleState[`detDisAceptaTrx`] =
          detDisAceptaTrx === true ? 1 : 0;
        const appDisponibilidadDetalleService =
          new AppDisponibilidadDetalleService();

        //   '_appDisponibilidadDetalleState',
        //   _appDisponibilidadDetalleState,
        // )
        appDisponibilidadDetalleService
          .save(_appDisponibilidadDetalleState)
          .then((response) => {
            let _conControlDisponibilidadState = {
              ...conControlDisponibilidadEmpty,
            };

            _conControlDisponibilidadState[`disFecha`] = disFecha;
            _conControlDisponibilidadState[`detDisId`] = response.detDisId;
            _conControlDisponibilidadState[`disMonto`] = 0;
            _conControlDisponibilidadState[`sucId`] = sucId;
            _conControlDisponibilidadState[`disStatus`] = 0;
            // console.log(
            //   '_conControlDisponibilidadState',
            //   _conControlDisponibilidadState,
            // )
            const conControlDisponibilidadService =
              new ConControlDisponibilidadService();
            conControlDisponibilidadService
              .save(_conControlDisponibilidadState)
              .then(() => {
                conControlDisponibilidadService
                  .dateandbranchprovenance(disFecha, sucId, disId)
                  .then((response) => {
                    // console.log('detalles campos', response)
                    setConControlDisponibilidades(response);
                  });

                onHide("dialogNuevoDetalle");
                toast.current.show({
                  severity: "success",
                  summary: "Éxitoso!",
                  detail: "Datos guardados correctamente",
                  life: 4000,
                });
              });
          });
        setAppDisponibilidadDetalleState(appDisponibilidadDetalleEmpty);
        setSubmitted(false);
        setNuevoDetalle(false);
        setDetDisAceptaTrx(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error!",
          detail: "Debe ingresar un nombre del detalle",
          life: 4000,
        });
      }
    } else {
      if (appDisponibilidadDetalle.detDisId) {
        let _conControlDisponibilidadState = {
          ...conControlDisponibilidadEmpty,
        };

        _conControlDisponibilidadState[`disFecha`] = disFecha;
        _conControlDisponibilidadState[`detDisId`] =
          appDisponibilidadDetalle.detDisId;
        _conControlDisponibilidadState[`disMonto`] = 0;
        _conControlDisponibilidadState[`sucId`] = sucId;
        _conControlDisponibilidadState[`disStatus`] = 0;
        _conControlDisponibilidadState[`disId`] = 0;
        // console.log(
        //   '_conControlDisponibilidadState',
        //   _conControlDisponibilidadState,
        // )
        const conControlDisponibilidadService =
          new ConControlDisponibilidadService();
        conControlDisponibilidadService
          .save(_conControlDisponibilidadState)
          .then(() => {
            conControlDisponibilidadService
              .dateandbranchprovenance(disFecha, sucId, disId)
              .then((response) => {
                setConControlDisponibilidades(response);
              });

            onHide("dialogNuevoDetalle");
            toast.current.show({
              severity: "success",
              summary: "Éxitoso!",
              detail: "Datos guardados correctamente",
              life: 4000,
            });
          });

        setAppDisponibilidadDetalle(appDisponibilidadDetalleEmpty);
        setSubmitted(false);
        setNuevoDetalle(false);
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error!",
          detail: "Debe de seleccionar un detalle",
          life: 4000,
        });
      }
    }
  };

  return (
    <>
      {listarCamposGrupo()}
      <Toast ref={toast} />
      <Toolbar
        right={() => (
          <Button
            onClick={onClick.bind(this, "dialogNuevoDetalle", 0)}
            icon="pi pi-plus"
            label="Nuevo"
            className="mr-2 p-button-success"
            tooltip="Nuevo registro"
          />
        )}
      ></Toolbar>
      <Dialog
        visible={dialogNuevoDetalle}
        header="Nuevo Detalle"
        onHide={() => onHide("dialogNuevoDetalle")}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        footer={footerTemplate}
      >
        <div className="p-col-12 p-md-12">
          <div className="card">
            <div className=" p-fluid">
              <h5>Datos generales</h5>

              <div className="p-field">
                <label htmlFor="input1">Grupo</label>
                <InputText
                  id="input1"
                  type="text"
                  value={gruDisponibilidad.gruDiponibilidadNombre}
                  disabled
                />
              </div>
              <div className="p-field">
                <label htmlFor="input1">Proveciencia</label>
                <InputText
                  id="input1"
                  type="text"
                  value={proveniencia.disNombre}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="card">
            <h5>Tipo detalle</h5>
            <div className=" p-fluid">
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-2">
                  <div className="field-radiobutton">
                    <RadioButton
                      value="true"
                      name="tipoDetalle"
                      onChange={(e) => setNuevoDetalle(e.value)}
                      checked={nuevoDetalle === "true"}
                    />
                    <label htmlFor="city1">Detalle nuevo</label>
                  </div>
                </div>
                <div className="p-field p-col-3">
                  <div className="field-radiobutton">
                    <RadioButton
                      value="false"
                      name="tipoDetalle"
                      onChange={(e) => setNuevoDetalle(e.value)}
                      checked={nuevoDetalle === "false"}
                    />
                    <label htmlFor="city1">Detalle existe</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card"
            style={{ display: nuevoDetalle === "true" ? "" : "none" }}
          >
            <div className=" p-fluid">
              <h5>Detalle Nuevo</h5>

              <div className="p-field">
                <label htmlFor="input1">Nombre nuevo detalle</label>
                <InputText
                  id="input1"
                  type="text"
                  value={appDisponibilidadDetalleState.detDisNombre}
                  onChange={(e) => onInputChange(e, "detDisNombre")}
                  className={classNames({
                    "p-invalid":
                      submitted && !appDisponibilidadDetalleState.detDisNombre,
                  })}
                />
                {submitted && !appDisponibilidadDetalleState.detDisNombre && (
                  <small className="p-invalid">
                    Nombre de detalle es requerido
                  </small>
                )}
              </div>
              <div className="p-field">
                <label htmlFor="input1">Acepta Transacción</label>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-1">
                  <div className="field-radiobutton">
                    <RadioButton
                      value={true}
                      name="detDisAceptaTrx"
                      onChange={(e) => setDetDisAceptaTrx(e.value)}
                      checked={detDisAceptaTrx === true}
                    />
                    <label htmlFor="city1">Sí</label>
                  </div>
                </div>
                <div className="p-field p-col-3">
                  <div className="field-radiobutton">
                    <RadioButton
                      value={false}
                      name="detDisAceptaTrx"
                      onChange={(e) => setDetDisAceptaTrx(e.value)}
                      checked={detDisAceptaTrx === false}
                    />
                    <label htmlFor="city1">No</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="card"
            style={{ display: nuevoDetalle === "false" ? "" : "none" }}
          >
            <div className=" p-fluid">
              <h5>Detalle Existente</h5>

              <div className="p-field">
                <label htmlFor="input1">Seleccione el detalle existente</label>
                <Dropdown
                  // id="perTipoId"
                  // name="perTipoId"
                  value={appDisponibilidadDetalle}
                  onChange={(e) => {
                    setAppDisponibilidadDetalle(e.value);
                  }}
                  options={appDisponibilidadDetalles}
                  optionLabel="detDisNombre"
                  placeholder="Selecciones un detalle"
                  className={classNames({
                    "p-invalid":
                      submitted && !appDisponibilidadDetalle.detDisId,
                  })}
                ></Dropdown>
                {submitted && !appDisponibilidadDetalle.detDisId && (
                  <small className="p-invalid">Seleccione un detalle</small>
                )}
              </div>
              {/* <div className="p-field">
              <label htmlFor="input1">Proveciencia</label>
              <InputText id="input1" type="text" value={proveniencia.disNombre} disabled />
            </div> */}
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
