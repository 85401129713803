import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
//Component
import { Pagination } from "../../../../components/Pagination";
import { MenuNuevoAhoProducto } from "./MenuNuevoAhoProducto";
//Servicios
import AppTipoSubProductoService from "../../../../service/AppServices/AppTipoSubProductoService";

export const AhoProducto = () => {
  const toast = useRef();
  let filtroEmpty = {
    nombre: "",
  };
  let newProductoEmpty = {
    ahoTipoTasa: "",
    appAhoTipoCuenta: {
      subTipoNombre: "",
      subtipoId: 0,
    },
    appMoneda: {
      monAlfanumerico: "",
      monId: 0,
      monNombre: "",
      monSimbolo: "",
    },
    appProducto: {
      appTipoProducto: {
        proTipoId: 0,
        proTipoNombre: "",
      },
      proId: 0,
      proNombre: "",
      proTipoId: 0,
    },
    appTasa: {
      appTasaRangoList: [
        {
          tasId: 0,
          tasRangoFechaVigencia: "",
          tasRangoId: 0,
          tasRangoMontoFinal: 0,
          tasRangoMontoInicial: 0,
          tasRangoPorcentaje: 0,
        },
      ],
      appTipoTasa: {
        tasTipoId: 0,
        tasTipoNombre: "",
      },
      tasFechaVigencia: "",
      tasId: 0,
      tasNombre: "",
      tasTipoId: 0,
      tasUnica: 0,
    },
    monId: 0,
    proId: 0,
    subAhoId: 0,
    subAhoNombre: "",
    subAhoPenalizacion: "",
    subAhoTasaIsr: 0,
    subAhoTasaPenaliza: 0,
    subAhoPagaIsr: "",
    subTipoId: 0,
    tasId: 0,
  };
  const [newProducto, setNewProducto] = useState(newProductoEmpty);

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [ahoProducto, setAhoProducto] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevoAhoProducto, setNuevoAhoProducto] = useState(false);
  const [estado, setEstado] = useState(false);
  useEffect(() => {
    const ahoProducto = new AppTipoSubProductoService();
    ahoProducto
      .findByPage(pageNumber, filtro.nombre.toUpperCase())
      .then((response) => {
        setAhoProducto(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      }); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const ahoProducto = new AppTipoSubProductoService();
      ahoProducto
        .findByPage(pageNumber, filtro.nombre.toUpperCase())
        .then((response) => {
          setAhoProducto(response?.content);
          setTotalPages(response?.totalPages);
          setPageNumber(response?.number);
          setFirstPage(response?.first);
          setLastPage(response?.last);
          if (filtro.nombre !== "") {
            setPageNumber(0);
          }
        });
    }
  };

  function siNoIsr(rowData) {
    return <>{rowData?.subAhoPagaIsr === "S" ? "SI" : "NO"}</>;
  }

  function siNoPenalizacion(rowData) {
    return <>{rowData?.subAhoPenalizacion === "S" ? "SI" : "NO"}</>;
  }

  function porcentajeIsr(rowData) {
    return <>{rowData?.subAhoTasaIsr}%</>;
  }
  function porcentajePenalizacion(rowData) {
    return <>{rowData?.subAhoTasaPenaliza}%</>;
  }

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoAhoProducto(true); setNewProducto(rowData)}}
        />
      </>
    );
  }

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function left() {
    return (
      <>
        <div className="p-fluid p-grid" onKeyDown={(e) => fil(e)}>
          <div className="p-col">
            <label>Descripcion</label>
            <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText
                value={filtro.nombre}
                type="search"
                onChange={(e) => {
                  setFiltro({
                    ...filtro,
                    nombre: e.target.value.toUpperCase(),
                  });
                }}
                placeholder="Buscar.."
              />
            </span>
          </div>
        </div>
      </>
    );
  }

  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={() => {
            setNuevoAhoProducto(true); setNewProducto(newProductoEmpty)
          }}
        />
      </>
    );
  }

  function tipoTasa(rowData) {
    return <>{rowData?.appTasa?.tasNombre || "TASA A PLAZO"}</>;
  }

  function rederTabale() {
    return (
      <DataTable
        value={ahoProducto}
        footer={renderPaginacion()}
        responsiveLayout="scroll"
      >
        <Column field="subAhoId" header="Id" style={{ textAlign: "center" }} />

        <Column field="appProducto.proNombre" header="Nombre del Producto" />
        <Column field="subAhoNombre" header="Nombre Sub Producto Ahorro" />
        <Column
          field="appTasa.tasNombre"
          body={tipoTasa}
          header="Tipo de Tasa"
        />
        <Column
          field="subAhoPenalizacion"
          body={siNoPenalizacion}
          header="Tiene Penalización"
          style={{ textAlign: "center" }}
        />
        <Column
          field="subAhoTasaPenaliza"
          header="Tasa Personalizada"
          body={porcentajePenalizacion}
          style={{ textAlign: "center" }}
        />
        <Column
          field="subAhopagaIsr"
          body={siNoIsr}
          header="Paga ISR"
          style={{ textAlign: "center" }}
        />
        <Column
          field="subAhoTasaIsr"
          header="Tasa ISR"
          body={porcentajeIsr}
          style={{ textAlign: "center" }}
        />

        <Column
          header="Acciones"
          body={acciones}
          style={{ textAlign: "center" }}
        />
      </DataTable>
    );
  }

  return (
    <div>
      <div className="card">
        <Toolbar className="p-mb-4" left={left} right={right} />
      </div>
      <div className="card">{rederTabale()}</div>

      <Dialog
        visible={nuevoAhoProducto}
        onHide={() => {
          setNuevoAhoProducto(false);
          toast.current.show({
            severity: "warn",
            summary: "Alerta",
            detail: "Producto no creado/actualizado",
            life: 4000,
          })
        }}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "60vw" }}
        header={"Nuevo Porducto de Ahorro"}
      >
        <MenuNuevoAhoProducto
          newProducto={newProducto}
          setNuevoAhoProducto={setNuevoAhoProducto}
          toast={toast}
          estado={estado}
          setNewProducto={setNewProducto}
          setEstado={setEstado}
        />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
