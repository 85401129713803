
import React, { useEffect, useState } from "react";

import { Chart } from "primereact/chart";

//servicios
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";
export const GraAgencias = ({eventId}) => {
  const [labels, setLabels] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [inscritos, setInscritos] = useState([]);

  useEffect(() => {
    const eventParticipant = new EventoParticipanteService();
    eventParticipant.getByEvenBranchEvyId(eventId).then((res) => {
      if (res?.length > 0) {
        var labelsVar = [];
        var participantesVar = [];
        var inscritosVar = [];

        res?.forEach((element) => {
          labelsVar.push(element?.branch);
          participantesVar.push(element?.totalParticipants);
          inscritosVar.push(element?.totalEnroll);
        });
        setLabels(labelsVar);
        setInscritos(inscritosVar);
        setParticipantes(participantesVar);
      }
    });
  }, [eventId]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Inscritos",
        data:  inscritos ,
        backgroundColor: 'rgba(15,151,199,0.2)',
        borderColor: 'rgba(15,151,199,1)',
        pointBackgroundColor: 'rgba(15,151,199,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(15,151,199,1)',
      },
      {
        label: "Participantes",
        data:participantes ,
        backgroundColor: 'rgba(185,68,214,0.2)',
        borderColor: 'rgba(185,68,214,1)',
        pointBackgroundColor: 'rgba(185,68,214,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(185,68,214,1)',
      },
    ],
  };

  


  return (
       <Chart type="radar" id="revenue-chart" data={data} ></Chart>
  );
};
