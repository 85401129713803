import React, { useEffect, useState,useRef } from "react";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages"
import { Dialog } from "primereact/dialog";
//servicios
import AppPreDeduccionService from "../../../../service/AppServices/AppPreDeduccionService";

//Componentes
import { Pagination } from "../../../../components/Pagination";
import { NuevaDeduccion } from "./NuevaDeduccion";

//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";

export const Deduccion = () => {
    const toast = useRef();
    const message = useRef();
  let filtroEmpty = {
    descripcion: "",
  };
  let deduccionEmpty = {
    preDedDescripcion: "",
    preDedId: 0
  }

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [deduccion,setDeduccion] = useState(deduccionEmpty)
  const [deducciones, setDeducciones] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [estado, setEstado] = useState(false);
  const [nuevaDeduccion,setNuevaDeducciion] = useState (false)
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  useEffect(() => {
    const deduccionesService = new AppPreDeduccionService();
    deduccionesService
      .findByPage(pageNumber, filtro.descripcion)
      .then((result) => {
        setDeducciones(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);
  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const deduccionesService = new AppPreDeduccionService();
      deduccionesService
        .findByPage(pageNumber, filtro.descripcion)
        .then((result) => {
          setDeducciones(result?.content);
          setTotalPages(result?.totalPages);
          setPageNumber(result?.number);
          setFirstPage(result?.first);
          setLastPage(result?.last);
          if (filtro.descripcion !== "") {
            setPageNumber(0);
          }
        });
    }
  };


  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }
  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning  mr-2 p-mr-2"
          icon="pi pi-pencil"
         onClick={() => {setDeduccion(rowData); setNuevaDeducciion(true);setSubmitted(false)}}
        />
        <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setDeduccion(rowData); setEliminar(true)}}
        />
      </>
    );
  }

  const renderTable = (
    <>
      <DataTable
        emptyMessage="No se encontraron datos"
        value={deducciones}
        footer={renderPaginacion()}
      >
        <Column header="Id" field="preDedId" />
        <Column header="Descripcion" field="preDedDescripcion" />
        <Column header="Acciones" body={acciones} />
      </DataTable>
    </>
  );


  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            type={"search"}
            className="p-inputtext-sm"
            value={filtro.descripcion}
            onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
          />
          <label>Garantia</label>
        </span>
      </div>
    );
  }
  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {setNuevaDeducciion(true); setDeduccion(deduccionEmpty);setSubmitted(false)}}
        />
      </>
    );
  }
  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-button-text p-mr-2 "
          onClick={() => setNuevaDeducciion(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save(){
    if(deduccion.preDedDescripcion !== ""  ){
        const deduccionesService = new AppPreDeduccionService();
        deduccionesService.save(deduccion).then(()=>{
            setNuevaDeducciion(false);
            setEstado(!estado)
            toast.current.show({
                severity: "success",
                summary: "Tarea realizada con exito",
                detail: `Deducciion creada con exito`,
                life: 4000,
              });
        })
    }else{
        setSubmitted(true);
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que los campos necesarios no esten vacios",
          life: 4000,
        });

    }


  }

  const elimnarDeduccion = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={borrar} />
    </>
  );

  function borrar(){
    const deduccionesService = new AppPreDeduccionService();
    deduccionesService.delete(deduccion.preDedId).then((res)=>{
        setEliminar(false);
        setEstado(!estado)
        toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Deduccion eliminada con exito",
            life: 4000,
          });

    })

  }

  return (
    <>
      <div className="card">
        <Toolbar className="p-mb-4"  left={left} right={right}/>
        {renderTable}
      </div>

      <Dialog
        visible={nuevaDeduccion}
        onHide={() => {setNuevaDeducciion(false);setSubmitted(false)}}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
        header={ deduccion.preDedId !== 0 ? "Editar Deduccion":"Nueva Deduccion"}
      >
        <NuevaDeduccion deduccion={deduccion} setDeduccion={setDeduccion} submitted={submitted}/>
       
        <Messages ref={message} />
      </Dialog>

      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={elimnarDeduccion}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar esta deduccion .</span>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
