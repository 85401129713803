export default function numberToLetters(number) {
  function unidades_nal(n) {
    if (n + "" === "1") {
      return "Uno";
    }
    if (n + "" === "2") {
      return "Dos";
    }
    if (n + "" === "3") {
      return "Tres";
    }
    if (n + "" === "4") {
      return "Cuatro";
    }
    if (n + "" === "5") {
      return "Cinco";
    }
    if (n + "" === "6") {
      return "Seis";
    }
    if (n + "" === "7") {
      return "Siete";
    }
    if (n + "" === "8") {
      return "Ocho";
    }
    if (n + "" === "9") {
      return "Nueve";
    }

    if (n + "" === "10") {
      return "Diez";
    }
    if (n + "" === "11") {
      return "Once";
    }
    if (n + "" === "12") {
      return "Doce";
    }
    if (n + "" === "13") {
      return "Trece";
    }
    if (n + "" === "14") {
      return "Catorce";
    }
    if (n + "" === "15") {
      return "Quince";
    }
    if (n + "" === "16") {
      return "Dieciseis";
    }
    if (n + "" === "17") {
      return "Diecisite";
    }
    if (n + "" === "18") {
      return "Dieciocho";
    }
    if (n + "" === "19") {
      return "Diecinueve";
    }
    if (n + "" === "20") {
      return "Veinte";
    }
    if (n + "" === "21") {
      return "Veintiuno";
    }
    if (n + "" === "22") {
      return "Veintidos";
    }
    if (n + "" === "23") {
      return "Veintitres";
    }
    if (n + "" === "24") {
      return "Veinticuatro";
    }
    if (n + "" === "25") {
      return "Veinticinco";
    }
    if (n + "" === "26") {
      return "Veintiseis";
    }
    if (n + "" === "27") {
      return "Veintisiete";
    }
    if (n + "" === "28") {
      return "Veintiocho";
    }
    if (n + "" === "29") {
      return "Veintinueve";
    }

    return "";
  }

  function decenas_nal(n) {
    if (n + "" === "1") {
      return "Diez";
    }
    if (n + "" === "2") {
      return "Veinte";
    }
    if (n + "" === "3") {
      return "Treinta";
    }
    if (n + "" === "4") {
      return "Cuartenta";
    }
    if (n + "" === "5") {
      return "Cincuenta";
    }
    if (n + "" === "6") {
      return "Sesenta";
    }
    if (n + "" === "7") {
      return "Setenta";
    }
    if (n + "" === "8") {
      return "Ochenta";
    }
    if (n + "" === "9") {
      return "Noventa";
    }

    return "";
  }

  function centenas_nal(n) {
    if (n + "" === "1") {
      return "Ciento";
    }
    if (n + "" === "2") {
      return "Docientos";
    }
    if (n + "" === "3") {
      return "Trecientos";
    }
    if (n + "" === "4") {
      return "Cuatrocientos";
    }
    if (n + "" === "5") {
      return "Quinientos";
    }
    if (n + "" === "6") {
      return "Seicientos";
    }
    if (n + "" === "7") {
      return "Setencientos";
    }
    if (n + "" === "8") {
      return "Ochocientos";
    }
    if (n + "" === "9") {
      return "Novecientos";
    }

    return "";
  }

  function numeroALetras(n) {
    var numero = parseInt(n + "");

    var respuesta = "";
    var residuo;
    var x;
    var dec ;
    if (numero > 999) {
      if ((numero + "").length > 6) {
         residuo = parseInt(numero + "") % 1000000;
         x = parseInt(numero / 1000000);

        if (x === 1) {
          respuesta = " Un millon" + numeroALetras(residuo);
        } else {
          respuesta = numeroALetras(x) + " millones" + numeroALetras(residuo);
        }
      } else if ((numero + "").length > 3) {
         residuo = parseInt(numero + "") % 1000;
         x = parseInt(numero / 1000);

        if (x === 1) {
          respuesta = "Mil" + numeroALetras(residuo);
        } else {
          respuesta = numeroALetras(x) + " mil" + numeroALetras(residuo);
        }
      }
    } else {
      if (numero === 100) {
        respuesta = " cien";
      } else if (numero > 100) {
        var cen = parseInt(numero / 100);
         dec = numero % 100;

        respuesta = " " + centenas_nal(cen) + numeroALetras(dec);
      } else {
         dec = numero % 100;

        if (dec < 30) {
          respuesta = " " + unidades_nal(dec);
        } else {
          var unis = dec % 10;
          var ddec = parseInt(dec / 10);

          if (unis !== 0) {
            respuesta = " " + decenas_nal(ddec) + " y " + unidades_nal(unis);
          } else {
            respuesta = " " + decenas_nal(ddec);
          }
        }
      }
    }

    return respuesta;
  }

  function montoL(monto) {
    var text;
    if (Number.isInteger(monto) === true) {
       text = numeroALetras(monto) + " quetzales exactos";
      return text.split("  " || "   " || "    ").join(" ");
    } else {
      var monto1 = `${monto}`;
      var mon = monto1.split(".");
      var mon1 = numeroALetras(mon[0]);
      var mon2 = numeroALetras(mon[1]);
       text = mon1 + " quetzales con" + mon2 + " centavos";
      return text.split("  " || "   " || "    ").join(" ");
    }
  }

  return montoL(number).toUpperCase();
}
