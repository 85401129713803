import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import { NuevaTarjeta } from "../NuevaTarjeta";
import TcrTarjetaService from "../../../service/TcrService/TcrTarjetaService";
import AppSucursalService from "../../../service/AppServices/AppSucursalService";
import AppSexoService from "../../../service/AppServices/AppSexoService";
import { Pagination } from "../../../components/Pagination";
//import { response } from "express";

export const ListarTarjetas = () => {

  const toast = useRef();
  const msgs = useRef(null);
  const token = localStorage.getItem("user");
  var user = JSON.parse(token).usr.usuUsuario;
  var appUser = JSON.parse(token).usr;

  let tcrTarjetaEmpty = {
    //appSucursal: appUser.appSucursal,
    //appUsuario: "",
    //appUsuarioModifica: null,
    asoId: 0,
    //cliAsociado: null,
    nombre: "",
    dirId: 0,
    fechaAceptaContrato: "",
    fechaIngreso: "",
    fechaPrimeraApertura: "",
    fechaVenceTarjeta: "",
    firma: 1,
    garantia: "",
    id: 0,
    impreso: 0,
    limiteMonedaExtrajera: 0,
    limiteMonedaNacional: 0,
    nombreTestigo: null,
    numIdentificacionTestigo: null,
    numeroTarjeta: "",
    sexIdTestigo: 0,
    plazo: 0,
    status: 1,
    sucId: appUser.appSucursal.sucId,
    tipoContrato: 1,
    usuarioIngresa: "",
    usuarioModifica: null,
  };

  let filterEmpty = {
    numeroTarjeta: "",
    asoId: 0,
  };

  const [tcrTarjeta, setTcrTarjeta] = useState(tcrTarjetaEmpty);
  const [tcrContratos, setTcrContratos] = useState([]);
  const [appSucursales, setAppSucursales] = useState([]);
  const [appSexos, setAppSexos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [showDialogNewTarjeta, setShowDialogNewTarjeta] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [prevValues, setPrevValues] = useState(filterEmpty);
  const [filter, setFilter] = useState(filterEmpty);

  useEffect(() => {
    const appSexoService = new AppSexoService();
    appSexoService.findAll().then((response) => {
      setAppSexos(response);
    });
  }, []);

  useEffect(() => {
    const appSucursalService = new AppSucursalService();
    appSucursalService.findAll().then((response) => {
      setAppSucursales(response);
    });
  }, []);

  useEffect(() => {
    recargarListaTarjetas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (filter.asoId !== prevValues.asoId) {
        recargarListaTarjetas();
        setPageNumber(0);
      }
      if (filter.numeroTarjeta !== prevValues.numeroTarjeta) {
        recargarListaTarjetas();
        setPageNumber(0);
      }
      setPrevValues({ ...filter });
    }, 1000); // Espera 1000 ms después del último cambio antes de recargar la lista
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.numeroTarjeta, filter.asoId]);

  function recargarListaTarjetas() {
    const tcrTarjetaService = new TcrTarjetaService();
    tcrTarjetaService
      .findByPage(pageNumber, filter.numeroTarjeta, filter.asoId)
      .then((response) => {
        setTcrContratos(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      });
  }

  const tipoContratoTemplate = (rowData) => {
    return (
      <div>
        <span>
          {rowData.tipoContrato === 1
            ? "Apertura de crédito"
            : rowData.tipoContrato === 2
            ? "Ampliación"
            : "Extra Financiamiento"}
        </span>
      </div>
    );
  };

  const nombreAsociadoTemplate = (rowData) => {
    return (
      <div>
        <span>
          {rowData.asoId} - {rowData.nombreCompleto}
        </span>
      </div>
    );
  };

  const montoMonedaNaCionalTemplate = (rowData) => {
    return (
      <div>
        <span>
          {rowData.limiteMonedaNacional.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    );
  };

  const formatDate = (value) => {
    const event = new Date(value);

    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "/" + mm + "/" + yy;

    return myDateString;
  };

  const fechaIngresoTemplate = (rowData) => {
    return (
      <div>
        <span>{formatDate(rowData.fechaIngreso)}</span>
      </div>
    );
  };

  /*const montoMonedaExtrajeraTemplate = (rowData) => {
    return (
      <div>
        <span>
          {rowData.limiteMonedaExtrajera.toLocaleString(undefined, {
            minimumFractionDigits: 2,
          })}
        </span>
      </div>
    );
  };*/

  const renderPaginacion = () => {
    return (
      <Pagination
        totalPages={totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        firstPage={firstPage}
        lastPage={lastPage}
      />
    );
  };



  const onSave = () => {
    if (tcrTarjeta.id === 0) {
      let tcrTarjetaNew = { ...tcrTarjeta };
      tcrTarjetaNew[`${"usuarioIngresa"}`] = user;
      tcrTarjetaNew[`${"appUsuario"}`] = appUser;
      tcrTarjetaNew[`${"fechaIngreso"}`] = new Date()
        .toISOString()
        .split("T")[0];
      delete tcrTarjetaNew.appUsuarioModifica;
      delete tcrTarjetaNew.appUsuario;
      delete tcrTarjetaNew.appSucursal;
      delete tcrTarjetaNew.usuarioModifica;
      validationForm(tcrTarjetaNew);
    } else {
      let tcrTarjetaNew = { ...tcrTarjeta };
      tcrTarjetaNew[`${"usuarioModifica"}`] = user; //usuarioModifica
      tcrTarjetaNew[`${"appUsuarioModifica"}`] = appUser; //usuarioModifica
      delete tcrTarjetaNew.appUsuario; //eliminamos el campo appUsuario
      validationForm(tcrTarjetaNew);
    }
  };

  const validationForm = (tcrTarjetaNew) => {
    if (tcrTarjeta.firma === 1) {
      saveValidation(tcrTarjetaNew);
    } else if (
      tcrTarjeta.nombreTestigo &&
      tcrTarjeta.numIdentificacionTestigo !== "" &&
      tcrTarjeta.sexIdTestigo !== 0
    ) {
      saveValidation(tcrTarjetaNew);
    } else {
      menssajeWarning();
    }
  };

  const saveValidation = (tcrTarjetaNew) => {
    if (
      tcrTarjeta.asoId !== 0 &&
      tcrTarjeta.numeroTarjeta !== "" &&
      tcrTarjeta.numeroTarjeta.length === 19 &&
      tcrTarjeta.fechaAceptaContrato !== "" &&
      tcrTarjeta.limiteMonedaNacional !== 0 &&
      tcrTarjeta.garantia !== "" &&
      tcrTarjeta.dirId !== 0 &&
      tcrTarjeta.fechaVenceTarjeta !== ""
    ) {
      setSubmitted(false);
      if (tcrTarjeta.tipoContrato === 1) {
        save(tcrTarjetaNew);
      } else if (
        tcrTarjeta.tipoContrato === 2 &&
        tcrTarjeta.fechaPrimeraApertura !== ""
      ) {
        save(tcrTarjetaNew);
      } else if (
        tcrTarjeta.tipoContrato === 3 &&
        tcrTarjeta.fechaPrimeraApertura !== "" &&
        tcrTarjeta.plazo !== 0
      ) {
        save(tcrTarjetaNew);
      } else {
        menssajeWarning();
      }
    } else {
      menssajeWarning();
    }
  };

  const menssajeWarning = () => {
    setSubmitted(true);
    msgs.current.show([
      {
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      },
    ]);
  };

  const save = (tcrTarjetaNew) => {
    setSubmitted(false);
    const tcrTarjetaService = new TcrTarjetaService();
    tcrTarjetaService.save(tcrTarjetaNew).then((res) => {
      if (res.code === 400){
        const claves = Object.keys(res.details);
        const mensaje = claves.map((clave) => (
          clave.charAt(0).toUpperCase()+clave.slice(1) + ": " + res.details[clave] + ", "
        ));
        toast.current.show({
          severity: "error",
          summary: res.message,
          detail: mensaje,
          life: 5000,
        });
      } else {
        setShowDialogNewTarjeta(false);
        setSubmitted(false);
        setTcrTarjeta(tcrTarjetaEmpty);
        recargarListaTarjetas();
        toast.current.show({
          severity: "success",
          summary: "Guardado",
          detail: "Se guardó correctamente",
          life: 5000,
        });
      }
      
    });
  };

  const onEdit = (rowData) => {
    setShowDialogNewTarjeta(true);
    const tarjeta = new TcrTarjetaService();
    tarjeta.getById(rowData.id).then(res => {
      res.data.nombre = rowData.nombreCompleto;
      setTcrTarjeta(res.data);
    });
  };

  const onDelete = (rowData) => {
    const tcrTarjetaService = new TcrTarjetaService();
    tcrTarjetaService.delete(rowData).then(() => {
      recargarListaTarjetas();
    });
  };

  const printContract = async (rowData) => {
    const tcrTarjetaService = new TcrTarjetaService();
    var reporte = await tcrTarjetaService.reportIdTipoTcrId(
      rowData.tipoContrato,
      rowData.id
    );
    var file = new Blob([reporte], { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    recargarListaTarjetas();
  };

  const accionesTarjeta = (rowData) => {
    return (
      <div>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => onEdit(rowData)}
        />
        <Button
          style={{ display: rowData.impreso === 1 ? "none" : "" }}
          className="p-button-danger p-button-rounded p-mr-2"
          icon="pi pi-trash"
          onClick={() => onDelete(rowData)}
        />
        <Button
          className="p-button-default p-button-rounded"
          icon="pi pi-print"
          tooltip="Imprimir"
          onClick={() => printContract(rowData)}
        />
      </div>
    );
  };

  const renderTableTarjeta = () => {
    return (
      <DataTable value={tcrContratos} footer={renderPaginacion()}>
        <Column field="id" header="Id" sortable={true} />
        <Column field="numeroTarjeta" header="Numero Tarjeta" sortable={true} />
        <Column header="Nombre" body={nombreAsociadoTemplate} sortable={true} />
        <Column
          header="Fecha Ingreso"
          body={fechaIngresoTemplate}
          sortable={true}
        />
        <Column
          header="Limite Moneda Nacional"
          body={montoMonedaNaCionalTemplate}
          sortable={true}
          bodyStyle={{ textAlign: "right", overflow: "visible" }}
        />
        <Column
          header="Tipo Contrato"
          body={tipoContratoTemplate}
          sortable={true}
        />
        <Column
          header="Acciones"
          body={accionesTarjeta}
          style={{ textAlign: "center" }}
          sortable={true}
        />
      </DataTable>
    );
  };

  const leftContents = (
    <>
      <div className="p-fluid p-col-6">
        <span className="p-float-label">
          <InputText
            onChange={(e) => setFilter({ ...filter, asoId: e.target.value })}
          />
          <label>Código Asociado</label>
        </span>
      </div>
      <div className="p-fluid p-col-12">
        <span className="p-float-label">
          <InputText
            mask="9999 9999 9999 9999"
            className="p-mr-2"
            onChange={(e) => setFilter({ ...filter, numeroTarjeta: e.target.value })
            }
          />
          <label>Número Tarjeta</label>
        </span>
      </div>
    </>
  );

  const rightContents = (
    <>
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="p-button-success p-mr-2"
        onClick={() => {
          setShowDialogNewTarjeta(true);
        }}
      />
    </>
  );

  const footerDialog = () => {
    return (
      <>
        <Messages ref={msgs} />
        <div>
          <Button
            label="Cancelar"
            className="p-button-danger"
            icon="pi pi-times"
            onClick={() => onHide()}
          />
          <Button
            label="Guardar"
            icon="pi pi-check"
            className="p-button-success"
            onClick={() => onSave()}
          />
        </div>
      </>
    );
  };

  const onHide = () => {
    setSubmitted(false);
    setShowDialogNewTarjeta(false);
    setTcrTarjeta(tcrTarjetaEmpty);
  };

  return (
    <>
      <div className="card">
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <div className="card">{renderTableTarjeta()}</div>

      <Dialog
        header="Datos de la Tarjeta"
        visible={showDialogNewTarjeta}
        maximizable={true}
        modal
        style={{ width: "50vw" }}
        footer={footerDialog}
        onHide={() => onHide()}
      >
        <NuevaTarjeta
          submitted={submitted}
          tcrTarjeta={tcrTarjeta}
          setTcrTarjeta={setTcrTarjeta}
          appSucursales={appSucursales}
          appSexos={appSexos}
          toast={toast}
        />
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};