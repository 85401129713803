import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { AutoComplete } from "primereact/autocomplete";
import { locale } from "primereact/api";
import { addLocale } from "primereact/api";
import { Calendar } from "primereact/calendar";
import { Fieldset } from "primereact/fieldset";

// Import Services
import CliDireccionService from "../../service/CliServices/CliDireccionService";
import CliPerfilEconomicoService from "../../service/CliServices/CliPerfilEconomicoService";
import AppActividadEconomicaService from "../../service/AppServices/AppActividadEconomicaService";
import AppMonedaService from "../../service/AppServices/AppMonedaService";

import AppPaisService from "../../service/AppServices/AppPaisService";
import AppDepartamentoService from "../../service/AppServices/AppDepartamentoService";
import AppMunicipioService from "../../service/AppServices/AppMunicipioService";
import AppTipoDireccionService from "../../service/AppServices/AppTipoDireccionService";

//Instalamos e importamos
import dateFormat from "dateformat";

const CliAsociadoPerfilEconomicoComponent = (props) => {


  const toast = useRef(null);

  let emptyCliPerfilEconomicoEmty = {
    actEcoId: 0,
    appActividadEconomica: {
      actEcoDescripcion: "",
      actEcoId: 0,
    },
    appMoneda: {
      monAlfanumerico: "",
      monId: 0,
      monNombre: "",
      monSimbolo: "",
    },
    asoId: 0,
    cliDireccion: {
      appDepartamento: {
        depCodigo: 0,
        depId: 0,
        depNombre: "",
        paiId: 0,
        regId: 0,
      },
      appMunicipio: {
        depCodigo: 0,
        depId: 0,
        munCodigo: 0,
        munId: 0,
        munNombre: "",
      },
      appPais: {
        paiCodAlpha2: "",
        paiCodAlpha3: "",
        paiId: 0,
        paiNombre: "",
        paiNombreEn: "",
        paiNumerico: "",
      },
      appTipoDireccion: {
        dirTipoId: 0,
        dirTipoNombre: "",
      },
      asoId: 0,
      depId: 0,
      dirDireccion: "",
      dirId: 0,
      dirTipoId: 0,
      munId: 0,
      paiId: 0,
    },
    dirId: 0,
    monId: 0,
    perEcoFechaRegistro: "",
    perEcoFolio: 0,
    perEcoId: 0,
    perEcoLibro: 0,
    perEcoMonto: 0,
    perEcoNombre: "",
    perEcoOtroTipoIngreso: 0,
    perEcoPuesto: "",
    perEcoRegistro: 0,
    perEcoSector: 0,
    perEcoTipo: 0,
    perEcoFechaInscripcionNegocio: "",
    perEcoDetalleOtrosIngresos: "",
  };

  // let emptyAppActividadEconomica = {
  //   actEcoDescripcion: "",
  //   actEcoId: 0,
  // };

  let emptyCliDireccion = {
    asoId: 0,
    depId: 0,
    dirDireccion: "",
    dirId: 0,
    dirTipoId: 0,
    munId: 0,
    paiId: 0,
    appDepartamento: {
      depCodigo: 0,
      depId: 0,
      depNombre: "",
      paiId: 0,
      regId: 0,
    },
    appMunicipio: {
      depCodigo: 0,
      depId: 0,
      munCodigo: 0,
      munId: 0,
      munNombre: "",
    },
    appPais: {
      paiCodAlpha2: "",
      paiCodAlpha3: "",
      paiId: 0,
      paiNombre: "",
      paiNombreEn: "",
      paiNumerico: "",
    },
    appTipoDireccion: {
      dirTipoId: 0,
      dirTipoNombre: "",
    },
  };

  let emptyAppMoneda = {
    monId: 0,
    monAlfanumerico: "",
    monNombre: "",
    monSimbolo: "",
  };

  // let emptyAppPais = {
  //   paiCodAlpha2: "",
  //   paiCodAlpha3: "",
  //   paiId: 0,
  //   paiNombre: "",
  //   paiNombreEn: "",
  //   paiNumerico: "",
  // };

  // let emptyAppDepartamento = {
  //   depCodigo: 0,
  //   depId: 0,
  //   depNombre: "",
  //   paiId: 0,
  //   regId: 0,
  // };
  // let emptyAppMunicipio = {
  //   depCodigo: 0,
  //   depId: 0,
  //   munCodigo: 0,
  //   munId: 0,
  //   munNombre: "",
  // };

  // let emptyAppTipoDireccion = {
  //   dirTipoId: 0,
  //   dirTipoNombre: "",
  // };

  const [appPaises, setAppPaises] = useState([]);
  const [appMunicipios, setAppMunicipios] = useState([]);
  const [appDepartamentos, setAppDepartamentos] = useState([]);
  const [appTipoDirecciones, setAppTipoDirecciones] = useState(
    []
  );
const [estado,setEstado] = useState(false)
  //State para manipulacion de los datos
  const [cliPerfilEconomico, setCliPerfilEconomico] = useState(
    emptyCliPerfilEconomicoEmty
  );
  const [cliPerfilesEconomicos, setCliPerfilesEconomicos] = useState([]);
  const [appActividadEconomicas, setAppActividadEconomicas] = useState(
    []
  );

  const [cliDirecciones, setCliDirecciones] = useState(emptyCliDireccion);
  const [appMonedas, setAppMenedas] = useState(emptyAppMoneda);

  const [dropdownItemTipoFuenteIngreso = 0, setDropdownItemTipoFuenteIngreso] =
    useState({ code: 0, name: "" });
  const dropdownItemTipoFuenteIngresos = [
    { name: "NEGOCIO PROPIO", code: 1 },
    { name: "RELACION DE DEPENDENCIA", code: 2 },
    { name: "OTRO INGRESO", code: 3 },
  ];

  const [dropdownItemSector, setDropdownItemSector] = useState(null);
  const dropdownItemSectores = [
    { name: "SECTOR PÚBLICO", code: 1 },
    { name: "SECTOR PRIVADO", code: 2 },
  ];

  useEffect(() => {
    const appPaisService = new AppPaisService();
    appPaisService.findAll().then((data) => {
      setAppPaises(data);
    });

    const appDepartamentoService = new AppDepartamentoService();
    appDepartamentoService.findAll().then((data) => setAppDepartamentos(data));
    const appMunicipioService = new AppMunicipioService();
    appMunicipioService.findAll().then((data) => setAppMunicipios(data));
    const appTipoDireccionService = new AppTipoDireccionService();
    appTipoDireccionService
      .findAll()
      .then((data) => setAppTipoDirecciones(data));
  }, []);

  useEffect(() => {
    const cliPerfilEconomicoService = new CliPerfilEconomicoService();
    cliPerfilEconomicoService
      .findAllByAsoId(props.asociado.asoId)
      .then((data) => {
        setCliPerfilesEconomicos(data);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado]);

  //Efectos que obtienen las informaciones de la useEffect(() => {
  useEffect(() => {
    const appActividadEconomicaService = new AppActividadEconomicaService();
    appActividadEconomicaService.findAll().then((data) => {
      setAppActividadEconomicas(data);
    });
  }, []);

  useEffect(() => {
    const cliDireccionesService = new CliDireccionService();
    cliDireccionesService.findAllByAsoId(props.asociado.asoId).then((data) => {
      setCliDirecciones(data);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appMonedaService = new AppMonedaService();
    appMonedaService.findAll().then((data) => {
      setAppMenedas(data);
    });
  }, []);

  //Efecto que me selecciona el tipo de fuente de ingreso
  useEffect(() => {
    if (cliPerfilEconomico?.perEcoTipo === 0) {
    } else {
      var item = dropdownItemTipoFuenteIngresos.find(
        (item) => item.code === cliPerfilEconomico?.perEcoTipo
      );
      setDropdownItemTipoFuenteIngreso(item);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliPerfilEconomico?.perEcoTipo]);

  //Efecto que me selecciona el tipo de fuente de ingreso
  useEffect(() => {
    if (cliPerfilEconomico?.perEcoSector === 0) {
    } else {
      var item = dropdownItemSectores.find(
        (item) => item.code === cliPerfilEconomico?.perEcoSector
      );
      setDropdownItemSector(item);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliPerfilEconomico?.perEcoSector]);

  const saveCliReferenciaPersonal = (cliPerfilEconomico) => {
    const cliPerfilEconomicoService = new CliPerfilEconomicoService();

    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    _cliPerfilEconomico[`${"asoId"}`] = props.asociado.asoId;
    _cliPerfilEconomico[`${"perEcoFechaRegistro"}`] = s;
    setCliPerfilEconomico(_cliPerfilEconomico);
    cliPerfilEconomicoService.save(_cliPerfilEconomico).then((data) => {
      setEstado(!estado)
    });

    toast.current.show({
      severity: "success",
      summary: "¡Exitoso!",
      detail: "Perfil Económico Guardado",
      life: 3000,
    });


    // cliPerfilEconomicoService
    //   .findAllByAsoId(props.asociado.asoId)
    //   .then((data) => {
    //     setCliPerfilesEconomicos(data);
    //   });
    setCliPerfilEconomicoDialog(false);
  };

  //State para los dialogos
  const [cliPerfilEconomicoDialog, setCliPerfilEconomicoDialog] =
    useState(false);
  const [deleteCliPerfilEconomicoDialog, setDeleteCliPerfilEconomicoDialog] =
    useState(false);

  const editCliPerfilEconomico = (cliPerfilEconomico) => {
    setCliPerfilEconomico({ ...cliPerfilEconomico });
    setCliPerfilEconomicoDialog(true);
  };

  //Borramos un Perfil Economico
  const deleteCliPerfilEconomico = () => {
    // setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const cliPerfilEconomicoService = new CliPerfilEconomicoService();
    //Enviamos los datos al servicio correspondiente
    cliPerfilEconomicoService.delete(cliPerfilEconomico).then((data) => { });

    cliPerfilEconomicoService
      .findAllByAsoId(props.asociado.asoId)
      .then((data) => {
        setCliPerfilesEconomicos(data);
      });

    toast.current.show({
      severity: "success",
      summary: "¡Éxitoso!",
      detail: "Perfil Económico eliminado",
      life: 3000,
    });

    setDeleteCliPerfilEconomicoDialog(false);
    setCliPerfilEconomico(emptyCliPerfilEconomicoEmty);
  };

  const confirmDeleteCliReferenciaPersonal = (cliPerfilEconomico) => {
    setCliPerfilEconomico({ ...cliPerfilEconomico });
    setDeleteCliPerfilEconomicoDialog(true);
  };

  const hideDeleteCliPerfilEconomicoDialog = () => {
    setDeleteCliPerfilEconomicoDialog(false);
  };

  const hideDialog = () => {
    //  setSubmitted(false);
    setCliPerfilEconomicoDialog(false);
  };

  const [autoFilteredActividadEconomica, setAutoFilteredActividadEconomica] =
    useState([]);

  const searchActividadEconomica = (event) => {
    setTimeout(() => {
      if (!event.query.trim().length) {
        setAutoFilteredActividadEconomica([...appActividadEconomicas]);
      } else {
        setAutoFilteredActividadEconomica(
          appActividadEconomicas.filter((actividadEconomica) => {
            return actividadEconomica.actEcoDescripcion
              .toLowerCase()
              .startsWith(event.query.toLowerCase());
          })
        );
      }
    }, 250);
  };

  addLocale("es", {
    firstDayOfWeek: 1,
    dayNames: [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ],
    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
    monthNames: [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ],
    monthNamesShort: [
      "ene",
      "feb",
      "mar",
      "abr",
      "may",
      "jun",
      "jul",
      "ago",
      "sep",
      "oct",
      "nov",
      "dic",
    ],
    today: "Hoy",
    clear: "Claro",
  });
  locale("es");

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  let a = [{ year: "numeric" }, { month: "2-digit" }, { day: "2-digit" }];
  let s = join(new Date(), a, "-");

  function toJSONLocal(date) {
    console.log(date)

    if(date !== null ){
      var local = new Date(date);
      local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
      return local.toJSON().slice(0, 10);
    }

  }

  const onInputChangesimpleData = (val, name) => {
    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    _cliPerfilEconomico[`${name}`] = val;
    setCliPerfilEconomico(_cliPerfilEconomico);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    _cliPerfilEconomico[`${name}`] = val;
    setCliPerfilEconomico(_cliPerfilEconomico);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    _cliPerfilEconomico[`${name}`] = val;
    setCliPerfilEconomico(_cliPerfilEconomico);

    //Set simple data
    _cliPerfilEconomico[`${name2}`] = val1;
    setCliPerfilEconomico(_cliPerfilEconomico);
  };

  const onInputChangeObjectTipoFuenteIngreso = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    setDropdownItemTipoFuenteIngreso(val);

    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    //Set simple data
    _cliPerfilEconomico[`${name2}`] = val1;
    setCliPerfilEconomico(_cliPerfilEconomico);
  };

  const onInputChangeObjectSectorperfil = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    setDropdownItemSector(val);

    let _cliPerfilEconomico = { ...cliPerfilEconomico };
    //Set simple data
    _cliPerfilEconomico[`${name2}`] = val1;
    setCliPerfilEconomico(_cliPerfilEconomico);
  };

  const perfilEconomicoDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-danger"
        onClick={hideDialog}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={() => saveCliReferenciaPersonal(cliPerfilEconomico)}
      />
    </>
  );

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editCliPerfilEconomico(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteCliReferenciaPersonal(rowData)}
        />
      </div>
    );
  };

  const deleteCliPerfilEconomicoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCliPerfilEconomicoDialog}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        onClick={deleteCliPerfilEconomico}
      />
    </>
  );

  const tipoPerfilEconomicoBodyTemplate = (rowData) => {
    var nombre ;
    // dropdownItemTipoFuenteIngresos.forEach = {

    // }
    // var item = dropdownItemTipoFuenteIngresos.find(
    //   (item) => item.code === rowData?.perEcoTipo
    // );

    dropdownItemTipoFuenteIngresos.forEach((item)=>{
      if(
        item.code === rowData?.perEcoTipo 
      ){
        nombre = item?.name
      }
      
    })

    return <>{nombre}</>;

  };

  const descripcionBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.perEcoTipo === 3
          ? rowData.perEcoDetalleOtrosIngresos
          : rowData.perEcoNombre}
      </>
    );
  };

  const montoBodyTemplate = (rowData) => {
    return (
      <>
        {rowData.perEcoMonto.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}
      </>
    );
  };
  const fechaIngresoBodyTemplate = (rowData) => {
    return <>{dateFormat(rowData.perEcoFechaRegistro, "dd/mm/yyyy")}</>;
  };

  let fechaInscripcion = new Date(
cliPerfilEconomico?.perEcoFechaInscripcionNegocio?.toString()
  );
  fechaInscripcion.setMinutes(
    fechaInscripcion.getMinutes() +
    fechaInscripcion.getTimezoneOffset()
  );

  return (
    <div className="p-grid">
      <Toast ref={toast} />
      <div className="p-col-12 p-md-12" disabled="disabled">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          tooltip="Nueva Referencia"
          onClick={() => editCliPerfilEconomico()}
        />
        <div className="p-col-12 p-md-12">
          <DataTable
            value={cliPerfilesEconomicos}
            dataKey="perEcoId"
            rowHover
            className="datatable-responsive"
            emptyMessage="No se encontraron perfiles económicos."
          >
            <Column
              header="Tipo Perfil"
              sortable
              body={tipoPerfilEconomicoBodyTemplate}
            ></Column>
            <Column
              header="Fecha Ingreso"
              sortable
              body={fechaIngresoBodyTemplate}
            ></Column>
            <Column
              header="Desscripción"
              sortable
              body={descripcionBodyTemplate}
            ></Column>
            <Column header="Monto" sortable body={montoBodyTemplate}></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={cliPerfilEconomicoDialog}
            style={{ width: "60vw" }}
            modal
            maximizable={true}
            maximized
            header="Datos del Perfil Económico"
            className="p-fluid"
            scroll
            footer={perfilEconomicoDialogFooter}
            onHide={hideDialog}
          >
            <>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-6">
                  <label htmlFor="state">Tipo Fuente de Ingreso</label>
                  <Dropdown
                    id="state"
                    value={dropdownItemTipoFuenteIngreso || ""}
                    onChange={(e) => {
                      onInputChangeObjectTipoFuenteIngreso(
                        e,
                        "cliPerEcoTipoFuenteIngreso",
                        e.target.value.code,
                        "perEcoTipo"
                      );
                    }}
                    options={dropdownItemTipoFuenteIngresos}
                    optionLabel="name"
                    placeholder="Seleccione..."
                    required
                  ></Dropdown>
                </div>
              </div>
              <div
                className="p-formgrid p-grid"
                style={{
                  display:
                    cliPerfilEconomico.perEcoTipo === 3 ? "none" : "grid",
                }}
              >
                <div className="p-field p-col-12">
                  <label htmlFor="asoId">
                    {cliPerfilEconomico.perEcoTipo === 1
                      ? "Nombre Negocio"
                      : cliPerfilEconomico.perEcoTipo === 2
                        ? "Nombre Empleador"
                        : "Detalle tipo de ingreso"}
                  </label>
                  <InputText
                    id="refPerNombre"
                    type="text"
                    value={cliPerfilEconomico?.perEcoNombre ||""}
                    onChange={(e) => onInputChange(e, "perEcoNombre")}
                  />
                </div>
              </div>
              <div
                className="p-formgrid p-grid"
                style={{
                  display:
                    cliPerfilEconomico.perEcoTipo === 3 ? "grid" : "none",
                }}
              >
                <div className="p-field p-col-12">
                  <label htmlFor="asoId">Detalle tipo de ingreso</label>
                  <InputText
                    id="perEcoDetalleOtroTipoIngreso"
                    type="text"
                    value={cliPerfilEconomico?.perEcoDetalleOtrosIngresos || ""}
                    onChange={(e) =>
                      onInputChange(e, "perEcoDetalleOtrosIngresos")
                    }
                  />
                </div>
              </div>
              <div
                className="p-formgrid p-grid"
                style={{
                  display:
                    cliPerfilEconomico.perEcoTipo === 2 ? "grid" : "none",
                }}
              >
                <div className="p-field p-col-12">
                  <label htmlFor="asoId">Puesto desempeña</label>
                  <InputText
                    id="perEcoPuesto"
                    type="text"
                    value={cliPerfilEconomico?.perEcoPuesto || ""}
                    onChange={(e) => onInputChange(e, "perEcoPuesto")}
                  />
                </div>
              </div>

              <div className="p-formgrid p-grid">
                <div
                  className="p-field p-col"
                  style={{
                    display:
                      cliPerfilEconomico.perEcoTipo === 2 ? "grid" : "none",
                  }}
                >
                  <label htmlFor="state">Sector</label>
                  <Dropdown
                    id="state"
                    value={dropdownItemSector || 0}
                    onChange={(e) => {
                      onInputChangeObjectSectorperfil(
                        e,
                        "cliPerfilSector",
                        e.target.value.code,
                        "perEcoSector"
                      );
                    }}
                    options={dropdownItemSectores}
                    optionLabel="name"
                    placeholder="Seleccione..."
                    required
                  ></Dropdown>
                </div>
                <div
                  className="p-field p-col"
                  style={{
                    display:
                      cliPerfilEconomico.perEcoTipo === 3 ? "none" : "grid",
                  }}
                >
                  <label htmlFor="state">Actividad Económica</label>
                  <AutoComplete
                    placeholder="Search"
                    id="dd"
                    dropdown
                    // multiple
                    value={cliPerfilEconomico?.appActividadEconomica || ""}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appActividadEconomica",
                        e.target.value.actEcoId,
                        "actEcoId"
                      );
                    }}
                    suggestions={autoFilteredActividadEconomica}
                    completeMethod={searchActividadEconomica}
                    field="actEcoDescripcion"
                  // disabled={editAsociado}
                  // required
                  // className={classNames({
                  //   "p-invalid": submitted && !asociado.actEcoId,
                  // })}
                  />
                  {/* {submitted && !asociado.actEcoId && (
                    <small className="p-invalid">
                      Actividad Económica es requerido.
                    </small>
                  )} */}
                </div>
                <div
                  className="p-field p-col"
                  style={{
                    display:
                      cliPerfilEconomico.perEcoTipo === 1 ? "grid" : "none",
                  }}
                >
                  <label htmlFor="asoFechaNacimiento">
                    Fecha Inscripcion del Negocio
                  </label>
                  <Calendar
                    showIcon
                    showButtonBar
                    value={ fechaInscripcion || "" }
                    onChange={(e) => {
                      var newDate = toJSONLocal(e.value);
                      onInputChangesimpleData(
                        newDate,
                        "perEcoFechaInscripcionNegocio"
                      );
                    }}
                    // disabled={editAsociado}
                    dateFormat="dd/mm/yy"
                    monthNavigator
                    yearNavigator
                    yearRange="1917:2500"
                  // className="p-calendar p-datepicker"
                  // required="true"
                  // className={classNames({
                  //   "p-invalid": submitted && !asociado.asoFechaNacimiento,
                  // })}
                  ></Calendar>
                  {/* {submitted && !asociado.asoFechaNacimiento && (
                    <small className="p-invalid">
                      Fecha de nacimiento es requerido.
                    </small>
                  )} */}
                </div>
                <div
                  className="p-field p-col"
                  style={{
                    display:
                      cliPerfilEconomico.perEcoTipo === 3 ? "none" : "grid",
                  }}
                >
                  <label htmlFor="state">Dirección</label>
                  <Dropdown
                    id="state"
                    value={cliPerfilEconomico?.cliDireccion || ""}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "cliDireccion",
                        e.target.value.dirId,
                        "dirId"
                      );
                    }}
                    options={cliDirecciones}
                    optionLabel={
                      "dirDireccion"
                      // "appTipoDireccion.dirTipoNombre"
                    }
                    placeholder="Seleccione..."
                    style={{ position: "" }}
                    zIndex={3}
                    className="p-dropdown"
                    required
                  // className={classNames({
                  //   "p-invalid": submitted && !asociado.medComId,
                  // })}
                  ></Dropdown>
                  {/* {submitted && !asociado.medComId && (
                    <small className="p-invalid">
                      Como se entero de la cooperativa es requerido.
                    </small>
                  )} */}
                </div>
              </div>
              <br></br>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                  <label htmlFor="state">Tipo Moneda</label>
                  <Dropdown
                    id="state"
                    value={cliPerfilEconomico?.appMoneda || ""}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appMoneda",
                        e.target.value.monId,
                        "monId"
                      );
                    }}
                    options={appMonedas}
                    optionLabel={"monNombre"}
                    placeholder="Seleccione..."
                    style={{ zIndex: "3", position: "" }}
                    className="p-dropdown"
                    required
                  ></Dropdown>
                </div>
                <div className="p-field p-col">
                  <label htmlFor="asoId">Monto</label>
                  <InputText
                    id="perEcoMonto"
                    type="number"
                    value={cliPerfilEconomico?.perEcoMonto || ""}
                    onChange={(e) => onInputChange(e, "perEcoMonto")}
                    placeholder={cliPerfilEconomico?.appMoneda?.monSimbolo}
                  />
                </div>
              </div>
              <div
                className=""
                style={{
                  display:
                    cliPerfilEconomico.perEcoTipo === 3 ? "none" : "grid",
                }}
              >
                <Fieldset
                  legend="Dirección"
                  toggleable
                  collapsed={true}
                  style={{ backgroundColor: "#989898" }}
                >
                  <>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col">
                        <label htmlFor="state">Tipo Dirección</label>
                        <Dropdown
                          id="state"
                          key={cliPerfilEconomico?.cliDireccion?.asoId}
                          value={
                            cliPerfilEconomico?.cliDireccion?.appTipoDireccion || ""
                          }
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appTipoDireccion",
                              e.target.value.dirTipoId,
                              "dirTipoId"
                            );
                          }}
                          options={appTipoDirecciones}
                          optionLabel="dirTipoNombre"
                          placeholder="Seleccione..."
                          required
                          disabled={true}
                        ></Dropdown>
                      </div>
                    </div>
                    <div className="p-formgrid p-grid">
                      <div className="p-field p-col-12">
                        <label htmlFor="state">País</label>
                        <Dropdown
                          key={cliPerfilEconomico?.cliDireccion?.asoId ||""}
                          id="state"
                          value={cliPerfilEconomico?.cliDireccion?.appPais || ""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appPais",
                              e.target.value.paiId,
                              "paiId"
                            );
                          }}
                          options={appPaises}
                          optionLabel="paiNombre"
                          placeholder="Seleccione..."
                          required
                          disabled
                        ></Dropdown>
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="state">Departamento</label>
                        <Dropdown
                          key={cliPerfilEconomico?.cliDireccion?.asoId ||""}
                          id="state"
                          value={
                            cliPerfilEconomico?.cliDireccion?.appDepartamento ||""
                          }
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appDepartamento",
                              e.target.value.depId,
                              "depId"
                            );
                          }}
                          options={appDepartamentos}
                          optionLabel="depNombre"
                          placeholder="Seleccione..."
                          required
                          disabled
                        ></Dropdown>
                      </div>
                      <div className="p-field p-col">
                        <label htmlFor="state">Municicpio</label>
                        <Dropdown
                          key={cliPerfilEconomico?.cliDireccion?.asoId ||""}
                          id="state"
                          value={cliPerfilEconomico?.cliDireccion?.appMunicipio || ""}
                          onChange={(e) => {
                            onInputChangeObject(
                              e,
                              "appMunicipio",
                              e.target.value.depId,
                              "munId"
                            );
                          }}
                          options={appMunicipios}
                          optionLabel="munNombre"
                          placeholder="Seleccione..."
                          required
                          disabled
                        ></Dropdown>
                      </div>
                      <div className="p-field p-col-12">
                        <label htmlFor="address">Address</label>
                        <InputTextarea
                          rows={5}
                          cols={30}
                          value={cliPerfilEconomico?.cliDireccion?.dirDireccion ||""}
                          onChange={(e) => onInputChange(e, "dirDireccion")}
                          disabled
                        />
                      </div>
                    </div>
                  </>
                </Fieldset>
              </div>
            </>
          </Dialog>
          <Dialog
            visible={deleteCliPerfilEconomicoDialog}
            style={{ width: "450px" }}
            modal
            footer={deleteCliPerfilEconomicoDialogFooter}
            onHide={hideDeleteCliPerfilEconomicoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {cliPerfilEconomico && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{cliPerfilEconomico.perEcoNombre}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CliAsociadoPerfilEconomicoComponent;
