import React, { useEffect, useState } from "react";
import formatDate from "../../../../../utilities/formatDateTable";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Divider } from "primereact/divider";
import formatMiles from "../../../../../utilities/formatMiles";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import selectText from "../../../../../utilities/selecText";
import AhoMovimientoDiarioService from "../../../../../service/AhoCuentaService/AhoMovimientoDiarioService";
import useAuth from "../../../../../auth/useAuth";

import AhoCuentaService from "../../../../../service/AhoCuentaService/AhoCuentaService";
export const CancelacionAho = ({
  newAhorro,
  setCanselacion,
  setAhorro,
  toast,
}) => {
  const auth = useAuth();
  //console.log(newAhorro)
  //console.log(auth.user?.usr.usuUsuario)
  let cancelacionEmpty = {
    ahorro: newAhorro?.ahoId,
    penalizacion:
      newAhorro?.appAhoSubProducto.subAhoPenalizacion !== "S" ? 0 : 1,
    usuario: auth.user?.usr.usuUsuario,
    formaPago: 0,
    formaRenovacion: 1,
    monto: 0,
    plazo: newAhorro?.ahoPlazoDeposito || 0,
    certificado: 0,
  };

  let style = {
    textAlign: "right",
  };
  const [cancelacion, setCancelacion] = useState(cancelacionEmpty);
  const [summited, setSumited] = useState(false);
  const [saldos, setSaldos] = useState({});

  useEffect(() => {
    const ahoMovimientoService = new AhoMovimientoDiarioService();
    ahoMovimientoService
      .saldosCancelacion(cancelacion.ahorro, cancelacion.penalizacion)
      .then((res) => {
        setSaldos(res);
      });
  }, [cancelacion.ahorro, cancelacion.penalizacion]);

  const save = () => {
    setSumited(true);
    var _cancelacion = { ...cancelacion };
    if (cancelacion.formaPago !== 0 && cancelacion.formaPago !== 3) {
      const ahoMovimientoService = new AhoMovimientoDiarioService();
    
      _cancelacion["plazo"] = 0;
      _cancelacion["certificado"] = 0;
      _cancelacion["monto"] = 0;
      ahoMovimientoService.cancelacion(_cancelacion).then((res) => {
        actualizarCuenta();
        toasMessage(res);
      });
    } else if (
      cancelacion.formaPago === 3 &&
      cancelacion.plazo !== 0 &&
      cancelacion.certificado !== 0
    ) {
      const ahoMovimientoService = new AhoMovimientoDiarioService();
      ahoMovimientoService.cancelacion(cancelacion).then((res) => {
        actualizarCuenta();
        toasMessage(res);
      });
    }
  };

  const toasMessage = (res) => {
   
    // console.log(res);
    if(res?.code === 200){
      setSumited(false);
      setCanselacion(false);
      toast.current.show({
        severity: "success",
        summary: res.description,
        detail: res.message,
        life: 4000,
      });
    }else{
      setSumited(false);
      toast.current.show({
        severity: "warn",
        summary: "Ocurrio un error",
        detail:"Por favor de comunicarse a soporte",
        life: 4000,
      });
    }
  
  };

  const actualizarCuenta = () => {
    const ahoCuenta = new AhoCuentaService();
    ahoCuenta.findById(cancelacion.ahorro).then((res) => {
      setAhorro(res);
      //   console.log(res)
      sessionStorage.setItem("Ahorro", JSON.stringify(res));
    });
  };

  const deleteProductsDialogFooter = (
    <>
      <Button
        className=" p-button-danger p-mr-2"
        icon="pi pi-times"
        label="Cancelar"
        onClick={() => setCanselacion(false)}
      />
      <Button icon="pi pi-check" label="Guardar" onClick={() => save()} />
    </>
  );

  return (
    <div>
      <div className="card">
        {/* <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b>
              <label>Numero de cuenta: </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            <label>{newAhorro?.ahoId}</label>
          </div>
        </div> */}
        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b>
              <label>Fecha de vencimiento: </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            <label>
              {formatDate(newAhorro?.ahoFechaVencimiento || "1995-10-10") ||
                "No aplica"}
            </label>
          </div>
        </div>
        <div
          className="p-grid p-field p-mr-5 p-ml-5"
          style={{
            display:
              newAhorro?.appAhoSubProducto.subAhoPenalizacion !== "S"
                ? "none"
                : "",
          }}
        >
          <div className="p-col">
            <b>
              <label>Penalizacion: </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            <Dropdown
              className="p-inputtext-sm"
              value={cancelacion.penalizacion}
              options={[
                { label: "SI", value: 1 },
                { label: "NO", value: 0 },
              ]}
              onChange={(e) =>
                setCancelacion({ ...cancelacion, penalizacion: e?.value })
              }
            />
          </div>
        </div>

        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b>
              <label>Forma pago: </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            <Dropdown
              className={
                summited && !cancelacion.formaPago
                  ? "p-inputtext-sm p-invalid"
                  : "p-inputtext-sm"
              }
              placeholder="Seleccione una opcion"
              value={cancelacion.formaPago}
              options={
                newAhorro?.appAhoSubProducto.subTipoId !== 2
                  ? [
                      //{ label: "CREDITO A CUENTA", value: 1 },
                      //  { label: "CHEQUE", value: 2 },
                      // { label: "RENOVACION AUTOMATICA", value: 3 },
                      { label: "EFECTIVO", value: 4 },
                    ]
                  : [
                      { label: "CREDITO A CUENTA", value: 1 },
                      //  { label: "CHEQUE", value: 2 },
                      { label: "RENOVACION AUTOMATICA", value: 3 },
                    ]
              }
              onChange={(e) =>
                setCancelacion({ ...cancelacion, formaPago: e?.value })
              }
            />
          </div>
        </div>
        <div
          style={{
            display: cancelacion.formaPago !== 3 ? "none" : "",
          }}
        >
          <Divider layout="horizontal" align="left">
            <span className="overview-title">Detalles para renovacion</span>
          </Divider>
          <div className="p-grid p-field p-mr-5 p-ml-5">
            <div className="p-col">No. Certificado</div>
            <div className="p-col" style={style}>
              <InputText
                className={
                  summited &&
                  !cancelacion.certificado &&
                  cancelacion.formaPago === 3
                    ? "p-inputtext-sm p-invalid"
                    : "p-inputtext-sm"
                }
                id="certi"
                type="number"
                value={cancelacion?.certificado}
                onChange={(e) =>
                  setCancelacion({
                    ...cancelacion,
                    certificado: e.target.value,
                  })
                }
                onClick={(e) => selectText(e)}
              />
            </div>
          </div>
          <div className="p-grid p-field p-mr-5 p-ml-5">
            <div className="p-col">Plazo en dias</div>
            <div className="p-col" style={style}>
              <InputText
                className={
                  summited && !cancelacion.plazo && cancelacion.formaPago === 3
                    ? "p-inputtext-sm p-invalid"
                    : "p-inputtext-sm"
                }
                id="plazo"
                type="number"
                value={cancelacion?.plazo}
                onChange={(e) =>
                  setCancelacion({ ...cancelacion, plazo: e.target.value })
                }
                onClick={(e) => selectText(e)}
              />
            </div>
          </div>
          <div className="p-grid p-field p-mr-5 p-ml-5">
            <div className="p-col">
              <RadioButton
                checked={cancelacion.formaRenovacion === 1}
                onChange={() =>
                  setCancelacion({
                    ...cancelacion,
                    formaRenovacion: 1,
                    monto: 0,
                  })
                }
              />{" "}
              Mismo capital
            </div>
            <div className="p-col" style={style}>
              {formatMiles(newAhorro?.ahoSaldoTotal)}
            </div>
          </div>
          <div className="p-grid p-field p-mr-5 p-ml-5">
            <div className="p-col">
              <RadioButton
                checked={cancelacion.formaRenovacion === 2}
                onChange={() =>
                  setCancelacion({
                    ...cancelacion,
                    formaRenovacion: 2,
                    monto: 0,
                  })
                }
              />{" "}
              Capital + interes
            </div>
            <div className="p-col" style={style}>
              {formatMiles(saldos?.total || 0)}
            </div>
          </div>
          <div className="p-grid p-field p-mr-5 p-ml-5">
            <div className="p-col">
              <RadioButton
                checked={cancelacion.formaRenovacion === 3}
                onChange={() =>
                  setCancelacion({ ...cancelacion, formaRenovacion: 3 })
                }
              />{" "}
              Monto distinto
            </div>
            <div className="p-col" style={style}>
              <InputText
                className={
                  summited &&
                  !cancelacion.monto &&
                  cancelacion.formaRenovacion === 3
                    ? "p-inputtext-sm p-invalid"
                    : "p-inputtext-sm"
                }
                id="canmonto"
                type="number"
                value={cancelacion?.monto}
                disabled={cancelacion.formaRenovacion !== 3}
                onChange={(e) =>
                  setCancelacion({ ...cancelacion, monto: e.target.value })
                }
                onClick={(e) => selectText(e)}
              />
            </div>
          </div>
        </div>
        <Divider layout="horizontal" align="left">
          <span className="overview-title">Tas e intereses</span>
        </Divider>
        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col-2">
            <b>Base:</b>
          </div>
          <div className="p-col-2" style={style}>
            {newAhorro?.ahoTasaBase}%
          </div>
          <div className="p-col-2">
            <b>Spread:</b>
          </div>
          <div className="p-col-2" style={style}>
            {newAhorro?.ahoTasaNegociado}%
          </div>
          <div className="p-col-2">
            <b>total:</b>
          </div>
          <div className="p-col-2" style={style}>
            {Number(newAhorro?.ahoTasaNegociado) +
              Number(newAhorro?.ahoTasaBase)}
            %
          </div>
        </div>
        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b>Monto</b>
          </div>
          <div className="p-col" style={style}>
            <label> {formatMiles(saldos?.saldo || 0)}</label>
          </div>
        </div>
        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b>
              <label>Interes: </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            {formatMiles(saldos?.intereses || 0)}
          </div>
        </div>
        <div
          className="p-grid p-field p-mr-5 p-ml-5"
          style={{
            display: cancelacion.penalizacion !== 1 ? "none" : "",
          }}
        >
          <div className="p-col">
            <b>
              <label>
                Penalizacion: {newAhorro?.appAhoSubProducto?.subAhoTasaPenaliza}{" "}
                %{" "}
              </label>
            </b>
          </div>
          <div className="p-col" style={style}>
            {formatMiles(saldos?.penalizacion || 0)}
          </div>
        </div>
        <div className="p-grid p-field p-mr-5 p-ml-5">
          <div className="p-col">
            <b> Impuesto: </b>
          </div>
          <div className="p-col" style={style}>
            {formatMiles(saldos?.impuesto || 0)}
          </div>
        </div>
        <div className="p-grid p-field p-mr-5 p-ml-5 ">
          <div className="p-col">
            <b> Total a cancelar: </b>
          </div>
          <div className="p-col" style={style}>
            {formatMiles(saldos?.total || 0)}
          </div>
        </div>
      </div>
      <div className="p-grid p-formgrid">
        <div className="p-col-2"></div>
        <div
          className="p-col-12 "
          style={{ textAlign: "right", alignItems: "right" }}
        >
          {deleteProductsDialogFooter}
        </div>
      </div>
    </div>
  );
};
