import React, { useEffect, useState } from "react";

import { Chart } from "primereact/chart";

//servicios
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";

export const GraGeneraciones = ({ eventId }) => {
  const [labels, setLabels] = useState([]);
  const [participantes, setParticipantes] = useState([]);
  const [inscritos, setInscritos] = useState([]);
  useEffect(() => {
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService.getGenerationByEventId(eventId).then((res) => {

      if (res?.length > 0) {
        var labelsVar = [];
        var participantesVar = [];
        var inscritosVar = [];
        res.forEach((element) => {
          labelsVar.push(element.nombre + ", " + element.rangoEdad);
          participantesVar.push(element.cantParticipante);
          inscritosVar.push(element.cantEscrito);
        });
        setInscritos(inscritosVar);
        setParticipantes(participantesVar);
        setLabels(labelsVar);
      }
    });
  }, [eventId]);

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Inscritos",
        data: inscritos,
        backgroundColor: "rgba(15,151,199,1)",
      },
      {
        label: "Participantes",
        data: participantes,
        backgroundColor: "rgba(185,68,214,1)",
      },
    ],
  };
  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "black",
        },
      },
    },
  };
  return (
    <div className="revenue-chart-container">
      <Chart type="bar" id="revenue-chart" data={data} options={options} />
    </div>
  );
};
