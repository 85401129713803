import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
export const Pagination = ({
  totalPages,
  pageNumber,
  setPageNumber,
  firstPage,
  lastPage,
}) => {
  return (
    <div style={{ textAlign: "center" }}>
      <br />
      <Button
        type="button"
        label=""
        icon="pi pi-angle-double-left"
        className="p-button-outlined p-button-secondary "
        style={{
          background: "transparent; color: #6E707A",
          marginRight: "4px",
          border: "none",
        }}
        disabled={firstPage}
        onClick={() => setPageNumber(0)}
      />
      <Button
        type="button"
        label=""
        icon="pi pi-angle-left"
        className="p-button-outlined p-button-secondary "
        style={{
          background: "transparent; color: #6E707A",
          marginRight: "4px",
          border: "none",
        }}
        disabled={firstPage}
        onClick={() => setPageNumber(pageNumber - 1)}
      />

      <InputText
        type="text"
        className="p-inputtext p-component"
        placeholder={
          totalPages !== 0
            ? pageNumber + 1 + " de " + totalPages
            : pageNumber + " de " + totalPages
        }
        style={{
          width: "100px",
          height: "30px",
          borderColor: "#5499C7",
          textAlign: "center",

          fontSize: "15px",
          background: "transparent",
        }}
        readOnly
      />

      <Button
        type="button"
        label=""
        className="p-button-outlined p-button-secondary "
        icon="pi pi-angle-right"
        style={{
          background: "transparent; color: #5499C7",
          marginRight: "4px",
          border: "none",
        }}
        disabled={lastPage}
        onClick={() => setPageNumber(pageNumber + 1)}
      />

      <Button
        type="button"
        label=""
        className="p-button-outlined p-button-secondary "
        icon="pi pi-angle-double-right"
        style={{
          background: "transparent; color: #6E707A",
          marginRight: "4px",
          border: "none",
        }}
        disabled={lastPage}
        onClick={() => setPageNumber(totalPages - 1)}
      />
    </div>
  );
};
