import React, {  useRef, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import classNames from "classnames";

//servicios
import AppTasaRangoService from "../../../../../service/AppServices/AppTasaRangoService";

//funciones
import onChajeObjN from "../../../../../utilities/onChajeObjN";
import selectText from "../../../../../utilities/selecText";

export const NuevaTasaRango = ({
  setNuevaTasaRango,
  HooktasaRango,
  nuevoTasaInteres,
  rango,
  setRango,
  toastRango,
}) => {
  const message = useRef();
  const [tasaRango, setTasaRango] = useState(HooktasaRango);
  const [submitted, setSubmitted] = useState(false);

  function save() {
    if (
      tasaRango.tasRangoFechaVigencia !== "" &&
      tasaRango.tasRangoMontoInicial &&
      tasaRango.tasRangoMontoFinal &&
      tasaRango.tasRangoPorcentaje !== 0 &&
      tasaRango.tasRangoMontoInicial &&
      tasaRango.tasRangoMontoFinal &&
      tasaRango.tasRangoPorcentaje !== "" 
    ) {
      let _tasaRango = { ...tasaRango };
      _tasaRango["tasId"] = nuevoTasaInteres?.tasId;
      const appTasaRangoService = new AppTasaRangoService();
      appTasaRangoService.save(_tasaRango).then(() => {
        if(tasaRango.tasId === 0 ){
            toastRango.current.show({
                severity: "success",
                summary: "Tarea Realizada con exito",
                detail: "Tasa agregada",
                life: 4000,
              });
        }else{
            toastRango.current.show({
                severity: "success",
                summary: "Tarea Realizada con exito",
                detail: "Tasa actualizada",
                life: 4000,
              });
        }
    
        setNuevaTasaRango(false);
        setRango(!rango);
      });
    } else {
      setSubmitted(true);
      setTimeout(() => {
        setSubmitted(false);
      }, 3000);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 3000,
      });
    }
  }

  function fomRango() {
    return (
      <>
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Fecha de vigencia</label>
            <InputText
              type={"date"}
              value={tasaRango.tasRangoFechaVigencia}
              onChange={(e) =>
                onChajeObjN(e, "tasRangoFechaVigencia", tasaRango, setTasaRango)
              }
              className={classNames({
                "p-invalid": submitted && !tasaRango?.tasRangoFechaVigencia,
              })}
            />
            {submitted && !nuevoTasaInteres.tasRangoFechaVigencia && (
              <small className="p-invalid">Fecha de vigencia requerida</small>
            )}
          </div>
          <div className="p-col">
            <label>Tasa a utilizar</label>
            <InputText
              id="tasaUtil"
              value={tasaRango.tasRangoPorcentaje}
              onClick={(e) => selectText(e)}
              onChange={(e) =>
                onChajeObjN(e, "tasRangoPorcentaje", tasaRango, setTasaRango)
              }
              className={classNames({
                "p-invalid": submitted && !tasaRango?.tasRangoPorcentaje,
              })}
            />
            {submitted && !nuevoTasaInteres.tasRangoFechaVigencia && (
              <small className="p-invalid">Tasa requerida</small>
            )}
          </div>
        </div>
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Monto Inicial</label>
            <InputText
              id="monoInicial"
              type={"number"}
              value={tasaRango.tasRangoMontoInicial}
              onClick={(e) => selectText(e)}
              onChange={(e) =>
                onChajeObjN(e, "tasRangoMontoInicial", tasaRango, setTasaRango)
              }
              className={classNames({
                "p-invalid": submitted && !tasaRango?.tasRangoMontoInicial,
              })}
            />
            {submitted && !nuevoTasaInteres.tasRangoMontoInicial && (
              <small className="p-invalid">Monto inicial requerido</small>
            )}
          </div>
          <div className="p-col">
            <label>Monto Final</label>
            <InputText
              id="montoFinal"
              type={"number"}
              onClick={(e) => selectText(e)}
              value={tasaRango.tasRangoMontoFinal}
              onChange={(e) =>
                onChajeObjN(e, "tasRangoMontoFinal", tasaRango, setTasaRango)
              }
              className={classNames({
                "p-invalid": submitted && !tasaRango?.tasRangoMontoFinal,
              })}
            />
            {submitted && !nuevoTasaInteres.tasRangoMontoFinal && (
              <small className="p-invalid">Tasa final requerido</small>
            )}
          </div>
        </div>
      </>
    );
  }

  function buttons() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-mr-2 p-mb-2 p-button-danger "
          onClick={() => {setNuevaTasaRango(false);toastRango.current.show({
            severity: "warn",
            summary: "Alerta",
            detail: "Pestaña de rangos cerrada",
            life: 4000,
          })}}
          label="Cerrar"
        />
        <Button
          icon="pi pi-check"
          className="p-mr-2 p-mb-2"
          onClick={() => save()}
          label="save"
        />
      </>
    );
  }

  return (
    <>
      <div className="card">{fomRango()}</div>
      <Messages ref={message} />
      <div style={{ textAlign: "right" }}>{buttons()}</div>
    </>
  );
};
