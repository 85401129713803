import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
//servicios
import AppModuloService from "../../../../service/AppServices/AppModuloService";

//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../../utilities/onChangeObjDropDawn";
import classNames from "classnames";
export const NuevoSaldo = ({ saldo, setSaldo, submitted }) => {
  const [modulos, setMoodulos] = useState([]);
  useEffect(() => {
    const modulosService = new AppModuloService();
    modulosService.findAll().then((res) => {
      setMoodulos(res);
    });
  }, []);

  return (
    <div className="card p-grid p-fluid">
      <div className="p-col">
        <label>Descripcion</label>
        <InputText
          value={saldo.salDescripcion}
          placeholder="Descripcion"
          onChange={(e) => onChajeObjN(e, "salDescripcion", saldo, setSaldo)}
          className={classNames({
            "p-invalid": submitted && !saldo.salDescripcion,
          })}
        />
        {submitted && !saldo.salDescripcion && (
          <small className="p-invalid">Descripcion requerida</small>
        )}
      </div>
      <div className="p-col">
        <label>Modulo</label>
        <Dropdown
          value={saldo.appModulo}
          optionLabel="modNombre"
          placeholder="Seleccione..."
          options={modulos}
          onChange={(e) =>
            onChangeObjDropDawn(
              e,
              "appModulo",
              e.target.value.modId,
              "salModId",
              saldo,
              setSaldo
            )
          }
          disabled={saldo.salId !== 0}
          className={classNames({
            "p-invalid": submitted && !saldo.salModId,
          })}
        />
        {submitted && !saldo.salModId && (
          <small className="p-invalid">Modulo requerido</small>
        )}
      </div>
    </div>
  );
};
