import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class EventoParticipanteService {
  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/evtparticipante/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      return(err);
    }
  }

  async upDate(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/evtparticipante/update`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }
  async getById(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      let config = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${jwt.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      let res = await axios.get(
        api.baseUrl() + "/evtparticipante/" + id,
        config
      );
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }
  async getByEvenId(id) {

    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtparticipante/list/ ${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
addressingError(err);
    }

  }

  async delete(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.delete(
        api.baseUrl() + `/evtparticipante/delete/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtevento/all`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByPage(
    pageNumber,
    parEstado,
    asoId,
    sucId,
    eveId,
    primerNombre,
    segundoNombre,
    otroNombre,
    primerApellido,
    segundoApellido,
    apellidoCasada,
    numIdentificacion
  ) {
  
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/evtparticipantedato/${parEstado || "a"
          }/${asoId || 0}/${
            sucId || 0
          }/${eveId || 0}/${primerNombre || 0}/${segundoNombre || 0}/${
            otroNombre || 0
          }/${primerApellido || 0}/${segundoApellido || 0}/${
            apellidoCasada || 0
          }/${numIdentificacion || 0}/?page=${pageNumber || 0}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      // console.log(response)
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async getByEvenBranchEvyId(id) {

    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtparticipante/branch/ ${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
addressingError(err);
    }

  }



  async getGenederByEventId(id) {

    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtparticipante/gender/ ${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
addressingError(err);
    }

  }
  async getGenerationByEventId(id) {

    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtparticipante/generation/ ${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
addressingError(err);
    }

  }
  async getTottalByEventId(id) {

    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/evtparticipante/total/ ${id}`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
addressingError(err);
    }

  }
}
