import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";

//servicios
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";

export const GraTotal = ({ eventId }) => {
  const [participantes, setParticipantes] = useState([]);
  const [inscritos, setInscritos] = useState([]);
  useEffect(() => {
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService.getTottalByEventId(eventId).then((res) => {
      var participantesVar = [res?.participants||0];
      var inscritosVar = [res?.enroll || 0];

      setInscritos(inscritosVar);
      setParticipantes(participantesVar);
    });
  }, [eventId]);

  const data = {
    labels: ["Total"],
    datasets: [
      {
        label: "Inscritos",
        data: inscritos,
        backgroundColor: "rgba(15,151,199,1)",
      },
      {
        label: "Participantes",
        data: participantes,
        backgroundColor: "rgba(185,68,214,1)",
      },
    ],
  };

  const options = {
    indexAxis: "y",
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        ticks: {
          color: "black",
        },
      },
    },
  };

  return (
    <div className="revenue-chart-container">
      <Chart type="bar" id="revenue-chart" options={options} data={data} />
    </div>
  );
};
