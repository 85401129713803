import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { FileUpload } from "primereact/fileupload";
//import { Rating } from "primereact/rating";
import { Toolbar } from "primereact/toolbar";
//import { InputTextarea } from "primereact/inputtextarea";
//import { RadioButton } from "primereact/radiobutton";
//import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import AppPaisService from "../service/AppServices/AppPaisService";

export const AppPais = () => {
  let emptyPais = {
    paiId: 0,
    paiNombre: "",
    paiNombreEn: "",
    paiCodAlpha2: "",
    paiCodAlpha3: "",
    paiNumerico: 0,
  };

  const [paises, setPaises] = useState([]);
  const [paisDialog, setPaisDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false);
  const [pais, setPais] = useState(emptyPais);
  const [selectedPaises, setSelectedPaises] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);

  useEffect(() => {
    const appPaisService = new AppPaisService();
    appPaisService.findAll().then((data) => setPaises(data));
  }, []);
  

  const openNew = () => {
    setPais(emptyPais);
    setSubmitted(false);
    setPaisDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setPaisDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false);
  };

  //Guardamos un registro
  const saveProduct = () => {
    setSubmitted(true);

    //if (pais.paiNombre.trim()) {
    //let _paises = [...paises];
    //let _pais = { ...pais };

    const appPaisService = new AppPaisService();
    if (pais.paiId !== 0) {
      appPaisService.save(pais).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "País Actualizado",
        life: 3000,
      });
    } else {
      appPaisService.save(pais).then((data) => data);
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail: "País Creado",
        life: 3000,
      });
    }

    //setPaises(_paises);
    appPaisService.findAll().then((data) => setPaises(data));
    setPaisDialog(false);
    setPais(emptyPais);
    //}
  };

  //Editamos un registro
  const editPais = (pais) => {
    setPais({ ...pais });
    setPaisDialog(true);
  };

  //Mensaje de confirmación de un registro
  const confirmDeleteProduct = (product) => {
    setPais(product);
    setDeleteProductDialog(true);
  };

  //Borramos un producto
  const deleteProduct = () => {
    let _products = paises.filter((val) => val.id !== pais.paiId);
    setPaises(_products);
    setDeleteProductDialog(false);
    setPais(emptyPais);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Product Deleted",
      life: 3000,
    });
  };
  /*
  //Buscamos un producto por ID
  const findIndexById = (id) => {
    let index = -1;
    for (let i = 0; i < paises.length; i++) {
      if (paises[i].id === id) {
        index = i;
        break;
      }
    }

    return index;
  };
  /*
  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };
*/
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true);
  };

  const deleteSelectedProducts = () => {
    let _products = paises.filter((val) => !selectedPaises.includes(val));
    setPaises(_products);
    setDeleteProductsDialog(false);
    setSelectedPaises(null);
    toast.current.show({
      severity: "success",
      summary: "Successful",
      detail: "Products Deleted",
      life: 3000,
    });
  };

  /*
  const onCategoryChange = (e) => {
    let _product = { ...pais };
    _product["category"] = e.value;
    setPais(_product);
  };
  */

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _pais = { ...pais };
    _pais[`${name}`] = val;
    setPais(_pais);
  };
  /*
  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _product = { ...pais };
    _product[`${name}`] = val;

    setPais(_product);
  };*/

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          className="p-button-danger p-mb-2"
          onClick={confirmDeleteSelected}
          disabled={!selectedPaises || !selectedPaises.length}
        />
      </React.Fragment>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        <FileUpload
          mode="basic"
          accept="image/*"
          maxFileSize={1000000}
          label="Import"
          chooseLabel="Import"
          className="p-mr-2 p-d-inline-block"
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={exportCSV}
        />
      </React.Fragment>
    );
  };

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.paiId}
      </>
    );
  };

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nombre</span>
        {rowData.paiNombre}
      </>
    );
  };
  const nameEnglishBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Nombre Inglés</span>
        {rowData.paiNombreEn}
      </>
    );
  };
  const alpha2BodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código Alpha2</span>
        {rowData.paiCodAlpha2}
      </>
    );
  };
  const alpha3BodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código Alpha3</span>
        {rowData.paiCodAlpha3}
      </>
    );
  };
  const numericoBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Numérico</span>
        {rowData.paiNumerico}
      </>
    );
  };
  /*
  const imageBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Image</span>
        <img
          src={`assets/demo/images/product/${rowData.image}`}
          alt={rowData.image}
          className="product-image"
        />
      </>
    );
  };*/
  /*
  const priceBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Price</span>
        {formatCurrency(rowData.price)}
      </>
    );
  };

  const categoryBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Category</span>
        {rowData.category}
      </>
    );
  };

  const ratingBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Reviews</span>
        <Rating value={rowData.rating} readonly cancel={false} />
      </>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Status</span>
        <span
          className={`product-badge status-${rowData.inventoryStatus.toLowerCase()}`}
        >
          {rowData.inventoryStatus}
        </span>
      </>
    );
  };*/

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editPais(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </div>
    );
  };

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestionar países</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setGlobalFilter(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveProduct} />
    </>
  );
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductDialog}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteProduct} />
    </>
  );
  const deleteProductsDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteProductsDialog}
      />
      <Button label="Yes" icon="pi pi-check" onClick={deleteSelectedProducts} />
    </>
  );

  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={paises}
            selection={selectedPaises}
            onSelectionChange={(e) => setSelectedPaises(e.value)}
            dataKey="paiId"
            paginator
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            className="datatable-responsive"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} países"
            globalFilter={globalFilter}
            emptyMessage="No se encontraron países."
            header={header}
          >
            <Column
              selectionMode="multiple"
              headerStyle={{ width: "3rem" }}
            ></Column>
            <Column
              field="paiId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="paiNombre"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column
              field="paiNombreEn"
              header="Nombre Inglés"
              sortable
              body={nameEnglishBodyTemplate}
            ></Column>
            <Column
              field="paiCodAlpha2"
              header="Código Alpha2"
              body={alpha2BodyTemplate}
              sortable
            ></Column>
            <Column
              field="paiCodAlpha3"
              header="Código Alpha3"
              sortable
              body={alpha3BodyTemplate}
            ></Column>
            <Column
              field="paiNumerico"
              header="Númerico"
              body={numericoBodyTemplate}
              sortable
            ></Column>
            <Column body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={paisDialog}
            style={{ width: "450px" }}
            header="Detalles del país"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            {/*
            {pais.image && (
              <img
                src={`assets/demo/images/product/${pais.image}`}
                alt={pais.image}
                className="product-image"
              />
            )} */}
            <div className="p-field">
              <label htmlFor="paiNombre">Nombre</label>
              <InputText
                id="paiNombre"
                value={pais.paiNombre}
                onChange={(e) => onInputChange(e, "paiNombre")}
                required
                autoFocus
                className={classNames({
                  "p-invalid": submitted && !pais.paiNombre,
                })}
              />
              {submitted && !pais.paiNombre && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="paiNombreEn">Nombre Inglés</label>
              <InputText
                id="paiNombreEn"
                value={pais.paiNombreEn}
                onChange={(e) => onInputChange(e, "paiNombreEn")}
                required
                className={classNames({
                  "p-invalid": submitted && !pais.paiNombreEn,
                })}
              />
              {submitted && !pais.paiNombreEn && (
                <small className="p-invalid">
                  El nombre en inglés es requerido.
                </small>
              )}
            </div>

            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="paiCodAlpha2">Alpha 2</label>
                <InputText
                  id="paiCodAlpha2"
                  value={pais.paiCodAlpha2}
                  onChange={(e) => onInputChange(e, "paiCodAlpha2")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !pais.paiCodAlpha2,
                  })}
                />
                {submitted && !pais.paiCodAlpha2 && (
                  <small className="p-invalid">
                    El código alpha2 es requerido.
                  </small>
                )}
              </div>
              <div className="p-field p-col">
                <label htmlFor="paiCodAlpha3">Alpha 3</label>
                <InputText
                  id="paiCodAlpha3"
                  value={pais.paiCodAlpha3}
                  onChange={(e) => onInputChange(e, "paiCodAlpha3")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !pais.paiCodAlpha3,
                  })}
                />
                {submitted && !pais.paiCodAlpha3 && (
                  <small className="p-invalid">
                    El código alpha3 es requerido.
                  </small>
                )}
              </div>
            </div>
            <div className="p-formgrid p-grid">
              <div className="p-field p-col">
                <label htmlFor="paiNumerico">Numérico</label>
                <InputText
                  id="paiNumerico"
                  value={pais.paiNumerico}
                  onChange={(e) => onInputChange(e, "paiNumerico")}
                  required
                  className={classNames({
                    "p-invalid": submitted && !pais.paiNumerico,
                  })}
                />
                {submitted && !pais.paiNumerico && (
                  <small className="p-invalid">
                    El código númerico es requerido.
                  </small>
                )}
              </div>
              <div className="p-field p-col"></div>
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteProductDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteProductDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {pais && (
                <span>
                  Are you sure you want to delete <b>{pais.paiNombre}</b>?
                </span>
              )}
            </div>
          </Dialog>

          <Dialog
            visible={deleteProductsDialog}
            style={{ width: "450px" }}
            header="Confirm"
            modal
            footer={deleteProductsDialogFooter}
            onHide={hideDeleteProductsDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {pais && (
                <span>
                  Are you sure you want to delete the selected products?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};
