import React, { useState, useEffect, useRef } from "react";
//import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";

//servicios
import EventoService from "../../../service/AsambleaServices/EventoService";

//funciones
import formatDate from "../../../utilities/formatDateTable";
import { NuevoEvento } from "./NuevoEvento";
//import { AsambleaReport } from "./EventoReport/AsambleaReport";
export const Evento = () => {
  const toast = useRef();
  // let filtroEmpty = {
  //   descripcion: "",
  //   fechaEvento: "",
  // };

  let evetoEmpty = {
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucCodigoAbreviado: "",
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
      sucNombreAbreviado: "",
    },
    eveDescripcion: "",
    eveFecha: "",
    eveId: 0,
    eveNombre: "",
    eveSucId: 0,
  };
 // const [filtro, setFiltro] = useState(filtroEmpty);
  const [eventos, setEventos] = useState([]);
  const [evento, setEvento] = useState(evetoEmpty);
  const [nuevoRegistro, setNuevoRegistro] = useState(false);
  const [estado, setEstado] = useState(false);

  useEffect(() => {
    const eventoService = new EventoService();
    eventoService.findAll().then((res) => {
      setEventos(res);
    });
  }, [estado]);

  // const leftContents = (
  //   <div className="p-fluid p-grid p-formgrid p-mt-2">
  //     <div className="p-col-5">
  //       <label>Descripcion</label>
  //       <InputText
  //         placeholder="Descripcion"
  //         type={"text"}
  //         value={filtro.descripcion}
  //         onChange={(e) =>
  //           setFiltro({
  //             ...filtro,
  //             descripcion: e.target.value.toUpperCase(),
  //           })
  //         }
  //       />
  //     </div>
  //     <div className=" p-col-7" >
  //       <label>Nombre</label>
  //       <InputText
  //         className="p-mr-2"
  //         value={filtro.fechaEvento}
  //         onChange={(e) => setFiltro({ ...filtro, nombre: e.target.value })}
  //         type={"date"}
  //       />
  //     </div>
  //   </div>
  // );
  const right = () => {
    return (
      <Button
        label="Nuevo"
        icon="pi pi-plus"
        className="p-button-success "
        onClick={() => {setNuevoRegistro(true) ;setEvento(evetoEmpty)}}
      />
    );
  };

  const forDateTable = (rowData) => {
    return formatDate(rowData?.eveFecha);
  };


  const acciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() =>{ setEvento(rowData); setNuevoRegistro(true)}}
        />
      </>
    );
  };
  const renderTable = () => {
    return (
      <DataTable emptyMessage="No se encontro ningun evento" value={eventos}>
        <Column header="No. Evento" field="eveId" />
        <Column header="Nombre" field="eveNombre" />
        <Column header="Descripcion" field="eveDescripcion" />
        <Column
          header="Fecha del evento"
          field="eveFecha"
          body={forDateTable}
        />
        <Column header="Sucursal Anfitrión" field="appSucursal.sucNombre" />
        <Column header="Acciones" body={acciones} />
      </DataTable>
    );
  };

  return (
    <div className="card">

      <Toolbar
     //  left={leftContents} 
       right={right} className="p-mb-4" />
      {renderTable()}

      <Dialog
        visible={nuevoRegistro}
        onHide={() => setNuevoRegistro(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header={evento.eveId ===  0 ? "Nueva evento" : "Editar evento"}
      >
        <NuevoEvento
          setNuevoRegistro={setNuevoRegistro}
          eventoHook={evento}
          toast={toast}
          estado={estado}
          setEstado={setEstado} 
        />
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
