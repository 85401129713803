import React, { useState, useCallback, useRef } from "react";
import Cropper from "react-easy-crop";
import { Button } from "primereact/button";

import "../../../../utils/App.css";

import { generateDownload } from "./cropImage";
export const CargarFirmaAso = ({ setCargarFirma }) => {
  const inputRef = useRef();

  const triggerFileSelectPopup = () => inputRef.current.click();
  const [file, setFile] = useState(null);
  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
  const [zoom, setZoom] = React.useState(1);
  const [croppedArea, setCroppedArea] = React.useState(null);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    //   console.log(croppedArea, croppedAreaPixels)
    setCroppedArea(croppedAreaPixels);
  }, []);

  const onSelectFile = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setFile(reader.result);
      });
    }
  };

  const onDownload = () => {
    generateDownload(file, croppedArea);
  };

  return (
    <>
      <div
        className="container-croppe card"
        style={{ height: "50vh", width: "100%", backgroundColor: "#575757" , alignItems:"center", textAlign:"center", justifyContent:"center"}}
      >
        {file ? (
            <div className="cropper">
          <Cropper
            image={file}
            initialAspectRatio={4 / 3}
            crop={crop}
            zoom={zoom}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
          </div>
        ) : null}
      </div>
      <div className="container-buttons p-mt-5  ">
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          ref={inputRef}
          //onChange={(e) => setFile(e.target.files[0])}
          style={{ display: "none" }}
        />
        <Button
          label="Seleccionar"
          icon="pi pi-image"
          iconPos="right"
          className="p-button-rounded p-button-secondary"
          onClick={triggerFileSelectPopup}
          style={{ marginRight: "10px" }}
        />
        <Button
          label="Subir"
          icon="pi pi-cloud-upload"
          iconPos="right"
          variant="contained"
          className="p-button-rounded p-button-success "
          onClick={onDownload}
        />
      </div>
    </>
  );
};
