import React from "react";
import { HashRouter } from "react-router-dom";
import AppWrapper from "../AppWrapper";

export default function AppHash() {
  return (
    <HashRouter>
      <AppWrapper />
    </HashRouter>
  );
}
