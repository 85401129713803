import classNames from "classnames";
import { InputText } from "primereact/inputtext";
import React from "react";

//funciones
import onChajeObjN from "../../../utilities/onChajeObjN";
import selectText from "../../../utilities/selecText";
export const NuevoBanco = ({ banco, setBanco, submitted }) => {
  return (
    <>
      <div className="card">
        <div className="p-grid p-fluid">
          <div className="p-col">
            <label>Nombre del banco</label>
            <InputText
              id="banNombre"
              value={banco.banNombre}
              onChange={(e) => onChajeObjN(e, "banNombre", banco, setBanco)}
              onClick={(e) => selectText(e)}
              placeholder="Nombre del banco"
              className={classNames({
                "p-invalid": submitted && !banco.banNombre,
              })}
            />
            {submitted && !banco.banNombre && (
              <small className="p-invalid">Nombre del banco requerido</small>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
