import axios from "axios";
import qs from "qs";
export default class TransaccionTokenService {
  async consulta() {
    // let data = qs.stringify({
    //   grant_type: "password",
    //   client_id: "api",
    //   username: "fondosamerica",
    //   password: "temporal123",
    //   client_secret: "27b74f1d-1788-445a-9502-c42c26bc6576",
    //   scope: "roles",
    // });

   let data = qs.stringify({
      grant_type: "password",
      client_id: "api",
      username: "famerica",
      password: "Da6JJcMM4Z6eJ58cg",
      client_secret: "499370bf-f476-4dff-a1ee-373a37c1ea34",
      scope: "roles",
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://proxy.mycoop.io/https://sso.multicredit.com.gt:8443/auth/realms/Exchequer/protocol/openid-connect/token",
      //url: "https://proxy.mycoop.io/https://sso.ist.com.gt/auth/realms/Exchequer/protocol/openid-connect/token",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: data,
    };

    // axios
    //   .request(config)
    //   .then((response) => {
    //     console.log(response.data.access_token)
    //     sessionStorage.setItem("token_multi",response.data.access_token);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });

    try {
      const response = await axios.request(config);
     // sessionStorage.setItem("token_multi", response.data.access_token);
      return response;
    } catch (error) {
      console.log(error);
  
    }

  }

  // async tokenSifco(){
  //   let data = new FormData();
  //   data.append('client_id', '94003a4cf5f8448797cfd8f16e3b644a');
  //   data.append('client_secret', 'd44814a2d87544549c5f781c493d12a7');
  //   data.append('grant_type', 'password');
  //   data.append('scope', 'FullControl');
  //   data.append('username', 'mmenchu');
  //   data.append('password', 'hazty4-qaFfog-divgik');
    
  //   let config = {
  //     method: 'post',
  //     maxBodyLength: Infinity,
  //     url: 'https://test.sifco.org/fondos/oauth/access_token',
  //     headers: { 
  //       'Cookie': 'GX_CLIENT_ID=04949264-937a-4bfb-a0d3-9733ed6c04ab; ASP.NET_SessionId=lcacqmejl4mxpwghuwkyjpuk', 
  //       ...data.getHeaders()
  //     },
  //     data : data
  //   };
    
  //   try {
  //     const response = await axios.request(config);
  //    // sessionStorage.setItem("token_multi", response.data.access_token);
  //     return response;
  //   } catch (error) {
  //     console.log(error);
  
  //   }
  // }

  async  tokenSifco() {
    try {
      const data = new URLSearchParams();
      data.append('client_id', '94003a4cf5f8448797cfd8f16e3b644a');
      data.append('client_secret', 'd44814a2d87544549c5f781c493d12a7');
      data.append('grant_type', 'password');
      data.append('scope', 'FullControl');
      data.append('username', 'multimain');
      data.append('password', 'geqki0-wasnon-wiHvoj');
  
      const config = {
        method: 'post',
        url: 'https://proxy.mycoop.io/https://app.sifco.org/fondos/oauth/access_token',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        data: data.toString()
      };
  
      const response = await axios(config);
      return response;
    } catch (error) {
     // console.log(error);
    }

  //   const myHeaders = new Headers();
  //   myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  //   const data = new URLSearchParams();
  //     data.append('client_id', '94003a4cf5f8448797cfd8f16e3b644a');
  //     data.append('client_secret', 'd44814a2d87544549c5f781c493d12a7');
  //     data.append('grant_type', 'password');
  //     data.append('scope', 'FullControl');
  //     data.append('username', 'mmenchu');
  //     data.append('password', 'hazty4-qaFfog-divgik');
  
    
  //   fetch(apiUrl, {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: data,
  //     redirect: 'follow',
  //   })
  //     .then(response => response.text())
  //     .then(result => console.log(result))
  //     .catch(error => console.log('error', error));
  
  }
}
