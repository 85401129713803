import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
//components
import { Pagination } from "../../../../components/Pagination";

import AppSaldoService from "../../../../service/AppServices/AppSaldoService";
import { NuevoSaldo } from "./NuevoSaldo";
export const Saldo = () => {
  const toast = useRef();
  const message = useRef();
  let filtroEmpty = {
    descripcion: "",
  };

  let saldoEmpty = {
    appModulo: {
      empId: 0,
      modCodigo: "",
      modEstado: "",
      modFechaAnterior: "",
      modFechaCierre: "",
      modFechaHoy: "",
      modFechaSiguiente: "",
      modId: 0,
      modNombre: "",
    },
    salDescripcion: "",
    salId: 0,
    salModId: 0,
  };

  const [saldo, setSaldo] = useState(saldoEmpty);
  const [filtro, setFiltro] = useState(filtroEmpty);
  const [nuevoSaldo, setNuevoSaldo] = useState(false);
  const [saldos, setSaldos] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [estado, setEstado] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);

  useEffect(() => {
    const saldoService = new AppSaldoService();
    saldoService.findByPage(pageNumber, filtro.descripcion).then((result) => {
      setSaldos(result?.content);
      setTotalPages(result?.totalPages);
      setPageNumber(result?.number);
      setFirstPage(result?.first);
      setLastPage(result?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning  mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setSaldo(rowData); setNuevoSaldo(true)}}
        />
        <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setSaldo(rowData); setEliminar(true)}}
        />
      </>
    );
  }
  const renderTable = (
    <>
      <DataTable
        value={saldos}
        emptyMessage="No se encontraron Saldos"
        footer={renderPaginacion}
      >
        <Column header="Id" field="salId" />
        <Column header="Descripcion" field="salDescripcion" />
        <Column header="Modulo" field="appModulo.modNombre" />
        <Column header="Acciones" body={acciones} />
      </DataTable>
    </>
  );

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const saldoService = new AppSaldoService();
      saldoService.findByPage(pageNumber, filtro.descripcion).then((result) => {
        setSaldos(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
        if (filtro.descripcion !== "") {
          setPageNumber(0);
        }
      });
    }
  };

  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            type={"search"}
            className="p-inputtext-sm"
            value={filtro.descripcion}
            onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
          />
          <label>Saldo</label>
        </span>
      </div>
    );
  }

  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {setNuevoSaldo(true); setSaldo(saldoEmpty)}}
        />
      </>
    );
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-button-text p-mr-2 "
          onClick={() => setNuevoSaldo(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  const save = () => {
    if (saldo.salDescripcion !== "" && saldo.salModId !== 0) {
      const saldoService = new AppSaldoService();
      saldoService.save(saldo).then(() => {
        setEstado(!estado);
        setNuevoSaldo(false);
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: `Saldo creado con exito`,
          life: 4000,
        });
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  };

  const deleteSaldo = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={borrar} />
    </>
  );

  function borrar() {
    const saldoService = new AppSaldoService();
    saldoService.delete(saldo.salId).then((res)=>{
        console.log(res);
        setEstado(!estado);
        setEliminar(false);
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Saldo eliminado con exito",
          life: 4000,
        });
    })
  }

  return (
    <div className="card">
      <Toolbar className="p-mb-4" right={right} left={left} />
      {renderTable}

      <Dialog
        visible={nuevoSaldo}
        onHide={() => setNuevoSaldo(false)}
        header="Nuevo Saldo"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevoSaldo saldo={saldo} setSaldo={setSaldo} submitted={submitted} />
        <Messages ref={message} />
      </Dialog>
      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={deleteSaldo}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar este saldo .</span>
        </div>
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
