import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
// import { Calendar } from 'primereact/calendar'
import { Dropdown } from "primereact/dropdown";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
import AppUsuarioService from "../../../../service/AppUsuarioService";
import AppSexoService from "../../../../service/AppServices/AppSexoService";
import AppSucursalService from "../../../../service/AppServices/AppSucursalService";

import { ViewRoleUser } from "../ViewRoleUser";

import { Pagination } from "../../../../components/Pagination";
export const ListarUsuario = () => {
  const messages = useRef(null);
  const toast = useRef(null);

  let appUsuarioEmpty = {
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucCodigoAbreviado: "",
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
      sucNombreAbreviado: "",
    },
    sexId: 0,
    sucId: 0,
    usuApellido: "",
    usuCajero: true,
    usuCambiaPwd: true,
    usuDireccion: "",
    usuEmail: "",
    usuEnabled: true,
    usuFechaExpPwd: "",
    usuFechaNacimiento: "",
    usuFotografia: "",
    usuGenero: "",
    usuGuid: "",
    usuIdExterno: "",
    usuListaIp: "",
    usuNombre: "",
    usuPassword: "",
    usuPromotor: true,
    usuPwdExpira: true,
    usuRestriccionIp: true,
    usuUsuario: "",
  };

  // const appSexoEmpty = {
  //   sexDescripcion: "string",
  //   sexId: 0,
  // };

  // const appSucursalEmpty = {
  //   appEmpresa: {
  //     empGerente: "string",
  //     empId: 0,
  //     empNombre: "string",
  //   },
  //   sucCodigoAbreviado: "string",
  //   sucDireccion: "string",
  //   sucEmpId: 0,
  //   sucGerente: "string",
  //   sucId: 0,
  //   sucNombre: "string",
  //   sucNombreAbreviado: "string",
  // };

  const filterUserEmpty = {
    usuUsuario: "",
    usuNombre: "",
    usuApellido: "",
  };

  const [appUsuarios, setAppUsuarios] = useState([]);
  const [appUsuario, setAppUsuario] = useState(appUsuarioEmpty);
  const [appSexos, setAppSexos] = useState([]);
  const [appSucursales, setAppSucursales] = useState([]);
  // const [appSexo, setAppSexo] = useState(appSexoEmpty);
  // const [appSucursal, setAppSucursal] = useState(appSucursalEmpty);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  //State Dialog
  const [dialogNuevoUsuario, setDialogNuevoUsuario] = useState(false);
  const [dialogRolesUsuario, setDialogRolesUsuario] = useState(false);
  const [cambiarContraseñaDialog, setCambiarContraseñaDialog] = useState(false);

  //State para validar los campos
  //const [submitted, setSubmitted] = useState(false);

  //States para el filtro de usuarios
  const [filterUser, setFilterUser] = useState(filterUserEmpty);
  //States para la paginacion
 // const [totalElements, setTotalElements] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  //state password
  const [visible, setVisible] = useState(false);

  const [viewPassword, setViewPassword] = useState({
    user: "",
    password: "",
  });

  //var pass = "";
  useEffect(() => {
    const appUsuarioService = new AppUsuarioService();
    appUsuarioService
      .findByPage(
        pageNumber,
        filterUser.usuUsuario,
        filterUser.usuNombre,
        filterUser.usuApellido
      )
      .then((response) => {
        setAppUsuarios(response.content);
      //  setTotalElements(response.totalElements);
        setTotalPages(response.totalPages);
        setPageNumber(response.number);
        setFirstPage(response.first);
        setLastPage(response.last);
      })
      .catch((error) => {
        console.log(error);
      });

    const appSexoService = new AppSexoService();
    appSexoService.findAll().then((res) => {
      setAppSexos(res);
    });

    const appSucursalService = new AppSucursalService();
    appSucursalService.findAll().then((res) => {
      setAppSucursales(res);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  // useEffect(() => {
  //     const appUsuarioService = new AppUsuarioService()
  //     appUsuarioService.findAll().then(response => {
  //         setAppUsuarios(response)
  //     })
  // }, [])

  const nombreUsuarioTemplate = (rowData) => {
    return (
      <span>
        {rowData.usuNombre} {rowData.usuApellido}
      </span>
    );
  };

  //Methods Dialog
  const dialogFuncMap = {
    dialogNuevoUsuario: setDialogNuevoUsuario,
    cambiarContraseñaDialog: setCambiarContraseñaDialog,
    dialogRolesUsuario: setDialogRolesUsuario,
  };

  const dialogoNuevoUsuario = (dataRow) => {
    setAppUsuario(dataRow);
    setDialogNuevoUsuario(true);
  };

  const dialogoCambioPawd = (dataRow) => {
    setAppUsuario(dataRow);
    setCambiarContraseñaDialog(true);
  };

  const dialogoRolesUsuario = (dataRow) => {
    setAppUsuario(dataRow);
    setDialogRolesUsuario(true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const reject = () => {
    setVisible(false);
  };

  const accionesUsuarioTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-secondary p-mr-2"
          tooltip="Editar"
          onClick={() => dialogoNuevoUsuario(rowData)}
        />
        <Button
          icon="pi pi-flag"
          className="p-button-rounded p-button-success p-mr-2"
          tooltip="Roles"
          onClick={() => dialogoRolesUsuario(rowData)}
        />
        <Button
          icon="pi pi-key"
          className="p-button-rounded p-button-info"
          tooltip="Cambiar Contraseña"
          onClick={() => dialogoCambioPawd(rowData)}
        />
      </div>
    );
  };

  const footerTemplate = () => (
    <>
      <Button
        onClick={() => onHide("dialogNuevoUsuario")}
        icon="pi pi-times"
        label="Cancelar"
        className="mr-2 p-button-danger"
      ></Button>
      <Button
        onClick={() => onSave()}
        icon="pi pi-check"
        label="Guardar"
        className="mr-2"
      ></Button>
    </>
  );

  const footerTemplatePassword = () => (
    <>
      <Button
        onClick={() => onHide("cambiarContraseñaDialog")}
        icon="pi pi-times"
        label="Cancelar"
        className="mr-2 p-button-danger"
      ></Button>
      <Button
        onClick={() => onUpdatePassword()}
        icon="pi pi-check"
        label="Guardar"
        className="mr-2"
      ></Button>
    </>
  );

  const renderTablaUsusarios = () => {
    return (
      <DataTable value={appUsuarios} footer={renderPaginacionUsusarios()}>
        <Column field="usuUsuario" header="Usuario" />
        <Column header="Nombre" body={nombreUsuarioTemplate} />
        <Column field="usuEmail" header="Email" />
        <Column header="Acciones" body={accionesUsuarioTemplate} />
      </DataTable>
    );
  };

  const renderPaginacionUsusarios = () => {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  };

  const createId = () => {
    let id = "";
    let chars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 10; i++) {
      id += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return id;
  };

  const onSave = () => {
    //setSubmitted(true);
    if (!appUsuario?.usuGuid) {
      var pass = createId();
      let _appUsuario = { ...appUsuario };
      let _viewPass = { ...viewPassword };
      _viewPass[`password`] = pass;
      _viewPass[`user`] = appUsuario.usuUsuario;
      setViewPassword(_viewPass);
      _appUsuario[`usuPassword`] = pass;
      _appUsuario[`usuGuid`] = "";

      const appUsuarioService = new AppUsuarioService();

      appUsuarioService.save(_appUsuario).then((response) => {
        setVisible(true);
        const appUsuarioService = new AppUsuarioService();
        appUsuarioService
          .findByPage(
            pageNumber,
            filterUser.usuUsuario,
            filterUser.usuNombre,
            filterUser.usuApellido
          )
          .then((response) => {
            setAppUsuarios(response.content);
         //   setTotalElements(response.totalElements);
            setTotalPages(response.totalPages);
            setPageNumber(response.number);
            setFirstPage(response.first);
            setLastPage(response.last);
          });
        setAppUsuario(appUsuarioEmpty);
       // setSubmitted(false);
        toast.current.show({
          severity: "success",
          detail: "Usuario creado correctamente",
        });
        onHide("dialogNuevoUsuario");
      });
    } else {
      console.log("update");
      const appUsuarioService = new AppUsuarioService();
      appUsuarioService.save(appUsuario).then((response) => {
        const appUsuarioService = new AppUsuarioService();
        appUsuarioService
          .findByPage(
            pageNumber,
            filterUser.usuUsuario,
            filterUser.usuNombre,
            filterUser.usuApellido
          )
          .then((response) => {
            setAppUsuarios(response.content);
          //  setTotalElements(response.totalElements);
            setTotalPages(response.totalPages);
            setPageNumber(response.number);
            setFirstPage(response.first);
            setLastPage(response.last);
          });
        setAppUsuario(appUsuarioEmpty);
      //  setSubmitted(false);
        toast.current.show({
          severity: "success",
          detail: "Usuario actualizado correctamente",
        });
        onHide("dialogNuevoUsuario");
      });
    }
  };
  const onUpdatePassword = () => {
    //setSubmitted(true);
    let _appUsuario = { ...appUsuario };
    _appUsuario[`usuPassword`] = password.password;

    if (password.password !== "" && password.confirmPassword !== "") {
      if (password.password === password.confirmPassword) {
        const appUsuarioService = new AppUsuarioService();
        appUsuarioService.save(_appUsuario).then((response) => {
          setAppUsuario(appUsuarioEmpty);
         // setSubmitted(false);
          toast.current.show({
            severity: "success",
            summary: "Contraseña Actualizada",
            detail: "La contraseña se actualizó correctamente",
          });
          onHide("cambiarContraseñaDialog");
        });
      } else {
        messages.current.show({
          severity: "warn",
          detail: "Las contraseñas no coinciden",
          life: 3000,
        });
      }
    } else {
      const appUsuarioService = new AppUsuarioService();
      appUsuarioService.save(_appUsuario).then((response) => {
        const appUsuarioService = new AppUsuarioService();
        appUsuarioService.findAll().then((response) => {
          setAppUsuarios(response);
        });
        setAppUsuario(appUsuarioEmpty);
       // setSubmitted(false);
        onHide("cambiarContraseñaDialog");
      });
    }
  };

  const renderDataUserEdit = () => {
    return (
      <>
        <div className="p-field p-col-12">
          <label htmlFor="usuUsuario">Usuario</label>
          <InputText
            className="p-inputtext p-component"
            id="usuUsuario"
            name="usuUsuario"
            value={appUsuario?.usuUsuario}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuUsuario: e.target.value })
            }
            required
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="usuNombre">Nombre</label>
          <InputText
            // className="p-inputtext p-component"
            id="usuNombre"
            name="usuNombre"
            value={appUsuario?.usuNombre}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuNombre: e.target.value })
            }
            required
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="usuApellido">Apellido</label>
          <InputText
            // className="p-inputtext p-component"
            id="usuApellido"
            name="usuApellido"
            value={appUsuario?.usuApellido}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuApellido: e.target.value })
            }
            required
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="appSexo">Sexo</label>
          <Dropdown
            id="usuSexo"
            value={appUsuario?.appSexo}
            options={appSexos}
            placeholder="Seleccione un Sexo"
            optionLabel="sexDescripcion"
            onChange={(e) => {
              setAppUsuario({
                ...appUsuario,
                appSexo: e.value,
                sexId: e.value.sexId,
              });
            }}
          />
        </div>
        <div className="p-field p-col-6">
          <label htmlFor="appSucursal">Sucursal</label>
          <Dropdown
            id="appSucursal"
            value={appUsuario?.appSucursal}
            options={appSucursales}
            placeholder="Seleccione una Sucursal"
            optionLabel="sucNombre"
            onChange={(e) => {
              setAppUsuario({
                ...appUsuario,
                appSucursal: e.value,
                sucId: e.value.sucId,
              });
            }}
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="usuEmail">Email</label>
          <InputText
            className="p-inputtext p-component"
            id="usuEmail"
            name="usuEmail"
            value={appUsuario?.usuEmail}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuEmail: e.target.value })
            }
            required
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="usuFechaNacimiento">Fecha Nacimiento</label>
          <input
            type="date"
            className="p-inputtext p-component"
            id="usuFechaNacimiento"
            name="usuFechaNacimiento"
            value={appUsuario?.usuFechaNacimiento}
            onChange={(e) =>
              setAppUsuario({
                ...appUsuario,
                usuFechaNacimiento: e.target.value,
              })
            }
            required
          />
        </div>
        <div className="p-field p-col-12">
          <label htmlFor="usuDireccion">Dirección</label>
          <InputTextarea
            className="p-inputtextarea p-component"
            name="usuDireccion"
            value={appUsuario?.usuDireccion}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuDireccion: e.target.value })
            }
            required
          />
        </div>
        {/* <div className="p-field p-col-12">
                    <label htmlFor="usuGenero">Genero</label>
                    <Dropdown
                        className="p-dropdown p-component"
                        id="usuGenero"
                        name="usuGenero"
                        value={appUsuario.usuGenero}
                        options={generoOptions}
                        onChange={(e) => setAppUsuario({ ...appUsuario, usuGenero: e.value })}
                        required
                    />
                </div> */}
        <div className=" p-col-6">
          <Checkbox
            inputId="usuCajero"
            name="usuCajero"
            checked={appUsuario?.usuCajero}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuCajero: e.target.checked })
            }
          />
          <label htmlFor="usuCajero">Cajero</label>
        </div>
        <div className=" p-col-6">
          <Checkbox
            inputId="usuPromotor"
            name="usuPromotor"
            checked={appUsuario?.usuPromotor}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuPromotor: e.target.checked })
            }
          />
          <label htmlFor="usuPromotor">Promotor</label>
        </div>
        <div className=" p-col-6">
          <Checkbox
            inputId="usuCambiaPwd"
            name="usuCambiaPwd"
            checked={appUsuario?.usuCambiaPwd}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuCambiaPwd: e.target.checked })
            }
          />
          <label htmlFor="usuCambiaPwd">Cambia Pwd</label>
        </div>
        <div className=" p-col-6">
          <Checkbox
            inputId="usuPwdExpira"
            name="usuPwdExpira"
            checked={appUsuario?.usuPwdExpira}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuPwdExpira: e.target.checked })
            }
          />
          <label htmlFor="usuPwdExpira">Pwd Expira</label>
        </div>
        <div className=" p-col-6">
          <Checkbox
            inputId="usuEnabled"
            name="usuEnabled"
            checked={appUsuario?.usuEnabled}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuEnabled: e.target.checked })
            }
          />
          <label htmlFor="usuEnabled">Activo</label>
        </div>
        <div className=" p-col-12">
          <Checkbox
            inputId="usuRestriccionIp"
            name="usuRestriccionIp"
            checked={appUsuario?.usuRestriccionIp}
            onChange={(e) =>
              setAppUsuario({
                ...appUsuario,
                usuRestriccionIp: e.target.checked,
              })
            }
          />
          <label htmlFor="usuRestriccionIp">Restriccion Ip</label>
        </div>
        <br />
        <br />
        <div className="p-field p-col-12">
          <label htmlFor="usuListaIp">Lista Ip's</label>
          <InputTextarea
            id="usuListaIp"
            name="usuListaIp"
            rows={5}
            cols={30}
            value={appUsuario?.usuListaIp}
            onChange={(e) =>
              setAppUsuario({ ...appUsuario, usuListaIp: e.target.value })
            }
            autoResize
            placeholder="1.1.1.1, 2.2.2.2"
          />
        </div>
      </>
    );
  };
  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-success "
        icon="pi pi-check"
        label="Aceptar"
        onClick={reject}
      />
    </>
  );
  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const appUsuarioService = new AppUsuarioService();
      appUsuarioService
        .findByPage(
          pageNumber,
          filterUser.usuUsuario,
          filterUser.usuNombre,
          filterUser.usuApellido
        )
        .then((response) => {
          setAppUsuarios(response.content);
        //  setTotalElements(response.totalElements);
          setTotalPages(response.totalPages);
          setPageNumber(response.number);
          setFirstPage(response.first);
          setLastPage(response.last);

          if (
            filterUser.usuUsuario !== "" ||
            filterUser.usuNombre !== "" ||
            filterUser.usuApellido !== ""
          ) {
            setPageNumber(0);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="card p-fluid">
        <div className="p-formgrid p-grid" onKeyDown={(e) => fil(e)}>
          <div className="p-col-12 p-md-6">
            <h4>{"Filtros"}</h4>
          </div>
          <div className="p-col-12 p-md-6" style={{ textAlign: "right" }}>
            <Button
              icon="pi  pi-user-plus p-component"
              label="Nuevo Usuario"
              className="p-button-warning"
              //tooltip="Nuevo Usuario"
              onClick={() => dialogoNuevoUsuario()}
              style={{ width: "150px" }}
            />
          </div>
          <div className="p-col-12 p-md-2">
            <span className="p-float-label p-mt-2">
              <InputText
                type="text"
                className="p-inputtext p-component"
                id="usuUsuario"
                name="usuUsuario"
                value={filterUser.usuUsuario}
                onChange={(e) =>
                  setFilterUser({ ...filterUser, usuUsuario: e.target.value })
                }
              />
              <label htmlFor="usuUsuario">Usuario</label>
            </span>
          </div>
          <div className="p-col-12 p-md-5">
            <span className="p-float-label p-mt-2">
              <InputText
                type="text"
                className="p-inputtext p-component"
                id="usuNombre"
                name="usuNombre"
                value={filterUser.usuNombre}
                onChange={(e) =>
                  setFilterUser({ ...filterUser, usuNombre: e.target.value })
                }
              />
              <label htmlFor="usuNombre">Nombre</label>
            </span>
          </div>
          <div className="p-col-12 p-md-5">
            <span className="p-float-label p-mt-2">
              <InputText
                type="text"
                className="p-inputtext p-component"
                id="usuApellido"
                name="usuApellido"
                value={filterUser.usuApellido}
                onChange={(e) =>
                  setFilterUser({ ...filterUser, usuApellido: e.target.value })
                }
              />
              <label htmlFor="usuApellido">Apellido</label>
            </span>
          </div>
        </div>
      </div>

      <div className="card">
        <Toast ref={toast}></Toast>
        <div className="p-fliud col-12">{renderTablaUsusarios()}</div>
        <Dialog
          visible={dialogNuevoUsuario}
          className="p-fluid"
          header={
            appUsuario?.usuUsuario !== "" ? "Editar Usuario" : "Nuevo Usuario"
          }
          onHide={() => onHide("dialogNuevoUsuario")}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          footer={footerTemplate}
        >
          <div className="p-formgrid p-grid">{renderDataUserEdit()}</div>
        </Dialog>

        <Dialog
          visible={cambiarContraseñaDialog}
          header={`Cambiar contraseña de ${appUsuario?.usuNombre}`}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "50vw" }}
          modal
          className="p-fluid"
          onHide={() => onHide("cambiarContraseñaDialog")}
          footer={footerTemplatePassword}
        >
          <div className="p-formgrid p-grid">
            <div className="p-field p-col-12">
              <label htmlFor="usuPwd">Contraseña</label>
              <InputText
                type="password"
                className="p-inputtext p-component"
                id="usuPwd"
                name="usuPwd"
                value={password.password}
                onChange={(e) =>
                  setPassword({ ...password, password: e.target.value })
                }
                required
              />
            </div>
            <div className="p-field p-col-12">
              <label htmlFor="usuPwdConfirm">Confirmar Contraseña</label>
              <InputText
                type="password"
                className="p-inputtext p-component"
                id="usuPwdConfirm"
                name="usuPwdConfirm"
                value={password.passwordConfirm}
                onChange={(e) =>
                  setPassword({ ...password, confirmPassword: e.target.value })
                }
                required
              />
            </div>
          </div>
          <Messages ref={messages}></Messages>
        </Dialog>
        <Dialog
          visible={dialogRolesUsuario}
          style={{ width: "450px" }}
          header="Datos del Rol"
          modal
          className="p-fluid"
          // footer={productDialogFooter}
          onHide={() => onHide("dialogRolesUsuario")}
        >
          <ViewRoleUser appUsuario={appUsuario} />
        </Dialog>

        <Dialog
          header="Mensaje del sistema"
          visible={visible}
          onHide={() => reject()}
          breakpoints={{ "960px": "75vw", "640px": "100vw" }}
          style={{ width: "35vw" }}
          footer={deleteProductsDialogFooter}
        >
          <div>
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              <div className="p-grid p-ml-5">
                <div className="p-fluid p-col-12">
                  <b>Usuario : </b> {viewPassword.user} {"  "}
                </div>
                <div className="p-fluid p-col-12">
                  <b> Contraseña :</b>
                  {viewPassword.password}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </>
  );
};
