import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//servicios
import AppBancoService from "../../../service/AppServices/AppBancoService";

//Component
import { Pagination } from "../../../components/Pagination";
import { NuevoBanco } from "./NuevoBanco";
import { Dialog } from "primereact/dialog";
export const AppBanco = ({
  setSelecBanco,
  cuentaBan,
  setCuentaBan,
  select,
  par1,
  par2,
  cantPar,
}) => {
  const message = useRef();
  const toast = useRef();
  let bancoEmpty = {
    banId: 0,
    banNombre: "",
  };

  const [banco, setBanco] = useState(bancoEmpty);
  const [bancos, setBancos] = useState([]);
  const [filtro, setFiltro] = useState({ nombre: "" });
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevoBanco, setNuevoBanco] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  useEffect(() => {
    const appBancoService = new AppBancoService();
    appBancoService.findByPage(pageNumber, filtro.nombre).then((res) => {
      setBancos(res?.content);
      console.log(res?.content);
      setTotalPages(res?.totalPages);
      setFirstPage(res?.first);
      setLastPage(res?.last);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function seleccionar(rowData) {
    if (cantPar === 2) {
      const _cuentaBan = { ...cuentaBan };
      _cuentaBan[`${par1}`] = rowData;
      _cuentaBan[`${par2}`] = rowData?.banId;
      console.log(_cuentaBan);
      setCuentaBan(_cuentaBan);
      setSelecBanco(false);
    } else {
    }
  }

  function acciones(rowData) {
    if (select === "S") {
      return (
        <>
          <Button
            className="p-button-success p-button-rounded p-mr-2"
            icon="pi pi-plus"
            onClick={() => seleccionar(rowData)}
          />
        </>
      );
    } else {
      return (
        <>
          <Button
            className="p-button-info p-button-rounded p-mr-2"
            icon="pi pi-pencil"
            onClick={() => {setNuevoBanco(true); setBanco(rowData)}}
          />
        </>
      );
    }
  }

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const appBancoService = new AppBancoService();
      appBancoService.findByPage(pageNumber, filtro.nombre).then((res) => {
        setBancos(res?.content);
        setTotalPages(res?.totalPages);
        setFirstPage(res?.first);
        setLastPage(res?.last);
        if (filtro.nombre !== "") {
          setPageNumber(0);
        }
      });
    }
  };

  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <label className="p-mr-4">Nombre del banco </label>
        <span className="p-input-icon-right">
          <i className="pi pi-search" />
          <InputText
            placeholder="Banco..."
            type="search"
            onChange={(e) => {
              setFiltro({
                ...filtro,
                nombre: e.target.value.toUpperCase(),
              });
            }}
          />
        </span>
      </div>
    );
  }

  function right() {
    return (
      <Button
        className="p-button-success"
        icon="pi pi-plus"
        label="Nuevo"
        onClick={() => {setNuevoBanco(true); setBanco(bancoEmpty)}}
      />
    );
  }

  function search() {
    if (select !== "S") {
      return (
        <>
          <Toolbar className="p-mb-4" right={right} left={left} />
        </>
      );
    }
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-mr-2 "
          onClick={() => setNuevoBanco(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save() {
    if (banco.banNombre !== "") {
      const appBancoService = new AppBancoService();
      appBancoService.save(banco).then((res) => {
        console.log(res);
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: `Banco agregado`,
          life: 4000,
        });
        setEstado(!estado);
        setNuevoBanco(false);
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }
  return (
    <div className="card">
      {search()}
      <DataTable
        value={bancos}
        footer={renderPaginacion()}
        emptyMessage="No se encontro ningun Banco"
      >
        <Column header="Id" field="banId" />
        <Column header="Nombre" field="banNombre" />
        <Column header="Acciones" body={acciones} />
      </DataTable>

      <Dialog
        visible={nuevoBanco}
        header="Nuevo Banco"
        onHide={() => setNuevoBanco(false)}
        footer={footer}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
      >
        <NuevoBanco banco={banco} setBanco={setBanco} submitted={submitted} />
        <Messages ref={message} />
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
