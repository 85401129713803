import React, { useEffect, useState } from "react";
import classNames from "classnames";
import AppBreadcrumb from "./AppBreadcrumb";
import useAuth from "./auth/useAuth";
import { Link } from "react-router-dom";
import AppModuloService from "./service/AppServices/AppModuloService";
import { Button } from "primereact/button";
const AppTopbar = (props) => {

  const auth = useAuth();
 // const [appModulos, setAppModulos] = useState([]);
  const [dateNow, setDateNow] = useState(false);
  // const notificationsItemClassName = classNames("notifications-item", {
  //   "active-menuitem": props.topbarNotificationMenuActive,
  // });
  const profileItemClassName = classNames("profile-item", {
    "active-menuitem fadeInDown": props.topbarUserMenuActive,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var dayNow = year + "-" + month + "-" + day;


      try {
        const appModuloService = new AppModuloService();
        appModuloService.findAll().then((response) => {
          // setAppModulos(response);

          if (response !== undefined) {
            sessionStorage.setItem("fechamodaho", JSON.stringify(response[0]));
            setDateNow(dayNow === response[4]?.modFechaHoy);
          }
        });
      } catch (error) {
        //window.location.reload();
      }
  
  }, []);

  const fechaOk = () => {
    return (
      <div
        style={{
          height: "8px",
          width: "8px",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "#5ab507",
          MozBorderRadius: "50%",
          WebkitBorderRadius: "50%",
          borderRadius: "50%",
        }}
      ></div>
    );
  };

  const fechaErr = () => {
    return (
      <div
        style={{
          height: "8px",
          width: "8px",
          alignItems: "center",
          textAlign: "center",
          backgroundColor: "#e00000",
          MozBorderRadius: "50%",
          WebkitBorderRadius: "50%",
          borderRadius: "50%",
        }}
      ></div>
    );
  };
  return (
    <div className="layout-topbar">
      <div className="topbar-left">
        <button
          type="button"
          className="menu-button p-link"
          onClick={props.onMenuButtonClick}
        >
          <i className="pi pi-chevron-left"></i>
        </button>
        <span className="topbar-separator"></span>

        <div
          className="layout-breadcrumb viewname"
          style={{ textTransform: "uppercase" }}
        >
          <AppBreadcrumb routers={props.routers} />
        </div>

        <img
          id="logo-mobile"
          className="mobile-logo"
          src="assets/layout/images/logo-dark.svg"
          alt="diamond-layout"
        />
      </div>

      <div className="topbar-right">
        <ul className="topbar-menu">
          {/* <li className="search-item">
            <button
              type="button"
              className="p-link"
              onClick={props.onSearchClick}
            >
              <i className="pi pi-search"></i>
            </button>
          </li> */}

          <li>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                display: "flex",
              }}
            >
              <Button
                tooltipOptions={{
                  position: "bottom",
                  mouseTrack: true,
                  mouseTrackTop: 15,
                }}
                className={
                  dateNow === true
                    ? "p-button-rounded p-button-success p-button-text mr-5"
                    : "p-button-rounded p-button-danger p-button-text mr-5"
                }
                tooltip={
                  dateNow === true
                    ? "Fecha de modulos correcta "
                    : "Fecha de mudulos incorrectas"
                }
                icon={dateNow === true ? fechaOk() : fechaErr()}
                style={{
                  height: "13px",
                  width: "13px",
                  marginRight: "10px",
                  MozBorderRadius: "50%",
                  WebkitBorderRadius: "50%",
                  borderRadius: "50%",
                  border: "none",
                }}
              ></Button>

              <strong>{auth.user?.usr?.appSucursal?.sucNombre} </strong>
            </div>
          </li>

          {/* <li className={notificationsItemClassName}>
            <button
              type="button"
              className="p-link"
              onClick={props.onTopbarNotification}
            >
              <i className="pi pi-bell"></i>
              <span className="topbar-badge">5</span>
            </button>
            <ul className="notifications-menu fade-in-up">
              <li role="menuitem">
                <button type="button" className="p-link" tabIndex="0">
                  <i className="pi pi-shopping-cart"></i>
                  <div className="notification-item">
                    <div className="notification-summary">New Order</div>
                    <div className="notification-detail">
                      You have <strong>3</strong> new orders.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-check-square"></i>
                  <div className="notification-item">
                    <div className="notification-summary">
                      Withdrawn Completed
                    </div>
                    <div className="notification-detail">
                      Funds are on their way.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-chart-line"></i>
                  <div className="notification-item">
                    <div className="notification-summary">Monthly Reports</div>
                    <div className="notification-detail">
                      New reports are ready.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-comments"></i>
                  <div className="notification-item">
                    <div className="notification-summary">Comments</div>
                    <div className="notification-detail">
                      <strong>2</strong> new comments.
                    </div>
                  </div>
                </button>
              </li>
              <li role="menuitem">
                <button type="button" className="p-link">
                  <i className="pi pi-exclamation-circle"></i>
                  <div className="notification-item">
                    <div className="notification-summary">
                      Chargeback Request
                    </div>
                    <div className="notification-detail">
                      <strong>1</strong> to review.
                    </div>
                  </div>
                </button>
              </li>
            </ul>
          </li> */}

          <li className={profileItemClassName}>
            <button
              type="button"
              className="p-link"
              onClick={props.onTopbarUserMenu}
            >
              {/* <img
                src={`assets/demo/images/avatar/${auth.user?.usr.usuFotografia}`}
                alt="Avatar"
                className="profile-image"
              /> */}
              <span style={{ marginRight: "8px" }}>
                <div className="avatar-mini">
                  <div className="avatar__letters">
                    {auth.user?.usr?.usuNombre.substring(0, 1)}
                    {auth.user?.usr?.usuApellido.substring(0, 1)}
                  </div>
                </div>
              </span>

              <span className="profile-name">
                {auth.user?.usr?.usuNombre + " " + auth.user?.usr?.usuApellido}
              </span>
            </button>
            <ul className="profile-menu fade-in-up">
              <li>
                <button type="button" className="p-link">
                  <i className="pi pi-user"></i>
                  <Link
                    to={{
                      pathname: "/appperfilusuario",
                      state: { usuUsuario: auth.user?.usr?.usuUsuario },
                    }}
                  >
                    <span>Perfil</span>
                  </Link>
                </button>
              </li>
              {/* <li>
                <button type="button" className="p-link">
                  <i className="pi pi-cog"></i>
                  <span>Configuración</span>
                </button>
              </li> */}
              {/* <li>
                <button type="button" className="p-link">
                  <i className="pi pi-calendar"></i>
                  <span>Calendario</span>
                </button>
              </li>
              <li>
                <button type="button" className="p-link">
                  <i className="pi pi-inbox"></i>
                  <span>Bandeja de entrada</span>
                </button>
              </li> */}
              <li>
                <button type="button" className="p-link" onClick={auth.logout}>
                  <i className="pi pi-power-off"></i>
                  <span>Cerrar sesión</span>
                </button>
              </li>
            </ul>
          </li>

          <li className="right-sidebar-item">
            <button type="button" className="p-link">
              <i className="pi pi-align-right"></i>
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AppTopbar;
