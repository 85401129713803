import React, { useState, useEffect, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { Toast } from "primereact/toast";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
import { NuevaAprobacion } from "./NuevaAprobacion";
//servicios
import AppPreAprobacionService from "../../../../service/AppServices/AppPreAprobacionService";
//funciones
import formatMiles from "../../../../utilities/formatMiles";
import onChajeObjN from "../../../../utilities/onChajeObjN";
//Componentes
import { Pagination } from "../../../../components/Pagination"; 

export const Aprobacion = () => {
  const toast = useRef();
  const message = useRef();
  let aprobacionLet = {
    preAproDescripcion: "",
    preAproId: 0,
    preAproLimite: true,
    preAproMax: 0,
    preAproMin: 0,
  };
  let filtroEmpty = {
    descripcion: "",
  };

  const [filtro, setFiltro] = useState(filtroEmpty);
  const [aprobacion, setAprobacion] = useState(aprobacionLet);
  const [aprobaciones, setAprobaciones] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevaAprobacion, setNuevaAprobacion] = useState(false);
  const [estado, setEstado] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  useEffect(() => {
    const aprobacionService = new AppPreAprobacionService();
    aprobacionService
      .findByPage(pageNumber, filtro.descripcion)
      .then((result) => {
        setAprobaciones(result?.content);
        setTotalPages(result?.totalPages);
        setPageNumber(result?.number);
        setFirstPage(result?.first);
        setLastPage(result?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9 || charCode === 13) {
      const aprobacionService = new AppPreAprobacionService();
      aprobacionService
        .findByPage(pageNumber, filtro.descripcion)
        .then((result) => {
          setAprobaciones(result?.content);
          setTotalPages(result?.totalPages);
          setPageNumber(result?.number);
          setFirstPage(result?.first);
          setLastPage(result?.last);
          if (filtro.descripcion !== "") {
            setPageNumber(0);
          }
        });
    }
  };

  function monMax(rowData) {
    return <div style={{textAlign:"right"}}>Q. {formatMiles(rowData.preAproMax || 0) || "NO APLICA"}</div>;
  }
  function monMin(rowData) {
    return <div style={{textAlign:"right"}}>Q. {formatMiles(rowData.preAproMin || 0) || "NO APLICA"}</div>;
  }

  function apliLim(rowData) {
    return <>{rowData?.preAproLimite === true ? "SI APLICA" : "NO APLICA"}</>;
  }

  function left() {
    return (
      <div onKeyDown={(e) => fil(e)}>
        <span className="p-float-label p-mt-2">
          <InputText
            type={"search"}
            className="p-inputtext-sm"
            value={filtro.descripcion}
            onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
          />
          <label>APROBACION</label>
        </span>
      </div>
    );
  }
  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {
            setNuevaAprobacion(true); setAprobacion(aprobacionLet)
          }}
        />
      </>
    );
  }
  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning  mr-2 p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setAprobacion(rowData); setNuevaAprobacion(true)}}
        />
         <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setAprobacion(rowData); setEliminar(true)}}
        />
      </>
    );
  }

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-button-text p-mr-2 "
          onClick={() => setNuevaAprobacion(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => beforSave()}
        />
      </>
    );
  }

  function beforSave() {
    if (
      aprobacion.preAproLimite &&
      aprobacion?.preAproDescripcion !== "" &&
      aprobacion.preAproMax !== 0 &&
      aprobacion.preAproMin !== 0 &&
      aprobacion.preAproMax !== "" &&
      aprobacion.preAproMin !== ""
    ) {
      save(aprobacion);
    } else if (
      aprobacion.preAproLimite === false &&
      aprobacion?.preAproDescripcion !== ""
    ) {
      const _aprobacion = { ...aprobacion };
      _aprobacion["preAproMax"] = null;
      _aprobacion["preAproMin"] = null;
      save(_aprobacion);
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }

  function save(aprobacionSave) {
    const aprobacionService = new AppPreAprobacionService();
    aprobacionService.save(aprobacionSave).then(() => {
      setEstado(!estado);
      setNuevaAprobacion(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Aprobacion creada con exito",
        life: 4000,
      });
    });
  }

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }
  function renderTable() {
    return (
      <>
        <DataTable
          emptyMessage="No se encontro ninguna aprobacion"
          value={aprobaciones}
          footer={renderPaginacion()}
        >
          <Column header="No." field="preAproId" />
          <Column header="Aprobacion" field="preAproDescripcion" />
          <Column header="Tiene limite" body={apliLim} />
          <Column header="Monto maximo" field="preAproMax" body={monMax} />
          <Column header="Monto minimno" field="preAproMin" body={monMin} />
          <Column header="Acciones" body={acciones} />
        </DataTable>
      </>
    );
  }

  function borrar() {
    const aprobacionService = new AppPreAprobacionService();
    aprobacionService.delete(aprobacion.preAproId).then(()=>{
      setEstado(!estado);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Aprobacion eliminada",
        life: 4000,
      });
    })
  }

  const elimarProducto = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Yes" icon="pi pi-check" onClick={borrar} />
    </>
  );

  return (
    <div className="card">
      <Toolbar className="p-mb-4" left={left} right={right} />
      {renderTable()}
      <Dialog
        visible={nuevaAprobacion}
        onHide={() => setNuevaAprobacion(false)}
        header="Nueva Aprobacion"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevaAprobacion
          aprobacion={aprobacion}
          setAprobacion={setAprobacion}
          submitted={submitted}
        />
        <Messages ref={message} />
      </Dialog>
      <Toast ref={toast} />
      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={elimarProducto}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar esta garantia .</span>
        </div>
      </Dialog>
    </div>
  );
};
