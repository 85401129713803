import React, { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import classNames from "classnames";
//funciones
import selectText from "../../../utilities/selecText";
import onChajeObjN from "../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../utilities/onChangeObjDropDawn";
//componentes
import { CliAsociadoSeleccionar } from "../../ClientesPages/CliAsociadoSeleccionar";

//servicios
import EventoService from "../../../service/AsambleaServices/EventoService";
import CliAsociadoService from "../../../service/CliServices/CliAsociadoService";
import EventoParticipanteService from "../../../service/AsambleaServices/EventoParticipanteService";
export const NuevoRegistro = ({
  setNuevoRegistro,
  toast,
  registro,
  setRegistro,
  estado,
  setEstado,
}) => {
  const message = useRef();

  const [buscar, setBuscar] = useState(false);
  const [eventos, setEventos] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [dia, setDia] = useState(false);
  useEffect(() => {
    const eventoService = new EventoService();
    eventoService.findAll().then((res) => {
      setEventos(res);
      if (registro?.parAsoId !== 0) {
        res?.forEach((element) => {
          if (element.eveId === registro.parEveId) {
            let _registro = { ...registro };
            _registro["evtEvento"] = element;
            _registro["parEveId"] = element.eveId;

            var primerNombre = registro?.primerNombre + "";
            var segundoNombre = registro?.segundoNombre + "";
            var tercerNombre = registro?.otroNombre + "";
            var primerApellido = registro?.primerApellido + "";
            var segundoApellido = registro?.segundoApellido + "";
            var apellidoCasada = registro?.apellidoCasada + "";

            // var nombre =
            //   registro?.primerNombre +
            //   " " +
            //   registro?.segundoNombre +
            //   " " +
            //   registro?.otroNombre +
            //   " " +
            //   registro?.primerApellido +
            //   " " +
            //   registro?.asoSegundoApellido +
            //   " " +
            //   registro?.apellidoCasada;
            var nombre =
              primerNombre.replace("null" || "undefined", "") +
              " " +
              segundoNombre.replace("null" || "undefined", "") +
              " " +
              tercerNombre.replace("null" || "undefined", "") +
              " " +
              primerApellido.replace("null" || "undefined", "") +
              " " +
              segundoApellido.replace("null" || "undefined", "") +
              " " +
              apellidoCasada.replace("null" || "undefined", "");

            var nombre2 = nombre.replace("   ", " ");
            var nombre3 = nombre2.replace("  ", " ");
            _registro["nom"] = nombre3;

            var date = new Date();
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            var day = date.getDate();
            if (day < 10) {
              day = "0" + day;
            }
            if (month < 10) {
              month = "0" + month;
            }
            var dayNow = year + "-" + month + "-" + day;
            setDia(_registro.evtEvento.eveFecha === dayNow);
            setRegistro(_registro);
          }
        });
      }
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {}, []);

  // const fil = (evt) => {
  //   var e = evt;
  //   var charCode = e.which || e.keyCode;

  //   if (charCode === 9) {
  //     if (
  //       registro?.parAsoId !== 0 &&
  //       registro?.parAsoId !== undefined &&
  //       registro?.parAsoId !== null &&
  //       registro?.parAsoId !== ""
  //     ) {
  //       const cliAsociadoService = new CliAsociadoService();
  //       cliAsociadoService.findByIdClau(registro?.parAsoId).then((res) => {
  //         if (res === false) {
  //           message.current.show({
  //             severity: "warn",
  //             summary: "error",
  //             content: "Asociado no encontrado",
  //             life: 4000,
  //           });
  //         } else {
  //           var nombre = res.name;
  //           let _cliPersona = { ...registro };
  //           _cliPersona[`nom`] = nombre;
  //           setRegistro(_cliPersona);
  //         }
  //       });
  //     }
  //   }
  // };

  function asoNombre() {
    
    if (registro?.parAsoId !== 0 ) {
      const cliAsociadoService = new CliAsociadoService();
        cliAsociadoService.findByIdClau(registro?.parAsoId).then((res) => {
          if (res === undefined || res === false) {
            toast.current.show({
              severity: "warn",
              summary: "error",
              content: "Asociado no encontrado",
              life: 4000,
            });
            let _cliPersona = { ...registro };
            _cliPersona[`nom`] = "";
            _cliPersona[`parAsoId`] = 0;
            setRegistro(_cliPersona);
          } else {
            var nombre = res.name;
            let _cliPersona = { ...registro };
            _cliPersona[`nom`] = nombre;
            setRegistro(_cliPersona);
          }
        });
    }
}


  function save() {
    if (
      registro.parAsoId &&
      registro?.parEveId !== 0 &&
      registro.parAsoId &&
      registro?.parEveId !== ""
    ) {
      const eventoParticipanteService = new EventoParticipanteService();
      eventoParticipanteService.save(registro).then((res) => {
        if (res?.response?.data?.code === 302) {
          message.current.show({
            severity: "warn",
            summary: res?.response?.data?.message,
            content: res?.response?.data?.description,
            life: 4000,
          });
        } else {
          setEstado(!estado);
          setNuevoRegistro(false);
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Persona Inscrita",
            life: 4000,
          });
        }
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => {
        setSubmitted(false);
      }, 4000);
    }
  }

  const renderForm = () => {
    return (
      <div>
        <div className="p-fluid p-grid">
          <div className="p-col" 
          //onKeyDown={(e) => fil(e)}
          >
            <label>Codigo</label>
            <div className="p-inputgroup">
              <InputText
                value={registro?.parAsoId || ""}
                id="codigo"
                onClick={(e) => selectText(e)}
                placeholder="Codigo"
                onChange={(e) =>
                  onChajeObjN(e, "parAsoId", registro, setRegistro)
                }
                className={classNames({
                  "p-invalid": submitted && !registro?.parAsoId,
                })}
                disabled={registro?.parId !== 0}
                onBlur={() => asoNombre()}
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setBuscar(true)}
                disabled={registro?.parId !== 0}
              />
            </div>
            {submitted && !registro?.parAsoId && (
              <small className="p-invalid">Codigo requerido.</small>
            )}
          </div>
          <div className="p-col">
            <label>Nombre</label>
            <InputText
              value={registro?.nom || ""}
              placeholder="Nombre"
              id="nombre"
              onClick={(e) => selectText(e)}
              disabled={true}
              className={classNames({
                "p-invalid": submitted && !registro?.nom,
              })}
            />
            {submitted && !registro?.nom && (
              <small className="p-invalid">Nombre requerido.</small>
            )}
          </div>
        </div>
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Actividad a participar</label>
            <Dropdown
              value={registro?.evtEvento || ""}
              placeholder="Seleccione..."
              options={eventos}
              optionLabel="eveNombre"
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "evtEvento",
                  e.target.value.eveId,
                  "parEveId",
                  registro,
                  setRegistro
                )
              }
              disabled={dia}
              className={classNames({
                "p-invalid": submitted && !registro?.parEveId,
              })}
            />
            {submitted && !registro?.parEveId && (
              <small className="p-invalid">Seleccione una actividad.</small>
            )}
          </div>
        </div>
      </div>
    );
  };

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelsar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscar(false)}
        />
      </>
    );
  }

  function cambioEstado() {
    let _registro = { ...registro };
    _registro["parEstado"] = true;
    const eventoParticipanteService = new EventoParticipanteService();
    eventoParticipanteService.upDate(_registro).then((res) => {
      setEstado(!estado);
      setNuevoRegistro(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "La persona se encuentra participando",
        life: 4000,
      });
    });
  }

  return (
    <div>
      {renderForm()}

      <Messages ref={message} />
      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "100px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => setNuevoRegistro(false)}
        />
        <Button
          style={{ width: "100px" }}
          className=" p-button-info p-mr-2 "
          icon="pi pi-check"
          label="Inscribir"
          onClick={() => save()}
          disabled={dia}
        />
        <Button
          style={{ width: "120px" }}
          className="p-button-success "
          icon="pi pi-check "
          label="Participar"
          onClick={() => cambioEstado()}
       //   disabled={!dia}
        />
      </div>

      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={registro}
          setPersona={setRegistro}
          parametro1={"parAsoId"}
          parametro2={"nom"}
          cantPar={2}
        />
      </Dialog>
    </div>
  );
};
