import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import { Link } from "react-router-dom";
import useAuth from "./../../../auth/useAuth";
//Service
import AhoCuentaService from "../../../service/AhoCuentaService/AhoCuentaService";
import { Toolbar } from "primereact/toolbar";

export const ListarCuentasAhorro = () => {
  const auth = useAuth();
  let ahoCuentaFilter = {
    ahoEst: 0,
    ahoId: "",
    ahoNom: "",
    asoId: "",
  };

  //UseState
  let ahoCuentaEmpty = {
    ahoActivoInactivo: "A",
    ahoCertificadoImpreso: 0,
    ahoCondicionEspecial: 0,
    ahoEstado: 1,
    ahoFechaApertura: "",
    ahoFechaCancelacion: "",
    ahoFechaModifica: "",
    ahoFechaProximoPago: "",
    ahoFechaRenovacion: "",
    ahoFechaUltimoPago: "",
    ahoFechaVencimiento: "",
    ahoId: "",
    ahoIdAcreditar: "",
    ahoImpuestoAcumulado: 0,
    ahoImpuestoMes: 0,
    ahoInteresAcumulado: 0,
    ahoInteresMes: 0,
    ahoLineaLibreta: 0,
    ahoMontoInicial: 0,
    ahoNombre: "",
    ahoNumeroCertificado: 0,
    ahoNumeroFirmaRegistra: 1,
    ahoNumeroFirmaRequiere: 1,
    ahoNumeroLibreta: 0,
    ahoPaginaLibreta: 1,
    ahoPenalizado: true,
    ahoPlazoDeposito: 0,
    ahoSaldoAnterior: 0,
    ahoSaldoLibreta: 0,
    ahoSaldoReserva: 0,
    ahoSaldoTotal: 0,
    ahoTasaBase: 0,
    ahoTasaNegociado: 0,
    ahoReferencia: null,
    ahoTipoTasa: {
      tasAhoNombre: "PASIVO",
      tasId: 2,
    },
    appAhoCausaBloqueo:null,
    appAhoFormaPagoCap: null,
    appAhoFormaPagoInt: null,
    appAhoSubProducto: {
      monId: 0,
      proId: 0,
      subAhoId: 0,
      subAhoNombre: "",
      subAhoPenalizacion: "",
      subAhoTasaIsr: 0,
      subAhoTasaPenaliza: 0,
      subAhopagaIsr: "",
      subTipoId: 0,
      tasId: 0,
    },
    cliDireccion:null,
    causaBloqueoId:null,
    appAhoTipoCuenta:null,
    appMoneda:null,
    appFrecuencia: null,
    appProducto:null,
    asoId: 0,
    dirId: null,
    forPagoCapId: null,
    forPagoIntId: null,
    freId: null,
    proId: 0,
    subAhoId: 0,
    sucId: auth.user?.usr.sucId,
    tasAhoId: 2,
    usuCancela: null,
    usuIngresa: null,
    usuModifica: null,
    usuUsuarioPromotor: auth.user?.usr.usuUsuario,
  };

  const [ahoCuentaList, setAhoCuentaList] = useState([]);
  const [ahoCuentaListFilter, setAhoCuentaListFilter] =
    useState(ahoCuentaFilter);

  //States para la paginacion
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const ahoCuentaService = new AhoCuentaService();
    ahoCuentaService.findByPage(pageNumber, ahoCuentaListFilter).then((res) => {
      if (res !== undefined) {
        setAhoCuentaList(res?.content);
        setTotalPages(res?.totalPages);
        setPageNumber(res?.number);
        setFirstPage(res?.first);
        setLastPage(res?.last);
      }
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, ahoCuentaListFilter.ahoEst]);

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9) {
      const ahoCuentaService = new AhoCuentaService();
      ahoCuentaService
        .findByPage(pageNumber, ahoCuentaListFilter)
        .then((res) => {
          setAhoCuentaList(res?.content);
          setTotalPages(res?.totalPages);
          setPageNumber(res?.number);
          setFirstPage(res?.first);
          setLastPage(res?.last);
          if (
            ahoCuentaListFilter.ahoId !== "" ||
            ahoCuentaListFilter.ahoNom !== "" ||
            ahoCuentaListFilter.asoId !== "" ||
            ahoCuentaListFilter.ahoEst !== 0
          ) {
            setPageNumber(0);
          }
        });
    }
  };

  //render filter cuentas ahorro
  const renderFilterCuentasAhorro = () => {
    return (
      <div>
        <div className="p-fluid p-grid p-formgrid p-mt-2">
          <div className=" p-col-3 " onKeyDown={(e) => fil(e)}>
            <span className="p-float-label">
              <InputText
                style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                id="AhoId"
                name="AhoId"
                value={ahoCuentaListFilter.ahoId}
                onChange={(e) => {
                  setAhoCuentaListFilter({
                    ...ahoCuentaListFilter,
                    ahoId: e.target.value,
                  });
                }}
              />
              <label htmlFor="AhoId">Número de cuenta</label>
            </span>
          </div>

          <div className=" p-col-2" onKeyDown={(e) => fil(e)}>
            <span className="p-float-label">
              <InputText
                style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                id="AsoId"
                name="AsoId"
                value={ahoCuentaListFilter.asoId}
                onChange={(e) => {
                  setAhoCuentaListFilter({
                    ...ahoCuentaListFilter,
                    asoId: e.target.value,
                  });
                }}
              />
              <label htmlFor="AsoId">Código</label>
            </span>
          </div>
          <div className="p-col" onKeyDown={(e) => fil(e)}>
            <span className="p-float-label">
              <InputText
                style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                value={ahoCuentaListFilter.ahoNom}
                onChange={(e) => {
                  setAhoCuentaListFilter({
                    ...ahoCuentaListFilter,
                    ahoNom: e.target.value.toUpperCase(),
                  });
                }}
              />
              <label htmlFor="AhoNom">Nombre</label>
            </span>
          </div>
          <div className=" p-col-3">
            <span className="p-float-label">
              <Dropdown
                style={{ fontWeight: "bold", backgroundColor: "#f5f5f5" }}
                id="AhoEst"
                name="AhoEst"
                value={ahoCuentaListFilter.ahoEst}
                options={[
                  { label: "Vigente", value: 1 },
                  { label: "Bloqueado", value: 2 },
                  { label: "Cancelado", value: 3 },
                  { label: "Vencido", value: 4 },
                  { label: "Todos", value: 5 },
                ]}
                onChange={(e) => {
                  setAhoCuentaListFilter({
                    ...ahoCuentaListFilter,
                    ahoEst: e.value,
                  });
                }}
               
              />
              <label htmlFor="AhoEst">
                Estado {ahoCuentaListFilter.ahoEst === 0 ? "(Todos)" : ""}
              </label>
            </span>
          </div>
        </div>
      </div>
    );
  };

 const right  =() =>{
  return (
   
   
        <div >
          <Link
            to={{
              pathname: "/ahonuevo",
            }}
          >
          <Button
                label="Nuevo"
                icon="pi pi-plus"
                className="p-button-success "
                onClick={() =>
                  sessionStorage.setItem(
                    "Ahorro",
                    JSON.stringify(ahoCuentaEmpty)
                  )
                }
                />
          </Link>
        </div>
      );
 
  
 }
  const renderPaginacion = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <br />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-double-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={totalPages === "0" || firstPage}
          onClick={() => setPageNumber(0)}
        />
        <Button
          type="button"
          label=""
          icon="pi pi-angle-left"
          className="p-button-outlined p-button-secondary "
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={totalPages === "0" || firstPage}
          onClick={() => setPageNumber(pageNumber - 1)}
        />

        <InputText
          type="text"
          className="p-inputtext p-component"
          placeholder={
            totalPages > 0
              ? pageNumber + 1 + " de " + totalPages
              : pageNumber + " de " + totalPages
          }
          style={{
            width: "100px",
            height: "30px",
            borderColor: "#5499C7",
            textAlign: "center",

            fontSize: "15px",
            background: "transparent",
          }}
          readOnly
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-right"
          style={{
            background: "transparent; color: #5499C7",
            marginRight: "4px",
            border: "none",
          }}
          disabled={totalPages === "0" || lastPage}
          onClick={() => setPageNumber(pageNumber + 1)}
        />

        <Button
          type="button"
          label=""
          className="p-button-outlined p-button-secondary "
          icon="pi pi-angle-double-right"
          style={{
            background: "transparent; color: #6E707A",
            marginRight: "4px",
            border: "none",
          }}
          disabled={totalPages === "0" || lastPage}
          onClick={() => setPageNumber(totalPages - 1)}
        />
      </div>
    );
  };

  const estadoCuenta = (rowData) => {
    return (
      <div>
        <span>
          {rowData.ahoEstado === 1
            ? "VIGENTE"
            : rowData.ahoEstado === 2
            ? "BLOQUEADO"
            : rowData.ahoEstado === 3
            ? "CANCELADO"
            : "NO ENCONTRADO"}
        </span>
      </div>
    );
  };

  const certificadoLibreta = (rowData) => {
    return (
      <div>
        <span>
          {rowData.ahoNumeroLibreta !== 0
            ? rowData.ahoNumeroLibreta
            : rowData.ahoNumeroCertificado}
        </span>
      </div>
    );
  };

  const accionesAhorro = (rowData) => {
    return (
      <div>
        <Link
          to={{
            pathname: "/ahonuevo",
          }}
        >
          <Button
            //   label="Imprimir"
            className="p-button-default p-button-rounded"
            icon="pi pi-eye"
            tooltip="ver"
            onClick={() =>
              sessionStorage.setItem("Ahorro", JSON.stringify(rowData))
            }
          />
        </Link>
      </div>
    );
  };
  const formatDate = (value) => {
    const event = new Date(value);

    var mm = event.getMonth() + 1;
    var dd = event.getDate() + 1;
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = dd + "/" + mm + "/" + yy;

    return myDateString;
  };

  const date = (rowData) => {
    return formatDate(rowData.ahoFechaApertura);
  };

  const renderListCuentasAhorro = () => {
    return (
      <>
        <DataTable
          value={ahoCuentaList}
          style={{ backgroundColor: "#f5f5f5" }}
          responsive={true}
          lazy={true}
          footer={renderPaginacion()} 
          className="p-datatable-tbody"
         
         
  
        >
          <Column field="ahoId" header="No. Cuenta" />
          <Column field="ahoNombre" header="Nombre" />
          <Column
            field="appAhoSubProducto.subAhoNombre"
            header="Sub Producto"
          />
          <Column
            body={certificadoLibreta}
            header="Certificado / Libreta"
            bodyStyle={{ textAlign: "right", overflow: "visible" }}
          />
          <Column
            field="ahoFechaApertura"
            body={date}
            header="Fecha de Apertura"
          />
          <Column
            field="ahoEstado"
            body={estadoCuenta}
            header="Estado"
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
          <Column
            header="Acciones"
            body={accionesAhorro}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
        </DataTable>
      </>
    );
  };

  return (
    <div>
   
      <div className="card">
        <Toolbar className="p-mb-4" left= {renderFilterCuentasAhorro} right={right}>  </Toolbar>
     
        {renderListCuentasAhorro()}</div>
    </div>
  );
};
