import React from "react";
import classNames from "classnames";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

//FUNCIONES
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";
export const NuevaGarantia = ({ garantia, setGarantia, submitted }) => {
  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col">
          <label>Descripcion</label>
          <InputText
          id="des"
            value={garantia.preGarDescripcion}
            onChange={(e) =>
              onChajeObjN(e, "preGarDescripcion", garantia, setGarantia)
            }
            placeholder="Descripcion"
            className={classNames({
              "p-invalid": submitted && !garantia.preGarDescripcion,
            })}
            onClick={(e)=>selectText(e)}
          />
          {submitted && !garantia.preGarDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}
        </div>
        <div className="p-col">
          <label>Tipo de garantia</label>
          <Dropdown
            value={garantia.preGarTipo}
            options={[
              { value: 1, label: "FIDUCIARIO" },
              { value: 2, label: "DERECHO DE POSESION" },
              { value: 3, label: "HIPOTECARIO" },
              { value: 4, label: "BACK TO BACK" },
            ]}
            onChange={(e) =>
              setGarantia({
                ...garantia,
                preGarTipo: e.target.value,
              })
            }
            placeholder="Tipo de garantia"
            className={classNames({
              "p-invalid": submitted && !garantia.preGarTipo,
            })}
            disabled={garantia.preGarId !== 0}
          />
          {submitted && !garantia.preGarTipo && (
            <small className="p-invalid">Tipo de garantia requerida</small>
          )}
        </div>
      </div>
    </div>
  );
};
