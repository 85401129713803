import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
// import { Chips } from "primereact/chips";
// import { Chip } from "primereact/chip";
import { InputText } from "primereact/inputtext";
// import { Checkbox } from "primereact/checkbox";
// import BlockViewer from "../../../../BlockViewer";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";//import { useLocation } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Divider } from "primereact/divider";
// import { Badge } from "primereact/badge";
import useAuth from "../../../../auth/useAuth";

import AppUsuarioService from "../../../../service/AppUsuarioService";
// import AppSexoService from "../../../../service/AppServices/AppSexoService";
import AppSucursalService from "../../../../service/AppServices/AppSucursalService";

export const PerfilUsuario = () => {
  const messages = useRef(null);
  const toast = useRef(null);
  // const location = useLocation();
  const auth = useAuth();

  const appUsuarioEmpty = {
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucCodigoAbreviado: "",
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
      sucNombreAbreviado: "",
    },
    sexId: 0,
    sucId: 0,
    usuApellido: "",
    usuCajero: true,
    usuCambiaPwd: true,
    usuDireccion: "",
    usuEmail: "",
    usuEnabled: true,
    usuFechaExpPwd: "",
    usuFechaNacimiento: "",
    usuFotografia: "",
    usuGenero: "",
    usuGuid: "",
    usuIdExterno: "",
    usuListaIp: "",
    usuNombre: "",
    usuPassword: "",
    usuPromotor: true,
    usuPwdExpira: true,
    usuRestriccionIp: true,
    usuUsuario: "",
  };

  const appSucursalEmpty = {
    appEmpresa: {
      empGerente: "string",
      empId: 0,
      empNombre: "string",
    },
    sucCodigoAbreviado: "string",
    sucDireccion: "string",
    sucEmpId: 0,
    sucGerente: "string",
    sucId: 0,
    sucNombre: "string",
    sucNombreAbreviado: "string",
  };

  const [appUsuario, setAppUsuario] = useState(appUsuarioEmpty);
  //const [appUsuarios, setAppUsuarios] = useState([]);
  //const [appSexos, setAppSexos] = useState([]);
  const [appSucursales, setAppSucursales] = useState([]);
  const [cambioSucursal, setCambioSucursal] = useState(false);
  // const [appSexo, setAppSexo] = useState(appSexoEmpty);
  const [appSucursal, setAppSucursal] = useState(appSucursalEmpty);
  // const [layout, setLayout] = useState("grid");

  //State para validar los campos
  // const [submitted, setSubmitted] = useState(false);

  const [cambiarPassword, setCambiarPassword] = useState(false);
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
    oldPassword: "",
  });

  useEffect(() => {


      setAppUsuario(auth.user?.usr);


    // const appSexoService = new AppSexoService();
    // appSexoService.findAll().then((res) => {
    //   console.log("AppSexo", res);
    //   setAppSexos(res);
    // });

    const appSucursalService = new AppSucursalService();
    appSucursalService.findAll().then((res) => {
      console.log("AppSucursal", res);
      setAppSucursales(res);
    });
    // appUsuarioService.findAll().then((res) => {
    //   console.log("Appusuario", res);
    //   setAppUsuarios(res);
    // });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUpdatePassword = () => {
    // setSubmitted(true);
    let _appUsuario = { ...appUsuario };
    _appUsuario[`usuPassword`] = password.password;
    if (password.password !== "" && password.confirmPassword !== "") {
      if (password.password === password.confirmPassword) {
        const appUsuarioService = new AppUsuarioService();
        appUsuarioService.save(_appUsuario).then((response) => {
          setAppUsuario(response);
          setPassword({
            password: "",
            confirmPassword: "",
          });
       //   setSubmitted(false);
          setCambiarPassword(false);
          toast.current.show({
            severity: "success",
            summary: "Contraseña actualizada",
            detail: "La contraseña se actualizó correctamente",
            life: 3000,
          });
        });
      } else {
        messages.current.show({
          severity: "warn",
          detail: "Las contraseñas no coinciden",
          life: 3000,
        });
      }
    } else {
      messages.current.show({
        severity: "warn",
        detail: "Debe ingresar la contraseña",
        life: 3000,
      });
    }
    // else {
    //   const appUsuarioService = new AppUsuarioService()
    //   appUsuarioService.save(_appUsuario).then((response) => {
    //     toast.current.show({
    //       severity: 'success',
    //       summary: 'Contraseña actualizada',
    //       detail: 'La contraseña se actualizó correctamente',
    //       life: 3000,
    //     })
    //     setAppUsuario(response)
    //     setPassword({
    //       password: '',
    //       confirmPassword: '',
    //       oldPassword: '',
    //     })
    //     setSubmitted(false)
    //     setCambiarPassword(false)
    //   })
    // }
  };

  function onChangeSuc() {
    if (appSucursal.sucId !== 0) {
      let _appUsuario = { ...appUsuario };
      const appUsuarioService = new AppUsuarioService();
      _appUsuario["appSucursal"] = appSucursal;
      _appUsuario["sucId"] = appSucursal.sucId;
      appUsuarioService.save(_appUsuario).then(() => {

        appUsuarioService.getUserById(appUsuario.usuUsuario).then((res)=>{
          var usuarioUpdates ={ usr: res}
          localStorage.setItem("user", JSON.stringify(usuarioUpdates));
          window.location.reload();
        })
        setCambioSucursal(false);
      });
    } else {
      messages.current.show({
        severity: "warn",
        detail: "Debe Seleccionar una sucursal",
        life: 3000,
      });
    }
  }

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Toast ref={toast}></Toast>
      <div className="card p-grid">
        {/* Start Seccion Foto t Contraseña */}
        <div className="p-col-12 p-md-4 p-fluid">
          {/* <div className="card p-fluid"> */}
          <h3 style={{ fontWeight: "bold", textAlign: "center" }}>
            Fotografia y contraseña
          </h3>

          <div
            className="p-field p-col-12"
            style={{
              textAlign: "center",
              borderRadius: "100%",
            }}
          >
            <div class="avatar">
              <div class="avatar__letters">
                {appUsuario?.usuNombre.substring(0, 1)}
                {appUsuario?.usuApellido.substring(0, 1)}
              </div>
            </div>
          </div>

          <Divider align="center">
            <div className="inline-flex align-items-center">
              {/* <i className="pi pi-user mr-2"></i> */}
              <b>Contraseña</b>
            </div>
          </Divider>
          {/* <Chip
            label="Cambiar contraseña"
            icon="pi pi-key"
            className="mr-2 mb-2 custom-chip"
            onClick={() => setCambiarPassword(true)}
          > */}
          <Button
            icon="pi pi-key"
            style={{
              display: cambioSucursal
                ? "none"
                : cambiarPassword
                ? "none"
                : "block",
              background: "#0791e6",
              fontSize: "14px",
            }}
            label="Cambiar contraseña"
            className="p-button-sm p-mb-2"
            onClick={() => setCambiarPassword(true)}
          />
          <Button
            icon="pi pi-briefcase"
            style={{
              display: cambioSucursal
                ? "none"
                : cambiarPassword
                ? "none"
                : "block",
              fontSize: "14px",
            }}
            label="Cambio de sucursal"
            className="p-button-sm p-button-danger"
            onClick={() => setCambioSucursal(true)}
          />
          {/* </Chip> */}

          {/* <div className="p-field">
            <label htmlFor="email1">Email</label>
            <InputText id="email1" type="text" />
          </div>
          <div className="p-field">
            <label htmlFor="age1">Age</label>
            <InputText id="age1" type="text" />
          </div> */}

          <div
            className="card"
            style={{
              background: "#F2F2F2",
              display: cambiarPassword === true ? "block" : "none",
            }}
          >
            <div className="p-grid">
              {/* <div className="p-field p-col-12">
                <label htmlFor="usuPwd">Contraseña Anterior</label>
                <InputText
                  type="password"
                  className="p-inputtext p-component"
                  id="usuPwd"
                  name="usuPwd"
                  value={password.oldPassword}
                  onChange={(e) =>
                    setPassword({ ...password, oldPassword: e.target.value })
                  }
                  required
                />
              </div> */}
              <div className="p-field p-col-12 p-mb-1">
                <label htmlFor="usuPwd">Contraseña</label>
                <InputText
                  type="password"
                  className="p-inputtext-sm"
                  id="usuPwd"
                  name="usuPwd"
                  value={password.password}
                  onChange={(e) =>
                    setPassword({ ...password, password: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-field p-col-12 p-mb-1">
                <label htmlFor="usuPwdConfirm">Confirmar Contraseña</label>
                <InputText
                  type="password"
                  className="p-inputtext-sm"
                  id="usuPwdConfirm"
                  name="usuPwdConfirm"
                  value={password.passwordConfirm}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      confirmPassword: e.target.value,
                    })
                  }
                  required
                />
              </div>
            </div>
            <div className="p-grid">
              <div className="p-field p-col p-mb-1">
                <Button
                  onClick={() => setCambiarPassword(false)}
                  icon="pi pi-times"
                  label="Cancelar"
                  className="p-button-danger p-button-sm"
                ></Button>
              </div>
              <div className="p-field p-col p-mb-1">
                <Button
                  onClick={() => onUpdatePassword()}
                  icon="pi pi-check"
                  label="Guardar"
                  className=" p-button-sm"
                ></Button>
              </div>
            </div>
          </div>

          {/* Empieza el cambio de sucuirsal */}
          <div
            className="card"
            style={{
              background: "#F2F2F2",
              display: cambioSucursal === true ? "block" : "none",
            }}
          >
            <div className="p-grid">
              {/* <div className="p-field p-col-12">
                <label htmlFor="usuPwd">Contraseña Anterior</label>
                <InputText
                  type="password"
                  className="p-inputtext p-component"
                  id="usuPwd"
                  name="usuPwd"
                  value={password.oldPassword}
                  onChange={(e) =>
                    setPassword({ ...password, oldPassword: e.target.value })
                  }
                  required
                />
              </div> */}
              <div className=" p-field p-col-12">
                <label htmlFor="usuPwd">Cambio de Sucursal</label>
                <Dropdown
                  className="p-inputtext-sm"
                  id="camSuc"
                  name="camSuc"
                  options={appSucursales}
                  placeholder="Seleccione una Sucursal"
                  optionLabel="sucNombre"
                  value={appSucursal}
                  onChange={(e) => {
                    setAppSucursal(e.target.value);
                  }}
                  required
                />
              </div>
            </div>
            <div className="p-grid p-fluid">
              <div className=" p-col-6">
                <Button
                  onClick={() => setCambioSucursal(false)}
                  icon="pi pi-times"
                  label="Cancelar"
                  className="mr-2 p-button-danger p-button-sm"
                ></Button>
              </div>
              <div className=" p-col-6">
                <Button
                  onClick={() => onChangeSuc()}
                  icon="pi pi-check"
                  label="Guardar"
                  className="mr-2 p-button-sm"
                ></Button>
              </div>
            </div>
          </div>
          <Messages ref={messages}></Messages>
          {/* </div> */}
        </div>
        {/* End Seccion Foto t Contraseña */}

        {/* Start Seccion Tus Datos */}
        <div
          className="p-col-12 p-md-8 p-fluid p-mt-6"
          style={{ borderLeft: "1px solid #F2F2F2" }}
        >
          <h3 style={{ fontWeight: "bold" }}>Tus Datos</h3>
          <div className="p-grid">
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Nombres
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuNombre"
                type="text"
                value={appUsuario.usuNombre}
                onChange={(e) => {
                  setAppUsuario({ ...appUsuario, usuNombre: e.target.value });
                }}
                readOnly
              />
            </div>
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Apellidos
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuApellido"
                type="text"
                value={appUsuario.usuApellido}
                onChange={(e) => {
                  setAppUsuario({
                    ...appUsuario,
                    usuApellido: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Usuario
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuUsuario"
                type="text"
                value={appUsuario.usuUsuario}
                onChange={(e) => {
                  setAppUsuario({
                    ...appUsuario,
                    usuUsuario: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Email
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuEmail"
                type="text"
                value={appUsuario.usuEmail}
                onChange={(e) => {
                  setAppUsuario({ ...appUsuario, usuEmail: e.target.value });
                }}
                readOnly
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Fecha Cumpleaños
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuFechaNacimiento"
                type="date"
                value={appUsuario.usuFechaNacimiento}
                onChange={(e) => {
                  setAppUsuario({
                    ...appUsuario,
                    usuFechaNacimiento: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Fecha Expiración de Contraseña
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuFechaExpPwd"
                type="date"
                value={appUsuario.usuFechaExpPwd}
                onChange={(e) => {
                  setAppUsuario({
                    ...appUsuario,
                    usuFechaExpPwd: e.target.value,
                  });
                }}
                readOnly
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Sexo
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuSexo"
                value={appUsuario.appSexo?.sexDescripcion}
                //options={appSexos}
                // placeholder="Seleccione un Sexo"
                // optionLabel="sexDescripcion"
                // onChange={(e) => {
                //   setAppUsuario({
                //     ...appUsuario,
                //     appSexo: e.value,
                //     sexId: e.value.sexId,
                //   });
                // }}
                readOnly
              />
            </div>
            <div className="p-field p-md-6">
              <label htmlFor="name2" style={{ fontWeight: "bold" }}>
                Sucursal
              </label>
              <InputText
                style={{ "font-weight": "bold", backgroundColor: "#f5f5f5" }}
                id="usuSucursal"
                value={appUsuario.appSucursal.sucNombre}
                readOnly
                // onChange={(e) => {
                //   setAppUsuario({
                //     ...appUsuario,
                //     appSucursal: e.value,
                //     sucId: e.value.sucId,
                //   });
                // }}
              />
            </div>
            {/* <Button
              icon="pi pi-key"
              style={{
                display: cambiarPassword ? 'none' : 'block',
                background: '#0791e6',
                fontSize: '14px',
              }}
              label="Cambiar contraseña"
              className="p-button-sm p-button-rounded"
              onClick={() => setCambiarPassword(true)}
            /> */}
          </div>

          {/* </div> */}
        </div>
        {/* End Seccion Tus Datos */}
      </div>
    </div>
  );
};
