import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppAhoLibretaService {

  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url: api.baseUrl() + `/appaholibreta/all`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {

      const response = await axios(config);
      console.log(response)
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/appaholibreta`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }


}
