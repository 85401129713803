import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppAuthorityService {
  async save(data) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/appauthority/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async delete(authId) {
    try {
      let config = {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(data),
      };

      let res = await fetch(
        api.baseUrl() + `/appauthority/delete/${authId}`,
        config
      );
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByRolIdNotAssign(rolId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/appauthority/notassign/${rolId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByName(name, pageNumber) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/appauthority/page/${name || 0}?page=${pageNumber || 0}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );

      return response;
    } catch (err) {
      addressingError(err);
    }
  }
}
