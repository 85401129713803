import AuthProvider from "./auth/AuthProvider";
import AppHash from "./pages/AppHash";

export default function MyCoop(props) {
  return (
    <AuthProvider>
      <AppHash />
    </AuthProvider>
  );
}
