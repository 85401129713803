import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
//funciones
import onChajeObjN from "../../../utilities/onChajeObjN";
import onChangeObjDropDawn from "../../../utilities/onChangeObjDropDawn";
//servicios
import AppRegionService from "../../../service/AppServices/AppRegionService";
import AppPaisService from "../../../service/AppServices/AppPaisService";
import AppDepartamentoService from "../../../service/AppServices/AppDepartamentoService";

export const NuevoDepartamento = ({
  appDepartamento,
  setAppDepartamento,
  setNuevoDepartamento,
  appDepartamentoEmpty,
  estado,
  setEstado,
  toast,
}) => {
  const message = useRef();
  const [regiones, setRegiones] = useState([]);
  const [paises, setPaises] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  useEffect(() => {
    const appRegionesService = new AppRegionService();
    appRegionesService.findAll().then((res) => {
      setRegiones(res);
    });

    const appPaisService = new AppPaisService();

    appPaisService.findAll().then((res) => {
      setPaises(res);
    });
  }, []);

  const save = () => {
    if (
      appDepartamento?.depNombre &&
      appDepartamento?.depCodigo !== "" &&
      appDepartamento?.depNombre &&
      appDepartamento?.depCodigo &&
      appDepartamento?.regId &&
      appDepartamento?.paiId !== "0"
    ) {
      const appDepartamentoService = new AppDepartamentoService();
      appDepartamentoService.save(appDepartamento).then((res) => {
        console.log(res);
        setAppDepartamento(appDepartamentoEmpty);
        setNuevoDepartamento(false);
        setEstado(!estado);
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: `${
            appDepartamento.depId === 0
              ? "Departamento creado"
              : "Departamento actualizado"
          }`,
          life: 4000,
        });
      });
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => {
        setSubmitted(false);
      }, 4000);
    }
  };

  return (
    <>
      <div className="card">
        <div className="p-fluid p-grid ">
          <div className="p-col">
            <label>Nombre</label>
            <InputText
              value={appDepartamento?.depNombre}
              onChange={(e) =>
                onChajeObjN(e, "depNombre", appDepartamento, setAppDepartamento)
              }
              className={{
                "p-invalid": submitted && !appDepartamento?.depNombre,
              }}
            />
            {submitted && !appDepartamento?.depNombre && (
              <small className="p-invalid">Nombre requerido.</small>
            )}
          </div>
          <div className="p-col">
            <label>Codigo del departamento</label>
            <InputText
              value={appDepartamento?.depCodigo}
              onChange={(e) =>
                onChajeObjN(e, "depCodigo", appDepartamento, setAppDepartamento)
              }
              className={{
                "p-invalid": submitted && !appDepartamento?.depCodigo,
              }}
            />
            {submitted && !appDepartamento?.depCodigo && (
              <small className="p-invalid">Codigo requerido.</small>
            )}
          </div>
        </div>
        <div className="p-fluid p-grid ">
          <div className="p-col">
            <label>Region</label>
            <Dropdown
              value={appDepartamento?.appRegion}
              options={regiones}
              optionLabel="regNombre"
              placeholder="Seleccione..."
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appRegion",
                  e.target.value.regId,
                  "regId",
                  appDepartamento,
                  setAppDepartamento
                )
              }
              className={{
                "p-invalid": submitted && !appDepartamento?.depCodigo,
              }}
            />
            {submitted && !appDepartamento?.depCodigo && (
              <small className="p-invalid">Seleccione una opcion.</small>
            )}
          </div>
          <div className="p-col">
            <label>Pais</label>
            <Dropdown
              value={appDepartamento?.appPais}
              options={paises}
              optionLabel="paiNombre"
              placeholder="Seleccione..."
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appPais",
                  e.target.value.paiId,
                  "paiId",
                  appDepartamento,
                  setAppDepartamento
                )
              }
              className={{ "p-invalid": submitted && !appDepartamento?.paiId }}
            />
            {submitted && !appDepartamento?.paiId && (
              <small className="p-invalid">Seleccione una opcion.</small>
            )}
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "100px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => setNuevoDepartamento(false)}
        />
        <Button
          style={{ width: "100px" }}
          className=" p-button-info p-mr-2 "
          icon="pi pi-save"
          label="Guardar"
          onClick={() => save()}
        />
      </div>
    </>
  );
};
