import React, { useState, useEffect } from 'react'
import { Fieldset } from 'primereact/fieldset'

import AppSeccionCampoAdicionalService from '../../service/AppServices/AppSeccionCampoAdicionalService'

import CliAsociadoCamposDefinidosComponenet from '../ClientesPages/CliAsociadoCamposDefinidosComponenet'

const CliCampoAdicionalFieldset = (props) => {
  // let appSeccionCampoAdicionalEmpty = {
  //   secDescripcion: '',
  //   secEstado: '',
  //   secId: 0,
  //   secNombre: '',
  // }
  const [appSeccionCampoAdicional, setAppSeccionCampoAdicional] = useState([])

  //Obtebnemos las secciones de los campos definidos por el usuario
  useEffect(() => {
    const appSeccionCampoAdicionalService = new AppSeccionCampoAdicionalService()
    appSeccionCampoAdicionalService.findAll().then((data) => {
      setAppSeccionCampoAdicional(data)
      console.log(data)
    })
  }, [])


  return (
    <div>
      {appSeccionCampoAdicional.map((data) => {
     
        return (
          <div
            key ={data?.secId }
            >
            <Fieldset 
        
            legend={data?.secNombre} toggleable collapsed={true}>
              <div className="card p-fluid" style={{border: "1px solid #BDBDBD"}}>
                <CliAsociadoCamposDefinidosComponenet
               
                  asociado={props?.asociado}
                  seccion={data?.secId}
                />
              </div>
            </Fieldset>
            <br></br>
          </div>
        )
      })}
    </div>
  )
}

export default CliCampoAdicionalFieldset
