import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//servicios
import AppPreDeduccionAsignadoService from "../../../../service/AppServices/AppPreDeduccionAsignadoService";
import { NuevaAsignacionDeduccion } from "./NuevaAsignacionDeduccion";
//componentes
import { Pagination } from "../../../../components/Pagination";

//funciones

export const SubProductoDeduccion = ({ subProducto }) => {
  const toast = useRef();
  const message = useRef();
  let deduccionEmpty = {
    appPreDeduccion: {
      preDedDescripcion: "",
      preDedId: 0,
    },
    preDedAsiAcreditar: true,
    preDedAsiDedId: 0,
    preDedAsiId: 0,
    preDedAsiPreSubId: subProducto.preSubId,
    preDedAsiSubAhoId: null,
    preDedAsiSumaResta: 0,
    preDedAsiTipo: 0,
    preDedAsiValor: 0,
  };

  const [deduccion, setDeduccion] = useState(deduccionEmpty);
  const [deducciones, setDeducciones] = useState([]);
  const [nuevaDeduccion, setNuevaDeduccion] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [estado, setEstado] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  useEffect(() => {
    const deduccionesSerice = new AppPreDeduccionAsignadoService();
    deduccionesSerice
      .findByPage(pageNumber, subProducto.preSubId)
      .then((response) => {
        setDeducciones(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, estado]);

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }

  function afectar(rowData) {
    return <>{rowData.preDedAsiSumaResta === 1 ? "SUMA" : "RESTA"}</>;
  }

  function tipoDeduccion(rowData) {
    return <>{rowData.preDedAsiTipo === 1 ? "LIBRE" : "PORCENTAJE"}</>;
  }
  function porcentaje(rowData) {
    return (
      <>
        {rowData.preDedAsiTipo === 1
          ? "NO APLICA"
          : rowData.preDedAsiValor + "%"}
      </>
    );
  }

  function acreditarAho(rowData) {
    return (
      <>
        {rowData.preDedAsiAcreditar === true
          ? rowData?.appAhoSubProducto.subAhoNombre
          : "NO APLICA"}
      </>
    );
  }
  const acciones = (rowData) => {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-eye"
          tooltip="Editar"
          onClick={() => {setDeduccion(rowData); setNuevaDeduccion(true)}}
        />
        <Button
          className="p-button-rounded p-button-danger  mr-2 p-mr-2"
          icon="pi pi-trash"
          onClick={() => {setDeduccion(rowData); setEliminar(true)}}
        />
      </>
    );
  };

  const renderTable = (
    <>
      <DataTable
        emptyMessage="No se encontro ningun dato"
        value={deducciones}
        footer={renderPaginacion}
      >
        <Column header="Deduccion" field="appPreDeduccion.preDedDescripcion" />
        <Column header="Forma de afectar" body={afectar} />
        <Column header="Tipo de deduccion" body={tipoDeduccion} />
        <Column header="Porcenta" body={porcentaje} />
        <Column header="Cuenta a creditar" body={acreditarAho} />
        <Column header="Acciones" body={acciones} />
      </DataTable>
    </>
  );

  const left = (
    <>
      <Button
        className="p-button-success p-mr-2 p-button-sm"
        icon="pi pi-plus"
        label="Nuevo"
        onClick={() => {setNuevaDeduccion(true) ;setDeduccion(deduccionEmpty)}}
      />
    </>
  );
  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-mr-2 "
          onClick={() => setNuevaDeduccion(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save() {
    let _deduccion = { ...deduccion };
    if (
      deduccion.preDedAsiAcreditar === true &&
      deduccion.preDedAsiDedId !== 0 &&
      deduccion.preDedAsiTipo === 2 &&
      deduccion.preDedAsiValor > 0 &&
      deduccion.preDedAsiSubAhoId !== null &&
      deduccion.preDedAsiSumaResta !== 0
    ) {
      onSave(_deduccion);
    } else if (
      deduccion.preDedAsiAcreditar === true &&
      deduccion.preDedAsiDedId !== 0 &&
      deduccion.preDedAsiTipo === 1 &&
      deduccion.preDedAsiSubAhoId !== null &&
      deduccion.preDedAsiSumaResta !== 0
    ) {
      _deduccion["preDedAsiValor"] = 0;
      onSave(_deduccion);
    } else if (
      deduccion.preDedAsiAcreditar === false &&
      deduccion.preDedAsiDedId !== 0 &&
      deduccion.preDedAsiTipo === 2 &&
      deduccion.preDedAsiValor > 0 &&
      deduccion.preDedAsiSumaResta !== 0
    ) {
      _deduccion["preDedAsiSubAhoId"] = null;
      _deduccion["appAhoSubProducto"] = null;
      onSave(_deduccion);
    } else if (
      deduccion.preDedAsiAcreditar === false &&
      deduccion.preDedAsiDedId !== 0 &&
      deduccion.preDedAsiTipo === 1 &&
      deduccion.preDedAsiSumaResta !== 0
    ) {
      _deduccion["preDedAsiValor"] = 0;
      _deduccion["preDedAsiSubAhoId"] = null;
      _deduccion["appAhoSubProducto"] = null;
      onSave(_deduccion);
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }

  function onSave(_deduccion) {
    const deduccionesService = new AppPreDeduccionAsignadoService();
    deduccionesService.save(_deduccion).then(() => {
      setEstado(!estado);
      setNuevaDeduccion(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: `Deduccion creada con exito`,
        life: 4000,
      });
    });
  }

  const deliminarDeduccion = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text p-button-danger "
        onClick={() => setEliminar(false)}
      />
      <Button label="Confirmar" icon="pi pi-check" onClick={borrar} />
    </>
  );

  function borrar() {

    const deduccionesSerice = new AppPreDeduccionAsignadoService();
    deduccionesSerice.delete(deduccion.preDedAsiId).then((res) => {
      setEstado(!estado);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea Realizada con exito",
        detail: "Deduccion eliminada con exito",
        life: 4000,
      });
    });
  }
  return (
    <>
      <div>{left}</div>
      {renderTable}

      <Dialog
        visible={nuevaDeduccion}
        header="Asigar Deduccion"
        onHide={() => setNuevaDeduccion(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevaAsignacionDeduccion
          deduccion={deduccion}
          setDeduccion={setDeduccion}
          submitted={submitted}
        />
        <Messages ref={message} />
      </Dialog>
      <Dialog
        visible={eliminar}
        style={{ width: "450px" }}
        header="Mensaje del sistema"
        modal
        footer={deliminarDeduccion}
        onHide={() => setEliminar(false)}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar esta deduccion .</span>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </>
  );
};
