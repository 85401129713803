import { Accordion, AccordionTab } from "primereact/accordion";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { Toolbar } from "primereact/toolbar";
import { Calendar } from "primereact/calendar";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import AppDisponibilidadGrupoService from "../../../service/AppServices/AppDisponibilidadGrupoService";
import AppTrxNaturalezaService from "../../../service/AppServices/AppTrxNaturalezaService";
import ConControlDisponibilidadService from "../../../service/ConContabilidad/ConControlDisponibilidadService";
import ConTransaccionLiquidezService from "../../../service/ConContabilidad/ConTransaccionLiquidezService";
import { Proveniencias } from "./Proveciencias";
import formatDate from "../../../utilities/formatDateTable";

export const Liquidez = () => {
  //Obtenemos la sucursal del usuario en lo LocalStorage
  const token = localStorage.getItem("user");
  var sucId = JSON.parse(token).usr.appSucursal.sucId;

  //Uses Ref
  const toast = useRef(null);

  //structures state
  // const appDisponibilidadGrupo = [
  //   {
  //     gruDiponibilidadNombre: "",
  //     gruDisponibilidadId: 0,
  //   },
  // ];

  const appTrxNaturalezaEmpty = {
    natTransaccionAccion: "",
    natTransaccionId: 0,
    natTransacionNombre: "",
  };

  const concontroldisponibilidadEmpty = {
    appDisponibilidadDetalle: {
      appDisponibilidadProveniencia: {
        disId: 0,
        disNombre: "",
        gruDisponibilidadId: 0,
      },
      detDisAceptaTrx: true,
      detDisId: 0,
      detDisNombre: "",
      disId: 0,
    },
    detDisId: 0,
    disFecha: "",
    disId: 0,
    disMonto: 0,
    disStatus: 0,
    disTasaInversion: 0,
    disVencimientoInversion: "",
    sucId: 0,
  };

  const conTransaccionLiquidezEmpty = {
    conDisId: 0,
    conTrxDescripcion: "",
    conTrxFecha: "",
    conTrxHora: "",
    conTrxId: 0,
    conTrxMonto: 0,
    natTransaccionId: 0,
    usuUsuario: "",
  };

  //State
  const [appDisponibilidadGrupos, setAppDisponibilidadGrupos] = useState([]);
  const [apptrxnaturalezas, setApptrxnaturalezas] = useState(
    appTrxNaturalezaEmpty
  );
  const [apptrxnaturaleza, setApptrxnaturaleza] = useState(
    appTrxNaturalezaEmpty
  );
  const [concontroldisponibilidads, setConcontroldisponibilidads] = useState(
    concontroldisponibilidadEmpty
  );
  const [concontroldisponibilidad, setConcontroldisponibilidad] = useState(
    concontroldisponibilidadEmpty
  );
  const [conTransaccionLiquidez, setConTransaccionLiquidez] = useState(
    conTransaccionLiquidezEmpty
  );

  let filtroEmpty = {
    fecha: "",
  };

  const [submitted, setSubmitted] = useState(false);

  //State Dialog
  //const [position, setPosition] = useState(0);
  const [dialogTransaccion, setDialogTransaccion] = useState(false);
  const [filtro, setFiltro] = useState(filtroEmpty);
  //Uses Effect
  useEffect(() => {
    const conControlDisponibilidadService =
      new ConControlDisponibilidadService();
    if (filtro.fecha === "") {
      let _filtro = { ...filtro };
      const event = new Date();
      var mm = event.getMonth() + 1;
      var dd = event.getDate();
      var yy = event.getFullYear();
      if (mm < 10) {
        mm = "0" + mm;
      }
      if (dd < 10) {
        dd = "0" + dd;
      }
      var myDateString = yy + "-" + mm + "-" + dd;
      _filtro[`fecha`] = myDateString;

      setFiltro(_filtro);
      conControlDisponibilidadService
        .trxByDateAndBranch(_filtro.fecha, sucId)
        .then((response) => {
          // console.log('DETALLES', response)
          setConcontroldisponibilidads(response);
        });
    } else {
      conControlDisponibilidadService
        .trxByDateAndBranch(filtro.fecha, sucId)
        .then((response) => {
          // console.log('DETALLES', response)
          setConcontroldisponibilidads(response);
        });
    }

    // alert(new Date().toISOString())
    const appDisponibilidadGrupoService = new AppDisponibilidadGrupoService();
    appDisponibilidadGrupoService.findAll().then((response) => {
      setAppDisponibilidadGrupos(response);
    });

    const appTrxNaturalezaService = new AppTrxNaturalezaService();
    appTrxNaturalezaService.findAll().then((response) => {
      // console.log(response)
      setApptrxnaturalezas(response);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro.fecha, conTransaccionLiquidez]);

  //Methods Dialog
  const dialogFuncMap = {
    dialogTransaccion: setDialogTransaccion,
  };

  const onClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    // if (position) {
    //   setPosition(position);
    // }
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setConTransaccionLiquidez(conTransaccionLiquidezEmpty);
    setConcontroldisponibilidad(concontroldisponibilidadEmpty);
    setApptrxnaturaleza(appTrxNaturalezaEmpty);
  };

  const renderCampos = () => (
    <Accordion>
      {appDisponibilidadGrupos.map((item) => (
        <AccordionTab
          header={item.gruDiponibilidadNombre}
          key={item.gruDisponibilidadId}
        >
          <Proveniencias
            gruDisponibilidadId={item.gruDisponibilidadId}
            gruDisponibilidad={item}
            disFecha={filtro.fecha}
            sucId={sucId}
            key={item.gruDisponibilidadId}
            conTransaccionLiquidez={conTransaccionLiquidez}
          ></Proveniencias>
        </AccordionTab>
      ))}
    </Accordion>
  );

  const reportsucursalanddate = async (disFecha, sucId) => {
    const conControlDisponibilidadService =
      new ConControlDisponibilidadService();

    var reporte = await conControlDisponibilidadService.reportsucursalanddate(
      disFecha,
      sucId
    );

    toast.current.show({
      severity: "info",
      summary: "¡Información!",
      detail: "Generando reporte de liquidez por favor espere...",
      life: 4000,
    });

    var file = new Blob([reporte], { type: "application/pdf" });
    var fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _conTransaccionLiquidez = { ...conTransaccionLiquidez };
    _conTransaccionLiquidez[`${name}`] = val;
    setConTransaccionLiquidez(_conTransaccionLiquidez);
    // console.log(_conTransaccionLiquidez)
  };

  const onSave = () => {
    setSubmitted(true);

    if (
      concontroldisponibilidad.disId &&
      apptrxnaturaleza.natTransaccionId &&
      conTransaccionLiquidez.conTrxDescripcion !== "" &&
      conTransaccionLiquidez.conTrxMonto !== ""
    ) {
      // console.log('concontroldisponibilidad', concontroldisponibilidad)
      // console.log('apptrxnaturaleza', apptrxnaturaleza)
      const conTransaccionLiquidezService = new ConTransaccionLiquidezService();

      var hoy = new Date();
      var hora =
        hoy.getHours() + ":" + hoy.getMinutes() + ":" + hoy.getSeconds();

      let _conTransaccionLiquidez = { ...conTransaccionLiquidez };
      _conTransaccionLiquidez[`conDisId`] = concontroldisponibilidad.disId;
      _conTransaccionLiquidez[`conTrxFecha`] = filtro.fecha;
      _conTransaccionLiquidez[`conTrxHora`] = hora;
      _conTransaccionLiquidez[`natTransaccionId`] =
        apptrxnaturaleza.natTransaccionId;
      _conTransaccionLiquidez[`usuUsuario`] = JSON.parse(token).usr.usuUsuario;
      // console.log(_conTransaccionLiquidez)
      conTransaccionLiquidezService
        .save(_conTransaccionLiquidez)
        .then((response) => {
          // console.log(response)
          toast.current.show({
            severity: "success",
            summary: "¡Información!",
            detail: "Transacción guardada correctamente",
            life: 4000,
          });

          //actualizamos los datos de cada uno de los acordiones
          const appDisponibilidadGrupoService =
            new AppDisponibilidadGrupoService();
          appDisponibilidadGrupoService.findAll().then((response) => {
            setAppDisponibilidadGrupos(response);
          });
        });
      //recargamos la pagina
      // window.location.reload();
      //resetiemos todos los states
      setConTransaccionLiquidez(conTransaccionLiquidezEmpty);
      setConcontroldisponibilidad(concontroldisponibilidadEmpty);
      setApptrxnaturaleza(appTrxNaturalezaEmpty);
      setSubmitted(false);
    }
  };

  const toolbarLeftTemplate = () => {
    return (
      <>
        <Button
          label="Agregar"
          icon="pi pi-plus"
          style={{ marginRight: ".5em" }}
        />
        <Button
          icon="pi pi-dollar"
          label="Nueva Transacción"
          className="p-button-success"
          style={{ marginRight: ".5em" }}
          onClick={() => onClick("dialogTransaccion", 0)}
        />

        <Button
          icon="pi pi-print"
          label="Imprimir"
          className="p-button-warning"
          onClick={() => reportsucursalanddate(filtro.fecha, sucId)}
        />
      </>
    );
  };

  const onChangeFiltroDate = (value) => {
    console.log("primero");
    let _filtro = { ...filtro };
    const event = new Date(value);
    var mm = event.getMonth() + 1;
    var dd = event.getDate();
    var yy = event.getFullYear();
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (dd < 10) {
      dd = "0" + dd;
    }
    var myDateString = yy + "-" + mm + "-" + dd;
    _filtro[`fecha`] = myDateString;

    setFiltro(_filtro);
  };

  const toolbarRightTemplate = (
    <>
      <label style={{ marginRight: ".5em" }}>Fecha liquidez</label>
      <Calendar
        readOnly
        id="basic"
        maxDate={new Date()}
        onChange={(e) => onChangeFiltroDate(e.value)}
        dateFormat="dd/mm/yy"
        showIcon
        placeholder={formatDate(filtro.fecha)}
      />
      {/* <InputText
        id="conDisFecha"
        type="date"
        value={disFecha}
        onChange={(e) => {
          setDisFecha(e.target.value)
        }}
      /> */}
    </>
  );

  const accept = () => {
    // toast.current.show({ severity: 'info', summary: 'Confirmado', detail: 'Transacción realizada con éxito!', life: 3000 });

    setSubmitted(true);
    if (
      concontroldisponibilidad.disId &&
      apptrxnaturaleza.conTrxMonto !== 0 &&
      apptrxnaturaleza.natTransaccionId &&
      conTransaccionLiquidez.conTrxDescripcion !== "" &&
      conTransaccionLiquidez.conTrxMonto !== ""
    ) {
      onSave();
      onHide("dialogTransaccion", 0);
    } else {
      toast.current.show({
        severity: "error",
        summary: "¡Error!",
        detail: "Por favor complete todos los campos",
        life: 4000,
      });
    }
  };

  const reject = () => {
    toast.current.show({
      severity: "warn",
      summary: "Precaución",
      detail: "Transacción cancelada",
      life: 3000,
    });
  };

  const confirm1 = () => {
    confirmDialog({
      message: "¿Desea confirmar la Transacción?",
      header: "Confirmación",
      icon: "pi pi-exclamation-triangle",
      acceptLabel: "ACEPTAR",
      rejectLabel: "CANELAR",
      accept,
      reject,
    });
  };

  const footerTemplate = () => (
    <>
      <Button
        onClick={() => onHide("dialogTransaccion")}
        icon="pi pi-times"
        label="Cancelar"
        className="mr-2 p-button-danger"
      ></Button>
      <Button
        onClick={confirm1}
        icon="pi pi-check"
        label="Confirmar"
        className="mr-2"
      ></Button>
    </>
  );

  return (
    <div className="p-grid table-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          {/* <h5>Toolbar</h5> */}
          <Toolbar
            left={toolbarLeftTemplate}
            right={toolbarRightTemplate}
          ></Toolbar>
        </div>
      </div>
      <div className="p-col-12">
        <div className="card p-fluid">
          <h5>CONTROL DE DISPONIBILIDAD</h5>
          {renderCampos()}
        </div>
      </div>
      <Dialog
        visible={dialogTransaccion}
        header="Nueva Transacción"
        onHide={() => onHide("dialogTransaccion")}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "50vw" }}
        footer={footerTemplate}
      >
        <ConfirmDialog />

        <div className="p-col-12 p-md-12">
          <div className=" p-fluid">
            <h5>Datos de la Transacción</h5>
            <div className="p-field">
              <label htmlFor="natTransaccionId">Transacción</label>
              <Dropdown
                id="natTransaccionId"
                value={apptrxnaturaleza}
                placeholder="Seleccione una Transacción"
                options={apptrxnaturalezas}
                onChange={(e) => {
                  // console.log(e.value)
                  setApptrxnaturaleza(e.value);
                }}
                showClear={true}
                optionLabel="natTransacionNombre"
                // optionValue="natTransaccionId"
                className={classNames({
                  "p-invalid": submitted && !apptrxnaturaleza.natTransaccionId,
                })}
              />
              {submitted && !apptrxnaturaleza.natTransaccionId && (
                <small className="p-invalid">
                  Tipo transacción es requirida.
                </small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="natTransaccionId">Cuenta</label>
              <Dropdown
                id="natTransaccionId"
                value={concontroldisponibilidad}
                placeholder="Seleccione el detalle de la Transacción"
                options={concontroldisponibilidads}
                onChange={(e) => {
                  // console.log(e.value)
                  setConcontroldisponibilidad(e.value);
                }}
                showClear={true}
                optionLabel="appDisponibilidadDetalle.detDisNombre"
                // optionValue="appDisponibilidadDetalle"
                className={classNames({
                  "p-invalid": submitted && !concontroldisponibilidad.disId,
                })}
              />
              {submitted && !concontroldisponibilidad.disId && (
                <small className="p-invalid">Cuenta es requerida.</small>
              )}
            </div>
            <div className="p-formgrid p-grid">
              <div className="p-field p-col-6">
                <label htmlFor="name2">Proveciencia</label>
                <InputText
                  id="name2"
                  value={
                    concontroldisponibilidad?.appDisponibilidadDetalle
                      ?.appDisponibilidadProveniencia?.disNombre
                  }
                  type="text"
                  disabled
                />
              </div>
              <div className="p-field p-col-3">
                <label htmlFor="email2">Fecha Liquidez</label>
                <InputText
                  id="name2"
                  value={concontroldisponibilidad?.disFecha}
                  type="date"
                  disabled
                />
              </div>
              <div className="p-field p-col-3">
                <label htmlFor="email2">Monto actual</label>
                <InputNumber
                  id="name2"
                  // style={{ textAlign: 'right' }}
                  value={concontroldisponibilidad?.disMonto}
                  // type="number"
                  disabled
                />
              </div>
            </div>
            <div className="p-field">
              <label htmlFor="name1">Monto</label>
              <InputNumber
                id="name1"
                // type="number"
                placeholder="0.00"
                step={0.01}
                // onChange={(e) => {
                //   onInputChange(e, 'conTrxMonto')
                // }}
                mode="decimal"
                minFractionDigits={2}
                locale="gt-GT"
                currency="GTQ"
                value={conTransaccionLiquidez?.conTrxMonto}
                onChange={(e) =>
                  setConTransaccionLiquidez({
                    ...conTransaccionLiquidez,
                    conTrxMonto: String(e.value).replace(/,/g, ""),
                  })
                }
                className={classNames({
                  "p-invalid": submitted && (!conTransaccionLiquidez.conTrxMonto || conTransaccionLiquidez.conTrxMonto === "0"),
                })}
                
              />
              {submitted && !conTransaccionLiquidez.conTrxMonto && (
                <small className="p-invalid">Monto es requerido.</small>
              )}
              {submitted && conTransaccionLiquidez.conTrxMonto === "0" && (
                <>
                  <br></br>
                  <small className="p-invalid">
                    Monto debe ser menor y mayor a 0.
                  </small>
                </>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="age1">Referencia</label>
              <InputTextarea
                id="age1"
                type="text"
                maxLength={150}
                rows={5}
                onChange={(e) => {
                  onInputChange(e, "conTrxDescripcion");
                }}
                className={classNames({
                  "p-invalid":
                    submitted && !conTransaccionLiquidez.conTrxDescripcion,
                })}
              />
              {submitted && !conTransaccionLiquidez.conTrxDescripcion && (
                <small className="p-invalid">Referencia es requerida.</small>
              )}
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};
