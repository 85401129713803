import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useState, useEffect } from "react";

//Servicios
import AppAhoCausaBloqueoService from "../../../../service/AppServices/AppAhoCausaBloqueoService";
import AhoMovimientoDiarioService from "../../../../service/AhoCuentaService/AhoMovimientoDiarioService";
import AhoCuentaService from "../../../../service/AhoCuentaService/AhoCuentaService";

export const BloqueoDesbloque = ({ ahorro, setAhorro, toast }) => {
  const [causasBloqueo, setCausasBloqueo] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [causa, setCausa] = useState({ causaApp: "" });
  useEffect(() => {
    const casaBloqueoService = new AppAhoCausaBloqueoService();
    casaBloqueoService.findAll().then((res) => {
      setCausasBloqueo(res);
    });
  }, []);

  function estado() {
    switch (ahorro?.ahoEstado) {
      case 1:
        return <label>Vigente</label>;
      case 2:
        return <label>Cancelado</label>;
      case 3:
        return <label>Bloqueado</label>;
      case 4:
        return <label>Vencido</label>;
      default:
        return <label>Sin estado</label>;
    }
  }
  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => setConfirm(false)}
      />
      <Button
        icon="pi pi-check"
        label="Si"
        onClick={() => {
          ahorro?.ahoEstado !== 1 ? desbloqueo() : bloqueo();
        }}
      />
    </>
  );

  function renderForm() {
    return (
      <div className=" p-grid p-mt-4">
        <div className="p-col-12 p-md-6 p-lg-6">
          <div className="card no-gutter widget-overview-box widget-overview-box-1 ">
            <span className="overview-icon">
              <i className="pi pi-info"></i>
            </span>
            <span className="overview-title">Informacion</span>

            <div
              className="p-grid overview-detail p-mr-2 p-ml-2"
              style={{ textAlign: "left" }}
            >
              <div className="p-col-6">
                <div className="overview-subtext">No. Cuenta</div>
                <div className="overview-subtext">Estado</div>
                <div className="overview-subtext">Causa del bloqueo</div>
              </div>
              <div className="p-col-6">
                <div className="overview-subtext">{ahorro?.ahoId}</div>
                <div className="overview-subtext">{estado()}</div>
                <div className="overview-subtext">
                  {ahorro?.appAhoCausaBloqueo === null
                    ? "Sin causa"
                    : ahorro?.appAhoCausaBloqueo.ahoBloDescripcion}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-col-12 p-md-6 p-lg-6">
          <div
            className="card no-gutter widget-overview-box widget-overview-box-3 "
            style={{ height: "100%" }}
          >
            <span className="overview-icon">
              <i className="pi pi-dollar"></i>
            </span>
            <span className="overview-title">Accion</span>
            <div className="p-grid p-mt-3">
              <div className="p-col-12 p-md-7 p-lg-7">
                <label>Causa del Bloqueo</label>
                <Dropdown
                  value={causa?.causaApp}
                  options={causasBloqueo}
                  className="p-inputtext-sm"
                  optionLabel="ahoBloDescripcion"
                  filter
                  filterBy="ahoBloDescripcion"
                  placeholder="Seleccione..."
                  onChange={(e) =>
                    setCausa({
                      ...causa,
                      causaApp: e.value,
                    })
                  }
                  disabled={ahorro?.ahoEstado !== 1}
                />
              </div>
              <div className="p-col-12 p-md-5 p-lg-5">
                <Button
                  className={`p-mt-3 p-button-sm p-button-${
                    ahorro?.ahoEstado !== 1 ? "info" : "danger"
                  }`}
                  icon={`pi  ${
                    ahorro?.ahoEstado !== 1 ? "pi-lock-open" : "pi-lock"
                  }`}
                  label={`${
                    ahorro?.ahoEstado !== 1 ? "Desbloquear" : "Bloquear"
                  }`}
                  onClick={() => setConfirm(true)}

                  disabled={ ahorro?.ahoEstado !== 1 ? false: causa?.causaApp.ahoBloId === 0 ||  causa?.causaApp.ahoBloId === undefined  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function bloqueo() {
    const ahoMovimientoDiario = new AhoMovimientoDiarioService();

    ahoMovimientoDiario
      .bloqueo(ahorro?.ahoId, causa?.causaApp.ahoBloId)
      .then((res) => {
        updateAhorro(res);
      });
  }

  function desbloqueo() {
    const ahoMovimientoDiario = new AhoMovimientoDiarioService();
    ahoMovimientoDiario.desBloqueo(ahorro?.ahoId).then((res) => {
      updateAhorro(res);
    });
  }
  function updateAhorro(res) {
    if (res?.code === 200) {
      toast.current.show({
        severity: "success",
        summary: `${res?.message}`,
        detail: `${res?.description}`,
        life: 4000,
      });
      const ahoService = new AhoCuentaService();
      ahoService.findById(ahorro.ahoId).then((res) => {
        setAhorro(res);
        setCausa({ causaApp: "" });
        sessionStorage.setItem("Ahorro", JSON.stringify(res));
      });
      setConfirm(false);
    } else {
      toast.current.show({
        severity: "error",
        summary: `${res?.message}`,
        detail: `${res?.description}`,
        life: 4000,
      });
    }
  }

  return (
    <>
      <h5>Bloqueado y Desbloqueo de cuenta</h5>
      {renderForm()}
      <Dialog
        header="Mensaje del sistema"
        visible={confirm}
        onHide={() => setConfirm(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>
            Confirme si desea{" "}
            {ahorro?.ahoEstado !== 1 ? "desbloquear" : "bloquear"} la cuenta.
          </span>
        </div>
      </Dialog>
    </>
  );
};
