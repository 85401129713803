import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import classNames from "classnames";
import { Messages } from "primereact/messages";
import { Dialog } from "primereact/dialog";
//serivicos
import AppConfigService from "../../../../service/AppServices/AppConfigService";
import AhoMovimientoDiarioService from "../../../../service/AhoCuentaService/AhoMovimientoDiarioService";
import AhoCuentaService from "../../../../service/AhoCuentaService/AhoCuentaService";
//funciones
import dateNow from "../../../../utilities/dateNow";
import onChajeObjN from "../../../../utilities/onChajeObjN";
import formatMiles from "../../../../utilities/formatMiles";
import selecText from "../../../../utilities/selecText";

export const AjusteSaldo = ({ ahorro, setAhorro, toast }) => {
  const message = useRef();
  const user = JSON.parse(localStorage.getItem("user"));
  const data = JSON.parse(sessionStorage.getItem("fechamodaho"));

  let movDiEmpty = {
    appTransaccion: null,
    movAhoBanco: "N",
    movAhoCajaId: null,
    movAhoCtaBanId: null,
    movAhoDocumento: 0,
    movAhoEstado: "S",
    movAhoFechaSistema: dateNow(),
    movAhoHora: "",
    movAhoId: ahorro?.ahoId,
    movAhoImpAcumulado: "0",
    movAhoImpMes: "0",
    movAhoIntAcumulado: "0",
    movAhoIntMes: "0",
    movAhoInterno: "S",
    movAhoMonto: "0",
    movAhoReferencia: "",
    movAhoUsuIngresa: user?.usr.usuUsuario,
    movDiarioId: "0",
    movFechaTransaccion: data?.modFechaHoy,
    movSucId: user?.usr.sucId,
    movTrxId: 0,
  };
  let saldoEmpty = {
    idSaldo: 0,
  };

  const [movAjuste, setMovAjuste] = useState(movDiEmpty);
  const [saldoAfectura, setSaldoAfectual] = useState(saldoEmpty);
  const [ajuste, setAjuste] = useState([]);
  const ahoAntes =   JSON.parse(sessionStorage.getItem("Ahorro") || { ahoId: "0" });

  const [submitted, setSubmitted] = useState(false);
  const [ahoDes, setAhoDes] = useState({
    ahoSaldoTotal: 0,
    ahoInteresMes: 0,
    ahoInteresAcumulado: 0,
    ahoSaldoLibreta: 0,
  });
  const [confirm, setConfirm] = useState(false);

  useEffect(() => {
    const appConfigService = new AppConfigService();
    appConfigService.findAll().then((res) => {
  
      try {
        var options = [
          {
            id: res[0]?.configAhoAjuDeb,
            nombre: "Debito",
            abre: "D",
          },
          {
            id: res[0]?.configAhoAjuCre,
            nombre: "Credito",
            abre: "C",
          },
        ];
        setAjuste(options);
      } catch (error) {}
    });
  }, []);

  function informacion() {
    return (
      <div className="p-grid p-fluid ">
        <div className="p-col-12 p-md-6">
          <div className="card no-gutter widget-overview-box widget-overview-box-3 ">
            <span className="overview-icon">
              <i className="pi pi-dollar"></i>
            </span>
            <span className="overview-title">Antes del ajuste</span>
            <div className="p-grid overview-detail p-mr-2 p-ml-2">
              <div className="p-col-6">
                <div style={{ textAlign: "left" }}>
                  <div className="overview">Saldo Contable</div>
                  <div className="overview-subtext"> Saldo Libreta</div>
                  <div className="overview-subtext"> Saldo Interes</div>
                </div>
              </div>
              <div className="p-col-6">
                <div style={{ textAlign: "right" }}>
                  <div className="overview">
                    {formatMiles(ahoAntes?.ahoSaldoTotal)}
                  </div>
                  <div className="overview-subtext">
                    {formatMiles(ahoAntes?.ahoSaldoLibreta)}
                  </div>
                  <div className="overview-subtext">
                    {formatMiles(
                      Number(ahoAntes?.ahoInteresAcumulado) +
                        Number(ahoAntes?.ahoInteresMes)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-12 p-md-6 ">
          <div className="card no-gutter widget-overview-box widget-overview-box-4">
            <span className="overview-icon">
              <i className="pi pi-dollar"></i>
            </span>
            <span className="overview-title">Despues del Ajuste</span>
            <div className="p-grid overview-detail p-mr-2 p-ml-2">
              <div className="p-col-6">
                <div style={{ textAlign: "left" }}>
                  <div className="overview">Saldo Contable</div>
                  <div className="overview-subtext"> Saldo Libreta</div>
                  <div className="overview-subtext"> Saldo Interes</div>
                </div>
              </div>
              <div className="p-col-6">
                <div style={{ textAlign: "right" }}>
                  <div className="overview">
                    {formatMiles(ahoDes?.ahoSaldoTotal)}
                  </div>
                  <div className="overview-subtext">
                    {formatMiles(ahoDes?.ahoSaldoLibreta)}
                  </div>
                  <div className="overview-subtext">
                    {formatMiles(
                      Number(ahoDes?.ahoInteresAcumulado) +
                        Number(ahoDes?.ahoInteresMes)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  function onSave() {
    const ahoMovimientoService = new AhoMovimientoDiarioService();
    ahoMovimientoService
      .save(movAjuste, saldoAfectura.idSaldo, movAjuste?.options?.abre)
      .then((res) => {
        setMovAjuste(movDiEmpty);
        if (res?.code === 201) {
          toast.current.show({
            severity: "success",
            summary: `${res?.message}`,
            detail: `${res?.description}`,
            life: 4000,
          });
          const ahoService = new AhoCuentaService();
          ahoService.findById(ahorro.ahoId).then((res) => {
            setAhorro(res);
            setAhoDes(res);
            sessionStorage.setItem("Ahorro", JSON.stringify(res));
          });
        } else {
          toast.current.show({
            severity: "error",
            summary: `${res?.message}`,
            detail: `${res?.description}`,
            life: 4000,
          });
        }
        setSaldoAfectual(saldoEmpty);
      });
  }

  function save() {
    if (
      movAjuste.movTrxId &&
      movAjuste.movAhoMonto &&
      saldoAfectura.idSaldo !== 0 &&
      movAjuste.movAhoReferencia !== ""
    ) {
      setConfirm(true)
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSubmitted(false), 4000);
    }
  }

  function onChangeObjDropDawn(e, name, val1, name2) {
    const val = (e.target && e.target.value) || "";
    let _obj = { ...movAjuste };
    _obj[`${name}`] = val;
    setMovAjuste(_obj);
    _obj[`${name2}`] = val1;
  
    setMovAjuste(_obj);
  }

  function renderForm() {
    return (
      <div className="card">
        <div className=" p-grid">
          <div className="p-col-12 p-md-4">
            <label>Saldo a Afectar</label>
            <Dropdown
              value={saldoAfectura.idSaldo}
              options={[
                { value: 1, label: "Saldo contable" },
                {
                  value: 2,
                  label: "Saldo libreta",
                },
                {
                  value: 3,
                  label: "Saldo interes",
                },
              ]}
              onChange={(e) => {
                setSaldoAfectual({
                  ...saldoAfectura,
                  idSaldo: e.value,
                });
              }}
              placeholder="Seleccione... "
              className={classNames({
                "p-invalid": submitted && !saldoAfectura?.idSaldo,
              })}
            />{" "}
            {submitted && !saldoAfectura?.idSaldo && (
              <small className="p-invalid">Seleccione el saldo a afectar</small>
            )}
          </div>
          <div className="p-col-12 p-md-4">
            <label>Tipo de Ajuste</label>
            <Dropdown
              value={movAjuste?.options}
              options={ajuste}
              onChange={(e) => {
                onChangeObjDropDawn(
                  e,
                  "options",
                  e.target.value.id,
                  "movTrxId"
                );
              }}
              placeholder="Seleccione..."
              optionLabel="nombre"
              className={classNames({
                "p-invalid": submitted && !movAjuste?.movTrxId,
              })}
            />{" "}
            {submitted && !movAjuste?.movTrxId && (
              <small className="p-invalid">Seleccione un tipo de ajuste</small>
            )}
          </div>

          <div className="p-col-12 p-md-4">
            <label>Monto</label>
            <span className="p-input-icon-left">
              <i style={{ fontStyle: "normal" }}>
                <b>Q. </b>{" "}
              </i>
              <InputText
                placeholder="100"
                type={"number"}
                value={movAjuste?.movAhoMonto}
                onChange={(e) =>
                  onChajeObjN(e, "movAhoMonto", movAjuste, setMovAjuste)
                }
                className={classNames({
                  "p-invalid": submitted && !movAjuste?.movAhoMonto,
                })}
                id="movAhoMonto"
                onClick={(e) => selecText(e)}
              />
            </span>
            {submitted && !movAjuste?.movAhoMonto && (
              <small className="p-invalid">Monto requerido</small>
            )}
          </div>
          <div className="p-col-12">
            <label>Referencia</label>
            <InputTextarea
              value={movAjuste?.movAhoReferencia}
              onChange={(e) =>
                onChajeObjN(e, "movAhoReferencia", movAjuste, setMovAjuste)
              }
              rows={3}
              placeholder="Deposito interno por pago ..."
              className={classNames({
                "p-invalid": submitted && !movAjuste?.movAhoReferencia,
              })}
              id="setMovAjuste"
              onClick={(e) => selecText(e)}
            />
            {submitted && !movAjuste?.movAhoReferencia && (
              <small className="p-invalid">
                Ingese una referencia para el movimiento
              </small>
            )}
          </div>
          <div className="p-col-12" style={{ textAlign: "right" }}>
            <Button
              icon="pi pi-check"
              className="p-button-sm"
              style={{ width: "150px" }}
              label="Aceptar"
              onClick={() => save()}
            />
          </div>
        </div>
      </div>
    );
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className="p-button-text p-button-danger "
        icon="pi pi-times"
        label="No"
        onClick={() => setConfirm(false)}
      />
      <Button
        icon="pi pi-check"
        label="Si"
        onClick={() =>{onSave();setConfirm(false)}}
      />
    </>
  );

  return (
    <div>
      {informacion()}
      <Messages ref={message} />
      {renderForm()}

      <Dialog
        header="Mensaje del sistema"
        visible={confirm}
        onHide={() => setConfirm(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "35vw" }}
        footer={deleteProductsDialogFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea realizar la transaccion.</span>
        </div>
      </Dialog>
    </div>
  );
};
