import React from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import useAuth from "../../../../../auth/useAuth";

//services
import AhoCuentaService from "../../../../../service/AhoCuentaService/AhoCuentaService";
import selectText from "../../../../../utilities/selecText";
export const TasaComponent = ({
  setAhorro,
  toast,
  newAhorro,
  setNewAhorro,
  tasaFalse,
  setTasa,
}) => {
  const auth = useAuth();
  const onChangeNew = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newAhorro = { ...newAhorro };
    _newAhorro[`${name}`] = val;
    setNewAhorro(_newAhorro);
  };

  function confirm() {
    let _newAhorro = { ...newAhorro };
    _newAhorro[`usuModifica`] = auth.user?.usr.usuUsuario;
    if (
      _newAhorro.ahoTasaNegociado !== 0 &&
      _newAhorro.ahoTasaNegociado !== "" &&
      _newAhorro.ahoTasaNegociado !== undefined 
     
    ) {
      const ahoService = new AhoCuentaService();
      ahoService.save(_newAhorro).then((response) => {
        if (response?.ahoId !== undefined) {
          setAhorro(response);
          setNewAhorro(response);
          setTasa(false);
          sessionStorage.setItem("Ahorro", JSON.stringify(response));
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: "Tasa actualizada",
            life: 4000,
          });
        } else {
          var obj = JSON.parse(sessionStorage.getItem("Ahorro"));
          setNewAhorro(obj);
          toast.current.show({
            severity: "error",
            summary: "Ocurrió un error",
            detail: "Por favor comuniquese con el equipo de soporte",
            life: 4000,
          });
        }
      });
    } else {
    }
  }

  const deleteProductsDialogFooter = (
    <>
      <Button
        className=" p-button-danger p-mr-2"
        icon="pi pi-times"
        label="Cancelar"
        onClick={tasaFalse}
      />
      <Button icon="pi pi-check" label="Confirmar" onClick={confirm} />
    </>
  );
  return (
    <div>
      <div className="card ">
        <b>
          <label>Numero de cuenta: </label>
        </b>
        <label>{newAhorro?.ahoId}</label>
        <br /> <br />
        <b>
          <label>Nombre de la persona: </label>{" "}
        </b>
        <label>{newAhorro?.ahoNombre}</label> <br /> <br />
        <div className="p-grid p-field">
          <div className="p-col">
            <b>
              <label>Tasa Base: </label>
            </b>
            <label>{newAhorro?.ahoTasaBase}%</label>
          </div>
          <div className="p-col">
            <b>
              <label>Tasa Spread: </label>
            </b>
            <label>{newAhorro?.ahoTasaNegociado}%</label>
          </div>
          <div className="p-col">
            <b>
              <label>Tasa total: </label>{" "}
            </b>
            <label>
              {Number(newAhorro?.ahoTasaNegociado) +
                Number(newAhorro?.ahoTasaBase)}
              %
            </label>
          </div>
        </div>
        <div className="p-grid p-field">
          <div className="p-col">
            <b>
              <label>Nueva Tasa Spread: </label>
            </b>
            <InputText
              className="p-inputtext-sm block mb-2"
              value={newAhorro.ahoTasaNegociado}
              id="intNego"
              type="number"
              onChange={(e) => onChangeNew(e, "ahoTasaNegociado")}
              onClick={(e) => selectText(e)}
            />
          </div>
          <div className="p-col">
            <b>
              <label>Tasa total: </label>{" "}
            </b>
            <label>
              {Number(newAhorro?.ahoTasaNegociado) +
                Number(newAhorro?.ahoTasaBase)}{" "}
              %
            </label>
          </div>
        </div>
      </div>
      <div className="p-grid p-formgrid">
        <div className="p-col"></div>
        <div
          className="p-col "
          style={{ textAlign: "right", alignItems: "right" }}
        >
          {deleteProductsDialogFooter}
        </div>
      </div>
    </div>
  );
};
