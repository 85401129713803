import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import classNames from "classnames";
import { SelectButton } from "primereact/selectbutton";

//servicios
import AppTipoSubProductoService from "../../../../service/AppServices/AppTipoSubProductoService";
import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
import AppAhoTipoCuentaService from "../../../../service/AppServices/AppAhoTipoCuentaService";
import AppTasaService from "../../../../service/AppServices/AppTasaService";
import AppMonedaService from "../../../../service/AppServices/AppMonedaService";

export const NuevoAhoProducto = ({
  setNuevoAhoProducto,
  newProductoHook,
  toast,
  estado,
  setEstado,
  setNewProductoHook,
}) => {
  useEffect(() => {
    if (newProducto?.tasId !== null) {
      let _newProducto = { ...newProducto };
      _newProducto["ahoTipoTasa"] = "U";
      _newProducto["ahoTipoTasaNom"] = {
        id: "U",
        name: "UNICA",
      };
      setNewProducto(_newProducto);
    } else {
      let _newProducto = { ...newProducto };
      _newProducto["ahoTipoTasa"] = "P";
      _newProducto["ahoTipoTasaNom"] = {
        id: "P",
        name: "PLAZO",
      };
      setNewProducto(_newProducto);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const message = useRef();
  const [newProducto, setNewProducto] = useState(newProductoHook);
  const [appAhoTipoProducto, setAppAhoTipoProducto] = useState([]);
  const [appProducto, setAppProducto] = useState([]);
  const [appTasa, setAppTasa] = useState([]);
  const [appMonedas, setAppMonedas] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const appProductoService = new AppTipoProductoService();
    const appAhoTipoProductoServce = new AppAhoTipoCuentaService();
    const appTasaService = new AppTasaService();
    const appMonedaService = new AppMonedaService();
    appProductoService.findAll().then((response) => {
      setAppProducto(response?.data);
    });

    appAhoTipoProductoServce.findAll().then((response) => {

      setAppAhoTipoProducto(response);
    });

    appTasaService.findAllAho().then((response) => {
      setAppTasa(response);
    });

    appMonedaService.findAll().then((response) => {
      setAppMonedas(response);
    });
  }, []);

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _newProducto = { ...newProducto };
    _newProducto[`${name}`] = val;
    setNewProducto(_newProducto);
    _newProducto[`${name2}`] = val1;
    setNewProducto(_newProducto);
  };

  const onChangeNew = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newProducto = { ...newProducto };
    _newProducto[`${name}`] = val.toUpperCase();

    setNewProducto(_newProducto);
  };

  function onSave() {
    const ahoProducto = new AppTipoSubProductoService();

    let _newProducto = { ...newProducto };
    if (newProducto?.subAhoPenalizacion === "N") {
      _newProducto["subAhoTasaPenaliza"] = 0;
    }
    if (newProducto?.subAhoPagaIsr === "N") {
      _newProducto["subAhoTasaIsr"] = 0;
    }
    if (newProducto?.ahoTipoTasa === "P") {
      _newProducto["tasId"] = null;
      _newProducto["appTasa"] = null;
    }
    ahoProducto.save(_newProducto).then((response) => {
      if (newProducto?.ahoTipoTasa !== "P") {
        setNuevoAhoProducto(false);
      }
      setNewProductoHook(response);

      if (newProducto?.ahoProducto === 0) {
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Producto creado",
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Producto actualizado",
          life: 4000,
        });
      }
      setEstado(!estado);
    });
  }

  function save() {
    if (newProducto?.ahoTipoTasa === "P") {
      if (
        newProducto.monId &&
        newProducto.subTipoId &&
        newProducto.proId !== 0 &&
        newProducto.subAhoNombre !== ""
      ) {
        onSave();
      } else {
        alertError();
      }
    } else {
      if (
        newProducto.monId &&
        newProducto.tasId &&
        newProducto.subTipoId &&
        newProducto.proId !== 0 &&
        newProducto.subAhoNombre !== ""
      ) {
        onSave();
      } else {
        alertError();
      }
    }
  }
  function alertError() {
    setSubmitted(true);
    setTimeout(gfg, 4000);
    message.current.show({
      severity: "warn",
      summary: "error",
      content: "Verifique que los campos necesarios no esten vacios",
      life: 4000,
    });
  }

  function gfg() {
    setSubmitted(false);
  }
  return (
    <>
      <div className="card">
        <div className="p-grid">
          <div className="p-fluid   p-col-12 p-lg-6 p-md-6">
            <label>Nombre del Sub Producto de Ahorro</label>

            <InputText
              value={newProducto?.subAhoNombre}
              onChange={(e) => onChangeNew(e, "subAhoNombre")}
              className={classNames({
                "p-invalid": submitted && !newProducto.subAhoNombre,
              })}
              placeholder="NOMBRE DE LA CUENTA..."
            />
            {submitted && !newProducto.subAhoNombre && (
              <small className="p-invalid">Nombre del producto requerido</small>
            )}
          </div>
          <div className="p-fluid  p-col-12 p-lg-6 p-md-6">
            <label>Selecione Tipo de Moneda</label>
            <Dropdown
              value={newProducto?.appMoneda}
              options={appMonedas}
              optionLabel="monNombre"
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appMoneda",
                  e.target.value.monId,
                  "monId"
                );
              }}
              className={classNames({
                "p-invalid": submitted && !newProducto.monId,
              })}
              disabled={newProducto?.subAhoId !== 0}
              placeholder="TIPO DE MONEDAD..."
            />
          </div>
        </div>
        <div className="p-grid">
          <div className="p-fluid  p-col-12 p-lg-6 p-md-6">
            <label>Selecione tipo de cuenta</label>
            <Dropdown
              value={newProducto?.appAhoTipoCuenta}
              options={appAhoTipoProducto}
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appAhoTipoCuenta",
                  e.target.value.subtipoId,
                  "subTipoId"
                );
              }}
              optionLabel="subTipoNombre"
              className={classNames({
                "p-invalid": submitted && !newProducto.subTipoId,
              })}
              disabled={newProducto?.subAhoId !== 0}
              placeholder="TIPO DE CUENTA..."
            />
          </div>
          <div className="p-fluid p-col-12 p-lg-6 p-md-6">
            <label>Selecione Producto</label>
            <Dropdown
              value={newProducto?.appProducto}
              options={appProducto}
              optionLabel="proNombre"
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appProducto",
                  e.target.value.proId,
                  "proId"
                );
              }}
              className={classNames({
                "p-invalid": submitted && !newProducto.proId,
              })}
              disabled={newProducto?.subAhoId !== 0}
              placeholder="PRODUCTO..."
            />
          </div>
        </div>

        <div className="p-grid">
          <div className="p-fluid  p-col-12 p-lg-6 p-md-6">
            <label>Tipo de tasa</label>
            <Dropdown
              value={newProducto?.ahoTipoTasaNom}
              options={[
                {
                  id: "U",
                  name: "UNICA",
                },
                {
                  id: "P",
                  name: "PLAZO",
                },
              ]}
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "ahoTipoTasaNom",
                  e.target.value.id,
                  "ahoTipoTasa"
                );
              }}
              optionLabel="name"
            />
          </div>
          <div className="p-fluid  p-col-12 p-lg-6 p-md-6">
            <label>Selecione el tipo de tasa</label>
            <Dropdown
              value={newProducto?.appTasa}
              options={appTasa}
              onChange={(e) => {
                onInputChangeObject(
                  e,
                  "appTasa",
                  e.target.value.tasId,
                  "tasId"
                );
              }}
              optionLabel="tasNombre"
              disabled={newProducto?.ahoTipoTasa === "P"}
              placeholder="TIPO DE TASAS.."
              className={classNames({
                "p-invalid":
                  submitted &&
                  newProducto?.ahoTipoTasa === "U" &&
                  !newProducto.tasId,
              })}
            />
          </div>
        </div>

        <div
          className="p-grid"
          style={{
            display:
              newProducto.subTipoId === 1
                ? "none"
                : newProducto.subTipoId === 3
                ? "none"
                : "",
          }}
        >
          <div className="p-fluid  p-col">
            <label>Tiene Penalizacion</label>
            <SelectButton
              value={newProducto?.subAhoPenalizacion}
              options={[
                {
                  value: "S",
                  label: "Si",
                },
                {
                  value: "N",
                  label: "No",
                },
              ]}
              //    onChange={(e) => setValue1(e.value)}
              onChange={(e) => onChangeNew(e, "subAhoPenalizacion")}
            />
          </div>
          <div className="p-fluid  p-col">
            <label>Porcentaje de Penalizacion</label>
            <span className="p-input-icon-right">
              <i>%</i>
              <InputText
                value={newProducto?.subAhoTasaPenaliza}
                type="number"
                onChange={(e) => onChangeNew(e, "subAhoTasaPenaliza")}
                disabled={newProducto?.subAhoPenalizacion === "N"}
              />
            </span>
          </div>
        </div>
        <div className="p-grid">
          <div className="p-fluid p-col">
            <label>Paga ISR</label>
            <SelectButton
              value={newProducto?.subAhoPagaIsr}
              options={[
                {
                  value: "S",
                  label: "Si",
                },
                {
                  value: "N",
                  label: "No",
                },
              ]}
              //    onChange={(e) => setValue1(e.value)}
              onChange={(e) => onChangeNew(e, "subAhoPagaIsr")}
            />
          </div>
          <div className=" p-fluid  p-col">
            <label>Porcentaje de ISR</label>
            <span className="p-input-icon-right">
              <i>%</i>
              <InputText
                value={newProducto?.subAhoTasaIsr}
                type="number"
                onChange={(e) => onChangeNew(e, "subAhoTasaIsr")}
                disabled={newProducto?.subAhoPagaIsr === "N"}
              />
            </span>
          </div>
        </div>
      </div>
      <Messages ref={message} />
      <div style={{ textAlign: "right" }}>
        <Button
          style={{ width: "120px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => {
            setNuevoAhoProducto(false);
            toast.current.show({
              severity: "warn",
              summary: "Alerta",
              detail: "Producto no creado/actualizado",
              life: 4000,
            })
          }}
        />
        <Button
          style={{ width: "120px" }}
          icon="pi pi-check"
          label="Guardar"
          onClick={() => save()}
        />
      </div>
    </>
  );
};
