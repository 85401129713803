import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class CliAsociadoService {
  async findByPage(
    pageNumber,
    codigoAsociado,
    primerNombre,
    segundoNombre,
    otroNombre,
    primerApellido,
    segundoApellido,
    numeroIdentificacion,
    estadoAsociado,
    nombreJuridico,
    tipoIdentificacion
  ) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/cliasociado/pagina/${codigoAsociado || 0}/${primerNombre || 0}/${
            segundoNombre || 0
          }/${otroNombre || 0}/${primerApellido || 0}/${segundoApellido || 0}/${
            numeroIdentificacion || 0
          }/${(estadoAsociado === true ? "A" : 0) || 0}/${
            nombreJuridico || 0
          }/${tipoIdentificacion || 0}/?page=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByIdClau(asoId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/cliasociado/summary/${asoId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {

    }
  }

  async findById(asoId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/cliasociado/${asoId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      if(err?.response?.status === 404){
        return(err);
      }else{
        addressingError(err);
      }
    
    }
  }

  async save(data) {

    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      };

      let res = await fetch(api.baseUrl() + "/cliasociado/save", config);
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async findAsociadoByNumDocIdentificacion(cliAsoNumDocIdentificacion) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/cliasociado/findByAsoNumDocIdentificacion/${cliAsoNumDocIdentificacion}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {

    }
  }

  async findByIdBeneficiario(asoId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/cliasociado/beneficiario/${asoId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async savePhoto(filePhoto, asoId) {
    try {
      let config = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${jwt?.jwt}`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: "filePhoto",
      };

      let res = await fetch(
        api.baseUrl() + `/cliasociado/savePhoto/${asoId}`,
        config
      );
      let json = await res.json();
      return json;
    } catch (err) {
      addressingError(err);
    }
  }

  async findAdd(
    pageNumber,
    codigoAsociado,
    primerNombre,
    segundoNombre,
    otroNombre,
    primerApellido,
    segundoApellido,
    numeroIdentificacion,
    estadoAsociado,
    nombreJuridico,
    tipoIdentificacion
  ) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() +
          `/cliasociado/busqueda/${codigoAsociado || 0}/${primerNombre || 0}/${
            segundoNombre || 0
          }/${otroNombre || 0}/${primerApellido || 0}/${segundoApellido || 0}/${
            numeroIdentificacion || 0
          }/${estadoAsociado|| 0}/${
            nombreJuridico || 0
          }/${tipoIdentificacion || 0}/?page=${pageNumber}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      console.log(response);
      return response;
    } catch (err) {
      addressingError(err);
    }
  }

}
