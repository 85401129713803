import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { Messages } from "primereact/messages";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import classNames from "classnames";
//Service
import CliAsociadoService from "../../../../service/CliServices/CliAsociadoService";
import AppTipoProductoService from "../../../../service/AppServices/AppTipoProductoService";
import AppTipoSubProductoService from "../../../../service/AppServices/AppTipoSubProductoService";
import AppFrecuencia from "../../../../service/AppServices/AppFrecuencia";
import AppTipoTasaAho from "../../../../service/AppServices/AppTipoTasaAho";
import AhoCuentaService from "../../../../service/AhoCuentaService/AhoCuentaService";
import AppAhoFormaPagoService from "../../../../service/AppServices/AppAhoFormaPagoService";
import CliDireccionService from "../../../../service/CliServices/CliDireccionService";
import printJS from "print-js";
//Componentes
import { SeleccionarCuentaAhorro } from "./SeleccionarCuentaAhorro";
import { TasaComponent } from "./TasaComponent";
import { CancelacionAho } from "./CancelacionAho";
import { CliAsociadoSeleccionar } from "../../../ClientesPages/CliAsociadoSeleccionar";
import useAuth from "../../../../auth/useAuth";
import { ImpresionCerificado } from "./Ipresion/ImpresionCerificado";
import AppSucursalService from "../../../../service/AppServices/AppSucursalService";
export const GeneralComponentMain = ({ ahorro, setAhorro, toast }) => {
  const message = useRef();
  const auth = useAuth();
  let productoEmpty = {
    proId: 0,
    proTipoId: 0,
    proTipoNombre: "",
  };

  const handlePrint = () => {
    printJS({
      printable: "printable-content",
      type: "html",
      targetStyles: ["*"],
      font_size: "13px",
      font: "arial",
      style: `
      body {
        margin-top: 110px;
         }
    `,
    });
  };

  const [appSucursalIngreso, setAppSucursalIngreso] = useState([]);
  const [newAhorro, setNewAhorro] = useState(ahorro);
  const [newCta, setNewCta] = useState(false);
  const [productos, setProductos] = useState([]);
  const [idProducto, setIdProducto] = useState(productoEmpty);
  const [subProductos, setSubProductos] = useState([]);
  const [frecuencia, setFrecuencia] = useState([]);
  const [tipoTasaAho, setTipoTasaAho] = useState([]);
  const [ahoFormaDePago, setAhoFormDePago] = useState([]);
  const [ahoFormaDePagoInt, setAhoFormDePagoInt] = useState([]);
  const [editar, setEditar] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [tamano, setTamano] = useState(false);
  const [tasa, setTasa] = useState(false);
  const [cancelacion, setCanselacion] = useState(false);
  const [imprimir, setImprimir] = useState(false);
  const [buscar, setBuscar] = useState(false);
  //const [enviarAso, setEnviarAso] = useState({ asoId: 0 });
  const [clidireccion, setCliDireccion] = useState([]);
  var charCode = 0;

  useEffect(() => {
    const productoService = new AppTipoProductoService();
    if (window.screen.width < 770) {
      setTamano(true);
    }

    if (ahorro.ahoId !== "") {
      setEditar(true);
      const appSucursalService = new AppSucursalService();
      appSucursalService.findAll().then((res) => {
        if (res?.length > 0) {
          res?.forEach((sucursal) => {
            if (sucursal?.sucId === newAhorro?.sucId) {
              setAppSucursalIngreso(sucursal);
            }
          });
        }
      });
    }

    if (newAhorro?.subAhoId !== 0) {
      const subProductoService = new AppTipoSubProductoService();
      subProductoService
        .findById(newAhorro?.appAhoSubProducto.proId)
        .then((res) => {
          setSubProductos(res);
        });

      productoService
        .findByID(newAhorro?.appAhoSubProducto.proId)
        .then((response) => {
          setIdProducto(response);
        });
    }

    productoService.findAll().then((response) => {
      setProductos(response?.data);
    });

    const frecuenciaService = new AppFrecuencia();
    frecuenciaService.findAll().then((response) => {
      setFrecuencia(response?.data);
    });

    const tipoTasaAhorService = new AppTipoTasaAho();
    tipoTasaAhorService.findAll().then((response) => {
      setTipoTasaAho(response);
    });

    const formaPagoService = new AppAhoFormaPagoService();
    formaPagoService.findAll("C").then((response) => {
      setAhoFormDePago(response);
    });
    formaPagoService.findAll("I").then((response) => {
      setAhoFormDePagoInt(response);
    });

    if (newAhorro?.asoId !== 0) {
      const cliDireccionService = new CliDireccionService();
      cliDireccionService.findAllByAsoId(newAhorro?.asoId).then((response) => {
        setCliDireccion(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeNew = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _newAhorro = { ...newAhorro };
    _newAhorro[`${name}`] = val;

    if (
      ("ahoCondicionEspecial" === name && e.target.value === "0") ||
      ("ahoCondicionEspecial" === name && e.target.value === 0)
    ) {
      _newAhorro[`ahoFechaProximoPago`] = newAhorro?.ahoFechaVencimiento;
      _newAhorro["appFrecuencia"] = {
        freDias: 0,
        freId: 8,
        freMeses: 0,
        freNombre: "VENCIMIENTO",
      };
      _newAhorro["freId"] = 8;
    }
    setNewAhorro(_newAhorro);
  };

  const onInputChangeRegObject = (e) => {
    setIdProducto(e.value);

    const subProductoService = new AppTipoSubProductoService();

    subProductoService.findById(e.value.proId).then((res) => {
      setSubProductos(res);
    });
  };
  const onInputChangeObjetAhorro = (e, name, value, key) => {
    const val = (e.target && e.target.value) || "";

    let _ahorro = { ...newAhorro };
    _ahorro[`${name}`] = val;

    _ahorro[`${key}`] = value;
    if (
      _ahorro?.appAhoSubProducto.subTipoId === 2 &&
      _ahorro.appFrecuencia === null
    ) {
      _ahorro["appFrecuencia"] = {
        freDias: 0,
        freId: 8,
        freMeses: 0,
        freNombre: "VENCIMIENTO",
      };
      _ahorro["freId"] = 8;
    } else if (_ahorro?.appAhoSubProducto.subTipoId !== 2) {
      _ahorro["appFrecuencia"] = null;
      _ahorro["freId"] = null;
    }

    setNewAhorro(_ahorro);
  };

  const onpunchangeFrec = (e, name, value, key) => {
    var val = (e.target && e.target.value) || "";
    let _ahorro = { ...newAhorro };
    _ahorro[`${name}`] = val;
    _ahorro[`${key}`] = value;

    var date = new Date();
    var fechaInt = "";
    switch (value) {
      case 1:
        fechaInt = sumarDias(date, 7);
        break;
      case 2:
        fechaInt = sumarDias(date, 15);
        break;
      case 3:
        fechaInt = sumarDias(date, 30);
        break;
      case 4:
        fechaInt = sumarDias(date, 60);
        break;
      case 5:
        fechaInt = sumarDias(date, 90);
        break;
      case 6:
        fechaInt = sumarDias(date, 180);
        break;
      case 7:
        fechaInt = sumarDias(date, 365);
        break;
      case 8:
        fechaInt = newAhorro?.ahoFechaVencimiento;
        break;
      default:
        fechaInt = newAhorro?.ahoFechaVencimiento;
    }

    _ahorro[`ahoFechaProximoPago`] = fechaInt;
    setNewAhorro(_ahorro);
  };

  const fil = (evt) => {
    var e = evt;
    charCode = e.which || e.keyCode;

    if (charCode === 9) {
      if (
        newAhorro?.asoId !== 0 &&
        newAhorro?.asoId !== undefined &&
        newAhorro?.asoId !== null &&
        newAhorro?.asoId !== ""
      ) {
        dataCli();
      }
    }
  };

  const findCli = () => {
    if (newAhorro?.asoId !== 0 && charCode !== 9) {
      dataCli();
    }
  };

  const dataCli = () => {
    const cliAsociadoService = new CliAsociadoService();
    cliAsociadoService.findByIdClau(newAhorro?.asoId).then((res) => {
      if (res === undefined || res === false) {
        toast.current.show({
          severity: "warn",
          summary: "error",
          content: "Asociado no encontrado",
          life: 4000,
        });
        let _cliPersona = { ...newAhorro };
        _cliPersona[`ahoNombre`] = "";
        _cliPersona[`asoId`] = 0;

        setNewAhorro(_cliPersona);
      } else {
        var nombre = res.name;

        let _cliPersona = { ...newAhorro };
        _cliPersona[`ahoNombre`] = nombre;

        setNewAhorro(_cliPersona);
      }
    });
  };

  useEffect(() => {
    if (newAhorro?.asoId !== 0) {
      const cliDireccionService = new CliDireccionService();
      cliDireccionService.findAllByAsoId(newAhorro?.asoId).then((response) => {
        setCliDireccion(response);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newAhorro?.ahoNombre]);

  const dayNow = () => {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var dayNow = year + "-" + month + "-" + day;

    return dayNow;
  };

  function selectText(e) {
    const input = document.getElementById(e.target.id);
    input.focus();
    input.select();
  }
  function sumarDias(fecha, dias) {
    fecha.setDate(fecha.getDate() + dias);
    var year = fecha.getFullYear();
    var month = fecha.getMonth() + 1;
    var day = fecha.getDate();
    if (day < 10) {
      day = "0" + day;
    }
    if (month < 10) {
      month = "0" + month;
    }
    var dayNow = year + "-" + month + "-" + day;
    return dayNow;
  }

  const plazoEvt = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;

    if (charCode === 9) {
      plazo();
    }
  };

  const plazo = () => {
    var fecha = new Date();
    var day = newAhorro?.ahoPlazoDeposito;
    var day1 = Number(day);
    var date = sumarDias(fecha, day1);
    let _newAhorro = { ...newAhorro };
    _newAhorro[`ahoFechaVencimiento`] = date;
    _newAhorro[`ahoFechaProximoPago`] = date;
    setNewAhorro(_newAhorro);
  };

  function save() {
    let _ahorro = { ...newAhorro };
    _ahorro[`proId`] = newAhorro?.appAhoSubProducto.proId;
    if (newAhorro?.usuIngresa === null) {
      _ahorro[`usuIngresa`] = auth.user?.usr.usuUsuario;
    }
    if (newAhorro?.usuIngresa !== null) {
      _ahorro[`usuModifica`] = auth.user?.usr.usuUsuario;
    }
    if (newAhorro?.ahoFechaApertura === "") {
      var fechAp = dayNow();
      _ahorro[`ahoFechaApertura`] = fechAp;
    }

    if (
      (_ahorro.ahoNombre !== "" && _ahorro.asoId !== "") ||
      (_ahorro.asoId !== 0 && _ahorro.usuUsuarioPromotor !== "")
    ) {
      setSubmitted(false);
      if (newAhorro?.appAhoSubProducto.subTipoId !== 2) {
        _ahorro[`ahoCondicionEspecial`] = 0;
        _ahorro[`ahoIdAcreditar`] = null;
        console.log(_ahorro);
        if (
          _ahorro.ahoMontoInicial !== 0 &&
          _ahorro.ahoNumeroLibreta !== 0 &&
          _ahorro.ahoMontoInicial !== "" &&
          _ahorro.ahoNumeroLibreta !== "" &&
          _ahorro.ahoReferencia !== "" &&
          _ahorro.ahoReferencia !== 0 &&
          _ahorro.ahoReferencia !== null
        ) {
          saveAho(_ahorro);
          setSubmitted(false);
        } else {
          setSubmitted(true);
          message.current.show({
            severity: "warn",
            summary: "error",
            content: "Verifique que los campos necesarios no esten vacios",
            life: 4000,
          });
        }
      } else {
        if (
          _ahorro.ahoNumeroCertificado !== 0 &&
          _ahorro.ahoNumeroCertificado !== "" &&
          _ahorro.ahoPlazoDeposito !== 0 &&
          _ahorro.ahoPlazoDeposito !== ""
        ) {
          setSubmitted(false);
          saveAho(_ahorro);
        } else {
          setSubmitted(true);
          message.current.show({
            severity: "warn",
            summary: "error",
            content: "Verifique que los campos necesarios no esten vacios",
            life: 4000,
          });
        }
      }
    } else {
      setSubmitted(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
    }
  }
  function saveAho(aho) {
    const ahoService = new AhoCuentaService();
    ahoService.save(aho).then((response) => {
      if (response?.status === 500 || response?.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail:
            "Error al crear la cuenta, por favor de comunicarse con soporte",
          life: 4000,
        });
        setEditar(false);
      } else if (ahorro.ahoId !== "") {
        setEditar(true);
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Cuenta actualizada correctamente",
          life: 4000,
        });

        setEditar(true);
        setNewAhorro(response);
        setAhorro(response);
        sessionStorage.setItem("Ahorro", JSON.stringify(response));
      } else {
        toast.current.show({
          severity: "success",
          summary: "Tarea Realizada con exito",
          detail: "Cuenta creada correctamente",
          life: 4000,
        });
        setEditar(true);
        setNewAhorro(response);
        setAhorro(response);
        sessionStorage.setItem("Ahorro", JSON.stringify(response));
      }
    });
  }

  function tasaFalse() {
    setNewAhorro(ahorro);
    setTasa(false);
  }

  const botonesImpresion = () => {
    return (
      <>
        <Button
          className="mr-2 p-button-danger"
          label="Cancelar"
          icon="pi pi-times"
          onClick={() => setImprimir(false)}
        />

        <Button
          label="Imprimir"
          icon="pi pi-print"
          onClick={() => {
            handlePrint();
            // setImprimir(false);
          }}
        />
      </>
    );
  };

  // const vechaInteresV = () => {
  //   console.log(_newAhorro)
  //   let _newAhorro = { ...newAhorro };
  //   _newAhorro[
  //     `ahoFechaProximoPago`
  //   ] = newAhorro?.ahoFechaVencimiento;
  //   _newAhorro[`appFrecuencia`] = {
  //     freDias: 0,
  //     freId: 8,
  //     freMeses: 0,
  //     freNombre: "VENCIMIENTO",
  //   };
  //   _newAhorro[`freId`] = newAhorro?.freId;

  //   setNewAhorro(_newAhorro);
  // };

  function acciones() {
    return (
      <>
        {" "}
        <Button
          icon={editar === true ? "pi pi-lock" : "pi pi-lock-open"}
          onClick={() =>
            editar === false ? setEditar(true) : setEditar(false)
          }
          className="p-button-rounded p-mr-2 p-mb-2"
          tooltip="Editar datos"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          type="button"
          disabled={
            ahorro?.ahoCertificadoImpreso !== 0 || ahorro?.ahoEstado === 3
          }
        />
        <Button
          icon={"pi pi-percentage"}
          onClick={() => setTasa(true)}
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          className="p-button-rounded p-button-success p-mr-2 p-mb-2"
          tooltip="Cambio de Tasa"
          type="button"
          disabled={
            newAhorro?.appAhoSubProducto?.subTipoId !== 2 ||
            ahorro?.ahoEstado === 3
          }
        />
        <Button
          icon={"pi pi-ban"}
          onClick={() => setCanselacion(true)}
          className="p-button-rounded p-button-danger p-mr-2 p-mb-2"
          tooltip="Cancelar Cuenta"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          type="button"
          disabled={ahorro?.ahoEstado === 3}
        />
        <Button
          icon={"pi pi-file"}
          onClick={() => setImprimir(true)}
          className="p-button-rounded p-button-info  p-mr-2 p-mb-2"
          tooltip="Impresion"
          type="button"
          tooltipOptions={{
            position: "bottom",
            mouseTrack: true,
            mouseTrackTop: 15,
          }}
          disabled={
            newAhorro?.appAhoSubProducto?.subTipoId !== 2 ||
            ahorro?.ahoEstado === 3
          }
        />
      </>
    );
  }

  const informacion = () => {
    return (
      <div className="card" style={{ height: "100%" }}>
        <div
          style={{
            borderBottom: "1px solid #EDEDED",
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h5>Acciones</h5>

          {acciones()}
        </div>
        <h5
          style={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Información
        </h5>
        <div
          style={{
            textAlign: "justify",
            alignItems: "justify",
            justifyContent: "justify",
          }}
        >
          <h6>
            Tasa actual:{" "}
            {Number(newAhorro?.ahoTasaNegociado) +
              Number(newAhorro?.ahoTasaBase)}{" "}
            %
          </h6>

          <h6>Sucursal de ingreso:</h6>
          <>{appSucursalIngreso?.sucNombre || "NO SE ENCONTRO LA AGENCIA"}</>
          <h6>
            Usuario quien ingreso: <>{newAhorro?.usuIngresa}</>
          </h6>
        </div>
      </div>
    );
  };

  const formulario = () => {
    return (
      <div>
        <div className="p-grid">
          <div
            className={`p-col-12`}
            style={{
              display:
                newAhorro?.ahoId === ""
                  ? "none"
                  : tamano !== true
                  ? "none"
                  : "",
            }}
          >
            {informacion()}
          </div>
          <div
            className={`p-col-12 p-md-${
              newAhorro?.ahoId !== "" ? "9" : "12"
            } p-lg-${newAhorro?.ahoId !== "" ? "9" : "12"}`}
          >
            <div className="card" style={{ height: "100%" }}>
              <div className="p-grid">
                <div
                  className="p-fluid p-col-12 p-lg-3 p-md-3"
                  onKeyDown={(e) => fil(e)}
                >
                  <label htmlFor="asoId">Código Cliente</label>
                  <div className="p-inputgroup">
                    <InputText
                      id="asoId"
                      type={"number"}
                      value={newAhorro?.asoId || 0}
                      onChange={(e) => onChangeNew(e, "asoId")}
                      onClick={(e) => {
                        selectText(e);
                      }}
                      className={classNames({
                        "p-invalid": submitted && !newAhorro?.asoId,
                      })}
                      disabled={
                        editar || newAhorro?.ahoId !== "" ? true : false
                      }
                      onBlur={(e) => findCli(e)}
                    />

                    <Button
                      icon="pi pi-search"
                      className="p-button-info"
                      onClick={() => setBuscar(true)}
                      disabled={
                        editar || newAhorro?.ahoId !== "" ? true : false
                      }
                    />
                  </div>
                  {submitted && !newAhorro?.asoId && (
                    <small className="p-invalid">Codigo requerido.</small>
                  )}
                </div>

                <div className="p-fluid p-col-12 p-lg-6 p-md-6">
                  <label htmlFor="name">Nombre</label>
                  <InputText
                    id="asoId"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                    }}
                    type={"text"}
                    value={newAhorro?.ahoNombre || ""}
                    onChange={(e) => onChangeNew(e, "ahoNombre")}
                    disabled={true}
                  />
                </div>
                <div className="p-fluid p-col-12 p-lg-3 p-md-3">
                  <label htmlFor="fecApertura">Fecha Apertura</label>
                  <InputText
                    id="asoId"
                    style={{
                      fontWeight: "bold",
                      backgroundColor: "#f5f5f5",
                    }}
                    type="date"
                    value={
                      ahorro?.asoId !== 0
                        ? newAhorro?.ahoFechaApertura
                        : dayNow() || ""
                    }
                    onChange={(e) => onChangeNew(e, "ahoFechaApertura")}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="p-grid">
                <div className="p-fluid p-col">
                  <label htmlFor="prod">Producto</label>
                  <Dropdown
                    value={idProducto || 0}
                    options={productos}
                    onChange={(e) =>
                      onInputChangeRegObject(
                        e,
                        "idProducto",
                        e.value.id,
                        "proId"
                      )
                    }
                    optionLabel="proNombre"
                    placeholder="Seleccione un producto"
                    required
                    disabled={editar || newAhorro?.ahoId !== "" ? true : false}
                  />
                </div>
                <div className="p-fluid p-col">
                  <label htmlFor="subPro">Sub Producto</label>
                  <Dropdown
                    value={newAhorro?.appAhoSubProducto || ""}
                    options={subProductos}
                    onChange={(e) =>
                      onInputChangeObjetAhorro(
                        e,
                        "appAhoSubProducto",
                        e.value.subAhoId,
                        "subAhoId"
                      )
                    }
                    placeholder="Seleccione un sub producto"
                    optionLabel="subAhoNombre"
                    disabled={editar || newAhorro?.ahoId !== "" ? true : false}
                  />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-fluid p-col">
                  <label htmlFor="firReg">Firmas Registradas</label>
                  <InputText
                    value={newAhorro?.ahoNumeroFirmaRegistra || 0}
                    id="firmaRegistrada"
                    type="number"
                    onChange={(e) => onChangeNew(e, "ahoNumeroFirmaRegistra")}
                    placeholder="Seleccione un producto"
                    required
                    onClick={(e) => selectText(e)}
                    disabled={editar}
                  />
                </div>
                <div className="p-fluid p-col">
                  <label htmlFor="firReq">Firmas Requeridas</label>
                  <InputText
                    value={newAhorro?.ahoNumeroFirmaRequiere || 0}
                    id="firmaR"
                    type="number"
                    onChange={(e) => onChangeNew(e, "ahoNumeroFirmaRequiere")}
                    onClick={(e) => selectText(e)}
                    disabled={editar}
                  />
                </div>
                <div className="p-fluid p-col">
                  <label htmlFor="firReq">Monto inicial</label>
                  <span className="p-input-icon-left">
                    <i>Q. </i>
                    <InputText
                      value={newAhorro?.ahoMontoInicial || 0}
                      id="monto"
                      type="number"
                      onChange={(e) => onChangeNew(e, "ahoMontoInicial")}
                      onClick={(e) => selectText(e)}
                      className={classNames({
                        "p-invalid": submitted && !newAhorro?.ahoMontoInicial,
                      })}
                      disabled={editar}
                    />
                  </span>
                  {submitted && !newAhorro?.ahoMontoInicial && (
                    <small className="p-invalid">
                      Monto Inicial Requerido.
                    </small>
                  )}
                </div>
              </div>

              <div className="p-grid">
                <div className="p-fluid p-col">
                  <label htmlFor="ahoLib">No. Libreta</label>
                  <InputText
                    value={newAhorro?.ahoNumeroLibreta || 0}
                    id="libreta"
                    type="number"
                    onChange={(e) => onChangeNew(e, "ahoNumeroLibreta")}
                    onClick={(e) => selectText(e)}
                    className={classNames({
                      "p-invalid": submitted && !newAhorro?.ahoNumeroLibreta,
                    })}
                    disabled={editar}
                  />

                  {submitted && !newAhorro?.ahoNumeroLibreta && (
                    <small className="p-invalid">No. Libreta requerido</small>
                  )}
                </div>
                <div className="p-fluid p-col">
                  <label htmlFor="AhoPromotor">Usuario Promotor</label>
                  <InputText
                    value={newAhorro?.usuUsuarioPromotor || ""}
                    id="promotor"
                    type="text"
                    onChange={(e) => onChangeNew(e, "usuUsuarioPromotor")}
                    onClick={(e) => selectText(e)}
                    className={classNames({
                      "p-invalid": submitted && !newAhorro?.usuUsuarioPromotor,
                    })}
                    disabled={editar}
                  />
                  {submitted && !newAhorro?.usuUsuarioPromotor && (
                    <small className="p-invalid">Promotor Requerido</small>
                  )}
                </div>

                <div className="p-fluid p-col">
                  <label htmlFor="AhoReferencia">Referencia</label>
                  <InputText
                    value={newAhorro?.ahoReferencia || ""}
                    id="referencia"
                    type="text"
                    onChange={(e) => onChangeNew(e, "ahoReferencia")}
                    onClick={(e) => selectText(e)}
                    className={classNames({
                      "p-invalid": submitted && !newAhorro?.ahoReferencia,
                    })}
                    disabled={editar}
                  />
                </div>
              </div>

              <div className="p-grid">
                <div className="p-fluid p-col">
                  <label>Dirección para correspondencia</label>
                  <Dropdown
                    value={newAhorro?.cliDireccion || ""}
                    options={clidireccion}
                    onChange={(e) =>
                      onInputChangeObjetAhorro(
                        e,
                        "cliDireccion",
                        e.value.dirId,
                        "dirId"
                      )
                    }
                    placeholder="Seleccione una direccion"
                    optionLabel="dirDireccion"
                    disabled={editar}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`p-col-12   p-md-${newAhorro?.ahoId !== "" ? "3" : "12"}
      
      
      p-lg-${newAhorro?.ahoId !== "" ? "3" : "12"}`}
            style={{
              display:
                newAhorro?.ahoId === ""
                  ? "none"
                  : tamano === true
                  ? "none"
                  : "",
            }}
          >
            {informacion()}
          </div>
        </div>
        <div
          className="card"
          style={{
            display:
              newAhorro?.appAhoSubProducto?.subTipoId !== 2 ? "none" : "",
          }}
        >
          <div className="p-grid">
            <div className="p-fluid p-col-12 p-md-4 p-lg-4">
              <label htmlFor="ahoCerti">No. Certificado</label>
              <InputText
                value={newAhorro?.ahoNumeroCertificado}
                id="certificado"
                type="number"
                onChange={(e) => onChangeNew(e, "ahoNumeroCertificado")}
                onClick={(e) => selectText(e)}
                placeholder="Numero de Certificado Requerido"
                required
                className={classNames({
                  "p-invalid": submitted && !newAhorro?.ahoNumeroCertificado,
                })}
                disabled={editar}
              />
              {submitted && !newAhorro?.ahoNumeroCertificado && (
                <small className="p-invalid">
                  Numero de Certificado Requerido
                </small>
              )}
            </div>{" "}
            <div
              className="p-fluid p-col-12 p-md-4 p-lg-4"
              onKeyDown={(e) => {
                plazoEvt(e);
              }}
            >
              <label htmlFor="ahoLib">Plazo en dias</label>
              <InputText
                value={newAhorro?.ahoPlazoDeposito || 0}
                id="plazoDias"
                type="number"
                onChange={(e) => {
                  onChangeNew(e, "ahoPlazoDeposito");
                }}
                onClick={(e) => {
                  selectText(e);
                }}
                onBlur={() => plazo()}
                //  onBlur={(e) => fechaInteres(newAhorro?.freId)}
                className={classNames({
                  "p-invalid": submitted && !newAhorro?.ahoPlazoDeposito,
                })}
                disabled={editar}
              />

              {submitted && !newAhorro?.ahoPlazoDeposito && (
                <small className="p-invalid">Plazo Requerido.</small>
              )}
            </div>
            <div className="p-fluid p-col-12 p-md-4 p-lg-4">
              <label htmlFor="ahoLib">Fecha de vencimiento</label>
              <InputText
                value={newAhorro?.ahoFechaVencimiento || ""}
                id="vencimiento"
                className="block mb-2"
                type="date"
                placeholder="dd/mm/yyyy"
                onChange={(e) => onChangeNew(e, "ahoFechaVencimiento")}
                onClick={(e) => selectText(e)}
                disabled={true}
              />
            </div>
          </div>
          <div className="p-grid">
            <div className="p-fluid p-col-12 p-md-4 p-lg-4">
              <label htmlFor="especial">Condicion Especial</label>
              <div className="p-grid">
                <div className="p-col">
                  <div className="p-field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value={1}
                      checked={
                        newAhorro?.ahoCondicionEspecial === 1 ||
                        newAhorro?.ahoCondicionEspecial === "1"
                      }
                      onChange={(e) => onChangeNew(e, "ahoCondicionEspecial")}
                      // className={classNames({
                      //   "p-invalid": submitted && !direccion.tipo,
                      // })}
                      // disabled={eliminar === true ? "on" : ""}
                      disabled={editar}
                    />

                    <label htmlFor="esSi">Si</label>
                  </div>
                </div>
                <div className="p-col">
                  <div className="p-field-radiobutton">
                    <RadioButton
                      inputId="option1"
                      value={"0"}
                      checked={
                        newAhorro?.ahoCondicionEspecial === "0" ||
                        newAhorro?.ahoCondicionEspecial === 0
                      }
                      onChange={(e) => onChangeNew(e, "ahoCondicionEspecial")}
                      // onClick={() => vechaInteresV()}
                      // className={onChangeNew({
                      //   "p-invalid": submitted && !direccion.tipo,
                      // })}
                      // disabled={eliminar === true ? "on" : ""}
                      disabled={editar}
                    />
                    <label htmlFor="esNo">No</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-fluid p-col-12 p-md-4 p-lg-4">
              <label htmlFor="frecuencia">Frecuencia</label>
              <Dropdown
                value={newAhorro?.appFrecuencia || ""}
                options={frecuencia}
                onChange={(e) => {
                  onpunchangeFrec(e, "appFrecuencia", e.value.freId, "freId");
                }}
                optionLabel="freNombre"
                disabled={
                  newAhorro?.ahoCondicionEspecial === "0" ||
                  editar ||
                  newAhorro?.ahoCondicionEspecial === 0
                }

                //onClick={fechaInteres()}
              />
            </div>
            <div className="p-fluid p-col-12 p-md-4 p-lg-4">
              <label htmlFor="ahoLib">Proximo Pago de Interes</label>
              <InputText
                value={newAhorro?.ahoFechaProximoPago || ""}
                id="pagoInters"
                type="date"
                disabled={true}
              />
            </div>
          </div>
          <div>
            <div className="p-grid">
              <div className="p-fluid p-col-12 p-md-4 p-lg-4">
                <label htmlFor="tipoTasa">Tipo de tasa</label>
                <Dropdown
                  value={newAhorro?.ahoTipoTasa || ""}
                  options={tipoTasaAho}
                  onChange={(e) =>
                    onInputChangeObjetAhorro(
                      e,
                      "ahoTipoTasa",
                      e.value.tasId,
                      "tasAhoId"
                    )
                  }
                  placeholder="Seleccione un tipo de tasa..."
                  optionLabel="tasAhoNombre"
                  disabled={editar || newAhorro?.ahoId !== "" ? true : false}
                />
              </div>
              <div className="p-fluid p-col-12 p-md-4 p-lg-4">
                <label htmlFor="pagoCapital">Forma de pago Capital</label>
                <Dropdown
                  value={newAhorro?.appAhoFormaPagoCap || ""}
                  options={ahoFormaDePago}
                  onChange={(e) =>
                    onInputChangeObjetAhorro(
                      e,
                      "appAhoFormaPagoCap",
                      e.value.forPagoId,
                      "forPagoCapId"
                    )
                  }
                  placeholder="Seleccione una forma de pago..."
                  optionLabel="forPagoNombre"
                  disabled={editar || newAhorro?.ahoId !== "" ? true : false}
                />
              </div>
              <div className="p-fluid  p-col-12 p-md-4 p-lg-4">
                <label htmlFor="pagoCapital">Forma de pago Interes</label>
                <Dropdown
                  value={newAhorro?.appAhoFormaPagoInt || ""}
                  options={ahoFormaDePagoInt}
                  onChange={(e) =>
                    onInputChangeObjetAhorro(
                      e,
                      "appAhoFormaPagoInt",
                      e.value.forPagoId,
                      "forPagoIntId"
                    )
                  }
                  placeholder="Seleccione una forma de pago..."
                  optionLabel="forPagoNombre"
                  disabled={editar || newAhorro?.ahoId !== "" ? true : false}
                />
              </div>
            </div>
          </div>
          <div className="p-grid">
            <div className="p-fluid p-col">
              <label htmlFor="pagoCapital">Cuenta para Acreditar</label>
              <div className="p-inputgroup">
                <InputText
                  value={newAhorro?.ahoIdAcreditar || ""}
                  id="ahoAcreditar"
                  type="text"
                  onChange={(e) => onChangeNew(e, "ahoIdAcreditar")}
                  onClick={(e) => selectText(e)}
                  className={classNames({
                    "p-invalid": submitted && !newAhorro?.ahoIdAcreditar,
                  })}
                  disabled={
                    editar || newAhorro?.forPagoIntId !== 4 ? true : false
                  }
                />
                <Button
                  icon="pi pi-search"
                  onClick={() => setNewCta(true)}
                  disabled={
                    editar || newAhorro?.forPagoIntId !== 4 ? true : false
                  }
                />{" "}
              </div>
              {submitted && !newAhorro?.ahoIdAcreditar && (
                <small className="p-invalid">Cuenta requerida.</small>
              )}
            </div>
          </div>
        </div>
        <Messages ref={message} />
        <div className="card">
          <div className="p-grid">
            <div className="p-fluid p-col-12 p-md-6 p-lg-6">
              <Button
                label="CANCELAR"
                icon="pi pi-bookmark"
                className="p-mr-2 p-mb-2 p-button-danger"
                onClick={() => {
                  setNewAhorro(ahorro);
                  setEditar(true);
                  charCode = 0;
                }}
                disabled={editar}
              ></Button>
            </div>
            <div className="p-fluid p-col-12 p-md-6 p-lg-6">
              <Button
                label="GUARDAR"
                icon="pi pi-bookmark"
                className="p-mr-2 p-mb-2 p-button-success"
                onClick={() => save()}
                disabled={editar}
              ></Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  function botones() {
    return (
      <>
        <Button
          icon="pi pi-times"
          label="Cancelsar"
          className="p-button-danger p-button-sm"
          onClick={() => setBuscar(false)}
        />
      </>
    );
  }
  return (
    <>
      {formulario()}

      <Dialog
        header={<h5>Seleccione una cuenta para acreditar.</h5>}
        visible={newCta}
        onHide={() => setNewCta(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "75vw" }}
      >
        <SeleccionarCuentaAhorro
          codigo={newAhorro?.asoId}
          newAhorro={newAhorro}
          setNewAhorro={setNewAhorro}
          setNewCta={setNewCta}
        ></SeleccionarCuentaAhorro>
      </Dialog>

      <Dialog
        header="Cambio de Tasa de Interes"
        visible={tasa}
        onHide={tasaFalse}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "55vw" }}
      >
        <TasaComponent
          setAhorro={setAhorro}
          toast={toast}
          newAhorro={newAhorro}
          setNewAhorro={setNewAhorro}
          tasaFalse={tasaFalse}
          setTasa={setTasa}
        />
      </Dialog>

      <Dialog
        header={"Cancelacion de cuenta - " + newAhorro?.ahoId}
        visible={cancelacion}
        onHide={() => setCanselacion(false)}
        //breakpoints={{ "90px": "65vw", "60px": "10vw" }}
        style={{ width: "550px" }}
      >
        <CancelacionAho
          newAhorro={newAhorro}
          setCanselacion={setCanselacion}
          setAhorro={setAhorro}
          toast={toast}
        />
      </Dialog>
      <Dialog
        visible={imprimir}
        header="Impresion de certificado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "900px" }}
        className="p-fluid"
        onHide={() => {
          setImprimir(false);
        }}
        footer={botonesImpresion}
      >
        {/* <Ipresion setImprimir={setImprimir} ahorro={ahorro} toast={toast}></Ipresion> */}

        <ImpresionCerificado setImprimir={setImprimir} ahorro={ahorro} />
      </Dialog>

      <Dialog
        visible={buscar}
        header="Buscar asociado"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "80vw" }}
        className="p-fluid"
        onHide={() => {
          setBuscar(false);
        }}
        maximizable
        footer={botones}
      >
        <CliAsociadoSeleccionar
          setBuscar={setBuscar}
          toast={toast}
          persona={newAhorro}
          setPersona={setNewAhorro}
          parametro1={"asoId"}
          parametro2={"ahoNombre"}
          cantPar={2}
        />
      </Dialog>
    </>
  );
};
