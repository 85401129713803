import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppModuloService {
  async findAll() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appmodulo/all`, {
        headers: { Authorization: `Bearer ${jwt?.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async procesarCierreDiario() {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(api.baseUrl() + `/appmodulo/execute`, {
        headers: { Authorization: `Bearer ${jwt.jwt}` },
      });
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
