import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";

const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};
export default class AppConfigService {
  async findAll() {

    const user = JSON.parse(localStorage.getItem("user"));
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/appconfig/all/${user?.usr.sucId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
