import React, { useState, useEffect } from 'react'
import { PickList } from 'primereact/picklist'
//import ProductService from '../../../../../src/service/ProductService'
import './../../../../utils/PickListDemo.css'

//import Services
import AppAuthorityService from '../../../../../src/service/AppServices/AppAuthorityService'
import AppAssignAuthorityService from '../../../../service/AppServices/AppAssignAuthorityService'

//import AppRoleService from '../../../../service/AppServices/AppRoleService'

export const ViewAuthorityRole = (props) => {
  let emptyAssignAuthority = {
    assignAuthId: 0,
    authId: 0,
    rolId: 0,
  }

  // let emptyAssignRole = {
  //   assignRoleId: 0,
  //   rolId: 0,
  //   usuUsuario: '',
  // }
  const [appAssignAuthority, setAppAssignAuthority] = useState(
    emptyAssignAuthority,
  )
  const [appAssignAuthorityAll, setAppAssignAuthorityAll] = useState([])
  const [source, setSource] = useState([])
  const [target, setTarget] = useState([])
  const appAuthorityService = new AppAuthorityService()
  const appAssignAuthorityService = new AppAssignAuthorityService()

  useEffect(() => {
    console.log('useEffect', props.rolId)
    appAuthorityService
      .findByRolIdNotAssign(props.rolId)
      .then((data) => {
        console.log('dataSourceEffect', data)
        setSource(data)
      })
      .catch((err) => {
        console.log('Error', err.message)
      })

    appAssignAuthorityService
      .findByRolIdAssign(props.rolId)
      .then((data) => {
        console.log('dataTargetEffect', data)
        setTarget(data)
      })
      .catch((err) => {
        console.log('Error', err.message)
      })
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onChange = (event) => {
    setSource(event.source)
    setTarget(event.target)
  }

  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <span className="product-category">
            {item?.authName || item?.appAuthority?.authName}
          </span>
        </div>
      </div>
    )
  }

  const refreshAuthorities = () => {
    setAppAssignAuthority(emptyAssignAuthority)
    appAuthorityService.findByRolIdNotAssign(props.rolId).then((data) => {
      setSource(data)
    })
    appAssignAuthorityService.findByRolIdAssign(props.rolId).then((data) => {
      setTarget(data)
    })
  }

  const addNewAuthority = (data) => {
    let _appAssignAuthority = { ...appAssignAuthority }
    _appAssignAuthority.authId = data.authId
    _appAssignAuthority.rolId = props.rolId

    appAssignAuthorityService.save(_appAssignAuthority).then((data) => {
      refreshAuthorities()
    })
  } // end addNewAuthority
  const removeAuthority = (data) => {
    appAssignAuthorityService.delete(data).then((data) => {
      refreshAuthorities()
    })
  } // end removeAuthority

  const addNewAuthorityAll = (data) => {
    console.log('addNewAuthorityAll', data)

    data.forEach((item) => {
      let _appAssignAuthorityAll = { ...emptyAssignAuthority }
      _appAssignAuthorityAll.authId = item.authId
      _appAssignAuthorityAll.rolId = props.rolId
      appAssignAuthorityAll.push(_appAssignAuthorityAll)
    })
    appAssignAuthorityService.saveAll(appAssignAuthorityAll).then((data) => {
      setAppAssignAuthorityAll([])
      refreshAuthorities()
    })
  }

  const removeAuthorityAll = (data) => {
    console.log('addNewAuthorityAll', data)

    appAssignAuthorityService.deleteAll(data).then((data) => {
      refreshAuthorities()
    })
  }

  return (
    <div className="picklist-demo">
      <PickList
        source={source}
        target={target}
        itemTemplate={itemTemplate}
        sourceHeader="No Asignados"
        targetHeader="Asignados"
        sourceStyle={{ height: '700px' }}
        targetStyle={{ height: '700px' }}
        showSourceControls={false}
        showTargetControls={false}
        onMoveToTarget={(e) => addNewAuthority(e.value[0])}
        onMoveToSource={(e) => removeAuthority(e.value[0])}
        onMoveAllToTarget={(e) => addNewAuthorityAll(e.value)}
        onMoveAllToSource={(e) => removeAuthorityAll(e.value)}
        onChange={onChange}
      ></PickList>
    </div>
  )
}
