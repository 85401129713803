import React, { useState, useEffect,useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { NuevoDepartamento } from "../NuevoDepartamento";
import { Toast } from "primereact/toast";
//servicios

import AppDepartamentoService from "../../../../service/AppServices/AppDepartamentoService";

export const ListarDepartamentos = () => {
    const toast = useRef();
  const appDepartamentoEmpty = {
    appPais: {
      paiCodAlpha2: "",
      paiCodAlpha3: "",
      paiId: 0,
      paiNombre: "",
      paiNombreEn: "",
      paiNumerico: "",
    },
    appRegion: {
      appPais: {
        paiCodAlpha2: "",
        paiCodAlpha3: "",
        paiId: 0,
        paiNombre: "",
        paiNombreEn: "",
        paiNumerico: "",
      },
      paiId: 0,
      regCodigo: 0,
      regId: 0,
      regNombre: "",
    },
    depCodigo: 0,
    depId: 0,
    depNombre: "",
    paiId: 0,
    regId: 0,
  };

  const [appDepartamento, setAppDepartamento] = useState(appDepartamentoEmpty);
  const [appDepartamentos, setAppDepartamentos] = useState([]);
  const [nuevoDepartamento, setNuevoDepartamento] = useState(false);
  const [estado, setEstado] = useState(false);

  useEffect(() => {
    const appDepartamentoService = new AppDepartamentoService();
    appDepartamentoService.findAll().then((response) => {
  
      setAppDepartamentos(response);
    });
  }, [estado]);

  const renderListaDepartamentos = () => {
    return (
      <DataTable
        value={appDepartamentos}
        // paginator={true}
        // rows={10}
        // rowsPerPageOptions={[5, 10, 20]}
        // selectionMode="single"
        // selection={appDepartamento}
        // onSelectionChange={e => setAppDepartamento(e.value)}
        // onRowSelect={e => setAppDepartamento(e.data)}
      >
        <Column field="depId" header="Id" />
        <Column field="depNombre" header="Departamento" />
        <Column field="depCodigo" header="Codigo" />
        <Column field="appRegion.regNombre" header="Region" />
        <Column field="appPais.paiNombre" header="Pais" />
        <Column header="Acciones" body={accionesDepartamento}/>
      </DataTable>
    );
  };

  const accionesDepartamento = (rowData) => {
    return (
      <div>
        <Button
          //   label="Editar"
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoDepartamento(true); setAppDepartamento(rowData)}}
        />
      </div>
    );
  };

  const ButtonNew = (
    <Button
      onClick={() => 
      {  setNuevoDepartamento(true); setAppDepartamento(appDepartamentoEmpty)
      }}
      className="p-button-success p-button-sm"
      label="nuevo"
      icon="pi pi-plus"
    />
  );

  return (
    <div className="card">
      <Toolbar className="p-mb-3" right={ButtonNew} />
      {renderListaDepartamentos()}

      <Dialog
        visible={nuevoDepartamento}
        onHide={() => setNuevoDepartamento(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        header={
          appDepartamento?.depId === 0
            ? "Nuevo departamento"
            : "Editar departamento"
        }
      >
        <NuevoDepartamento
          appDepartamento={appDepartamento}
          setAppDepartamento={setAppDepartamento}
          setNuevoDepartamento={setNuevoDepartamento}
          appDepartamentoEmpty={appDepartamentoEmpty}
          estado={estado}
          setEstado={setEstado}
          toast={toast}
        />
      </Dialog>

      <Toast ref={toast} />
    </div>
  );
};
