import React, { useState } from "react";
import { Menu } from "primereact/menu";
import { AjusteSaldo } from "./AjusteSaldo";
import { BackOffie } from "./BackOffie";
import { BloqueoDesbloque } from "./BloqueoDesbloque";
import { EncabezadoLibreta } from "./EncabezadoLibreta";
export const Administrar = ({ ahorro, setAhorro, toast }) => {
  const [administrar, setAdministrar] = useState(0);

  const menuItems = [
    {
      label: "Libreta",
      items: [{ label: "Encabezado libreta" ,
      icon: "pi pi-inbox",
      command: () => setAdministrar(4),
    }],
    },
    {
      label: "Solicitud",
    },
    {
      label: "Administrar",
      items: [
        {
          label: "BackOffice  ",
          icon: "pi pi-inbox",
          command: () => setAdministrar(1),
        },
        {
          label: "Ajuste de saldos",
          icon: "pi pi-money-bill",
          command: () => setAdministrar(2),
        },
        {
          label: "Bloqueo/Desbloqueo",
          icon: "pi pi-unlock",
          command: () => setAdministrar(3),
        },
      ],
    },
  ];



  function renderContent() {
    switch (administrar) {
      case 1:
        return (
          <BackOffie ahorro={ahorro} setAhorro={setAhorro} toast={toast} />
        );
     
      case 2:
        return (
          <>
            <h5>Ajuste de Saldo</h5>
            <AjusteSaldo ahorro={ahorro} setAhorro={setAhorro} toast={toast} />
          </>
        );
      case 3:
        return <BloqueoDesbloque   ahorro={ahorro} setAhorro={setAhorro} toast={toast} />;
      case 4:
        return <EncabezadoLibreta ahorro={ahorro}/>
      
        default:
        break;
    }
  }

  return (
    <>
      <div className="p-grid  p-fluid">
        <div className="p-col-12 p-md-3 p-lg-3 ">
          
          <Menu
          style={{ height: "100%", width : "100%" }}
          model={menuItems} />
        </div>

        <div
          className="p-col-12 p-mt-3 p-md-9 p-lg-9"
          style={{
            backgroundColor: "#f8f9fa",
            border: " 0.5px  solid #dcdcde",
          }}
        >
          {renderContent()}
        </div>
      </div>
    </>
  );
};
