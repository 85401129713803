import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { Toolbar } from "primereact/toolbar";
import { Dialog } from "primereact/dialog";
import AppTipoTasaService from "../../../../../service/AppServices/AppTipoTasaService";
export const ListarTipoTasa = () => {
  let appTipoTasaEmpty = {
    tasTipoId: 0,
    tasTipoNombre: "",
  };

  const [appTipoTasa, setAppTipoTasa] = useState(appTipoTasaEmpty);
  const [appTipoTasaList, setAppTipoTasaList] = useState([]);

  //Dialog -  Save
  const [showDialog, setShowDialog] = useState(false);

  const appTipoTasaService = new AppTipoTasaService();

  useEffect(() => {
    appTipoTasaService.findAll().then((res) => {
      setAppTipoTasaList(res);
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const save = () => {
    appTipoTasaService.save(appTipoTasa).then((res) => {
      setShowDialog(false);
      setAppTipoTasa(appTipoTasaEmpty);
      appTipoTasaService.findAll().then((res) => {
        setAppTipoTasaList(res);
      });
    });
  };

  const deleteRow = (rowData) => {
    appTipoTasaService.delete(rowData).then((res) => {
      appTipoTasaService.findAll().then((res) => {
        setAppTipoTasaList(res);
      });
    });
  };

  const onRowEditInit = (rowData) => {
    setShowDialog(true);
    setAppTipoTasa(rowData);
  };

  const onHideDialog = () => {
    setShowDialog(false);
    setAppTipoTasa(appTipoTasaEmpty);
  };

  const renderTableTipoTasa = () => {
    return (
      <DataTable value={appTipoTasaList} paginator={true} rows={10}>
        <Column field="tasTipoId" header="Id" sortable={true} />
        <Column field="tasTipoNombre" header="Nombre" sortable={true} />
        <Column header="Acciones" body={renderAction} />
      </DataTable>
    );
  };

  const renderAction = (rowData) => {
    return (
      <div>
        <Button
          label=""
          icon="pi pi-pencil"
          className="p-button-rounded p-button-warning p-mr-2"
          onClick={() => {
            onRowEditInit(rowData);
          }}
        />
        <Button
          label=""
          icon="pi pi-trash"
          className=" p-button-rounded p-button-danger"
          onClick={() => {
            deleteRow(rowData);
          }}
        />
      </div>
    );
  };

  var myHeaders = new Headers();
  myHeaders.append(
    "Cookie",
    "GX_CLIENT_ID=e70b72aa-cc86-49a7-89d8-2a4e61b0b4c6; ASP.NET_SessionId=05bkaavl0oyuh02rk141hg4x",
    "Access-Control-Allow-Origin: *"
  );

  var formdata = new FormData();
  formdata.append("client_id", "94003a4cf5f8448797cfd8f16e3b644a");
  formdata.append("client_secret", "d44814a2d87544549c5f781c493d12a7");
  formdata.append("grant_type", "password");
  formdata.append("scope", "FullControl");
  formdata.append("username", "mmenchu");
  formdata.append("password", "zombop-buwjo2-cIpzuk");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
  };

  fetch("https://app.sifco.org/fondos/oauth/access_token", requestOptions)
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));

  const leftContents = (
    <React.Fragment>
      <Button label="New" icon="pi pi-plus" className="p-mr-2" />
      <Button label="Upload" icon="pi pi-upload" className="p-button-success" />
      <i className="pi pi-bars p-toolbar-separator mr-2" />
      {/* <SplitButton
        label="Save"
        icon="pi pi-check"
        model={items}
        className="p-button-warning"
      ></SplitButton> */}
    </React.Fragment>
  );

  const rightContents = (
    <React.Fragment>
      <Button
        //label="Save"
        icon="pi pi-plus"
        tooltip="Nuevo"
        className="p-button-success"
        onClick={() => {
          setShowDialog(true);
        }}
      />
      {/* <Button icon="pi pi-search" className="p-mr-2" />
      <Button icon="pi pi-calendar" className="p-button-success p-mr-2" />
      <Button icon="pi pi-times" className="p-button-danger" /> */}
    </React.Fragment>
  );

  return (
    <>
      <div className="card">
        <Toolbar left={leftContents} right={rightContents} />
      </div>
      <div className="card">{renderTableTipoTasa()}</div>
      <Dialog
        header="Tipo Tasa"
        visible={showDialog}
        modal={true}
        onHide={() => onHideDialog()}
        width="50%"
        height="50%"
        footer={
          <div>
            <Button
              label="Guardar"
              icon="pi pi-check"
              onClick={() => {
                save();
              }}
            />
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={() => onHideDialog()}
            />
          </div>
        }
      >
        <div className="p-grid">
          {/* <div className="p-col-12">
            <label htmlFor="tasTipoId">Id</label>
            <InputText
              id="tasTipoId"
              value={appTipoTasa.tasTipoId}
              onChange={(e) => {
                setAppTipoTasa({ ...appTipoTasa, tasTipoId: e.target.value })
              }}
            />
          </div> */}
          <div className="p-field p-col-12">
            <label htmlFor="tasTipoNombre">Nombre</label>
            <InputText
              id="tasTipoNombre"
              value={appTipoTasa.tasTipoNombre}
              onChange={(e) => {
                setAppTipoTasa({
                  ...appTipoTasa,
                  tasTipoNombre: e.target.value,
                });
              }}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
