import React, { useState, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
//Servicios
import AppTipoCuentaBancariaService from "../../service/AppServices/AppTipoCuentaBancariaService";

//componetes
import { AppBanco } from "../AppPages/AppBanco/AppBanco";

//funciones
import onChangeObjDropDawn from "../../utilities/onChangeObjDropDawn";
import onChajeObjN from "../../utilities/onChajeObjN";
export const CliAsociadoNuevaCuentaBan = ({ cuentaBan, setCuentaBan,submitted }) => {

  const [tipoCuentas, setTipoCuentas] = useState([]);
  const [selecBanco, setSelecBanco] = useState(false);
  useEffect(() => {
    const appTipoCuentaBancariaService = new AppTipoCuentaBancariaService();
    appTipoCuentaBancariaService.findAll().then((response) => {
      setTipoCuentas(response);
    });
  }, []);

  function buttons() {
    return (
      <>
        <Button
          className="p-button p-button-danger "
          icon="pi pi-times"
          label="Cancelar"
          onClick={() => setSelecBanco(false)}
        />
      </>
    );
  }

  function renderForm() {
    return (
      <div className="card">
        <div className="p-grid p-formgrid">
          <div className="p-fluid p-col-12 p-lg-6 p-md-6">
            <label>Banco</label>
            <div className="p-inputgroup">
              <InputText
                disabled={true}
                value={cuentaBan?.appBanco?.banNombre}
                placeholder="Buscar.."
              />
              <Button
                icon="pi pi-search"
                className="p-button-info"
                onClick={() => setSelecBanco(true)}
              />
            </div>
            {/* <Dropdown 
        options={bancos}
        optionLabel="banNombre"
        placeholder="Selecciione..."
        virtualScrollerOptions={{ itemSize: 1 }}
        
        /> */}
          </div>
          <div className="p-fluid p-col-12 p-lg-6 p-md-6">
            <label>Tipo de cuenta</label>
            <Dropdown
              value={cuentaBan?.appTipoCuentaBancaria}
              options={tipoCuentas}
              optionLabel="tipCtaBanDescripcion"
              placeholder="Seleccione..."
              onChange={(e) =>
                onChangeObjDropDawn(
                  e,
                  "appTipoCuentaBancaria",
                  e.target.value.tipCtaBanId,
                  "ctaBanTipCtaBanId",
                  cuentaBan,
                  setCuentaBan
                )
              }
            />
          </div>
          <div className="p-fluid p-col-12 p-lg-6 p-md-6">
            <label>Nombre a quien pertenece</label>
            <InputText
              placeholder="Nombre de la persona"
              value={cuentaBan?.ctaBanNombreCuenta}
              onChange={(e) =>
                onChajeObjN(e, "ctaBanNombreCuenta", cuentaBan, setCuentaBan)
              }
            />
          </div>
          <div className="p-fluid p-col-12 p-lg-6 p-md-6">
            <label>Numero de cuenta</label>
            <InputText
              placeholder="Numero de cuenta"
              value={cuentaBan?.ctaBanNumeroCuenta}
              onChange={(e) =>
                onChajeObjN(e, "ctaBanNumeroCuenta", cuentaBan, setCuentaBan)
              }
            />
          </div>
          <div className="p-fluid p-col-12 ">
            <label>Detalle de la cuenta</label>
            <InputTextarea
              rows={3}
              placeholder="Detalle de la cuenta"
              value={cuentaBan?.ctaBanDetalle}
              onChange={(e) =>
                onChajeObjN(e, "ctaBanDetalle", cuentaBan, setCuentaBan)
              }
            />
          </div>
        </div>
      </div>
    );
  }
  return (
    <>
      {renderForm()}
      <Dialog
        visible={selecBanco}
        onHide={() => setSelecBanco(false)}
        header="Bancos disponibles"
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={buttons}
      >
        <AppBanco
          setSelecBanco={setSelecBanco}
          cuentaBan={cuentaBan}
          setCuentaBan={setCuentaBan}
          select={"S"}
          par1={"appBanco"}
          par2={"ctaBanBanId"}
          cantPar={2}
        />
      </Dialog>
    </>
  );
};
