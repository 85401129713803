import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";

//Import components
import { Dropdown } from "primereact/dropdown";

// Import Services
import CliContactoService from "../../service/CliServices/CliContactoService";
import AppTipoContactoService from "../../service/AppServices/AppTipoContactoService";
import classNames from "classnames";

export const CliAsociadoContactoComponent = (props) => {
  let emptyAsociado = {
    actEcoId: 0,
    appActividadEconomica: {
      actEcoDescripcion: "",
      actEcoId: 0,
    },
    appCondicionMigratoria: {
      conMigDescripcion: "",
      conMigId: 0,
    },
    appEstadoCivil: {
      estCivDescripcion: "",
      estCivId: 0,
    },
    appMediosComunicacion: {
      medComDescripcion: "",
      medComId: 0,
      medComLogo: "",
    },
    appProfesionOficio: {
      ofiDescripcion: "",
      ofiId: 0,
    },
    appSexo: {
      sexDescripcion: "",
      sexId: 0,
    },
    appSucursal: {
      appEmpresa: {
        empGerente: "",
        empId: 0,
        empNombre: "",
      },
      sucDireccion: "",
      sucEmpId: 0,
      sucGerente: "",
      sucId: 0,
      sucNombre: "",
    },
    appTipoIdentificacion: {
      ideTipoDescripcion: "",
      ideTipoFormato: "",
      ideTipoId: 0,
    },
    appTipoPersona: {
      perTipoDescripcion: "",
      perTipoId: 0,
    },
    asoActuaNombrePropio: 0,
    asoAfectoImpuesto: 0,
    asoApellidoCasada: "",
    asoCPE: 0,
    asoCalidadActua: "",
    asoEmpleado: 0,
    asoEstado: "",
    asoFechaConstitucion: "",
    asoFechaInactivacion: "",
    asoFechaIngreso: "",
    asoFechaModificacion: "",
    asoFechaNacimiento: "",
    asoFechaVenceIdentificacion: "",
    asoFirma: "",
    asoFoto: "",
    asoId: 0,
    asoIdReferencia: "",
    asoIdRepresentante: "",
    asoListaNegra: 0,
    asoListaNegraReferencia: "",
    asoMensajeAdvertencia: "",
    asoNit: "",
    asoNombreJuridico: "",
    asoNumDocIdentificacion: "",
    asoOtraCondicionMigra: "",
    asoOtroNombre: "",
    asoPrimerApellido: "",
    asoPrimerNombre: "",
    asoPropositoRelacion: "",
    asoRegistroMercantil: "",
    asoRepresentanteLegal: "",
    asoSegundoApellido: "",
    asoSegundoNombre: "",
    asoUsaBancoCoop: "",
    conMigId: 0,
    estCivId: 0,
    ideTipoId: 0,
    medComId: 0,
    ofiId: 0,
    perTipoId: 0,
    sexId: 0,
    sucId: 0,
    usuUsuarioIngresa: "",
    usuUsuarioModifica: "",
    usuUsuarioPromotor: "",
  };

  let emptyCliContacto = {
    conId: 1,
    conDescripcion: "",
    asoId: 2724,
    conTipoId: 1,
    appTipoContacto: {
      conTipoId: 1,
      conTipoDescripcion: "",
      conTipoLogo: "",
    },
  };

  // let emptyAppTipoContacto = {
  //   dirTipoId: 0,
  //   dirTipoNombre: "",
  // };

  const [asociado, setAsociado] = useState(emptyAsociado);
  const [cliContacto, setCliContacto] = useState([]);
  const [cliContactos, setCliContactos] = useState([]);

  const [appTipoContactos, setAppTipoContactos] =
    useState([]);

  const [cliContactoDialog, setCliContactoDialog] = useState(false);
  const [deleteCliContactoDialog, setDeleteCliContactoDialog] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);

  useEffect(() => {
    setAsociado(props.asociado);
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const cliContactoService = new CliContactoService();
    cliContactoService.findAllByAsoId(props.asociado.asoId).then((data) => {
      setCliContactos(data);
    });

    const appTipoContactoService = new AppTipoContactoService();
    appTipoContactoService.findAll().then((data) => setAppTipoContactos(data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const codeBodyTemplate = (rowData) => {
    return <>{rowData.conId}</>;
  };

  const contactoBodyTemplate = (rowData) => {
    return <>{rowData.conDescripcion}</>;
  };

  const tipoContactoBodyTemplate = (rowData) => {
    return <>{rowData.appTipoContacto.conTipoDescripcion}</>;
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _cliContacto = { ...cliContacto };
    _cliContacto[`${name}`] = val;
    setCliContacto(_cliContacto);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _cliContacto = { ...cliContacto };
    _cliContacto[`${name}`] = val;
    setCliContacto(_cliContacto);

    //Set simple data
    _cliContacto[`${name2}`] = val1;
    setCliContacto(_cliContacto);
  };

  const saveCliContacto = () => {
    setSubmitted(true);
if(cliContacto?.conTipoId !== 0 && cliContacto?.conDescripcion ){

  const cliContactoService = new CliContactoService();
  let _cliContacto = { ...cliContacto };
  _cliContacto[`${"asoId"}`] = asociado.asoId;
    cliContactoService.save(_cliContacto).then((data) => {
      cliContactoService.findAllByAsoId(props.asociado.asoId).then((data) => {
        setCliContactos(data);
      });
      toast.current.show({
        severity: "success",
        summary: "¡Exitoso!",
        detail:_cliContacto.conId !== null || cliContacto.conId !== 0 ?  "Contacto Actualizado" : "Contacto Creado",
        life: 5000,
      });
    });

  // cliContactoService.findAllByAsoId(props.asociado.asoId).then((data) => {
  //   setCliContactos(data);
  // });
  setCliContactoDialog(false);
}
   
  };

  //Borramos un Contacto del cliente
  const deleteCliContacto = () => {
    setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const cliContactoService = new CliContactoService();
    //Enviamos los datos al servicio correspondiente
    cliContactoService.delete(cliContacto).then((data) => {
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      toast.current.show({
        severity: "success",
        summary: "¡Éxitoso!",
        detail: "Contacto eliminado",
        life: 3000,
      });

      cliContactoService.findAllByAsoId(props.asociado.asoId).then((data) => {
        setCliContactos(data);
      });
    });

    cliContactoService.findAllByAsoId(props.asociado.asoId).then((data) => {
      setCliContactos(data);
    });
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteCliContactoDialog(false);
    //Metemos al state el all de los sexos del sistemas despues de aver eliminado
    setCliContacto(emptyCliContacto);
  };

  const editCliContacto = (cliContacto) => {
    setCliContacto({ ...cliContacto });
    setCliContactoDialog(true);
  };

  const hideDialog = () => {
    setSubmitted(false);
    setCliContactoDialog(false);
  };

  const hideDeleteCliContactoDialog = () => {
    setDeleteCliContactoDialog(false);
  };

  //Mensaje de confirmación de eliminación un registro
  const confirmDeleteCliContacto = (cliContacto) => {
    setCliContacto(cliContacto);
    setDeleteCliContactoDialog(true);
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editCliContacto(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteCliContacto(rowData)}
        />
      </div>
    );
  };

  const deleteCliContactoDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCliContactoDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteCliContacto} />
    </>
  );

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-danger"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveCliContacto} />
    </>
  );

  return (
    <div className="p-grid" disabled="disabled">
      <Toast ref={toast} />
      <div className="p-col-12 p-md-12" disabled="disabled">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          tooltip="Nueva Dirección"
          onClick={() => editCliContacto()}
        />
        <div className="p-col-12 p-md-12">
          <DataTable
            value={cliContactos}
            rowHover
            className="datatable-responsive"
            emptyMessage="No se encontraron contactos."
          >
            <Column
              field="dirId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="appTipoContacto"
              header="Tipo Contacto"
              sortable
              body={tipoContactoBodyTemplate}
            ></Column>
            <Column
              field="dirDescripcion"
              header="Dirección"
              sortable
              body={contactoBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>
          <Dialog
            visible={cliContactoDialog}
            style={{ width: "50vw" }}
            header="Datos del Contacto"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col">
                  <label htmlFor="state">Tipo Contacto</label>
                  <Dropdown
                    id="state"
                    key={cliContacto.asoId}
                    value={cliContacto.appTipoContacto}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appTipoContacto",
                        e.target.value.conTipoId,
                        "conTipoId"
                      );
                    }}
                    options={appTipoContactos}
                    optionLabel="conTipoDescripcion"
                    placeholder="Seleccione..."
                    required
                    className={classNames({
                      "p-invalid": submitted && !cliContacto?.conTipoId
                    }) }
                  ></Dropdown>
                       {submitted && !cliContacto?.conTipoId && (
                <small className="p-invalid">Tipo contacto reqierodo.</small>
              )}
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-12">
                  <label htmlFor="address">
                    Ingrese {cliContacto?.appTipoContacto?.conTipoDescripcion}:
                  </label>
                  <InputTextarea
                    rows={1}
                    cols={30}
                    value={cliContacto?.conDescripcion}
                    onChange={(e) => onInputChange(e, "conDescripcion")}
                    className={classNames({
                      "p-invalid": submitted && !cliContacto?.conDescripcion
                    }) }
                  />
                             {submitted && !cliContacto?.conDescripcion && (
                <small className="p-invalid">Descripcion reqierodo.</small>
              )}
                </div>
              </div>
            </>
          </Dialog>
          <Dialog
            visible={deleteCliContactoDialog}
            style={{ width: "450px" }}
            header="Confirmar"
            modal
            footer={deleteCliContactoDialogFooter}
            onHide={hideDeleteCliContactoDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {cliContacto && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{cliContacto.conDescripcion}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CliAsociadoContactoComponent;
