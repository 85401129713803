import React, { useEffect, useState, useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Pagination } from "../../../../components/Pagination";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
import { Toast } from "primereact/toast";
//Services
import AppPreEstadoService from "../../../../service/AppServices/AppPreEstadoService";

//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { NuevoEstado } from "./NuevoEstado";

export const Estado = () => {
  const toast = useRef();
  const message = useRef();
  let EstadoEmty = {
    preEstCambioEstado: 0,
    preEstCuota: 0,
    preEstDescripcion: "",
    preEstId: 0,
    preEstIdentificador: 0,
  };

  let FiltroEmpty = {
    descripcion: "",
  };

  const [preEstado, setPreEstado] = useState(EstadoEmty);
  const [preEstados, setPreEstados] = useState([]);
  const [filtro, setFiltro] = useState(FiltroEmpty);
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(0);
  const [firstPage, setFirstPage] = useState(true);
  const [lastPage, setLastPage] = useState(false);
  const [nuevoEstado, setNuevoEstado] = useState(false);
  const [estado, setEstado] = useState(false);
  const [eliminar, setEliminar] = useState(false);
  const [summited, setSummited] = useState(false);
  useEffect(() => {
    const preEstadoSerivce = new AppPreEstadoService();
    preEstadoSerivce
      .findByPage(pageNumber, filtro.descripcion)
      .then((response) => {

        setPreEstados(response?.content);
        setTotalPages(response?.totalPages);
        setPageNumber(response?.number);
        setFirstPage(response?.first);
        setLastPage(response?.last);
      });
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [estado, pageNumber]);

  const paginatioTable = (
    <>
      <Pagination
        totalPages={totalPages}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        firstPage={firstPage}
        lastPage={lastPage}
      />
    </>
  );

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-rounded p-button-warning mr-2 p-mr-2"
          icon="pi pi-eye"
          onClick={() => {setNuevoEstado(true); setPreEstado(rowData)}}
        />
        <Button
          className="p-button-rounded p-button-danger mr-2 "
          icon="pi pi-trash"
          onClick={() => {setPreEstado(rowData); setEliminar(true)}}
        />
      </>
    );
  }

  const renderTable = (
    <>
      <DataTable
        emptyMessage="No se en contraron datos"
        value={preEstados}
        footer={paginatioTable}
      >
        <Column header="Descripcion" field="preEstDescripcion" />
        <Column header="Cantidad de cuota" field="preEstCuota" />
        <Column header="Cambio de estado" body={cambioEstado} />
        <Column header="Acciones" body={acciones} />
      </DataTable>
    </>
  );
  function cambioEstado(rowData) {
    return <>{rowData.preEstCambioEstado === 1 ? "AUTOMATICO" : "MANUAL"}</>;
  }
  const left = (
    <div onKeyDown={(e) => fil(e)}>
      <span className="p-float-label p-mt-2">
        <InputText
          value={filtro.descripcion}
          type={"search"}
          className="p-inputtext-sm"
          onChange={(e) => onChajeObjN(e, "descripcion", filtro, setFiltro)}
        />
        <label>Descripcion</label>
      </span>
    </div>
  );
  function right() {
    return (
      <>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-button-sm"
          onClick={() => {setNuevoEstado(true); setPreEstado(EstadoEmty)}}
        />
      </>
    );
  }

  const fil = (evt) => {
    var e = evt;
    var charCode = e.which || e.keyCode;
    if (charCode === 9 || charCode === 13) {
      const preEstadoSerivce = new AppPreEstadoService();
      preEstadoSerivce
        .findByPage(pageNumber, filtro.descripcion)
        .then((response) => {

          setPreEstados(response?.content);
          setTotalPages(response?.totalPages);
          setPageNumber(response?.number);
          setFirstPage(response?.first);
          setLastPage(response?.last);
          if (filtro.descripcion !== "") {
            setPageNumber(0);
          }
        });
    }
  };

  function footer() {
    return (
      <>
        <Button
          icon="pi pi-times"
          className="p-button-danger p-mr-2 "
          onClick={() => setNuevoEstado(false)}
          label="Cancelar"
        />
        <Button
          icon="pi pi-save"
          className="p-button-success"
          label="Guardar"
          onClick={() => save()}
        />
      </>
    );
  }

  function save() {
    if (
      preEstado.preEstCambioEstado &&
      preEstado.preEstIdentificador !== 0 &&
      preEstado.preEstDescripcion !== "" &&
      preEstado.preEstCuota !== undefined
    ) {
      const estadoService = new AppPreEstadoService();
      estadoService.save(preEstado).then(() => {
        setEstado(!estado);
        setNuevoEstado(false);
        toast.current.show({
            severity: "success",
            summary: "Tarea realizada con exito",
            detail: `Estado de credito creado con exito`,
            life: 4000,
          });
      });
    } else {
      setSummited(true);
      message.current.show({
        severity: "warn",
        summary: "error",
        content: "Verifique que los campos necesarios no esten vacios",
        life: 4000,
      });
      setInterval(() => setSummited(false), 4000);
    }
  }

  function deletePreEstado() {
    const estadoService = new AppPreEstadoService();
    estadoService.delete(preEstado.preEstId).then(() => {
      setEstado(!estado);
      setEliminar(false);
      toast.current.show({
        severity: "success",
        summary: "Tarea realizada con exito",
        detail: `Estado de credito eliminada con exito`,
        life: 4000,
      });
    });
  }

  function deleteFooter() {
    return (
      <>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          className="p-button-text p-button-danger"
          onClick={() => setEliminar(false)}
        />

        <Button
          label="Confirmar"
          icon="pi pi-check"
          onClick={() => deletePreEstado()}
        />
      </>
    );
  }

  return (
    <div className="card">
      <Toolbar className="p-mb-4" left={left} right={right} />
      {renderTable}
      <Dialog
        header={preEstado.preEstId === 0 ? "Nuevo Estado" : "Editar Estado"}
        visible={nuevoEstado}
        onHide={() => setNuevoEstado(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "40vw" }}
        footer={footer}
      >
        <NuevoEstado
          preEstado={preEstado}
          setPreEstado={setPreEstado}
          summited={summited}
        />
        <Messages ref={message} />
      </Dialog>
      <Dialog
        visible={eliminar}
        onHide={() => setEliminar(false)}
        header="Mensaje del sistema"
        modal
        style={{ width: "450px" }}
        footer={deleteFooter}
      >
        <div className="p-d-flex p-ai-center p-jc-center">
          <i
            className="pi pi-exclamation-triangle p-mr-3"
            style={{ fontSize: "2rem" }}
          />
          <span>Confirme si desea eliminar este estado.</span>
        </div>
      </Dialog>
      <Toast ref={toast} />
    </div>
  );
};
