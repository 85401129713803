import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState, useRef } from "react";
import { Button } from "primereact/button";
import { Messages } from "primereact/messages";
//servicios
import AppDepartamentoService from "../../../service/AppServices/AppDepartamentoService";
import AppMunicipioService from "../../../service/AppServices/AppMunicipioService";
//import AppPaisService from "../../../service/AppServices/AppPaisService";

//fucniones\

export const NuevoMunicipio = ({
  municipio,
  setMunicipio,
  setNuevoMunicipio,
  estado,
  setEstado,
  toast

}) => {
  const message = useRef();
  const [departamentos, setDepartamentos] = useState([]);
  const [ setSubmitted] = useState(false);
  useEffect(() => {
    const departamentoService = new AppDepartamentoService();
    departamentoService.findAll().then((res) => {
      setDepartamentos(res);
    });
  }, []);

  const save = () => {
    if(municipio?.munNombre !== "" && municipio?.munCodigo && municipio?.depId !== 0){
        const appMunicipioService = new AppMunicipioService();
        appMunicipioService.save(municipio).then((res) => {
      
          setNuevoMunicipio(false);
          setEstado(!estado);
          toast.current.show({
            severity: "success",
            summary: "Tarea Realizada con exito",
            detail: `${
                municipio.munId === 0
                ? "Municipio creado"
                : "Municipio actualizado"
            }`,
            life: 4000,
          });
        });
    }else{
        setSubmitted(true);
        message.current.show({
          severity: "warn",
          summary: "error",
          content: "Verifique que los campos necesarios no esten vacios",
          life: 4000,
        });
        setInterval(() => {
          setSubmitted(false);
        }, 4000);
    }
  };


  return (
    <>
      <div className="card">
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Nombre</label>
            <InputText
              placeholder="Nombre"
              value={municipio?.munNombre}
              onChange={(e) =>
                setMunicipio({
                  ...municipio,
                  munNombre: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
          <div className="p-col">
            <label>Codigo</label>
            <InputText
              value={municipio?.munCodigo}
              onChange={(e) =>
                setMunicipio({
                  ...municipio,
                  munCodigo: e.target.value,
                })
              }
              type="number"
            />
          </div>
        </div>
        <div className="p-fluid p-grid">
          <div className="p-col">
            <label>Departamento</label>
            <Dropdown
              value={municipio.appDepartamento}
              placeholder="Seleccione"
              options={departamentos}
              optionLabel="depNombre"
              onChange={(e) =>
                setMunicipio({
                  ...municipio,
                  appDepartamento: e.target.value,
                  depCodigo: e.target.value.depCodigo,
                  depId: e.target.value.depId,
                })
              }
            />
          </div>
        </div>
      </div>

      <Messages ref={message} />
      <div style={{ alignItems: "right", textAlign: "right" }}>
        <Button
          style={{ width: "100px" }}
          className="p-button-danger p-mr-2 "
          icon="pi pi-times"
          label="Cerrar"
          onClick={() => setNuevoMunicipio(false)}
        />
        <Button
          style={{ width: "100px" }}
          className=" p-button-info p-mr-2 "
          icon="pi pi-save"
          label="Guardar"
          onClick={() => save()}
        />
      </div>
    </>
  );
};
