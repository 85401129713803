import { PickList } from "primereact/picklist";
import React, { useState, useEffect } from "react";
//Servicios
import AppPreTipoCompromisoService from "../../../../service/AppServices/AppPreTipoCompromisoService";
import AppPreTipoCompromisoAsignadoService from "../../../../service/AppServices/AppPreTipoCompromisoAsignadoService";

export const AsiganarCompromiso = ({ subProducto, toast }) => {
  let compromisoAsignadoEmpty = {
    preTipComAsiComId: 0,
    preTipComAsiId: 0,
    preTipComAsiPreSubId: subProducto.preSubId,
  };

  const [compromisoAsignado] = useState(
    compromisoAsignadoEmpty
  );
  const [source, setSource] = useState([]);
  const [target, setTarget] = useState([]);

  useEffect(() => {
    refreshAuthorities();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshAuthorities = () => {
    const apprTipoCompromiso = new AppPreTipoCompromisoService();
    const appPreTipoCompromisoAsignadoService =
      new AppPreTipoCompromisoAsignadoService();
    apprTipoCompromiso.findAllSubId(subProducto.preSubId).then((res) => {
      setSource(res);
    });
    appPreTipoCompromisoAsignadoService
      .findBySubProducto(subProducto.preSubId)
      .then((res) => {
        setTarget(res);
      });
  };
  const itemTemplate = (item) => {
    return (
      <div className="product-item">
        <div className="product-list-detail">
          <span className="product-category">
            {item?.preTipoComDescripcion ||
              item?.appPreTipoCompromiso?.preTipoComDescripcion}
          </span>
        </div>
      </div>
    );
  };

  const addNewCom = (data) => {
    const appPreTipoCompromisoAsignadoService =
      new AppPreTipoCompromisoAsignadoService();
    var all = [];

    data.forEach((item) => {
      const _compromisoAsignado = { ...compromisoAsignado };
      _compromisoAsignado["preTipComAsiComId"] = item.preTipComId;
      all.push(_compromisoAsignado);
    });

    appPreTipoCompromisoAsignadoService.saveAll(all).then((res) => {
      refreshAuthorities();
      if (all.length === 1) {
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: "Compromiso agregado ",
          life: 4000,
        });
      } else {
        toast.current.show({
          severity: "success",
          summary: "Tarea realizada con exito",
          detail: "Compromisos agregados ",
          life: 4000,
        });
      }
    });
  };

  const removeComAll = (data) => {
    const appPreTipoCompromisoAsignadoService =
      new AppPreTipoCompromisoAsignadoService();
    appPreTipoCompromisoAsignadoService.deleteAll(data).then(() => {
      toast.current.show({
        severity: "error",
        summary: "Tarea realizada con exito",
        detail: "Compromisos elimidos ",
        life: 4000,
      });
      refreshAuthorities();
    });
  };
  const removeCom = (data) => {
    const appPreTipoCompromisoAsignadoService =
      new AppPreTipoCompromisoAsignadoService();
    appPreTipoCompromisoAsignadoService.delete(data.preTipComAsiId).then(() => {
      refreshAuthorities();
      toast.current.show({
        severity: "error",
        summary: "Tarea realizada con exito",
        detail: "Compromiso elimido ",
        life: 4000,
      });
    });
  };
  return (
    <div className="picklist-demo">
      <PickList
        source={source}
        target={target}
        itemTemplate={itemTemplate}
        sourceHeader="No Asignados"
        targetHeader="Asignados"
        sourceStyle={{ height: "700px", width: "30vh" }}
        targetStyle={{ height: "700px", width: "40vh" }}
        showSourceControls={false}
        showTargetControls={false}
        onMoveToTarget={(e) => addNewCom(e.value)}
        onMoveToSource={(e) => removeCom(e.value[0])}
        onMoveAllToTarget={(e) => addNewCom(e.value)}
        onMoveAllToSource={(e) => removeComAll(e.value)}
      />
    </div>
  );
};
