import React, { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import classNames from 'classnames'
import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Toast } from 'primereact/toast'
import { Button } from 'primereact/button'
import { Toolbar } from 'primereact/toolbar'
import { Dialog } from 'primereact/dialog'
import { InputText } from 'primereact/inputtext'
import AppRoleService from '../../../../service/AppServices/AppRoleService'
import { TabView, TabPanel } from 'primereact/tabview'

import { ViewAuthorityRole } from '../ViewAuthorityRole'
import { Pagination } from '../../../../components/Pagination'

export const ListRole = () => {
  const history = useHistory()
  let location = useLocation()
  const previusObjectURL = location.state?.from

  let emptyRole = {
    rolId: 0,
    rolName: '',
  }

  const [appRoles, setAppRoles] = useState(null)
  const [appRoleDialog, setAppRoleDialog] = useState(false)
  const [appAssingRoleDialog, setAppAssignRoleDialog] = useState(false)
  const [deleteRoleDialog, setDeleteRoleDialog] = useState(false)
  const [appRole, setAppRole] = useState(emptyRole)
  const [selectedRoles, setSelectedRoles] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const toast = useRef(null)
  const dt = useRef(null)

  //Creamos el state para la busqueda
  const [roleFind, setRoleFind] = useState('')

  //States para la paginacion
  const [sizeForPage, setSizeForPage] = useState(2)
  const [totalElements, setTotalElements] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [pageNumber, setPageNumber] = useState(0)
  const [firstPage, setFirstPage] = useState(true)
  const [lastPage, setLastPage] = useState(false)

  useEffect(() => {
    const appRoleService = new AppRoleService()
    appRoleService.findByName(roleFind).then((response) => {
      if (response.status === 200) {
        setAppRoles(response.data.content)
        setSizeForPage(response.data.size)
        setTotalElements(response.data.totalElements)
        setTotalPages(response.data.totalPages)
        setPageNumber(response.data.number)
        setFirstPage(response.data.first)
        setLastPage(response.data.last)
      } else if (response.status === 403) {
        history.push(previusObjectURL || '/access')
      } else {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: response.message,
        })
      }
    })
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleFind])

  const openNew = () => {
    setAppRole(emptyRole)
    setSubmitted(false)
    setAppRoleDialog(true)
  }

  const openDialogAssignAuthority = (rowData) => {
    setAppRole(rowData)
    setSubmitted(false)
    setAppAssignRoleDialog(true)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setAppRoleDialog(false)
    setAppAssignRoleDialog(false)
  }

  const hideDeleteAuthorityDialog = () => {
    setDeleteRoleDialog(false)
  }

  //Guardamos un registro
  const saveRole = () => {
    setSubmitted(true)

    const appRoleService = new AppRoleService()
    if (appRole.rolId !== null) {
      appRoleService.save(appRole).then((data) => {
        appRoleService.findByName(roleFind).then((data) => {
          console.log(data)
          setAppRoles(data.data.content)
          setSizeForPage(data.data.size)
          setTotalElements(data.data.totalElements)
          setTotalPages(data.data.totalPages)
          setPageNumber(data.data.number)
          setFirstPage(data.data.first)
          setLastPage(data.data.last)
        })
        toast.current.show({
          severity: 'success',
          summary: '¡Exitoso!',
          detail: 'Rol Actualizado',
          life: 3000,
        })
      })
    } else {
      appRoleService.save(appRole).then((data) => {
        appRoleService.findByName(roleFind).then((data) => {
          console.log(data)
          setAppRoles(data.data.content)
          setSizeForPage(data.data.size)
          setTotalElements(data.data.totalElements)
          setTotalPages(data.data.totalPages)
          setPageNumber(data.data.number)
          setFirstPage(data.data.first)
          setLastPage(data.data.last)
        })

        toast.current.show({
          severity: 'success',
          summary: '¡Exitoso!',
          detail: 'Rol Creado',
          life: 5000,
        })
      })
    }

    //Llamamos el metodo findByName para que la busqueda actual no cambie al guardar los datos
    findByName()
    //Cuerramos el dialogo de nueva appRole Economica
    setAppRoleDialog(false)
    setAppRole(emptyRole)
    //}
  }

  //Editamos un registro
  const editRole = (appRole) => {
    setAppRole({ ...appRole })
    setAppRoleDialog(true)
  }

  //Mensaje de confirmación de un registro
  const confirmDeleteRole = (product) => {
    setAppRole(product)
    setDeleteRoleDialog(true)
  }

  //Borramos un Género
  const deleteRole = () => {
    setSubmitted(true)
    //Cramos una instancia de nuestro servicio
    const appRoleService = new AppRoleService()
    //Enviamos los datos al servicio correspondiente
    appRoleService.delete(appRole.rolId).then((data) => {
      appRoleService.findByName(roleFind).then((data) => {
        console.log(data)
        setAppRoles(data.data.content)
        setSizeForPage(data.data.size)
        setTotalElements(data.data.totalElements)
        setTotalPages(data.data.totalPages)
        setPageNumber(data.data.number)
        setFirstPage(data.data.first)
        setLastPage(data.data.last)
      })
      //Si todo esta bien, mostramos el Toast(Mensaje) al usuario de confirmacion
      console.log(data)
      toast.current.show({
        severity: 'success',
        summary: '¡Éxitoso!',
        detail: 'Rol eliminado',
      })
    })
    //Mandamos el argumento false para que se cierre el modal de confirmacion de eliminación
    setDeleteRoleDialog(false)
    //Metemos al state el all de los appRoles del sistemas despues de aver eliminado
    setAppRole(emptyRole)
  }

  const findByName = () => {
    //Cramos una instancia de nuestro servicio
    const appRoleService = new AppRoleService()
    //Enviamos los datos al servicio correspondiente
    appRoleService.findByName(roleFind).then((response) => {
      console.log(response)
      setAppRoles(response.data.content)
    })
  }

  // const findByNameAndPage = (pageNumber) => {
  //   //Cramos una instancia de nuestro servicio
  //   const appRoleService = new AppRoleService()
  //   //Enviamos los datos al servicio correspondiente
  //   appRoleService.findByName(roleFind, pageNumber).then((response) => {
  //     console.log(response)
  //     setAppRoles(response.data.content)
  //     setPageNumber(response.data.number)
  //     setFirstPage(response.data.first)
  //     setLastPage(response.data.last)
  //   })
  // }

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || ''
    let _appRole = { ...appRole }
    _appRole[`${name}`] = val
    setAppRole(_appRole)
  }

  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button
          label="Nuevo"
          icon="pi pi-plus"
          className="p-button-success p-mr-2 p-mb-2"
          onClick={openNew}
        />
      </React.Fragment>
    )
  }

  const rightToolbarTemplate = () => {
    return (
      <React.Fragment>
        {/* //Agregar contenido al lado derecho */}
      </React.Fragment>
    )
  }

  // Se configura el nombre de las columnas de la tabla y el dato correspondiente a cada columna
  // segun el formato JSON del API SGC
  const codeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Código</span>
        {rowData.rolId}
      </>
    )
  }

  const nameBodyTemplate = (rowData) => {
    return (
      <>
        <span className="p-column-title">Descripción</span>
        {rowData.rolName}
      </>
    )
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-pencil"
          tooltip="Editar Rol"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editRole(rowData)}
        />
        <Button
          icon="pi pi-flag"
          tooltip="Ver permisos"
          className="p-button-rounded p-button-info p-mr-2"
          onClick={() => openDialogAssignAuthority(rowData)}
          // onClick={() => confirmDeleteAuthority(rowData)}
        />
        <Button
          icon="pi pi-trash"
          tooltip="Eliminar Rol"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteRole(rowData)}
        />
      </div>
    )
  }

  const header = (
    <div className="table-header">
      <h5 className="p-m-0">Gestión de Roles</h5>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText
          type="search"
          onInput={(e) => setRoleFind(e.target.value)}
          placeholder="Buscar..."
        />
      </span>
    </div>
  )

  const productDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDialog}
      />
      <Button label="Guardar" icon="pi pi-check" onClick={saveRole} />
    </>
  )
  const deleteProductDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteAuthorityDialog}
      />
      <Button label="Si" icon="pi pi-check" onClick={deleteRole} />
    </>
  )

  function renderPaginacion() {
    return (
      <>
        <Pagination
          totalPages={totalPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          firstPage={firstPage}
          lastPage={lastPage}
        />
      </>
    );
  }


  return (
    <div className="p-grid crud-demo">
      <div className="p-col-12">
        <div className="card">
          <Toast ref={toast} />
          <Toolbar
            className="p-mb-4"
            left={leftToolbarTemplate}
            right={rightToolbarTemplate}
          ></Toolbar>

          {/* En DataTable agregamos el array de JSON de los resultados del API*/}
          <DataTable
            ref={dt}
            value={appRoles}
            selection={selectedRoles}
            onSelectionChange={(e) => setSelectedRoles(e.value)}
            dataKey="paiId"
            rows={sizeForPage}
            className="datatable-responsive"
            totalRecords={totalElements}
            emptyMessage="No se encontraron roles en el sistema."
            header={header}
            footer={renderPaginacion}
          >
            <Column
              field="rolId"
              header="Código"
              sortable
              body={codeBodyTemplate}
            ></Column>
            <Column
              field="rolName"
              header="Nombre"
              sortable
              body={nameBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>

          {/* <div style={{text-align: "center"}}>
            <br />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(0)}
            />
            <Button
              type="button"
              label=""
              icon="pi pi-angle-left"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={firstPage}
              onClick={() => findByNameAndPage(pageNumber - 1)}
            />

            <InputText
              type="text"
              placeholder={
                'Página ' +
                (pageNumber + 1) +
                ' de ' +
                totalPages +
                ' : : elementos ' +
                totalElements
              }
              disabled
              Style="background: transparent; color: #ffff !important; margin-right: 4px; width:18%; text-align:center"
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(pageNumber + 1)}
            />

            <Button
              type="button"
              label=""
              icon="pi pi-angle-double-right"
              Style="background: transparent; color: #6E707A; margin-right: 4px; border: none;"
              disabled={lastPage}
              onClick={() => findByNameAndPage(totalPages - 1)}
            />
          </div> */}

          <Dialog
            visible={appRoleDialog}
            Style={{ width: '450px' }}
            header="Datos del Rol"
            modal
            className="p-fluid"
            footer={productDialogFooter}
            onHide={hideDialog}
          >
            <div className="p-field">
              <label htmlFor="actEcoDescripcion">Nombre</label>
              <InputText
                id="actEcoDescripcion"
                value={appRole.rolName}
                onChange={(e) => onInputChange(e, 'rolName')}
                required
                autoFocus
                className={classNames({
                  'p-invalid': submitted && !appRole.rolName,
                })}
              />
              {submitted && !appRole.rolName && (
                <small className="p-invalid">El nombre es requerido.</small>
              )}
            </div>
          </Dialog>
          {/* Modal para confirmar la eliminación de un registro*/}
          <Dialog
            visible={deleteRoleDialog}
            Style={{ width: '450px' }}
            header="Confirmar"
            modal
            footer={deleteProductDialogFooter}
            onHide={hideDeleteAuthorityDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                Style={{ fontSize: '2rem' }}
              />
              {appRole && (
                <span>
                  ¿Estás seguro de que quieres eliminar <b>{appRole.rolName}</b>
                  ?
                </span>
              )}
            </div>
          </Dialog>

          {/* Start Dialog Asign Authority */}
          <Dialog
            visible={appAssingRoleDialog}
            header="Asignar Autorizaciones"
            modal
            onHide={hideDialog}
            className="p-fluid"
          >
            <TabView>
              <TabPanel header="Asginar Permisos">
                <ViewAuthorityRole rolId={appRole.rolId}></ViewAuthorityRole>
              </TabPanel>
            </TabView>
          </Dialog>
          {/* End Dialog Asign Authority */}
        </div>
      </div>
    </div>
  )
}
