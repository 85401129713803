import { Toolbar } from "primereact/toolbar";
import React, { useEffect, useRef, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { NuevoPlazoMediosElectronicos } from "./NuevoPlazoMediosElectronicos";
//servicio

import PlazoService from "../../../service/TcrService/PlazoService";
import { Toast } from "primereact/toast";
export const PlazoMediosElectronicos = () => {
const toast= useRef()

  let plazoEmpty = {
    plaId: 0,
    plaMeses: 0,
  };

  const [plazo, setPlazo] = useState(plazoEmpty);
  const [plazos, setPlazos] = useState([]);
  const [nuevoPlazo, setNuevoPlazo] = useState(false);
  const [estado, setEstado] = useState(false);

  useEffect(() => {
    const plazoService = new PlazoService();
    plazoService.getAll().then((response) => {
      setPlazos(response);
    
    });
  }, [estado]);

  function acciones(rowData) {
    return (
      <>
        <Button
          className="p-button-warning p-button-rounded p-mr-2"
          icon="pi pi-pencil"
          onClick={() => {setNuevoPlazo(true); setPlazo(rowData)}}
        />
      </>
    );
  }

  const tablePlazo = () => {
    return (
      <>
        <DataTable value={plazos} paginator rows={20}>
          <Column header="Id" field="plaId" />
          <Column header="No. Plazo" field="plaMeses" />
          <Column header="Plazo" body="MESES" />
          <Column header="Plazo" body={acciones} />
        </DataTable>
      </>
    );
  };

  const nuevoPlazoBtn = () => {
    return (
      <>
        <Button
          label="Nuevo plazo"
          icon="pi pi-plus"
          className="p-button-success"
          onClick={() => {
            setNuevoPlazo(true);
            setPlazo(plazoEmpty);
          }}
        />
      </>
    );
  };

  return (
    <div className="card">
      <Toolbar className="p-mb-4" right={nuevoPlazoBtn} />

      {tablePlazo()}

      <Dialog
        visible={nuevoPlazo}
        onHide={() => setNuevoPlazo(false)}
        breakpoints={{ "960px": "75vw", "640px": "100vw" }}
        style={{ width: "30vw" }}
        header={"Nuevo plazo"}
      >
        <NuevoPlazoMediosElectronicos
          plazoHooks={plazo}
          setNuevoPlazo={setNuevoPlazo}
          estado={estado}
          setEstado={setEstado}
          toast={toast}
        />
      </Dialog>

      <Toast ref={toast}/>
    </div>
  );
};
