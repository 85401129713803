import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AhoMancomunadoService {
  async save(data) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.post(
        api.baseUrl() + `/ahomancomunado/save`,
        data,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async findByAhoId(ahoId) {
    if (token) {
      jwt = JSON.parse(token);
    }

    var config = {
      method: "get",
      url: api.baseUrl() + `/ahomancomunado/aho/${ahoId || 0}`,
      headers: {
        Authorization: `Bearer ${jwt?.jwt}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios(config);
      return response?.data;
    } catch (err) {
      addressingError(err);
    }
  }

  async delete(manId) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.delete(
        api.baseUrl() + `/ahomancomunado/delete/${manId}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

}
