import axios from "axios";
import Api from "../../Api";
import addressingError from "../../utilities/addressingError";
const token = localStorage.getItem("token");
const api = new Api();
var { jwt } = {};

export default class AppAhoFormaPagoService {
  async findAll(tipo) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/appahoformapago/saldo/${tipo}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }

  //Roles no asignados al usuario
  async findByID(id) {
    if (token) {
      jwt = JSON.parse(token);
    }
    try {
      const response = await axios.get(
        api.baseUrl() + `/appahoformapago/${id}`,
        {
          headers: { Authorization: `Bearer ${jwt.jwt}` },
        }
      );
      return response.data;
    } catch (err) {
      addressingError(err);
    }
  }
}
