import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputTextarea } from "primereact/inputtextarea";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";

import CliReferenciaPersonalService from "../../service/CliServices/CliReferenciaPersonalService";
import AppTipoPersonaService from "../../service/AppServices/AppTipoPersonaService";

export const CliAsociadoReferenciaPersonalComponent = (props) => {
  // let emptyAsociado = {
  //   actEcoId: 0,
  //   appActividadEconomica: {
  //     actEcoDescripcion: "",
  //     actEcoId: 0,
  //   },
  //   appCondicionMigratoria: {
  //     conMigDescripcion: "",
  //     conMigId: 0,
  //   },
  //   appEstadoCivil: {
  //     estCivDescripcion: "",
  //     estCivId: 0,
  //   },
  //   appMediosComunicacion: {
  //     medComDescripcion: "",
  //     medComId: 0,
  //     medComLogo: "",
  //   },
  //   appProfesionOficio: {
  //     ofiDescripcion: "",
  //     ofiId: 0,
  //   },
  //   appSexo: {
  //     sexDescripcion: "",
  //     sexId: 0,
  //   },
  //   appSucursal: {
  //     appEmpresa: {
  //       empGerente: "",
  //       empId: 0,
  //       empNombre: "",
  //     },
  //     sucDireccion: "",
  //     sucEmpId: 0,
  //     sucGerente: "",
  //     sucId: 0,
  //     sucNombre: "",
  //   },
  //   appTipoIdentificacion: {
  //     ideTipoDescripcion: "",
  //     ideTipoFormato: "",
  //     ideTipoId: 0,
  //   },
  //   appTipoPersona: {
  //     perTipoDescripcion: "",
  //     perTipoId: 0,
  //   },
  //   asoActuaNombrePropio: 0,
  //   asoAfectoImpuesto: 0,
  //   asoApellidoCasada: "",
  //   asoCPE: 0,
  //   asoCalidadActua: "",
  //   asoEmpleado: 0,
  //   asoEstado: "",
  //   asoFechaConstitucion: "",
  //   asoFechaInactivacion: "",
  //   asoFechaIngreso: "",
  //   asoFechaModificacion: "",
  //   asoFechaNacimiento: "",
  //   asoFechaVenceIdentificacion: "",
  //   asoFirma: "",
  //   asoFoto: "",
  //   asoId: 0,
  //   asoIdReferencia: "",
  //   asoIdRepresentante: "",
  //   asoListaNegra: 0,
  //   asoListaNegraReferencia: "",
  //   asoMensajeAdvertencia: "",
  //   asoNit: "",
  //   asoNombreJuridico: "",
  //   asoNumDocIdentificacion: "",
  //   asoOtraCondicionMigra: "",
  //   asoOtroNombre: "",
  //   asoPrimerApellido: "",
  //   asoPrimerNombre: "",
  //   asoPropositoRelacion: "",
  //   asoRegistroMercantil: "",
  //   asoRepresentanteLegal: "",
  //   asoSegundoApellido: "",
  //   asoSegundoNombre: "",
  //   asoUsaBancoCoop: "",
  //   conMigId: 0,
  //   estCivId: 0,
  //   ideTipoId: 0,
  //   medComId: 0,
  //   ofiId: 0,
  //   perTipoId: 0,
  //   sexId: 0,
  //   sucId: 0,
  //   usuUsuarioIngresa: "",
  //   usuUsuarioModifica: "",
  //   usuUsuarioPromotor: "",
  // };

  let emptyCliReferenciaPersonal = {
    appTipoPersona: {
      perTipoDescripcion: "",
      perTipoId: 0,
    },
    asoId: 0,
    perTipoId: 0,
    redPerTelefono: "",
    refPerCelular: "",
    refPerCorrelativo: 0,
    refPerCorreo: "",
    refPerDescripcion: "",
    refPerDireccion: "",
    refPerEstatus: "",
    refPerId: 0,
    refPerNombre: "",
    refPerRelacion: "",
    refPersonaCodigoPostal: "",
  };

  // let emptyAppTipoPersona = {
  //   perTipoId: 0,
  //   perTipoDescripcion: "",
  // };

  const toast = useRef(null);
 //const [asociado, setAsociado] = useState(emptyAsociado);
  const [cliReferenciaPersonal, setCliReferenciaPersonal] = useState(
    emptyCliReferenciaPersonal
  );
  const [cliReferenciaPersonales, setCliReferenciaPersonales] = useState(
    []
  );

  const [appTipoPersonas, setAppTipoPersonas] = useState([]);

  const [dropdownItemRelacionReferencia, setDropdownItemRelacionReferencia] =
    useState([]);
  const dropdownItemsRelacionReferencias = [
    { name: "PADRE", code: 1 },
    { name: "MADRE", code: 2 },
    { name: "HIJO(A)", code: 3 },
    { name: "FAMILIAR", code: 4 },
    { name: "AMIGO(A)", code: 5 },
    { name: "OTRO", code: 6 },
  ];
  const [dropdownItemStatusReferencia, setDropdownItemStatusReferencia] =
    useState([]);
  const dropdownItemStatusReferencias = [
    { name: "PENDIENTE", code: "P" },
    { name: "VERIFICADO", code: "S" },
  ];

  //State para los dialogos
  const [cliReferenciaPersonalDialog, setCliReferenciaPersonalDialog] =
    useState(false);
  const [
    deleteCliReferenciaPersonalDialog,
    setDeleteCliReferenciaPersonalDialog,
  ] = useState(false);

  useEffect(() => {
    //setAsociado(props.asociado);
    const cliReferenciaPersonalService = new CliReferenciaPersonalService();
    cliReferenciaPersonalService
      .findAllCliByAsoId(props.asociado.asoId)
      .then((data) => setCliReferenciaPersonales(data));
       // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const appTipoPersonaService = new AppTipoPersonaService();
    appTipoPersonaService.findAll().then((data) => {
      setAppTipoPersonas(data.data);
    });
  }, []);

  //Efecto que me seleccionar el tipo de realcion según el codigo
  useEffect(() => {
    if (cliReferenciaPersonal?.refPerId === 0) {
    } else {
      var item = dropdownItemsRelacionReferencias.find(
        (item) => item.code === cliReferenciaPersonal?.refPerRelacion
      );
      setDropdownItemRelacionReferencia(item);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliReferenciaPersonal?.refPerRelacion]);

  //Efecto que me seleccionar el Estatus según el codigo
  useEffect(() => {
    if (cliReferenciaPersonal?.refPerId === 0) {
    } else {
      var item = dropdownItemStatusReferencias.find(
        (item) => item.code === cliReferenciaPersonal?.refPerEstatus
      );
      setDropdownItemStatusReferencia(item);
    }
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cliReferenciaPersonal?.refPerEstatus]);

  const editCliReferenciaPersonal = (cliReferenciaPersonal) => {
    setCliReferenciaPersonal({ ...cliReferenciaPersonal });
    setCliReferenciaPersonalDialog(true);
  };

  const hideDeleteCliReferenciaPersonalDialog = () => {
    setDeleteCliReferenciaPersonalDialog(false);
  };

  const hideDialog = () => {
    //  setSubmitted(false);
    setCliReferenciaPersonalDialog(false);
  };

  const referenciaPersonalDialogFooter = (
    <>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        className="p-button-danger"
        onClick={hideDialog}
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        onClick={() => saveCliReferenciaPersonal(cliReferenciaPersonal)}
      />
    </>
  );

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _cliReferenciaPersonal = { ...cliReferenciaPersonal };
    _cliReferenciaPersonal[`${name}`] = val;
    setCliReferenciaPersonal(_cliReferenciaPersonal);
  };

  const onInputChangeObject = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    let _cliReferenciaPersonal = { ...cliReferenciaPersonal };
    _cliReferenciaPersonal[`${name}`] = val;
    setCliReferenciaPersonal(_cliReferenciaPersonal);

    //Set simple data
    _cliReferenciaPersonal[`${name2}`] = val1;
    setCliReferenciaPersonal(_cliReferenciaPersonal);
  };

  const onInputChangeObjectRelacionReferencia = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    setDropdownItemRelacionReferencia(val);

    let _cliReferenciaPersonal = { ...cliReferenciaPersonal };
    //Set simple data
    _cliReferenciaPersonal[`${name2}`] = val1;
    setCliReferenciaPersonal(_cliReferenciaPersonal);
  };

  const onInputChangeObjectEstatusReferencia = (e, name, val1, name2) => {
    //Set Object
    const val = (e.target && e.target.value) || "";
    setDropdownItemStatusReferencia(val);

    let _cliReferenciaPersonal = { ...cliReferenciaPersonal };
    //Set simple data
    _cliReferenciaPersonal[`${name2}`] = val1;
    setCliReferenciaPersonal(_cliReferenciaPersonal);
  };

  const saveCliReferenciaPersonal = (cliReferenciaPersonal) => {
    const cliReferenciaPersonalService = new CliReferenciaPersonalService();

    let _cliReferenciaPersonal = { ...cliReferenciaPersonal };
    _cliReferenciaPersonal[`${"asoId"}`] = props.asociado.asoId;
    _cliReferenciaPersonal[`${"refPerCorrelativo"}`] = 1;
    setCliReferenciaPersonal(_cliReferenciaPersonal);
    cliReferenciaPersonalService
      .save(_cliReferenciaPersonal)
      .then((data) => data);

    toast.current.show({
      severity: "success",
      summary: "¡Exitoso!",
      detail:
        "Refrencia Personal Guardado: " + cliReferenciaPersonal.refPerNombre,
      life: 3000,
    });

    cliReferenciaPersonalService
      .findAllCliByAsoId(props.asociado.asoId)
      .then((data) => {
        setCliReferenciaPersonales(data);
      });
    setCliReferenciaPersonalDialog(false);
  };

  //Borramos una referencia personal
  const deleteCliReferenciaPersonal = () => {
    // setSubmitted(true);
    //Cramos una instancia de nuestro servicio
    const cliReferenciaPersonalService = new CliReferenciaPersonalService();
    //Enviamos los datos al servicio correspondiente
    cliReferenciaPersonalService
      .delete(cliReferenciaPersonal)
      .then((data) => { });

    cliReferenciaPersonalService
      .findAllCliByAsoId(props.asociado.asoId)
      .then((data) => {
        setCliReferenciaPersonales(data);
      });

    toast.current.show({
      severity: "success",
      summary: "¡Éxitoso!",
      detail: "Referencia Personal eliminado",
      life: 3000,
    });

    setDeleteCliReferenciaPersonalDialog(false);
    setCliReferenciaPersonal(emptyCliReferenciaPersonal);
  };
  // const idReferenciaBodyTemplate = (rowData) => {
  //   return <>{rowData.refPerId}</>;
  // };
  const relacionReferenciaBodyTemplate = (rowData) => {
    var item = dropdownItemsRelacionReferencias.find(
      (item) => item.code === rowData?.refPerRelacion
    );
    return <>{item?.name}</>;
  };
  const nombreReferenciaBodyTemplate = (rowData) => {
    return <>{rowData.refPerNombre}</>;
  };
  const celularReferenciaBodyTemplate = (rowData) => {
    return <>{rowData.refPerCelular}</>;
  };
  const estatusReferenciaBodyTemplate = (rowData) => {
    return (
      <>
        <Button
          type="button"
          label={rowData.refPerEstatus === "P" ? "PENDIENTE" : "VERIFICADO"}
          className={
            rowData.refPerEstatus === "P"
              ? "p-button-rounded p-button-warning"
              : "p-button-rounded p-button-success"
          }
        ></Button>
      </>
    );
  };

  const confirmDeleteCliReferenciaPersonal = (cliReferenciaPersonal) => {
    setCliReferenciaPersonal(cliReferenciaPersonal);
    setDeleteCliReferenciaPersonalDialog(true);
  };
console.log(cliReferenciaPersonal)
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-eye"
          className="p-button-rounded p-button-success p-mr-2"
          onClick={() => editCliReferenciaPersonal(rowData)}
        />
        <Button
          icon="pi pi-trash"
          className="p-button-rounded p-button-warning"
          onClick={() => confirmDeleteCliReferenciaPersonal(rowData)}
        />
      </div>
    );
  };

  const deleteCliReferenciaPersonalDialogFooter = (
    <>
      <Button
        label="No"
        icon="pi pi-times"
        className="p-button-text"
        onClick={hideDeleteCliReferenciaPersonalDialog}
      />
      <Button
        label="Si"
        icon="pi pi-check"
        onClick={deleteCliReferenciaPersonal}
      />
    </>
  );

  return (
    <div className="p-grid" disabled="disabled">
      <Toast ref={toast} />
      <div className="p-col-12 p-md-12" disabled="disabled">
        <Button
          icon="pi pi-plus"
          className="p-button-rounded p-button-warning p-mr-2 p-mb-2"
          tooltip="Nueva Referencia"
          onClick={() => editCliReferenciaPersonal()}
        />
        <div className="p-col-12 p-md-12">
          <DataTable
            value={cliReferenciaPersonales}
            dataKey="refId"
            rowHover
            className="datatable-responsive"
            emptyMessage="No se encontraron Referencias personales."
          >
            {/* <Column
              field="refPerId"
              header="Código"
              sortable
              body={idReferenciaBodyTemplate}
            ></Column> */}
            <Column
              header="Relación"
              sortable
              body={relacionReferenciaBodyTemplate}
            ></Column>
            <Column
              header="Nombre"
              sortable
              body={nombreReferenciaBodyTemplate}
            ></Column>
            <Column
              header="Celular"
              sortable
              body={celularReferenciaBodyTemplate}
            ></Column>
            <Column
              header="Estatus"
              sortable
              body={estatusReferenciaBodyTemplate}
            ></Column>
            <Column header="Acciones" body={actionBodyTemplate}></Column>
          </DataTable>

          <Dialog
            visible={cliReferenciaPersonalDialog}
            style={{ width: "50vw" }}
            header="Datos de la referencia personal"
            modal
            className="p-fluid"
            footer={referenciaPersonalDialogFooter}
            onHide={hideDialog}
          >
            <>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-4">
                  <label htmlFor="state">Tipo Persona</label>
                  <Dropdown
                    id="perTipoId"
                    name="perTipoId"
                    value={cliReferenciaPersonal.appTipoPersona}
                    onChange={(e) => {
                      onInputChangeObject(
                        e,
                        "appTipoPersona",
                        e.target.value.perTipoId,
                        "perTipoId"
                      );
                    }}
                    options={appTipoPersonas}
                    optionLabel="perTipoDescripcion"
                    placeholder="Selecciones un tipo de Persona"
                  ></Dropdown>
                </div>
                <div className="p-field p-col-4">
                  <label htmlFor="state">Relación Asociado</label>
                  <Dropdown
                    id="state"
                    value={dropdownItemRelacionReferencia}
                    onChange={(e) => {
                      onInputChangeObjectRelacionReferencia(
                        e,
                        "apprelacionPersona",
                        e.target.value.code,
                        "refPerRelacion"
                      );
                    }}
                    options={dropdownItemsRelacionReferencias}
                    optionLabel="name"
                    placeholder="Seleccione..."
                    required
                  ></Dropdown>
                </div>
                <div className="p-field p-col-4">
                  <label htmlFor="state">Estado</label>
                  <Dropdown
                    id="state"
                    value={dropdownItemStatusReferencia}
                    onChange={(e) => {
                      onInputChangeObjectEstatusReferencia(
                        e,
                        "appEstatusReferencia",
                        e.target.value.code,
                        "refPerEstatus"
                      );
                    }}
                    options={dropdownItemStatusReferencias}
                    optionLabel="name"
                    placeholder="Seleccione..."
                    required
                  ></Dropdown>
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-12">
                  <label htmlFor="asoId">Nombre</label>
                  <InputText
                    id="refPerNombre"
                    type="text"
                    value={cliReferenciaPersonal?.refPerNombre}
                    onChange={(e) => onInputChange(e, "refPerNombre")}
                  />
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-4">
                  <label htmlFor="asoId">Correo</label>
                  <InputText
                    id="refPerNombre"
                    type="email"
                    value={cliReferenciaPersonal?.refPerCorreo}
                    onChange={(e) => onInputChange(e, "refPerCorreo")}
                  />
                </div>
                <div className="p-field p-col-4">
                  <label htmlFor="asoId">Telefono</label>
                  <InputText
                    id="refPerNombre"
                    type="number"
                    value={cliReferenciaPersonal?.redPerTelefono}
                    onChange={(e) => onInputChange(e, "redPerTelefono")}
                  />
                </div>
                <div className="p-field p-col-4">
                  <label htmlFor="asoId">Celular</label>
                  <InputText
                    id="refPerNombre"
                    type="number"
                    value={cliReferenciaPersonal?.refPerCelular}
                    onChange={(e) => onInputChange(e, "refPerCelular")}
                  />
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-12">
                  <label htmlFor="address">Dirección</label>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={cliReferenciaPersonal?.refPerDireccion}
                    onChange={(e) => onInputChange(e, "refPerDireccion")}
                  />
                </div>
              </div>
              <div className="p-formgrid p-grid">
                <div className="p-field p-col-12">
                  <label htmlFor="address">Descripción</label>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={cliReferenciaPersonal?.refPerDescripcion}
                    onChange={(e) => onInputChange(e, "refPerDescripcion")}
                  />
                </div>
              </div>
            </>
          </Dialog>
          <Dialog
            visible={deleteCliReferenciaPersonalDialog}
            style={{ width: "450px" }}
            modal
            footer={deleteCliReferenciaPersonalDialogFooter}
            onHide={hideDeleteCliReferenciaPersonalDialog}
          >
            <div className="p-d-flex p-ai-center p-jc-center">
              <i
                className="pi pi-exclamation-triangle p-mr-3"
                style={{ fontSize: "2rem" }}
              />
              {cliReferenciaPersonal && (
                <span>
                  ¿Estás seguro de que quieres eliminar{" "}
                  <b>{cliReferenciaPersonal.refPerNombre}</b>?
                </span>
              )}
            </div>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default CliAsociadoReferenciaPersonalComponent;
