import React from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { NuevoAhoProducto } from "./NuevoAhoProducto";
import { TasaRango } from "./TasaRango";
export const MenuNuevoAhoProducto = ({
  newProducto,
  setNuevoAhoProducto,
  toast,
  estado,
  setEstado,
  setNewProducto,
}) => {
  return (
    <TabView>
      <TabPanel header="Nuevo">
        <NuevoAhoProducto
          newProductoHook={newProducto}
          setNuevoAhoProducto={setNuevoAhoProducto}
          toast={toast}
          estado={estado}
          setEstado={setEstado}
          setNewProductoHook={setNewProducto}
        />
      </TabPanel>
      <TabPanel header="Tasa Por Rangos" disabled={newProducto?.tasId !== null}>
        <TasaRango
          newProducto={newProducto}
          setNuevoAhoProducto={setNuevoAhoProducto}
          toast={toast}
        />
      </TabPanel>
    </TabView>
  );
};
