import React from "react";
import { InputText } from "primereact/inputtext";

import classNames from "classnames";
//funciones
import onChajeObjN from "../../../../utilities/onChajeObjN";
import selectText from "../../../../utilities/selecText";

export const NuevoCompromiso = ({
  tipoCompromiso,
  setTipoCompromiso,
  submitted,
}) => {
  return (
    <div className="card">
      <div className="p-grid p-fluid">
        <div className="p-col-12 p-md-6 p-lg-6">
          <label> Descripcion</label>
          <InputText
            id="descripcion"
            placeholder="Descripcion"
            maxLength={45}
            value={tipoCompromiso.preTipoComDescripcion}
            onChange={(e) =>
              onChajeObjN(
                e,
                "preTipoComDescripcion",
                tipoCompromiso,
                setTipoCompromiso
              )
            }
            onClick={(e) => selectText(e)}
            className={classNames({
              "p-invalid": submitted && !tipoCompromiso.preTipoComDescripcion,
            },{"p-invalid": tipoCompromiso.preTipoComDescripcion.length >= 45 })}
          />

          {tipoCompromiso.preTipoComDescripcion.length >= 45 && (
            <small className="p-invalid">Solo se admiten 45 carateres</small>
          )}

          {submitted && !tipoCompromiso.preTipoComDescripcion && (
            <small className="p-invalid">Descripcion requerida</small>
          )}
        </div>
        <div className="p-col-12 p-md-6 p-lg-6">
          <label> Accion</label>
          <InputText
            id="accion"
            onClick={(e) => selectText(e)}
            placeholder="Accion"
            maxLength={45}
            value={tipoCompromiso.preTipComAccion}
            disabled={tipoCompromiso.preTipComId !== 0}
            onChange={(e) =>
              onChajeObjN(
                e,
                "preTipComAccion",
                tipoCompromiso,
                setTipoCompromiso
              )
            }
            className={classNames({
              "p-invalid": submitted && !tipoCompromiso.preTipComAccion,
            },{"p-invalid": tipoCompromiso.preTipComAccion.length >= 45 })}
          />
          {tipoCompromiso.preTipComAccion.length >= 45 && (
            <small className="p-invalid">Solo se admiten 45 carateres</small>
          )}
          {submitted && !tipoCompromiso.preTipComAccion && (
            <small className="p-invalid">Nombre de accion requerida</small>
          )}
        </div>
      </div>
    </div>
  );
};
